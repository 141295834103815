import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import {
  ButtonLarge,
  ButtonSmall
} from '../../components/elements/Button/ButtonStyled';

const ChangePasswordContainer = styled.div<{ height?: string }>`
  width: 515px;
  float: right;
  border: none;
  display: flex;
  margin-right: 12%;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  padding: 42px;
  min-height: ${(props) => props.height};
  background: rgba(255, 255, 255, 0.1);
  @media (max-width: 1440px) {
    margin-right: 5%;
  }
  ${media.lessThan('medium')`
    width: 100%;
    height: 100vh;
    margin-right: 0;
    padding: 35px 30px;
    background: rgba(11, 11, 34, 0.9);
  `}
`;
const Logo = styled.img`
  width: 100%;
  max-width: 191px;
  max-height: 62px;
  margin-bottom: 42px;
`;
const ContainerSendCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ContainerChangePassword = styled.div``;
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 431px;
  align-items: flex-start;
  justify-content: flex-start;

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.6);
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: #fff !important;
        }
        border-radius: 0px;
        color: rgba(255, 255, 255, 0.6);
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff61;
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #ffffff61;
        }
      }
    }
  }
  .MuiInputAdornment-root {
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;
    .MuiButtonBase-root {
      width: 100%;
      height: 100%;
      padding: 11px 20px;
      margin-right: -32px;
      background-color: #2f2744;
      border-radius: 0 !important;
      button:hover {
      }
      svg {
        width: 22px;
        height: 22px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .css-1lwbda4-MuiStack-root {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
      .MuiOutlinedInput-input {
        border-radius: 4px !important;
      }
      .MuiOutlinedInput-root {
        padding-right: 0px;
        .MuiInputAdornment-root {
          display: none;
        }
      }
    }
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 47px;
      color: #ffffff61;
      background: transparent;
      border: 1px solid #ffffff61;
    }
    .selected-flag:hover,
    .flag-dropdown.open .selected-flag,
    .react-tel-input .selected-flag:focus {
      background-color: transparent;
    }
    .flag-dropdown {
      border-radius: 5px 0 0 5px;
      background-color: transparent;
      .country-list {
        width: 430px;
        color: #ffffff61;
        border-radius: 4px;
        filter: invert(0.02);
        background-color: #0d0b22;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5px;
          box-sizing: border-box;
          border: 1px solid #0f42f7;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: linear-gradient(0deg, #3b9ef1, #3b9ef1);
        }
        .country {
          :hover {
            background-color: #27244dad;
            background-color: #2d2d4a78;
          }
        }
        .country.highlight {
          background-color: #2d2d4a78;
        }
      }
    }
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
  }
  .MuiFormHelperText-root {
    margin-bottom: 10px;
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }
  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 0px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
  }
`;
const Register = styled(ButtonSmall)`
  height: 52px;
  width: 206px;
  line-height: 120%;
  margin-top: 42px;
  font-family: 'Poppins';
  font-weight: ${(props) => props.theme.typography.fontWeightBold + 200};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.2}rem;
`;
const ChangePassword = styled(ButtonLarge)`
  height: 52px;
  max-width: 273px;
  margin-top: 42px;
  line-height: 120%;
  font-family: 'Poppins';
  font-weight: ${(props) => props.theme.typography.fontWeightBold + 200};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.2}rem;
`;
const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;

  justify-content: flex-start;
  .invalid-number-message {
    top: 45px;
    line-height: 24px;
    font-style: normal;
    position: absolute;
    letter-spacing: 0.15px;
    background-color: transparent;
    color: ${(props) => props.theme.palette.error.main};
    font-size: ${(props) => props.theme.typography.htmlFontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightLight};
  }

  ${(props) =>
    props.error &&
    css`
      .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
        color: ${props.theme.palette.error.main};
        font-size: ${props.theme.typography.fontSize}rem;
      }

      .MuiOutlinedInput-notchedOutline {
        font-size: ${props.theme.typography.fontSize}rem;
        border-color: ${props.theme.palette.error.main} !important;
      }
    `};
  ${media.lessThan('medium')`
    width: 100%;
  `}

  & + & {
    margin-top: 22px;
  }
`;
const ContainerPassword = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  div:first-child {
    margin-right: 0px;
    width: 100% !important;
  }
  button {
    width: 45px;
    right: 13px;
    height: 55px;
    color: #ffffff;
    position: absolute;
    border-radius: 0px !important;
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
    .MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.3}rem;
    }
  }
`;
const Text = styled.p`
  gap: 4px;
  display: flex;
  text-align: center;
  margin-inline: auto;
  margin-bottom: 22px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  max-width: 318px;
`;
const Title = styled.h2`
  text-align: left;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Russo One';
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSizeBold}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const Cancel = styled(Text)`
  min-width: 100%;
  font-size: ${(props) => props.theme.typography.fontSize - 0.2}rem;
  display: flex;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 0px;
  justify-content: center;
`;
export {
  Logo,
  Text,
  Form,
  Title,
  Cancel,
  Register,
  ContainerInput,
  ChangePassword,
  ContainerPassword,
  ContainerSendCode,
  ContainerChangePassword,
  ChangePasswordContainer
};
