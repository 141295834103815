export const header = {
  accountButton: {
    userIcon: {
      alt: 'Account icon'
    }
  },
  accountButtons: {
    messageIcon: {
      alt: 'Message icon'
    },
    notificationIcon: {
      alt: 'Notification icon'
    }
  },
  indication: {
    copyIcon: {
      alt: 'Copy icon'
    }
  },
  menuMobile: {
    labels: {
      battle: 'Battle',
      logout: 'Logout',
      tournaments: 'Tournaments',
      globalRanking: 'Global Ranking',
      matchBetBanking: 'MatchCash Banking',
      profileSettings: 'Profile Settings',
      friends: 'Friends',
      nft: 'Marketplace',
      chat: 'Chat',
      buyMatchcoins: `Buy {{coin}}`,
      matchBetTournament: 'MatchCash Tournament',
      cart: 'Checkout'
    },
    matchbetIcon: {
      alt: 'Matchbet icon'
    },
    openCloseIcon: {
      alt: 'Open and close menu icon'
    }
  },
  profileMenu: {
    userButton: {
      label: 'Users',
      alt: 'User icon'
    },
    profileSettingsButton: {
      label: 'Profile Settings',
      alt: 'Profile settings icon'
    },
    settingsButton: {
      label: 'Settings',
      alt: 'Settings icon'
    },
    logoutButton: {
      label: 'Logout',
      alt: 'Logout icon'
    },
    notification: {
      title: "What's New",
      isNew: 'New',
      paragraph: 'Here you will see your updates. So far there are none.',
      invitation: {
        title: 'You have a new invitation',
        paragraph: 'sent you a new friend invite!',
        accept: 'Accept',
        reject: 'Reject'
      },
      aloneBattleRequest: {
        accept: 'Accept',
        title: 'New challenge!',
        decline: 'Decline',
        paragraph:
          'You were challenged by your friend {{apelido}} , to play {{game}} on {{platform}} platform, bet amount {{amount}} {{matchcoins}}.'
      },
      teamBattleRequest: {
        accept: 'Accept',
        title: 'New challenge!',
        decline: 'Decline',
        paragraph:
          'You were challenged by the team {{team_name}} , to play {{game}} on {{platform}} platform, bet amount {{amount}} {{matchcoins}}.'
      },
      aloneBattleAccepted: {
        title: 'Challenge accepted!',
        paragraph:
          'Your battle {{game}}, betting {{amount}} machcoins, has been accepted by the user {{apelido}}.',
        decline: 'Decline',
        letsgo: 'Let’s go!'
      },
      teamBattleAccepted: {
        title: 'Challenge accepted!',
        paragraph:
          'Your battle {{game}}, betting {{amount}} machcoins, has been accepted by the team {{team_name}}.',
        decline: 'Decline',
        letsgo: 'Let’s go!'
      },
      aloneBattleDeclined: {
        title: 'Challenge declined!',
        paragraph: 'Your proposal was declined.'
      },
      teamBattleDeclined: {
        title: 'Challenge declined!',
        paragraph: 'Your proposal was declined.'
      },
      tournamentStartPhase: {
        title: 'New stage!',
        paragraph:
          'The {{tournamentName}} tournament, has enter the stage {{phase}}.'
      },
      tournamentInvite: {
        title: 'New Tournament Challenge!',
        paragraph:
          'You were invited by {{name}} to join a tournament and play {{game}}, on {{platform}}, bet amount {{amount}} {{matchcoins}} '
      },
      empty: {
        title: "What's New",
        description: 'Here you will see your updates, so far there are none.'
      },
      updateListButton: {
        searching: 'Searching notifications...',
        updateNotifications: 'Update notifications'
      },
      alt: 'Notification icon',
      popup: {
        friendInviteAccepted: 'Now your are friends!',
        friendInviteAcceptedError: 'Error on accept the invite. Try again!',
        friendInviteRejected: 'Invite has been decline!',
        friendInviteRejectedError: 'Error on accept the invite. Try again!',
        battleInviteAcceptedError: 'Error on accept the battle. Try again!',
        battleInviteRejected: 'The battle are rejected!',
        battleInviteRejectedError: 'Error on reject the battle. Try again!',
        tournamentInviteAcceptedError:
          'Error while joing the tournament. Try again'
      }
    }
  }
};
