import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 19px 0;

  & + & {
    border-top: 1px solid rgba(255, 255, 255, 0.09);
  }

  &:last-child {
    padding-bottom: 27px;
  }
`;

export const Date = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 100%;
  letter-spacing: 0.0125em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 11px;
`;

export const Description = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 19px;
`;
