/* eslint-disable @typescript-eslint/no-explicit-any */
import { Team } from 'pages/CreateBattle/@types';
import { Actions } from './@types/actions';

const tournamentReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.SET_TOURNAMENT:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          ...action.payload
        }
      };

    case Actions.SET_STEP:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          step: action.payload.step
        }
      };

    case Actions.SELECT_TEAM:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          teamSelected: action.payload.team
        }
      };

    case Actions.SELECT_GAME:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          game: action.payload.game
        }
      };

    case Actions.SAVE_PLATFORM_FOR_SELECTED_GAME:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          nickname: action.payload.nickname,
          platform: action.payload.platform
        }
      };

    case Actions.CREATE_NEW_TEAM:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          teams: [...state.tournament.teams, action.payload.team]
        }
      };

    case Actions.SET_TEAMS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          teams: action.payload.teams
        }
      };

    case Actions.UPDATE_TEAM:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          teams: [
            ...state.tournament.teams.filter(
              (team: Team) => team.entity_id !== action.payload.team.entity_id
            ),
            action.payload.team
          ]
        }
      };

    case Actions.TOGGLE_CREATE_A_BET_MODAL:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          createABetModal: {
            open: !state.tournament.createABetModal.open
          }
        }
      };

    case Actions.SET_BET_AMOUNT:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          amount: action.payload.amount
        }
      };

    case Actions.SET_FRIEND_CHALLENGER:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          challenger: {
            friend: action.payload.friend,
            team: null
          }
        }
      };

    case Actions.SET_LOADING:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          loading: action.payload.loading
        }
      };

    case Actions.SET_MY_TOURNAMENTS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          myTournament: action.payload.myTournament,
          myTournamentHistory: action.payload.myTournamentHistory
        }
      };

    case Actions.RESET_TOURNAMENT_ALONE:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          game: {
            created_by: null,
            status: '',
            entity_id: '',
            platforms: [],
            name: '',
            image_url: ''
          },
          platform: null,
          challenger: {
            ...state.tournament.challenger,
            friend: null
          },
          amount: 0,
          step: 1
        }
      };

    case Actions.UPDATE_PLAYERS_SELECTED:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          selectedPlayers: [...action.payload.selectedPlayers]
        }
      };
    case Actions.TYPE_TOURNAMENT:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          typeTournament: action.payload.typeTournament
        }
      };
    case Actions.SET_SIZE_TOURNAMENT:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          sizeTournament: action.payload.sizeTournament
        }
      };

    case Actions.SET_AMOUNT:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          amount: action.payload.amount
        }
      };

    default:
      return {};
  }
};

export default tournamentReducer;
