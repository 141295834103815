import { GeneralButton } from 'components/elements/GeneralButton';
import { Pagination } from 'components/elements/Pagination';
import { useModal } from 'components/modules/Modal';
import { Search } from 'components/modules/Search';
import { Friend } from 'models/Friends';
import { IndicationLink } from 'components/modules/IndicationLink';
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationContext } from 'state/notification/state';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import Mock, {
  Solicitations as SolicitationsMock
} from 'service/mocks/Friends';
import { servicesApis } from 'service/service.api';
import { t } from 'translate/i18n';
import { Matchbet } from '..';
import { InviteAFriend } from './components/InviteAFriend';
import List from './components/List/List';
import { Solicitations } from './components/Solicitations';
import * as S from './FriendsStyled';

const Friends: React.FC = () => {
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [searching, setSearching] = useState<boolean>(false);
  const [lastCardWidth, setLastCardWidth] = useState<number>(0);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const router = useLocation();
  const { showModal } = useModal();

  const translationString = 'components.friends';

  const [friendListReturnData, setFriendListReturnData] =
    useState<boolean>(false);
  const [solicitationListReturnData] = useState<boolean>(true);
  const [data, setData] = useState<Friend[]>([...Mock]);
  const [dataToShow, setDataToShow] = useState<Friend[]>(data);
  const [allSearchData, setAllSearchData] = useState<Friend[]>([]);
  const [searchDataToShow, setSearchDataToShow] = useState<Friend[]>([]);

  const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const listRef = useRef() as MutableRefObject<HTMLUListElement>;
  const cardRef = useRef() as MutableRefObject<HTMLLIElement>;

  useEffect(() => {
    const resetSearchInput = (): void => {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
    };

    const calculatePaginationVars = (): void => {
      if (listRef.current && cardRef.current) {
        const GAP = 24;
        const ROWS = window.innerWidth < 669 ? 6 : 2;
        const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
        const cardsOnScreen =
          Math.floor(
            (listRef.current.clientWidth + COMPENSATION) /
              (cardRef.current.clientWidth + GAP)
          ) * ROWS;
        setDataToShow(data.slice(0, cardsOnScreen));
        setNumberOfPages(Math.ceil(data.length / cardsOnScreen));
        setPage(1);
        resetSearchInput();
      }
    };

    calculatePaginationVars();

    window.addEventListener('resize', calculatePaginationVars);

    return () => window.removeEventListener('resize', calculatePaginationVars);
  }, [window, router.pathname, data]);

  const getFriends = async () => {
    const response = await servicesApis.friends.getFriends('alone');
    if (response.data) {
      setData(response.data);
    }
    if (response.data.length > 0) {
      setFriendListReturnData(true);
    }
  };

  useEffect(() => {
    getFriends();
  }, []);

  const goFowardOnList = (): void => {
    if (listRef.current && cardRef.current && page < numberOfPages) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : 2;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      if (searchDataToShow.length > 0) {
        setSearchDataToShow(
          allSearchData.slice(cardsOnScreen * page, (page + 1) * cardsOnScreen)
        );
      } else {
        setDataToShow(
          data.slice(cardsOnScreen * page, (page + 1) * cardsOnScreen)
        );
      }

      setPage(page + 1);
    }
  };

  const goBackwardsOnList = (): void => {
    if (listRef.current && cardRef.current && page > 1) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : 2;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      if (searchDataToShow.length > 0) {
        setSearchDataToShow(
          allSearchData.slice(
            (page - 2) * cardsOnScreen,
            cardsOnScreen * (page - 1)
          )
        );
      } else {
        setDataToShow(
          data.slice((page - 2) * cardsOnScreen, cardsOnScreen * (page - 1))
        );
      }

      setPage(page - 1);
    }
  };

  const clickOnPageIndicator = (requestedPage: number): void => {
    if (listRef.current && cardRef.current) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : 2;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      if (searchDataToShow.length > 0) {
        setSearchDataToShow(
          allSearchData.slice(
            (requestedPage - 1) * cardsOnScreen,
            cardsOnScreen * requestedPage
          )
        );
      } else {
        setDataToShow(
          data.slice(
            (requestedPage - 1) * cardsOnScreen,
            cardsOnScreen * requestedPage
          )
        );
      }

      setPage(requestedPage);
    }
  };

  const getCardWidth = (): number => {
    if (cardRef.current) {
      setLastCardWidth(cardRef.current.clientWidth);
      return cardRef.current.clientWidth;
    }

    return lastCardWidth;
  };

  const resetPage = (): void => {
    if (listRef.current) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : 2;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) / (getCardWidth() + GAP)
        ) * ROWS;
      setDataToShow(data.slice(0, cardsOnScreen));
      setNumberOfPages(Math.ceil(data.length / cardsOnScreen));
      setSearchDataToShow([]);
      setAllSearchData([]);
      setPage(1);
    }
  };

  const paginateSearch = (term: string): void => {
    if (listRef.current && cardRef.current) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : 2;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;
      const allDataToShow = (data as Friend[]).filter((all: Friend) =>
        all.apelido.toLocaleLowerCase()?.includes(term.toLocaleLowerCase())
      );
      setAllSearchData(allDataToShow);
      setSearchDataToShow(allDataToShow.slice(0, cardsOnScreen));
      setNumberOfPages(Math.ceil(allDataToShow.length / cardsOnScreen));
      setPage(1);
    }
  };

  const searchTeamOrGame = (term: string): void => {
    if (term.length > 0) {
      paginateSearch(term);
      setSearching(true);
    } else {
      resetPage();
      setSearching(false);
    }
  };

  const onClickToAddAFriend = (): void => {
    showModal(<InviteAFriend />, '468px', true, '45px 50px');
  };

  const onClickToRemoveAFriend = async (friendId: string): Promise<void> => {
    try {
      await servicesApis.friends.deleteFriend(friendId);
      getFriends();
      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.friendDelete`)
        }
      });
    } catch (error: unknown) {
      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.friendDeleteError`)
        }
      });
    }
  };

  return (
    <Matchbet>
      <S.Container>
        <S.FriendsList>
          <S.Title>{t(`${translationString}.title`)}</S.Title>
          <S.AuxiliarTitle>
            {t(`${translationString}.auxiliarTitle`)}
          </S.AuxiliarTitle>
          <S.Wrapper>
            <S.Description>
              {t(`${translationString}.description`)}
            </S.Description>
            <S.SearchContainer>
              <Search
                inputRef={searchInputRef}
                placeholder={t(`${translationString}.searchPlaceholder`)}
                onChange={searchTeamOrGame}
              />
              <GeneralButton
                variant="primary"
                size="medium"
                onClick={onClickToAddAFriend}
              >
                {t(`${translationString}.addButton`)}
              </GeneralButton>
            </S.SearchContainer>
          </S.Wrapper>
          <List
            listRef={listRef}
            cardRef={cardRef}
            data={searching ? searchDataToShow : dataToShow}
            empty={!friendListReturnData}
            removeFriend={onClickToRemoveAFriend}
          />
          {friendListReturnData && numberOfPages > 1 ? (
            <S.PaginationWrapper>
              <Pagination
                numberOfPages={numberOfPages}
                selectedPage={page}
                clickOnRightArrow={goFowardOnList}
                clickOnLeftArrow={goBackwardsOnList}
                clickOnPageIndicator={clickOnPageIndicator}
              />
            </S.PaginationWrapper>
          ) : null}
        </S.FriendsList>
        <S.Solicitations>
          <IndicationLink />
          <div style={{ display: 'none' }}>
            <Solicitations
              data={SolicitationsMock}
              empty={!solicitationListReturnData}
            />
          </div>
        </S.Solicitations>
      </S.Container>
    </Matchbet>
  );
};

export default Friends;
