export default {
  name: {
    key: 'name'
  },
  description: {
    key: 'description'
  },
  rules: {
    key: 'rules'
  },
  timeBetweenStages: {
    key: 'timeBetweenStages'
  },
  stageTime: {
    key: 'stageTime'
  },
  readyTimeout: {
    key: 'readyTimeout'
  },
  stagesPerDay: {
    key: 'stagesPerDay'
  },
  minTimeBetweenStages: {
    key: 'minTimeBetweenStages'
  },
  minStageTime: {
    key: 'minStageTime'
  },
  minReadyTimeOut: {
    key: 'minReadyTimeOut'
  },
  minStagesPerDay: {
    key: 'minStagesPerDay'
  }
};
