import { Amplify, Auth } from 'aws-amplify';
import config from './constants';

const getSession = () => Auth.currentSession();

const COMMON = {
  region: config.COGNITO.REGION,
  custom_header: async () => {
    try {
      return {
        Authorization: (await getSession())?.idToken?.jwtToken
      };
    } catch (error) {
      return `erro gerado em getSession: ${error}`;
    }
  }
};

Amplify.configure({
  Auth: {
    region: config.COGNITO.REGION,
    userPoolId: config.COGNITO.USER_POOL_ID,
    userPoolWebClientId: config.COGNITO.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.APIS.MATCHBET,
        endpoint: config.APIURL,
        ...COMMON
      }
    ]
  }
});
