export enum TransactionTypes {
  DEPOSIT = 'DEPOSIT',
  TRANSFER = 'TRANSFER',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum TransactionStatus {
  CONCLUDED = 'CONCLUDED',
  PROCESSING = 'PROCESSING',
  CALLED = 'CALLED'
}

export type Transaction = {
  type: TransactionTypes;
  date: string;
  status: TransactionStatus;
  value: number;
  currency: string;
  operation_id: string;
  user_from: string;
  user_to: string;
  entry_type: string;
};
