import styled from 'styled-components';

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: grid;
  padding: 24px;
  grid-template-rows: 0.5fr 1fr;
  grid-template-columns: 2fr 500px;
  grid-template-areas: 'banner list' 'slider list';

  @media (max-width: 1919px) {
    grid-template-columns: 1.5fr 400px;
  }

  @media (max-width: 1700px) {
    grid-template-columns: 900px 1fr;
  }

  @media (max-width: 1439px) {
    grid-template-columns: 800px 1fr;
  }

  @media (max-width: 1320px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 2fr 1fr;
  }
`;

export const BannerContainer = styled.div`
  grid-area: banner;
  gap: 24px;
  display: flex;
  width: 100%;
`;
export const SliderContainer = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  grid-area: slider;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  grid-area: list;
`;
