import { useContext } from 'react';

import { Actions } from '../@types/actions';
import { TournamentContext } from '../state';

export const useTournament = () => {
  const { state, dispatch } = useContext(TournamentContext);

  const removePlayerSelected = (entity_id: string) => {
    const newState = state.tournament.selectedPlayers.filter(
      (player: { entity_id: string }) => player.entity_id !== entity_id
    );

    dispatch({
      type: Actions.UPDATE_PLAYERS_SELECTED,
      payload: {
        selectedPlayers: [...newState]
      }
    });

    return [...newState];
  };

  const updateStep = (step: number): void => {
    dispatch({
      type: Actions.SET_STEP,
      payload: {
        step
      }
    });
  };

  const setTournament = (payload: object) => {
    dispatch({
      type: Actions.SET_TOURNAMENT,
      payload
    });
  };

  const toggleBetModal = () => {
    dispatch({
      type: Actions.TOGGLE_CREATE_A_BET_MODAL
    });
  };

  const setLoading = (loading: boolean): void => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: {
        loading
      }
    });
  };

  const setSizeTournament = (sizeTournament: number): void => {
    dispatch({
      type: Actions.SET_SIZE_TOURNAMENT,
      payload: {
        sizeTournament
      }
    });
  };

  const isTournamentOpen = state.tournament.typeTournament === 'openTournament';

  return {
    tournament: state.tournament,
    removePlayerSelected,
    updateStep,
    setTournament,
    toggleBetModal,
    setLoading,
    setSizeTournament,
    isTournamentOpen
  };
};
