import React from 'react';
import { Props } from './@types';
import * as S from './TextFieldStyled';

const TextField: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <S.Container>
      <S.Field {...rest} />
      {children}
    </S.Container>
  );
};

export default TextField;
