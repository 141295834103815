import React, { useEffect, useRef } from 'react';
import { Props } from './@types';

import * as S from './DoubleRangeSliderStyled';

const DoubleRangeSlider: React.FC<Props> = ({
  min,
  max,
  gap = 0,
  firstThumbPosition,
  secondThumbPosition,
  sliderMinimumRef = null,
  sliderMaximumRef = null,
  onChangeInput = () => {}
}) => {
  const sliderOneRef =
    sliderMinimumRef || (useRef() as React.MutableRefObject<HTMLInputElement>);
  const sliderTwoRef =
    sliderMaximumRef || (useRef() as React.MutableRefObject<HTMLInputElement>);
  const sliderTrackRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    onChangeInput([
      firstThumbPosition.toString(),
      secondThumbPosition.toString()
    ]);
  }, []);

  const fillTrackColor = (): void => {
    if (
      sliderOneRef?.current &&
      sliderTwoRef?.current &&
      sliderTrackRef?.current
    ) {
      const sliderMaxValue = sliderTwoRef.current.max;

      const percentOne = (+sliderOneRef.current.value / +sliderMaxValue) * 100;
      const percentTwo = (+sliderTwoRef.current.value / +sliderMaxValue) * 100;
      sliderTrackRef.current.style.background = `linear-gradient(
          90.04deg,
          rgba(255, 182, 32, 0.321875) ${percentOne}%,
          #ffb620 ${percentOne + (percentTwo - percentOne) / 2}%,
          rgba(255, 182, 32, 0.3) ${percentTwo}%
        )`;
    }
  };

  const onSlideOne = (): void => {
    if (sliderOneRef?.current && sliderTwoRef?.current) {
      if (+sliderTwoRef.current.value - +sliderOneRef.current.value <= gap) {
        sliderOneRef.current.value = `${+sliderTwoRef.current.value - gap}`;
      }

      onChangeInput([sliderOneRef.current.value, sliderTwoRef.current.value]);

      fillTrackColor();
    }
  };

  const onSlideTwo = (): void => {
    if (sliderOneRef?.current && sliderTwoRef?.current) {
      if (+sliderTwoRef.current.value - +sliderOneRef.current.value <= gap) {
        sliderOneRef.current.value = `${+sliderTwoRef.current.value + gap}`;
      }

      onChangeInput([sliderOneRef.current.value, sliderTwoRef.current.value]);

      fillTrackColor();
    }
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.SliderTrack id="slider-track" ref={sliderTrackRef}>
          <S.Input
            type="range"
            ref={sliderOneRef}
            min={min}
            max={max}
            defaultValue={firstThumbPosition}
            onInput={onSlideOne}
          />
          <S.Input
            type="range"
            ref={sliderTwoRef}
            min={min}
            max={max}
            defaultValue={secondThumbPosition}
            onInput={onSlideTwo}
          />
        </S.SliderTrack>
      </S.Container>
    </S.Wrapper>
  );
};

export default DoubleRangeSlider;
