import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const AccountInfo = styled.div`
  gap: 15px;
  width: fit-content;
  max-width: 271px;
  display: flex;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);

  ${media.lessThan('medium')`
    background-color: transparent;
  `}

  @media (max-width: 1377px) {
    width: 100%;
    min-width: 100%;
    height: fit-content;
  }
`;

export const ContentTexts = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  ${media.lessThan('medium')`
    width: 95%;
    padding: 21px 0px 24px 10px;
  `}

  @media (max-width: 1377px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 542px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1377px) {
    width: fit-content;
  }
`;

export const Title = styled.h2<{
  size?: string;
  margin?: string;
  showBalance?: boolean;
}>`
  gap: 5px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  font-family: 'Russo One';
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.size}rem;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${(props) =>
    props.showBalance === false &&
    css`
      -webkit-text-security: disc;
    `};

  button {
    width: 22px;
    height: 22px;
    margin: 0 0 0 37px;
    border-radius: 0px !important;
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.white};
    :hover {
      background-color: transparent;
    }
    svg {
      width: 1.3em;
      height: 1.3em;
    }
  }
`;

export const Icon = styled.img``;

export const Text = styled.p<{ margin?: string }>`
  width: auto;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  justify-content: space-between;

  ${({ margin, theme }) => css`
    margin: ${margin};
    color: ${theme.palette.text.white};
    font-weight: ${theme.typography.fontWeightLight};
    font-size: ${theme.typography.fontSize - 0.4}rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 10px;

  @media (max-width: 1377px) {
    align-items: flex-end;
  }

  @media (max-width: 542px) {
    margin-bottom: 0;
    margin-top: 10px;
    flex-direction: row;
    justify-content: flex-start;
  }
`;
