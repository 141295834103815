import styled, { css } from 'styled-components';

export const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  margin-top: 15px;
`;

export const Item = styled.li`
  list-style: none;
  width: fit-content;
  cursor: pointer;

  @media (max-width: 693px) {
    width: 46.5%;
  }

  @media (max-width: 393px) {
    width: 45%;
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 130%;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const ButtonsWrapper = styled.div<{ hide?: boolean }>`
  display: flex;
  gap: 12px;

  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}
`;

export const Button = styled.button`
  width: 28px;
  height: 28px;
  border: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Arrow = styled.img<{ left?: boolean }>`
  width: 20px;
  height: 20px;
  object-fit: contain;

  ${({ left }) =>
    left &&
    css`
      transform: scale(-1);
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  position: relative;
`;

export const Photo = styled.img`
  width: 272px;
  height: 268px;
  object-fit: contain;

  @media (max-width: 693px) {
    width: 100%;
    height: auto;
  }
`;

export const Name = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Validity = styled.p`
  font-family: 'Roboto';
`;

export const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 570px) {
    flex-direction: column;
    gap: 14px;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Coin = styled.img`
  width: 36px;
  height: 34px;
  object-fit: contain;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ValueLabel = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Value = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 570px) {
    justify-content: center;
  }
`;

export const Badget = styled.div`
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.primary};
  color: ${({ theme }) => theme.palette.text.white};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.004em;
  position: absolute;
  top: 10px;
  left: 12px;
`;
