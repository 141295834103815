export const account = {
  yourWallet: {
    wallet: {
      alt: 'wallet icon'
    },
    title: 'Your wallet',
    eye: {
      ariaLabel: 'show or hide match coins values'
    },
    virtualBalance: 'Virtual account balance',
    virtualBalancePending: 'Balance in process',
    coin: 'MATCH',
    moreCoins: 'Need more coins?',
    buyCoins: 'Buy coins'
  },
  transfer: {
    title: 'Transfer',
    description: 'Transfer coin to other users',
    button: 'Transfer'
  },
  withdraw: {
    title: 'Withdraw',
    description: 'Withdraw to another wallet',
    button: 'Withdraw'
  },
  wallet: {
    title: 'Wallet',
    description: 'Top up your MatchCash account',
    button: 'Recharge'
  },
  lineChart: {
    label: 'Balance in Reais'
  }
};
