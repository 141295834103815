import React from 'react';
import icons from 'assets/img/icons';

import { t } from 'translate/i18n';
import { formatMoney } from 'utils/formatMoney';
import { useModal } from 'components/modules/Modal';
import { TournamentCounter } from 'components/modules/TournamentCounter';
import { AcceptTournament } from 'pages/Tournament/components/AcceptTournaments';

import * as S from './TournamentCardStyled';

import { Props } from './@types';

const TournamentCard: React.FC<Props> = ({
  tournamentTitle,
  amount,
  gameImage,
  registered,
  maxPlayers,
  platform,
  startDate,
  tournament,
  updatedData
}) => {
  const { showModal } = useModal();

  const translationString =
    'components.userTournament.openGlobalTournament.card';

  const enterTournamentEvent = () => {
    showModal(
      <AcceptTournament
        data={{
          name: tournament.name,
          amount: `${tournament.entry_fee}`,
          entity_id: tournament.entity_id,
          image_url: tournament.image_url,
          platforms: tournament.platform
        }}
        callback={updatedData}
      />,
      'min-content',
      true,
      '42px'
    );
  };

  return (
    <S.Container onClick={() => enterTournamentEvent()}>
      <S.GameImage
        src={gameImage}
        alt={t(`${translationString}.gameImage.alt`)}
      />
      <S.Infos>
        <S.TitleValueWrapper alignment="flex-start">
          <S.Title>{tournamentTitle}</S.Title>

          <S.ValueWrapper>
            <S.Coin
              src={icons.moedaOuro}
              alt={t(`${translationString}.coin.alt`)}
            />
            <S.Value>{formatMoney(amount)}</S.Value>
          </S.ValueWrapper>
        </S.TitleValueWrapper>

        <S.Platforms>
          <S.Label>{t(`${translationString}.platformLabel`)}</S.Label>
          <S.Icon
            src={platform.image_url}
            alt={t(`${translationString}.platformIcon.alt`)}
          />
        </S.Platforms>

        <S.CountDownWrapper>
          <TournamentCounter
            startDate={startDate}
            registered={registered}
            maxPlayers={maxPlayers}
            registeredLabel={t(`${translationString}.registeredLabel`)}
          />
        </S.CountDownWrapper>
      </S.Infos>
    </S.Container>
  );
};

export default TournamentCard;
