import React, { useEffect, useState } from 'react';
import { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Props } from './@types';
import * as S from './PhoneFieldStyled';

const PhoneField: React.FC<Props> = ({
  id,
  label,
  value,
  error,
  errorMessage,
  onChange,
  isValid = () => {}
}) => {
  const [countryListWidth, setCountryListWidth] = useState<number | null>(null);

  useEffect(() => {
    setCountryListWidth(
      document.querySelector(`input#${id}`)?.clientWidth || null
    );
  }, []);

  useEffect(() => {
    function onResize(): void {
      const input: Element | null = document.querySelector(`input#${id}`);

      if (input) {
        setCountryListWidth(input.clientWidth);
      }
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const mask = (valueToParse: string, pattern: string): string => {
    let index = 0;
    // eslint-disable-next-line no-plusplus
    return pattern.replace(/\./g, () => valueToParse[index++] || '');
  };

  const isValidPhone = (phone: string, country: object): boolean => {
    const { format } = country as CountryData;
    const valueMasked = mask(phone, format);
    const valid = valueMasked.length === format.length;

    isValid(valid);
    return valid;
  };

  return (
    <S.Container error={error}>
      <fieldset>
        <legend>{label}</legend>
        <S.Field
          inputProps={{ id }}
          country="br"
          onChange={onChange}
          value={value}
          specialLabel=""
          countryListWidth={countryListWidth}
          isValid={isValidPhone}
        />
      </fieldset>
      {error && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.Container>
  );
};

export default PhoneField;
