import { i18n } from 'translate/i18n';

import TournamentModalModel from 'pages/CreateTournament/components/TournamentDetailsModal/TournamentModalModel';

const {
  name,
  description,
  rules,
  timeBetweenStages,
  stageTime,
  readyTimeout,
  stagesPerDay,
  minTimeBetweenStages,
  minStageTime,
  minReadyTimeOut,
  minStagesPerDay
} = TournamentModalModel;

export const tournament = {
  banner: {
    alt: 'Game banner'
  },
  playerOneImage: {
    alt: 'Player one'
  },
  playerTwoImage: {
    alt: 'Player two'
  },
  text: 'Starts at',
  gameTitle: 'Game',
  matches: 'matches',
  awardTitle: 'Award',
  stageOne: 'Stage 1',
  stageTwo: 'Stage 2',
  stagefinals: 'Final',
  altIcon: 'Demo icon',
  title01: 'Award of ',
  title02: ' dollars ',
  platform: 'Platform',
  button: 'Participate',
  rules: 'General rules',
  title03: 'in MatchCash',
  entranceFee: 'Entrance fee',
  stageSemifinals: 'Semifinals',
  subTitle: 'MatchCash Tournament {{name}}',
  buttonText: 'Entering the match',
  maxPlayersTitle: 'Maximum players',
  stageQuarterFinals: 'Quarter Finals',
  minPlayersTitle: 'Minimum players',
  titleStage: 'Matches',
  currentRoundTitle: 'Current Departure',
  registeredPlayers: 'Registered players',
  description:
    'Join the battle, gain fame by showing your skills and earn money by coming out as a winner!',
  termoTournamentsModal: {
    title: 'Terms and Conditions of Use'
  },
  modalNickname: {
    alt: 'Icone user',
    title: 'Set a nickname',
    placeholder: 'Nick Name',
    checkBox: `Don't ask me anymore`,
    errorFinancialPassword: 'Invalid financial password',
    errorFinancialPasswordNotRegistred: 'financial password not registred',
    description: 'Put the same nickname used on the selected platform.',
    buttons: {
      left: 'After',
      right: 'Define'
    },
    messages: {
      playerAlreadyRegistered: 'Already registered.',
      successfulAcceptBattle: 'Battle successfully accepted.',
      insufficientWalletBalance: 'Insufficient wallet balance.',
      registrationFailed: 'Registration failed, try again later.',
      successfulRegistration: 'Registration completed successfully.',
      unknow_error: 'Unknow Error'
    }
  },
  modalMessageBalance: {
    alt: 'Icone coin',
    title: 'Your balance is insufficient',
    description: `Buy ${i18n.t('titles.matchcoins')} to enter the tournament!`,
    buttons: {
      buy: 'Buy now'
    }
  },
  acceptTournament: {
    title: 'Enter the tournament',
    errorFinancialPassword: 'Invalid financial password',
    description: 'By proceeding you accept the battle rules and terms of use',

    gameImage: {
      alt: 'tournament image'
    },
    coinIcon: {
      alt: 'coin'
    },
    password: {
      placeholder: 'Financial password'
    },
    eyeIcon: {
      alt: 'see password'
    },
    platforms: 'On the platform',
    buttons: {
      cancel: 'Cancel',
      create: 'Enter'
    }
  },
  deleteTournament: {
    title: 'Delete this tournament',
    gameImage: {
      alt: 'delete tournament image'
    },
    coinIcon: {
      alt: 'coin'
    },
    platforms: 'On the platform',
    buttons: {
      cancel: 'Cancel',
      create: 'Delete'
    }
  },
  notEnoughFunds: 'You do not have enough funds to join this tournament.',
  title: 'Tournament MatchCash',
  chatButton: 'Go to chat',
  bannerBattle: {
    title: 'Current match',
    information: 'This battle is unlock in your validator app',
    button: 'General rules'
  },
  messenger: {
    title: 'Chat MatchCash',
    badget: {
      label: 'You have new messages'
    }
  },
  modalNewTournament: {
    modal: {
      title: 'New Tournament',
      subTitle: 'Define which tournament format you want to create',
      buttonSecondary: 'Cancel',
      buttonPrimary: 'Next',
      typesOfMatch: {
        alone: 'Play alone',
        team: 'Play with team',
        newTeam: 'Create a new team'
      },
      typesOfTournament: {
        withFriends: 'Tournament between friends',
        openTournament: 'Open Tournament'
      }
    }
  },
  editor: {
    field: {
      placeholder: 'Send message...'
    }
  },
  selectedCompetitors: {
    title: 'Selected players',
    selectPlayers: {
      title: 'Select players',
      subTitle: 'Your selected friends will appear here.'
    },
    footer: {
      text: {
        first: 'The tournament model chosen was that of',
        last: 'participants.'
      },
      link: 'change model',
      button: {
        completedTournament: 'Create tournament',
        completingTournament: 'Selected'
      }
    },
    error: {
      tournament_no_nft_matchbet:
        'You dont own the tournament NFT to create tournaments'
    }
  },
  playerAlreadyRegistered: 'Already registered.',
  tournamentDetailsModal: {
    title: 'Tournament details',
    subTitle: 'Fill in the tournament details to continue',
    formModel: {
      [name.key]: {
        placeholder: 'Tournament Name',
        messageError: 'Required field*'
      },
      [description.key]: {
        placeholder: 'Tournament Description',
        messageError: 'Required field*'
      },
      [rules.key]: {
        placeholder: 'Tournament Rules',
        messageError: 'Required field*'
      },
      [timeBetweenStages.key]: {
        placeholder: 'Time Between Stages',
        messageError: 'Required field*'
      },
      [stageTime.key]: {
        placeholder: 'Stage Time',
        messageError: 'Required field*'
      },
      [readyTimeout.key]: {
        placeholder: 'Ready Timeout',
        messageError: 'Required field*'
      },
      [stagesPerDay.key]: {
        placeholder: 'Stages Per Day',
        messageError: 'Required field*'
      },
      [minTimeBetweenStages.key]: {
        placeholder: 'Minimum time between stages',
        messageError: 'Minimum time between stages is 5'
      },
      [minStageTime.key]: {
        placeholder: 'Minimum stage time',
        messageError: 'Minimum stage time is 5'
      },
      [minReadyTimeOut.key]: {
        placeholder: 'Minimum ready time out',
        messageError: 'Minimum ready time out is 3'
      },
      [minStagesPerDay.key]: {
        placeholder: 'Minimum stages per day',
        messageError: 'Minimum stages per day is 2'
      }
    },
    buttonCancel: 'Cancel',
    buttonCreate: 'Continue'
  },
  stages: {
    semiFinal: 'SemiFinal',
    quarterFinal: 'QuarterFinals'
  },
  finishedMessage: 'Tournament ended',
  emptyTournament: {
    title: 'No tournament avaliable',
    paragraph: 'There is no tournament available.'
  }
};
