import styled, { css } from 'styled-components';

function getHeight(numberOfErrors: number, breakingTexts: boolean): string {
  switch (numberOfErrors) {
    case 1:
      if (breakingTexts) {
        return 'height: 406px;';
      }

      return 'height: 387px;';

    case 2:
      if (breakingTexts) {
        return 'height: 441px;';
      }

      return 'height: 422px;';

    case 3:
      if (breakingTexts) {
        return 'height: 476px;';
      }

      return 'height: 457px';

    default:
      if (breakingTexts) {
        return 'height: 476px;';
      }

      return 'height: 457px';
  }
}

export const DropDown = styled.div<{
  active?: boolean;
  minHeight?: string;
  error?: boolean;
  numberOfErrors?: number;
}>`
  width: 100%;
  height: 48px;
  min-height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 6px;
  padding: 0px 19.5px 20px;
  transition: all 0.3s ease-out;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 12px;

  svg {
    transform: rotateZ(90deg);
    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.palette.background.light};
  }

  ${(props) =>
    props.active &&
    css`
      height: 352px;

      @media (max-width: 482px) {
        height: 371px;
      }

      svg {
        transform: rotateZ(270deg);
      }
    `};

  ${(props) =>
    props.active &&
    props.error &&
    css`
      @media (max-width: 483px) {
        ${props.numberOfErrors && getHeight(props.numberOfErrors, true)}
      }

      ${props.numberOfErrors && getHeight(props.numberOfErrors, false)}
    `}
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0 15px 0;
  cursor: pointer;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.palette.text.white};
  font-weight: 400;
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-family: 'Roboto';
  margin-bottom: 12px;
  width: 100%;
`;

export const Text = styled.p`
  color: #ffffff61;
  font-weight: 400;
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-family: 'Roboto';
  margin-top: 5px;
  margin-bottom: 12px;
  width: 100%;
`;

export const GetCode = styled.button`
  background: transparent;
  color: #e49824;
  position: absolute;
  right: 17.25px;
  top: 15px;
  border: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 24px;
  cursor: pointer;
`;
