import { GeneralButton } from 'components/elements/GeneralButton';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ showOnMobile?: boolean }>`
  width: 100%;
  max-width: min-content;
  display: none;
  flex-direction: column;
  grid-area: banner;
  margin: 0 auto;
  height: 511px;

  @media (min-width: 341px) {
    height: 608.22px;
  }

  ${({ showOnMobile }) =>
    showOnMobile &&
    css`
      display: flex;
    `}

  @media (min-width: 1170px) {
    display: flex;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 250px;
  min-height: 250px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (min-width: 341px) {
    height: 328px;
    min-height: 328px;
  }
`;

export const Board = styled.div`
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, #1f2a44 0%, #1f2a44 60%, #1f2a4400 100%);
  width: 100%;
  position: relative;
  top: -200px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 33.83px 10px;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 341px) {
    padding: 33.83px 35.5px;
    padding-top: 200px;
  }
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const PlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
  gap: 15.53px;
  width: 100%;
`;

export const Name = styled.p<{ position: 'left' | 'right' }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.55294rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.text.white};
  word-break: break-word;
  width: 100%;

  ${({ position }) =>
    position === 'left' &&
    css`
      text-align: left;
    `}

  ${({ position }) =>
    position === 'right' &&
    css`
      text-align: right;
    `}
`;

export const PhotosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9.56px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 140%;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Photo = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 7.76px;
`;

export const DateAndHour = styled.p<{ started?: boolean }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 13px;

  ${({ started }) => css`
    visibility: ${started ? 'hidden' : 'visible'};
  `}
`;

export const Information = styled.p`
  margin-top: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.001em;
  color: #f5f6f8;
  width: 230px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 17.63px;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

export const Button = styled(GeneralButton)`
  @media (max-width: 325px) {
    height: 30px;
    padding: 0 21px;
    font-size: 1.2rem;
  }
`;
