import styled, { css } from 'styled-components';

export const SliderContainer = styled.div<{ isLoading?: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  border-radius: 12px;
  padding: 25px 45px;
  overflow: hidden;

  ${({ isLoading }) =>
    isLoading &&
    css`
      min-height: 449px;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const Slider = styled.div`
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  flex: 1;
  height: 100%;
  display: flex;
  overflow-x: scroll;
  gap: 13px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    width: 0;
    height: 0;
  }
`;

export const LeftArrow = styled.img<{ $visibility: boolean }>`
  visibility: ${(props) => (props.$visibility ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 3;
  object-fit: contain;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 8px;
  cursor: pointer;
`;
export const RightArrow = styled.img<{ $visibility: boolean }>`
  visibility: ${(props) => (props.$visibility ? 'visible' : 'hidden')};
  position: absolute;
  top: 50%;
  z-index: 3;
  object-fit: contain;
  width: 30px;
  height: 30px;
  right: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
