import styled from 'styled-components';
import media from 'styled-media-query';

const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderContainer = styled(ContainerGeneric)`
  top: 0px;
  gap: 20px;
  z-index: 3;
  width: 100%;
  min-height: 80px;
  padding-left: 144px;
  justify-content: flex-end;
  background: rgba(51, 41, 80, 0.3);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14);

  ${media.lessThan('medium')`
    top: 0;
    padding: 24px;
    background: none;
  `}
`;

export const ContainerCards = styled(ContainerGeneric)`
  gap: 24px;
  margin-right: 48px;
  justify-content: center;
  ${media.lessThan('medium')`
    display: none;
  `}
`;
