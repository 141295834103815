export const createTournament = {
  title: 'create a tournament',
  auxiliarTitle: 'New Tournament',
  description: {
    matchFormat:
      'Choose your match format, play alone or team up with your friends.',
    selectAGame:
      'Select a game to continue, you can choose a game from the platform or add a new one.',
    challenge: 'Select your friends who will participate in the tournament'
  },
  addButton: 'Add',
  newGlobalBet: 'New Global Tournament',
  subtitle: 'Challenge a friend',
  search: {
    placeholder: 'Search'
  },
  platformFilter: {
    gamesFor: 'Games for',
    allGames: 'All Games',
    victoryValidation: 'Victory validation'
  },
  teamCard: {
    select: 'Select',
    view: 'View',
    challenge: 'Challenge'
  },
  gameCard: {
    select: 'Select',
    openBets: 'open bets'
  },
  platforms: {
    onThePlatform: 'On the platform'
  },
  createABetModal: {
    title: 'Bet amount',
    amount: 'Bet amount',
    balance: 'Balance:',
    password: 'Financial password',
    subTitle: 'Define the amount to be bet on the tournament',
    description:
      ' In case of games played in a team, the amount is deposited and withdrawn only from the user responsible for the team.',
    buttons: {
      cancel: 'Return',
      create: 'Create bet'
    }
  },
  amountBetFilter: {
    title: 'Bet amount',
    lowestPlaceholder: 'Minimum bet',
    highPlaceholder: 'Maximum bet'
  },
  list: {
    challengePlayerWarning: "You don't have friends to challenge"
  },
  tournamentSizeModal: {
    title: 'Tournament size',
    description: 'Choose the amount of tournament participants',
    typesOfTournamentSize: {
      fourTeams: 'Four team tournament',
      eightTeams: 'Eight team tournament',
      sixTeenTeams: 'Sixteen team tournament',
      thirtyTwoTeams: 'Thirty-two team tournament',
      fourPlayers: 'Four players tournament',
      eightPlayers: 'Eight players tournament',
      sixTeenPlayers: 'Sixteen players tournament',
      thirtyTwoPlayers: 'Thirty-two players tournament'
    },
    primaryButton: 'Next',
    secondaryButton: 'Return'
  },
  selectTournamentDateModal: {
    title: 'Tournament date',
    description: 'Set a date for your tournament to start',
    buttons: {
      return: 'Return',
      next: 'Next'
    },
    tournamentStartOptions: {
      onFuture: 'Start in future',
      now: 'Start now'
    },
    error: {
      tournament_no_nft_matchbet:
        'You dont own the tournament NFT to create tournaments'
    }
  }
};
