import { MouseEvent, useRef } from 'react';
import styled, { css } from 'styled-components';
import { StyledButtonProps } from './@types';

export const ButtonWithType = ({
  onClick,
  children,
  submit,
  id,
  ...props
}: StyledButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: MouseEvent<Element>) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };
  return (
    <button
      {...props}
      id={id}
      type={submit ? 'submit' : 'button'}
      ref={buttonRef}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
const ButtonGeneric = styled(ButtonWithType)<{
  secondary?: boolean;
  noActive?: boolean;
}>`
  width: 100%;
  border: none;
  height: 48px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 60px;
  font-family: 'Poppins';
  transition: 0.2s color ease;
  font-family: Poppins;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  background-color: ${(props) => props.theme.palette.button.default};
  ${(props) =>
    props.secondary &&
    css`
      background-color: transparent;
      border: 1px solid ${props.theme.palette.button.default};
      color: ${props.theme.palette.button.default};
    `};
  ${(props) =>
    props.noActive &&
    css`
      cursor: no-drop;
      box-shadow: none;
      color: ${props.theme.palette.text.disable};
      background-color: ${props.theme.palette.button.disable};
    `};
`;
export const ButtonLarge = styled(ButtonGeneric)`
  width: 100%;
`;
export const ButtonMedium = styled(ButtonGeneric)`
  width: 80%;
`;
export const ButtonSmall = styled(ButtonGeneric)`
  width: 38%;
`;
