import { CardType } from './@types';
import * as S from './CardStyle';

const Card = ({
  width,
  maxWidth,
  minWidth,
  height,
  background,
  padding,
  children,
  borderRadius = '12px',
  ...props
}: CardType) => {
  return (
    <S.CardContainer
      width={width}
      height={height}
      padding={padding}
      background={background}
      borderRadius={borderRadius}
      maxWidth={maxWidth}
      minWidth={minWidth}
      {...props}
    >
      {children}
    </S.CardContainer>
  );
};

export default Card;
