import { i18n } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const newGameSchema = yup.object().shape({
  nameGame: yup
    .string()
    .required(i18n.t('errors.mandatoryName'))
    .min(3, i18n.t('errors.invalidName')),
  platforms: yup
    .array()
    .min(1, i18n.t('errors.invalidPlatforms'))
    .required(i18n.t('errors.mandatoryPlatforms'))
});
export default newGameSchema;
