import styled, { css } from 'styled-components';
import { styledProps } from './@types';

type Margin = {
  margin: string;
};

export const ContainerSelector = styled.div<Margin>`
  width: 100%;
  display: flex;
  margin: ${({ margin }) => margin};
  height: 64.15px;
  padding: 12.8292px;
  align-items: center;
  text-overflow: ellipsis;
  border-radius: 12.8292px;
  border: 1px solid #ffb620;
  justify-content: space-between;
`;
export const Icon = styled.img<styledProps>`
  width: 100%;
  width: 38.49px;
  height: 38.49px;
  cursor: pointer;
  filter: invert(9.5%) sepia(17%) saturate(7418%) hue-rotate(339deg)
    brightness(101%) contrast(101%);

  ${({ right }) =>
    right &&
    css`
      transform: rotate(-180deg);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: no-drop;
      filter: opacity(0.5);
    `}
`;
export const Item = styled.h2`
  font-weight: 500;
  color: #ffffff;
  font-size: 1.9rem;
  line-height: 130%;
  font-style: normal;
  font-family: 'Poppins';
  letter-spacing: 0.01em;
  @media (max-width: 430px) {
    font-size: 1.4rem;
    text-align: center;
  }
`;
