import * as Battle from './components/Battle';
import * as CreateBattle from './components/CreateBattle';
import * as CreateTournament from './components/CreateTournament';
import * as Tournament from './components/Tournament';
import * as CountDown from './components/CountDown';
import * as Friends from './components/Friends';
import * as Header from './components/Header';
import * as IndicationLink from './components/IndicationLink';
import * as NFT from './components/NFT';
import * as RecentTransactions from './components/RecentTransactions';
import * as UserProfile from './components/UserProfile';
import * as LanguageSwitch from './components/LanguageSwitch';
import * as Account from './components/Account';
import * as Transfer from './components/Transfer';
import * as AmountAndPlatformFilter from './components/AmountAndPlatformFilter';
import * as DatePicker from './components/DatePicker';
import * as TimePicker from './components/TimePicker';
import * as YourHistory from './components/YourHistory';
import * as GlobalRanking from './components/GlobalRanking';
import * as CompleteSignup from './components/CompleteSignUp';
import * as UserTournament from './components/UserTournament';
import * as TournamentDetails from './components/TournamentDetails';
import * as MatchBetStore from './components/MatchbetStore';
import * as Checkout from './components/Checkout';
import * as CheckoutSuccess from './components/CheckoutSuccess';
import * as SideBar from './components/SideBar';

const messages = {
  'en-US': {
    translations: {
      account: {
        user: 'User',
        logout: 'Logout',
        settings: 'Profile Settings',
        configurations: 'Configurations'
      },
      inputs: {
        email: 'E-mail',
        password: 'Password',
        confirmPassword: 'Confirm Password'
      },
      titles: {
        matchcoins: 'Matchcoins',
        app: 'Virtual account balance',
        account_user: 'Account',
        confirmEmail: 'Confirm Email',
        transfer: 'Transfer',
        withdraw: 'Withdraw',
        wallet: 'Wallet'
      },
      messages: {
        itWorks: 'It works with multi languages',
        smallText: 'A small text',
        dontHaveAnAccount: "Don't have an account?",
        alreadyHaveAnAccount: 'Already have a MatchCash account?',
        iAgreeToThe: 'I agree with the',
        termsOfUse: 'terms of use',
        weVeSentYouACode: `We've sent you a confirmation code via email, enter it below`,
        counter: 'resend in {{counter}} sec',
        weWillSendYouACode:
          'Enter your account email below. A confirmation code will be emailed to it.',
        sucessRegister:
          'Your registration was successful, now just sign in and enjoy the platform.',
        unknow_error: 'Unknow Error'
      },
      links: {
        signIn: 'Sign in',
        signUp: 'Sign up',
        resendCode: 'resend code'
      },
      buttons: {
        save: 'Save',
        back: 'Back',
        cancel: 'Cancel',
        signIn: 'Sign In',
        signUp: 'Sign Up',
        transfer: 'Transfer',
        withdraw: 'Withdraw',
        recharge: 'Recharge',
        sendCode: 'Send Code',
        continue: 'Continue',
        changePassword: 'Change Password',
        signUpForFree: 'Sign Up for free',
        forgotMyPassword: 'Forgot my password'
      },
      components: {
        userProfile: {
          ...UserProfile.labels.EN_US.userProfile,
          schemas: {
            ...UserProfile.schemas.EN_US
          }
        },
        globalRanking: {
          ...GlobalRanking.labels.EN_US.GlobalRanking
        },
        yourHistory: {
          ...YourHistory.labels.EN_US.YourHistory
        },
        header: {
          ...Header.labels.EN_US.header
        },
        recentTransactions: {
          ...RecentTransactions.labels.EN_US.recentTransactions
        },
        battle: {
          ...Battle.labels.EN_US.battle,
          schemas: {
            ...Battle.schemas.EN_US
          }
        },
        completeSignup: {
          ...CompleteSignup.labels.EN_US.CompleteSignUp
        },
        transfer: {
          ...Transfer.labels.EN_US.transfer
        },
        tournament: {
          ...Tournament.labels.EN_US.tournament
        },
        countDown: {
          ...CountDown.labels.EN_US.countDown
        },
        createBattle: {
          ...CreateBattle.labels.EN_US.createBattle
        },
        createTournament: {
          ...CreateTournament.labels.EN_US.createTournament,
          schemas: {
            ...CreateTournament.schemas.EN_US
          }
        },
        friends: {
          ...Friends.labels.EN_US.friends,
          schemas: {
            ...Friends.schemas.EN_US
          }
        },
        indicationLink: {
          ...IndicationLink.labels.EN_US.indicationLink
        },
        nft: {
          ...NFT.labels.EN_US.nft
        },
        languageSwitch: {
          ...LanguageSwitch.labels.EN_US.languageSwitch
        },
        account: {
          ...Account.labels.EN_US.account
        },
        userTournament: {
          ...UserTournament.labels.EN_US.userTournament
        },
        tournamentDetails: {
          ...TournamentDetails.labels.EN_US.tournamentDetails
        },
        amountAndPlatformFilter: {
          ...AmountAndPlatformFilter.labels.EN_US.amountAndPlatformFilter
        },
        datePicker: {
          ...DatePicker.labels.EN_US.datePicker
        },
        timePicker: {
          ...TimePicker.labels.EN_US.timePicker
        },
        MatchBetStore: {
          ...MatchBetStore.labels.EN_US.matchBetStore
        },
        Checkout: {
          ...Checkout.labels.EN_US.Checkout
        },
        CheckoutSuccess: {
          ...CheckoutSuccess.labels.EN_US.CheckoutSuccess
        },
        SideBar: {
          ...SideBar.labels.EN_US.SideBar
        }
      },
      errors: {
        insufficientBalance: 'Insufficient balance',
        incorrectUserNameOrPassword: 'Incorrect user name or password',
        invalidEmail: 'Invalid e-mail.',
        invalidName: 'Invalid name, must be longer than 3 letters.',
        invalidPlatforms: 'Invalid platform, select a platform',
        invalidPassword: 'Invalid password, must be 8 digits',
        invalidCodeAccount: 'Invalid code account, must be 8 digits',
        mandatoryPassword: 'Mandatory password field.',
        mandatoryCodeAccount: 'Mandatory code account field.',
        mandatoryEmail: 'Mandatory e-mail field',
        mandatoryName: 'Mandatory name field!',
        mandatoryFile: 'Mandatory file field!',
        mandatoryPlatforms: 'Mandatory platforms field!',
        mandatoryConfirmPassword: 'Mandatory confirm password field.',
        passwordsDontMatch: `Passwords don't match.`,
        passwordAttemptsExceeded: 'Password attempts exceeded.'
      }
    }
  }
};

export { messages };
