import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 10px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 37px;
  input {
    font-size: ${(props) => props.theme.typography.fontSize - 0.2}rem;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #d9d9d9 !important;
  }
`;

export const Error = styled.p`
  margin-top: 5px;
  margin-left: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.fontSize - 0.2}rem;
`;
