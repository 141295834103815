import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ButtonSmall } from 'components/elements/Button/ButtonStyled';

const AmountContainer = styled.div`
  width: 100%;
  height: 365px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div<{
  column?: boolean;
  padding?: string;
}>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding: ${(props) => props.padding};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};
`;
const Title = styled.h2`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 22px;
  letter-spacing: 0.0015em;
  font-family: 'Russo One';
  text-transform: uppercase;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
const SubTitle = styled(Title)`
  font-family: 'Roboto';
  text-transform: initial;
`;
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 22px;
  align-items: flex-start;
  justify-content: flex-start;

  .MuiFormControl-root {
    margin: 0;
    width: 60%;

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        width: 47%;
        border-radius: 0px;
        padding-right: 20px;
        color: ${(props) => props.theme.palette.text.light};
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.text.light};
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${(props) => props.theme.palette.text.light};
        }
      }
    }
  }

  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.palette.text.light};
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }

  .MuiFormLabel-filled,
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.text.light};
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        .MuiOutlinedInput-input {
          width: 65%;
        }
      }
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }

    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
    -webkit-box-shadow: 0 0 0 1000px #292b42 inset;
    box-shadow: 0 0 0 1000px #292b42 inset;
  }
`;
const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;
  border-radius: 5px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: flex-start;
  /* border: 1px solid ${(props) => props.theme.palette.button.default}; */

  input {
    font-family: 'Roboto';
    letter-spacing: 0.15px;
    font-size: ${(props) => props.theme.typography.fontSize}rem;
    ::placeholder {
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
  }
  ${(props) =>
    props.error &&
    css`
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props.theme.palette.error.main};
      }
      .MuiInputLabel-formControl {
        color: ${props.theme.palette.error.main};
      }
    `};

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const Continue = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
const Cancel = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
const Text = styled.p<{ yellow?: boolean; flex?: boolean; margin?: string }>`
  gap: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  margin: ${(props) => props.margin};
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  ${(props) =>
    props.flex &&
    css`
      width: 66%;
      display: flex;
      justify-content: space-between;
    `};
  ${(props) =>
    props.yellow &&
    css`
      color: ${props.theme.palette.button.default};
    `};
`;
const Initials = styled.strong<{ yellow?: boolean }>`
  ${(props) =>
    props.yellow &&
    css`
      font-family: 'Roboto';
      color: ${props.theme.palette.button.default};
      font-size: ${props.theme.typography.fontSize}rem;
      font-weight: ${props.theme.typography.fontWeightBold};
    `};
`;
const ContainerButtons = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 0 24px;
  align-items: flex-end;
  justify-content: flex-end;
`;
const Icon = styled.img`
  cursor: pointer;
`;
const ContainerPassword = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  div:first-child {
    margin-right: 0px;
    width: 100% !important;
  }
  button {
    width: 45px;
    right: 13px;
    height: 55px;
    color: #ffffff;
    position: absolute;
    border-radius: 0px !important;
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
    .css-i4bv87-MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.3}rem;
    }
  }
`;

export {
  Text,
  Form,
  Icon,
  Title,
  Cancel,
  Content,
  SubTitle,
  Initials,
  Continue,
  ContainerInput,
  AmountContainer,
  ContainerButtons,
  ContainerPassword
};
