import React from 'react';
import { t } from 'translate/i18n';
import { Props } from './@types';

import * as S from './ChatButtonStyled';

const ChatButton: React.FC<Props> = ({
  showOnMobile = true,
  onClick = () => {}
}) => {
  const translationString = 'components.tournament';

  return (
    <S.Wrapper showOnMobile={showOnMobile}>
      <S.Button variant="primary" size="large" onClick={onClick}>
        {t(`${translationString}.chatButton`)}
      </S.Button>
    </S.Wrapper>
  );
};

export default ChatButton;
