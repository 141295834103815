/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import icons from 'assets/img/icons';
// import { Checkbox } from '@mui/material';
import { GeneralButton } from 'components/elements/GeneralButton';
import { useModal } from 'components/modules/Modal';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { t } from 'translate/i18n';
import { servicesApis } from 'service/service.api';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { getErrorStringName } from 'utils/errorHandling';
import BetCreated from 'pages/CreateBattle/components/BetCreated';
import { BattleRequested } from 'models/Battle';
import { BattleContext } from 'state/battle/state';
import { NotificationContext } from 'state/notification/state';
import { ProfileNotificationsContext } from 'state/profileNotifications/state';
import { Actions as BattleActions } from 'state/battle/@types/actions';
import { Actions as NotificationsActions } from 'state/profileNotifications/@types/actions';
import { Actions as NotificationPopUpActions } from 'state/notification/@types/actions';
import { useNavigate } from 'react-router-dom';
import { AcceptTournamentType } from 'pages/Tournament/@types';
import { formSchema } from './SetNicknameSchema';
import * as S from './SetNicknameStyled';
import { setNicknameProps } from './@types';
import { AcceptTournament } from '../AcceptTournaments';

const SetNickname = ({
  name,
  type,
  amount,
  password,
  entity_id,
  platforms,
  image_url,
  callback = () => {}
}: setNicknameProps) => {
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const { setUserActive } = useContentUserActive();
  const { state: battleState, dispatch: battleDispatch } =
    useContext(BattleContext);
  const [createABetError, setCreateABetError] = useState<string>('');
  const translationString = 'components.tournament.modalNickname';
  const { dispatch: notificationPopUpDispatch } =
    useContext(NotificationContext);
  const { dispatch: notificationDispatch } = useContext(
    ProfileNotificationsContext
  );

  const selectedTeamId = battleState.battle.teamSelected?.entity_id || '';

  const showAcceptTournament = (
    isData: AcceptTournamentType,
    message: string
  ) => {
    return showModal(
      <AcceptTournament data={isData} isError={message} />,
      'min-content',
      true,
      '35px 44px 25px'
    );
  };
  const setMessage = (isMessage: string) => {
    return setUserActive({
      registrationSuccessful: true,
      message: isMessage
    });
  };

  const getNotifications = async (): Promise<void> => {
    notificationDispatch({
      type: NotificationsActions.SET_LOADING,
      payload: { loading: true }
    });

    try {
      const response = await servicesApis.user.getNotifications();

      notificationDispatch({
        type: NotificationsActions.SET_NOTIFICATIONS,
        payload: {
          notifications: response
        }
      });
    } catch (error) {
      console.error(error);
    }

    notificationDispatch({
      type: NotificationsActions.SET_LOADING,
      payload: { loading: false }
    });
  };

  const getCurrentBattle = async (): Promise<void> => {
    try {
      const battle: BattleRequested =
        await servicesApis.battle.getCurrentBattle();
      battleDispatch({
        type: BattleActions.SET_CURRENT_BATTLE,
        payload: {
          currentBattle: {
            entity_id: battle.entity_id,
            inProgress: true,
            selectedGame: {
              image_url: battle.selected_game.image_url
            },
            playerOne: {
              name: battle.player1.name,
              image_url: battle.player1.image_url
            },
            playerTwo: {
              name: battle.player2.name,
              image_url: battle.player2.image_url
            }
          }
        }
      });
    } catch (error: any) {
      const errorCode = await getErrorStringName(
        error.response.data.error_code
      );
      const message = t(`${translationString}.messages.${errorCode}`);

      notificationPopUpDispatch({
        type: NotificationPopUpActions.OPEN,
        payload: {
          message
        }
      });
    }
  };

  const registerInTournament = async (nickname: string) => {
    await servicesApis.tournament
      .postTournament(nickname, password, entity_id)
      .then(() => {
        setUserActive({
          registrationSuccessful: true,
          message: t(`${translationString}.messages.successfulRegistration`)
        });
        closeModal();
        callback();
      });
  };

  const createBattle = () => {
    showModal(
      <BetCreated currentPassword={password} />,
      '475px',
      true,
      '35px 44px 25px'
    );
    setCreateABetError('');
  };

  const acceptBattle = async (nickname: string) => {
    await servicesApis.battle.putAcceptBattle(
      entity_id,
      password,
      nickname,
      selectedTeamId
    );

    setUserActive({
      registrationSuccessful: true,
      message: t(`${translationString}.messages.successfulAcceptBattle`)
    });

    closeModal();
    getCurrentBattle();
    getNotifications();
    navigate('/matchbet/battle');
  };

  const formik = useFormik({
    initialValues: {
      nickname: ''
    },
    onSubmit: async ({ nickname }) => {
      setLoading(true);
      try {
        switch (type) {
          case 'createBattle':
            createBattle();
            break;
          case 'acceptBattle':
            await acceptBattle(nickname);
            break;
          default:
            await registerInTournament(nickname);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        const data = {
          name,
          amount,
          entity_id,
          platforms,
          image_url
        };
        if (error.message) {
          switch (error.message) {
            case 'invalidPassword':
              showAcceptTournament(
                data,
                t(`${translationString}.errorFinancialPassword`)
              );
              break;
            case 'financialPasswordNotRegistered':
              showAcceptTournament(
                data,
                t(`${translationString}.errorFinancialPasswordNotRegistred`)
              );
              break;
            case 'playerAlreadyRegistered':
              closeModal();
              return setMessage(
                t(`${translationString}.messages.playerAlreadyRegistered`)
              );
              break;
            case 'InsufficientWalletBalance':
              closeModal();
              setMessage(
                t(`${translationString}.messages.insufficientWalletBalance`)
              );
              break;
            default:
              return setMessage(
                t(`${translationString}.messages.registrationFailed`)
              );
          }
        }
      }
    },
    validationSchema: formSchema
  });
  return (
    <S.Container>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.Description>{t(`${translationString}.description`)}</S.Description>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.InputWrapper
          error={!!formik.errors.nickname && formik.touched.nickname}
        >
          <S.Field
            type="text"
            id="nickname"
            onChange={formik.handleChange}
            placeholder={t(`${translationString}.placeholder`)}
          />

          <S.SeePassIcon
            src={icons.userOctagonSvg}
            alt={t(`${translationString}.eyeIcon.alt`)}
            error={!!formik.errors.nickname && formik.touched.nickname}
          />
        </S.InputWrapper>
        {formik.errors.nickname && (
          <S.TextError>{formik.errors.nickname}</S.TextError>
        )}
      </S.Form>
      <S.ButtonsWrapper>
        <GeneralButton
          type="submit"
          size="large"
          variant="primary"
          isLoading={loading}
          onClick={() => formik.handleSubmit()}
        >
          {t(`${translationString}.buttons.right`)}
        </GeneralButton>
      </S.ButtonsWrapper>
    </S.Container>
  );
};

export default SetNickname;
