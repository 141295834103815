/* eslint-disable no-else-return */
import images from 'assets/img';
import { GeneralButton } from 'components/elements/GeneralButton';
import { Card } from 'components/modules';
import React, { useContext, useEffect, useState } from 'react';
import { MenuContext } from 'state/menu/state';
import { Actions as MenuActions } from 'state/menu/@types/actions';
import { Actions as userActions } from 'state/user/@types/actions';
import { useNavigate } from 'react-router-dom';
import { t } from 'translate/i18n';
import { useModal } from 'components/modules/Modal';
import { useMobile } from 'hooks/useMobile';
import { UserContext } from 'state/user/state';
import { servicesApis } from 'service/service.api';
import { Props, Type } from './@types';

import * as S from './BannerStyled';
import { NewEvent } from '../NewEvent';

const Banner: React.FC<Props> = ({ type, small = false }) => {
  const [isMobile] = useMobile();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const { dispatch: MenuDispatch } = useContext(MenuContext);
  const [redirectToStore, setRedirectToStore] = useState<boolean | undefined>(
    true
  );

  const userBalance = userState.user.balance;
  const { champBetOnwership } = userState.user;

  const translationBattleString = 'components.battle.banner';
  const translationTournamentString = 'components.userTournament.banner';

  const showModalBattle = () => {
    showModal(<NewEvent />, '475px', true, '35px 42px 43px');
  };

  const showModalTournament = () => {
    showModal(<NewEvent isTournament />, '475px', true, '35px 42px 43px');
  };

  const getSrc = () => {
    switch (type) {
      case Type.BATTLE:
        return images.battle_cards;

      case Type.TOURNAMENT:
        return images.createTournamentBanner;

      default:
        return '';
    }
  };

  const getAlt = () => {
    switch (type) {
      case Type.BATTLE:
        return t(`${translationBattleString}.alt`);

      case Type.TOURNAMENT:
        return t(`${translationTournamentString}.alt`);

      default:
        return '';
    }
  };

  const getTitle = () => {
    switch (type) {
      case Type.BATTLE:
        return t(`${translationBattleString}.title`);

      case Type.TOURNAMENT:
        return t(`${translationTournamentString}.title`);

      default:
        return '';
    }
  };

  const getDescription = () => {
    switch (type) {
      case Type.BATTLE:
        return t(`${translationBattleString}.description`);

      case Type.TOURNAMENT:
        return t(`${translationTournamentString}.description`);

      default:
        return '';
    }
  };

  const checkButtonLabel = (translationString: string) => {
    if (redirectToStore) {
      return t(`${translationString}.button.goToStore`);
    }
    if (small || isMobile) {
      return t(`${translationString}.button.small`);
    }
    return t(`${translationString}.button.large`);
  };

  const getLabelButton = () => {
    switch (type) {
      case Type.BATTLE:
        return checkButtonLabel(translationBattleString);

      case Type.TOURNAMENT:
        return checkButtonLabel(translationTournamentString);

      default:
        return '';
    }
  };

  const getButtonSize = () => {
    return small || isMobile ? 'small' : 'large';
  };

  const getButtonId = () => {
    return small || isMobile ? 'createBattleSmall' : 'createBattleLarge';
  };

  const onClickToBuyCoins = (): void => {
    MenuDispatch({
      type: MenuActions.SET_MENU,
      payload: {
        selected: 'buy_coins'
      }
    });
    navigate('/matchbet/store/buyCoins');
  };

  const onClickToBuyNFT = (): void => {
    MenuDispatch({
      type: MenuActions.SET_MENU,
      payload: {
        selected: 'store'
      }
    });
    navigate('/matchbet/store/nfts');
  };

  const checkOnClick = () => {
    switch (type) {
      case Type.BATTLE:
        return onClickToBuyCoins();

      case Type.TOURNAMENT:
        return onClickToBuyNFT();
      default:
        return () => {};
    }
  };

  const getOnClick = () => {
    if (redirectToStore) {
      return () => checkOnClick();
    }
    switch (type) {
      case Type.BATTLE:
        return () => showModalBattle();

      case Type.TOURNAMENT:
        return () => showModalTournament();

      default:
        return () => {};
    }
  };

  const getDescriptionText = () => {
    switch (type) {
      case Type.BATTLE:
        return t(`${translationBattleString}.buyCoins`);
      case Type.TOURNAMENT:
        return t(`${translationTournamentString}.buyNft`);

      default:
        return '';
    }
  };

  const userHasBalance = () => {
    if (userBalance > 0) {
      return true;
    }
    return false;
  };

  const checkUserNftOwnership = async () => {
    try {
      let status;
      const today = new Date();
      const age =
        (today.valueOf() - champBetOnwership.lastCheckedTime.valueOf()) /
        3600000;
      if (age > 1) {
        const response = await servicesApis.user.getUserNFts('NFT_CHAMP_BET');
        const hasNft = response.has_ownership;
        const lastCheckedTime = new Date();
        userDispatch({
          type: userActions.SET_NFT_OWNERSHIP,
          payload: {
            ...userState.user,
            champBetOnwership: {
              lastCheckedTime,
              ownership: hasNft
            }
          }
        });
        status = champBetOnwership.ownership;
        return status;
      } else {
        status = champBetOnwership.ownership;
        return status;
      }
    } catch (error) {
      return false;
    }
  };

  const checkDisabled = async () => {
    switch (type) {
      case Type.BATTLE:
        return userHasBalance();

      case Type.TOURNAMENT:
        return checkUserNftOwnership();
      default:
        return true;
    }
  };
  useEffect(() => {
    const fetchDisabledStatus = async () => {
      const checkResult = await checkDisabled();
      setRedirectToStore(!checkResult);
    };

    fetchDisabledStatus();
  }, [userState]);

  return (
    <Card
      width="100%"
      height="100%"
      background="rgba(255, 255, 255, 0.1);"
      padding="25px 23px"
    >
      <S.Content small={small}>
        <S.Image src={getSrc()} alt={getAlt()} small={small} />
        <S.TextWrapper small={small} type={type}>
          <S.Title small={small}>{getTitle()}</S.Title>
          <S.Description small={small}>{getDescription()}</S.Description>
          <S.ButtonWrapper small={small}>
            <GeneralButton
              variant="primary"
              size={getButtonSize()}
              onClick={getOnClick()}
              id={getButtonId()}
            >
              {getLabelButton()}
            </GeneralButton>
            {redirectToStore && (
              <S.BuyMatchCoinText onClick={checkOnClick}>
                {getDescriptionText()}
              </S.BuyMatchCoinText>
            )}
          </S.ButtonWrapper>
        </S.TextWrapper>
      </S.Content>
    </Card>
  );
};

export default Banner;
