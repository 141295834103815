import React from 'react';
import { Props } from './@types';
import { ButtonLarge } from './ButtonStyled';

const Button = ({
  type,
  onClick,
  children,
  disabled = false,
  ...rest
}: Props) => {
  return (
    <ButtonLarge type={type} onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </ButtonLarge>
  );
};

export { Button };
