export const amountAndPlatformFilter = {
  amount: 'Quantia em Matchcoin',
  filterIcon: {
    alt: 'Botão de filtro'
  },
  slider: {
    lowestPlaceholder: 'Aposta mínima',
    highPlaceholder: 'Aposta máxima'
  },
  platforms: 'Plataformas',
  gamesFor: 'Jogos para',
  seeAllGames: 'Ver todos os jogos',
  victoryValidation: 'Validador de vitória'
};
