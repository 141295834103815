import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 30px;

  @media (min-width: 768px) {
    padding: 50px;
  }

  @media (min-width: 1170px) {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 96px 1fr;
    grid-template-areas: 'title chat' 'banner chat';
  }
`;
