import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const ContainerButtons = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  margin: 34px auto 0;
  justify-content: space-between;
  @media (max-width: 430px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const Title = styled.h2`
  line-height: 125%;
  font-style: normal;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.title.default};
  font-weight: ${({ theme }) => theme.typography.fontWeightBolder};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
  @media (max-width: 430px) {
    text-align: center;
  }
`;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  max-height: 203px;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 50px;
`;
export const Text = styled.p`
  max-width: 300px;
  line-height: 130%;
  text-align: center;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.title.default};
  font-size: ${({ theme }) => theme.typography.fontSize + 0.2}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  margin-bottom: 24px;
`;
