/* eslint-disable @typescript-eslint/no-explicit-any */
import { Team } from 'pages/CreateBattle/@types';
import { Actions } from './@types/actions';

const battleReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.SET_STEP:
      return {
        ...state,
        battle: {
          ...state.battle,
          step: action.payload.step
        }
      };

    case Actions.SELECT_TEAM:
      return {
        ...state,
        battle: {
          ...state.battle,
          teamSelected: action.payload.team
        }
      };

    case Actions.SELECT_GAME:
      return {
        ...state,
        battle: {
          ...state.battle,
          game: action.payload.game
        }
      };

    case Actions.SAVE_PLATFORM_FOR_SELECTED_GAME:
      return {
        ...state,
        battle: {
          ...state.battle,
          nickname: action.payload.nickname,
          platform: action.payload.platform
        }
      };

    case Actions.CREATE_NEW_TEAM:
      return {
        ...state,
        battle: {
          ...state.battle,
          teams: [...state.battle.teams, action.payload.team]
        }
      };

    case Actions.SET_TEAMS:
      return {
        ...state,
        battle: {
          ...state.battle,
          teams: action.payload.teams
        }
      };

    case Actions.UPDATE_TEAM:
      return {
        ...state,
        battle: {
          ...state.battle,
          teams: [
            ...state.battle.teams.filter(
              (team: Team) => team.entity_id !== action.payload.team.entity_id
            ),
            action.payload.team
          ]
        }
      };

    case Actions.TOGGLE_CREATE_A_BET_MODAL:
      return {
        ...state,
        battle: {
          ...state.battle,
          createABetModal: {
            open: !state.battle.createABetModal.open
          }
        }
      };

    case Actions.SET_BET_AMOUNT:
      return {
        ...state,
        battle: {
          ...state.battle,
          amount: action.payload.amount
        }
      };

    case Actions.SET_FRIEND_CHALLENGER:
      return {
        ...state,
        battle: {
          ...state.battle,
          challenger: {
            friend: action.payload.friend,
            team: null
          }
        }
      };

    case Actions.SET_TEAM_CHALLENGER:
      return {
        ...state,
        battle: {
          ...state.battle,
          challenger: {
            friend: action.payload.friend,
            team: action.payload.team
          }
        }
      };

    case Actions.SET_LOADING:
      return {
        ...state,
        battle: {
          ...state.battle,
          loading: action.payload.loading
        }
      };

    case Actions.SET_MY_BATTLES:
      return {
        ...state,
        battle: {
          ...state.battle,
          myBattles: action.payload.myBattles,
          myBattlesHistory: action.payload.myBattlesHistory
        }
      };
    case Actions.RESET_BATTLE_ALONE:
      return {
        ...state,
        battle: {
          ...state.battle,
          game: {
            created_by: null,
            status: '',
            entity_id: '',
            platforms: [],
            name: '',
            image_url: ''
          },
          platform: null,
          challenger: {
            ...state.battle.challenger,
            friend: null
          },
          amount: 0,
          step: 1
        }
      };

    case Actions.SET_CURRENT_BATTLE:
      return {
        ...state,
        battle: {
          ...state.battle,
          currentBattle: action.payload.currentBattle
        }
      };

    case Actions.RESET_CURRENT_BATTLE:
      return {
        ...state,
        battle: {
          ...state.battle,
          currentBattle: {
            entity_id: '',
            inProgress: false,
            playerOne: {
              name: '',
              image_url: ''
            },
            playerTwo: {
              name: '',
              image_url: ''
            }
          }
        }
      };

    default:
      return {};
  }
};

export default battleReducer;
