/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
import {
  DateRange,
  LocalizationProvider,
  StaticDateRangePicker
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { getImage } from 'assets/img';

import Card from 'components/modules/Card/Card';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { servicesApis } from 'service/service.api';
import { t } from 'translate/i18n';
import { Matchbet } from '..';
import * as S from './AccountStyled';
import { LineChart } from './components/LineChart';
import { RecentsTransactions } from './components/RecentsTransactions';
import { Recharge, Transfer, Withdraw, YourWallet } from './components/Wallet';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Account = () => {
  const [showCalendar] = useState(false);
  const [showContentWallet, setShowContentWallet] = useState({
    Withdraw: false,
    Transfer: false,
    Recharge: false
  });
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
  const [transactions, setTransactions] = useState([]);
  const [updateTransactions, setUpdateTransactions] = useState(true);

  const navigation = useNavigate();

  const getTransactions = async () => {
    const response = await servicesApis.user.getTransactions();
    if (response?.success) {
      setTransactions(response.data);
    } else {
      navigation('/completesignup', { replace: true });
    }
  };

  const callbackTransfer = () => {
    setUpdateTransactions(true);
  };

  useEffect(() => {
    if (updateTransactions) {
      getTransactions();
      setUpdateTransactions(false);
    }
  }, [updateTransactions]);

  const translationString = 'components.account';

  return (
    <Matchbet>
      <S.AccountContainer>
        <S.ContentContainer>
          {showContentWallet.Withdraw && (
            <Withdraw
              close={() => {
                setShowContentWallet({
                  Withdraw: false,
                  Transfer: false,
                  Recharge: false
                });
              }}
            />
          )}

          {showContentWallet.Transfer && (
            <Transfer
              callback={callbackTransfer}
              close={() => {
                setShowContentWallet({
                  Withdraw: false,
                  Transfer: false,
                  Recharge: false
                });
              }}
            />
          )}

          {showContentWallet.Recharge && (
            <Recharge
              close={() => {
                setShowContentWallet({
                  Withdraw: false,
                  Transfer: false,
                  Recharge: false
                });
              }}
            />
          )}

          <S.WalletContainer>
            <S.Container>
              <YourWallet />
              <S.ContainerCards>
                <Card
                  width="166px"
                  minWidth="166px"
                  height="fit-content"
                  padding="22px 16px"
                  background="rgba(255, 255, 255, 0.1)"
                >
                  <S.AccountCardsImage
                    src={getImage('Wallet')}
                    alt="Carteira"
                  />
                  <S.Title size="1.6" margin="0 0 12px 0">
                    {t(`${translationString}.transfer.title`)}
                  </S.Title>
                  <S.SubTitle>
                    {t(`${translationString}.transfer.description`)}
                  </S.SubTitle>
                  <S.Button
                    onClick={() =>
                      setShowContentWallet({
                        Withdraw: false,
                        Transfer: true,
                        Recharge: false
                      })
                    }
                  >
                    {t(`${translationString}.transfer.button`)}
                  </S.Button>
                </Card>
                <Card
                  width="166px"
                  minWidth="166px"
                  height="fit-content"
                  padding="22px 16px"
                  background="rgba(255, 255, 255, 0.1)"
                >
                  <S.AccountCardsImage
                    src={getImage('Withdraw')}
                    alt="Sacar dinheiro"
                  />
                  <S.Title size="1.6" margin="0 0 12px 0">
                    {t(`${translationString}.withdraw.title`)}
                  </S.Title>
                  <S.SubTitle>
                    {t(`${translationString}.withdraw.description`)}
                  </S.SubTitle>
                  <S.Button
                    disabled
                    noActive
                    onClick={() =>
                      setShowContentWallet({
                        Withdraw: true,
                        Transfer: false,
                        Recharge: false
                      })
                    }
                  >
                    {t(`${translationString}.withdraw.button`)}
                  </S.Button>
                </Card>
                <Card
                  width="166px"
                  minWidth="166px"
                  height="fit-content"
                  padding="22px 16px"
                  background="rgba(255, 255, 255, 0.1)"
                >
                  <S.AccountCardsImage
                    src={getImage('Transfer')}
                    alt="Transferir"
                  />
                  <S.Title size="1.6" margin="0 0 12px 0">
                    {t(`${translationString}.wallet.title`)}
                  </S.Title>
                  <S.SubTitle>
                    {t(`${translationString}.wallet.description`)}
                  </S.SubTitle>
                  <S.Button
                    disabled
                    noActive
                    onClick={() =>
                      setShowContentWallet({
                        Withdraw: false,
                        Transfer: false,
                        Recharge: true
                      })
                    }
                  >
                    {t(`${translationString}.wallet.button`)}
                  </S.Button>
                </Card>
              </S.ContainerCards>
            </S.Container>
          </S.WalletContainer>
          <S.TextContainer>
            {/* <Chart /> */}

            {/* Just one of them is shown depending on css breakpoints  */}
            {/* <S.ChartInitialMobile src={images.grafico_mobile} />

          <S.ChartInitialDesktop src={images.grafico_desktop} /> */}
            <LineChart />
          </S.TextContainer>

          <S.TransactionsContainer>
            <RecentsTransactions transactions={transactions} />
          </S.TransactionsContainer>
        </S.ContentContainer>
        <S.Calendar showCalendar={showCalendar}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={value}
              onChange={(newValue: React.SetStateAction<DateRange<Date>>) => {
                setValue(newValue);
              }}
              renderInput={(
                startProps: JSX.IntrinsicAttributes & TextFieldProps,
                endProps: JSX.IntrinsicAttributes & TextFieldProps
              ) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
        </S.Calendar>
      </S.AccountContainer>
    </Matchbet>
  );
};
