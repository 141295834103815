import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { t } from 'translate/i18n';

import icons from 'assets/img/icons';

import Date from 'react-date-picker';

import { Props } from './@types';
import * as S from './DatePickerStyled';

const DatePicker: React.FC<Props> = ({
  value = null,
  disabled = false,
  onChange = () => {},
  minDate
}) => {
  const translationString = 'components.datePicker';

  const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker {
      width: 195px;
      height: 65px;
      border-radius: 13px;
      border: 0;
      border: 1px solid #FFB620;
      color: white;
    }

    .react-date-picker__wrapper {
      border: 0 !important;
      padding: 10px;
    }

    .react-date-picker__inputGroup {
      font-size: 1.6rem;
    }

    .react-date-picker__inputGroup > input {
      color: white;
    }

    .react-date-picker--disabled {
      background-color: unset !important;
      opacity: 0.5;
    }
`;

  return (
    <>
      <DatePickerWrapperStyles />
      <Date
        className={['date_picker']}
        onChange={onChange}
        value={value}
        disabled={disabled}
        calendarIcon={
          <S.Icon
            src={icons.calendarYellow}
            alt={t(`${translationString}.calenderIcon.alt`)}
          />
        }
        clearIcon={
          <S.Icon
            src={icons.closeYellow}
            alt={t(`${translationString}.clearIcon.alt`)}
            marginTop="2px"
          />
        }
        minDate={minDate}
      />
    </>
  );
};

export default DatePicker;
