import React, {
  MutableRefObject,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import images from 'assets/img';

import { UserContext } from 'state/user/state';

import { servicesApis } from 'service/service.api';

import { Message, Messages, Participants } from 'models/Chat';
import { t } from 'translate/i18n';
import { Props } from './@types';

import { Editor } from '../Editor';

import * as S from './MessengerStyled';

const Messenger: React.FC<Props> = ({ battleId, showOnMobile = true }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [messagesLength, setMessagesLength] = useState<number>(0);
  const [participants, setParticipants] = useState<Participants[]>([]);
  const [hasNewMessages, setHasNewMessages] = useState<boolean>(false);

  const { state: userState } = useContext(UserContext);

  const listRef = useRef() as MutableRefObject<HTMLDivElement>;

  const scrollToEnd = (): void => {
    if (listRef) {
      listRef.current.scroll(0, listRef.current.scrollHeight);
    }
  };

  const getMessages = async (interval?: NodeJS.Timer): Promise<void> => {
    const data: Messages = await servicesApis.chat.getMessages(battleId);
    setParticipants(data.participants);

    const hasOnBottomOfChat =
      Math.abs(
        listRef.current.scrollHeight -
          listRef.current.clientHeight -
          listRef.current.scrollTop
      ) < 1;
    if (data.chat.length > messagesLength) {
      setMessages(data.chat);

      setMessagesLength(data.chat.length);
      if (!hasOnBottomOfChat) setHasNewMessages(true);
      if (interval) clearInterval(interval);
      if (hasOnBottomOfChat) scrollToEnd();
    }
  };

  const poolingMessages = (): NodeJS.Timer => {
    const SECOND = 1000;

    const interval = setInterval(async () => {
      await getMessages(interval);
    }, 3 * SECOND);

    return interval;
  };

  useEffect(() => {
    const interval = poolingMessages();

    return () => clearInterval(interval);
  }, [messagesLength]);

  useEffect(() => {
    const getFirstMessages = async (): Promise<void> => {
      await getMessages();
      scrollToEnd();
    };

    getFirstMessages();
  }, []);

  const goToNewMessages = (): void => {
    setHasNewMessages(false);
    scrollToEnd();
  };

  const onScrollList = (): void => {
    const hasBeenTotallyScrolled =
      Math.abs(
        listRef.current.scrollHeight -
          listRef.current.clientHeight -
          listRef.current.scrollTop
      ) < 1;

    if (hasBeenTotallyScrolled) {
      setHasNewMessages(false);
    }
  };

  const addSenderMessageToMessagesOnSend = (message: string): void => {
    const HALF_SECOND = 500;

    const newMessage: Message = {
      created_at: new Date().toISOString(),
      message,
      sender_id: userState.user.entityId
    };

    setMessages([...messages, newMessage]);
    setMessagesLength(messages.length + 1);
    setHasNewMessages(false);
    setTimeout(() => scrollToEnd(), HALF_SECOND);
  };

  const renderMessage = (message: Message): ReactElement => {
    const mine = message.sender_id === userState.user.entityId;

    const participant: Participants | undefined = participants.find(
      (one: Participants) =>
        (one.created_by || one.entity_id) === message.sender_id
    );

    return (
      <S.Message mine={mine}>
        <S.Photo src={participant?.image_url || images.defaultImage} />
        <S.Column>
          <S.Name>{participant?.name || ''}</S.Name>
          <S.Text mine={mine}>{message.message}</S.Text>
        </S.Column>
      </S.Message>
    );
  };

  const translationString = 'components.tournament.messenger';

  return (
    <S.Container showOnMobile={showOnMobile}>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.MessagesList ref={listRef} onScroll={onScrollList}>
        {messages.map(renderMessage)}
      </S.MessagesList>
      {hasNewMessages ? (
        <S.NewMessages onClick={goToNewMessages}>
          {t(`${translationString}.badget.label`)}
        </S.NewMessages>
      ) : null}
      <Editor
        battleId={battleId}
        onSendMessage={addSenderMessageToMessagesOnSend}
      />
    </S.Container>
  );
};

export default Messenger;
