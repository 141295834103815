import images from 'assets/img';
import { Friend } from 'models/Friends';

const Friends: Friend[] = [
  {
    teams: [''],
    entity_id: '1',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '2',
    checked: false,
    apelido: 'paulocont',
    image_url: images.playerTwo
  },
  {
    teams: [''],
    entity_id: '3',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '4',
    checked: false,
    apelido: 'paulocont',
    image_url: images.playerTwo
  },
  {
    teams: [''],
    entity_id: '5',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '3',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '3',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '3',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '3',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  },
  {
    teams: [''],
    entity_id: '3',
    checked: false,
    apelido: 'acm_medrado',
    image_url: images.playerOne
  }
];

export const Solicitations: Friend[] = [
  // {
  //   teams: [''],
  //   entity_id: '1',
  //   checked: false,
  //   apelido: 'ana_smurf',
  //   image_url: images.playerOne
  // },
];

export default Friends;
