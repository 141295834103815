import React from 'react';

import BattleProvider from './battle/state';
import TournamentProvider from './tournament/state';
import MenuProvider from './menu/state';
import NFTProvider from './nft/state';
import NotificationProvider from './notification/state';
import ProfileNotificationsProvider from './profileNotifications/state';
import ProfileSettingsProvider from './profileSettings/state';
import UserProvider from './user/state';
import FeatureControlProvider from './featureControl/state';
import AuthProvider from './auth/state';
import ErrorProvider from './errorCodes/state';

type ProviderType = {
  children: React.ReactElement | React.ReactElement[];
};

const Provider: React.FC<ProviderType> = ({ children }) => {
  return (
    <AuthProvider>
      <ErrorProvider>
        <FeatureControlProvider>
          <MenuProvider>
            <ProfileNotificationsProvider>
              <NFTProvider>
                <BattleProvider>
                  <TournamentProvider>
                    <NotificationProvider>
                      <ProfileSettingsProvider>
                        <UserProvider>{children}</UserProvider>
                      </ProfileSettingsProvider>
                    </NotificationProvider>
                  </TournamentProvider>
                </BattleProvider>
              </NFTProvider>
            </ProfileNotificationsProvider>
          </MenuProvider>
        </FeatureControlProvider>
      </ErrorProvider>
    </AuthProvider>
  );
};

export default Provider;
