import icons from 'assets/img/icons';
import { Card } from 'components/modules';
import React from 'react';
import { Props } from './@types';

import * as S from './SearchStyled';

const Search: React.FC<Props> = ({
  placeholder = 'Search',
  inputRef = undefined,
  onChange = () => {}
}) => {
  return (
    <Card
      width="100%"
      maxWidth="276px"
      height="46px"
      padding="13px 17px"
      background="rgba(255, 255, 255, 0.1);"
      id="search"
    >
      <S.Content>
        <S.Icon src={icons.search} />
        <S.Field
          placeholder={placeholder}
          id="search-input"
          ref={inputRef}
          onChange={(event) => onChange(event.target.value)}
        />
      </S.Content>
    </Card>
  );
};

export default Search;
