/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './@types/actions';

const nftStoreListReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.UPDATE_ALL_NFT_LIST:
      return {
        ...state,
        nft_store_item_selected:
          action.payload.nft_store_items &&
          action.payload.nft_store_items.length > 0
            ? { ...action.payload.nft_store_items[0] }
            : {},
        nft_store_items: action.payload.nft_store_items,
        user_nft_selected:
          action.payload.user_nfts && action.payload.user_nfts.length > 0
            ? { ...action.payload.user_nfts[0] }
            : {},
        user_nfts: action.payload.user_nfts,
        reload: false
      };

    case Actions.SELECT_USER_NFT:
      return {
        ...state,
        user_nft_selected: action.payload
      };

    case Actions.SELECT_NFT_STORE_ITEM:
      return {
        ...state,
        nft_store_item_selected: action.payload
      };

    case Actions.UPDATE_NFT_STORE_ITEM_LIST:
      return {
        ...action.payload,
        nft_store_item_selected: {
          ...state.nft_store_item_selected
        }
      };

    case Actions.UPDATE_NFT_USER_LIST:
      return {
        ...state,
        user_nfts: action.payload
      };

    case Actions.RELOAD:
      return {
        ...state,
        reload: true
      };

    default:
      return {};
  }
};

export default nftStoreListReducer;
