import styled from 'styled-components';

const CardContainer = styled.div<{
  width: string;
  height: string;
  padding: string;
  background: string;
  borderRadius?: string;
  maxWidth?: string;
  minWidth?: string;
}>`
  display: flex;
  border-radius: ${(props) => props.borderRadius};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: ${(props) => props.width};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  min-width: ${({ minWidth }) => minWidth && minWidth};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.background};
`;
const Content = styled.div``;
export { CardContainer, Content };
