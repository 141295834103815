import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

const SignInContainer = styled.div<{ confirmCode?: boolean }>`
  float: right;
  display: flex;
  margin-right: 12%;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  padding: 42px;
  overflow-x: hidden;
  background: rgba(255, 255, 255, 0.1);
  border: ${(props) => (props.confirmCode ? '2px solid #ffffff61' : 'none')};
  @media (max-width: 1440px) {
    margin-right: 5%;
  }

  @media (max-width: 1440px) {
    margin-right: 5%;
  }
  ${media.lessThan('medium')`
    width: 100%;
    height: auto;
    min-height: 100vh;
    margin-right: 0;
    padding: 35px 30px;
    background: rgba(11, 11, 34, 0.9);
  `}
`;
const BackgroundImage = styled.img`
  display: none;
  ${media.lessThan('medium')`
    display: block;
    width: 115%;
    height: auto;
    margin-top: -35px;
    margin-bottom: -120px;
`}
`;
const Logo = styled.img`
  width: 100%;
  max-width: 191px;
  max-height: 62px;
  margin-bottom: 42px;
`;

const Text = styled.p`
  gap: 4px;
  display: flex;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.6);
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const BlueLink = styled(Link)`
  color: ${(props) => props.theme.palette.text.lightBlue};
  display: flex;
  cursor: pointer;
  text-decoration: underline;
`;
export { SignInContainer, Logo, Text, BlueLink, BackgroundImage };
