import { CompetitorsProps, GameProps } from 'pages/YourHistory/@types';
import { Game } from '../components/SelectGamer/@types';
import { Platform } from '../components/SelectPlatform/@types';

export type User = {
  name: string;
  image_url: string;
  entity_id: string;
};

export type Player = {
  user: User;
  position: string;
  battles_won: string;
  tournaments_won: string;
  elo_points: string;

  result: string;
  game: GameProps;
  end_date: string;
  bet_value: string;
  start_date: string;
  competitors: CompetitorsProps;
};

export type yourHistory = {
  battles: number;
  image_url: string;
  entity_id: string;
  tournaments: number;
  battles_won: number;
  tournaments_won: number;
  elo_points: number;
  place_in_the_ranking: number;
};

export enum OrdenationType {
  end_date = 'end_date',
  bet_value = 'bet_value',
  start_date = 'start_date',
  battles_won = 'battles_won',
  tournaments_won = 'tournaments_won',
  elo_points = 'elo_points'
}

export enum HistoryEnum {
  tournaments = 'tournaments',
  battles_won = 'battles_won',
  tournaments_won = 'tournaments_won',
  elo_points = 'elo_points',
  place_in_the_ranking = 'place_in_the_ranking'
}

export type StyleProps = {
  size?: string;
  click?: boolean;
};
export type globalRankingResponse = {
  ranking: Player[];
  history_stats: yourHistory;
  games: Game[];
  platforms: Platform[];
};

export type StyleStatusProps = {
  gap?: string;
  size?: string;
  status?: string;
  click?: boolean;
};
