import { EntityPlatform } from 'models/Games';
import React from 'react';
import { t } from 'translate/i18n';
import { v4 } from 'uuid';
import { Props } from './@types';

import * as S from './PlatformsStyled';

const Platforms: React.FC<Props> = ({ platforms }) => {
  const translationString = 'components.createBattle.platforms';

  const renderPlatform = (platform: EntityPlatform): React.ReactElement => {
    return <S.Icon key={v4()} src={platform.image_url} />;
  };

  return (
    <S.Container>
      <S.Description>{t(`${translationString}.onThePlatform`)}</S.Description>
      <S.PlatformIcons>{platforms?.map(renderPlatform)}</S.PlatformIcons>
    </S.Container>
  );
};

export default Platforms;
