import React, { ChangeEvent, useContext, useState } from 'react';

import { useFormik } from 'formik';
import { utils } from 'ethers';

import { UserContext } from 'state/user/state';
import { useTournament } from 'state/tournament/hooks';

import icons from 'assets/img/icons';

import { GeneralButton } from 'components/elements/GeneralButton';
import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';

import { battleSchema, tournamentSchema } from './BetAmountSchema';

import * as S from './BetAmountStyled';

import { Props, Type } from './@types';
import { useTranslations } from './hooks/useTranslations';

const BetAmount: React.FC<Props> = ({
  type,
  isError,
  errorMessage,
  onClose = () => {},
  onSubmit = () => {}
}) => {
  const { isTournamentOpen } = useTournament();
  const { state } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { translations } = useTranslations(type);

  const formik = useFormik({
    initialValues: {
      amount: '',
      password: ''
    },
    onSubmit,
    validationSchema: type === Type.BATTLE ? battleSchema : tournamentSchema
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const stringValue = e.target.value.replace(/[^0-9.]/g, '');

    if (!e.target.value || !stringValue) {
      formik.setFieldValue('amount', '');
      return;
    }

    const balance = utils.parseEther(state.user.balance);
    const value = utils.parseEther(stringValue);
    const isBalanceEnough = balance.gte(value);

    if (!isBalanceEnough) {
      formik.setFieldValue('amount', state.user.balance);
    } else {
      formik.setFieldValue('amount', stringValue);
    }
  };

  const onCloseModal = (): void => {
    onClose();
    formik.resetForm();
  };

  return (
    <S.Container>
      <S.Title>{translations.title}</S.Title>
      <S.SubTitle>{translations.subtitle}</S.SubTitle>
      <S.Form onSubmit={() => formik.handleSubmit}>
        <S.InputWrapper error={!!formik.errors.amount && formik.touched.amount}>
          <S.Field
            id="amount"
            type="text"
            placeholder={translations.amountPlaceholder}
            onChange={onChange}
            value={formik.values.amount || ''}
            autoComplete="off"
          />

          <div>
            <S.Text>
              <S.Initials>{translations.balanceLabel}</S.Initials>
              <S.Initials yellow>
                <ExtendedNumberBox>{state.user.balance}</ExtendedNumberBox>
              </S.Initials>
            </S.Text>
            <S.SeePassIcon
              src={icons.amount}
              alt={translations.seeBalance}
              error={!!formik.errors.amount && formik.touched.amount}
            />
          </div>
        </S.InputWrapper>
        {type === Type.BATTLE ? (
          <S.InputWrapper
            error={
              (!!formik.errors.password && formik.touched.password) ||
              !!errorMessage
            }
          >
            <S.Field
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={translations.password}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <S.SeePassIcon
              src={icons.eye}
              alt={translations.seePassword}
              show={showPassword}
              error={!!formik.errors.password && formik.touched.password}
              onClick={() => setShowPassword(!showPassword)}
            />
          </S.InputWrapper>
        ) : null}

        {isError ? (
          <S.TextError>{isError}</S.TextError>
        ) : (
          <S.TextError> {errorMessage}</S.TextError>
        )}
      </S.Form>
      <S.Description>{translations.description}</S.Description>

      <S.ButtonsWrapper>
        <GeneralButton
          transparent
          type="button"
          size="medium"
          variant="primary"
          onClick={() => onCloseModal()}
          id="cancel"
        >
          {translations.cancel}
        </GeneralButton>
        <GeneralButton
          type="submit"
          size="medium"
          variant="primary"
          isLoading={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
          id="create"
        >
          {isTournamentOpen ? translations.createTournament : translations.next}
        </GeneralButton>
      </S.ButtonsWrapper>
    </S.Container>
  );
};

export default BetAmount;
