/* eslint-disable no-console */
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Stack, TextField } from '@mui/material';
import { getImage } from 'assets/img';
import { API } from 'aws-amplify';
import { servicesApis } from 'service/service.api';
import { AuthContext } from 'state/auth/state';
import { MenuContext } from 'state/menu/state';
import { Actions as AuthActions } from 'state/auth/@types/actions';
import { Actions as MenuActions } from 'state/menu/@types/actions';
import { CustomCircularProgress } from 'components/modules';
import config from 'config/constants';
import { ENDPOINTS } from 'config/endpoints';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'translate/i18n';
import { CompleteSignUpType } from './@types';
import completeSignUpSchema from './CompleteSignUpSchema';
import * as S from './CompleteSignUpStyled';

const CompleteSignUp = () => {
  const [load, setLoad] = useState(false);
  const navigation = useNavigate();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { dispatch: menuDispatch } = useContext(MenuContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state }: any = useLocation();
  const [hideLabel, setHideLabel] = useState('');
  const invitationCode = localStorage.getItem('invitation_code');
  const translationString = 'components.completeSignup';

  const postData = async (data: object) => {
    const userData = {
      body: data
    };

    return API.post(config.APIS.MATCHBET, ENDPOINTS.user.postUser, userData);
  };

  async function completedSignUp(values: CompleteSignUpType) {
    setLoad(true);
    try {
      const data = {
        email: values.email,
        telefone: values.phone,
        apelido: values.nickname,
        nascimento: values.birthDate,
        invitation_code: invitationCode
      };
      postData(data).then(async () => {
        await servicesApis.user.postFinancePassword(
          '',
          values.financialPassword
        );
        authDispatch({
          type: AuthActions.USER_IS_SIGNED
        });
        menuDispatch({
          type: MenuActions.SET_MENU,
          payload: {
            selected: 'tournaments'
          }
        });
        setLoad(false);
        navigation('/matchbet/tournaments', { replace: true });
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  const formik = useFormik<CompleteSignUpType>({
    initialValues: {
      email: state.email,
      phone: '',
      nickname: '',
      birthDate: '',
      financialPassword: ''
    },
    onSubmit: (values) => {
      completedSignUp(values);
    },
    validationSchema: completeSignUpSchema
  });

  return (
    <S.CompleteSignUpContainer>
      <S.Logo src={getImage('Logo')} alt="Logo matchbet" />

      <S.Form onSubmit={formik.handleSubmit}>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.Container>
          <S.ContainerInput>
            <TextField
              disabled
              id="email"
              value={formik.values.email}
              label={t(`${translationString}.email`)}
              variant="outlined"
              autoComplete="off"
              sx={{
                '& legend': {
                  fontSize: '1.2rem'
                }
              }}
            />
          </S.ContainerInput>
          <S.ContainerInput
            error={formik.touched.nickname && Boolean(formik.errors.nickname)}
          >
            <TextField
              id="financialPassword"
              type="password"
              label="Senha Financeira"
              variant="outlined"
              autoComplete="off"
              sx={{
                '& legend': {
                  fontSize: '1.2rem'
                }
              }}
              value={formik.values.financialPassword}
              onChange={formik.handleChange}
              error={formik.touched.nickname && Boolean(formik.errors.nickname)}
              helperText={
                formik.touched.financialPassword &&
                formik.errors.financialPassword
              }
            />
          </S.ContainerInput>
          <S.ContainerInput
            error={formik.touched.nickname && Boolean(formik.errors.nickname)}
          >
            <TextField
              label={t(`${translationString}.nickname`)}
              variant="outlined"
              autoComplete="off"
              id="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
              sx={{
                '& legend': {
                  fontSize: '1.2rem'
                }
              }}
              error={formik.touched.nickname && Boolean(formik.errors.nickname)}
              helperText={formik.touched.nickname && formik.errors.nickname}
            />
          </S.ContainerInput>
          <S.ContainerInput
            error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DatePicker
                  label={t(`${translationString}.birthdate`)}
                  inputFormat="DD/MM/YYYY"
                  value={formik.values.birthDate}
                  onChange={(newValue) => {
                    formik.setFieldValue('birthDate', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& legend': {
                          fontSize: '1.2rem'
                        }
                      }}
                      {...params}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </S.ContainerInput>
          <S.ContainerInput
            error={formik.touched.phone && Boolean(formik.errors.phone)}
          >
            <PhoneInput
              country="br"
              value={formik.values.phone}
              onChange={(newValue) => {
                formik.setFieldValue('phone', newValue);
                setHideLabel(newValue);
              }}
              isValid={(value) => {
                if (
                  value.match(/12345/ || /1234/ || /''/) ||
                  value.length < 13
                ) {
                  return 'Invalid Phone number';
                }
                return true;
              }}
            />
            <S.Label show={hideLabel.length > 2}>
              {t(`${translationString}.phone`)}
            </S.Label>
          </S.ContainerInput>
        </S.Container>
        <S.Register submit>
          {load ? (
            <Box>
              <CustomCircularProgress />
            </Box>
          ) : (
            t(`${translationString}.complete`)
          )}
        </S.Register>
      </S.Form>
    </S.CompleteSignUpContainer>
  );
};

export default CompleteSignUp;
