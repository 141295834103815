import Card from 'components/modules/Card/Card';
import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  border-radius: 12px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  ${media.lessThan('medium')`
    padding: 0;
    background-color: transparent;
  `}
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.h2`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 27px;
  color: ${({ theme }) => theme.palette.title.default};
  text-align: center;
  width: 100%;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const List = styled.ul`
  overflow-y: scroll;

  flex-grow: 1;
  margin-top: 22px;

  &::-webkit-scrollbar {
    width: 0;
  }

  div.list-item + div.list-item {
    margin-top: 6px;
  }
`;

export const TransactionCard = styled(Card).attrs(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  background: `${theme.palette.background.lightBlur}`,
  padding: '8px',
  borderRadius: '6px'
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ComplementaryInfoContainer = styled.div`
  width: 100%;
  margin-top: 2%;
`;

export const TransactionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
`;

export const TransactionType = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const TransactionDetail = styled.div`
  display: flex;
  align-items: center;
`;

export const TransactionDate = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 251;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.palette.text.grey};
  margin: 0 4px;
`;

export const TransactionFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusIndicator = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: ${({ theme }) => theme.palette.transaction.white};

  &.green {
    background-color: ${({ theme }) => theme.palette.transaction.green};
  }

  &.yellow {
    background-color: ${({ theme }) => theme.palette.transaction.yellow};
  }

  &.red {
    background-color: ${({ theme }) => theme.palette.transaction.red};
  }
`;

export const TransactionStatus = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 251;
  font-size: 1.2rem;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.transaction.white};
  text-transform: uppercase;
  margin-left: 6px;
`;

export const TransactionPrice = styled.p`
  display: flex;
  gap: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 18px;
  margin-left: 15px;
  color: ${({ theme }) => theme.palette.transaction.white};
`;

export const EmptyText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.transaction.white};
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TypeTransaction = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const FromToContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`;

export const FromToText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const UserInfo = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 1rem;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.white};
  margin-left: 2%;
`;

export const UserInfoContainer = styled.div`
  display: flex;
`;
