/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from 'components/elements';
import { GeneralButton } from 'components/elements/GeneralButton';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { t } from 'translate/i18n';
import { servicesApis } from 'service/service.api';
import { getErrorStringName } from 'utils/errorHandling';
import { useModal } from 'components/modules/Modal';
import { inviteAFriendSchema } from './InviteAFriendSchema';

import * as S from './InviteAFriendStyled';

const InviteAFriend: React.FC = () => {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const translationString = 'components.friends.inviteAFriend';

  const inviteFriend = async (email: string) => {
    setLoading(true);
    try {
      await servicesApis.friends.postFriendsRequest(email);
      closeModal();
    } catch (error: any) {
      const errorCode = await getErrorStringName(
        error.response.data.error_code
      );
      const message = t(
        `${translationString}.error.${errorCode.toLowerCase()}`
      );
      // eslint-disable-next-line no-use-before-define
      formik.setFieldError('email', message);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: ({ email }) => {
      inviteFriend(email);
    },
    validationSchema: inviteAFriendSchema
  });

  return (
    <S.Container>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.Description
        dangerouslySetInnerHTML={{
          __html: t(`${translationString}.description`)
        }}
      />
      <S.Form onSubmit={formik.handleSubmit}>
        <S.InputWrapper>
          <Input
            type="email"
            id="email"
            placeholder={t(`${translationString}.inputPlaceholder`)}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && <S.Error>{formik.errors.email}</S.Error>}
        </S.InputWrapper>
        <GeneralButton
          isLoading={loading}
          type="submit"
          size="large"
          variant="primary"
        >
          {t(`${translationString}.submitButton`)}
        </GeneralButton>
      </S.Form>
    </S.Container>
  );
};

export default InviteAFriend;
