import styled, { css } from 'styled-components';

const GeneralButtonContainer = styled.button<{
  variant: 'primary' | 'secondary' | 'purple' | 'blue' | 'green';
  onClick?: (e: MouseEvent) => void;
  isLoading?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  type?: string;
  size: 'small' | 'medium' | 'large';
  id?: string;
}>`
  --main-color: ${(props) => {
    switch (props.variant) {
      case 'primary':
        return '#FFB620';
      case 'secondary':
        return '#FFFFFF';
      case 'blue':
        return '#A4D4FE';
      case 'purple':
        return '#8C0FF5';
      case 'green':
        return '#52A569';
      default:
        return '#FFB620';
    }
  }};
  --padding: ${(props) => {
    if (props.size === 'large') {
      return '0 42px';
    }
    if (props.size === 'medium') {
      return '0 34px';
    }
    return '0 21px ';
  }};
  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}

  font-weight: 900;
  box-sizing: border-box;
  transition: all 0.1s linear;
  appearance: none;
  min-width: 100px;
  white-space: nowrap;
  width: max-content;
  font-family: Poppins;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: var(--padding);
  display: flex;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};

  font-size: ${(props) => {
    if (props.size === 'large') {
      return '2.0rem';
    }
    if (props.size === 'medium') {
      return '1.8rem';
    }
    return '1.2rem';
  }};

  border: ${(props) => {
    if (props.variant === 'secondary' || props.disabled)
      return '2px solid #FFFFFF';
    return '2px solid var(--main-color)';
  }};

  height: ${(props) => {
    if (props.size === 'large') {
      return '52px';
    }
    if (props.size === 'medium') {
      return '46px';
    }
    return '30px';
  }};

  background-color: ${(props) => {
    if (props.transparent) return 'transparent';
    if (props.disabled) return '#F9F9F9';
    if (props.variant === 'secondary' && !props.isLoading) return 'transparent';
    return 'var(--main-color)';
  }};
  :hover {
    background: ${(props) => {
      if (props.disabled) return '#F9F9F9';
      if (props.transparent) return 'var(--main-color)';
      if (props.variant === 'secondary' || props.isLoading)
        return 'var(--main-color)';
      return 'transparent';
    }};
    color: ${(props) => {
      if (props.disabled) return '#D9D9D9';
      if (props.transparent) return '#FFFFFF';
      if (props.variant === 'secondary') return '#666666';
      return 'var(--main-color)';
    }};
  }
  color: ${(props) => {
    if (props.disabled) return '#D9D9D9';
    if (props.transparent) return 'var(--main-color)';
    return '#FFFFFF';
  }};
`;

const LoadingContainer = styled.div<{
  isLoading: boolean;
}>`
  position: absolute;
  display: ${(props) => (props.isLoading ? 'flex' : 'none')};
`;

const ChildrenContainer = styled.span<{
  isLoading: boolean;
}>`
  visibility: ${(props) => (props.isLoading ? 'hidden' : 'visible')};
`;

export { GeneralButtonContainer, LoadingContainer, ChildrenContainer };
