import React from 'react';

import { Matchbet } from 'pages/Matchbet';

import { Slider } from 'pages/Battle/components/Slider';
import { Type } from 'components/modules/Banner/@types';
import { Banner } from 'components/modules/Banner';

import * as S from './UserTournamentStyled';
import { OpenGlobalTournaments } from './components/OpenGlobalTournaments';

const UserTournament: React.FC = () => {
  return (
    <Matchbet>
      <S.Container>
        <S.BannerContainer>
          <Banner type={Type.TOURNAMENT} />
        </S.BannerContainer>

        <S.ListContainer>
          <OpenGlobalTournaments />
        </S.ListContainer>

        <S.SliderContainer>
          <Slider type="my_tournaments" />
        </S.SliderContainer>
      </S.Container>
    </Matchbet>
  );
};
export default UserTournament;
