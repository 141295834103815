import icons from 'assets/img/icons';
import {
  OpenTournament,
  ResponseMyTournament,
  ResponseTournamentByID
} from 'models/Tournaments';

export const Tournaments = {
  status: 'success',
  data: [
    {
      name: 'Torneio Matchbet de Valorant',
      stage_infos: {
        time_between_stages: 30,
        ready_timeout: 15,
        stages_per_day: 4,
        stage_time: 8
      },
      entry_fee: 834000000000000000000,
      third_prize: 'n/a',
      max_players: 500,
      created_at: '2022-12-20T22:22:24.352059+00:00',
      status: 'CREATED',
      first_prize: 'US$ 2,000.00',
      tournament_type: 'KNOCKOUT',
      start_date: '2023-01-13T13:00:00+00:00',
      second_prize: 'n/a',
      image_url: 'https://s3.amazonaws.com/dev.piv.matchbet/imagem_torneio.png',
      entity_id: '99958d16-6c25-421b-b1a4-9c5456b9590b',
      rules: 'n/a',
      description: 'Torneio Matchbet de Valorant',
      platform: {
        name: 'PC',
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg',
        entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58'
      },
      game: {
        name: 'PS5',
        image_url: icons.playstation,
        entity_id: (Math.random() * 10000).toString()
      },
      min_players: 999,
      registered_players: 999,
      stages: [
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 0,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 0,
              date: '2023-01-13T13:00:00+00:00',
              status: 'Running',
              player1: {
                name: 'Carlinhos',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Jaja',
                image_url: icons.gameLol
              }
            },
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 0,
              status: 'Completed 2',
              date: '2023-01-13T13:00:00+00:00',
              player1: {
                name: 'Paulinho',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Ana',
                image_url: icons.gameLol
              }
            },
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 0,
              date: '2023-01-13T13:00:00+00:00',
              status: 'Running',
              player1: {
                name: 'Carlinhos',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Jaja',
                image_url: icons.gameLol
              }
            },
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 0,
              status: 'Pending',
              date: '2023-01-13T13:00:00+00:00',
              player1: {
                name: 'Paulinho',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Ana',
                image_url: icons.gameLol
              }
            },
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 0,
              status: 'Completed 3',
              date: '2023-01-13T13:00:00+00:00',
              player1: {
                name: 'Paulinho',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Ana',
                image_url: icons.gameLol
              }
            },
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 0,
              date: '2023-01-13T13:00:00+00:00',
              status: 'Pending',
              player1: {
                name: 'Carlinhos',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Jaja',
                image_url: icons.gameLol
              }
            }
          ]
        },
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 1,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 1,
              status: 'Completed 4',
              date: '2023-01-13T13:00:00+00:00',
              player1: {
                name: 'Paulinho',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Ana',
                image_url: icons.gameLol
              }
            }
          ]
        },
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 2,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 2,
              date: '2023-01-13T13:00:00+00:00',
              status: 'Running',
              player1: {
                name: 'Carlinhos',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Jaja',
                image_url: icons.gameLol
              }
            }
          ]
        },
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 3,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 3,
              status: 'Pending',
              date: '2023-01-13T13:00:00+00:00',
              player1: {
                name: 'Paulinho',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Ana',
                image_url: icons.gameLol
              }
            }
          ]
        },
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 4,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 4,
              date: '2023-01-13T13:00:00+00:00',
              status: 'Completed',
              player1: {
                name: 'Carlinhos',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Jaja',
                image_url: icons.gameLol
              }
            }
          ]
        },
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 5,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 5,
              status: 'Completed 5',
              date: '2023-01-13T13:00:00+00:00',
              player1: {
                name: 'Paulinho',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Ana',
                image_url: icons.gameLol
              }
            }
          ]
        },
        {
          stage_id: (Math.random() * 10000).toString(),
          order: 6,
          battles: [
            {
              battle_id: (Math.random() * 10000).toString(),
              order: 6,
              date: '2023-01-13T13:00:00+00:00',
              status: 'Pending',
              player1: {
                name: 'Carlinhos',
                image_url: icons.gameLol
              },
              player2: {
                name: 'Jaja',
                image_url: icons.gameLol
              }
            }
          ]
        }
      ]
    }
  ]
};

export const MyListTournaments: ResponseMyTournament = {
  status: 'success',
  data: [
    {
      tournament_type: 'KNOCKOUT',
      name: 'Torneio Teste',
      image_url:
        'https://s3.amazonaws.com/dev.matchbet/tournaments/decc/decc3250-a1a2-4be9-b63b-684d511c3c38',
      third_prize: '123',
      min_players: 10,
      max_players: 100,
      entry_fee: 123,
      entity_id: 'ef7e77a8-9a3c-4ae6-a37c-2d115dbc2121',
      game: {
        image_url:
          'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp',
        name: 'Valorant',
        entity_id: 'f2100d17-86ba-4943-89ea-039fb65a865d',
        platform: {
          entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
          name: 'Valorant',
          image_url:
            'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
        },
        created_by: {
          entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
          name: 'Valorant',
          image_url:
            'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
        },
        status: 'active'
      },
      stages: [],
      platform: {
        entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
        name: 'Valorant',
        image_url:
          'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
      },
      second_prize: '123',
      description: 'Teste',
      created_by: 'aa50aaab-fadd-4965-93e5-d19006545b81',
      status: 'WAITING_PLAYERS',
      first_prize: '123',
      tournament_players: [],
      created_at: '2023-03-06T20:59:49.187782+00:00',
      start_date: '2023-03-06T16:32:26.143871+00:00',
      stage_infos: {
        time_between_stages: 12,
        day_end_time: '1900-01-01T23:59:59+00:00',
        stages_per_day: 2,
        stage_time: 12,
        day_start_time: '1900-01-01T00:00:00+00:00',
        ready_timeout: 12
      },
      rules: 'Teste'
    },
    {
      tournament_type: 'KNOCKOUT',
      name: 'Torneio Teste',
      image_url:
        'https://s3.amazonaws.com/dev.matchbet/tournaments/decc/decc3250-a1a2-4be9-b63b-684d511c3c38',
      third_prize: '123',
      min_players: 10,
      max_players: 100,
      entry_fee: 123,
      entity_id: '00993f4d-2ed1-4287-99df-78d7e1b9737e',
      game: {
        image_url:
          'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp',
        name: 'Valorant',
        entity_id: 'f2100d17-86ba-4943-89ea-039fb65a865d',
        platform: {
          entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
          name: 'Valorant',
          image_url:
            'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
        },
        created_by: {
          entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
          name: 'Valorant',
          image_url:
            'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
        },
        status: 'active'
      },
      stages: [],
      platform: {
        entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
        name: 'Valorant',
        image_url:
          'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
      },
      second_prize: '123',
      description: 'Teste',
      created_by: '0dd43e06-cecf-4ff2-855c-0b39597c273f',
      status: 'WAITING_PLAYERS',
      first_prize: '123',
      tournament_players: [],
      created_at: '2023-03-06T21:01:09.919403+00:00',
      start_date: '2023-03-06T16:32:26.143871+00:00',
      stage_infos: {
        time_between_stages: 12,
        day_end_time: '1900-01-01T23:59:59+00:00',
        stages_per_day: 2,
        stage_time: 12,
        day_start_time: '1900-01-01T00:00:00+00:00',
        ready_timeout: 12
      },
      rules: 'Teste'
    }
  ]
};

export const OpenTournamentOne: OpenTournament = {
  entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
  entry_fee: 100,
  max_players: 10,
  // registered: 13,
  platform: {
    name: 'PC',
    image_url:
      'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg',
    entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58'
  },
  game: {
    platform: {
      name: 'PC',
      entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
      image_url:
        'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
    },
    created_by: {
      entity_id: '',
      image_url: '',
      name: ''
    },
    status: 'active',
    name: 'Valorant',
    entity_id: 'f2100d17-86ba-4943-89ea-039fb65a865d',
    image_url:
      'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
  },
  start_date: '2023-02-15T18:00:00.567Z',
  name: 'El torneo',
  tournament_players: [],
  image_url:
    'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
};

export const OpenTournamentTwo: OpenTournament = {
  entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
  entry_fee: 300,
  max_players: 30,
  // registered: 13,
  platform: {
    name: 'PC',
    image_url:
      'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg',
    entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58'
  },
  game: {
    platform: {
      name: 'PC',
      entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
      image_url:
        'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
    },
    created_by: {
      entity_id: '',
      image_url: '',
      name: ''
    },
    status: 'active',
    name: 'Valorant',
    entity_id: 'f2100d17-86ba-4943-89ea-039fb65a865d',
    image_url:
      'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
  },
  start_date: '2023-02-15T18:00:00.567Z',
  name: 'Le tournoi',
  tournament_players: [],
  image_url:
    'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
};

export const OpenTournamentThree: OpenTournament = {
  entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
  entry_fee: 155,
  max_players: 30,
  // registered: 13,
  platform: {
    name: 'PC',
    image_url:
      'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg',
    entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58'
  },
  game: {
    platform: {
      name: 'PC',
      entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
      image_url:
        'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
    },
    created_by: {
      entity_id: '',
      image_url: '',
      name: ''
    },
    status: 'active',
    name: 'Valorant',
    entity_id: 'f2100d17-86ba-4943-89ea-039fb65a865d',
    image_url:
      'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
  },
  start_date: '2023-02-15T18:00:00.567Z',
  name: 'Le gatito',
  tournament_players: [],
  image_url:
    'https://s3.amazonaws.com/dev.piv.matchbet/valorant-closed-beta-1200x675.webp'
};

export const OpenTournaments = [
  OpenTournamentOne,
  OpenTournamentTwo,
  OpenTournamentThree
];

export const selectedPlayers = [
  {
    entity_id: '999582d16-6c25-421b-b1a4-9c54569590b',
    name: 'jaja',
    image_url:
      'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
  },
  {
    entity_id: '9995d16-6c25-421b-b1a4-9c5456b95390b',
    name: 'acm_medrado',
    image_url:
      'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
  },
  {
    entity_id: '99958d16-6c225-421b-b1a4-9c545b9590b',
    name: 'Sergio',
    image_url:
      'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
  },
  {
    entity_id: '99918d16-6c25-421b-b1a4-9c5456b9690b',
    name: 'Daniel',
    image_url:
      'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
  }
];

export const TournamentByID: ResponseTournamentByID = {
  entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
  created_by: '0dd43e06-cecf-4ff2-855c-0b39597c273f',
  entry_fee: '00000.4',
  first_prize: '100',
  start_date: '2023-03-27T14:18:13.674000+00:00',
  image_url:
    'https://s3.amazonaws.com/dev.matchbet/tournaments/decc/decc3250-a1a2-4be9-b63b-684d511c3c38',
  registered_players: 3,
  game: {
    name: 'Valorant',
    image_url:
      'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
  },
  current_match: {
    entity_id: 'cf60e306-28df-42e2-96c5-95141d',
    image_url:
      'https://s3.amazonaws.com/stg.matchbet/games/official/official_lol.jpg',
    player1: {
      nickname: 'Calenzo',
      image_url:
        'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png',
      entity_id: '',
      name: '',
      ready: false
    },
    player2: {
      nickname: 'Sergio',
      image_url:
        'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png',
      entity_id: '',
      name: '',
      ready: false
    },
    status: 'CREATED'
  },
  platform: {
    name: 'PC',
    image_url:
      'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
  },
  stages: [
    {
      order: 1,
      entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
      tournament_battles: [
        {
          entity_id: 'cf60e306-28df-42e2-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-27T14:18:13.674000+00:00',
          end_datetime: '2023-03-30T14:18:13.674000+00:00',
          status: 'waiting_players',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e302-28df-42e2-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-42e2-93c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-14T14:18:13.674000+00:00',
          end_datetime: '2023-03-14T16:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-24df-42e2-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-4252-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e106-28df-42e2-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df442e2-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-42e2-96c5-958513e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e23306-28df-42e2-96c5-958b21228',
            nickname: 'Sergio',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-4232-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf6234306-28df-42e2-96c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-44524596c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-44524596c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-44524596c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-44524596c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-44524596c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        },
        {
          entity_id: 'cf60e306-28df-44524596c5-958b213e0a58',
          player1: {
            entity_id: 'cf60e306-28df-42e2-96c5-958b21228',
            nickname: 'Calenzo',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          player2: {
            entity_id: 'cf60e306-28df-42e2-933c5-958b21228',
            nickname: 'Marcao',
            image_url:
              'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
          },
          validator: null,
          start_datetime: '2023-03-25T14:18:13.674000+00:00',
          end_datetime: '2023-03-27T14:18:13.674000+00:00',
          status: 'started',
          winner_id: 'cf60e306-28df-42e2-933c5-958b21228'
        }
      ]
    }
  ]
};
