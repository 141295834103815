import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import { CustomCircularProgress } from 'components/modules';
import { Formik } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resendVerifyToken, signIn } from 'service/auth.service';
import { servicesApis } from 'service/service.api';
import { Actions } from 'state/auth/@types/actions';
import { Actions as MenuActions } from 'state/menu/@types/actions';
import { AuthContext } from 'state/auth/state';
import { MenuContext } from 'state/menu/state';
import { i18n } from 'translate/i18n';
import { PasswordType } from './@types';
import * as S from './FormStyled';
import SignInSchema from './SignInSchema';

const Form = () => {
  const { dispatch } = useContext(AuthContext);
  const { dispatch: menuDispatch } = useContext(MenuContext);

  const navigation = useNavigate();
  const [passwordShow, setPasswordShow] = useState<PasswordType>({
    showPassword: false
  });

  const [load, setLoad] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');

  const handleClickShowPassword = (password: boolean) => {
    setPasswordShow({
      showPassword: password
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  async function loginStart(email: string, password: string) {
    setLoad(true);
    await signIn(email, password)
      .then(() => {
        // eslint-disable-next-line no-use-before-define
        VerifictionAuthenticatedUser(email);
      })
      .catch((err) => {
        if (err === 'Incorrect username or password.') {
          setLoad(false);
          setMessage(i18n.t('errors.incorrectUserNameOrPassword'));
          setShowMessage(true);
        }
        if (err === 'User is not confirmed.') {
          setLoad(false);
          resendVerifyToken(email);
          navigation('../confirmCode', { replace: true, state: { email } });
        }
        if (err === 'Password attempts exceeded') {
          setLoad(false);
          setMessage(i18n.t('errors.passwordAttemptsExceeded'));
          setShowMessage(true);
        }
        if (err === 'Password attempts exceeded') {
          setLoad(false);
          setMessage(i18n.t('errors.passwordAttemptsExceeded'));
          setShowMessage(true);
        }
      });
  }

  const getUser = async (router: boolean, email: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await servicesApis.user.getUser(router);
    if (response?.success) {
      dispatch({
        type: Actions.USER_IS_SIGNED
      });
      menuDispatch({
        type: MenuActions.SET_MENU,
        payload: {
          selected: 'tournaments'
        }
      });

      navigation(response?.router, { replace: true });
    } else {
      navigation(response?.router, {
        replace: true,
        state: { email }
      });
    }
  };

  const VerifictionAuthenticatedUser = useCallback(
    async (email) => {
      Auth.currentAuthenticatedUser(email)
        .then(() => {
          getUser(true, email);
        })
        .catch((err) => {
          if (err === 'The user is not authenticated') {
            navigation('/', { replace: true });
          }
        });
    },
    [navigation]
  );

  const ForgotPassword = useCallback(
    async (email) => {
      navigation('/matchbet/changepassword', {
        replace: true,
        state: { email }
      });
    },
    [navigation]
  );

  const toggleYupError = (err: string | undefined) => {
    if (err) {
      if (showMessage) setShowMessage(false);
      return err;
    }
    return '';
  };
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={SignInSchema}
      onSubmit={(values) => loginStart(values.email, values.password)}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue
      }) => (
        <S.Form>
          <S.ContainerInput
            error={(touched.email && Boolean(errors.email)) || showMessage}
          >
            <TextField
              id="email"
              label="E-mail"
              variant="outlined"
              autoComplete="off"
              type="email"
              sx={{
                '& legend': {
                  fontSize: '1.2rem'
                }
              }}
              value={values.email}
              onChange={handleChange}
              onKeyUp={() => {
                setFieldValue('email', values.email.replace(/\s/g, ''));
              }}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </S.ContainerInput>
          <S.ContainerInput
            error={
              (touched.password && Boolean(errors.password)) || showMessage
            }
          >
            <S.ContainerPassword>
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                autoComplete="off"
                sx={{
                  '& legend': {
                    fontSize: '1.2rem'
                  }
                }}
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && toggleYupError(errors.password)}
                type={`${passwordShow.showPassword ? 'text' : 'password'}`}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={() =>
                  handleClickShowPassword(!passwordShow.showPassword)
                }
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passwordShow.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </S.ContainerPassword>
          </S.ContainerInput>
          {showMessage ? <S.Message>{message}</S.Message> : null}
          <S.ChangePasswordContainer>
            <S.ChangePassword onClick={() => ForgotPassword(values.email)}>
              {i18n.t('buttons.forgotMyPassword')}
            </S.ChangePassword>
          </S.ChangePasswordContainer>

          <S.Register id="login" onClick={() => handleSubmit()}>
            {load ? (
              <Box>
                <CustomCircularProgress />
              </Box>
            ) : (
              i18n.t('buttons.signIn')
            )}
          </S.Register>
        </S.Form>
      )}
    </Formik>
  );
};

export default Form;
