import { CloseButton } from 'components/elements';
import { useModal } from 'components/modules/Modal';
import React, { SyntheticEvent, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import SaveButton from '../SaveButton';
import { Props } from './@types';

import * as S from './CropModalStyles';

const CropModal: React.FC<Props> = ({ src, onComplete = () => {} }) => {
  const { closeModal } = useModal();
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0
  });

  const cropOnCenter = (img: HTMLImageElement) => {
    const aspect = img.width / img.height;
    const width = img.width > img.height ? 50 / aspect : 50;
    const height = img.height > img.width ? 50 / aspect : 50;
    const x = 50 - width / 2;
    const y = 50 - height / 2;

    setCrop({
      unit: '%',
      width,
      height,
      x,
      y
    });
  };

  const onLoadImage = (event: SyntheticEvent<HTMLImageElement>): void => {
    const img: HTMLImageElement = event.target as HTMLImageElement;
    setImage(img);
    cropOnCenter(img);
  };

  const getCroppedImg = async () => {
    try {
      if (image) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = crop.width;
        canvas.height = crop.height;

        const context = canvas.getContext('2d');

        if (context) {
          context.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
          );

          const base64Image = canvas.toDataURL('image/png', 1);

          onComplete(base64Image);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Fail on crop the image');
    }
  };

  const onClickToCrop = (): void => {
    getCroppedImg();
    closeModal();
  };

  return (
    <S.Container>
      <S.HeaderModal>
        <S.Title>Crop Image</S.Title>
        <CloseButton onClick={closeModal} />
      </S.HeaderModal>
      {src && (
        <S.CropContainer>
          <ReactCrop
            aspect={1}
            crop={crop}
            style={{
              width: '100%',
              height: '100%'
            }}
            onChange={setCrop}
          >
            <img src={src} alt="" onLoad={onLoadImage} />
          </ReactCrop>
        </S.CropContainer>
      )}
      <S.ButtonContainer>
        <SaveButton loading={false} onClick={onClickToCrop}>
          Crop
        </SaveButton>
      </S.ButtonContainer>
    </S.Container>
  );
};

export default CropModal;
