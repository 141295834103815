import React, { useEffect, useState } from 'react';

import { t } from 'translate/i18n';

import { servicesApis } from 'service/service.api';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
  AmountAndPlatformFilter,
  CustomCircularProgress,
  OpenEvents
} from 'components/modules';

import { getDateFormatted } from 'utils/formatters';
import { OpenTournament, Platform } from 'models/Tournaments';

import { EmptyList } from 'components/elements';
import { TournamentCard } from './components/TournamentCard';

import * as S from './OpenGlobalTournamentsStyled';

const OpenGlobalTournaments: React.FC = () => {
  const [openTournaments, setOpenTournaments] = useState<OpenTournament[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [page, setPage] = useState<number>(1);
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);

  const translationString = 'components.userTournament.openGlobalTournament';

  const getPlatformsForFilter = (tournaments: OpenTournament[]): void => {
    const newPlatforms: Platform[] = tournaments.map(
      (tournament: OpenTournament) => tournament.platform
    );
    const uniquesPlatforms: Platform[] = [
      ...new Map(
        [...platforms, ...newPlatforms].map((item) => [item.name, item])
      ).values()
    ];
    setPlatforms(uniquesPlatforms);
  };

  const loadMore = async (isScrolling = false): Promise<void> => {
    setLoading(true);

    try {
      const tournaments = await servicesApis.tournament.getTournaments('open');

      if (shouldUpdateData && !isScrolling) {
        setOpenTournaments([...tournaments]);
        setShouldUpdateData(false);
      }

      if (!shouldUpdateData && isScrolling) {
        setOpenTournaments([...openTournaments, ...tournaments]);
        getPlatformsForFilter(tournaments);
        setPage(page + 1);
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadMore();
  }, [shouldUpdateData]);

  const updatedData = () => {
    setShouldUpdateData(true);
  };

  const getAmmounts = (): number[] => {
    return openTournaments.map((item: OpenTournament) => item.entry_fee);
  };

  const getMaximumValueOfBet = (): number => {
    return getAmmounts().sort().at(-1) || 0;
  };

  const getMinimumValueOfBet = (): number => {
    return getAmmounts().sort().at(0) || 0;
  };

  const getNumberOfPages = (dataLength = 0): number => {
    const ROWS_ON_SCREEN = 5;
    const numbersOfPages = Math.ceil(dataLength / ROWS_ON_SCREEN);
    return numbersOfPages;
  };

  const hasMore = page < getNumberOfPages(openTournaments.length);

  const renderOpenTournament = (item: OpenTournament): JSX.Element => {
    const startDate = getDateFormatted(item.start_date);

    return (
      <TournamentCard
        tournamentTitle={item.name}
        amount={`${item.entry_fee}`}
        startDate={startDate}
        gameImage={item.game.image_url}
        maxPlayers={item.max_players}
        registered={item.tournament_players.length}
        platform={item.platform}
        tournament={item}
        updatedData={updatedData}
      />
    );
  };

  return (
    <OpenEvents
      title={t(`${translationString}.title`)}
      empty={openTournaments.length <= 0}
      emptyList={
        isLoading ? (
          <S.LoadingWrapper>
            <CustomCircularProgress />
          </S.LoadingWrapper>
        ) : (
          <EmptyList
            title={t(`${translationString}.emptyList.title`)}
            description={t(`${translationString}.emptyList.description`)}
          />
        )
      }
      filter={
        <AmountAndPlatformFilter
          min={getMinimumValueOfBet()}
          max={getMaximumValueOfBet()}
          disabled={openTournaments.length <= 0}
          platforms={platforms}
        />
      }
    >
      <InfiniteScroll
        dataLength={openTournaments.length}
        next={() => loadMore(true)}
        hasMore={hasMore}
        loader={
          <S.LoadingWrapper>
            <CustomCircularProgress />
          </S.LoadingWrapper>
        }
      >
        {openTournaments.map(renderOpenTournament)}
      </InfiniteScroll>
    </OpenEvents>
  );
};

export default OpenGlobalTournaments;
