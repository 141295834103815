import { GeneralButton } from 'components/elements';
import styled, { css } from 'styled-components';
import { Type } from './@types';

export const Container = styled.ul`
  margin-top: 25px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  height: 616px;

  @media (max-width: 968px) {
    height: auto;
  }

  @media (max-width: 485px) {
    justify-content: space-between;
  }

  @media (max-width: 299px) {
    gap: 20px;
  }
`;

export const Card = styled.li<{ $loading?: boolean }>`
  list-style: none;

  @media (max-width: 485px) {
    width: 45%;
  }

  @media (max-width: 337px) {
    button {
      transform: scale(0.8);
    }
  }

  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0;
    `}
`;

export const Picture = styled.img`
  width: 164px;
  height: 164px;
  object-fit: cover;
  border-radius: 10px;
  object-position: center;

  @media (max-width: 485px) {
    width: 100%;
    height: 112px;
  }
`;

export const Name = styled.p<{ type?: Type }>`
  margin: 13px 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.text.white};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 153px;
  margin-top: 13px;

  ${({ type }) => css`
    margin-bottom: ${type === Type.CHALLENGE_TEAM ? 'unset' : '13px'};
  `}
`;

export const UsernameOfLeaderOfTeam = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.004em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-bottom: 13px;
`;

export const Row = styled.div<{ gap?: string }>`
  width: 100%;
  display: flex;
  margin-top: 15px;
  gap: ${({ gap }) => gap || '15px'};

  @media (max-width: 418px) {
    button {
      margin: 0 auto;
    }
  }

  @media (max-width: 300px) {
    button {
      width: 70px;
      min-width: 70px;
    }
  }
`;

export const GameName = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.text.white};
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const PlatformsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  min-width: max-content;

  @media (max-width: 418px) {
    min-width: unset;
  }
`;

export const OpenBets = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.white};
  text-align: left;

  @media (max-width: 418px) {
    display: none;
  }
`;

export const Warning = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 130%;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 968px) {
    height: 300px;
  }
`;

export const WrapperActions = styled.div`
  display: flex;
  gap: 5px;
`;

export const Button = styled(GeneralButton)<{ isChallenge?: boolean }>`
  ${({ isChallenge }) =>
    isChallenge &&
    css`
      max-width: 80px;
      min-width: 0;
      padding: 0 10;
      font-size: 12px;
    `}
`;
