import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.004em;
  color: ${(props) => props.theme.palette.text.white};
`;

export const PlatformIcons = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

export const Icon = styled.img`
  width: 11px;
  height: 11px;
  object-fit: contain;
`;
