export const transfer = {
  title: 'Transfer',
  userWalletTransfer: {
    title: `Recipient's email`,
    placeholder: 'Email',
    description:
      'Transfers can only be made between users of the matchcash platform'
  },
  amount: {
    title: 'The amount',
    placeholder: 'Amount',
    initials: 'MATCH',
    initials2: 'Matchcoin',
    balance: 'Balance',
    description: 'Email: '
  },
  password: {
    placeholder: 'Financial password'
  },
  messages: {
    errorOnTransfer: 'An error occoured. Try again!',
    transferSuccessfully: 'Transfer completed successfully.',
    insufficientWalletBalance: 'Insufficient wallet balance.',
    transaction_same_user: 'User cannot transfer to himself.',
    user_financial_password_invalid: 'Financial password not registered.',
    user_to_transfer_does_not_exist: 'User does not exist',
    financial_wallets_must_be_differents: 'Wallets must be differents',
    user_financial_password_not_found: 'Financial password not found',
    unknow_error: 'Unknow error'
  }
};
