import { NftStoreItem as NftStoreItemModel } from 'models/NftStoreList';
import { useContext, useEffect, useState } from 'react';
import { NftStoreListContext } from 'state/nft/state';
import { t } from 'translate/i18n';
import { NftStoreProductItem } from '../NftStoreItem';
import { NftStoreItemsProps } from './@types';
import * as S from './NftStoreItemsStyled';

const NftStoreItems: React.FC<NftStoreItemsProps> = ({
  translationString
}: NftStoreItemsProps) => {
  const [page, setPage] = useState<number>(1);
  const { state } = useContext(NftStoreListContext);
  const [nftStoreItemList, setNftStoreItemList] = useState<NftStoreItemModel[]>(
    state.nft_store_items
  );

  useEffect(() => {
    setNftStoreItemList(state.nft_store_items);
  }, [state.nft_store_items]);

  return (
    <S.AllNFTSContainer>
      {nftStoreItemList && nftStoreItemList.length > 0 && (
        <NftStoreProductItem
          data={nftStoreItemList}
          title={t(`${translationString}.listAllNFTs.title`)}
          valueLabel={t(`${translationString}.listAllNFTs.item.valueLabel`)}
          button={{
            buy: t(`${translationString}.listAllNFTs.item.button.buy`),
            view: t(`${translationString}.listAllNFTs.item.button.view`)
          }}
          page={page}
          setPage={setPage}
        />
      )}
    </S.AllNFTSContainer>
  );
};

export default NftStoreItems;
