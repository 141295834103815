/* eslint-disable no-console */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, IconButton, TextField } from '@mui/material';
import { CustomCircularProgress } from 'components/modules/CustomCircularProgress';
import { useModal } from 'components/modules/Modal';
import { TermoModal } from 'components/modules/Termo';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { signUp } from 'service/auth.service';
import { i18n } from 'translate/i18n';
import { PasswordType, SingUpType } from './@types';
import * as S from './FormStyled';
import signUpSchema from './SignUpSchema';

const Form = () => {
  const [passwordShow, setPasswordShow] = useState<PasswordType>({
    showPassword: false,
    showConfirmPassword: false
  });

  const router = useLocation();
  const { showModal } = useModal();
  const navigation = useNavigate();
  const [load, setLoad] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleClickShowPassword = (
    password: boolean,
    confirmPassword: boolean
  ) => {
    setPasswordShow({
      showPassword: password,
      showConfirmPassword: confirmPassword
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const sendToken = async (
    values: SingUpType,
    setErrors: (errors: { email: string }) => void
  ) => {
    setLoad(true);
    await signUp(values.email, values.password)
      .then(() => {
        const { email } = values;
        setLoad(false);
        navigation('/confirmCode', { replace: true, state: { email } });
      })
      .catch((error: { code: string; message: string }) => {
        if (error.code === 'UsernameExistsException') {
          setErrors({ email: error.message });
          setLoad(false);
        } else if (error.code === 'InvalidPasswordException') {
          setLoad(false);
        }
      });
  };

  const formik = useFormik<SingUpType>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values, { setErrors }) => {
      sendToken(values, setErrors);
    },
    validationSchema: signUpSchema
  });

  const showModalTermo = () => {
    showModal(<TermoModal />, '700px', false, '42px 42px 22px 42px');
  };

  useEffect(() => {
    if (router.search !== '') {
      const queryParams = new URLSearchParams(router.search);
      const code = queryParams.get('indication') || '';
      localStorage.setItem('invitation_code', code);
    }
  }, []);

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <S.ContainerInput
        error={formik.touched.email && Boolean(formik.errors.email)}
      >
        <TextField
          id="email"
          type="email"
          label={i18n.t('inputs.email')}
          variant="outlined"
          autoComplete="off"
          sx={{
            '& legend': {
              fontSize: '1.2rem'
            }
          }}
          value={formik.values.email}
          onChange={formik.handleChange}
          onKeyUp={() => {
            formik.setFieldValue(
              'email',
              formik.values.email.replace('/s/g', '')
            );
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </S.ContainerInput>
      <S.ContainerInput
        error={formik.touched.password && Boolean(formik.errors.password)}
      >
        <S.ContainerPassword>
          <TextField
            label={i18n.t('inputs.password')}
            variant="outlined"
            autoComplete="off"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            sx={{
              '& legend': {
                fontSize: '1.2rem'
              }
            }}
            type={`${passwordShow.showPassword ? 'text' : 'password'}`}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() =>
              handleClickShowPassword(
                !passwordShow.showPassword,
                !!passwordShow.showConfirmPassword
              )
            }
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {passwordShow.showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </S.ContainerPassword>
      </S.ContainerInput>
      <S.ContainerInput
        error={formik.touched.password && Boolean(formik.errors.password)}
      >
        <S.ContainerPassword>
          <TextField
            label={i18n.t('inputs.confirmPassword')}
            variant="outlined"
            autoComplete="off"
            id="confirmPassword"
            sx={{
              '& legend': {
                fontSize: '1.2rem'
              }
            }}
            type={`${passwordShow.showConfirmPassword ? 'text' : 'password'}`}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <IconButton
            aria-label="toggle Confirm Password visibility"
            onClick={() =>
              handleClickShowPassword(
                !!passwordShow.showPassword,
                !passwordShow.showConfirmPassword
              )
            }
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {passwordShow.showConfirmPassword ? (
              <VisibilityOff />
            ) : (
              <Visibility />
            )}
          </IconButton>
        </S.ContainerPassword>
      </S.ContainerInput>
      <S.ContainerTermo>
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          onClick={() => setChecked(!checked)}
        />
        {i18n.t('messages.iAgreeToThe')}
        <S.Link onClick={() => showModalTermo()}>
          {i18n.t('messages.termsOfUse')}
        </S.Link>
      </S.ContainerTermo>
      <S.Register id="signUp" submit noActive={!checked} disabled={!checked}>
        {load ? (
          <Box>
            <CustomCircularProgress />
          </Box>
        ) : (
          <Box>{i18n.t('buttons.signUp')}</Box>
        )}
      </S.Register>
    </S.Form>
  );
};

export default Form;
