import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { passwordSchema } from './PasswordSchema';
import * as S from './PasswordStyled';

export const Password = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      emailVerificationCode: ''
    },
    onSubmit: () => {},
    validationSchema: passwordSchema
  });
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <S.PasswordContainer>
      <S.Content column padding="24px">
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
          >
            <S.ContainerPassword>
              <TextField
                label="Current password"
                variant="outlined"
                autoComplete="off"
                id="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                InputLabelProps={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                    ? {
                        style: { color: '#d32f2f', fontSize: 16 }
                      }
                    : {
                        style: { color: '#ffffff99', fontSize: 16 }
                      }
                }
                type={`${passwordShow ? 'text' : 'password'}`}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordShow(!passwordShow)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passwordShow ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </S.ContainerPassword>
          </S.ContainerInput>
          <S.ContainerInput
            error={
              formik.touched.emailVerificationCode &&
              Boolean(formik.errors.emailVerificationCode)
            }
          >
            <TextField
              id="emailVerificationCode"
              type="text"
              label="E-mail verification code"
              variant="outlined"
              InputProps={{
                endAdornment: <S.Text yellow>get code</S.Text>
              }}
              value={formik.values.emailVerificationCode}
              onChange={formik.handleChange}
              error={
                formik.touched.emailVerificationCode &&
                Boolean(formik.errors.emailVerificationCode)
              }
              helperText={
                formik.touched.emailVerificationCode &&
                formik.errors.emailVerificationCode
              }
            />
          </S.ContainerInput>
          <S.Text>Enter the 6-digit code sent to ana***@gmail.com</S.Text>
        </S.Form>
      </S.Content>
    </S.PasswordContainer>
  );
};
