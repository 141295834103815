import { Box } from '@mui/material';
import { CustomCircularProgress } from 'components/modules';
import React from 'react';
import { t } from 'translate/i18n';
import { Props } from './@types';
import { Container } from './SaveButtonStyled';

const SaveButton: React.FC<Props> = ({
  loading,
  children = t('buttons.save'),
  onClick = () => {}
}) => {
  return (
    <Container onClick={() => onClick()}>
      {loading ? (
        <Box>
          <CustomCircularProgress />
        </Box>
      ) : (
        <>{children}</>
      )}
    </Container>
  );
};

export default SaveButton;
