/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { Actions } from 'state/user/@types/actions';
import { UserContext } from 'state/user/state';
import { Props } from './@types';
import { walletSchema } from './WalletCodeSchema';
import * as S from './WalletCodeStyled';

export const WalletCode = ({ actionCloseModal = () => {} }: Props) => {
  const { state, dispatch } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      wallet: ''
    },
    onSubmit: ({ wallet }) => {
      dispatch({
        type: Actions.SET_WALLET_DATA,
        payload: {
          ...state.user,
          Step: 2,
          Wallet: wallet
        }
      });
    },
    validationSchema: walletSchema
  });

  return (
    <S.WalletCodeContainer>
      <S.Content column padding="24px">
        <S.SubTitle>Wallet code</S.SubTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput
            error={formik.touched.wallet && Boolean(formik.errors.wallet)}
          >
            <TextField
              id="wallet"
              type="text"
              label="Wallet  "
              variant="outlined"
              inputProps={{
                form: {
                  autocomplete: 'off'
                }
              }}
              value={formik.values.wallet}
              onChange={formik.handleChange}
              error={formik.touched.wallet && Boolean(formik.errors.wallet)}
              helperText={formik.touched.wallet && formik.errors.wallet}
            />
          </S.ContainerInput>
          <S.Text>
            Matchcoin withdrawals are only made to BSC - Binance Smart Chain
            (Bep-20)
          </S.Text>
        </S.Form>
      </S.Content>
      <S.ContainerButtons>
        <S.Cancel secondary onClick={() => actionCloseModal()}>
          Cancel
        </S.Cancel>
        <S.Continue onClick={() => formik.handleSubmit()}>Continue</S.Continue>
      </S.ContainerButtons>
    </S.WalletCodeContainer>
  );
};
