import { Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import images from 'assets/img';
import icons from 'assets/img/icons';

import { Game, SelectGamerProps } from './@types';

import * as S from './SelectGamerStyled';

const SelectGamer = ({
  gamers,
  gameSelected,
  setGameSelected,
  isLoading
}: SelectGamerProps) => {
  const [page, setPage] = useState<number>(0);
  const [numberHasIncreased, setNumberHasIncreased] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLImageElement | null>(null);

  const containerWidth = containerRef.current
    ? containerRef.current.offsetWidth
    : 0;
  const remainingWidth = containerRef.current
    ? containerRef.current.scrollWidth - containerWidth
    : 0;
  const MARGIN = 13;
  const WIDTH = cardRef.current ? cardRef.current.offsetWidth + MARGIN : 0;
  const cardsRemaining = remainingWidth / WIDTH;
  const maxPage = Math.ceil(cardsRemaining);

  const scrollToRight = (): void => {
    if (containerRef.current && page <= maxPage) {
      containerRef.current.scrollLeft = page * WIDTH;
    }
  };

  const scrollToLeft = (): void => {
    const currentScroll = WIDTH * page;

    if (containerRef.current && currentScroll >= 0) {
      containerRef.current.scrollLeft =
        remainingWidth - (maxPage - page) * WIDTH;
    }
  };

  const handleClickLeft = (): void => {
    if (page !== 0) {
      setPage(page - 1);
      setNumberHasIncreased(false);
    }
  };

  const handleClickRight = (): void => {
    if (page !== maxPage) {
      setPage(page + 1);
      setNumberHasIncreased(true);
    }
  };

  useEffect(() => {
    const scrollFunction = numberHasIncreased ? scrollToRight : scrollToLeft;

    scrollFunction();
  }, [numberHasIncreased, page]);

  const canRenderButtons = page !== 0 || page !== maxPage;

  return (
    <S.Container canRenderButtons={canRenderButtons}>
      <S.LeftArrow
        src={icons.leftArrow}
        onClick={handleClickLeft}
        canRenderButtons={canRenderButtons}
        isDisabled={isLoading}
      />

      <S.ContainerSlider ref={containerRef}>
        {(isLoading ? new Array(10).fill(1) : gamers)?.map(
          (game: Game | number) => {
            if (isLoading) {
              return (
                <Skeleton
                  variant="rectangular"
                  style={{
                    minWidth: 120,
                    width: 120,
                    height: 120,
                    marginLeft: 18
                  }}
                />
              );
            }

            const isGameActived = (game as Game).entity_id === gameSelected;

            return (
              <S.GameImage
                ref={cardRef}
                actived={isGameActived}
                src={(game as Game).image_url || images.defaultImage}
                alt="game image"
                onClick={(e) => {
                  e.stopPropagation();

                  if (!isGameActived) {
                    setGameSelected((game as Game).entity_id);
                  }
                }}
              />
            );
          }
        )}
      </S.ContainerSlider>

      <S.RightArrow
        onClick={handleClickRight}
        canRenderButtons={canRenderButtons}
        src={icons.rightArrow}
        isDisabled={isLoading}
      />
    </S.Container>
  );
};

export default SelectGamer;
