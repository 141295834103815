/* eslint-disable no-console */
import React from 'react';
import { v4 } from 'uuid';
import { t } from 'translate/i18n';

import images from 'assets/img';
import { GeneralButton } from 'components/elements/GeneralButton';
import { Card, NewTeam } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { Friend } from 'models/Friends';
import { EntityGame } from 'models/Games';
import { Team } from 'pages/CreateBattle/@types';

import { useTournament } from 'state/tournament/hooks';
import { TournamentDetailsModal } from '../TournamentDetailsModal';
import { Props, Type, DataType } from './@types';
import { Platforms } from './components/Platforms';

import * as S from './ListStyled';

const List: React.FC<Props> = ({
  type,
  data,
  loading = false,
  playAlone = false,
  updatedData = () => {},
  clearList = () => {}
}) => {
  const { updateStep, setTournament, tournament } = useTournament();

  const { showModal } = useModal();

  const translationString = 'components.createTournament';
  const isChallenge = [Type.CHALLENGE_TEAM, Type.CHALLENGE_PLAYER].includes(
    type
  );

  const onClickOnCard = (team: Team): void => {
    showModal(
      <NewTeam team={team} updateState={updatedData} />,
      '475px',
      true,
      '19px'
    );
  };

  const onSelectATeam = (team: Team): void => {
    setTournament({
      teamSelected: team
    });
    clearList();
    updateStep(2);
  };

  const onChallengeATeam = (team: Team): void => {
    if (type === Type.CHALLENGE_TEAM) {
      const selectedPlayers = [...tournament.selectedPlayers, team];

      setTournament({
        selectedPlayers
      });
    } else {
      onSelectATeam(team);
    }
  };

  const renderTeamCard = (team: Team): React.ReactElement => {
    const hasSelected = !!tournament.selectedPlayers.find(
      (item: { entity_id: string }) => item.entity_id === team.entity_id
    );

    return (
      <S.Card key={team.entity_id} id="card" $loading={loading}>
        <Card
          width={window.innerWidth < 486 ? '100%' : '187px'}
          height="min-content"
          padding="16px 12px"
          background="rgba(255, 255, 255, 0.1)"
        >
          <S.Picture src={team.image_url || images.defaultImage} />
          <S.Name type={type}>{team.name}</S.Name>
          {isChallenge && (
            <S.UsernameOfLeaderOfTeam>
              {team.user?.name}
            </S.UsernameOfLeaderOfTeam>
          )}
          <S.WrapperActions>
            {!isChallenge && (
              <S.Button
                variant="primary"
                size="small"
                transparent
                isChallenge={!isChallenge}
                onClick={() => onClickOnCard(team)}
              >
                {t(`${translationString}.teamCard.view`)}
              </S.Button>
            )}
            {!hasSelected && (
              <S.Button
                variant="primary"
                size="small"
                transparent={isChallenge}
                isChallenge={!isChallenge}
                onClick={() => onChallengeATeam(team)}
              >
                {t(`${translationString}.teamCard.select`)}
              </S.Button>
            )}
          </S.WrapperActions>
        </Card>
      </S.Card>
    );
  };

  const onClickInAcceptGame = (game: EntityGame): void => {
    showModal(
      <TournamentDetailsModal platforms={game.platforms} />,
      '475px',
      true,
      '35px 42px 43px'
    );

    setTournament({
      game
    });
  };

  const getOpenBets = (game: EntityGame): number => {
    return playAlone
      ? game?.open_battles_count?.alone_battles
      : game?.open_battles_count?.team_battles;
  };

  const renderGameCard = (
    game: EntityGame,
    index: number
  ): React.ReactElement => {
    return (
      <S.Card id="card" $loading={loading} key={v4()}>
        <Card
          width={window.innerWidth < 486 ? '100%' : '187px'}
          height="min-content"
          padding="16px 12px"
          background="rgba(255, 255, 255, 0.1)"
        >
          <S.Picture src={game.image_url || images.defaultImage} />
          <S.Row>
            <S.PlatformsWrapper>
              <Platforms platforms={game.platforms} />
            </S.PlatformsWrapper>
          </S.Row>
          <S.Row>
            <S.OpenBets>
              {getOpenBets(game)} {t(`${translationString}.gameCard.openBets`)}
            </S.OpenBets>
            <GeneralButton
              variant="primary"
              size="small"
              transparent
              onClick={() => onClickInAcceptGame(game)}
              id={`accept-game-${index}`}
            >
              {t(`${translationString}.gameCard.select`)}
            </GeneralButton>
          </S.Row>
        </Card>
      </S.Card>
    );
  };

  const selectAChallenger = (friend: Friend): void => {
    const selectedPlayers = [...tournament.selectedPlayers, friend];

    setTournament({
      selectedPlayers
    });
  };

  const renderPlayerCard = (
    friend: Friend,
    index: number
  ): React.ReactElement => {
    const playerId = `player-buttom-${index + 1}`;

    const hasSelected = !!tournament.selectedPlayers.find(
      (item: { entity_id: string }) => item.entity_id === friend.entity_id
    );

    return (
      <S.Card id="card" key={v4()} onClick={() => {}} $loading={loading}>
        <Card
          width={window.innerWidth < 486 ? '100%' : '187px'}
          height="min-content"
          padding="16px 12px"
          background="rgba(255, 255, 255, 0.1)"
        >
          <S.Picture src={friend.image_url || images.defaultImage} />
          <S.Name>{friend.apelido}</S.Name>
          <S.WrapperActions>
            {!hasSelected && (
              <S.Button
                variant="primary"
                size="small"
                transparent={!isChallenge}
                isChallenge={isChallenge}
                onClick={() => selectAChallenger(friend)}
                id={playerId}
              >
                {t(`${translationString}.teamCard.select`)}
              </S.Button>
            )}
          </S.WrapperActions>
        </Card>
      </S.Card>
    );
  };

  const getRenderFunction = (
    item: Team | EntityGame | Friend,
    index: number
  ): React.ReactElement => {
    const dataRender: DataType = {
      [Type.TEAM]: () => renderTeamCard(item as Team),
      [Type.GAME]: () => renderGameCard(item as EntityGame, index),
      [Type.CHALLENGE_TEAM]: () => renderTeamCard(item as Team),
      [Type.CHALLENGE_PLAYER]: () => renderPlayerCard(item as Friend, index)
    };

    return dataRender[type]();
  };

  const getWarningMessage = (): string => {
    if (type === Type.CHALLENGE_PLAYER) {
      return t(`${translationString}.list.challengePlayerWarning`);
    }
    return '';
  };

  return (
    <S.Container id="list">
      {data.response.map(getRenderFunction)}
      {data.response.length <= 0 ? (
        <S.Warning>{getWarningMessage()}</S.Warning>
      ) : null}
    </S.Container>
  );
};

export default List;
