import React, { KeyboardEvent, useState, ChangeEvent } from 'react';

import { servicesApis } from 'service/service.api';

import icons from 'assets/img/icons';

import { t } from 'i18next';
import * as S from './EditorStyled';
import { Props } from './@types';

const Editor: React.FC<Props> = ({ battleId, onSendMessage = () => {} }) => {
  const [message, setMessage] = useState<string>('');

  const sendMessage = async (): Promise<void> => {
    if (!message.trim()) {
      setMessage('');
      return;
    }

    onSendMessage(message);
    setMessage('');
    await servicesApis.chat.sendMessage(battleId, message);
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMessage(event?.target.value);
  };

  const translationString = 'components.tournament.editor';

  return (
    <S.Container>
      <S.Field
        type="text"
        placeholder={t(`${translationString}.field.placeholder`)}
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={message}
      />
      <S.Icon src={icons.send} onClick={sendMessage} />
    </S.Container>
  );
};

export default Editor;
