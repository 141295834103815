import styled from 'styled-components';

export const RegisteredContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
`;
export const Title = styled.h2`
  font-weight: 400;
  color: #ffffff;
  font-size: 1.9rem;
  line-height: 23px;
  font-style: normal;
  font-family: 'Russo One';
`;
export const Text = styled.p`
  color: #ffffff;
  font-weight: 400;
  font-size: 1.2rem;
  font-style: normal;
  font-family: 'Roboto';
  letter-spacing: 0.13121px;
`;
