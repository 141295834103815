import { NftStoreList, Currencies } from 'models/NftStoreList';

export const nftStoreList: NftStoreList = {
  nft_store_items: [
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_champ_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_champ_bet.mp4',
        name: 'ChampBet',
        description:
          'Muito mais divertido do que participar de uma disputa simples é jogar um torneio com chances de ganhar todas as Betcoins em disputa. Esta NFT permite a criação de torneios e receber um percentual sobre as Betcoins que foram colocadas em disputa.',
        entity_id: Currencies.NFT_CHAMP_BET
      },
      title: 'ChampBet',
      subtitle: 'NFT de Criação de Torneios',
      price: '4990000000000000000',
      validity: 90,
      has_nft: true
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_champ_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_champ_bet.mp4',
        name: 'ChampBet',
        description:
          'Muito mais divertido do que participar de uma disputa simples é jogar um torneio com chances de ganhar todas as Betcoins em disputa. Esta NFT permite a criação de torneios e receber um percentual sobre as Betcoins que foram colocadas em disputa.',
        entity_id: Currencies.NFT_CHAMP_BET
      },
      title: 'ChampBet',
      subtitle: 'NFT de Criação de Torneios',
      price: '8990000000000000000',
      validity: 180,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_super_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_super_bet.mp4',
        name: 'SuperBet',
        description:
          'Com a SuperBet é possível  criar desafios randômicos sem a necessidade de desafiar um amigo em sua lista de amigos',
        entity_id: Currencies.NFT_SUPER_BET
      },
      title: 'SuperBet',
      subtitle: 'NFT de Aposta Randômica',
      price: '1490000000000000000',
      validity: 90,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_super_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_super_bet.mp4',
        name: 'SuperBet',
        description:
          'Com a SuperBet é possível  criar desafios randômicos sem a necessidade de desafiar um amigo em sua lista de amigos',
        entity_id: Currencies.NFT_SUPER_BET
      },
      title: 'SuperBet',
      subtitle: 'NFT de Aposta Randômica',
      price: '1990000000000000000',
      validity: 180,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_super_cash_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_super_cash_bet.mp4',
        name: 'SuperCashBet',
        description:
          'Quem possuir esta NFT, ganha um percentual do rake da plataforma em todas as disputas realizadas por aqueles usuários de sua lista de indicados. Realmente é um Super CashBet! O usuário que tiver esta NFT com vigência ativa no momento da geração dos bônus, irá receber e 1% do valor das apostas de seus indicados inseridas nos eventos de: Torneio, disputa entre amigos e Disputa Randômica.',
        entity_id: Currencies.NFT_SUPER_CASH_BET
      },
      title: 'SuperCashBet',
      subtitle: 'NFT de Cashback nas Apostas realizadas pelos Indicados',
      price: '8990000000000000000',
      validity: 180,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_super_cash_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_super_cash_bet.mp4',
        name: 'SuperCashBet',
        description:
          'Quem possuir esta NFT, ganha um percentual do rake da plataforma em todas as disputas realizadas por aqueles usuários de sua lista de indicados. Realmente é um Super CashBet! O usuário que tiver esta NFT com vigência ativa no momento da geração dos bônus, irá receber e 1% do valor das apostas de seus indicados inseridas nos eventos de: Torneio, disputa entre amigos e Disputa Randômica.',
        entity_id: Currencies.NFT_SUPER_CASH_BET
      },
      title: 'SuperCashBet',
      subtitle: 'NFT de Cashback nas Apostas realizadas pelos Indicados',
      price: '2990000000000000000',
      validity: 90,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_cash_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_cash_bet.mp4',
        name: 'CashBet',
        description:
          'A MatchBet retém um rake, ou seja, um pequeno percentual em todas as disputas realizadas na sua plataforma, com essa NFT, parte desse percentual retorna para o usuário que a possuir. O usuário que tiver esta NFT com vigência ativa, irá receber e 1% do valor de suas apostas* inseridas nos eventos de: Torneio, disputa entre amigos e Disputa Randômica.',
        entity_id: Currencies.NFT_CASH_BET
      },
      title: 'CashBet',
      subtitle: 'NFT de Cashback nas Apostas',
      price: '4990000000000000000',
      validity: 180,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_cash_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_cash_bet.mp4',
        name: 'CashBet',
        description:
          'A MatchBet retém um rake, ou seja, um pequeno percentual em todas as disputas realizadas na sua plataforma, com essa NFT, parte desse percentual retorna para o usuário que a possuir. O usuário que tiver esta NFT com vigência ativa, irá receber e 1% do valor de suas apostas* inseridas nos eventos de: Torneio, disputa entre amigos e Disputa Randômica.',
        entity_id: Currencies.NFT_CASH_BET
      },
      title: 'CashBet',
      subtitle: 'NFT de Cashback nas Apostas',
      price: '1990000000000000000',
      validity: 90,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_cash_bet.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_cash_bet.mp4',
        name: 'MatchBet',
        description:
          'Com esta NFT, você poderá colocar Betcoins em disputas criadas contra usuários que estão na sua lista de amigos e além disso poderá começar a ganhar bônus com as suas indicações de novos usuários para a MatchBet. Uma conta matchbet pode ter somente um indicador direto, a indicação é realizada através de um link, que para ser finalizado o indicado deverá completar o cadastro na plataforma. A paternidade de uma conta é instranferível, portanto uma vez finalizado, o dono deste link será sempre o “patrocinador” desta nova conta.',
        entity_id: Currencies.NFT_MATCH_BET
      },
      title: 'MatchBet',
      subtitle: 'NFT de Cashback nas Apostas',
      price: '2990000000000000000',
      validity: 180,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_sales_cash.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_sales_cash.mp4',
        name: 'SalesCash',
        description:
          'Toda compra de NFTs realizada na loja da MatchBet, por um dos usuários que estão na sua Lista de Indicados, gera um bônus que é creditado diretamente na sua conta. O usuário que tiver esta NFT com vigência ativa, irá receber e 20% do valor pago pelo usuario indicado quando este comprar uma NFT.',
        entity_id: Currencies.NFT_SALES_CASH
      },
      title: 'SalesCash',
      subtitle: 'NFT de CashBack na compra de NFTs dos Indicados',
      price: '8990000000000000000',
      validity: 180,
      has_nft: false
    },
    {
      nft: {
        valid_until: '2021-12-31T23:59:59.999Z',
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_matchbet_partner.png',
        video_url:
          'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_matchbet_partner.mp4',
        name: 'MatchBetPartner',
        description:
          'Esta NFT possibilita a participação sobre parte dos resultados da MatchBet. No início de cada mês, quem tiver a NFT MatchBet Partner receberá parte de todas as disputas realizadas no mês anterior. Incrível! . Os usuário que possuirem esta NFT receberão todo dia 15 do mês parte do valor das taxas de aposta recebidas pela MATCHBET e parte da arrecadação das vendas de NFT contabilizadas no periodo integral do mês anterior. A rotina de bonificação é gerada no primeiro dia de cada mês, e o periodo considerado é do mês anterior (Dia 1 até o ultimo dia doa mês). O calculo deste bônus individual se dá pelo percentual(3%) do somátorio de todos as apostas da plataforma (Amigos, randômicas e Torneio), mais percentual (20%) do somatório do faturamento de todas as compras de NFT, divididos pelo somatório da quantidade usuários com NFT Matchbet ativo no dia da geração do valor(dia 31 do Mês de referência). A vigência da NFT irá ser considerada até o último segundo do dia da vigência e não a hora do momento da compra da NFT',
        entity_id: Currencies.NFT_MB_PARTNER
      },
      title: 'MatchBetPartner',
      subtitle: 'NFT de Cashback em TODAS as Apostas da Plataforma',
      price: '19990000000000000000',
      validity: 365,
      has_nft: true
    }
  ],
  user_nfts: [
    {
      valid_until: '2021-12-31T23:59:59.999Z',
      image_url:
        'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_champ_bet.png',
      video_url:
        'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_champ_bet.mp4',
      name: 'ChampBet',
      description:
        'Muito mais divertido do que participar de uma disputa simples é jogar um torneio com chances de ganhar todas as Betcoins em disputa. Esta NFT permite a criação de torneios e receber um percentual sobre as Betcoins que foram colocadas em disputa.',
      entity_id: Currencies.NFT_CHAMP_BET
    },
    {
      valid_until: '2021-12-31T23:59:59.999Z',
      image_url:
        'https://s3.amazonaws.com/dev.matchbet/nfts/images/nft_matchbet_partner.png',
      video_url:
        'https://s3.amazonaws.com/dev.matchbet/nfts/videos/nft_matchbet_partner.mp4',
      name: 'MatchBetPartner',
      description:
        'Esta NFT possibilita a participação sobre parte dos resultados da MatchBet. No início de cada mês, quem tiver a NFT MatchBet Partner receberá parte de todas as disputas realizadas no mês anterior. Incrível! . Os usuário que possuirem esta NFT receberão todo dia 15 do mês parte do valor das taxas de aposta recebidas pela MATCHBET e parte da arrecadação das vendas de NFT contabilizadas no periodo integral do mês anterior. A rotina de bonificação é gerada no primeiro dia de cada mês, e o periodo considerado é do mês anterior (Dia 1 até o ultimo dia doa mês). O calculo deste bônus individual se dá pelo percentual(3%) do somátorio de todos as apostas da plataforma (Amigos, randômicas e Torneio), mais percentual (20%) do somatório do faturamento de todas as compras de NFT, divididos pelo somatório da quantidade usuários com NFT Matchbet ativo no dia da geração do valor(dia 31 do Mês de referência). A vigência da NFT irá ser considerada até o último segundo do dia da vigência e não a hora do momento da compra da NFT',
      entity_id: Currencies.NFT_MB_PARTNER
    }
  ]
};
