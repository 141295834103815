/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { CustomCircularProgress } from 'components/modules';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { servicesApis } from 'service/service.api';
import { Actions } from 'state/user/@types/actions';
import { getErrorStringName } from 'utils/errorHandling';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { Actions as ProfileSettingsActions } from 'state/profileSettings/@types/actions';
import { UserContext } from 'state/user/state';
import { NotificationContext } from 'state/notification/state';
import { i18n } from 'translate/i18n';
import { ProfileSettingsContext } from 'state/profileSettings/state';
import { Props } from '../../@types';
import { passwordSchema } from './PasswordSchema';
import * as S from './PasswordStyled';

export const Password = ({
  callback = () => {},
  actionCloseModal = () => {}
}: Props) => {
  const [load, setLoad] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const { dispatch: settingsDispatch } = useContext(ProfileSettingsContext);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);

  const [passwordShow, setPasswordShow] = useState(false);
  const translationSchema =
    'components.userProfile.schemas.financePasswordSchema';

  const translationString = 'components.transfer';
  const getUserBalance = async () => {
    const response = await servicesApis.user.getUserBalance();
    dispatch({
      type: Actions.SET_USER_BALANCE,
      payload: {
        balance: response.data.available_balance
      }
    });
  };

  const dispatchMessage = async (
    error: any,
    setFieldError: (field: string, value: string | undefined) => void
  ) => {
    const errorCode = await getErrorStringName(error.response.data.error_code);
    const keyMessage = errorCode ? errorCode.toLowerCase() : 'errorOnTransfer';

    const message = t(`${translationString}.messages.${keyMessage}`);

    switch (error.response.data.error_code) {
      case 1700:
        actionCloseModal();
        settingsDispatch({
          type: ProfileSettingsActions.OPEN_MENU
        });
        notificationDispatch({
          type: NotificationActions.OPEN,
          payload: {
            message
          }
        });
        break;
      case 1800:
        setFieldError(
          'currentPassword',
          t(`${translationSchema}.submit.wrongPassword`)
        );
        break;
      default:
        actionCloseModal();
        notificationDispatch({
          type: NotificationActions.OPEN,
          payload: {
            message
          }
        });
    }
    setLoad(false);
  };

  const sendTransaction = async (
    currentPassword: string,
    setFieldError: (field: string, value: string | undefined) => void
  ) => {
    const transactionData = {
      amount: state.user.Amount,
      user_email: state.user.emailTransfer,
      currency: 'MATCH_PRESALE',
      financial_password: currentPassword
    };
    setLoad(true);
    const customCallBackError = (error: Error) =>
      dispatchMessage(error, setFieldError);
    await servicesApis.bank
      .sendTransaction({
        payload: transactionData,
        callBackError: customCallBackError
      })
      .then(() => {
        notificationDispatch({
          type: NotificationActions.OPEN,
          payload: {
            message: t(`${translationString}.messages.transferSuccessfully`)
          }
        });
        actionCloseModal();
        getUserBalance();
        callback();
        dispatch({
          type: Actions.SET_WALLET_DATA,
          payload: {
            ...state.user,
            Step: 1
          }
        });
      });
    setLoad(false);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: ''
    },
    onSubmit: ({ currentPassword }, { setFieldError }) => {
      sendTransaction(currentPassword, setFieldError);
    },
    validationSchema: passwordSchema
  });
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const backStep = () => {
    dispatch({
      type: Actions.SET_WALLET_DATA,
      payload: {
        ...state.user,
        Step: state.user.Step - 1
      }
    });
  };

  return (
    <S.PasswordContainer>
      <S.Content column padding="24px">
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
          >
            <S.ContainerPassword>
              <TextField
                label={t(`${translationString}.password.placeholder`)}
                variant="outlined"
                autoComplete="off"
                id="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                InputLabelProps={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                    ? {
                        style: { color: '#d32f2f', fontSize: 16 }
                      }
                    : {
                        style: { color: '#ffffff99', fontSize: 16 }
                      }
                }
                type={`${passwordShow ? 'text' : 'password'}`}
                error={
                  formik.touched.currentPassword &&
                  Boolean(formik.errors.currentPassword)
                }
                helperText={
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword
                }
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordShow(!passwordShow)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passwordShow ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </S.ContainerPassword>
          </S.ContainerInput>
        </S.Form>
      </S.Content>
      <S.ContainerButtons>
        <S.Cancel secondary onClick={() => backStep()}>
          {i18n.t('buttons.back')}
        </S.Cancel>
        <S.Continue onClick={() => formik.handleSubmit()}>
          {load ? (
            <Box>
              <CustomCircularProgress />
            </Box>
          ) : (
            i18n.t('buttons.continue')
          )}
        </S.Continue>
      </S.ContainerButtons>
    </S.PasswordContainer>
  );
};
