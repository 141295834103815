import React, { useState } from 'react';

import { getIcon } from 'assets/img/icons';
import {
  getLanguageFlag,
  getUserLanguage,
  saveUserLanguage
} from 'service/localStorage';
import { t } from 'translate/i18n';

import { Card } from 'components/modules/Card';
import * as S from './LanguageSwitchStyled';

const LanguageSwitch: React.FC = () => {
  const [language, setLanguage] = useState({
    lang: getUserLanguage(),
    icon: getLanguageFlag()
  });

  const updatePage = (): void => {
    // eslint-disable-next-line no-self-assign
    window.location = window.location;
  };

  const switchLanguage = () => {
    const nextLanguage = language.lang === 'pt-BR' ? 'en-US' : 'pt-BR';

    setLanguage({
      lang: nextLanguage,
      icon: language.icon === 'flagPt' ? 'flagUs' : 'flagPt'
    });

    saveUserLanguage(nextLanguage);

    updatePage();
  };

  const getLanguageLabel = (): string => {
    switch (language.lang) {
      case 'pt-BR':
        return 'PT';

      case 'en-US':
        return 'EN';

      default:
        return '';
    }
  };

  const translationString = 'components.languageSwitch';

  return (
    <S.Language>
      <Card width="50px" height="105px" padding="7px" background="#392E4F">
        <S.Content>
          <S.Icon
            src={getIcon(`${language.icon}`)}
            alt={t(`${translationString}.flag.alt`)}
          />
          <S.Text>{getLanguageLabel()}</S.Text>
          <S.Icon
            cursor="pointer"
            onClick={switchLanguage}
            src={getIcon('arrowSwap')}
            alt={t(`${translationString}.arrowSwap.alt`)}
          />
        </S.Content>
      </Card>
      <S.FloatingDescription>
        {t(`${translationString}.floatingLabel`)}
      </S.FloatingDescription>
    </S.Language>
  );
};

export default LanguageSwitch;
