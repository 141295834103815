import * as yup from 'yup';
import 'yup-phone';

const completeSignUpSchema = yup.object().shape({
  nickname: yup
    .string()
    .required('Mandatory name field!')
    .min(3, 'The name must be longer than 3 letters'),
  financialPassword: yup
    .string()
    .required('mandatory financial password field')
    .min(5, 'The Password must be longer needs at least 5 characters'),
  birthDate: yup.date().required('Mandatory birth Date field!')
});
export default completeSignUpSchema;
