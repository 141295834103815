export enum Init {
  NOW = 'now',
  ONFUTURE = 'onfuture'
}

export type StartOption = {
  name: string;
  init: Init;
};

export type TournamentStartOptions = StartOption[];
