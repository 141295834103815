/* eslint-disable @typescript-eslint/no-explicit-any */

import { Actions } from './@types/actions';

const errorReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.SET_ERRORS:
      return {
        errorCodes: action.payload.errors
      };

    default:
      return {};
  }
};

export default errorReducer;
