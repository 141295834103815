export const globalRanking = {
  title: 'Ranking global',
  auxiliarTitle: 'RANKING MatchCash',
  placeholder_search_user: 'Pesquisar',
  description: 'Este é o ranking global, todos os jogadores aparecem aqui.',

  yourHistory: {
    title: 'Seu histórico',
    battles_text: 'Batalhas',
    tournaments_text: 'Torneios',
    elo_points_text: 'ELO Points',
    battles_won_text: 'Vitórias de Batalhas',
    text: 'Este é um resumo da sua história',
    tournaments_won_text: 'Vitórias de Torneios',
    place_in_the_ranking_text: 'Posição no ranking'
  },

  table: {
    user_text: 'Usuário',
    battles_won_text: 'Batalhas vencidas',
    tournaments_won_text: 'Torneios vencidos',
    elo_points_text: 'ELO Points',
    imgs_alt: {
      icon: 'icone',
      game: 'Imagem do jogo',
      user: 'Imagem do usuário'
    },
    row: {
      text: 'valor da aposta'
    }
  }
};
