import React, { useState } from 'react';
import { Props } from './@types';

import * as S from './ToggleButtonStyled';

const ToggleButton: React.FC<Props> = ({
  active = false,
  disabled = false,
  onChange = () => {},
  bindState = false
}) => {
  const [toggle, setToggle] = useState<boolean>(active);

  const onToggle = (): void => {
    if (!disabled && !bindState) {
      setToggle(!toggle);
      onChange(!toggle);
    }

    if (!disabled && bindState) {
      onChange(active);
    }
  };

  return (
    <S.Button active={bindState ? active : toggle} onClick={onToggle}>
      <S.Indicator active={bindState ? active : toggle} />
    </S.Button>
  );
};

export default ToggleButton;
