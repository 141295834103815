import { i18n } from 'translate/i18n';
import { TermoModalEng } from './Termo-eng';
import { TermoModalPt } from './Termo-pt';

export const TermoModal = () => {
  if (i18n.language === 'pt-BR') {
    return <TermoModalPt />;
  }
  return <TermoModalEng />;
};
