import { MatchBetStoreItem } from 'models/MatchBetStoreItem';
import { Card } from 'components/modules';
import { v1 as uuidv1 } from 'uuid';

import React from 'react';
import { useModal } from 'components/modules/Modal';
import { Empty } from '../Empty';
import * as S from './StoreListStyled';
import { Props } from './@types';
import { ItemModal } from '../ItemModal';

const StoreList: React.FC<Props> = ({ data, empty = false }) => {
  const { showModal } = useModal();

  const showItemModal = (
    product: MatchBetStoreItem['product'],
    formattedPrice: string,
    price: number,
    entity_id: string
  ) => {
    showModal(
      <ItemModal
        item={product}
        formattedPrice={formattedPrice}
        price={price}
        storeItemId={entity_id}
      />,
      '100%',
      true,
      '35px 42px 43px'
    );
  };

  const renderStoreCard = ({
    price,
    product,
    entity_id
  }: MatchBetStoreItem): React.ReactElement => {
    const formattedPrice = (price / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });

    return (
      <S.Card
        id="card"
        onClick={() => showItemModal(product, formattedPrice, price, entity_id)}
        key={uuidv1()}
      >
        <Card
          width={window.innerWidth < 486 ? 'auto' : '187px'}
          height="300px"
          padding="16px 12px"
          background={
            empty ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.1)'
          }
        >
          <S.ContentCard hide={empty}>
            <S.CardImage src={product.image_url} />
            <S.CardDescription>{product.description}</S.CardDescription>
            <S.CardPrice>{formattedPrice}</S.CardPrice>
          </S.ContentCard>
        </Card>
      </S.Card>
    );
  };

  return (
    <S.Container id="list">
      {empty ? <Empty /> : null}
      {data.map(renderStoreCard)}
    </S.Container>
  );
};

export default StoreList;
