export const amountAndPlatformFilter = {
  amount: 'Amount Matchcoin',
  filterIcon: {
    alt: 'filter button'
  },
  slider: {
    lowestPlaceholder: 'Minimum bet',
    highPlaceholder: 'Maximum bet'
  },
  platforms: 'Platforms',
  gamesFor: 'Games for',
  seeAllGames: 'See all games',
  victoryValidation: 'Victory validation'
};
