import { getImage } from 'assets/img';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { useEffect, useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmSignUp, resendVerifyToken } from 'service/auth.service';
import { i18n } from 'translate/i18n';
import * as S from './ConfirmCodeStyled';

const ConfirmCode = () => {
  const navigation = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state }: any = useLocation();
  const [code, setCode] = useState('');
  const [counter, setCounter] = useState(15);
  const { setUserActive } = useContentUserActive();

  const resendCode = async () => {
    setCounter(15);

    resendVerifyToken(state.email)
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    let clear = () => {};
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      clear = () => clearTimeout(timer);
    }
    return () => clear();
  }, [counter]);

  const handleCodeSubmit = async () => {
    await confirmSignUp(state.email, code)
      .then(() => {
        setUserActive({
          registrationSuccessful: true,
          message: i18n.t('messages.sucessRegister')
        });
        navigation('/sign-in', { replace: true });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('err ::', err);
      });
  };
  const handleOnChange = (res: string) => {
    setCode(res);
  };

  return (
    <S.Container>
      <S.Logo src={getImage('Logo')} alt="Logo matchbet" />
      <S.ContainerConfirmCode>
        <S.Title>{i18n.t('titles.confirmEmail')}</S.Title>
        <S.Text>{i18n.t('messages.weVeSentYouACode')}</S.Text>
        <S.ContainerForm>
          <AuthCode onChange={(e) => handleOnChange(e)} />
        </S.ContainerForm>
        {counter === 0 ? (
          <S.ResendText active onClick={resendCode}>
            {i18n.t('links.resendCode')}
          </S.ResendText>
        ) : (
          <S.ResendText>
            {' '}
            {i18n.t('messages.counter', { counter })}
          </S.ResendText>
        )}
      </S.ContainerConfirmCode>
      {code.length === 6 ? (
        <S.Register onClick={handleCodeSubmit}>
          {i18n.t('buttons.signUp')}
        </S.Register>
      ) : (
        <S.Register noActive onClick={handleCodeSubmit}>
          {i18n.t('buttons.signUp')}
        </S.Register>
      )}
    </S.Container>
  );
};

export default ConfirmCode;
