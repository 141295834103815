import React, { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { DEFAULT_PRIVATE_ROUTE, DEFAULT_PUBLIC_ROUTE } from 'config/routes';

const wildcardRoutes = (): ReactElement[] => {
  return [
    <Route
      key="private"
      path="/matchbet"
      element={<Navigate to={DEFAULT_PRIVATE_ROUTE} />}
    />,
    <Route
      key="public"
      path="*"
      element={<Navigate to={DEFAULT_PUBLIC_ROUTE} />}
    />
  ];
};

export default wildcardRoutes;
