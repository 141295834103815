import React from 'react';

import { Props } from './@types';

import * as S from './OpenEventsStyled';

const OpenEvents: React.FC<Props> = ({
  title,
  description = undefined,
  filter = <></>,
  children,
  empty = false,
  emptyList = <></>
}) => {
  return (
    <S.Container>
      <S.Header>
        <S.TitleFilterWrapper>
          <S.Title>{title}</S.Title>
          {filter}
        </S.TitleFilterWrapper>
        {description ? <S.Text>{description}</S.Text> : null}
      </S.Header>

      {empty ? emptyList : <S.ListBattles>{children}</S.ListBattles>}
    </S.Container>
  );
};

export default OpenEvents;
