import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 130%;
  letter-spacing: 0.0015em;
  color: white;
`;

export const CropContainer = styled.div`
  width: auto;
  height: auto;
  margin: 0 auto;
  margin-top: 24px;
`;

export const ButtonContainer = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
