import React, { FC, ReactElement } from 'react';
import { Navigate, Outlet, Route as DOMRoute } from 'react-router-dom';

import { DEFAULT_PRIVATE_ROUTE, DEFAULT_PUBLIC_ROUTE } from 'config/routes';

import { Control, Route } from './@types';

const Private: FC<Control> = ({ signed }) => {
  return signed ? <Outlet /> : <Navigate to={DEFAULT_PUBLIC_ROUTE} />;
};

const Public: FC<Control> = ({ signed }) => {
  return signed ? <Navigate to={DEFAULT_PRIVATE_ROUTE} /> : <Outlet />;
};

export const publicRoute = ({
  key,
  path,
  element: Element,
  signed
}: Route): ReactElement => {
  return (
    <DOMRoute key={key} path={path} element={<Public signed={signed} />}>
      <DOMRoute path={path} element={<Element />} />
    </DOMRoute>
  );
};

export const privateRoute = ({
  key,
  path,
  element: Element,
  signed
}: Route): ReactElement => {
  return (
    <DOMRoute key={key} path={path} element={<Private signed={signed} />}>
      <DOMRoute path={path} element={<Element />} />
    </DOMRoute>
  );
};
