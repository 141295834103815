/* eslint-disable @typescript-eslint/no-explicit-any */
import { GeneralButton } from 'components/elements/GeneralButton';
import { Card } from 'components/modules';
import { BuyNft } from 'components/modules/BuyNft';
import { useModal } from 'components/modules/Modal';
import { NftStoreItemViewProduct } from 'pages/NftStore/components/NftStoreItensViewProduct';
import React, { MutableRefObject, useContext, useEffect, useRef } from 'react';
import { NftStoreListContext } from 'state/nft/state';
import { t } from 'translate/i18n';
import { betinToMatch } from '../../../../utils/formatters';

import * as S from './DisplayStyled';

const Display: React.FC = () => {
  const { state } = useContext(NftStoreListContext);
  const nftStoreItemSelected = state.nft_store_item_selected;
  const NFTContainerRef = useRef() as MutableRefObject<HTMLCanvasElement>;
  const { showModal } = useModal();

  useEffect(() => {
    const onResize = (): void => {
      if (!NFTContainerRef.current) return;

      if (window.innerWidth < 690) {
        NFTContainerRef.current.setAttribute(
          'style',
          'width: calc(541px * 0.80); height: calc(556px * 0.75); touch-action: none;'
        );
      }

      if (window.innerWidth < 560) {
        NFTContainerRef.current.setAttribute(
          'style',
          'width: calc(541px * 0.60); height: calc(556px * 0.50); touch-action: none;'
        );
      }

      if (window.innerWidth < 420) {
        NFTContainerRef.current.setAttribute(
          'style',
          'width: calc(541px * 0.40); height: calc(556px * 0.30); touch-action: none;'
        );
      }

      if (window.innerWidth < 312) {
        NFTContainerRef.current.setAttribute(
          'style',
          'width: calc(541px * 0.30); height: calc(556px * 0.20); touch-action: none;'
        );
      }
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const openBuyNFTModal = (): void => {
    showModal(
      <BuyNft
        img={nftStoreItemSelected.nft.image_url}
        description={nftStoreItemSelected.nft.description}
        name={nftStoreItemSelected.nft.name}
        value={betinToMatch(nftStoreItemSelected.price)}
      />,
      '435px',
      true,
      '42px 56px'
    );
  };

  const openNFTModal = (): void => {
    showModal(
      <NftStoreItemViewProduct
        name={nftStoreItemSelected.nft.name}
        text={nftStoreItemSelected.subtitle}
        value={betinToMatch(nftStoreItemSelected.price)}
        description={nftStoreItemSelected.nft.description}
      />,
      '1072px',
      true,
      '36px 34px'
    );
  };

  const translationString = 'components.nft.display';
  const loadVideo = () => {
    const video = document.getElementById('video') as HTMLVideoElement;
    if (!video) return;
    video.load();
    video
      .play()
      .then((res) => res)
      .catch((err) => err);
  };

  useEffect(() => {
    loadVideo();
  }, [nftStoreItemSelected.nft.video_url]);

  return (
    <S.Container>
      <Card
        background="rgba(255, 255, 255, 0.1)"
        height="100%"
        width="100%"
        padding="35px"
      >
        <S.Content>
          <S.Title>{nftStoreItemSelected.title}</S.Title>
          <S.Subtitle>{nftStoreItemSelected.subtitle}</S.Subtitle>
          <S.Video
            id="video"
            src={nftStoreItemSelected.nft.video_url}
            autoPlay
            loop
          />
          <S.Description>{nftStoreItemSelected.nft.description}</S.Description>
          <S.DisplayFooter>
            <S.BuyInfoWrapper>
              <S.ValueWrapper>
                <S.ValueLabel>
                  {t(`${translationString}.valueLabel`)}
                </S.ValueLabel>
                <S.Value>
                  {betinToMatch(nftStoreItemSelected.price)}
                  {t(`${translationString}.coin`)}
                </S.Value>
              </S.ValueWrapper>
              <GeneralButton
                size="large"
                variant="primary"
                onClick={
                  nftStoreItemSelected.has_nft ? openNFTModal : openBuyNFTModal
                }
              >
                {nftStoreItemSelected.has_nft
                  ? t(`${translationString}.button.view`)
                  : t(`${translationString}.button.buy`)}
              </GeneralButton>
            </S.BuyInfoWrapper>
          </S.DisplayFooter>
        </S.Content>
      </Card>
    </S.Container>
  );
};

export default Display;
