/* eslint-disable no-console */
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Actions } from 'state/user/@types/actions';
import { UserContext } from 'state/user/state';
import { amountSchema } from './AmountSchema';
import * as S from './AmountStyled';

/* Mocking getBalence que era importada de utils/connectors */
const getBalance = async () => Promise.reject(Error('Not implemented yet'));
/* Mocking end */

export const Amount = () => {
  const { state, dispatch } = useContext(UserContext);
  const [myBallance, setMyBallance] = useState('0');

  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    onSubmit: ({ amount }) => {
      dispatch({
        type: Actions.SET_WALLET_DATA,
        payload: {
          ...state.user,
          Step: 3,
          Amount: amount
        }
      });
    },
    validationSchema: amountSchema
  });

  const backStep = () => {
    dispatch({
      type: Actions.SET_WALLET_DATA,
      payload: {
        ...state.user,
        Step: state.user.Step - 1
      }
    });
  };

  const getMyBalance = async () => {
    const balance = await getBalance();
    setMyBallance(balance);
  };

  useEffect(() => {
    getMyBalance();
  }, []);

  return (
    <S.AmountContainer>
      <S.Content column padding="24px">
        <S.SubTitle>
          The amount
          <S.Text>
            <S.Initials>MATCH</S.Initials> Matchcoin
          </S.Text>
        </S.SubTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput>
            <S.ContainerPassword>
              <TextField
                type="text"
                variant="outlined"
                id="amount"
                label="Amount"
                sx={{
                  '& legend': {
                    fontSize: '1.2em'
                  }
                }}
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                InputProps={{
                  endAdornment: (
                    <S.Text>
                      <S.Initials>Balance:</S.Initials>
                      <S.Initials yellow>{myBallance} MATCH </S.Initials>
                    </S.Text>
                  )
                }}
              />
            </S.ContainerPassword>
          </S.ContainerInput>
          <S.Content column>
            <S.Text margin="0 0 5px">You receive:</S.Text>
            <S.Initials yellow>0.0 MATCH</S.Initials>
          </S.Content>
          <S.Content column>
            <S.Text flex margin="5px 0 10px">
              Number wallet: {state.user.Wallet.substring(0, 4)}***
              {state.user.Wallet.substring(38)}
              <S.Text yellow>Edit account </S.Text>
            </S.Text>
            <S.Text>Rate: 0.00 MATCH</S.Text>
          </S.Content>
        </S.Form>
      </S.Content>
      <S.ContainerButtons>
        <S.Cancel secondary onClick={() => backStep()}>
          voltar
        </S.Cancel>
        <S.Continue onClick={() => formik.handleSubmit()}>Continue</S.Continue>
      </S.ContainerButtons>
    </S.AmountContainer>
  );
};
