import { t } from 'i18next';
import { useContext } from 'react';
import { FormikValues, FormikHelpers } from 'formik';
import { BigNumber, utils } from 'ethers';

import { UserContext } from 'state/user/state';

import { useModal } from 'components/modules/Modal';
import { DataProps } from 'components/modules/EventModal/@types';

import { renderNicknameModal } from './Battle';

export const useBattle = () => {
  const { state: userState } = useContext(UserContext);
  const { showModal } = useModal();

  const isBalanceEnough = (_amount: string): boolean => {
    const userBalance: string = userState.user.balance as string;
    const decimalBalance: string = userBalance.replace(',', '.');
    const balance: BigNumber = utils.parseEther(decimalBalance);
    const amount: BigNumber = utils.parseEther(_amount);
    return balance.gte(amount);
  };

  const onSubmitEventModal = async (
    values: FormikValues & DataProps,
    { setFieldError }: FormikHelpers<FormikValues>
  ) => {
    if (!isBalanceEnough(values.amount)) {
      setFieldError(
        'password',
        t(`${values.translationString}.notEnoughFunds`)
      );
      return;
    }

    try {
      renderNicknameModal(
        showModal,
        values.password,
        values.entity_id as string
      );
    } catch (error) {
      setFieldError('password', (error as Error).message);
    }
  };

  return {
    isBalanceEnough,
    onSubmitEventModal
  };
};
