export const yourHistory = {
  title: 'SEU HISTÓRICO',
  auxiliarTitle: 'HISTÓRICO',
  placeholder_search_user: 'Pesquisar',
  description:
    'Esta é sua história, suas batalhas e torneios, aproveite a oportunidade para compartilhar com seus amigos!',

  table: {
    battles_won_text: 'Jogo',
    result_text: 'Resultado',
    end_date_text: 'Data final',
    bet_value_text: 'Valor apostado',
    start_date_text: 'Data de início',
    elo_points_text: 'Competidores',
    imgs_alt: {
      icon: 'icone',
      game: 'Imagem do jogo',
      user: 'Imagem do usuário'
    },
    row: {
      text: 'valor da aposta'
    }
  }
};
