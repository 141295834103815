import { GeneralButton } from 'components/elements/GeneralButton';
import styled from 'styled-components';

const EmptyStateContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.text.white};
  width: 100%;
  height: 366px;
  border-radius: 12px;

  @media (max-width: 941px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
const TitleEmptyState = styled.h1`
  font-size: 4.8rem;
  font-family: 'Russo One', sans-serif;
  font-weight: 400;
  margin-bottom: 12px;

  @media (max-width: 450px) {
    font-size: 3.8rem;
  }

  @media (max-width: 380px) {
    font-size: 2.8rem;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 50px;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 941px) {
    width: 100%;
    margin-left: 20px;
  }

  @media (max-width: 300px) {
    margin-left: 10px;
  }
`;
const EmojisImage = styled.img`
  max-height: 400px;
  height: 90%;
  margin: auto;
  font-weight: 400;

  @media (max-width: 941px) {
    display: none;
  }
`;
const LetsGoButton = styled.div`
  height: auto;
  width: auto;
  margin-top: 20px;
  margin-left: 0;
`;
const ParagraphEmptyState = styled.p`
  font-size: 1.6rem;
  width: 300px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

const Button = styled(GeneralButton)`
  padding: 0 21px;
  height: 30px;
  font-size: 1.2rem;
`;

export {
  EmptyStateContainer,
  ParagraphEmptyState,
  TitleEmptyState,
  ContentContainer,
  LetsGoButton,
  EmojisImage,
  Button
};
