import React, { useContext, useState } from 'react';

import { UserContext } from 'state/user/state';
import { useNavigate } from 'react-router-dom';

import { getIcon } from 'assets/img/icons';

import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';
import { GeneralButton } from 'components/elements/GeneralButton';
import { t } from 'translate/i18n';
import * as S from './YourWalletStyled';

export const YourWallet: React.FC = () => {
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const { state: userState } = useContext(UserContext);

  const navigate = useNavigate();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onClickToBuyCoins = (): void => {
    navigate('/matchbet/store/buyCoins');
  };

  const translationString = 'components.account.yourWallet';

  return (
    <S.AccountInfo>
      <S.ContentTexts>
        <S.Wrapper>
          <S.Title size="2.3">
            <S.Icon
              src={getIcon('emptyWallet')}
              alt={t(`${translationString}.wallet.alt`)}
            />
            {t(`${translationString}.title`)}
            <IconButton
              aria-label={t(`${translationString}.eye.ariaLabel`)}
              onClick={() => setShowBalance(!showBalance)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showBalance ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </S.Title>
          <S.Text margin="12px 0 6px">
            {t(`${translationString}.virtualBalance`)}
          </S.Text>
          <S.Text margin="0px 0 10px">{userState.user.email}</S.Text>
          <S.Title size="2.6">
            {t(`${translationString}.coin`)}
            <S.Title size="2.6" showBalance={showBalance}>
              <ExtendedNumberBox>{userState.user.balance}</ExtendedNumberBox>
            </S.Title>
          </S.Title>
          <S.Text margin="12px 0 10px 0">
            {t(`${translationString}.virtualBalancePending`)}
          </S.Text>
          <S.Title size="2.6">
            {t(`${translationString}.coin`)}
            <S.Title size="2.6" showBalance={showBalance}>
              <ExtendedNumberBox>
                {userState.user.pending_balance}
              </ExtendedNumberBox>
            </S.Title>
          </S.Title>
        </S.Wrapper>

        <S.Wrapper>
          <S.ButtonContainer>
            <S.Text margin="15px 0 6px">
              {t(`${translationString}.moreCoins`)}
            </S.Text>
            <GeneralButton
              size="small"
              variant="primary"
              transparent
              onClick={onClickToBuyCoins}
            >
              {t(`${translationString}.buyCoins`)}
            </GeneralButton>
          </S.ButtonContainer>
        </S.Wrapper>
      </S.ContentTexts>
    </S.AccountInfo>
  );
};
