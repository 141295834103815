import React from 'react';

import { t } from 'translate/i18n';
import { formatMoney } from 'utils/formatMoney';

import images from 'assets/img';
import icons from 'assets/img/icons';

import * as S from './BattleCardStyled';

import { Props } from './@types';

const BattleCard: React.FC<Props> = ({
  gameName,
  gameImage,
  platform,
  amount,
  onAccept = () => {}
}) => {
  const translationString = 'components.battle.openGlobalBattles';

  return (
    <S.Container onClick={onAccept}>
      <S.GameImage
        src={gameImage}
        alt={t(`${translationString}.gameImage.alt`)}
      />

      <S.Infos>
        <S.TitlePlatformWrapper>
          <S.GameName>{gameName}</S.GameName>

          <S.Platforms>
            <S.PlatformLabel>
              {t(`${translationString}.platformLabel`)}
            </S.PlatformLabel>
            <S.PlatformIcon
              src={platform?.image_url || images.defaultImage}
              alt={t(`${translationString}.platformIcon.alt`)}
            />
          </S.Platforms>
        </S.TitlePlatformWrapper>

        <S.ValueIconWrapper>
          <S.ValueWrapper>
            <S.ValueLabel>{t(`${translationString}.valueLabel`)}</S.ValueLabel>
            <S.Value>{formatMoney(amount)}</S.Value>
          </S.ValueWrapper>

          <S.CoinIcon
            src={icons.moedaOuro}
            alt={t(`${translationString}.coinIcon.alt`)}
          />
        </S.ValueIconWrapper>
      </S.Infos>
    </S.Container>
  );
};

export default BattleCard;
