import { t } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const translationString = 'components.createTournament.schemas.formSchema';

const formSchema = yup.object().shape({
  date: yup.date().required(t(`${translationString}.date.required`)),
  time: yup.string().required(t(`${translationString}.time.required`)),
  startOption: yup
    .object({
      name: yup
        .string()
        .required(t(`${translationString}.startOption.name.required`))
    })
    .required(t(`${translationString}.startOption.required`))
});

export { formSchema };
