import icons from 'assets/img/icons';
import { useModal } from 'components/modules/Modal';
import { GeneralButton } from 'components/elements/GeneralButton';
import { InviteAFriend } from 'pages/Friends/components/InviteAFriend';
import React from 'react';
import { t } from 'translate/i18n';

import * as S from './EmptyStyled';

const Empty: React.FC = () => {
  const { showModal } = useModal();
  const translationString = 'components.friends.emptyList';

  const onClickToAddAFriend = (): void => {
    showModal(<InviteAFriend />, '468px', true, '45px 50px');
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.Icon src={icons.tagUser} />
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.Description>{t(`${translationString}.description`)}</S.Description>
        <GeneralButton
          size="medium"
          variant="primary"
          onClick={() => onClickToAddAFriend()}
        >
          {t(`${translationString}.button`)}
        </GeneralButton>
      </S.Container>
    </S.Wrapper>
  );
};

export default Empty;
