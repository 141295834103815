import styled, { css } from 'styled-components';

const ContainerSearch = styled.div<{ search?: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  margin: 0 20px;
  font-size: 15px;
  border-radius: 4px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px 0 20px;
  text-overflow: ellipsis;
  border: 1px solid #d4d7d4;
  justify-content: space-between;
  ${(props) =>
    props.search === false &&
    css`
      width: 100%;
      margin: 10px 0;
      border-radius: 8px;
    `};
`;
const Input = styled.input`
  border: 0;
  width: 100%;
  height: 100%;
  color: #9f9f9f;
  background-color: transparent;
  ::placeholder {
    color: #9f9f9f;
  }
  :focus {
    outline: none;
  }
  :-webkit-autofill {
    -webkit-background-clip: text !important;
  }
`;
const Icon = styled.img<{ iconUrl?: string }>`
  width: 17.5;
  cursor: pointer;
  display: ${(props) => (props.iconUrl === '' ? 'none' : 'flex')};
`;

export { ContainerSearch, Input, Icon };
