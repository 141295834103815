import styled from 'styled-components';
import media from 'styled-media-query';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 431px;
  align-items: flex-start;
  justify-content: flex-start;

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.6);
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        border-radius: 0px;
        color: rgba(255, 255, 255, 0.6);
        box-shadow: 0 0 0 1000px #292b42 inset;
        font-size: 16px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff61;
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #ffffff61;
        }
      }
    }
  }
  .MuiInputAdornment-root {
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;
    .MuiButtonBase-root {
      width: 100%;
      height: 100%;
      padding: 11px 20px;
      margin-right: -32px;
      background-color: #2f2744;
      border-radius: 0 !important;
      button:hover {
      }
      svg {
        width: 22px;
        height: 22px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    border-radius: 5px;
  }

  .css-1lwbda4-MuiStack-root {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
      .MuiOutlinedInput-input {
        border-radius: 4px !important;
      }
      .MuiOutlinedInput-root {
        padding-right: 0px;
        .MuiInputAdornment-root {
          display: none;
        }
      }
    }
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 47px;
      color: #ffffff61;
      background: transparent;
      border: 1px solid #ffffff61;
    }
    .selected-flag:hover,
    .flag-dropdown.open .selected-flag,
    .react-tel-input .selected-flag:focus {
      background-color: transparent;
    }
    .flag-dropdown {
      border-radius: 5px 0 0 5px;
      background-color: transparent;
      .country-list {
        width: 430px;
        color: #ffffff61;
        border-radius: 4px;
        filter: invert(0.02);
        background-color: #0d0b22;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5px;
          box-sizing: border-box;
          border: 1px solid #0f42f7;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: linear-gradient(0deg, #3b9ef1, #3b9ef1);
        }
        .country {
          :hover {
            background-color: #27244dad;
            background-color: #2d2d4a78;
          }
        }
        .country.highlight {
          background-color: #2d2d4a78;
        }
      }
    }
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 0px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
    -webkit-box-shadow: 0 0 0 1000px #292b42 inset;
    box-shadow: 0 0 0 1000px #292b42 inset;
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: 1rem;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 14px;
    min-height: 56px;
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px;
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: 1.6rem;
  }
  a {
    margin: 0 auto 48px;
  }
`;

const Register = styled.button`
  width: 212px;
  border: none;
  height: 48px;
  cursor: pointer;
  color: #ffffff;
  font-weight: 900;
  border-radius: 50px;
  font-family: 'Poppins';
  font-size: 19.2104px;
  transition: 0.2s color ease;
  background-color: #ffb620;
`;

const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  position: sticky;
  margin-bottom: 22px;
  align-items: center;
  justify-content: flex-start;
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const ContainerPassword = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  div:first-child {
    margin-right: 0px;
    width: 100% !important;
  }
  button {
    width: 45px;
    right: 13px;
    height: 55px;
    position: absolute;
    border-radius: 0px !important;
    background-color: transparent;
    color: #ffffff;
    :hover {
      background-color: transparent;
    }
    .css-i4bv87-MuiSvgIcon-root {
      font-size: 25px;
    }
  }
`;

const ChangePassword = styled.span`
  width: 100%;
  cursor: pointer;
  text-align: end;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  margin-bottom: 22px;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.6);
`;

export { Form, Register, ChangePassword, ContainerInput, ContainerPassword };
