import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const PasswordContainer = styled.div`
  width: 100vw;
  height: auto;
  max-width: 479px;
`;
export const Content = styled.div<{
  column?: boolean;
  padding?: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: ${(props) => props.padding};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};
`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 22px;
  align-items: flex-start;
  justify-content: flex-start;

  .MuiFormControl-root {
    margin: 0;
    width: 100%;

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        width: 81%;
        border-radius: 0px;
        color: ${(props) => props.theme.palette.text.light};
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.text.light};
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${(props) => props.theme.palette.text.light};
        }
      }
    }
  }

  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.palette.text.light};
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }
  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }
  legend {
    font-size: 1.2rem;
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 0px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  .Mui-error {
    color: ${(props) => props.theme.palette.error.main};
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
    -webkit-box-shadow: 0 0 0 1000px inset;
    box-shadow: 0 0 0 1000px inset;
    mix-blend-mode: screen;
  }
`;
export const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;
  border-radius: 5px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: flex-start;

  input {
    font-family: 'Roboto';
    letter-spacing: 0.15px;
    font-size: ${(props) => props.theme.typography.fontSize}rem;
    ::placeholder {
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
  }
  ${(props) =>
    props.error &&
    css`
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props.theme.palette.error.main};
      }
      .MuiInputLabel-formControl {
        color: ${props.theme.palette.error.main};
      }
    `};

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
export const Text = styled.p<{ yellow?: boolean }>`
  font-family: 'Roboto';
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  ${(props) =>
    props.yellow &&
    css`
      cursor: pointer;
      color: ${props.theme.palette.button.default};
      font-size: ${props.theme.typography.fontSize}rem;
    `};
`;
export const ContainerPassword = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  div:first-child {
    margin-right: 0px;
    width: 100% !important;
  }
  button {
    width: 45px;
    right: 13px;
    height: 55px;
    color: #ffffff;
    position: absolute;
    border-radius: 0px !important;
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
    .css-i4bv87-MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.3}rem;
    }
  }
`;
export const ContainerButtons = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 0 24px;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const Continue = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
export const Cancel = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
