import React, { Children, ReactElement } from 'react';
import { Props } from './@types';
import * as S from './FormStyled';

const Form: React.FC<Props> = ({ onSubmit, children }) => {
  function renderChildrens(): ReactElement[] | null | undefined {
    return Children.map(children, (child: ReactElement) => {
      if (child.props.id) {
        return (
          <S.ContainerInput error={child.props.error}>{child}</S.ContainerInput>
        );
      }

      return child;
    });
  }

  return <S.Form onSubmit={onSubmit}>{renderChildrens()}</S.Form>;
};

export default Form;
