/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './@types/actions';

const notificationReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.OPEN:
      return {
        ...state,
        notification: {
          open: true,
          message: action.payload.message
        }
      };

    case Actions.CLOSE:
      return {
        ...state,
        notification: {
          open: false,
          message: ''
        }
      };

    default:
      return {};
  }
};

export default notificationReducer;
