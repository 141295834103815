/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import { UserPayload } from 'models/User';
import React, { ChangeEvent, useContext, useState } from 'react';
import { servicesApis } from 'service/service.api';
import { NotificationContext } from 'state/notification/state';
import { Actions as UserActions } from 'state/user/@types/actions';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { UserContext } from 'state/user/state';
import { t } from 'translate/i18n';
import { ChangePersonalDataType, DropdownComponents } from '../../../@types';
import { userProfileSchema } from '../../../UserProfileSchema';
import { Props } from '../../@types';
import Form from '../../Form';
import PhoneField from '../../PhoneField';
import SaveButton from '../../SaveButton';
import TextField from '../../TextField';
import * as S from './PersonalDataStyled';

const PersonalData: React.FC<Props> = ({ active, setActive }) => {
  const [load, setLoad] = useState(false);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const { dispatch: notificationDisptach } = useContext(NotificationContext);
  const translationComponent = 'components.userProfile.menus.personalData';

  const getformattedDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR');
  };

  async function onSubmit(data: ChangePersonalDataType): Promise<void> {
    // eslint-disable-next-line no-use-before-define
    if (formik.errors.phone) return;

    setLoad(true);

    const [day, month, year] = data.birthday.split('/');
    const nascimento = new Date(`${year}/${month}/${day}`).toISOString();

    const userData: UserPayload = {
      phone: data.phone,
      nickname: data.nickname,
      email: userState.user.email,
      image_url: userState.user.image_url,
      birthday: nascimento
    };

    try {
      await servicesApis.user
        .putUserData(userState.user.entityId, userData)
        .then(() => {
          userDispatch({
            type: UserActions.SET_PERSONAL_DATA,
            payload: {
              phone: data.phone,
              nickname: data.nickname,
              birthday: nascimento
            }
          });
          setLoad(false);
          notificationDisptach({
            type: NotificationActions.OPEN,
            payload: {
              message: t(`${translationComponent}.messages.requestSuccessfully`)
            }
          });
        });
    } catch (error: any) {
      setLoad(false);
      notificationDisptach({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationComponent}.messages.requestFailed`)
        }
      });
    }
  }

  const formik = useFormik<ChangePersonalDataType>({
    enableReinitialize: true,
    initialValues: {
      phone: userState.user.phone,
      nickname: userState.user.nickname,
      birthday: getformattedDate(userState.user.birthday)
    },
    onSubmit,
    validationSchema: userProfileSchema
  });

  function mask(value: string, pattern: string): string {
    let index = 0;
    const string = value.toString();
    // eslint-disable-next-line no-plusplus
    return pattern.replace(/#/g, () => string[index++] || '');
  }

  function onChangeBirthday(e: ChangeEvent<HTMLInputElement>): void {
    const birthday = e.target.value || '';
    const inputType: string = (e.nativeEvent as InputEvent).inputType || '';

    if (inputType === 'deleteContentBackward') {
      formik.setFieldValue('birthday', birthday);
    } else {
      formik.setFieldValue(
        'birthday',
        mask(birthday.replace(/[//]/g, ''), '##/##/####')
      );
    }
  }

  function onClickOverMenu(): void {
    setActive({
      active: !active,
      component: DropdownComponents.PERSONAL_DATA
    });
  }

  const isPhoneNumberValid = (valid: boolean): void => {
    if (!valid && !formik.errors.phone) {
      formik.setFieldError('phone', 'Número invalido');
    }
  };

  const nicknameError: boolean | undefined =
    formik.touched.nickname && Boolean(formik.errors.nickname);

  const birthdayError: boolean | undefined =
    formik.touched.birthday && Boolean(formik.errors.birthday);

  const numberOfErrors: number = Object.values(formik.errors).length || 0;

  const phoneError: boolean | undefined =
    formik.touched.phone && Boolean(formik.errors.phone);

  const error: boolean | undefined =
    nicknameError || birthdayError || phoneError;

  const translationComponentPhoneNumber =
    'components.userProfile.menus.phoneNumber';

  return (
    <S.DropDown active={active} error={error} numberOfErrors={numberOfErrors}>
      <S.Menu onClick={() => onClickOverMenu()}>
        <S.Title>{t(`${translationComponent}.title`)}</S.Title>
        <ArrowForwardIosIcon />
      </S.Menu>

      <Form onSubmit={formik.handleSubmit}>
        <TextField
          id="nickname"
          type="text"
          variant="outlined"
          autoComplete="off"
          error={nicknameError}
          value={formik.values.nickname}
          onChange={formik.handleChange}
          label={t(`${translationComponent}.nameInputPlaceholder`)}
          helperText={formik.touched.nickname && formik.errors.nickname}
        />
        <TextField
          id="birthday"
          type="text"
          variant="outlined"
          autoComplete="off"
          error={birthdayError}
          value={formik.values.birthday}
          label={t(`${translationComponent}.birthdayInputPlaceholder`)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBirthday(e)}
          helperText={formik.touched.birthday && formik.errors.birthday}
        />
        <PhoneField
          id="phone"
          error={phoneError}
          value={formik.values.phone}
          label={t(`${translationComponentPhoneNumber}.newNumberPlaceholder`)}
          onChange={(_a, _b, event: ChangeEvent<HTMLInputElement>) =>
            formik.handleChange(event)
          }
          errorMessage={t(
            `components.userProfile.schemas.userProfileSchema.phone.invalid`
          )}
          isValid={isPhoneNumberValid}
        />
        <SaveButton loading={load} onClick={formik.handleSubmit} />
      </Form>
    </S.DropDown>
  );
};

export default PersonalData;
