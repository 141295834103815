/* eslint-disable @typescript-eslint/no-explicit-any */
import { Matchbet } from 'pages';
import React, { useContext, useEffect } from 'react';
import { NftStoreListContext } from 'state/nft/state';
import { servicesApis } from 'service/service.api';
import { CustomCircularProgress } from 'components/modules';
import { Actions } from 'state/nft/@types/actions';
import { Display } from './components/Display';
import { Header } from './components/Header';
import { NftStoreItems } from './components/NftStoreItems';
import { NftUserItems } from './components/NftUserItems';
import * as S from './NftStoreStyled';

const NftStore: React.FC = () => {
  const { state, dispatch } = useContext(NftStoreListContext);

  const translationString = 'components.nft';
  const get_nft_store_item_list = async (): Promise<void> => {
    try {
      const nftStoreList = await servicesApis.nftStore.getNftStoreList();
      dispatch({
        type: Actions.UPDATE_ALL_NFT_LIST,
        payload: nftStoreList
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!state.reload) return;
    get_nft_store_item_list().catch((error) => console.error(error));
  }, [state.reload]);

  const get_loading = () => {
    if (state.reload) {
      return <CustomCircularProgress />;
    }
    return null;
  };
  return (
    <Matchbet>
      <S.Container>
        <S.HeaderContainer>
          <Header />
          {get_loading()}
        </S.HeaderContainer>
        {state.reload ? null : (
          <>
            <NftStoreItems translationString={translationString} />
            <S.DisplayContainer>
              <Display />
            </S.DisplayContainer>
            <NftUserItems translationString={translationString} />
          </>
        )}
      </S.Container>
    </Matchbet>
  );
};

export default NftStore;
