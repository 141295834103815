import styled, { css } from 'styled-components';

const ContainerGeneric = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`;
export const NftUserViewProductContainer = styled(ContainerGeneric)``;
export const Img = styled.img<{ small?: boolean }>`
  width: 100%;
  max-width: 492px;
  max-height: 486px;
  border-radius: 10px;
  ${(props) =>
    props.small &&
    css`
      cursor: pointer;
      max-width: 156px;
      max-height: 154px;
    `};
`;
export const Container = styled(ContainerGeneric)`
  height: auto;
  align-self: start;
  align-items: start;
  gap: 15px;
`;
export const Itens = styled(ContainerGeneric)`
  width: auto;
  margin-left: 12px;
  margin-right: 33px;
  flex-direction: column;
`;
export const Title = styled.h2`
  font-family: 'Russo One';
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.4}rem;

  @media (max-width: 768px) {
    margin-top: 4%;
  }
`;
export const Text = styled.p<{ margin?: string; roboto?: boolean }>`
  max-width: 312px;
  font-style: normal;
  line-height: 181%;
  letter-spacing: 0.0015em;
  margin: ${(props) => props.margin};
  color: ${(props) => props.theme.palette.background.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-family: ${(props) => (props.roboto ? 'Roboto' : 'Poppins')};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  @media (max-width: 768px) {
    text-align: justify;
  }
`;
export const SubTitle = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
export const ContainerBuy = styled.div`
  top: 100%;
  width: 100%;
  gap: 0 20px;
  display: grid;
  position: inherit;
  align-items: center;
  justify-items: right;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'current'
    'valueBet';
  p {
    grid-area: current;
  }
  h2 {
    grid-area: valueBet;
  }
`;
export const ContainerDescription = styled(ContainerGeneric)`
  position: sticky;
  text-align: start;
  align-self: start;
  align-items: start;
  flex-direction: column;
  justify-content: flex-start;
`;
export const Video = styled.video<{ small?: boolean }>`
  width: 492px;
  height: 486px;
  object-fit: cover;
  border-radius: 10px;
  ${(props) =>
    props.small &&
    css`
      width: 156px;
      height: 154px;
      cursor: pointer;
    `};
`;
