export const createBattle = {
  title: 'Criar batalha',
  auxiliarTitle: 'Nova batalha',
  description: {
    matchFormat:
      'Escolha o seu formato de partida, jogue sozinho ou em um time com seus amigos',
    selectAGame:
      'Selecione um jogo para continuar, você pode escolher um jogo da plataforma ou adicionar um novo jogo.',
    challenge: 'Desafie os seus amigos ou aceite uma bet aberta'
  },
  addButton: 'Adicionar',
  newGlobalBet: 'Nova Batalha Global',
  subtitle: 'Desafie um/os amigo(s)',
  search: {
    placeholder: 'Procurar'
  },
  platformFilter: {
    gamesFor: 'Jogos para',
    allGames: 'Todos os jogos',
    victoryValidation: 'Validador de vitória'
  },
  teamCard: {
    select: 'Selecionar',
    challenge: 'Desafiar',
    view: 'Visualizar'
  },
  card: {
    select: 'Selecionar',
    openBets: 'bets abertos'
  },
  platforms: {
    onThePlatform: 'Na(s) plataforma(s)'
  },
  betAmount: {
    title: 'Valor da aposta',
    subTitle: 'Definir o valor da apostar da batalha',
    amount: 'Quantidade de Bet',
    balance: 'Balanço:',
    eyeIcon: {
      balance: {
        alt: 'Ver balanço'
      },
      password: {
        alt: 'Ver senha'
      }
    },
    password: 'Senha financeira',
    description:
      'No caso de jogos jogados em equipe, o valor é depositado e retirado apenas do utilizador responsável pela equipe.',
    buttons: {
      cancel: 'Voltar',
      create: 'Criar aposta',
      next: 'Avançar'
    }
  },
  amountBetFilter: {
    title: 'Quantia em Bet',
    lowestPlaceholder: 'Bet mínimo',
    highPlaceholder: 'Bet máximo'
  },
  list: {
    challengePlayerWarning: 'Você não possui amigos para desafiar'
  },
  openGlobalBets: {
    title: 'Escolha uma Batalha Global',
    card: {
      image: {
        alt: 'foto de usuário'
      },
      valueLabel: 'valor da aposta',
      button: 'Aceitar',
      coin: {
        alt: 'moeda'
      }
    },
    emptyList: {
      title: 'Batalhas Globais abertas',
      description: 'As Batalhas Globais disponíveis irão aparecer aqui'
    }
  }
};
