import { getImage } from 'assets/img';
import { t } from 'translate/i18n';
import { emptyStateProps } from './@types';
import * as S from './EmptyStateStyled';

const EmptyState = ({ type }: emptyStateProps) => {
  const typeOfScreen =
    type === 'my_tournaments'
      ? 'userTournament.yourTournaments'
      : 'battle.yourBattles';
  const translationString = `components.${typeOfScreen}`;

  return (
    <S.EmptyStateContainer>
      <S.ContentContainer>
        <S.TitleEmptyState>
          {type
            ? t(`${translationString}.emptyState.title`)
            : t(`${translationString}.emptyState.titleHistory`)}
        </S.TitleEmptyState>
        {type && (
          <S.ParagraphEmptyState>
            {t(`${translationString}.emptyState.paragraph`)}
          </S.ParagraphEmptyState>
        )}
      </S.ContentContainer>
      {type && <S.EmojisImage src={getImage('EmojiIcons')} />}
    </S.EmptyStateContainer>
  );
};

export default EmptyState;
