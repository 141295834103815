import React from 'react';

import { HeaderProps } from '../../@types';

import * as S from './TableHeaderStyled';

export const TableHeader = ({ headerContent }: HeaderProps) => {
  return (
    <S.ContainerTableHeader>
      {headerContent.columns.map((column) => column.content)}
    </S.ContainerTableHeader>
  );
};
