import { number, object, string } from 'yup';

import TournamentModalModel from './TournamentModalModel';

const {
  name,
  description,
  rules,
  timeBetweenStages,
  stageTime,
  readyTimeout,
  stagesPerDay,
  minTimeBetweenStages,
  minStageTime,
  minReadyTimeOut,
  minStagesPerDay
} = TournamentModalModel;

export const validationSchema = (getMessageError: (key: string) => string) =>
  object().shape({
    [name.key]: string().required(getMessageError(name.key)),
    [description.key]: string().required(getMessageError(description.key)),
    [rules.key]: string().required(getMessageError(rules.key)),
    [timeBetweenStages.key]: number()
      .required(getMessageError(timeBetweenStages.key))
      .min(5, getMessageError(minTimeBetweenStages.key)),
    [stageTime.key]: number()
      .required(getMessageError(stageTime.key))
      .min(5, getMessageError(minStageTime.key)),
    [readyTimeout.key]: number()
      .required(getMessageError(readyTimeout.key))
      .min(3, getMessageError(minReadyTimeOut.key)),
    [stagesPerDay.key]: number()
      .required(getMessageError(stagesPerDay.key))
      .min(2, getMessageError(minStagesPerDay.key))
  });
