import React, { useEffect, useState } from 'react';

import { t } from 'translate/i18n';

import { servicesApis } from 'service/service.api';

import { GeneralBattle } from 'models/InfoCard';
import { useModal } from 'components/modules/Modal';
import {
  FormEnterBattle,
  useBattle
} from 'components/modules/EventModal/Battle';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CustomCircularProgress,
  EventModal,
  OpenEvents
} from 'components/modules';
import { EmptyList } from 'components/elements';
import { BattleCard } from './components/BattleCard';

import * as S from './OpenGlobalBattlesStyled';

const OpenGlobalBattles: React.FC = () => {
  const [openBattles, setOpenBattles] = useState<GeneralBattle[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const { showModal } = useModal();
  const { onSubmitEventModal } = useBattle();

  const translationString = 'components.battle.openGlobalBattles';

  const loadMore = async (): Promise<void> => {
    try {
      const battles = await servicesApis.battle.getGlobalBattles(
        'alone',
        page,
        '',
        '',
        ''
      );

      setOpenBattles([...openBattles, ...battles]);

      if (battles.length <= 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadMore();
  }, [page]);

  const acceptBattle = (battle: GeneralBattle): void => {
    showModal(
      <EventModal
        data={{
          amount: battle.amount,
          image_url: battle.selected_game.image_url,
          title: battle.selected_game.name,
          translationString: 'components.battle.acceptBattle',
          platform: battle.selected_platform,
          entity_id: battle.entity_id
        }}
        Children={FormEnterBattle}
        onSubmit={onSubmitEventModal}
      />,
      'min-content',
      true,
      '42px'
    );
  };

  const renderOpenBattle = (item: GeneralBattle): JSX.Element => {
    return (
      <BattleCard
        gameName={item.selected_game.name}
        gameImage={item.selected_game.image_url || ''}
        platform={item.selected_platform}
        amount={item.amount}
        onAccept={() => acceptBattle(item)}
      />
    );
  };

  return (
    <OpenEvents
      title={t(`${translationString}.title`)}
      description={t(`${translationString}.description`)}
      empty={openBattles.length <= 0}
      emptyList={
        <EmptyList
          title={t(`${translationString}.emptyList.title`)}
          description={t(`${translationString}.emptyList.description`)}
        />
      }
    >
      <InfiniteScroll
        dataLength={openBattles.length}
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={
          <S.LoadingWrapper>
            <CustomCircularProgress />
          </S.LoadingWrapper>
        }
      >
        {openBattles.map(renderOpenBattle)}
      </InfiniteScroll>
    </OpenEvents>
  );
};

export default OpenGlobalBattles;
