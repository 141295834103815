import styled from 'styled-components';
import CachedIcon from '@mui/icons-material/Cached';

export const Container = styled.button`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  padding-bottom: 8px;
  border: 0;
  cursor: pointer;
`;

export const Message = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 10px;
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Refresh = styled(CachedIcon).attrs({
  htmlColor: '#fff',
  fontSize: 'large'
})`
  transform: scale(1.5);
`;
