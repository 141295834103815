import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { changePassword } from 'service/auth.service';
import { Actions } from 'state/notification/@types/actions';
import { NotificationContext } from 'state/notification/state';
import { t } from 'translate/i18n';
import { DropdownComponents, PlatformPasswordType } from '../../../@types';
import { platformPasswordSchema } from '../../../UserProfileSchema';
import { Props } from '../../@types';
import Form from '../../Form';
import SaveButton from '../../SaveButton';
import TextField from '../../TextField';
import * as S from './PlatformPasswordStyled';

const PlatformPassword: React.FC<Props> = ({ active, setActive }) => {
  const [load, setLoad] = useState(false);

  const { dispatch } = useContext(NotificationContext);

  const formik = useFormik<PlatformPasswordType>({
    initialValues: {
      newPassword: '',
      currentPassword: '',
      confirmPassword: ''
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit,
    validationSchema: platformPasswordSchema
  });

  async function onSubmit(data: PlatformPasswordType): Promise<void> {
    setLoad(true);

    const translationSchema =
      'components.userProfile.schemas.platformPasswordSchema';

    try {
      await changePassword(data.currentPassword, data.newPassword);
      dispatch({
        type: Actions.OPEN,
        payload: {
          message: t(`${translationSchema}.submit.success`)
        }
      });
    } catch (error) {
      if (error === 'NotAuthorizedException') {
        formik.setFieldError(
          'currentPassword',
          t(`${translationSchema}.submit.wrongPassword`)
        );
      }

      if (error === 'LimitExceededException') {
        dispatch({
          type: Actions.OPEN,
          payload: {
            message: t(`${translationSchema}.submit.callsExceeded`)
          }
        });
      }
    }

    setLoad(false);
  }

  function onClickOverMenu(): void {
    setActive({
      active: !active,
      component: DropdownComponents.PLATFORM_PASSWORD
    });
  }

  const currentPasswordError: boolean | undefined =
    formik.touched.currentPassword && Boolean(formik.errors.currentPassword);

  const newPasswordError: boolean | undefined =
    formik.touched.newPassword && Boolean(formik.errors.newPassword);

  const confirmPasswordError: boolean | undefined =
    formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword);

  const error: boolean | undefined =
    currentPasswordError || newPasswordError || confirmPasswordError;

  const numberOfErrors: number = Object.values(formik.errors).length || 0;

  const translationComponent = 'components.userProfile.menus.platformPassword';

  return (
    <S.DropDown active={active} error={error} numberOfErrors={numberOfErrors}>
      <S.Menu onClick={() => onClickOverMenu()}>
        <S.Title>{t(`${translationComponent}.title`)}</S.Title>
        <ArrowForwardIosIcon />
      </S.Menu>

      <Form onSubmit={formik.handleSubmit}>
        <S.Subtitle>{t(`${translationComponent}.description`)}</S.Subtitle>
        <TextField
          id="currentPassword"
          type="password"
          label={t(`${translationComponent}.currentPasswordPlaceholder`)}
          variant="outlined"
          autoComplete="off"
          sx={{
            '& legend': {
              fontSize: '1.2rem'
            }
          }}
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          error={currentPasswordError}
          helperText={
            formik.touched.currentPassword && formik.errors.currentPassword
          }
        />
        <TextField
          id="newPassword"
          type="password"
          label={t(`${translationComponent}.newPasswordPlaceholder`)}
          variant="outlined"
          autoComplete="off"
          sx={{
            '& legend': {
              fontSize: '1.2rem'
            }
          }}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={newPasswordError}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <TextField
          id="confirmPassword"
          type="password"
          label={t(`${translationComponent}.confirmPasswordPlaceholder`)}
          variant="outlined"
          autoComplete="off"
          sx={{
            '& legend': {
              fontSize: '1.2rem'
            }
          }}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={confirmPasswordError}
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
        <SaveButton loading={load} onClick={formik.handleSubmit} />
      </Form>
    </S.DropDown>
  );
};

export default PlatformPassword;
