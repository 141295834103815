import styled, { css } from 'styled-components';
import { GoBack } from 'components/elements';
import { Search as SearchComponent } from 'components/modules/Search';

export const Container = styled.div<{ step: number }>`
  width: 100%;
  padding: 46px 50px;
  overflow: hidden;
  position: relative;

  ${({ step }) =>
    step === 3 &&
    window.innerWidth > 1268 &&
    css`
      display: grid;
      grid-template-columns: 1fr 400px;
      grid-template-rows: 1fr;
      grid-template-areas: 'list openBets';
      gap: 22px;
    `}

  @media (max-width: 768px) {
    padding: 43px 30px;
  }
`;

export const ListWrapper = styled.div`
  grid-area: list;

  .MuiBox-root {
    z-index: 1;
    width: 100%;
    display: flex;
    height: 500px;
    align-items: center;
    justify-content: center;
    img {
      zoom: 1.5;
    }
  }
`;

export const OpenBetsWrapper = styled.div<{ step: number }>`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 19.1784px;
  grid-area: openBets;
  max-height: 849px;

  ${({ step }) =>
    (step !== 3 || window.innerWidth <= 1268) &&
    css`
      display: none;
    `}
`;

export const Title = styled.h1`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.title.default};
  margin-top: 16px;
  padding-left: 1px;

  @media (max-width: 768px) {
    font-size: 3.6rem;
    line-height: 43px;
    margin-top: 20px;
    padding-left: 7px;
  }
`;

export const AuxiliarTitle = styled.p`
  top: 9px;
  left: 40px;
  z-index: 0;
  width: 200px;
  font-weight: 400;
  font-style: normal;
  line-height: 225px;
  color: transparent;
  position: absolute;
  font-size: 186.711px;
  font-family: 'Russo One';
  text-transform: uppercase;
  -webkit-text-stroke: 1.24474px rgba(255, 255, 255, 0.1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;

  @media (max-width: 768px) {
    font-size: 8rem;
    line-height: 96px;
    top: 40px;
    left: 30px;
  }
`;

export const Description = styled.p<{ step?: number }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
  max-width: 370px;
  margin-top: 6px;
  padding-left: 1px;

  @media (max-width: 768px) {
    margin-top: 10px;
    line-height: 20px;
    padding-left: 7px;
  }

  ${({ step }) =>
    step === 2 &&
    css`
      max-width: 415.25px;
    `}

  ${({ step }) =>
    step === 3 &&
    css`
      max-width: 425.85px;
    `}
`;

export const Subtitle = styled.h2`
  width: 150px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 27px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Wrapper = styled.div<{ step: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1096px) {
    justify-content: unset;
    flex-direction: column;
  }

  ${({ step }) =>
    step &&
    css`
      margin-top: 30px;
    `}
`;

export const WrapperTexts = styled.div`
  width: 100%;
`;

export const WrapperGoBack = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 18px;
`;

export const SearchContainer = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  gap: 17px;
  justify-content: flex-end;

  @media (max-width: 1096px) {
    margin-top: 53px;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  @media (max-width: 968px) {
    justify-content: center;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 616px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonGoBack = styled(GoBack)`
  margin-top: 20px;
`;

export const Search = styled(SearchComponent)``;
