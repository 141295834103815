import styled, { css } from 'styled-components';
import { Pagination as PaginationComponent } from 'components/elements';

export const Container = styled.div`
  width: 100%;
  padding: 0 53px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ContentLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const GoBack = styled.div`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;
  display: flex;
  gap: 17.43px;
  margin: 50px 0;
  width: 100%;
`;

export const ContentRight = styled.div`
  width: 100%;
  max-width: 301px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 50px;

  @media (max-width: 1050px) {
    display: none;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 66px;
  padding: 9.88526px;
  gap: 20.03px;
  border-radius: 9.88526px;
  background: rgba(255, 255, 255, 0.06);

  display: flex;
  align-items: center;
`;

export const Icon = styled.img<{ formated?: boolean }>`
  width: 28px;
  height: 28px;
  margin-right: 8px;
  ${(props) =>
    props.formated &&
    css`
      object-fit: cover;
    `};
`;

export const ContainerText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2<{
  yellow?: boolean;
  small?: boolean;
  registered?: boolean;
}>`
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  line-height: 115%;
  align-items: center;
  font-family: 'Russo One';
  letter-spacing: 0.0015em;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeBold + 0.9}rem;
  ${(props) =>
    props.yellow &&
    css`
      color: ${props.theme.palette.button.default};
    `};
  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.typography.fontSize - 0.3}rem;
    `};

  ${(props) =>
    props.registered &&
    css`
      margin-bottom: 1rem;
    `};
`;

export const Text = styled.p<{ small?: boolean }>`
  line-height: 180%;
  font-family: 'Poppins';
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${(props) =>
    props.small &&
    css`
      line-height: 150%;
      font-size: ${props.theme.typography.fontSize - 0.4}rem;
    `};
`;

export const Pagination = styled(PaginationComponent)`
  margin-top: 10px;
`;
