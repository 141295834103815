import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
`;

export const TableRowContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(58px * 9);
  overflow-y: hidden;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderStage = styled.div<{
  stageSelected: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `}

  ${({ stageSelected }) =>
    stageSelected &&
    css`
      ::after {
        content: '-';
        width: 100%;
        height: 2px;
        background-color: #a53afc;
      }
    `}
`;

export const HeaderStageTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.0015em;
  color: #ffffff;
`;

export const HeaderStageSubTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.005em;
  color: #ffffff;
`;

export const RowContainer = styled.div`
  width: 100%;
  height: 58px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  :nth-child(odd) {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const RowDate = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #ffffff;

  display: flex;
  flex-direction: column;
`;

export const RowCompetitors = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #ffffff;
`;

export const RowCompetitor = styled.div<{ flex: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ flex }) => flex};
  min-width: 170px;
  position: relative;

  p {
    ${({ flex }) =>
      flex === 'flex-end' &&
      css`
        right: 50px;
        text-align: end;
      `}

    ${({ flex }) =>
      flex === 'flex-start' &&
      css`
        left: 50px;
        text-align: start;
      `}
  }
`;

export const RowCompetitorContainerName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-size: 12px;
  position: absolute;
`;

export const RowCompetitorName = styled.p<{ textAlign?: string }>`
  font-family: 'Poppins';
  font-style: normal;
  font-size: 12px;
  text-align: ${({ textAlign }) => textAlign};
`;

export const RowCompetitorImg = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 6px;
`;

export const RowStatus = styled.div<{ tagColor?: string }>`
  width: 100%;
  max-width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 22px;
  background: ${({ tagColor }) => tagColor};
  border-radius: 4px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.004em;
  color: #ffffff;
`;

export const RowStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  max-width: 220px;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    max-width: 150px;
  }

  @media (max-width: 812px) {
    max-width: 100px;
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
