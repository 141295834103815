import React from 'react';

import images from 'assets/img';
import icons from 'assets/img/icons';

import { t } from 'translate/i18n';
import { formatMoney } from 'utils/formatMoney';

import { GeneralButton } from 'components/elements/GeneralButton';

import * as S from './BetCardStyled';

import { Props } from './@types';

const BetCard: React.FC<Props> = ({
  playerImage,
  playerNickname,
  amount,
  onAccept = () => {}
}) => {
  const translationString = 'components.createBattle.openGlobalBets.card';

  return (
    <S.Container>
      <S.PlayerImage
        src={playerImage || images.defaultImage}
        alt={t(`${translationString}.image.alt`)}
      />

      <S.Infos>
        <S.PlayerNickname>{playerNickname}</S.PlayerNickname>

        <S.Footer>
          <S.CoinValueWrapper>
            <S.CoinIcon
              src={icons.moedaOuro}
              alt={t(`${translationString}.coin.alt`)}
            />

            <S.ValueWrapper>
              <S.ValueLabel>
                {t(`${translationString}.valueLabel`)}
              </S.ValueLabel>
              <S.Value>{formatMoney(amount)}</S.Value>
            </S.ValueWrapper>
          </S.CoinValueWrapper>

          <GeneralButton size="small" variant="primary" onClick={onAccept}>
            {t(`${translationString}.button`)}
          </GeneralButton>
        </S.Footer>
      </S.Infos>
    </S.Container>
  );
};

export default BetCard;
