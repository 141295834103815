export const SideBar = {
  MatchBetTournament: 'Torneio MatchCash',
  Tournaments: 'Torneios',
  GlobalRanking: 'Ranking Global',
  MatchBetBanking: 'Banco MatchCash',
  Battle: 'Batalha',
  Friends: 'Amigos',
  NftStore: 'Loja de NFT',
  Store: 'Compre Matchcoins'
};
