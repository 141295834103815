import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { Matchbet } from 'pages';

import { servicesApis } from 'service/service.api';
import { usePagination } from 'utils/Pagination';
import images from 'assets/img';

import { GeneralButton } from 'components/elements/GeneralButton';
import { Pagination } from 'components/elements/Pagination';
import { Table } from 'components/modules/Table/Table';

import { SelectGamerTypes } from './components/SelectGamer';
import { SelectPlatformTypes } from './components/SelectPlatform';

import { HistoryEnum, yourHistory, OrdenationType } from './@types';

import * as S from './GlobalRankingStyled';

const GlobalRanking = () => {
  const navigate = useNavigate();

  const {
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data
  } = usePagination({ ROWS_REQUIRED: 7 });

  const listRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [dataHistory, setDataHistory] = useState<yourHistory>({
    battles: 0,
    image_url: '',
    entity_id: '',
    tournaments: 0,
    battles_won: 0,
    tournaments_won: 0,
    elo_points: 0,
    place_in_the_ranking: 0
  });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [games, setGames] = useState<SelectGamerTypes.Game[]>([]);
  const [gameSelected, setGameSelected] = useState<string>('');
  const [platforms, setPlatforms] = useState<SelectPlatformTypes.Platform[]>(
    []
  );

  const fetchGlobalRaning = async () => {
    setLoading(true);

    await servicesApis.globalRanking
      .getGlobalRanking(gameSelected)
      .then((response) => {
        calculatePaginationVars(response.ranking);
        setDataHistory(response.history_stats);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchGames = async () => {
    await servicesApis.battle
      .getGames()
      .then(async (responseGames) => {
        setGames(responseGames?.data);
        setGameSelected(responseGames?.data[0]?.entity_id);

        await servicesApis.battle
          .getPlatforms()
          .then((responsePlatforms) => {
            setPlatforms(responsePlatforms);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (gameSelected) {
      fetchGlobalRaning();
    }
  }, [gameSelected]);

  useEffect(() => {
    fetchGames();
  }, []);

  const translationString = 'components.globalRanking';

  const placesYourHistory: HistoryEnum[] = [
    HistoryEnum.place_in_the_ranking,
    HistoryEnum.elo_points,
    HistoryEnum.tournaments,
    HistoryEnum.battles_won,
    HistoryEnum.tournaments_won,
    HistoryEnum.battles_won
  ];

  const availablePlatforms = games.find(
    (game: SelectGamerTypes.Game) => game.entity_id === gameSelected
  )?.platforms;

  const headerContent = {
    columns: [
      {
        key: 'position',
        content: <S.TextHeader size="8%">Ranking</S.TextHeader>
      },
      {
        key: 'user',
        content: (
          <S.TextHeader size="40%">
            {t(`${translationString}.table.user_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="17%">
            {t(`${translationString}.table.${OrdenationType.battles_won}_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="17%">
            {t(
              `${translationString}.table.${OrdenationType.tournaments_won}_text`
            )}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="17%">
            {t(`${translationString}.table.${OrdenationType.elo_points}_text`)}
          </S.TextHeader>
        )
      }
    ]
  };

  const rowContent = data?.map((item) => ({
    key: item.user.name,
    columns: [
      {
        key: 'position',
        content: (
          <S.ContainerPosition size="8%">
            <S.Position>{item.position}</S.Position>
          </S.ContainerPosition>
        )
      },
      {
        key: 'user',
        content: (
          <S.ContentInfo>
            <S.ImgUser
              src={item.user.image_url || images.defaultImage}
              alt={t(`${translationString}.imgs_alt.user`)}
            />
            <S.Name>{item.user.name}</S.Name>
          </S.ContentInfo>
        )
      },
      {
        key: 'ordenation_type',
        content: <S.TextRow>{item.battles_won}</S.TextRow>
      },
      {
        key: 'ordenation_type',
        content: <S.TextRow>{item.tournaments_won}</S.TextRow>
      },
      {
        key: 'ordenation_type',
        content: <S.TextRow>{item.elo_points}</S.TextRow>
      }
    ]
  }));

  return (
    <Matchbet>
      <S.AuxiliarTitle>
        {t(`${translationString}.auxiliarTitle`)}
      </S.AuxiliarTitle>
      <S.Container>
        <S.ContainerText>
          <S.Title>{t(`${translationString}.title`)}</S.Title>
          <S.SubContent>
            <S.Description>
              {t(`${translationString}.description`)}
            </S.Description>
          </S.SubContent>
        </S.ContainerText>

        <S.SelectPlatforms
          isLoading={isLoading}
          platforms={platforms}
          availablePlatforms={availablePlatforms}
        />

        <S.SelectGamers
          isLoading={isLoading}
          gamers={games}
          gameSelected={gameSelected}
          setGameSelected={setGameSelected}
        />

        <S.ContainerRanking>
          <Table
            headerContent={headerContent}
            rowContent={rowContent}
            listRef={listRef}
            isLoading={isLoading}
          />
          {!isLoading && (
            <S.PaginationWrapper>
              <Pagination
                selectedPage={selectedPage}
                numberOfPages={numberOfPages}
                clickOnRightArrow={clickOnRightArrow}
                clickOnLeftArrow={clickOnLeftArrow}
                clickOnPageIndicator={clickOnPageIndicator}
              />
            </S.PaginationWrapper>
          )}
        </S.ContainerRanking>

        <S.ContainerHistory>
          <S.Title>{t(`${translationString}.yourHistory.title`)}</S.Title>
          <S.Text>{t(`${translationString}.yourHistory.text`)}</S.Text>
          {placesYourHistory.map((place: HistoryEnum) => (
            <S.Info>
              {t(`${translationString}.yourHistory.${place}_text`)}
              <p>
                {isLoading ? (
                  <Skeleton width={40} variant="text" />
                ) : (
                  dataHistory[place]
                )}
              </p>
            </S.Info>
          ))}
          <GeneralButton
            size="medium"
            variant="primary"
            onClick={() => navigate('/matchbet/yourHistory')}
          >
            {t(`${translationString}.yourHistory.button`)}
          </GeneralButton>
        </S.ContainerHistory>
      </S.Container>
    </Matchbet>
  );
};

export default GlobalRanking;
