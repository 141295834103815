import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled from 'styled-components';
import media from 'styled-media-query';

const ConnectWalletContainer = styled.div`
  width: 479px;
  height: auto;
  ${media.lessThan('medium')`
  `}
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h2`
  display: flex;
  margin-top: 27px;
  line-height: 115%;
  align-items: center;
  font-family: 'Roboto';
  letter-spacing: 0.0015em;
  text-transform: uppercase;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
const Text = styled.p`
  gap: 5px;
  height: 100%;
  text-align: center;
  max-width: 218px;
  margin: 8px 0 28px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const Icon = styled.img``;
const Connect = styled(ButtonSmall)`
  margin: 0;
  max-width: 153px;
`;
export { Text, Icon, Title, Content, Connect, ConnectWalletContainer };
