import icons from 'assets/img/icons';
import React from 'react';
import { Props } from './@types';
import * as S from './CloseButtonStyled';

const CloseButton: React.FC<Props> = ({ onClick = () => {} }) => (
  <S.CloseButtonContainer onClick={onClick}>
    <S.CloseIcon src={icons.closeButton} />
  </S.CloseButtonContainer>
);

export default CloseButton;
