/* eslint-disable react/button-has-type */
import Icons from 'assets/img/icons';
import { Auth } from 'aws-amplify';
import { Card } from 'components/modules/Card';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureControlContext } from 'state/featureControl/state';
import { Actions as MenuActions } from 'state/menu/@types/actions';
import { MenuContext } from 'state/menu/state';
import { Actions as ProfileSettingsActions } from 'state/profileSettings/@types/actions';
import { ProfileSettingsContext } from 'state/profileSettings/state';
import { t, i18n } from 'translate/i18n';
import { Actions as AuthActions } from 'state/auth/@types/actions';
import { AuthContext } from 'state/auth/state';
import { BattleContext } from 'state/battle/state';
import Notifications from 'components/modules/Notifications/Notifications';
import { Route } from './@types';

import * as S from './MenuMobileStyled';

const MenuMobile = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const { state } = useContext(FeatureControlContext);
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { state: battleState } = useContext(BattleContext);
  const { dispatch: menuDispatch } = useContext(MenuContext);

  const profileSettingsContext = useContext(ProfileSettingsContext);
  const navigation = useNavigate();

  const logout = () => {
    Auth.signOut()
      .then(() => {
        authDispatch({
          type: AuthActions.USER_IS_UNSIGNED
        });
        menuDispatch({
          type: MenuActions.RESET_MENU
        });
        navigation('/', { replace: true });
      })
      .catch();
  };

  const openProfileSettings = (): void => {
    profileSettingsContext?.dispatch({
      type: ProfileSettingsActions.OPEN_MENU
    });
  };

  const pushRouter = ({ path, name }: Route): void => {
    setActiveMenu(false);
    navigation(`/matchbet/${path}`, { replace: true });
    menuDispatch({
      type: MenuActions.SET_MENU,
      payload: {
        current: name
      }
    });
  };

  const onToggleMenu = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.scrollTo({ top: 0, behavior: 'instant' } as any);
    setActiveMenu(!activeMenu);
  };

  const setBlockScroll = (block: boolean): void => {
    const html: HTMLElement | null = document.querySelector('html');

    if (html) {
      html.style.overflow = block ? 'hidden' : '';
    }
  };

  const shouldAllowScroll = (): void => {
    const SECOND = 1000;

    if (activeMenu) {
      setBlockScroll(activeMenu);
    } else {
      setTimeout(() => {
        setBlockScroll(activeMenu);
      }, SECOND);
    }
  };

  useEffect(() => {
    shouldAllowScroll();
  }, [activeMenu]);

  return (
    <S.MenuMobile>
      <S.Icon
        src={Icons.group_143}
        alt={t('components.header.menuMobile.matchbetIcon.alt')}
      />

      <S.ButtonWrapper>
        <Notifications />

        <Card
          width="50px"
          height="50px"
          padding="12px"
          background="#392E4F"
          onClick={onToggleMenu}
        >
          <S.Icon
            src={activeMenu ? Icons.closeOutline : Icons.menu}
            alt={t('components.header.menuMobile.openCloseIcon.alt')}
          />
        </Card>
      </S.ButtonWrapper>

      <S.ContainerLinks active={activeMenu}>
        {state.featureControl.matchBetTournament ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'matchBetTournament',
                name: 'MatchBet Tournament'
              })
            }
          >
            {t('components.header.menuMobile.labels.matchBetTournament')}
          </S.Link>
        ) : null}

        {state.featureControl.tournaments ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'tournaments',
                name: 'Tournaments'
              })
            }
          >
            {t('components.header.menuMobile.labels.tournaments')}
          </S.Link>
        ) : null}

        {state.featureControl.battle ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'battle',
                name: 'Battle'
              })
            }
          >
            {t('components.header.menuMobile.labels.battle')}
          </S.Link>
        ) : null}

        {state.featureControl.globalRanking ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'globalranking',
                name: 'Global Ranking'
              })
            }
          >
            {t('components.header.menuMobile.labels.globalRanking')}
          </S.Link>
        ) : null}

        {state.featureControl.friends ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'friends',
                name: 'Friends'
              })
            }
          >
            {t('components.header.menuMobile.labels.friends')}
          </S.Link>
        ) : null}

        {state.featureControl.chat &&
        battleState.battle.currentBattle.inProgress ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'chat',
                name: 'Chat'
              })
            }
          >
            {t('components.header.menuMobile.labels.chat')}
          </S.Link>
        ) : null}

        {state.featureControl.account ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'banking',
                name: 'MatchBet Banking'
              })
            }
          >
            {t('components.header.menuMobile.labels.matchBetBanking')}
          </S.Link>
        ) : null}

        <S.Link onClick={openProfileSettings}>
          {t('components.header.menuMobile.labels.profileSettings')}
        </S.Link>

        {state.featureControl.store ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'store/nfts',
                name: 'Store NFTs'
              })
            }
          >
            {t('components.header.menuMobile.labels.nft')}
          </S.Link>
        ) : null}

        {state.featureControl.buy_coins ? (
          <S.Link
            onClick={() =>
              pushRouter({
                path: 'store/buyCoins',
                name: 'MatchbetStore'
              })
            }
          >
            {t('components.header.menuMobile.labels.buyMatchcoins', {
              coin: i18n.t('titles.matchcoins')
            })}
          </S.Link>
        ) : null}

        <S.Logout onClick={() => logout()}>
          {t('components.header.menuMobile.labels.logout')}
        </S.Logout>
      </S.ContainerLinks>
    </S.MenuMobile>
  );
};

export default MenuMobile;
