import React, { useContext } from 'react';
import { ProfileNotificationsContext } from 'state/profileNotifications/state';
import icons from 'assets/img/icons';
import { Props } from './@types';

import * as C from './NotificationButtonStyles';

const NotificationButton: React.FC<Props> = ({ onClick = () => {} }) => {
  const { state } = useContext(ProfileNotificationsContext);

  const getNumberOfNotifications = (): string => {
    const { length } = state.notifications;

    if (length > 99) return '99+';
    return length.toString();
  };

  return (
    <C.Button onClick={onClick}>
      <C.Icon src={icons.notification} />
      {state.notifications?.length > 0 ? (
        <C.Badge>{getNumberOfNotifications()}</C.Badge>
      ) : null}
    </C.Button>
  );
};

export default NotificationButton;
