import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h1`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 4.2rem;
  line-height: 51px;
  color: ${({ theme }) => theme.palette.title.default};
`;

export const Description = styled.div`
  margin-top: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 24px;
  text-align: justify;
  letter-spacing: 0.15px;
  max-width: 518.91px;
  color: ${({ theme }) => theme.palette.text.white};
`;
