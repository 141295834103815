export const nft = {
  display: {
    valueLabel: 'Valor corrente',
    coin: 'MTC',
    button: {
      buy: 'COMPRAR',
      view: 'VER'
    }
  },
  header: {
    title: 'NFTs Machbet',
    description:
      'NFTS desbloqueiam certas funções na plataforma, acesso exclusivos a itens, criar torneios e outras funções.'
  },
  listAllNFTs: {
    title: 'NFTs disponíveis',
    yourNft: 'Seu NFT',
    days: 'dias',
    item: {
      valueLabel: 'Valor',
      button: {
        buy: 'COMPRAR',
        view: 'VER'
      }
    }
  },
  buyNft: {
    alt: 'Imagem da NFT',
    button: 'Comprar NFT',
    text: 'Valor atual',
    label: 'Senha financeira',
    success: 'Compra de NFT realizada com sucesso',
    error: 'Falha na compra da NFT'
  },
  userViewProduct: {
    button: 'COMPRAR',
    text: 'Valor atual'
  },
  listUserNFTs: {
    title: 'Suas NFTs',
    valid_until: 'Válido até',
    item: {
      valueLabel: 'Valor',
      button: {
        buy: 'COMPRAR',
        view: 'VER'
      }
    }
  }
};
