import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > button {
    margin: 40px auto 0px;
  }
`;

export const Row = styled.div<{ marginTop?: string; alignItems?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
`;

export const Column = styled.div<{ alignItems?: string; hide?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const Title = styled.h2`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
  width: 171px;

  @media (max-width: 970px) {
    width: 201px;
    line-height: 25px;
  }
`;

export const Description = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: #ffffff;
  margin-top: 15px;
  width: 133px;

  @media (max-width: 970px) {
    width: 207px;
    font-family: 'Roboto';
    font-size: 12px;
    letter-spacing: 0.15px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const QRCodeImage = styled.img`
  width: 77px;
  height: 77px;
  object-fit: contain;
`;

export const QRCodeDescription = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.004em;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 8px;
  width: 171px;
`;

export const PlayerPhoto = styled.img`
  width: 132px;
  height: 132px;
  object-fit: contain;

  ${media.lessThan('small')`
    width: 100%;
  `}

  @media (max-width: 400px) {
    margin-bottom: 20px;
  }
`;

export const PlayerName = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8451px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  margin-top: 11px;

  @media (max-width: 400px) {
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) scale(0.7);
  }

  @media (max-width: 340px) {
    transform: translateX(-50%) scale(0.5);
  }
`;

export const VersusIcon = styled.img`
  width: 21px;
  height: 21px;
  object-fit: contain;
  margin: 0 6px;
`;
