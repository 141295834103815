import styled from 'styled-components';
import media from 'styled-media-query';

export const ChatContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;

  ${media.lessThan('medium')`
    height: 200px;
  `}
`;
