/* eslint-disable import/no-duplicates */
import { format, Locale } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import React, { useContext, useState } from 'react';

import { NotificationContext } from 'state/notification/state';
import { ProfileNotificationsContext } from 'state/profileNotifications/state';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { Actions as ProfileActiosn } from 'state/profileNotifications/@types/actions';
import { getUserLanguage } from 'service/localStorage';
import { servicesApis } from 'service/service.api';
import { i18n, t } from 'translate/i18n';
import { useModal } from 'components/modules/Modal';

import { GeneralBattle } from 'models/InfoCard';
import { tournamentService } from 'service/tournament';
import { AcceptTournament } from 'pages/Tournament/components/AcceptTournaments';
import { betinToMatch } from 'utils/formatters';

import eventModalSchema from 'components/modules/EventModal/EventModalSchema';
import {
  useBattle,
  FormEnterBattle
} from 'components/modules/EventModal/Battle';
import { GeneralButton } from 'components/elements';
import { CustomCircularProgress, EventModal } from 'components/modules';

import * as C from './NotificationStyled';

import { Props } from './@types';

const Notification: React.FC<Props> = ({ notification }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch: profileDisptach } = useContext(ProfileNotificationsContext);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const { showModal } = useModal();
  const { onSubmitEventModal } = useBattle();

  const translationString = 'components.header.profileMenu.notification';

  const { getTournament } = tournamentService();

  const getLocale = (): Locale => {
    const language = getUserLanguage();

    switch (language) {
      case 'pt-BR':
        return ptBR;

      case 'en-US':
        return enUS;

      default:
        return enUS;
    }
  };

  const getDate = (): string => {
    return format(new Date(notification.created_at), 'dd MMMM', {
      locale: getLocale()
    });
  };

  const getTitle = (): string => {
    switch (notification.type) {
      case 'friend_request':
        return t(`${translationString}.invitation.title`);

      case 'alone_battle_request':
        return t(`${translationString}.aloneBattleRequest.title`);

      case 'team_battle_request':
        return t(`${translationString}.teamBattleRequest.title`);

      case 'tournament_start_phase':
        return t(`${translationString}.tournamentStartPhase.title`);

      case 'tournament_invite':
        return t(`${translationString}.tournamentInvite.title`);

      default:
        return '';
    }
  };

  const getDescription = (): string => {
    switch (notification.type) {
      case 'friend_request':
        return `${notification.sender?.name} ${t(
          `${translationString}.invitation.paragraph`
        )}`;

      case 'alone_battle_request':
        return t(`${translationString}.aloneBattleRequest.paragraph`, {
          amount: betinToMatch(`${notification.content_object.amount}`),
          matchcoins: i18n.t('titles.matchcoins'),
          apelido: notification.sender?.name,
          platform: notification.content_object?.game?.selected_platform?.name,
          game: notification.content_object?.game?.name
        });

      case 'team_battle_request':
        return t(`${translationString}.teamBattleRequest.paragraph`, {
          amount: betinToMatch(`${notification.content_object.amount}`),
          matchcoins: i18n.t('titles.matchcoins'),
          team_name: notification.sender?.name,
          platform: notification.content_object?.game?.selected_platform?.name,
          game: notification.content_object?.game?.name
        });

      case 'tournament_start_phase':
        return t(`${translationString}.tournamentStartPhase.paragraph`, {
          phase: notification.content_object?.phase,
          tournamentName: notification.content_object?.tournament_name
        });

      case 'tournament_invite':
        return t(`${translationString}.tournamentInvite.paragraph`, {
          amount: betinToMatch(`${notification.content_object.amount}`),
          matchcoins: i18n.t('titles.matchcoins'),
          platform: notification.content_object?.platform,
          game: notification.content_object?.game,
          name: notification.sender?.name
        });
      default:
        return '';
    }
  };

  const getNotifications = async (): Promise<void> => {
    profileDisptach({
      type: ProfileActiosn.SET_LOADING,
      payload: { loading: true }
    });

    try {
      const response = await servicesApis.user.getNotifications();

      profileDisptach({
        type: ProfileActiosn.SET_NOTIFICATIONS,
        payload: {
          notifications: response
        }
      });
    } catch (error) {
      console.error(error);
    }

    profileDisptach({
      type: ProfileActiosn.SET_LOADING,
      payload: { loading: false }
    });
  };

  const acceptFriendRequest = async (requestId: string): Promise<void> => {
    setLoading(true);

    try {
      await servicesApis.friends.putAcceptFriendRequest(requestId);
      await getNotifications();

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.friendInviteAccepted`)
        }
      });
    } catch (error) {
      console.error(error);

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.friendInviteAcceptedError`)
        }
      });
    }

    setLoading(false);
  };

  const rejectFriendRequest = async (requestId: string): Promise<void> => {
    setLoading(true);

    try {
      await servicesApis.friends.putRejectFriendRequest(requestId);
      await getNotifications();

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.friendInviteRejected`)
        }
      });
    } catch (error) {
      console.error(error);

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.friendInviteRejectedError`)
        }
      });
    }

    setLoading(false);
  };

  const getBattle = async (battleId: string): Promise<GeneralBattle> => {
    const response = await servicesApis.battle.getAGlobalBattleAlone(battleId);
    return response;
  };

  const acceptBattleRequest = async (battle_id: string): Promise<void> => {
    setLoading(true);

    try {
      const battle: GeneralBattle = await getBattle(battle_id);
      showModal(
        <EventModal
          data={{
            amount: battle.amount,
            image_url: battle.selected_game.image_url,
            title: battle.selected_game.name,
            translationString: 'components.battle.acceptBattle',
            platform: battle.selected_platform,
            entity_id: battle_id
          }}
          Children={FormEnterBattle}
          onSubmit={onSubmitEventModal}
          validationSchema={eventModalSchema}
        />,
        'min-content',
        true,
        '42px'
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.battleInviteAcceptedError`)
        }
      });
    }

    setLoading(false);
  };

  const rejectBattleRequest = async (battle_id: string): Promise<void> => {
    setLoading(true);

    try {
      await servicesApis.battle.putRejectBattle(battle_id);

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.battleInviteRejected`)
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.battleInviteRejectedError`)
        }
      });
    }

    setLoading(false);
  };

  const acceptTournamentRequest = async (tournamentId: string) => {
    try {
      const response = await getTournament(tournamentId);
      const responseFormat = {
        ...response,
        entry_fee: response.entry_fee,
        start_date: new Date(response.start_date).toLocaleDateString('pt-BR')
      };

      const body = {
        name: responseFormat?.name || '',
        amount: responseFormat?.entry_fee || '',
        entity_id: responseFormat?.entity_id || '',
        platforms: {
          entity_id: responseFormat?.platform?.entity_id || '',
          name: responseFormat?.platform?.name || '',
          image_url: responseFormat?.platform?.image_url || ''
        },
        tournament_players: responseFormat?.tournament_players || [],
        image_url: responseFormat.image_url
      };

      showModal(<AcceptTournament data={body} />, 'min-content', true, '42px');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);

      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message: t(`${translationString}.popup.tournamentInviteAcceptedError`)
        }
      });
    }

    setLoading(false);
  };

  const getRejectEventFunction = () => {
    switch (notification.type) {
      case 'friend_request':
        return rejectFriendRequest(
          notification.content_object?.request_friend_id || ''
        );

      case 'alone_battle_request':
        return rejectBattleRequest(
          notification.content_object?.battle_id || ''
        );
      case 'team_battle_request':
        return rejectBattleRequest(
          notification.content_object?.battle_id || ''
        );

      case 'tournament_start_phase':
        return (() => {})();

      case 'tournament_invite':
        return (() => {})();

      default:
        return (() => {})();
    }
  };

  const getAcceptEventFunction = () => {
    switch (notification.type) {
      case 'friend_request':
        return acceptFriendRequest(
          notification.content_object?.request_friend_id || ''
        );

      case 'alone_battle_request':
        return acceptBattleRequest(
          notification.content_object?.battle_id || ''
        );
      case 'team_battle_request':
        return acceptBattleRequest(
          notification.content_object?.battle_id || ''
        );

      case 'tournament_start_phase':
        return (() => {})();

      case 'tournament_invite':
        return acceptTournamentRequest(
          notification.content_object?.tournament_id || ''
        );
      default:
        return (() => {})();
    }
  };

  const renderButtons = (type: string) => {
    switch (type) {
      case 'tournament_start_phase':
        return null;
      case 'tournament_invite':
        return (
          <GeneralButton
            type="button"
            size="small"
            variant="primary"
            isLoading={false}
            onClick={getAcceptEventFunction}
          >
            {t(`${translationString}.invitation.accept`)}
          </GeneralButton>
        );
      default:
        return (
          <>
            <GeneralButton
              type="button"
              size="small"
              variant="secondary"
              isLoading={false}
              onClick={getRejectEventFunction}
            >
              {t(`${translationString}.invitation.reject`)}
            </GeneralButton>

            <GeneralButton
              type="button"
              size="small"
              variant="primary"
              isLoading={false}
              onClick={getAcceptEventFunction}
            >
              {t(`${translationString}.invitation.accept`)}
            </GeneralButton>
          </>
        );
    }
  };

  return (
    <C.Container>
      <C.Date>{getDate()}</C.Date>
      <C.Title>{getTitle()}</C.Title>
      <C.Description>{getDescription()}</C.Description>
      <C.ButtonsWrapper>
        {loading ? (
          <CustomCircularProgress />
        ) : (
          renderButtons(notification.type)
        )}
      </C.ButtonsWrapper>
    </C.Container>
  );
};

export default Notification;
