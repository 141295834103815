import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

import { Actions } from 'state/nft/@types/actions';
import { NftStoreListContext } from 'state/nft/state';
import { NftStoreItem } from 'models/NftStoreList';
import { GeneralButton } from 'components/elements/GeneralButton';
import { Card } from 'components/modules';
import { BuyNft } from 'components/modules/BuyNft';
import { useModal } from 'components/modules/Modal';
import { NftStoreItemViewProduct } from 'pages/NftStore/components/NftStoreItensViewProduct';
import { betinToMatch } from 'utils/formatters';
import { t } from 'translate/i18n';

import images from 'assets/img';
import icons from 'assets/img/icons';

import { Props } from './@types';

import * as S from './NftStoreItemStyled';

const NftStoreProductItem: React.FC<Props> = ({
  data,
  title = 'Title',
  valueLabel = 'Value',
  button = {
    buy: 'BUY',
    view: 'VIEW'
  },
  desktopRows = 2,
  page = 1,
  setPage = () => {}
}) => {
  const listRef = useRef() as MutableRefObject<HTMLUListElement>;
  const cardRef = useRef() as MutableRefObject<HTMLLIElement>;

  const router = useLocation();
  const { dispatch } = useContext(NftStoreListContext);
  const { showModal } = useModal();

  const [dataToShow, setDataToShow] = useState<NftStoreItem[]>(data);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const translationString = 'components.nft';

  useEffect(() => {
    const calculatePaginationVars = (): void => {
      if (listRef.current && cardRef.current) {
        const GAP = 24;
        const ROWS = window.innerWidth < 669 ? 6 : desktopRows;
        const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
        const cardsOnScreen =
          Math.floor(
            (listRef.current.clientWidth + COMPENSATION) /
              (cardRef.current.clientWidth + GAP)
          ) * ROWS;
        setDataToShow(data.slice(0, cardsOnScreen));
        setNumberOfPages(Math.ceil(data.length / cardsOnScreen));
        setPage(1);
      }
    };

    calculatePaginationVars();

    window.addEventListener('resize', calculatePaginationVars);

    return () => window.removeEventListener('resize', calculatePaginationVars);
  }, [window, router.pathname, data]);

  const goFowardOnList = (): void => {
    if (listRef.current && cardRef.current && page < numberOfPages) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : desktopRows;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      setDataToShow(
        data.slice(cardsOnScreen * page, (page + 1) * cardsOnScreen)
      );
      setPage(page + 1);
    }
  };

  const goBackwardsOnList = (): void => {
    if (listRef.current && cardRef.current && page > 1) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : desktopRows;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      setDataToShow(
        data.slice((page - 2) * cardsOnScreen, cardsOnScreen * (page - 1))
      );
      setPage(page - 1);
    }
  };

  const onClickOverNFTToBuy = (nftStoreItem: NftStoreItem): void => {
    dispatch({
      type: Actions.SELECT_NFT_STORE_ITEM,
      payload: nftStoreItem
    });
  };

  const openBuyStoreNftItemModal = (nft_store_item: NftStoreItem): void => {
    showModal(
      <BuyNft
        img={nft_store_item.nft.image_url}
        description={nft_store_item.nft.description}
        name={nft_store_item.nft.name}
        value={betinToMatch(nft_store_item.price)}
      />,
      '435px',
      true,
      '42px 56px'
    );
  };

  const openNftStoreItemModal = (nftStoreItem: NftStoreItem): void => {
    showModal(
      <NftStoreItemViewProduct
        name={nftStoreItem.nft.name}
        text={nftStoreItem.subtitle}
        value={betinToMatch(nftStoreItem.price)}
        description={nftStoreItem.nft.description}
      />,
      '1072px',
      true,
      '36px 34px'
    );
  };

  const renderItems = (
    nftStoreItem: NftStoreItem,
    index: number
  ): React.ReactElement => {
    return (
      <S.Item
        ref={index === 0 ? cardRef : undefined}
        onClick={() => onClickOverNFTToBuy(nftStoreItem)}
        key={index}
      >
        <Card
          background="rgba(255, 255, 255, 0.3)"
          height="min-content"
          width="100%"
          padding="16px 12px"
        >
          <S.Content>
            {nftStoreItem.has_nft ? (
              <S.Badget>
                {t(`${translationString}.listAllNFTs.yourNft`)}
              </S.Badget>
            ) : null}
            <S.Photo src={nftStoreItem.nft.image_url || images.defaultImage} />
            <S.Name>
              {nftStoreItem.nft.name} ({nftStoreItem.validity}{' '}
              {t(`${translationString}.listAllNFTs.days`)})
            </S.Name>

            <S.ItemFooter>
              <S.ValueWrapper>
                <S.Coin src={images.GoldCoin} />
                <S.ValueContainer>
                  <S.ValueLabel>{valueLabel}</S.ValueLabel>
                  <S.Value>{betinToMatch(nftStoreItem.price)} MTC</S.Value>
                </S.ValueContainer>
              </S.ValueWrapper>
              <S.ButtonContainer>
                <GeneralButton
                  variant="primary"
                  size="small"
                  transparent
                  onClick={
                    nftStoreItem.has_nft
                      ? () => openNftStoreItemModal(nftStoreItem)
                      : () => openBuyStoreNftItemModal(nftStoreItem)
                  }
                >
                  {nftStoreItem.has_nft ? button.view : button.buy}
                </GeneralButton>
              </S.ButtonContainer>
            </S.ItemFooter>
          </S.Content>
        </Card>
      </S.Item>
    );
  };

  return (
    <>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.ButtonsWrapper hide={numberOfPages <= 1}>
          <S.Button>
            <S.Arrow src={icons.arrowRight} left onClick={goBackwardsOnList} />
          </S.Button>

          <S.Button>
            <S.Arrow src={icons.arrowRight} onClick={goFowardOnList} />
          </S.Button>
        </S.ButtonsWrapper>
      </S.Header>
      <S.ListWrapper ref={listRef}>{dataToShow.map(renderItems)}</S.ListWrapper>
    </>
  );
};

export default NftStoreProductItem;
