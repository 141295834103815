import icons from 'assets/img/icons';
import React from 'react';
import { t } from 'translate/i18n';
import { Props } from './@types';

import * as S from './TitleStyled';

const Title: React.FC<Props> = ({ isTournament, onBackClick = () => {} }) => {
  const battleString = 'components.battle.chat';

  return (
    <S.Container>
      <S.Icon src={icons.arrowSquareLeft} onClick={onBackClick} />
      <S.Text>
        {t(`${battleString}.title.${isTournament ? 'tournament' : 'battle'}`)}
      </S.Text>
    </S.Container>
  );
};

export default Title;
