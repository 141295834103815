import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

const FeatureDemoContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 42px;
  align-items: center;
  flex-direction: column;
  background: rgba(11, 11, 34);

  ${media.lessThan('medium')`
    width: 100%;
    height: 100vh;
    margin-right: 0;
    padding: 35px 30px;
  `}
`;
const Logo = styled.img`
  width: 100%;
  max-width: 191px;
  max-height: 62px;
  margin-bottom: 42px;
`;

const Text = styled.p`
  gap: 4px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.6);
`;
const BlueLink = styled(Link)`
  display: flex;
  cursor: pointer;
  color: #48b9fe;
  text-decoration: underline;
`;
export { FeatureDemoContainer, Logo, Text, BlueLink };
