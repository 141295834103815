import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const WithdrawContainer = styled.div`
  z-index: 2;
  bottom: 0px;
  width: auto;
  height: auto;
  display: flex;
  position: fixed;
  align-items: center;
  justify-self: self-end;
  > div {
    justify-content: flex-start;
  }
  ${media.lessThan('medium')`
    left:0;
    > div {
      width: 100%;
      min-width: 100%;
    }
  `}
`;
const Content = styled.div<{
  header?: boolean;
  column?: boolean;
  padding?: string;
}>`
  width: 100%;
  height: 100%;
  display: grid;
  padding-bottom: 22px;
  grid-template-rows: 0fr 4.5fr 1fr;
  padding: ${(props) => props.padding};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
      justify-content: space-between;
    `};
  ${(props) =>
    props.header &&
    css`
      height: auto;
      display: flex;
      background: #22173a;
      border-radius: 12px 12px 0px 0px;
    `}
`;
const Title = styled.h2`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 22px;
  letter-spacing: 0.0015em;
  font-family: 'Russo One';
  text-transform: uppercase;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
const Continue = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
const Cancel = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
  color: ${(props) => props.theme.palette.text.white};
  border-color: ${(props) => props.theme.palette.background.light};
`;
const Text = styled.p<{ yellow?: boolean; flex?: boolean; margin?: string }>`
  gap: 5px;
  height: 100%;
  display: flex;
  font-family: 'Roboto';
  margin: ${(props) => props.margin};
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  ${(props) =>
    props.flex &&
    css`
      width: 66%;
      display: flex;
      justify-content: space-between;
    `};
  ${(props) =>
    props.yellow &&
    css`
      color: ${props.theme.palette.button.default};
    `};
`;
const ContainerButtons = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 0 24px;
  align-items: flex-end;
  justify-content: flex-end;
`;
const Icon = styled.img`
  cursor: pointer;
`;

export {
  Text,
  Icon,
  Title,
  Cancel,
  Content,
  Continue,
  ContainerButtons,
  WithdrawContainer
};
