import { ErrorContext } from 'state/errorCodes/state';
import { Actions as ErrorActions } from 'state/errorCodes/@types/actions';
import { useContext } from 'react';
import { ErrorCode } from '../@types';

export const useErrorCodes = () => {
  const { state: errorState, dispatch: errorDispatch } =
    useContext(ErrorContext);

  const setErrorCode = (errorCodes: ErrorCode) => {
    errorDispatch({
      type: ErrorActions.SET_ERRORS,
      payload: {
        errors: errorCodes
      }
    });
  };

  return {
    errorCodes: errorState.errorCodes,
    setErrorCode
  };
};
