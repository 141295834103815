import { Auth } from 'aws-amplify';

export function signIn(username, password) {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}
export async function signUp(userName, password) {
  const result = await Auth.signUp(userName, password);
  return result;
}

export function confirmSignUp(username, code) {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, code)
      .then((result) => {
        if (result === 'SUCCESS') {
          resolve(result);
        }
      })
      .catch((error) => {
        let errorMessage;
        switch (error.code) {
          case 'CodeMismatchException':
            errorMessage = 'Código incorreto! Por favor tente novamente';
            reject(errorMessage);
            break;

          case 'NetworkError':
            errorMessage =
              'Ops!! Ocorreu um erro.\n Por favor verifique sua conexão de internet e tente novamente';
            reject(errorMessage);
            break;

          default:
            errorMessage = 'Erro confirmando código';
            reject(errorMessage);
            break;
        }
      });
  });
}

export function resendVerifyToken(username) {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        let errorMessage;
        switch (error.code) {
          case 'LimitExceededException':
            errorMessage = 'Número máximo de tentativas excedidas';
            reject(errorMessage);
            break;

          default:
            errorMessage = 'Erro ao enviar código';
            reject(errorMessage);
            break;
        }
      });
  });
}

export function forgotPassword(username) {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        let errorMessage;

        switch (error.code) {
          case 'LimitExceededException':
            errorMessage = 'Número máximo de tentativas excedidas';
            reject(errorMessage);
            break;

          default:
            errorMessage = 'Erro ao enviar código';
            reject(errorMessage);
            break;
        }
      });
  });
}

export function changePasswordForgotten(username, code, newPassword) {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        let errorMessage;
        switch (error.code) {
          case 'CodeMismatchException':
            errorMessage = 'Código de verificação invalido';
            reject(errorMessage);
            break;

          case 'LimitExceededException':
            errorMessage = 'Número máximo de tentativas excedidas';
            reject(errorMessage);
            break;

          default:
            errorMessage = 'Error ao tentar trocar senha';
            reject(errorMessage);
            break;
        }
      });
  });
}

export function changePassword(oldPassword, newPassword) {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, oldPassword, newPassword))
      .then((data) => resolve(data))
      .catch((error) => reject(error.code));
  });
}
