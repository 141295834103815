import React from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { servicesApis } from 'service/service.api';

import images from 'assets/img';

import { CurrentMatchProps } from './@types';

import * as S from './CurrentMatchStyled';

const translationString = 'components.tournamentDetails';

const CurrentMatch = ({
  entity_id,
  player1,
  player2,
  image_url,
  tournamentId,
  userId,
  battleStatus,
  isLoading,
  callBack = () => {}
}: CurrentMatchProps) => {
  const navigate = useNavigate();

  const acceptBattle = async () => {
    if (battleStatus === 'ACCEPTED') {
      navigate('/matchbet/chat', {
        state: {
          isTournament: true,
          current_match: {
            entity_id,
            image_url,
            player1,
            player2
          }
        }
      });
    }
    await servicesApis.tournament.putAcceptTournamentBattle(
      tournamentId,
      entity_id
    );
    callBack();
  };

  const verifyButton = () => {
    if (battleStatus === 'ACCEPTED') return false;
    if (userId === player1.entity_id && player1.ready === true) return true;
    if (userId === player2.entity_id && player2.ready === true) return true;
    return false;
  };

  const getButtonText = () => {
    if (battleStatus === 'ACCEPTED') {
      return t(`${translationString}.currentMatch.buttonChat`);
    }
    return t(`${translationString}.currentMatch.button`);
  };

  return (
    <S.TableContainer>
      <S.BannerContainer backgroundUrl={image_url || images.defaultImage}>
        <S.BannerImage />
        <S.BannerTitle>
          {t(`${translationString}.currentMatch.title`)}
        </S.BannerTitle>
      </S.BannerContainer>

      <S.RowCompetitors>
        <S.RowCompetitor>
          <S.RowCompetitorName>{player1.nickname}</S.RowCompetitorName>
          <S.RowCompetitorImg
            src={player1.image_url || images.defaultImage}
            alt=""
          />
        </S.RowCompetitor>
        :
        <S.RowCompetitor>
          <S.RowCompetitorImg
            src={player2.image_url || images.defaultImage}
            alt=""
          />
          <S.RowCompetitorName>{player2.nickname}</S.RowCompetitorName>
        </S.RowCompetitor>
      </S.RowCompetitors>

      <S.Button
        disabled={verifyButton()}
        variant="primary"
        size="medium"
        onClick={() => acceptBattle()}
        isLoading={isLoading}
      >
        {getButtonText()}
      </S.Button>
    </S.TableContainer>
  );
};

export default CurrentMatch;
