import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img`
  width: 183px;
  height: 183px;
  object-fit: contain;
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 15px;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 258px;
`;
