import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 849px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Description = styled.p`
  width: 250px;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 119%;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 24px;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 19px;
`;
