import images from 'assets/img';
import { GeneralButton } from 'components/elements/GeneralButton';
import { Card } from 'components/modules';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BattleContext } from 'state/battle/state';
import { t } from 'translate/i18n';

import * as S from './BattleInProgressStyled';

const BattleInProgress: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(BattleContext);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 970);
  const [isMobileSmall, setIsMobileSmall] = useState<boolean>(
    window.innerWidth <= 450
  );

  useEffect(() => {
    function onResize(): void {
      setIsMobileSmall(window.innerWidth <= 450);
      setIsMobile(window.innerWidth <= 970);
    }

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [window]);

  const translationString = 'components.battle.battleInProgress';

  return (
    <Card
      width="100%"
      maxWidth={isMobile ? '100%' : '450px'}
      height="auto"
      background="rgba(255, 255, 255, 0.1)"
      padding="25px 23px"
    >
      <S.Content>
        <S.Row>
          <S.Column>
            <S.Title>{t(`${translationString}.title`)}</S.Title>
            <S.Description>
              {isMobile
                ? t(`${translationString}.description.small`)
                : t(`${translationString}.description.large`)}
            </S.Description>
          </S.Column>
        </S.Row>
        <S.Row marginTop="25px" alignItems="center">
          <Card
            width={isMobileSmall ? '50%' : 'auto'}
            height="auto"
            background="rgba(255, 255, 255, 0.1)"
            padding="15px"
          >
            <S.Column>
              <S.PlayerPhoto
                src={
                  state.battle.currentBattle.playerOne.image_url ||
                  images.defaultImage
                }
                alt={t(`${translationString}.playerOneImage.alt`)}
              />
              <S.PlayerName>
                {state.battle.currentBattle.playerOne.name}
              </S.PlayerName>
            </S.Column>
          </Card>
          <S.VersusIcon src={images.versus} />

          <Card
            width={isMobileSmall ? '50%' : 'auto'}
            height="auto"
            background="rgba(255, 255, 255, 0.1)"
            padding="15px"
          >
            <S.Column>
              <S.PlayerPhoto
                src={
                  state.battle.currentBattle.playerTwo.image_url ||
                  images.defaultImage
                }
                alt={t(`${translationString}.playerTwoImage.alt`)}
              />
              <S.PlayerName>
                {state.battle.currentBattle.playerTwo.name}
              </S.PlayerName>
            </S.Column>
          </Card>
        </S.Row>
        <GeneralButton
          size="medium"
          variant="primary"
          onClick={() => navigate('/matchbet/chat')}
        >
          {t(`${translationString}.buttonChat`)}
        </GeneralButton>
      </S.Content>
    </Card>
  );
};

export default BattleInProgress;
