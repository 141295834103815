import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h2`
  width: 292px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 4.8rem;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 768px) {
    font-size: 3.5rem;
    width: 250px;
  }

  @media (max-width: 368px) {
    width: 200px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 42px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const GameImage = styled.img<{ tournament?: boolean }>`
  width: 304px;
  height: 307px;
  object-fit: cover;
  border-radius: 12.3px;

  @media (max-width: 422px) {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 368px) {
    width: 200px;
    height: 200px;
  }
  ${({ tournament }) =>
    tournament &&
    css`
      width: 304px;
      height: 307px;
    `}
`;

export const BattleInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 35px;

  @media (max-width: 422px) {
    gap: 5px;
    flex-direction: column;
  }
`;

export const Name = styled.p`
  width: 123.56px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.94958rem;
  line-height: 23px;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 422px) {
    width: 100%;
  }
`;

export const CoinWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 422px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const CoinIcon = styled.img`
  width: 45.39px;
  height: 41.81px;
  object-fit: contain;
`;

export const Value = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.55883rem;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.white};
  margin-left: 5.05px;

  @media (max-width: 422px) {
    margin-right: 5.05px;
    margin-left: 0;
  }
`;

export const PlatformWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4.92px;
  margin-top: 5px;
  align-items: center;
`;

export const PlatformLabel = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.21849rem;
  line-height: 21px;
  letter-spacing: 0.13121px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const PlatformIcon = styled.img`
  width: 12.58px;
  height: 12.59px;
  object-fit: contain;
`;

export const Form = styled.form<{
  deleteTournament?: boolean;
  tournament?: boolean;
}>`
  margin-top: 95.57px;
  ${({ tournament }) =>
    tournament &&
    css`
      margin-top: 58.57px;
    `}
  ${({ deleteTournament }) =>
    deleteTournament &&
    css`
      margin-top: 26.07px;
    `}

  @media (max-width: 422px) {
    margin-top: 45px;
  }
`;

export const PasswordWrapper = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 58px;
  border: 1px solid #ffb620;
  padding: 16.5px 12.83px;
  border-radius: 12.83px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.palette.error.main};
    `}

  @media (max-width: 768px) {
    width: 304px;
  }

  @media (max-width: 422px) {
    width: 250px;
  }

  @media (max-width: 368px) {
    width: 200px;
  }
`;

export const Field = styled.input`
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.92438rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.text.white};
  background-color: transparent;
  border: 0;
  outline: 0;

  ::placeholder {
    color: ${({ theme }) => theme.palette.text.white};
  }
`;

export const SeePassIcon = styled.img<{ show?: boolean; error?: boolean }>`
  width: 38.49px;
  height: 38.79px;
  object-fit: contain;
  margin-left: 12.83px;
  cursor: pointer;

  ${({ show }) =>
    show &&
    css`
      filter: grayscale(1);
    `}

  ${({ error }) =>
    error &&
    css`
      filter: invert(45%) sepia(42%) saturate(2297%) hue-rotate(328deg)
        brightness(76%) contrast(123%);
    `}
`;

export const ButtonsWrapper = styled.div`
  margin-top: 27.5px;
  display: flex;
  flex-direction: row;
  gap: 19.5px;

  @media (max-width: 422px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Error = styled.p`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: 10px;
  margin-left: 10px;
`;

export const Description = styled.p`
  font-size: 10px;
  margin-top: 15px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 147%;
  font-family: 'Poppins';
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const DeleteTournament = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 104.88px;
`;
