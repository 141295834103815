import icons from 'assets/img/icons';
import React from 'react';
import { v4 } from 'uuid';
import { Props } from './@types';

import * as S from './PaginationStyled';

const Pagination: React.FC<Props> = ({
  numberOfPages,
  selectedPage,
  clickOnRightArrow = () => {},
  clickOnLeftArrow = () => {},
  clickOnPageIndicator = () => {},
  ...restProps
}) => {
  const renderPages = (_: number, index: number): React.ReactElement => {
    if (index + 1 === selectedPage) {
      return (
        <S.Button key={v4()} selected>
          {index + 1}
        </S.Button>
      );
    }

    return (
      <S.Button key={v4()} onClick={() => clickOnPageIndicator(index + 1)}>
        {index + 1}
      </S.Button>
    );
  };

  if (numberOfPages <= 1) {
    return null;
  }

  const isFirstPage = selectedPage === 1;
  const isLastPage = selectedPage === numberOfPages;

  return (
    <S.Container {...restProps}>
      <S.Button
        onClick={(event) => {
          event.stopPropagation();
          if (isFirstPage) return;

          clickOnLeftArrow();
        }}
        isDisabled={isFirstPage}
      >
        <S.Icon src={icons.arrowRight} $rotate />
      </S.Button>
      {new Array(numberOfPages || 0).fill(0).map(renderPages)}
      <S.Button
        onClick={(event) => {
          event.stopPropagation();
          if (isLastPage) return;

          clickOnRightArrow();
        }}
        isDisabled={isLastPage}
      >
        <S.Icon src={icons.arrowRight} />
      </S.Button>
    </S.Container>
  );
};

export default Pagination;
