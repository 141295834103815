/* eslint-disable no-console */
import { getIcon } from 'assets/img/icons';
import { useModal } from 'components/modules/Modal';
import { ENDPOINTS } from 'config/endpoints';
import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { saveImageOnBucket } from 'service/s3.service';
import { servicesApis } from 'service/service.api';
import { Actions } from 'state/user/@types/actions';
import { UserContext } from 'state/user/state';
import CropModal from '../CropModal';
import * as S from './UserDataStyled';

const UserDate: React.FC = () => {
  const { showModal } = useModal();

  const { state, dispatch } = useContext(UserContext);
  const [src, setSrc] = useState<string>(state.user.image_url);

  const getUploadURL = async () => {
    const response = await servicesApis.user.getUploadURL(
      ENDPOINTS.user.getUserUploadUrl
    );
    return response;
  };
  const putUser = async (img: File) => {
    try {
      if (img) {
        const { fields, url } = await getUploadURL();
        await saveImageOnBucket(url, fields, img);
        const userData = {
          email: state.user.email,
          image_url: `${url}/${fields?.key}`,
          nickname: state.user.nickname,
          phone: state.user.phone,
          birthday: state.user.birthday
        };
        await servicesApis.user
          .putUserData(state.user.entityId, userData)
          .then(() => {
            dispatch({
              type: Actions.SET_USER_DATA,
              payload: {
                ...state?.user,
                updateState: true
              }
            });
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const selectAnAvatar = (): void => {
    const input: HTMLInputElement | null =
      document.querySelector('#avatar-input');

    if (input) {
      input.click();
    }
  };

  const eraseInput = (input: HTMLInputElement): void => {
    // eslint-disable-next-line no-param-reassign
    input.value = '';
  };

  const base64ToFile = async (
    url: string,
    filename: string,
    mimeType: string
  ): Promise<File> => {
    const response: Response = await fetch(url);
    const buffer: ArrayBuffer = await response.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const onCompleteCrop = async (base64: string): Promise<void> => {
    setSrc(base64);
    const mimetype = base64?.split('data:')[1]?.split(';')[0];
    const imageFile = await base64ToFile(base64, 'avatar', mimetype);
    putUser(imageFile);
  };

  useEffect(() => {
    if (state.user.image_url) {
      setSrc(state.user.image_url);
    }
  }, [state?.user?.image_url]);

  const getAvatar = (event: ChangeEvent<HTMLInputElement>): void => {
    const images = event?.target?.files;
    const image = (images || [])[0];
    const url: string = image ? URL.createObjectURL(image) : '';
    setSrc(url);
    putUser(image);

    showModal(
      <CropModal src={url} onComplete={onCompleteCrop} />,
      '526px',
      true,
      '28px'
    );
    eraseInput(event?.target);
  };

  return (
    <S.UserDataContainer>
      <div>
        <S.PhotoContainer onClick={() => selectAnAvatar()}>
          <S.Avatar src={src} alt="Foto do usuário" />
          <S.EditIcon src={getIcon('edit')} alt="Icone de editar a foto" />
          <S.HiddenInput
            id="avatar-input"
            type="file"
            accept="image/*"
            onChange={getAvatar}
          />
        </S.PhotoContainer>
      </div>

      <S.ContentContainer>
        <S.Text>{state.user.nickname || 'Sem apelido'}</S.Text>
        <S.Text>{state.user.email || 'Sem e-mail'}</S.Text>
        <S.Text>{state.user.entityId || 'Sem Id'}</S.Text>
        <S.PhoneContainer>
          {state.user.phone ? (
            <S.Phone
              country="br"
              onChange={() => {}}
              value={state.user.phone}
              specialLabel=""
              disabled
            />
          ) : (
            <S.Text>Sem telefone</S.Text>
          )}
        </S.PhoneContainer>
      </S.ContentContainer>
    </S.UserDataContainer>
  );
};

export default UserDate;
