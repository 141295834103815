import styled, { css } from 'styled-components';
import { Type } from '../List/@types';

export const Container = styled.div<{ type: Type }>`
  position: relative;

  ${({ type }) =>
    type !== Type.GAME &&
    css`
      display: none;
    `}
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
`;

export const Popup = styled.div<{ openFilter?: boolean }>`
  position: absolute;
  top: 70px;
  right: -20px;
  z-index: 1;

  @media (max-width: 1096px) {
    left: -20px;
  }

  ${({ openFilter }) =>
    !openFilter &&
    css`
      display: none;
    `}
`;

export const BallonLeg = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14.5px 11px 14.5px;
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
  position: absolute;
  top: -11px;
  right: 27px;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.text.white};
  margin-top: 20px;
`;

export const Filter = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18.8513px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
`;
