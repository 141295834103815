/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { NotificationProviderType } from './@types';
import notificationReducer from './reducer';

const initialState: any = {
  notification: {
    open: false,
    message: ''
  }
};

export const NotificationContext = createContext(initialState);

const NotificationProvider: React.FC<NotificationProviderType> = ({
  children
}) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <NotificationContext.Provider value={provider}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
