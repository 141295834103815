import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const CompleteSignUpContainer = styled.div`
  float: right;
  display: flex;
  height: 627px;
  padding: 42px;
  margin-right: 12%;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  padding: 42px;

  @media (max-width: 1440px) {
    margin-right: 5%;
  }

  ${media.lessThan('medium')`
    width: 100%;
    height: 100vh;
    margin-right: 0;
    padding: 35px 30px;
    background: rgba(11, 11, 34, 0.9);
  `}
`;
const Logo = styled.img`
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 431px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  line-height: 43px;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Russo One';
  color: ${(props) => props.theme.palette.background.light};
  font-size: ${(props) => props.theme.typography.fontSizeBold}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  > div:nth-child(2) {
    width: 237px;
    margin-right: 21px;
  }
  > div:nth-child(3) {
    ${media.lessThan('medium')`
    width: 50%;
  `}
    width: 40%;
    max-width: 431px;
  }

  > div:last-child {
    margin-bottom: 20px;
  }

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.6);
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: #fff !important;
        }
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.6);
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff61;
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #ffffff61;
        }
      }
    }
  }
  .MuiInputAdornment-root {
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;
    .MuiButtonBase-root {
      width: 100%;
      height: 100%;
      padding: 11px 20px;
      margin-right: -32px;
      background-color: #2f2744;
      border-radius: 0 !important;
      button:hover {
      }
      svg {
        width: 22px;
        height: 22px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
  }
  .css-1lwbda4-MuiStack-root {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
      .MuiOutlinedInput-input {
        border-radius: 4px !important;
      }
      .MuiOutlinedInput-root {
        padding-right: 0px;
      }
    }
  }
  .MuiInputAdornment-root {
    display: none;
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 47px;
      color: #ffffff61;
      border-radius: 5px;
      background: transparent;
      border: 1px solid #ffffff61;
      color: rgba(255, 255, 255, 0.6);
    }
    .selected-flag:hover,
    .flag-dropdown.open .selected-flag,
    .react-tel-input .selected-flag:focus {
      background-color: transparent;
    }

    .flag-dropdown {
      border-radius: 5px 0 0 5px;
      background-color: transparent;
      .country-list {
        width: 430px;
        color: #ffffff61;
        border-radius: 4px;
        filter: invert(0.02);
        background-color: #0d0b22;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5px;
          box-sizing: border-box;
          border: 1px solid #0f42f7;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: linear-gradient(0deg, #3b9ef1, #3b9ef1);
        }
        .country {
          :hover {
            background-color: #27244dad;
            background-color: #2d2d4a78;
          }
        }
        .country.highlight {
          background-color: #2d2d4a78;
        }
      }
    }
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 15px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
  }
  .Mui-disabled {
    color: rgba(255, 255, 255, 0.6);
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #ffffff99;
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
  }
  .MuiOutlinedInput-input {
    height: 23px;
  }
  .react-tel-input .form-control {
    height: 56px;
    font-size: 1.6rem;
  }
  .special-label {
    display: none;
  }
`;

const Label = styled.label<{ show?: boolean }>`
  left: 100px;
  z-index: -1;
  font-size: 1.6rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  ${(props) =>
    props.show &&
    css`
      display: none;
    `};
`;
const Register = styled(ButtonSmall)`
  ${media.lessThan('medium')`
  width: 100%;
  max-width: 212px;
  `}
  width: 48%;
  font-weight: ${(props) => props.theme.typography.fontWeightBold + 200};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.2}rem;
`;

const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  display: flex;
  position: sticky;
  min-height: 72px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
  .invalid-number-message {
    top: 55px;
    line-height: 24px;
    font-style: normal;
    position: absolute;
    letter-spacing: 0.15px;
    background-color: transparent;
    color: ${(props) => props.theme.palette.error.main};
    font-size: ${(props) => props.theme.typography.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightLight};
  }
  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: 22px;

  `}
  ${(props) =>
    props.error &&
    css`
      margin-bottom: 0px;
      .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
        color: #d32f2f;
      }
      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
        .MuiOutlinedInput-notchedOutline {
        border-color: #d32f2f !important;
      }

      .MuiFormHelperText-root {
        margin-bottom: 10px;
        font-size: ${props.theme.typography.fontSize}rem;
      }
    `};
`;

const ContainerPassword = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  div:first-child {
    margin-right: 0px;
    width: 100% !important;
  }
  button {
    width: 45px;
    right: 13px;
    height: 55px;
    position: absolute;
    border-radius: 0px !important;
    background-color: transparent;
    color: ${(props) => props.theme.palette.title.default};

    :hover {
      background-color: transparent;
    }
    .MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.3}rem;
    }
  }
`;

export {
  Logo,
  Form,
  Title,
  Label,
  Register,
  Container,
  ContainerInput,
  ContainerPassword,
  CompleteSignUpContainer
};
