export const userTournament = {
  openGlobalTournament: {
    title: 'Torneios globais abertos',
    card: {
      gameImage: {
        alt: 'Imagem do jogo'
      },
      coin: {
        alt: 'Moeda'
      },
      platformLabel: 'Na plataforma',
      platformIcon: {
        alt: 'Icone de plataforma'
      },
      registeredLabel: 'Jogadores'
    },
    emptyList: {
      title: 'Torneios globais abertos',
      description: 'Os torneios globais abertos disponíveis irão aparecer aqui'
    }
  },
  banner: {
    title: 'Criar torneios e desafiar os seus amigos.',
    description: 'Jogar com os seus amigos, criar times e muito mais.',
    alt: 'Banner de criar torneio',
    button: {
      small: 'Vamos lá!',
      large: 'Vamos lá!',
      goToStore: 'Ir para a Loja'
    },
    buyNft:
      'Você precisa da NFT ChampBet para criar torneios, clique no botão para ir a loja'
  },
  yourTournaments: {
    emptyState: {
      titleHistory: 'Meu histórico de torneios',
      title: 'Sua lista de torneios criados',
      paragraph: 'Quando você criar torneios, eles aparecerão aqui.',
      button: `Vamos lá!`
    }
  },
  betAmount: {
    title: 'Valor da aposta',
    subTitle: 'Definir o valor da apostar do torneio',
    amount: 'Quantidade de Bet',
    balance: 'Balanço:',
    eyeIcon: {
      balance: {
        alt: 'Ver balanço'
      },
      password: {
        alt: 'Ver senha'
      }
    },
    password: 'Senha financeira',
    description:
      'No caso de jogos jogados em equipe, o valor é depositado e retirado apenas do utilizador responsável pela equipe.',
    buttons: {
      cancel: 'Voltar',
      next: 'Avançar',
      createTournament: 'Criar'
    }
  }
};
