import PhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ error?: boolean }>`
  fieldset {
    border: 2px solid #ffffff61;
    border-radius: 5px;
    padding: 0;

    legend {
      color: #ffffff61;
      padding: 0 5px;
      margin-left: 10px;
      font-size: 1.2rem;
      height: 0;
      transform: translateY(-7px);

      ${(props) =>
        props.error &&
        css`
          color: #d32f2f !important;
        `}
    }

    ${(props) =>
      props.error &&
      css`
        border-color: #d32f2f !important;
      `}
  }
`;

export const Field = styled(PhoneInput).attrs({
  inputStyle: {
    width: '100%',
    height: '56px',
    background: 'transparent',
    color: '#ffffff61',
    borderWidth: 0
  },
  buttonStyle: {
    background: 'transparent',
    borderRight: 0,
    borderWidth: 0
  },
  dropdownStyle: {
    width: '459px',
    background: 'rgba(255, 255, 255, 0.8)',
    height: '140px'
  },
  containerStyle: { borderWidth: 0 }
})<{ countryListWidth?: number | null }>`
  .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
  }

  .selected-flag:hover {
    background-color: transparent !important;
  }

  .flag-dropdown.open {
    border: 0;
  }

  input:focus,
  input:hover {
    border-color: #ffffff61 !important;
    border-width: 2px;
    box-shadow: unset !important;
  }

  .arrow {
    border-top-color: #ffffff61 !important;
  }

  .country-list {
    width: ${(props) =>
      props.countryListWidth
        ? `${props.countryListWidth + 4}px !important`
        : '459px'};
  }
`;

export const ErrorMessage = styled.p`
  font-size: 1.2rem;
  color: #d32f2f !important;
  margin: 5px 0 10px 14px;
`;
