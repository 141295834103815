import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25px;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 125%;
  text-align: center;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  .MuiFormControl-root {
    margin: 0;
    width: 60%;

    .MuiOutlinedInput-root {
      display: flex;
      justify-content: space-between;

      .MuiOutlinedInput-input {
        border-radius: 0px;
        padding-right: 20px;
        color: ${(props) => props.theme.palette.text.light};
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.text.light};
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${(props) => props.theme.palette.text.light};
        }
      }
    }

    p.Mui-error {
      font-size: 1rem;
      margin-top: 5px;
    }
  }

  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.palette.text.light};
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }

  .MuiFormLabel-filled,
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.text.light};
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  @media (max-width: 768px) {
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
      display: -webkit-box;
    }

    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }

    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 16.5px 0px;
    }

    > div:first-child {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #ffffff99 !important;
  }
`;
export const SubTitle = styled(Title)`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 119%;
`;

export const Text = styled.p<{
  yellow?: boolean;
  flex?: boolean;
  margin?: string;
}>`
  gap: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  margin: ${(props) => props.margin};
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${(props) =>
    props.flex &&
    css`
      width: 66%;
      display: flex;
      justify-content: space-between;
    `};

  ${(props) =>
    props.yellow &&
    css`
      color: ${props.theme.palette.button.default};
    `};

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const Description = styled(Text)`
  margin: 24px 0;
  font-weight: 400;
  line-height: 119%;
  font-size: 2.4rem;
  text-align: center;
  font-family: 'Poppins';
  color: ${({ theme }) => theme.palette.text.white};
`;
export const TextError = styled.p`
  margin-top: -18px;
  color: ${(props) => props.theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
export const Initials = styled.strong<{ yellow?: boolean }>`
  width: max-content;

  ${(props) =>
    props.yellow &&
    css`
      font-family: 'Roboto';
      color: ${props.theme.palette.button.default};
      font-size: ${props.theme.typography.fontSize}rem;
      font-weight: ${props.theme.typography.fontWeightBold};
    `};
`;

export const ContainerCkeckBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;

  span {
    padding: 0 9px 0 0;
    color: ${(props) => props.theme.palette.text.light};

    svg {
      width: 22px;
      height: 22px;
      fill: #ffb620;
    }
  }
  .Mui-checked {
    color: #ffb620;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 27.5px;
  display: flex;
  flex-direction: row;
  gap: 19.5px;

  @media (max-width: 422px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const InputWrapper = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12.83px;
  padding: 16.5px 12.83px;
  border: 1px solid #ffb620;
  justify-content: space-between;

  > div {
    display: flex;
    max-width: auto;
    align-items: center;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.palette.error.main};
    `}

  @media (max-width: 768px) {
    width: 304px;
  }

  @media (max-width: 422px) {
    width: 250px;
  }

  @media (max-width: 368px) {
    width: 200px;
  }
`;
export const Field = styled.input`
  border: 0;
  outline: 0;
  flex: 0.94;
  width: 100%;
  font-weight: 500;
  line-height: 130%;
  font-style: normal;
  font-size: 1.92438rem;
  letter-spacing: 0.01em;
  font-family: 'Poppins';
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.white};

  ::placeholder {
    font-size: 1.55rem;
    color: ${({ theme }) => theme.palette.text.white};
  }
`;
export const SeePassIcon = styled.img<{ show?: boolean; error?: boolean }>`
  width: 38.49px;
  height: 38.79px;
  cursor: pointer;
  margin-left: 4px;
  object-fit: contain;

  ${({ show }) =>
    show &&
    css`
      filter: grayscale(1);
    `}

  ${({ error }) =>
    error &&
    css`
      filter: invert(45%) sepia(42%) saturate(2297%) hue-rotate(328deg)
        brightness(76%) contrast(123%);
    `}
`;
