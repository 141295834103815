import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { Matchbet } from 'pages';
import { OrdenationType } from 'pages/GlobalRanking/@types';
import { Pagination } from 'components/elements/Pagination';
import { Search } from 'components/modules/Search';
import { Table } from 'components/modules/Table';
import { servicesApis } from 'service/service.api';
import { dateAndTimeFormatter } from 'utils/formatters';
import { usePagination } from 'utils/Pagination';

import icons from 'assets/img/icons';
import images from 'assets/img';

import { GameProps, Item, UserProps } from './@types';

import * as S from './YourHistoryStyled';

const YourHistory = () => {
  const {
    paginateSearch,
    resetPage,
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data
  } = usePagination({ ROWS_REQUIRED: 7 });
  const navigate = useNavigate();
  const searchInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isLoading, setLoading] = useState(true);

  const translationString = 'components.yourHistory';

  const onSearch = (searchedValue: string) => {
    if (searchedValue.length > 0) {
      const filterByPlayer = (player: UserProps) =>
        player.name
          .toLocaleLowerCase()
          .includes(searchedValue.toLocaleLowerCase());

      const filterByGame = (game: GameProps) =>
        game.name
          .toLocaleLowerCase()
          .includes(searchedValue.toLocaleLowerCase());

      const filterFunction = (battle: Item) =>
        filterByPlayer(battle.competitors.player_one) ||
        filterByPlayer(battle.competitors.player_two) ||
        filterByGame(battle.game);

      paginateSearch(filterFunction);
    } else {
      resetPage();
    }
  };

  const getYourHistory = async () => {
    setLoading(true);

    await servicesApis.yourHistory
      .getYourHistory()
      .then((response) => {
        calculatePaginationVars(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getYourHistory();
  }, []);

  const headerContent = {
    columns: [
      {
        key: 'position',
        content: (
          <S.TextHeader size="10.8%">
            {t(`${translationString}.table.result_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'user',
        content: (
          <S.TextHeader size="14.3%">
            {t(`${translationString}.table.${OrdenationType.battles_won}_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="16%">
            {t(`${translationString}.table.${OrdenationType.start_date}_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="15%">
            {t(`${translationString}.table.${OrdenationType.end_date}_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="37%">
            {t(`${translationString}.table.${OrdenationType.elo_points}_text`)}
          </S.TextHeader>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextHeader size="8%">
            {t(`${translationString}.table.${OrdenationType.bet_value}_text`)}
          </S.TextHeader>
        )
      }
    ]
  };

  const rowContent = data?.map((item) => ({
    key: item.user.name,
    columns: [
      {
        key: 'position',
        content: (
          <S.ContainerPosition size="10.8%">
            <S.Status status={item.result}>{item.result}</S.Status>
          </S.ContainerPosition>
        )
      },
      {
        key: 'user',
        content: (
          <S.ContentInfo size="14.3%">
            <S.ImgUser
              src={item.game.image_url}
              alt={t(`${translationString}.table.imgs_alt.game`)}
            />
            <S.Name>{item.game.name}</S.Name>
          </S.ContentInfo>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextRow size="16%">
            {dateAndTimeFormatter(item.start_date)}
          </S.TextRow>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.TextRow size="15%">
            {dateAndTimeFormatter(item.end_date)}
          </S.TextRow>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.ContentInfo gap="120px" size="37%">
            <div>
              <S.ImgUser
                src={
                  item.competitors.player_one.image_url || images.defaultImage
                }
                alt={t(`${translationString}.table.imgs_alt.user`)}
              />
              <S.Name>{item.competitors.player_one.name}</S.Name>
            </div>
            <div>
              <S.ImgUser
                src={
                  item.competitors.player_two.image_url || images.defaultImage
                }
                alt={t(`${translationString}.table.imgs_alt.user`)}
              />
              <S.Name>{item.competitors.player_two.name}</S.Name>
            </div>
          </S.ContentInfo>
        )
      },
      {
        key: 'ordenation_type',
        content: (
          <S.ContentValueBet>
            <S.TextbBet>{t(`${translationString}.table.row.text`)}</S.TextbBet>
            <S.ValueBet>{item.bet_value}</S.ValueBet>
            <S.Coin
              src={icons.moedaOuro}
              alt={t(`${translationString}.table.imgs_alt.icon`)}
            />
          </S.ContentValueBet>
        )
      }
    ]
  }));

  return (
    <Matchbet>
      <S.AuxiliarTitle>
        {t(`${translationString}.auxiliarTitle`)}
      </S.AuxiliarTitle>
      <S.Container>
        <S.ContainerText>
          <S.ContentTitle>
            <S.Icon
              src={icons.arrowSquareLeft}
              onClick={() => navigate('/matchbet/globalRanking')}
            />
            <S.Title>{t(`${translationString}.title`)}</S.Title>
          </S.ContentTitle>
          <S.SubContent>
            <S.Description>
              {t(`${translationString}.description`)}
            </S.Description>
            <Search
              inputRef={searchInputRef}
              placeholder={t(`${translationString}.placeholder_search_user`)}
              onChange={onSearch}
            />
          </S.SubContent>
        </S.ContainerText>
        <S.ContainerRanking>
          <Table
            headerContent={headerContent}
            rowContent={rowContent}
            isLoading={isLoading}
          />
          <S.PaginationWrapper>
            <Pagination
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              clickOnRightArrow={clickOnRightArrow}
              clickOnLeftArrow={clickOnLeftArrow}
              clickOnPageIndicator={clickOnPageIndicator}
            />
          </S.PaginationWrapper>
        </S.ContainerRanking>
      </S.Container>
    </Matchbet>
  );
};

export default YourHistory;
