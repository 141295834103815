import images from 'assets/img';
import icons from 'assets/img/icons';

import { GeneralButton } from 'components/elements/GeneralButton';

import { SelectPlayersProps, PlayerProps } from './@types';
import { useSelectedCompetitors } from './useSelectedCompetitors';
import { TournamentSizeModal } from '../TournamentSizeModal';

import * as S from './SelectedCompetitorsStyled';

const PlayerRow = ({
  entity_id,
  name,
  apelido,
  image_url,
  onClick
}: PlayerProps) => {
  return (
    <S.PlayerRow key={entity_id}>
      <S.PlayerImage src={image_url || images.defaultImage} alt="test" />
      <S.PlayerName>{apelido || name}</S.PlayerName>
      <S.PlayerClose
        src={icons.closeYellow}
        alt="test"
        onClick={() => {
          if (onClick) {
            onClick(entity_id);
          }
        }}
      />
    </S.PlayerRow>
  );
};

const SelectPlayers = ({ title, subTitle }: SelectPlayersProps) => (
  <S.SelectPlayers>
    <img src={images.Trophy} alt="Trophy" />
    <S.SelectPlayersTitle>{title}</S.SelectPlayersTitle>
    <S.SelectPlayersSubTitle>{subTitle}</S.SelectPlayersSubTitle>
  </S.SelectPlayers>
);

export const SelectedCompetitors = () => {
  const {
    handleRemoveUser,
    hasCompletedTournament,
    selectedModel,
    selectedPlayers,
    hasPlayerSelected,
    translation,
    textButton,
    showModal,
    tournament,
    handleClick,
    isLoading,
    showErrorMessage
  } = useSelectedCompetitors();

  return (
    <S.Container>
      {hasPlayerSelected && <S.Title>{translation.title}</S.Title>}

      {hasPlayerSelected ? (
        <S.ListPlayers>
          {selectedPlayers.map((player: PlayerProps) => (
            <PlayerRow {...player} onClick={handleRemoveUser} />
          ))}
        </S.ListPlayers>
      ) : (
        <SelectPlayers
          title={translation.selectPlayers.title}
          subTitle={translation.selectPlayers.subTitle}
        />
      )}

      <S.Footer>
        <GeneralButton
          disabled={!hasCompletedTournament}
          variant="primary"
          size="large"
          onClick={handleClick}
          isLoading={isLoading}
        >
          {textButton}
        </GeneralButton>
        <S.FooterText>
          {showErrorMessage && (
            <S.ErrorCodeText>{translation.error}</S.ErrorCodeText>
          )}
          <S.FooterDescription>
            {`${translation.footerTextFirst} ${selectedModel} ${translation.footerTextLast}`}
          </S.FooterDescription>
          <S.FooterLink
            href="#"
            onClick={() => {
              showModal(
                <TournamentSizeModal
                  initialTournamentSize={selectedModel}
                  platforms={tournament.game.platforms}
                />,
                '475px',
                true,
                '35px 42px 43px'
              );
            }}
          >
            {translation.footerLink}
          </S.FooterLink>
        </S.FooterText>
      </S.Footer>
    </S.Container>
  );
};
