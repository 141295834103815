export const YourHistory = {
  title: 'YOUR HISTORY',
  auxiliarTitle: 'HISTORY',
  placeholder_search_user: 'search',
  description:
    'This is your history, your battles and tournaments, take the opportunity to share with your friends!',

  table: {
    battles_won_text: 'Game',
    result_text: 'Result',
    end_date_text: 'End date',
    bet_value_text: 'Value bet',
    start_date_text: 'Start date',
    elo_points_text: 'Competitors',
    imgs_alt: {
      icon: 'icon',
      user: 'Image user',
      game: 'Image game'
    },
    row: {
      text: 'value bet'
    }
  }
};
