import { useMemo } from 'react';
import { t } from 'translate/i18n';
import { Type } from 'components/modules/BetAmount/@types';

export const useTranslations = (type: Type) => {
  const translationBattleString = 'components.createBattle.betAmount';
  const translationTournamentString = 'components.userTournament.betAmount';

  const translations = useMemo(() => {
    switch (type) {
      case Type.BATTLE:
        return {
          title: t(`${translationBattleString}.title`),
          subtitle: t(`${translationBattleString}.subTitle`),
          amountPlaceholder: t(`${translationBattleString}.amount`),
          balanceLabel: t(`${translationBattleString}.balance`),
          seeBalance: t(`${translationBattleString}.eyeIcon.balance.alt`),
          password: t(`${translationBattleString}.password`),
          seePassword: t(`${translationBattleString}.eyeIcon.password.alt`),
          description: t(`${translationBattleString}.description`),
          cancel: t(`${translationBattleString}.buttons.cancel`),
          create: t(`${translationBattleString}.buttons.create`),
          next: t(`${translationBattleString}.buttons.next`)
        };

      case Type.TOURNAMENT:
        return {
          title: t(`${translationTournamentString}.title`),
          subtitle: t(`${translationTournamentString}.subTitle`),
          amountPlaceholder: t(`${translationTournamentString}.amount`),
          balanceLabel: t(`${translationTournamentString}.balance`),
          seeBalance: t(`${translationTournamentString}.eyeIcon.balance.alt`),
          password: t(`${translationTournamentString}.password`),
          seePassword: t(`${translationTournamentString}.eyeIcon.balance.alt`),
          description: t(`${translationTournamentString}.description`),
          cancel: t(`${translationTournamentString}.buttons.cancel`),
          next: t(`${translationTournamentString}.buttons.next`),
          createTournament: t(
            `${translationTournamentString}.buttons.createTournament`
          )
        };

      default:
        return {
          title: t(`${translationBattleString}.title`),
          subtitle: t(`${translationBattleString}.subTitle`),
          amountPlaceholder: t(`${translationBattleString}.amount`),
          balanceLabel: t(`${translationBattleString}.balance`),
          seeBalance: t(`${translationBattleString}.eyeIcon.balance.alt`),
          password: t(`${translationBattleString}.password`),
          seePassword: t(`${translationBattleString}.eyeIcon.password.alt`),
          description: t(`${translationBattleString}.description`),
          cancel: t(`${translationBattleString}.buttons.cancel`),
          create: t(`${translationBattleString}.buttons.create`)
        };
    }
  }, [translationBattleString, translationTournamentString]);

  return { translations };
};
