/* eslint-disable no-console */
import { getIcon } from 'assets/img/icons';
import Card from 'components/modules/Card/Card';
import { Message } from 'pages/Account/components/Wallet/Withdraw/components/Password/components';
import { useContext } from 'react';
import { UserContext } from 'state/user/state';
import { Props } from '../@types';
import { Amount, Password, WalletCode } from './components';
import * as S from './WithdrawStyled';

export const Withdraw = ({ close }: Props) => {
  const { state } = useContext(UserContext);

  const CloseModal = () => {
    close();
  };

  return (
    <S.WithdrawContainer>
      <Card width="479px" height="441px" padding="0" background="#ffffff19">
        <S.Content column>
          <S.Content header padding="16px 0px 16px 22px">
            <S.Title>
              Withdraw
              <S.Icon
                onClick={() => close()}
                src={getIcon('Close')}
                alt="Icone de fechar"
              />
            </S.Title>
          </S.Content>
          {(() => {
            switch (state.user.Step) {
              case 1:
                return <WalletCode actionCloseModal={CloseModal} />;
              case 2:
                return <Amount />;
              case 3:
                return <Password />;
              case 4:
                return <Message />;
              default:
                return <WalletCode actionCloseModal={CloseModal} />;
            }
          })()}
        </S.Content>
      </Card>
    </S.WithdrawContainer>
  );
};
