import styled from 'styled-components';

export const IconsContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-right: 3px;
`;
export const PlatformIcon = styled.img<{ readOnly?: boolean }>`
  width: ${(props) => (props.readOnly ? '14px' : '12px')};
  height: ${(props) => (props.readOnly ? '14px' : '12px')};
`;
