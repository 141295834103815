import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 153px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  overflow: hidden;

  @media (max-width: 768px) {
    align-items: flex-start;
    width: 10%;
  }
`;

export const Platform = styled.div<{ actived?: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.11012px solid #fff;
  border-radius: 13.8838px;

  ${({ actived }) =>
    actived &&
    css`
      filter: invert(9.5%) sepia(17%) saturate(7418%) hue-rotate(339deg)
        brightness(101%) contrast(101%);
    `}
`;

export const PlatformIcon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;
`;
