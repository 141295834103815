import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const VerificationPaymentStatusContainer = styled.div`
  width: 479px;
  height: auto;
  ${media.lessThan('medium')`
  `}
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h2`
  max-width: 280px;
  margin-top: 27px;
  line-height: 115%;
  align-items: center;
  text-align: center;
  font-family: 'Roboto';
  letter-spacing: 0.0015em;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
const Text = styled.p<{ margin?: boolean }>`
  gap: 5px;
  height: 100%;
  max-width: 218px;
  text-align: center;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  ${(props) =>
    props.margin &&
    css`
      margin: 8px 0;
    `};
`;
const Icon = styled.img``;
const Connect = styled(ButtonSmall)`
  margin: 0;
  max-width: 153px;
`;
export {
  Text,
  Icon,
  Title,
  Content,
  Connect,
  VerificationPaymentStatusContainer
};
