import React from 'react';

import { Props } from './@types';
import * as S from './ModalStyled';

const Modal: React.FC<Props> = ({
  width,
  height,
  padding = 'unset',
  children = <></>,
  open = false,
  onClickOutContainer = () => {}
}) => {
  return (
    <S.OutContainer open={open} onClick={onClickOutContainer}>
      <S.FloatContainer
        width={width}
        height={height}
        padding={padding}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </S.FloatContainer>
    </S.OutContainer>
  );
};

export default Modal;
