import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoContainer = styled.div`
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

export const Description = styled.p`
  line-height: 43.38px;
  font-size: 36px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const Price = styled.p`
  line-height: 36px;
  font-size: 24px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  margin-top: 30px;
`;

export const ItemImage = styled.img`
  width: 30%;
  height: 30%;

  @media (max-width: 768px) {
    width: 70%;
    height: 70%;
  }
`;

export const StockMessage = styled.p`
  line-height: 36px;
  font-size: 16px;
  font-family: 'Russo One';
  font-style: normal;
  color: green;
  margin-top: 30px;
`;

export const Input = styled.input`
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: rgba(74, 62, 94, 0.95);
  color: white;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid white;
  width: 45px;
  height: 40px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
