const COGNITOS = {
  matchbet: {
    COGNITO_DEV: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_HpuKp06Kb',
      APP_CLIENT_ID: '53t2gp59vjeu7lu2lh0250u56a'
    },

    COGNITO_STG: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_3cNCZHfPA',
      APP_CLIENT_ID: '5qctgdto01sig1amkqjgtbgoco'
    },

    COGNITO_PRD: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_WpoLRwdmR',
      APP_CLIENT_ID: 't7698f6p9blerqookulfq86kd'
    },
    API_URLS: {
      APIURL_DEV: 'https://2sroul5ss2.execute-api.us-east-1.amazonaws.com/dev/',
      APIURL_STG: 'https://xcizzn8sxd.execute-api.us-east-1.amazonaws.com/stg/',
      APIURL_PRD: 'https://0ka59629yj.execute-api.us-east-1.amazonaws.com/prd/'
    }
  },

  stormdev: {
    COGNITO_DEV: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_ygLoZkVHq',
      APP_CLIENT_ID: '11reg8uodqqs52adqsueqrbb5e'
    },

    COGNITO_STG: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_PheMlLUlt',
      APP_CLIENT_ID: '7r16shqsopr6m4f201ulnja4ai'
    },

    COGNITO_PRD: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_XKBCjrxSw',
      APP_CLIENT_ID: '7r190p464uth3dg4votsea9oaa'
    },
    API_URLS: {
      APIURL_DEV: 'https://mlzuo0s367.execute-api.us-east-1.amazonaws.com/dev/',
      APIURL_STG: '',
      APIURL_PRD: ''
    }
  }
};

// const cognito_provider = 'stormdev'
const cognito_provider = 'matchbet';

const COGNITO = COGNITOS[cognito_provider];
const { COGNITO_DEV, COGNITO_STG, COGNITO_PRD } = COGNITO;
const { APIURL_DEV, APIURL_STG, APIURL_PRD } = COGNITO.API_URLS;

const APIS = {
  MATCHBET: 'matchbet',
  MATCHBETOPEN: 'matchbetOpen'
};

const STAGES = {
  dev: {
    COGNITO: COGNITO_DEV,
    APIS,
    APIURL: APIURL_DEV
  },
  stg: {
    COGNITO: COGNITO_STG,
    APIS,
    APIURL: APIURL_STG
  },
  prd: {
    COGNITO: COGNITO_PRD,
    APIS,
    APIURL: APIURL_PRD
  }
};

const env = process.env.REACT_APP_STAGE || 'dev';

const config = STAGES[env];

export const version = 'V1';

export default config;
