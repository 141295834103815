/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { FeatureControlProviderType } from './@types';
import featureControlReducer from './reducer';

const initialState: any = {
  featureControl: {
    chat: true,
    store: true,
    battle: true,
    friends: true,
    account: true,
    buy_coins: true,
    tournaments: true,
    globalRanking: true,
    matchBetTournament: false
  }
};

export const FeatureControlContext = createContext(initialState);

const FeatureControlProvider: React.FC<FeatureControlProviderType> = ({
  children
}) => {
  const [state, dispatch] = useReducer(featureControlReducer, initialState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <FeatureControlContext.Provider value={provider}>
      {children}
    </FeatureControlContext.Provider>
  );
};

export default FeatureControlProvider;
