import icons from 'assets/img/icons';
import { t } from 'translate/i18n';
import { Props } from './@types';
import * as S from './CheckoutResponseModalStyled';

const CheckoutResponseModal: React.FC<Props> = ({ type }: Props) => {
  const translationString = 'components.Checkout';
  const checkTypeModalIcon = () => {
    if (type !== 'success') {
      return icons.errorIconModal;
    }
    return icons.successIconModal;
  };

  const checkTypeModalDescription = () => {
    if (type !== 'success') {
      return t(`${translationString}.checkoutResponseModal.error`);
    }
    return t(`${translationString}.checkoutResponseModal.success`);
  };
  return (
    <S.MainContainer>
      <S.Icon src={checkTypeModalIcon()} />
      <S.Description>{checkTypeModalDescription()}</S.Description>
    </S.MainContainer>
  );
};

export default CheckoutResponseModal;
