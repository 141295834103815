import { Layout } from 'components/modules';
import { ModalProvider } from 'components/modules/Modal';
import 'config/amplify';
import { ContentProvider } from 'pages/SignIn/Components/Form/useContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from 'routes';
import Provider from 'state/provider';
import { ThemeProvider } from 'styled-components';
import Theme from 'styles/Theme';

const App = () => {
  return (
    <ContentProvider>
      <Provider>
        <ThemeProvider theme={Theme}>
          <Layout>
            <Router>
              <ModalProvider>
                <AppRoutes />
              </ModalProvider>
            </Router>
          </Layout>
        </ThemeProvider>
      </Provider>
    </ContentProvider>
  );
};

export default App;
