import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 399px;
  height: 100%;
  max-height: 849px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #fff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 19.24px 0 24px 0;
`;

export const SelectPlayers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const ListPlayers = styled.div`
  width: 100%;
  height: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PlayerRow = styled.div`
  width: 100%;
  max-width: 365px;
  height: 100%;
  max-height: 62.49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 12px;

  :nth-child(odd) {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const PlayerImage = styled.img`
  width: 34px;
  height: 34px;
  background-size: 34px;
  border-radius: 6px;
`;

export const PlayerName = styled.p`
  width: 100%;
  text-align: start;
  padding-left: 12px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #fff;
`;

export const PlayerClose = styled.img`
  width: 32px;
  background-size: 32px;
  cursor: pointer;
`;

export const SelectPlayersTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #fff;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const SelectPlayersSubTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.0015em;
  color: #fff;
  transform: rotate(0.13deg);
  flex: none;
  order: 2;
  flex-grow: 0;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 24px;
  margin-bottom: 19.24px;
`;

export const FooterText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: 0.0025em;
  text-align: left;
  max-width: 354px;
`;

export const FooterDescription = styled.p`
  color: #fff;
`;

export const FooterLink = styled.a`
  color: #ffb620;
  text-align: left;
  text-decoration: underline;
`;

export const ErrorCodeText = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  color: red;
  margin-bottom: 10px;
`;
