import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'state/user/state';

import icons from 'assets/img/icons';

import { usePagination } from 'utils/Pagination';
import { dateAndTimeFormatter } from 'utils/formatters';
import { tournamentService } from 'service/tournament';
import { ResponseTournamentByID } from 'models/Tournaments';
import { Matchbet } from 'pages/Matchbet';
import { GoBack } from 'components/elements';

import { Table, CurrentMatch } from './components';

import * as S from './TournamentDetailsStyled';

const TournamentDetails = () => {
  const [tournament, setTournament] = useState<ResponseTournamentByID>({
    entity_id: '',
    created_by: '',
    entry_fee: '',
    first_prize: '',
    start_date: '',
    registered_players: 3,
    image_url:
      'https://s3.amazonaws.com/dev.matchbet/tournaments/decc/decc3250-a1a2-4be9-b63b-684d511c3c38',
    game: {
      name: '',
      image_url: ''
    },
    current_match: {
      entity_id: '',
      image_url: '',
      player1: {
        entity_id: '',
        name: '',
        nickname: '',
        image_url: '',
        ready: false
      },
      player2: {
        entity_id: '',
        name: '',
        nickname: '',
        image_url: '',
        ready: false
      },
      status: 'CREATED'
    },
    platform: {
      name: '',
      image_url: ''
    },
    stages: [
      {
        order: 1,
        entity_id: '',
        tournament_battles: [
          {
            entity_id: '',
            player1: {
              entity_id: '',
              nickname: '',
              image_url: ''
            },
            player2: {
              entity_id: '',

              nickname: '',
              image_url: ''
            },
            validator: null,
            start_datetime: '',
            end_datetime: '',
            status: '',
            winner_id: ''
          }
        ]
      }
    ]
  });
  const { state: userState } = useContext(UserContext);

  const initialOrder = Number(localStorage.getItem('initialOrder')) || 1;
  const [stageSelected, setStageSelected] = useState<{
    order: number;
  }>({
    order: initialOrder
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);

  const getStageTitle = (order: number, stage_qtd: number) => {
    const specialNames: { [key: number]: string } = {
      0: 'Final',
      1: 'Semi Final',
      2: 'Quartas de Final'
    };
    const stage_num = stage_qtd - order;
    return Object.keys(specialNames)
      .map((_, i: number) => {
        return 1 * i;
      })
      .includes(order)
      ? specialNames[order]
      : `Fase ${stage_num}`;
  };

  const [stages, setStages] = useState([
    { title: '', order: 1, avaliable: true, matches: 2 }
  ]);

  const getStages = (
    firstStage: { order: number },
    stagesAvaliable: number[]
  ) => {
    const stagesQtd = firstStage.order + 1;
    const emptyStage = new Array(stagesQtd).fill({
      title: '',
      order: 0,
      avaliable: false,
      matches: 0
    });
    const newStages = emptyStage.map((_, index) => {
      const currentOrder = stagesQtd - index - 1;
      const isStageAvaliable = stagesAvaliable.includes(currentOrder);
      const matchCount = 2 ** currentOrder + 0 ** currentOrder;

      return {
        title: getStageTitle(currentOrder, stagesQtd),
        order: currentOrder,
        avaliable: isStageAvaliable,
        matches: matchCount
      };
    });
    setStages(newStages);
    if (stagesAvaliable.length <= 1) {
      localStorage.setItem('initialOrder', firstStage.order.toString());
    }
  };

  const {
    state: { entity_id, isAdmin }
  } = useLocation();
  const { getTournament } = tournamentService();
  const {
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data
  } = usePagination({ ROWS_REQUIRED: 9 });

  const translationString = 'components.tournamentDetails';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await getTournament(entity_id)
        .then((response) => {
          const currentState = response.stages.find(
            (stage) => stage.order === stageSelected.order
          );

          const avaliableStages = response.stages.map(
            (stage: { order: number }) => {
              return stage.order;
            }
          );

          getStages(response.stages[0], avaliableStages);

          calculatePaginationVars(currentState?.tournament_battles || []);
          setTournament(response);
        })
        .catch(() => {});

      setShouldUpdateData(false);
      setLoading(false);
    };

    if (shouldUpdateData) {
      fetchData();
    }
  }, [shouldUpdateData]);

  useEffect(() => {
    setShouldUpdateData(true);
  }, [stageSelected.order]);

  const updateData = () => {
    setShouldUpdateData(true);
  };

  const stagesLength = tournament?.stages?.map(
    (stage) => stage.tournament_battles.length
  );

  return (
    <Matchbet>
      <S.Container>
        <S.ContentLeft>
          <S.GoBack>
            <GoBack />
            {t(`${translationString}.title`)}
          </S.GoBack>

          <Table
            isLoading={isLoading}
            stagesLength={stagesLength}
            data={data}
            isAdmin={isAdmin}
            tournamentId={tournament.entity_id}
            tournamentCreatedById={tournament.created_by}
            callback={updateData}
            stageSelected={stageSelected}
            setStageSelected={setStageSelected}
            stages={stages}
          />

          <S.Pagination
            selectedPage={selectedPage}
            numberOfPages={numberOfPages}
            clickOnLeftArrow={clickOnLeftArrow}
            clickOnRightArrow={clickOnRightArrow}
            clickOnPageIndicator={clickOnPageIndicator}
          />
        </S.ContentLeft>

        {!isAdmin && (
          <S.ContentRight>
            <S.Card>
              <S.Icon src={icons.cup} alt={t(`${translationString}.altIcon`)} />
              <S.ContainerText>
                <S.Title small>{t(`${translationString}.entranceFee`)}</S.Title>
                <S.Text small>{tournament?.first_prize} Matchoins</S.Text>
              </S.ContainerText>
            </S.Card>
            <S.Card>
              <S.Icon
                formated
                src={tournament?.game.image_url}
                alt={t(`${translationString}.altIcon`)}
              />
              <S.ContainerText>
                <S.Title small>{t(`${translationString}.gameTitle`)}</S.Title>
                <S.Text small>{tournament?.game.name}</S.Text>
              </S.ContainerText>
            </S.Card>
            <S.Card>
              <S.Icon
                src={tournament?.platform.image_url}
                alt={t(`${translationString}.altIcon`)}
              />
              <S.ContainerText>
                <S.Title small>{t(`${translationString}.platform`)}</S.Title>
                <S.Text small>{tournament?.platform.name}</S.Text>
              </S.ContainerText>
            </S.Card>
            <S.Card>
              <S.Icon
                src={icons.players}
                alt={t(`${translationString}.altIcon`)}
              />
              <S.ContainerText>
                <S.Title small>{t(`${translationString}.startDate`)}</S.Title>
                <S.Text small>
                  {dateAndTimeFormatter(tournament.start_date)}
                </S.Text>
              </S.ContainerText>
            </S.Card>
            <S.Card>
              <S.Icon
                src={icons.players}
                alt={t(`${translationString}.altIcon`)}
              />
              <S.ContainerText>
                <S.Title small>
                  {t(`${translationString}.registeredPlayers`)}
                </S.Title>
                <S.Text small>
                  {tournament.registered_players}{' '}
                  {t(`${translationString}.registeredPlayers`)}
                </S.Text>
              </S.ContainerText>
            </S.Card>

            {tournament?.current_match?.entity_id && (
              <CurrentMatch
                entity_id={tournament.current_match.entity_id}
                image_url={tournament.current_match.image_url}
                player1={tournament.current_match.player1}
                player2={tournament.current_match.player2}
                tournamentId={tournament.entity_id}
                userId={userState.user.entityId}
                battleStatus={tournament.current_match.status}
                callBack={updateData}
                isLoading={isLoading}
              />
            )}
          </S.ContentRight>
        )}
      </S.Container>
    </Matchbet>
  );
};

export default TournamentDetails;
