import { Item } from 'pages/YourHistory/@types';
import { v4 } from 'uuid';

export const yourHistoryInfoMock: Item[] = [
  {
    position: '',
    entity_id: v4(),
    result: 'Canceled',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Luan',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Daniel',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Pending',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-7)).toISOString(),
    start_date: new Date(new Date().setDate(-8)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Marcos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Tania',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Alan',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-12)).toISOString(),
    start_date: new Date(new Date().setDate(-13)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-20)).toISOString(),
    start_date: new Date(new Date().setDate(-21)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Tie',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Lost',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  },

  {
    position: '',
    entity_id: v4(),
    result: 'Victory',
    user: {
      name: '',
      entity_id: '',
      image_url: ''
    },
    game: {
      name: 'Call of Duty',
      image_url:
        'https://image.api.playstation.com/vulcan/img/rnd/202010/2320/i5l1jLAhFSTiO7lSCNDWjJ2J.jpg',
      entity_id: v4(),
      platforms: [
        {
          name: 'PC',
          entity_id: v4(),
          image_url:
            'https://s3.amazonaws.com/dev.platform.matchbet.io/platform/pc.svg'
        }
      ]
    },
    battles_won: '',
    bet_value: '3.122',
    tournaments_won: '',
    elo_points: '',
    end_date: new Date(new Date().setDate(-5)).toISOString(),
    start_date: new Date(new Date().setDate(-6)).toISOString(),
    competitors: {
      player_one: {
        name: 'Jaja',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.matchbet/games/1d7a/1d7ab118-1c8f-4332-a69c-efa73504c605'
      },
      player_two: {
        name: 'Carlinhos',
        entity_id: v4(),
        image_url:
          'https://s3.amazonaws.com/dev.platform.matchbet.io/image_default/user_default.png'
      }
    }
  }
];
