import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .MuiList-root {
    width: 100%;
    max-width: 100%;
    margin-top: 12px;
    overflow-y: auto;
    max-height: 205px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    .MuiListItemAvatar-root {
      min-width: 0;
      margin-right: 12px;
    }
    .MuiListItemText-root {
      .MuiTypography-root {
        color: #d9d9d9;
        line-height: 130%;
        font-family: 'Poppins';
        letter-spacing: 0.01em;
        font-size: ${({ theme }) => theme.typography.fontSize - 0.4}rem;
        font-weight: ${({ theme }) => theme.typography.fontWeightLight};
      }
    }
    .MuiListItemSecondaryAction-root {
      .MuiCheckbox-root {
        .MuiSvgIcon-root {
          width: 24px;
          height: 24px;
          color: ${({ theme }) => theme.palette.button.default};
        }
        .Mui-checked {
          color: ${({ theme }) => theme.palette.button.default};
        }
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background: #dedede;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #c4c4c4;
    }
  }
`;
export const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas:
    'NameTeam Avatar'
    'SubTitle Avatar';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr auto;

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.6);
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: #fff !important;
        }
        border-radius: 0px;
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 5000s;

        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff61;
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #ffffff61;
        }
      }
    }
  }
  .MuiInputAdornment-root {
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;
    .MuiButtonBase-root {
      width: 100%;
      height: 100%;
      padding: 11px 20px;
      margin-right: -32px;
      background-color: #2f2744;
      border-radius: 0 !important;
      button:hover {
      }
      svg {
        width: 22px;
        height: 22px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 0px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
  }
`;
export const ContainerInput = styled.div<{
  error?: boolean;
  nameTeam?: boolean;
}>`
  width: 100%;
  display: flex;
  position: sticky;
  margin-bottom: 22px;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.error &&
    css`
      .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
        color: ${props.theme.palette.error.main};
        font-size: ${props.theme.typography.fontSize}rem;
      }

      .MuiOutlinedInput-notchedOutline {
        font-size: ${props.theme.typography.fontSize}rem;
        border-color: ${props.theme.palette.error.main} !important;
      }
      .MuiFormHelperText-root {
        margin-bottom: 10px;
        font-size: ${props.theme.typography.fontSize}rem;
      }
    `};
  ${media.lessThan('medium')`
    width: 100%;
  `}
  ${(props) =>
    props.nameTeam &&
    css`
      grid-area: NameTeam;
    `};
`;
export const ContainerButtons = styled.div<{ team?: boolean }>`
  gap: 10px;
  display: flex;
  align-items: center;
  margin: 34px auto 0;
  justify-content: space-between;

  ${(props) =>
    props.team &&
    css`
      width: 100%;
      margin: 34px 0 0;
      button:first-child {
        border: none;
        min-width: auto;
        border-radius: 40px;
        padding: 0 5px !important;
        :hover {
          background: none;
          color: #ffb620;
        }
      }
    `};
`;
export const PhotoContainer = styled.div`
  width: 101px;
  height: 101px;
  margin-left: 12px;
  grid-area: Avatar;
  border-radius: 6px;
  border: 0.998408px dashed #d9d9d9;
`;
export const HiddenInput = styled.input`
  display: none;
`;
export const Title = styled.h2<{ small?: boolean }>`
  line-height: 125%;
  font-style: normal;
  margin-bottom: 24px;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.title.default};
  font-weight: ${({ theme }) => theme.typography.fontWeightBolder};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
  ${(props) =>
    props.small &&
    css`
      margin-bottom: 0;
      max-width: 175px;
      line-height: 130%;
      grid-area: SubTitle;
      font-weight: ${({ theme }) => theme.typography.fontWeightBolder - 200};
      font-size: ${({ theme }) => theme.typography.fontSize + 0.2}rem;
    `};
`;
export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
`;
export const Img = styled.img`
  width: 34px;
  height: 34px;
  object-fit: cover;
`;
export const Text = styled.p`
  max-width: 71px;
  margin-top: 7px;
  color: #d9d9d9;
  line-height: 140%;
  text-align: center;
  font-family: 'Poppins';
  letter-spacing: 0.004em;
  font-size: ${({ theme }) => theme.typography.fontSize - 0.6}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
export const ContentNoPhone = styled.div`
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const Register = styled(ButtonSmall)`
  margin-top: 22px;
  width: 50%;
  margin-bottom: 42px;
  font-size: ${(props) => props.theme.typography.fontSize + 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBolder};
  ${media.lessThan('medium')`
    width: 100%;
    max-width: 212px;
  `}

  span {
    width: 25px !important;
    height: 25px !important;
  }
`;
