import { useContext, useEffect, useState } from 'react';
import { servicesApis } from 'service/service.api';
import { NotificationContext } from 'state/notification/state';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { CustomCircularProgress } from 'components/modules';
import { t } from 'translate/i18n';
import { Matchbet } from '..';
import { StoreList } from './components/StoreList';
import * as S from './MatchbetStoreStyled';

const MatchbetStore: React.FC = () => {
  const translationString = 'components.MatchBetStore';
  const [storeItems, setStoreItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch: notificationDispatch } = useContext(NotificationContext);

  const getStoreItems = async () => {
    try {
      const response = await servicesApis.matchBetStore.getStoreItems();
      setStoreItems(response.data);
    } catch (error) {
      const message = t(`${translationString}.error.unknow_error`);
      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStoreItems();
  }, []);

  return (
    <Matchbet>
      <S.MainContainer>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.SubTitle>{t(`${translationString}.subTitle`)}</S.SubTitle>
        {isLoading ? (
          <S.LoadingContainer>
            <CustomCircularProgress />
          </S.LoadingContainer>
        ) : (
          <StoreList data={storeItems} empty={storeItems.length === 0} />
        )}
      </S.MainContainer>
    </Matchbet>
  );
};

export default MatchbetStore;
