/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './@types/actions';

const profileSettingsReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.OPEN_MENU:
      return {
        ...state,
        menu: {
          open: true
        }
      };

    case Actions.CLOSE_MENU:
      return {
        ...state,
        menu: {
          open: false
        }
      };

    default:
      return {};
  }
};

export default profileSettingsReducer;
