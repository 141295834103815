/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { NFTProviderType } from './@types';
import nftStoreListReducer from './reducer';

const initialState: any = {
  nft_store_item_selected: {
    nft: {
      image_url: '',
      video_url: '',
      name: '',
      description: '',
      entity_id: ''
    },
    title: '',
    subtitle: '',
    price: '',
    validity: 0,
    has_nft: false
  },
  nft_store_items: [],
  user_nft_selected: {
    image_url: '',
    video_url: '',
    name: '',
    description: '',
    entity_id: ''
  },
  user_nfts: [],
  reload: true
};

export const NftStoreListContext = createContext(initialState);

const NFTProvider: React.FC<NFTProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(nftStoreListReducer, initialState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <NftStoreListContext.Provider value={provider}>
      {children}
    </NftStoreListContext.Provider>
  );
};

export default NFTProvider;
