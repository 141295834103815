import styled, { css } from 'styled-components';

export const CardContainer = styled.div<{
  readOnly?: boolean;
  tournament?: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  border-radius: 12px;
  display: flex;
  min-height: 332px;
  flex-direction: column;
  min-width: ${(props) => (props.readOnly ? '254px' : '188px')};
  width: ${(props) => (props.readOnly ? '254px' : '188px')};
  height: ${(props) => (props.readOnly ? '391px' : 'auto')};
  padding: 16px 12px;
  border: 2px solid transparent;
  transition: border 50ms linear;
  :hover {
    border: 2px solid ${({ theme }) => theme.palette.button.default};
  }
  ${({ tournament }) =>
    tournament &&
    css`
      height: 399px;
      min-width: 213.25px;
    `}
`;
export const Image = styled.img<{ readOnly?: boolean }>`
  object-fit: cover;
  border-radius: 10px;
  width: ${(props) => (props.readOnly ? '222px' : '100%')};
  height: ${(props) => (props.readOnly ? '220px' : '162px')};
`;
export const Tag = styled.div<{ readOnly?: boolean }>`
  font-family: 'Poppins', sans-serif;
  position: absolute;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.text.white};
  top: 26px;
  left: 23px;
  padding: ${(props) =>
    props.readOnly ? '5px 16px 5px 16px' : '4px 12px 4px 12px'};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.lightPurple};
`;
export const ImageContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
`;
export const Title = styled.h3<{ tournament?: boolean }>`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.palette.text.white};
  width: 40%;
  font-family: 'Russo One', sans-serif;

  ${({ tournament }) =>
    tournament &&
    css`
      align-self: baseline;
    `}
`;
export const PlatformInfo = styled.div<{ tournament?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: start;
  align-items: end;
  gap: 4px;
  ${({ tournament }) =>
    tournament &&
    css`
      align-self: baseline;
    `}
`;
export const PlatformInfoTitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.white};
  padding-top: 2px;
  width: 100%;
  height: fit-content;
  text-align: right;
  font-family: 'Poppins', sans-serif;
`;
export const BetValueTitle = styled.h4<{ readOnly?: boolean }>`
  font-size: ${(props) => (props.readOnly ? 1.4 : 1)}rem;
  color: ${({ theme }) => theme.palette.text.white};
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
`;
export const BetValue = styled.p<{ readOnly?: boolean }>`
  font-size: ${(props) => (props.readOnly ? 2.8 : 2.1)}rem;
  font-weight: 400;
  font-family: 'Russo One', sans-serif;
  color: ${({ theme }) => theme.palette.text.white};
`;
export const BetBox = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;
export const GoldCoin = styled.img<{ readOnly?: boolean }>`
  width: ${(props) => (props.readOnly ? '48px' : '36px')};
  height: ${(props) => (props.readOnly ? '46px' : '34px')};
`;
export const ButtonContainer = styled.div<{ readOnly?: boolean }>`
  display: ${(props) => (props.readOnly ? 'none' : 'flex')};
  justify-content: center;
  margin-top: auto;
  gap: 5px;
`;
export const CardInfoTitle = styled.p``;
export const ContainerDescription = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;
  justify-content: space-between;
  div {
    gap: 3px;
    div {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
  > div:last-child {
    gap: 0;
    h2 {
      line-height: 13px;
      font-size: 1.5rem;
    }
    p {
      line-height: 17px;
      font-size: 0.9rem;
    }
  }
`;

export const TournamentStatus = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 15.8538px;
  color: #ffffff;
  text-align: center;
`;
