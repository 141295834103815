import { FormikValues, FormikHelpers } from 'formik';

import { DataProps } from 'components/modules/EventModal/@types';
import { tournamentService } from 'service/tournament';
import { useModal } from 'components/modules/Modal';

export const useTournament = ({
  updatedData = () => {}
}: {
  updatedData?: () => void;
}) => {
  const { deleteTournament } = tournamentService();
  const { closeModal } = useModal();

  const onSubmitEventModal = async (
    values: FormikValues & DataProps,
    { setSubmitting }: FormikHelpers<FormikValues>
  ) => {
    setSubmitting(true);

    await deleteTournament(values.entity_id as string).then(() => {
      closeModal();
      updatedData();
    });

    setSubmitting(true);
  };

  return {
    onSubmitEventModal
  };
};
