export type ChangePersonalDataType = {
  phone: string;
  nickname: string;
  birthday: string;
};

export type ChangePhoneNumberType = {
  currentNumber: string;
  newNumber: string;
};

export type FinancePasswordType = {
  showCurrentPassword: boolean;
  currentPassword?: string;
  newPassword: string;
  confirmPassword: string;
};

export type PlatformPasswordType = {
  newPassword: string;
  currentPassword: string;
  confirmPassword: string;
};

export enum DropdownComponents {
  PERSONAL_DATA = 'PERSONAL_DATA',
  PHONE_NUMBER = 'PHONE_NUMBER',
  FINANCE_PASSWORD = 'FINANCE_PASSWORD',
  PLATFORM_PASSWORD = 'PLATFORM_PASSWORD'
}

export type ActiveComponent = {
  active: boolean;
  component: DropdownComponents;
};
