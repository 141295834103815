import React, { useContext, useEffect } from 'react';

import { ProfileNotificationsContext } from 'state/profileNotifications/state';
import { Actions } from 'state/profileNotifications/@types/actions';

import { servicesApis } from 'service/service.api';

import { CustomCircularProgress } from 'components/modules/CustomCircularProgress';
import { t } from 'translate/i18n';
import * as C from './UpdateListButtonStyled';

const UpdateListButton: React.FC = () => {
  const { state, dispatch } = useContext(ProfileNotificationsContext);

  const translationString =
    'components.header.profileMenu.notification.updateListButton';

  const getMessage = (): string => {
    if (state.status.loading) return t(`${translationString}.searching`);

    return t(`${translationString}.updateNotifications`);
  };

  const getNotifications = async (): Promise<void> => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: { loading: true }
    });

    try {
      const response = await servicesApis.user.getNotifications();

      dispatch({
        type: Actions.SET_NOTIFICATIONS,
        payload: {
          notifications: response
        }
      });
    } catch (error) {
      console.error(error);
    }

    dispatch({
      type: Actions.SET_LOADING,
      payload: { loading: false }
    });
  };

  useEffect(() => {
    const componentDidMount = (): void => {
      getNotifications();
    };

    componentDidMount();
  }, []);

  return (
    <C.Container disabled={state.status.loading}>
      {state.status.loading ? (
        <CustomCircularProgress />
      ) : (
        <C.IconWrapper>
          <C.Refresh onClick={getNotifications} />
        </C.IconWrapper>
      )}
      <C.Message>{getMessage()}</C.Message>
    </C.Container>
  );
};

export default UpdateListButton;
