import React from 'react';

import { CountDown } from 'components/modules/CountDown';
import { Registered } from 'components/modules/Registered';

import * as S from './TournamentCounterStyled';
import { Props } from './@types';

const TournamentCounter: React.FC<Props> = ({
  startDate,
  maxPlayers,
  registered,
  registeredLabel
}) => {
  const isNow =
    !!startDate && new Date(startDate).getTime() <= new Date().getTime();

  return (
    <S.Container>
      <CountDown isNow={isNow} startDate={startDate} />
      <Registered
        max={maxPlayers}
        registered={registered}
        text={registeredLabel}
      />
    </S.Container>
  );
};

export default TournamentCounter;
