import { i18n } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const newTeamSchema = yup.object().shape({
  nameTeam: yup
    .string()
    .required(i18n.t('errors.mandatoryName'))
    .min(3, i18n.t('errors.invalidName'))
});
export default newTeamSchema;
