import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 3%;
`;
