/* eslint-disable no-console */
import { t } from 'i18next';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { utils } from 'ethers';
import { UserContext } from 'state/user/state';
import { useTournament } from 'state/tournament/hooks';
import { tournamentService } from 'service/tournament';
import { PayloadCreateTournament } from 'service/@types';

import { useModal } from 'components/modules/Modal';
import { getErrorStringName } from 'utils/errorHandling';

export const useSelectedCompetitors = () => {
  const {
    state: { user }
  } = useContext(UserContext);
  const { createTournament } = tournamentService();
  const { tournament, removePlayerSelected } = useTournament();
  const { showModal, closeModal } = useModal();

  const [isLoading, setLoading] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState(
    tournament?.selectedPlayers || []
  );
  const navigate = useNavigate();
  const router = useLocation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [keyMessage, setKeyMessage] = useState('');

  useEffect(() => {
    setSelectedPlayers(tournament.selectedPlayers);
  }, [JSON.stringify(tournament.selectedPlayers)]);

  const queryParams = new URLSearchParams(router.search);
  const playAlone = queryParams.get('playAlone') === 'true';
  const selectedModel: number = tournament.sizeTournament;
  const selectedPlayersLength = selectedPlayers.length;
  const hasPlayerSelected = !!selectedPlayersLength;
  const translationString = 'components.tournament.selectedCompetitors';
  const hasCompletedTournament = selectedPlayersLength === selectedModel;

  const translation = {
    title: t(`${translationString}.title`),
    selectPlayers: {
      title: t(`${translationString}.selectPlayers.title`),
      subTitle: t(`${translationString}.selectPlayers.subTitle`)
    },
    footerTextFirst: t(`${translationString}.footer.text.first`),
    footerTextLast: t(`${translationString}.footer.text.last`),
    footerLink: t(`${translationString}.footer.link`),
    completedTournament: t(
      `${translationString}.footer.button.completedTournament`
    ),
    completingTournament: t(
      `${translationString}.footer.button.completingTournament`
    ),
    error: t(`${translationString}.error.${keyMessage}`)
  };

  const textButton = hasCompletedTournament
    ? translation.completedTournament
    : `${translation.completingTournament} ${selectedPlayersLength}/${selectedModel}`;

  const handleRemoveUser = (entity_id: string) => {
    const newState = removePlayerSelected(entity_id);

    setSelectedPlayers([...newState]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payloadCreateTournament = (values: any): PayloadCreateTournament => {
    const entryFeeString = `${values.amount}`;
    const entryFeeBigNumber = utils.parseEther(entryFeeString);
    const entryFeeConverted = entryFeeBigNumber.toString();
    const payload = {
      name: values.name,
      description: values.description,
      rules: values.rules,
      platform_id: values.platform.entity_id,
      entry_fee: entryFeeConverted,
      start_date: values.startDate,
      tournament_type: 'CLOSE',
      image_url:
        'https://s3.amazonaws.com/dev.matchbet/tournaments/decc/decc3250-a1a2-4be9-b63b-684d511c3c38',
      max_players: values.sizeTournament,
      game_id: values.game.entity_id,
      stage_infos: {
        time_between_stages: values.timeBetweenStages,
        stage_time: values.stageTime,
        ready_timeout: values.readyTimeout,
        stages_per_day: values.stagesPerDay
      },
      selected_players: values.selectedPlayers?.map(
        (player: { entity_id: string; created_by: string }) =>
          playAlone ? player.entity_id : player?.created_by
      )
    };
    return payload;
  };

  const handleClick = async () => {
    const payload = payloadCreateTournament(tournament);
    const isYourTournament = tournament.created_by === user.entityId;

    setLoading(true);
    await createTournament(payload)
      .then((response) => {
        if (tournament?.isStartNow) {
          navigate('/matchbet/tournament', {
            state: { entity_id: response.entity_id, isAdmin: isYourTournament }
          });
        } else {
          navigate('/matchbet/tournaments');
        }
        setLoading(false);
      })
      .catch(async (error) => {
        const errorCode = await getErrorStringName(
          error.response.data.error_code
        );

        setShowErrorMessage(true);
        setKeyMessage(errorCode.toLowerCase());
        setLoading(false);
      });
  };

  return {
    handleRemoveUser,
    hasCompletedTournament,
    selectedModel,
    selectedPlayers,
    hasPlayerSelected,
    translation,
    textButton,
    showModal,
    closeModal,
    tournament,
    handleClick,
    isLoading,
    showErrorMessage
  };
};
