/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './@types/actions';

const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.SET_PERSONAL_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.payload.phone,
          nickname: action.payload.nickname,
          birthday: action.payload.birthday
        }
      };

    case Actions.SET_PHONE_NUMBER:
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.payload.phone
        }
      };

    case Actions.SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
          phone: action.payload.phone,
          nickname: action.payload.nickname,
          entityId: action.payload.entityId,
          birthday: action.payload.birthday,
          image_url: action.payload.image_url,
          updateState: action.payload.updateState,
          hasFinancialPassword: action.payload.hasFinancialPassword
        }
      };

    case Actions.SET_WALLET_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          Step: action.payload.Step,
          emailTransfer: action.payload.emailTransfer,
          Amount: action.payload.Amount,
          Wallet: action.payload.Wallet
        }
      };

    case Actions.SET_USER_BALANCE:
      return {
        ...state,
        user: {
          ...state.user,
          balance: action.payload.balance
        }
      };

    case Actions.SET_USER_LINKREF:
      return {
        ...state,
        user: {
          ...state.user,
          linkRef: action.payload.linkRef
        }
      };

    case Actions.SET_NFT_OWNERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          champBetOnwership: action.payload.champBetOnwership
        }
      };
    default:
      return {};
  }
};

export default userReducer;
