import React, { useContext, useEffect, useState } from 'react';

import { GeneralButton } from 'components/elements/GeneralButton';
import {
  CustomCircularProgress,
  NewTeam,
  NewGame,
  BetAmount
} from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { Search } from 'components/modules/Search';
import intersaction from 'lodash.intersection';
import { Friend } from 'models/Friends';
import { EntityGame, EntityPlatform } from 'models/Games';
import { useLocation } from 'react-router-dom';
import { platformsMock } from 'service/mocks/platforms';
import { servicesApis } from 'service/service.api';
import { Actions } from 'state/battle/@types/actions';
import { BattleContext } from 'state/battle/state';
import { t } from 'translate/i18n';
import {
  Values,
  Type as BetAmountType
} from 'components/modules/BetAmount/@types';
import { FormikHelpers } from 'formik';
import { usePagination } from 'utils/Pagination';
import { OpenGlobalBets } from './components/OpenGlobalBets';
import { Matchbet } from '..';
import { Pagination } from '../../components/elements/Pagination';
import { ContentData, Game, Team } from './@types';
import { List } from './components/List';
import { Type } from './components/List/@types';
import { PlatformFilter } from './components/PlatformFilter';
import { Progress } from './components/Progress';
import * as S from './CreateBattleStyled';
import BetCreated from './components/BetCreated';

const CreateBattle: React.FC = () => {
  const [platforms, setPlatforms] = useState<string[]>([]);

  const { state, dispatch } = useContext(BattleContext);
  const {
    paginateSearch,
    resetPage,
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data
  } = usePagination({ ROWS_REQUIRED: 6 });
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);

  const { showModal, closeModal, closeAndShow } = useModal();

  const router = useLocation();

  const queryParams = new URLSearchParams(router.search);
  const playAlone = queryParams.get('playAlone') === 'true';

  const translationString = 'components.createBattle';

  const [dataToShow, setDataToShow] = useState<ContentData>({
    type: 'mock',
    response: []
  });

  const setLoading = (loading: boolean): void => {
    dispatch({
      type: Actions.SET_LOADING,
      payload: {
        loading
      }
    });
  };

  const getTeams = async () => {
    setLoading(true);

    const response = await servicesApis.user.getTeam();

    if (response?.data.length) {
      calculatePaginationVars(response?.data);
      setLoading(false);
    }

    setLoading(false);
  };

  const getGames = async () => {
    setLoading(true);

    const response = await servicesApis.battle.getGames();

    if (response?.data.length) {
      calculatePaginationVars(response?.data);
    }

    setLoading(false);
  };

  const getChallengers = async (type: string, teamId = '') => {
    setLoading(true);

    const response = await servicesApis.friends.getFriends(type, teamId);

    calculatePaginationVars(response?.data);

    setLoading(false);
  };

  const getList = (): void => {
    if (state.battle.step === 1 && !playAlone) {
      getTeams();
    }

    if (state.battle.step === 2) {
      getGames();
    }

    if (state.battle.step === 3 && playAlone) {
      getChallengers('alone');
    }

    if (state.battle.step === 3 && !playAlone) {
      getChallengers('team', state.battle.teamSelected.entity_id);
    }
  };

  const updatedData = () => {
    setShouldUpdateData(true);
  };

  useEffect(() => {
    if (!shouldUpdateData) return;
    getList();
    setShouldUpdateData(false);
  }, [shouldUpdateData]);

  useEffect(() => {
    getList();
  }, [state.battle.step, router.pathname]);

  useEffect(() => {
    setDataToShow({
      type: 'dataApi',
      response: data
    });
  }, [JSON.stringify(data)]);

  const searching = (valueSearched: string): void => {
    const functionFilter = (item: Team | Game | Friend) =>
      ((item as Team).name || (item as Game).name || (item as Friend).apelido)
        .toLocaleLowerCase()
        ?.includes(valueSearched.toLocaleLowerCase());

    paginateSearch(functionFilter);
  };

  const paginateGames = (games: string[], valueSearched: string): void => {
    const filterByPlatform = (game: EntityGame) =>
      intersaction(
        games,
        (game.platforms as EntityPlatform[]).map(
          (platform: EntityPlatform) => platform.name
        )
      ).length > 0;

    const filterByNameAndPlatforms = (game: EntityGame) =>
      game.name
        .toLocaleLowerCase()
        ?.includes(valueSearched.toLocaleLowerCase()) &&
      intersaction(
        games,
        (game.platforms as EntityPlatform[]).map(
          (platform: EntityPlatform) => platform.name
        )
      ).length > 0;

    const functionFilter =
      valueSearched.length > 0 ? filterByNameAndPlatforms : filterByPlatform;

    paginateSearch(functionFilter);
  };

  const filterGames = (games: string[]) => {
    const search: HTMLInputElement | null =
      document.querySelector('#search-input');

    if (search) {
      if (games.length > 0) {
        paginateGames(games, search.value);
      } else if (search.value.length > 0) {
        searching(search.value);
      } else {
        resetPage();
      }
    } else {
      resetPage();
    }
  };

  const searchTeamOrGame = (valueSearched: string): void => {
    if (valueSearched?.length > 0) {
      filterGames(platforms);
    } else {
      resetPage();
    }
  };

  const shouldRedirectToStepToOnReloadPage = () => {
    if (playAlone) {
      dispatch({
        type: Actions.SET_STEP,
        payload: {
          step: 2
        }
      });
    }
  };

  useEffect(() => {
    closeModal();
    resetPage();
    shouldRedirectToStepToOnReloadPage();
  }, [router.pathname]);

  const onCloseBetAmount = (): void => {
    closeModal();
  };

  const onSubmitBetAmount = (
    { amount, password }: Values,
    formik: FormikHelpers<Values>
  ) => {
    dispatch({
      type: Actions.SET_BET_AMOUNT,
      payload: {
        amount
      }
    });

    closeAndShow(
      <BetCreated
        currentPassword={password}
        onSubmitBetAmount={onSubmitBetAmount}
        onCloseBetAmount={onCloseBetAmount}
      />,
      '475px',
      true,
      '35px 44px 25px'
    );

    formik.resetForm();
  };

  const getStep = (): 'first' | 'second' | 'third' => {
    switch (state.battle.step) {
      case 1:
        return 'first';

      case 2:
        return 'second';

      case 3:
        return 'third';

      default:
        return 'first';
    }
  };

  const getTypeByStep = (): Type => {
    switch (state.battle.step) {
      case 1:
        return Type.TEAM;

      case 2:
        return Type.GAME;

      case 3:
        if (playAlone) {
          return Type.CHALLENGE_PLAYER;
        }
        return Type.CHALLENGE_TEAM;

      default:
        return Type.TEAM;
    }
  };

  const onClickToAdd = (): void => {
    switch (state.battle.step) {
      case 1:
        showModal(
          <NewTeam updateState={getList} />,
          '475px',
          true,
          '35px 42px 43px'
        );
        break;

      case 2:
        showModal(
          <NewGame updateState={getList} platforms={platformsMock} />,
          '468px',
          true,
          '19px'
        );
        break;

      case 3:
        showModal(
          <BetAmount
            type={BetAmountType.BATTLE}
            onClose={onCloseBetAmount}
            onSubmit={onSubmitBetAmount}
          />,
          '475px',
          true,
          '20px'
        );
        break;

      default:
        showModal(
          <NewTeam updateState={getList} />,
          '468px',
          true,
          '35px 42px 43px'
        );
        break;
    }
  };

  const getPageDescription = (): string => {
    switch (state.battle.step) {
      case 1:
        return t(`${translationString}.description.matchFormat`);

      case 2:
        return t(`${translationString}.description.selectAGame`);

      case 3:
        return t(`${translationString}.description.challenge`);

      default:
        return t(`${translationString}.description.matchFormat`);
    }
  };

  const getAddButtonLabel = (): string => {
    switch (state.battle.step) {
      case 1:
        return t(`${translationString}.addButton`);

      case 2:
        return t(`${translationString}.addButton`);

      case 3:
        if (window.innerWidth < 562) {
          return t(`${translationString}.addButton`);
        }
        return t(`${translationString}.newGlobalBet`);

      default:
        return t(`${translationString}.addButton`);
    }
  };

  const savePlatform = (games: string[]) => {
    setPlatforms(games);
    filterGames(games);
  };

  const clearList = () => {
    setDataToShow({
      type: 'mock',
      response: []
    });
  };

  const gameId = state.battle.step === 3 ? state.battle.game.entity_id : '';
  const platformId =
    state.battle.step === 3 ? state.battle.platform.entity_id : '';

  return (
    <Matchbet>
      <S.Container step={state.battle.step}>
        <S.ListWrapper>
          <Progress step={getStep()} />
          <S.Title>{t(`${translationString}.title`)}</S.Title>
          <S.AuxiliarTitle>
            {t(`${translationString}.auxiliarTitle`)}
          </S.AuxiliarTitle>
          {state.battle.step === 3 ? (
            <S.Description step={state.battle.step}>
              {getPageDescription()}
            </S.Description>
          ) : null}
          <S.Wrapper step={state.battle.step}>
            {state.battle.step <= 2 ? (
              <S.Description step={state.battle.step}>
                {getPageDescription()}
              </S.Description>
            ) : null}
            {state.battle.step === 3 && window.innerWidth > 1096 ? (
              <S.Subtitle>{t(`${translationString}.subtitle`)} </S.Subtitle>
            ) : null}
            <S.SearchContainer>
              <PlatformFilter
                data={dataToShow?.response}
                search={dataToShow?.response}
                type={getTypeByStep()}
                onChange={savePlatform}
              />
              <Search
                placeholder={t(`${translationString}.search.placeholder`)}
                onChange={searchTeamOrGame}
              />
              <GeneralButton
                variant="primary"
                size="medium"
                onClick={() => onClickToAdd()}
              >
                {getAddButtonLabel()}
              </GeneralButton>
            </S.SearchContainer>
          </S.Wrapper>
          {state.battle.loading && (
            <S.LoadingWrapper>
              <CustomCircularProgress />
            </S.LoadingWrapper>
          )}
          <List
            type={getTypeByStep()}
            loading={state.battle.loading}
            playAlone={playAlone}
            data={dataToShow}
            updatedData={updatedData}
            clearList={clearList}
          />

          {!state.battle.loading && numberOfPages > 1 && (
            <S.PaginationWrapper>
              <Pagination
                numberOfPages={numberOfPages}
                selectedPage={selectedPage}
                clickOnRightArrow={clickOnRightArrow}
                clickOnLeftArrow={clickOnLeftArrow}
                clickOnPageIndicator={clickOnPageIndicator}
              />
            </S.PaginationWrapper>
          )}
        </S.ListWrapper>
        {state.battle.step === 3 ? (
          <S.OpenBetsWrapper step={state.battle.step}>
            <OpenGlobalBets
              playAlone={playAlone}
              gameId={gameId}
              platformId={platformId}
            />
          </S.OpenBetsWrapper>
        ) : null}
      </S.Container>
    </Matchbet>
  );
};

export default CreateBattle;
