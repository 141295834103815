import { Battle } from 'models/Tournaments';

export type TableHeaderProps = {
  matchCurrent: number;
  stageSelected: { order: number };
  setStageSelected: React.Dispatch<
    React.SetStateAction<{
      order: number;
    }>
  >;
  stagesLength: number[];
  stages: {
    title: string;
    order: number;
    avaliable: boolean;
    matches: number;
  }[];
};

type Player = {
  nickname: string;
  image_url: string;
  entity_id: string;
};

export enum Status {
  CREATED = 'CREATED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  VALIDATED = 'VALIDATED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  SUPPORT = 'SUPPORT',
  WAITING_OPPONENT = 'WAITING_OPPONENT'
}

export type TableRowProps = {
  entityId: string;
  player1: Player;
  player2: Player;
  startDate: string;
  endDate: string;
  status: Status;
  validator: null | {
    first: {
      user_id: string;
    };
  };
  isAdmin: boolean;
  tournamentId: string;
  tournamentCreatedById: string;
  userId: string;
  callback?: () => void;
  battleWinner: string;
};

export type TableProps = {
  data: Battle[];
  isAdmin: boolean;
  tournamentId: string;
  tournamentCreatedById: string;
  callback?: () => void;
  stageSelected: { order: number };
  setStageSelected: React.Dispatch<
    React.SetStateAction<{
      order: number;
    }>
  >;
  stagesLength: number[];
  isLoading: boolean;
  stages: {
    title: string;
    order: number;
    avaliable: boolean;
    matches: number;
  }[];
};
