/* eslint-disable no-console */
import { API } from 'aws-amplify';

import { ENDPOINTS } from 'config/endpoints';
import config from 'config/constants';
import { ResponseTournamentByID } from 'models/Tournaments';
import { TournamentByID } from './mocks/Tournament';

import { PayloadCreateTournament } from './@types';

export const tournamentService = () => {
  const getTournament = async (
    tournamentId: string
  ): Promise<ResponseTournamentByID> => {
    const useMock = false;
    if (useMock) {
      return Promise.resolve(TournamentByID);
    }
    try {
      const response = await API.get(
        config.APIS.MATCHBET,
        ENDPOINTS.tournament.getTournamentId(tournamentId),
        {}
      );
      return response.data;
    } catch (error) {
      throw new Error("Can't delete your tournament");
    }
  };

  const deleteTournament = async (tournamentId: string) => {
    const useMock = false;
    if (useMock) {
      return Promise.resolve({});
    }
    try {
      const response = await API.del(
        config.APIS.MATCHBET,
        ENDPOINTS.tournament.getTournamentId(tournamentId),
        {}
      );
      return response;
    } catch (error) {
      throw new Error("Can't delete your tournament");
    }
  };

  const createTournament = async (payload: PayloadCreateTournament) => {
    const useMock = false;

    if (useMock) {
      return Promise.resolve({
        ...payload,
        success: true
      });
    }

    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.tournament.createTournament,
      { body: payload }
    );
    return response;
  };

  const setWinnerTournament = async ({
    battleId,
    winnerId,
    tournamentId
  }: {
    battleId: string;
    winnerId: string;
    tournamentId: string;
  }) => {
    const useMock = false;
    if (useMock) {
      return Promise.resolve({});
    }
    try {
      const response = await API.post(
        config.APIS.MATCHBET,
        ENDPOINTS.tournament.setWinner(battleId),
        {
          body: {
            winner_id: winnerId,
            tournament_id: tournamentId
          }
        }
      );
      return response;
    } catch (error) {
      throw new Error("Can't delete your tournament");
    }
  };

  return {
    getTournament,
    deleteTournament,
    createTournament,
    setWinnerTournament
  };
};
