import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const MessageContainer = styled.div`
  width: 479px;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${media.lessThan('medium')`
  `}
`;
const Title = styled.h2<{ large?: boolean; small?: boolean }>`
  width: 100%;
  font-family: 'Roboto';
  letter-spacing: 0.0015em;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;

  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.typography.fontSize + 0.2}rem;
    `};
  ${(props) =>
    props.large &&
    css`
      font-size: ${props.theme.typography.fontSizeRegular}rem;
      font-weight: ${props.theme.typography.fontWeightBold};
    `};
`;
const Icon = styled.img`
  margin-bottom: 16px;
`;
const Text = styled.p`
  display: flex;
  max-width: 269px;
  margin: 6px 0 34px;
  align-items: center;
  font-family: 'Roboto';
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  p {
    margin: 0px;
  }
`;

export { Icon, Text, Title, MessageContainer };
