import { i18n } from 'translate/i18n';
import { useModal } from '../Modal';
import * as S from './TermoStyled';

export const TermoModalEng = () => {
  const { closeModal } = useModal();

  const handleClose = () => {
    closeModal();
  };
  return (
    <S.TermoContainer>
      <S.Title>Terms and Conditions of Use</S.Title>
      <S.Container>
        <S.Text>
          These terms and conditions of use (&quot;Termos de Uso&quot;),
          describe the terms and conditions applicable to the access and
          consultation of {i18n.t('titles.matchcoins')} balance in the MatchCash
          Platform by you, the MatchCash Platform User. It is important that you
          read these Terms of Use carefully, and you agree to be bound by them
          when you register.
        </S.Text>
        <S.Text>
          The &quot;Acceptance&quot; of the following terms is required for any
          use of your MatchCash Account. If you do not agree to the following
          terms, you may not use or access the site in any way. Furthermore,
          registration as a User is not permitted for a person who is legally
          incapable.
        </S.Text>
        <S.SubTitle>1. Definitions</S.SubTitle>
        <S.Text>
          1.1 Wherever the terms &quot;MatchCash&quot;, &quot;we&quot;,
          &quot;us&quot; or &quot;our&quot; appear, we are referring to
          MatchCash, and whenever the terms &quot;you&quot;, &quot;you&quot;,
          &quot;your&quot; or &quot;your&quot; are used, we are referring to you
          as the &quot;user&quot; who is agreeing to these Terms of Use for
          accessing the Site.
        </S.Text>
        <S.Text>
          1.2 For the purposes hereof, the following words and expressions shall
          have the respective meanings ascribed to them below: Email: The email
          to be used by the User in the registration must be the same that was
          informed to perform the acquisition of {i18n.t('titles.matchcoins')}
          &nbsp; in the sales page of MatchCash&apos;s website
          <S.Link href="https://sales.matchbet.io/" target="_blank">
            (sales.matchbet.io)
          </S.Link>
          .
        </S.Text>
        <S.Topics>
          <S.Topic>
            Virtual Account: is the account where the User can view and consult
            the balance of {i18n.t('titles.matchcoins')} acquired and available,
            linked to the e-mail of purchase, within the MatchCash platform.
          </S.Topic>
          <S.Topic>
            Matchcoin: Utility Token utilizado para todas as transações internas
            ao ecossistema da MatchCash.O saldo de Tokens a ser visualizado por
            você foi aquele adquirido na Pré-Venda, exclusivamente, através do
            site
            <S.Link href="https://sales.matchbet.io/" target="_blank">
              (sales.matchbet.io)
            </S.Link>
            .
          </S.Topic>
          <S.Topic>
            Future Balance: Balance that is being processed with an expected
            return in the future.
          </S.Topic>
          <S.Topics>
            <S.Topic>
              E-mail:
              <S.Link href="mailto:admin@matchbet.io">admin@matchbet.io</S.Link>
            </S.Topic>
            <S.Topic>Whatsapp: +1 (321) 257-3602 </S.Topic>

            <S.Topic>
              Telegram:
              <S.Link href="https://t.me/matchbetprevenda">
                https://t.me/matchbetprevenda
              </S.Link>
            </S.Topic>
          </S.Topics>
          <S.Topic>
            MatchCash Platform: MatchCash&apos;s own digital platform that
            enables Users to access the Matchcoin balance query services for
            viewing and checking purposes before sending them to their wallets.
          </S.Topic>
          <S.Topic>
            Wallet: Wallets or digital wallets are responsible for storing the
            keys - sequence of numbers to pay and receive cryptocurrencies or
            tokens.
          </S.Topic>
          <S.Topic>
            User: an individual, over the age of 18 and capable, who has adhered
            to these Terms of Use by registering on the MatchCash platform,
            entering the registration data in the Virtual Account application
            form and accepting the Terms of Use.
          </S.Topic>
        </S.Topics>
        <S.SubTitle>2. Introduction</S.SubTitle>
        <S.Text>
          2.1. The object of these Terms of Use is to provide MatchCash with
          information regarding the User&apos;s Matchcoin balance. It is
          important that you check your Matchcoin balance in order to see how
          many {i18n.t('titles.matchcoins')} can be sent to your wallet when
          they become available, in accordance with the whitepaper available on
          the MatchCash site. If the User does not agree with the amount
          of&nbsp;
          {i18n.t('titles.matchcoins')} informed in his virtual account, he must
          contact our Customer Service Channels.
        </S.Text>
        <S.Text>
          2.2. The User declares to be aware that the virtual balance is
          available for verification only.
        </S.Text>
        <S.Text>
          2.3 You must accept these Terms of Use in order to use MatchCash
          Services. You must read, understand and agree to all of the terms and
          conditions before you register as a MatchCash Member.
        </S.Text>
        <S.Text>
          2.4 MatchCash will not be liable for any failure, error, interruption,
          malfunction, delay or other failure of MatchCash or any of its
          partners to provide a timely, uninterrupted, unavailable, or slow
          service. MatchCash considers such situations to be predictable and
          possible in the case of technology services.
        </S.Text>
        <S.Text>
          2.5. You acknowledge that there may be a delay of up to 72 hours
          before your balance will be displayed after you purchase your&nbsp;
          {i18n.t('titles.matchcoins')} on the MatchCash site, starting from the
          10th of May 2022.
        </S.Text>
        <S.Text>
          2.6 The systems necessary for the operation of your Account may be
          unavailable to you, as they are dependent upon services provided by
          third parties such as telecommunication companies and internet service
          providers, and in the event of technical failures or problems with the
          internet, system interruptions or crashes, or any other circumstances
          beyond the control of MatchCash or its business partners, which may
          make it impossible to view your balance. In addition, we will suspend
          the use of and access to the Platform in the case of unforeseen
          circumstances or force majeure, for example, in the case of cyber
          attacks and other attacks that may impair the functionality of the
          Platform and put the Users&apos; information at risk, or even in the
          case of maintenance and improvements to the Platform. Users may NOT
          hold MatchCash liable for any damages resulting from the above
          mentioned difficulties, or for any other type of damage arising from
          the provisions of this clause.
        </S.Text>
        <S.Text>
          2.7 MatchCash is not responsible for the receipt of forged e-mails or
          messages from third parties not directly related to MatchCash or its
          group companies.
        </S.Text>
        <S.Text>
          2.8. By agreeing to these Terms of Use you agree that MatchCash will
          act as your agent to provide the Services and will comply with your
          requests, subject to applicable law and these Terms of Use.
        </S.Text>

        <S.SubTitle>3. MatchCash Account</S.SubTitle>
        <S.Text>
          3.1. You are only eligible to maintain a MatchCash Account if you are
          18 years of age or older at the time of registration and you are an
          Individual. In order to register you must provide certain information
          that will allow us to identify you.
        </S.Text>
        <S.Text>
          3.2 The User, when registering in the Platform, has automatically
          created a MatchCash Account in his name, which consists in informing
          the User his Matchcoin balance for mere checking.
        </S.Text>
        <S.Text>
          3.3 The User will receive an e-mail to validate the registration in
          the informed address, as well as a confirmation code. If you do not
          receive this email, please contact us to verify the effective creation
          of your account.
        </S.Text>
        <S.Text>
          3.4 Your MatchCash account is unique, personal and non-transferable.
          The e-mail address provided during registration can only be associated
          with a single user. MatchCash will not accept the registration of new
          accounts by the same Member.
        </S.Text>
        <S.Text>
          3.5. The information provided during registration must be complete,
          with all required items filled out with accurate information, and the
          user is solely responsible for the information provided. It is up to
          the &apos;User&apos; to update the information whenever necessary,
          through the MatchCash Customer Service Channels, maintaining its
          truthfulness, under penalty of suspension of the Services, blocking
          MatchCash account, and the &apos;User&apos; will be liable in the
          civil, administrative and criminal spheres foreseen by law.
        </S.Text>
        <S.Text>
          3.6. A MatchCash se reserva o direito de, a qualquer momento e ao seu
          critério, solicitar informações do Usuário, para verificar a
          veracidade dos dados informados. Entretanto, se as informações não
          forem enviadas, a MatchCash se reserva o direito de cancelar o
          cadastro a qualquer momento e suspender a Conta do Usuário, sem a
          necessidade de aviso prévio.
        </S.Text>
        <S.Text>
          3.7 You are responsible for maintaining the secrecy of your password,
          which is personal and non-transferable. Your password is the only way
          for you to view your balance and enter the financial area. MatchCash
          will not be liable in any way for any damage caused to you or any
          third party by the unauthorized disclosure or use of passwords.
        </S.Text>
        <S.Text>
          3.8. You must notify MatchCash immediately if you become aware of
          fraud, loss, or any security incident that results in the sharing of
          your password or credentials with us, or if we suspect or suspect that
          you may be misusing your MatchCash Account, so that MatchCash can lock
          your account and issue a new password to you.
        </S.Text>
        <S.Text>
          3.9. You may only consult your Matchcoin balance through the tools
          available in your MatchCash Account.
        </S.Text>
        <S.Text>
          3.10. MatchCash reserves the right to suspend, limit, block, or
          disable the Services on MatchCash Account funds indefinitely, and you
          will not be entitled to any damages or compensation in the event of
          violation of the terms of these Terms of Use; suspected fraud; failure
          to provide requested information; incorrect or untruthful data
          provided by the User; conduct practiced with the purpose of obtaining
          balance in an illicit and/or fraudulent manner; involvement of the
          User in any act of corruption, active or passive; involvement in
          actions characterized as bribery, conflict of interest, terrorism
          financing, money laundering; any other participation in crimes
          described in all laws of the relevant jurisdictions that result
          applicable.
        </S.Text>
        <S.Text>
          3.11. You will access your MatchCash Account information through the
          website provided by MatchCash
          <S.Link href="http://sales.matchbet.io/" target="_blank">
            (sales.matchbet.io)
          </S.Link>
          . The availability of your MatchCash Account Balance is for all legal
          purposes accountable to you.
        </S.Text>
        <S.Text>
          3.12. In order to use the tools, the User must have internet access,
          and it is the User&apos;s responsibility to acquire the equipment
          (computer, smartphone, tablet) and programs (browser) necessary for
          browsing the Platform and the adequate provisioning of all internet
          resources (such as connection), without exception, as well as keeping
          the computer, smartphone and/or tablet environment safe, using
          available tools such as antivirus and firewall, among others, updated,
          in order to contribute to the prevention of electronic risks.
        </S.Text>

        <S.SubTitle>4. Withdrawal of funds from the Digital Account</S.SubTitle>
        <S.Text>
          4.1. The amount of {i18n.t('titles.matchcoins')} available in the
          User&apos;s MatchCash Account will be available for withdrawal between
          July 04 and 09, 2022, as described in the whitepaper on the MatchCash
          website, through the &quot;Withdrawal&quot; Button that will be
          available from these dates. To do so, the User must connect to his
          wallet so that the available amounts are transferred to his digital
          wallet.
        </S.Text>

        <S.SubTitle>5. MatchCash&apos;s Obligations</S.SubTitle>

        <S.Text>
          5.1. MatchCash will inform the balance of{' '}
          {i18n.t('titles.matchcoins')}
          &nbsp; bought by the User in a correct way, corresponding to the
          number of&nbsp;
          {i18n.t('titles.matchcoins')} that the User has bought through
          MatchCash&apos;s site, with all the advantages of purchase included,
          if there has been any special link of purchase used by the User.
        </S.Text>
        <S.Text>
          5.2 MatchCash undertakes, for the period July 4 to July 9, 2022, to
          make available to Users the option to withdraw their&nbsp;
          {i18n.t('titles.matchcoins')} to their digital wallets, as described
          in the whitepaper, available on the MatchCash site.
        </S.Text>
        <S.Text>
          5.3 MatchCash will use its best endeavours to ensure compliance with
          the terms of these Terms of Use. However, you acknowledge that due to
          external factors beyond our control delays may occur and you agree
          that MatchCash shall not be liable for any delay or liability
          whatsoever.
        </S.Text>
        <S.Text>
          5.4 MatchCash will hold your {i18n.t('titles.matchcoins')} in your
          MatchCash Account for an indefinite period of time.
        </S.Text>

        <S.SubTitle>
          6. Validity and Termination of these Terms of Use
        </S.SubTitle>

        <S.Text>
          6.1 These Terms of Use are valid for an indefinite period of time from
          the time of your acceptance at the time of registration.
        </S.Text>
        <S.Text>
          6.2 These Terms of Use will be promptly terminated upon notice to the
          User in the following situations:
        </S.Text>
        <S.Topics>
          <S.Topic>
            In case operations outside the standard of use are verified, and the
            User fails to comply with request to send information to prove the
            regularity of these operations;
          </S.Topic>
          <S.Topic>
            If we identify serious irregularities in the information given by
            the User
          </S.Topic>
        </S.Topics>
        <S.Text>
          6.3 If the User&apos;s MatchCash Account is suspended due to fraud or
          any other violation of these Terms of Use the amount of&nbsp;
          {i18n.t('titles.matchcoins')} you have wrongfully received may be
          cancelled. MatchCash reserves the right to unblock the User&apos;s
          MatchCash Account.
        </S.Text>
        <S.Text>
          6.3. Caso a Conta MatchCash do Usuário seja suspensa em razão de
          fraude ou infração a estes Termos de Uso, a quantidade de&nbsp;
          {i18n.t('titles.matchcoins')} recebida indevidamente pelo Usuário
          poderá ser cancelada. Cabe à MatchCash fazer ou não o desbloqueio da
          Conta MatchCash.
        </S.Text>

        <S.SubTitle>7. General Conditions</S.SubTitle>

        <S.Text>
          7.1 The parties acknowledge that the terms of these Terms of Use do
          not imply that MatchCash is a financial institution or an insurer.
          MatchCash does not provide any banking or foreign exchange services to
          the User, nor any other activities of Financial Institutions or
          insurance companies regulated or supervised by the Central Bank of
          Brazil, the Securities and Exchange Commission of Brazil (CVM) or the
          Superintendence of Private Insurance (Susep).
        </S.Text>

        <S.Text>
          7.2 MatchCash will not be responsible for and will not guarantee the
          fulfillment of any obligations assumed by Users with third parties.
        </S.Text>
        <S.Text>
          7.3 MatchCash and MatchCash have no other corporate or commercial
          relationship with each other than as set out in these Terms of Use,
          and each of them shall remain free and clear of all claims arising out
          of any tax, labor or social security obligations of their own. Neither
          MatchCash nor any User shall mislead any person as to their
          independence, nor bind either of them to any third party. Your failure
          to do so will entitle MatchCash to disable your MatchCash Account, and
          MatchCash reserves the right to take all appropriate legal and
          extra-judicial action.
        </S.Text>

        <S.SubTitle>8. Attachments</S.SubTitle>
        <S.Text>
          8.1 The following document is an integral and inseparable part of
          these Terms of Use, and incorporated herein by reference, wherein the
          policies and/or terms and conditions for the distribution of&nbsp;
          {i18n.t('titles.matchcoins')} are detailed. The respective document
          can be consulted through the link provided below:
        </S.Text>
        <S.Topics>
          <S.Topic>
            MatchCash Whitepaper:
            <S.Link href="https://whitepaper.matchbet.io/" target="_blank">
              https://whitepaper.matchbet.io/
            </S.Link>
          </S.Topic>
        </S.Topics>

        <S.SubTitle>9. Final Dispositions</S.SubTitle>
        <S.Text>
          9.1 The User understands and agrees that your relationship with
          MatchCash will be subject to the laws that apply to MatchCash,
          regardless of where you use our services.
        </S.Text>
        <S.Text>
          9.2 All notifications given by MatchCash to you will be given to your
          primary email address as stated on your registration form.
        </S.Text>
        <S.Text>
          9.3 The invalidity of any provision of these Terms of Use shall not
          render any other provision of these Terms of Use invalid. The
          provisions considered null or invalid will be rewritten in order to
          reflect the initial intention of its provision in accordance with
          applicable law, and inserted in the new version of these Terms of Use.
        </S.Text>
        <S.Text>
          9.4 Any tolerance by any of the parties to any violation of the terms
          and conditions of this document shall be considered mere liberality
          and shall not be interpreted as novation, invocable precedent, waiver
          of rights, tacit change of contractual terms, acquired right or
          contractual amendment.
        </S.Text>
        <S.Text>
          9.5 The court of jurisdiction of the User&apos;s domicile is hereby
          elected as the competent court to settle any doubt arising from this
          instrument.
        </S.Text>
        <S.Strong>Last Update: May 10, 2022</S.Strong>
      </S.Container>
      <S.Register onClick={handleClose}>REGISTER</S.Register>
    </S.TermoContainer>
  );
};
