import styled, { css } from 'styled-components';

type Show = {
  show: boolean;
};

type Height = {
  heightDesktop: number | null;
};

export const Container = styled.div<Show & Height>`
  height: 0px;
  background-color: #392e4f;
  position: absolute;
  top: 74px;
  left: -24px;
  width: 100vw;
  transition: all 300ms;
  overflow: hidden;

  ${({ show }) =>
    show &&
    css`
      height: calc(100vh - 98px);
      padding: 27px 0;
    `};

  @media (min-width: 769px) {
    width: 360px;
    top: 84px;
    left: unset;
    right: 50px;
    max-height: 760px;
    border-radius: 12px;

    ${({ show, heightDesktop }) =>
      show &&
      css`
        height: ${heightDesktop ? `${heightDesktop}px` : 'min-content'};
        padding: 0;
        padding-top: 27px;
      `};
  }
`;

export const ArrowUp = styled.div<Show>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #392e4f transparent;
  position: absolute;
  top: 64px;
  right: 95px;
  transform: translateX(50%);

  ${({ show }) =>
    !show &&
    css`
      border-width: 0;
      transition: border-width 150ms 300ms;
    `}

  @media (min-width: 769px) {
    right: 168.5px;
    top: 74px;
  }
`;

export const List = styled.ul`
  width: 100%;
  height: calc(100vh - 74px - 27px - 27px - 67.59px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 46px;
  overflow-y: scroll;

  @media (min-width: 769px) {
    height: auto;
    max-height: calc(760px - 84px - 16px);
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
