import { Button } from 'components/elements';
import styled from 'styled-components';

export const Container = styled(Button)`
  margin: 0;
  width: 139px;
  border-radius: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 20.4185px;
  line-height: 120%;
`;
