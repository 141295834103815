import DoubleBars from 'assets/img/icons/double-bars.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 27px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SliderTrack = styled.div`
  width: 100%;
  height: 5px;
  background: linear-gradient(
    90.04deg,
    rgba(255, 182, 32, 0.321875) -1.05%,
    #ffb620 21.05%,
    rgba(255, 182, 32, 0.3) 99.98%
  );
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;

  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }

  ::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
  }

  ::-ms-track {
    appearance: none;
    height: 5px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 27px;
    height: 27px;
    background-color: ${({ theme }) => theme.palette.button.default};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -11px;
    box-shadow: 0px 0px 20px ${({ theme }) => theme.palette.button.default};
    background-image: ${`url(${DoubleBars})`};
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: auto;
  }

  ::-moz-range-thumb {
    -webkit-appearance: none;
    width: 27px;
    height: 27px;
    background-color: ${({ theme }) => theme.palette.button.default};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -11px;
    box-shadow: 0px 0px 20px ${({ theme }) => theme.palette.button.default};
    background-image: ${`url(${DoubleBars})`};
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: auto;
  }

  ::-ms-thumb {
    -webkit-appearance: none;
    width: 27px;
    height: 27px;
    background-color: ${({ theme }) => theme.palette.button.default};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -11px;
    box-shadow: 0px 0px 20px ${({ theme }) => theme.palette.button.default};
    background-image: ${`url(${DoubleBars})`};
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: auto;
  }
`;
