export const tournamentDetails = {
  playerWin: 'Win',
  playerLess: 'Less',
  playerWithoutClass: 'Without definition',
  rowButton: 'Define',
  title: 'Tournament',
  altIcon: 'Demo icon',
  entranceFee: 'Award',
  gameTitle: 'Game',
  platform: 'Platform',
  startDate: 'Start date',
  registeredPlayers: 'Players',
  status_name: {
    created: 'Created',
    error: 'Error',
    pending: 'Pending',
    accepted: 'Accepted',
    validated: 'Validated',
    waiting_payment: 'Waiting payment',
    finished: 'Finished',
    canceled: 'Canceled',
    support: 'Support',
    waiting_opponent: 'Waiting oponent'
  },
  stage: {
    one: 'Stage 1',
    two: 'Stage 2',
    three: 'Quarterfinals',
    four: 'Semifinals',
    five: 'Final'
  },
  match: 'match',
  currentMatch: {
    title: 'Current match',
    button: 'Enter in battle',
    buttonChat: 'Enter chat'
  },
  winnerModal: {
    title: 'Set result',
    subTitle: 'Select the winning team to proceed.',
    button: {
      return: 'Return',
      set_result: 'Set result'
    },
    competitorSelected: 'Selected'
  }
};
