import styled from 'styled-components';

export const CountDownContainer = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Counter = styled.h2`
  display: flex;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 23px;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Russo One';
`;
export const CounterTexts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const CounterDescription = styled.span`
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 12px;
  font-family: 'Roboto';
`;
export const Pointer = styled.span`
  font-size: 2rem;
  align-self: flex-start;
`;
export const ContentDescription = styled.div`
  width: auto;
  display: flex;
  color: #fff;
  border-radius: 9px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const CounterTitle = styled.h2`
  font-weight: 400;
  line-height: 14px;
  font-style: normal;
  font-size: 1.2rem;
  font-family: 'Roboto';
`;
export const CounterParagraph = styled(CounterDescription)`
  margin-top: -5px;
  max-width: 570px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

export const CounterNow = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 15.8538px;
  line-height: 19px;
  color: #ffffff;
`;
