import { t } from 'translate/i18n';
import * as yup from 'yup';

const translationString = 'components.battle.schemas.eventModalSchema';

const eventModalSchema = yup.object().shape({
  password: yup
    .string()
    .required(t(`${translationString}.password.required`))
    .min(6, t(`${translationString}.password.min`))
});

export default eventModalSchema;
