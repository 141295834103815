import { MessageSuccess } from 'components/modules/MessageSuccess';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { useContext, useEffect } from 'react';
import { Actions } from 'state/notification/@types/actions';
import { NotificationContext } from 'state/notification/state';
import { GlobalStyles } from 'styles/globalStyled';
import { LayoutProps } from './@types';
import * as S from './LayoutStyled';

const Layout = ({ children }: LayoutProps) => {
  const { userActive } = useContentUserActive();

  const { dispatch } = useContext(NotificationContext);

  useEffect(() => {
    if (userActive.registrationSuccessful) {
      dispatch({
        type: Actions.OPEN,
        payload: {
          message: userActive.message
        }
      });
    }
  }, [userActive.registrationSuccessful]);

  return (
    <S.LayoutContainer>
      <GlobalStyles />
      <MessageSuccess />
      <S.LayoutContent>{children}</S.LayoutContent>
    </S.LayoutContainer>
  );
};

export default Layout;
