import styled, { css } from 'styled-components';
import { StyleStatusProps } from './@types';

export const Container = styled.div`
  gap: 10px;
  z-index: 1;
  width: 100%;
  display: flex;
  padding: 24px;
  min-height: 820px;
  align-items: start;
  flex-direction: column;

  @media (max-width: 1439px) {
    display: flex;
    flex-direction: column;
  }
`;
export const ContainerText = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const SubContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ContainerRanking = styled.div`
  gap: 10px;
  width: 100%;
  height: 550px;
  display: flex;
  overflow: hidden;
  border-radius: 12px;
  flex-direction: column;
  padding: 24px 23px 12px;
  backdrop-filter: blur(75px);
  background: rgba(255, 255, 255, 0.1);
`;
export const ContentTitle = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  max-width: 300px;
`;
export const Icon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
export const Img = styled.img`
  top: -90px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: relative;
  margin-bottom: -70px;
  border-radius: 74.5px;
  border: 1px solid #ffffff;
`;
export const Title = styled.h2`
  font-weight: 400;
  line-height: 100%;
  font-size: 4.8rem;
  font-style: normal;
  letter-spacing: -0.02em;
  font-family: 'Russo One';
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.title.default};

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 43px;
    margin-top: 20px;
    padding-left: 7px;
  }
`;
export const AuxiliarTitle = styled.p`
  top: 70px;
  left: 111px;
  z-index: 0;
  width: 200px;
  font-weight: 400;
  font-style: normal;
  line-height: 225px;
  color: transparent;
  position: absolute;
  font-size: 18.6711rem;
  font-family: 'Russo One';
  text-transform: uppercase;
  -webkit-text-stroke: 1.24474px rgba(255, 255, 255, 0.1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;

  @media (max-width: 768px) {
    font-size: 7.99566rem;
    line-height: 96px;
    top: 40px;
    left: 30px;
  }
`;
export const Description = styled.p`
  margin-top: 6px;
  font-weight: 500;
  max-width: 470px;
  line-height: 130%;
  font-size: 1.8rem;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 768px) {
    margin-top: 10px;
    line-height: 20px;
    padding-left: 7px;
  }
`;
export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;

  @media (max-width: 968px) {
    justify-content: center;
  }
`;
export const Text = styled.p`
  font-weight: 500;
  color: #ffffff;
  max-width: 180px;
  font-size: 1.6rem;
  line-height: 115%;
  font-style: normal;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Poppins';
`;
export const Info = styled(Text)`
  width: 100%;
  padding: 10px;
  display: flex;
  max-width: 100%;
  margin-bottom: 0;
  margin-top: 12px;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 130%;
  text-align: start;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const ContainerPosition = styled.div<StyleStatusProps>`
  min-width: 56px;
  width: ${({ size }) => size};
`;

export const Position = styled.h2`
  gap: 13px;
  width: 34px;
  height: 34px;
  font-weight: 400;
  font-size: 1.3rem;
  border-radius: 6px;
  font-family: 'Roboto';
  background: #ffb620;
  padding: 9.15385px 13.0769px;
  color: ${({ theme }) => theme.palette.title.default};
`;

export const Status = styled.div<StyleStatusProps>`
  --main-color: ${({ status }) => {
    switch (status) {
      case 'Victory':
        return '#52A569';
      case 'Lost':
        return '#FFB620';
      case 'Tie':
        return '#9F71FC';
      case 'Canceled':
        return '#A73737';
      case 'Pending':
        return '#FFB620';
      default:
        return '#52A569';
    }
  }};
  --main-size: ${({ status }) => {
    switch (status) {
      case 'Lost':
        return '44px';
      case 'Tie':
        return '39px';
      case 'Canceled':
        return '75px';
      case 'Pending':
        return '75px';
      default:
        return '59px';
    }
  }};

  width: auto;
  height: 22px;
  font-size: 1rem;
  color: #ffffff;
  font-weight: 400;
  line-height: 140%;
  padding: 4px 12px;
  border-radius: 4px;
  text-align: center;
  font-style: normal;
  font-family: 'Poppins';
  letter-spacing: 0.004em;
  max-width: var(--main-size);
  background: var(--main-color);
`;

export const ContentInfo = styled.div<StyleStatusProps>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '10px'};
  width: ${({ size }) => size || '40%'};

  div {
    gap: 10px;
    display: flex;
    align-items: center;
  }
`;

export const Name = styled.p`
  margin-top: 6px;
  font-weight: 500;
  max-width: 370px;
  line-height: 130%;
  font-size: 1.2rem;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 768px) {
    margin-top: 10px;
    line-height: 20px;
    padding-left: 7px;
  }
`;

export const TextRow = styled(Name)<StyleStatusProps>`
  text-align: start;
  width: ${({ size }) => size || '17%'};
`;

export const ContentValueBet = styled.div`
  width: 8%;
  display: grid;
  grid-template-areas:
    'text_bet coin'
    'value_bet coin';
  grid-template-rows: 0.5fr 1fr;
  grid-template-columns: 1fr auto;
`;

export const TextbBet = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  grid-area: text_bet;
  font-family: 'Roboto';
  text-transform: uppercase;
`;

export const ValueBet = styled.h2`
  font-size: 21px;
  color: #ffffff;
  font-weight: 400;
  grid-area: value_bet;
  font-family: 'Russo One';
`;

export const Coin = styled(Icon)`
  width: 36px;
  grid-area: coin;
`;

export const ImgUser = styled.img`
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 6px;
`;

export const TextHeader = styled.p<StyleStatusProps>`
  display: flex;
  min-width: 56px;
  margin-top: -6px;
  min-height: 28px;
  font-weight: 600;
  line-height: 130%;
  font-size: 1.2rem;
  text-align: start;
  align-items: center;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  width: ${({ size }) => size};
  color: ${({ theme }) => theme.palette.text.white};

  ${({ click }) =>
    click &&
    css`
      cursor: pointer;
    `};
`;
