import { Input } from 'components/elements/Input';
import { DoubleRangeSlider } from 'components/modules/DoubleRangeSlider';
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { Props } from './@types';

import * as S from './AmountSliderStyled';

const AmountSlider: React.FC<Props> = ({
  min,
  max,
  lowestValuePlaceholder = 'Minimum value',
  highValuePlaceholder = 'Maximum value',
  onChange = () => {}
}) => {
  const sliderMinimumRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const sliderMaximumRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const inputMinimumRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const inputMaximumRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onChangeInput = (values: string[]): void => {
    if (
      sliderMinimumRef?.current &&
      sliderMaximumRef?.current &&
      inputMinimumRef?.current &&
      inputMaximumRef?.current
    ) {
      const [minimum, maximum] = values;
      inputMinimumRef.current.value = minimum;
      inputMaximumRef.current.value = maximum;
      onChange(values);
    }
  };

  const notPermitSliderMoreThanMaxValue = (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ): void => {
    if (+(event.target as HTMLInputElement).value > max) {
      // eslint-disable-next-line no-param-reassign
      (event.target as HTMLInputElement).value = max.toString();
    }
  };

  const onChangeAmountBoxes = (
    event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ): void => {
    if (
      sliderMinimumRef?.current &&
      sliderMaximumRef?.current &&
      inputMinimumRef?.current &&
      inputMaximumRef?.current
    ) {
      if (+inputMinimumRef.current.value > +sliderMaximumRef.current.value) {
        sliderMinimumRef.current.value = sliderMaximumRef.current.value;
        inputMinimumRef.current.value = sliderMaximumRef.current.value;
      }

      if (+inputMinimumRef.current.value <= +sliderMaximumRef.current.value) {
        sliderMinimumRef.current.value = inputMinimumRef.current.value;
      }

      if (inputMinimumRef.current.value === '') {
        sliderMinimumRef.current.value = min.toString();
      }

      if (+inputMaximumRef.current.value < +sliderMinimumRef.current.value) {
        sliderMaximumRef.current.value = sliderMinimumRef.current.value;
      }

      if (+inputMaximumRef.current.value >= +sliderMinimumRef.current.value) {
        sliderMaximumRef.current.value = inputMaximumRef.current.value;
      }

      onChange([
        sliderMinimumRef.current.value,
        sliderMaximumRef.current.value
      ]);
    }

    notPermitSliderMoreThanMaxValue(event);
  };

  const backWithValues = (): void => {
    if (
      sliderMinimumRef?.current &&
      sliderMaximumRef?.current &&
      inputMinimumRef?.current &&
      inputMaximumRef?.current
    ) {
      inputMinimumRef.current.value = sliderMinimumRef.current.value;
      inputMaximumRef.current.value = sliderMaximumRef.current.value;
    }
  };

  useEffect(() => {
    onChangeInput([min.toString(), max.toString()]);
  }, [min, max]);

  return (
    <S.Container>
      <S.InputsWrapper>
        <S.InputWrapper>
          <Input
            type="number"
            placeholder={lowestValuePlaceholder}
            reference={inputMinimumRef}
            onChange={onChangeAmountBoxes}
            onKeyPress={onChangeAmountBoxes}
            onBlur={backWithValues}
          />
        </S.InputWrapper>

        <S.InputWrapper>
          <Input
            type="number"
            placeholder={highValuePlaceholder}
            reference={inputMaximumRef}
            onChange={onChangeAmountBoxes}
            onBlur={backWithValues}
            onKeyPress={onChangeAmountBoxes}
          />
        </S.InputWrapper>
      </S.InputsWrapper>

      <DoubleRangeSlider
        min={min}
        max={max}
        firstThumbPosition={min}
        secondThumbPosition={max}
        onChangeInput={onChangeInput}
        sliderMinimumRef={sliderMinimumRef}
        sliderMaximumRef={sliderMaximumRef}
      />
    </S.Container>
  );
};

export default AmountSlider;
