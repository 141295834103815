import styled from 'styled-components';

export const GoBackContainer = styled.button<{
  onClick?: (event: React.MouseEvent) => void;
}>`
  width: 34px;
  height: 34px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  z-index: 1;
`;

export const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;
