import { getImage } from 'assets/img';
import styled from 'styled-components';
import media from 'styled-media-query';

const MatchbetContainer = styled.div<{ confirmCode?: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  overflow-y: auto;
  min-height: 100vh;
  background-size: cover;
  flex-direction: column;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-image: url(${getImage('background_home')});
  overflow-x: hidden;
`;
const PageActive = styled.div<{ show?: boolean }>`
  height: auto;
  overflow-y: auto;
  min-height: 90vh;
  margin-left: 94px;
  width: calc(100% - 94px);
  justify-content: flex-end;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0;
  `}
`;
export { PageActive, MatchbetContainer };
