import styled from 'styled-components';

export const Container = styled.div``;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 16px;
`;

export const InputWrapper = styled.div`
  width: 151px;
`;
