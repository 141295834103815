import styled, { css } from 'styled-components';

export const Button = styled.div<{ active?: boolean }>`
  width: 25px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.palette.button.disable};
  border-radius: 7.5px;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.palette.button.default};
    `}
`;

export const Indicator = styled.div<{ active?: boolean }>`
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.light};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  transition: all 200ms ease-in-out;
  transform: translateX(1px);

  ${({ active }) =>
    active &&
    css`
      transform: translateX(11.5px);
    `}
`;
