export const battle = {
  banner: {
    title: 'Crie uma batalha e desafie seus amigos.',
    description: 'Jogue com seus amigos, crie times e muito mais.',
    alt: 'Cartões de batalha',
    button: {
      small: 'Criar batalha',
      large: 'Vamos lá !',
      goToStore: 'Ir para a Loja'
    },
    modal: {
      alt: 'imagem do trofeu.',
      title: 'Nova Batalha',
      subTitle: 'Com quem você quer jogar?',
      buttonPrimary: 'Avançar',
      buttonSecondary: 'Cancelar',
      typesOfMatch: {
        alone: 'Jogue sozinho',
        team: 'Jogue com um time',
        newTeam: 'Crie um novo time'
      },
      buttonNewTeam: 'Novo Time',
      buttonPlayAlone: 'Jogar Sozinho',
      buttonPlayWithTeam: 'Jogar com time',
      description: `Você não possui time criado`,
      descriptionWithTeam: `Com quem você quer jogar?`
    },
    buyCoins: 'Saldo insuficiente, Compre Matchcoins clicando aqui'
  },
  startNewBattle: {
    alt: 'imagem de troféu',
    title: 'Iniciar uma nova batalha',
    buttonNewTeam: 'Novo time',
    buttonPlayAlone: 'Jogar sozinho',
    buttonPlayWithTeam: 'Jogar com time',
    description: `Você não tem nenhum time criado ainda.`,
    descriptionWithTeam: `Com quem você deseja jogar nesse jogo?`
  },
  modalSelectPlatform: {
    placeholder: 'Apelido',
    buttonCancel: 'Cancelar',
    buttonCreate: 'Selecionar',
    title: 'Selecione uma plataforma',
    subTitle: 'Todos os jogos terão lugar na plataforma selecionada',
    description: 'Colocar o mesmo apelido usado na plataforma selecionada.'
  },
  battleInProgress: {
    buttonChat: 'Ir para o chat',
    title: 'Batalha corrente em progresso',
    description: {
      small: 'Para confirmar a pontuação, tire uma foto do quadro do placar.',
      large: 'Coloque o resultado no app'
    },
    playerOneImage: {
      alt: 'Jogador um'
    },
    playerTwoImage: {
      alt: 'Jogador dois'
    }
  },
  betCreated: {
    buttonCreate: 'Criar',
    title: 'Criar batalha',
    buttonCancel: 'Cancelar',
    betValue: 'valor da aposta',
    subtitle: 'SUA BET FOI CRIADA',
    platforms: 'Na(s) plataforma(s):',
    unknownError: 'Erro desconhecido',
    invalidFinancialPassword: 'Senha financeira inválida',
    description:
      'A equipe adversária deve aceitar sua bet para que a batalha começar',
    coinIcon: {
      alt: 'Imagem da moeda'
    },
    gameImage: {
      alt: 'Imagem do jogo'
    },
    messages: {
      successfulRegistration: 'Batalha criada com sucesso.'
    },
    error: {
      user_financial_password_invalid: 'Senha financeira incorreta',
      user_has_no_nft_to_create_battle:
        'Você precisa de uma NFT para criar a batalha',
      user_financial_password_not_found:
        'Você precisa de uma senha financeira para criar uma batalha'
    }
  },
  excludeBattles: {
    modal: {
      buttonReturn: 'Voltar',
      title: 'Excluir Batalha',
      buttonExclude: 'Excluir',
      description: 'Sua batalha ainda está esperando por um oponente'
    },
    messages: {
      success: 'Batalha excluída com sucesso.',
      failed: 'Batalha excluída falhou, tente novamente mais tarde.'
    }
  },
  newTeam: {
    Avatar: {
      alt: 'Imagem do usuário'
    },
    team: {
      alt: 'Imagem do time'
    },
    buttonCreate: 'Criar',
    buttonCancel: 'Cancelar',
    buttonDelete: 'Apagar',
    updateButton: 'Atualizar',
    labelTeam: 'Nome do time',
    title: 'Crie sua equipe',
    titleUpdate: 'Sua equipe',
    labelFriend: 'Adicionar novo amigo',
    subTitle: 'Adicione seus amigos ao time'
  },
  newGame: {
    avatar: {
      alt: 'Imagem do jogo'
    },
    gameIcon: {
      alt: 'Icone de jogo'
    },
    gameImage: {
      alt: 'Icone para adicionar imagem do jogo'
    },
    buttonCreate: 'Criar',
    buttonCancel: 'Cancelar',
    updateButton: 'Atualizar',
    labelTeam: 'Nome do jogo',
    title: 'Novo jogo',
    label: 'Selecione a(s) plataforma(s):',
    text: 'Add image for game',
    description:
      'Jogos adiconados pelo usuário não tem validação de resultado da MatchCash nas disputas, caso haja divergência no resultado da vitória, a MatchCash irá reter o rake e devolver o restante das Betcons para os oponentes igualmente.'
  },
  yourBattles: {
    emptyState: {
      titleHistory: 'Meu Histórico',
      title: 'Sua lista de batalhas criadas',
      paragraph: 'Quando você criar batalhas, elas aparecerão aqui.',
      button: `Vamos lá!`
    },
    card: {
      tag: 'Sua aposta',
      tagTournament: 'Seu torneio',
      betValue: 'VALOR PELA APOSTA',
      platform: 'Na(s) plataforma(s):',
      exclude: 'Excluir',
      view: 'Visualizar',
      finishedTournament: 'Encerrado',
      onGoingTournament: 'Em andamento'
    }
  },
  title: {
    battle: 'Battle MatchCash',
    tournament: 'Tournament MatchCash'
  },
  acceptBattle: {
    title: 'Entrar na batalha',
    gameImage: {
      alt: 'imagem do jogo'
    },
    coinIcon: {
      alt: 'moeda'
    },
    password: {
      placeholder: 'Senha financeira'
    },
    eyeIcon: {
      alt: 'ver senha'
    },
    platforms: 'Na(s) plataforma(s)',
    buttons: {
      cancel: 'Cancelar',
      create: 'Entrar'
    },
    notEnoughFunds: 'Você não tem saldo suficiente para entrar nessa batalha.',
    noPasswordSet:
      'Por favor defina uma senha financeira antes de entrar na batalha.',
    cannotAcceptBattleWhileInBattle:
      'Você não pode aceitar uma batalha enquanto uma batalha em andamento.',
    unknowError: 'Não foi possível aceitar a batalha'
  },
  openGlobalBattles: {
    title: 'Batalhas Globais',
    description: 'Batalhas 1:1',
    gameImage: {
      alt: 'Imagem do jogo'
    },
    platformLabel: 'Na(s) plataforma(s)',
    platformIcon: {
      alt: 'Icone de plataforma'
    },
    valueLabel: 'valor para a bet',
    coinIcon: {
      alt: 'Imagem da moeda'
    },
    modal: {
      buttonCancel: 'Cancelar',
      buttonCreate: 'Criar batalha',
      title: 'Aceitar a batalha',
      description:
        'O time oponente deve aceitar sua batalha para que ela se inicie'
    },
    emptyList: {
      title: 'Batalhas globais abertas',
      description: 'As batalhas globais abertas disponíveis irão aparecer aqui'
    }
  }
};
