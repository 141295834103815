/* eslint-disable no-console */
import React from 'react';
import { t } from 'i18next';
import { useFormik } from 'formik';

import { Input } from 'components/elements';
import { GeneralButton } from 'components/elements/GeneralButton';
import { useModal } from 'components/modules/Modal';
import { useTournament } from 'state/tournament/hooks';
import { SelectPlatformModal } from '../SelectPlatformModal';

import { Props, CustomInputProps } from './@types';

import TournamentModalModel from './TournamentModalModel';
import { validationSchema } from './TournamentModalSchema';

import * as S from './TournamentDetailsModalStyled';

const CustomInput = ({
  formik,
  onChange,
  placeholder,
  id,
  type,
  iconUrl,
  maxWidth,
  value
}: CustomInputProps) => (
  <S.CustomInput maxWidth={maxWidth}>
    <Input
      id={id}
      search
      type={type}
      iconUrl={iconUrl}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
    {formik.errors[id] && <S.TextError>{formik.errors[id]}</S.TextError>}
  </S.CustomInput>
);

const TournamentDetailsModal: React.FC<Props> = ({ platforms }) => {
  const { closeModal, closeAndShow } = useModal();
  const { setTournament } = useTournament();
  const {
    name,
    description,
    rules,
    timeBetweenStages,
    stageTime,
    readyTimeout,
    stagesPerDay
  } = TournamentModalModel;

  const translationString = 'components.tournament.tournamentDetailsModal';

  const initialValues = {
    [name.key]: '',
    [description.key]: '',
    [rules.key]: '',
    [timeBetweenStages.key]: undefined,
    [stageTime.key]: undefined,
    [readyTimeout.key]: undefined,
    [stagesPerDay.key]: undefined
  };

  const getMessageError = (key: string) =>
    t(`${translationString}.formModel.${key}.messageError`);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setTournament({
        ...values
      });

      closeAndShow(
        <SelectPlatformModal platforms={platforms} />,
        '475px',
        true,
        '35px 42px 43px'
      );
    },
    validationSchema: validationSchema(getMessageError),
    validateOnMount: true
  });

  const onCloseModal = (): void => {
    formik.resetForm();
    closeModal();
  };

  const isAvailableToContinue = formik.isValid;

  return (
    <S.Content>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.SubTitle>{t(`${translationString}.subTitle`)}</S.SubTitle>
      <S.Form onSubmit={formik.handleSubmit}>
        <CustomInput
          formik={formik}
          type="text"
          id={name.key}
          onChange={formik.handleChange}
          placeholder={t(
            `${translationString}.formModel.${name.key}.placeholder`
          )}
          value={formik.values[name.key]}
        />
        <CustomInput
          formik={formik}
          type="text"
          id={description.key}
          onChange={formik.handleChange}
          placeholder={t(
            `${translationString}.formModel.${description.key}.placeholder`
          )}
          value={formik.values[description.key]}
        />
        <CustomInput
          formik={formik}
          type="text"
          id={rules.key}
          onChange={formik.handleChange}
          placeholder={t(
            `${translationString}.formModel.${rules.key}.placeholder`
          )}
          value={formik.values[rules.key]}
        />

        <S.WrapperInput>
          <CustomInput
            formik={formik}
            type="number"
            id={timeBetweenStages.key}
            onChange={formik.handleChange}
            placeholder={t(
              `${translationString}.formModel.${timeBetweenStages.key}.placeholder`
            )}
            value={formik.values[timeBetweenStages.key]}
            maxWidth="170px"
          />
          <CustomInput
            formik={formik}
            type="number"
            id={stageTime.key}
            onChange={formik.handleChange}
            placeholder={t(
              `${translationString}.formModel.${stageTime.key}.placeholder`
            )}
            value={formik.values[stageTime.key]}
          />
        </S.WrapperInput>

        <S.WrapperInput>
          <CustomInput
            formik={formik}
            type="number"
            id={readyTimeout.key}
            onChange={formik.handleChange}
            placeholder={t(
              `${translationString}.formModel.${readyTimeout.key}.placeholder`
            )}
            value={formik.values[readyTimeout.key]}
          />
          <CustomInput
            formik={formik}
            type="number"
            id={stagesPerDay.key}
            onChange={formik.handleChange}
            placeholder={t(
              `${translationString}.formModel.${stagesPerDay.key}.placeholder`
            )}
            value={formik.values[stagesPerDay.key]}
            maxWidth="150px"
          />
        </S.WrapperInput>
      </S.Form>

      <S.ContainerButtons>
        <GeneralButton
          transparent
          size="medium"
          variant="primary"
          onClick={onCloseModal}
        >
          {t(`${translationString}.buttonCancel`)}
        </GeneralButton>
        <GeneralButton
          size="medium"
          type="submit"
          variant="primary"
          disabled={!isAvailableToContinue}
          onClick={() => formik.handleSubmit()}
        >
          {t(`${translationString}.buttonCreate`)}
        </GeneralButton>
      </S.ContainerButtons>
    </S.Content>
  );
};

export default TournamentDetailsModal;
