import { useContext, useEffect, useState } from 'react';
import { servicesApis } from 'service/service.api';
import { TournamentContext } from 'state/tournament/state';
import images from 'assets/img';
import { Selector } from 'components/elements';
import { GeneralButton } from 'components/elements/GeneralButton';
import { t } from 'translate/i18n';
import { useNavigate } from 'react-router-dom';
import { Actions as BattleActions } from 'state/battle/@types/actions';
import { Actions as TournamentActions } from 'state/tournament/@types/actions';
import { BattleContext } from 'state/battle/state';
import { NewTeam } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { NewEventProps } from './@types';
import * as S from './NewEventStyled';

const NewEvent: React.FC<NewEventProps> = ({
  isTournament = false
}: NewEventProps) => {
  const { dispatch: dispatchBattle } = useContext(BattleContext);
  const { dispatch: dispatchTournament } = useContext(TournamentContext);
  const navigate = useNavigate();
  const { closeModal, showModal } = useModal();
  const [indexTypeOfMatch, setIndexTypeOfMatch] = useState<number>(0);
  const [indexTypeOfTournament, setIndexTypeOfTournament] = useState<number>(0);

  const translationString = isTournament
    ? 'components.tournament.modalNewTournament'
    : 'components.battle.banner';
  const [typesOfMatch, setTypesOfMatch] = useState([
    { name: t(`${translationString}.modal.typesOfMatch.alone`), key: 'alone' },
    { name: t(`${translationString}.modal.typesOfMatch.team`), key: 'team' }
  ]);

  const handlePrevTypeOfMatch = () => {
    setIndexTypeOfMatch(indexTypeOfMatch - 1);
  };

  const handleNextTypeOfMatch = () => {
    setIndexTypeOfMatch(indexTypeOfMatch + 1);
  };

  const handlePrevTypeOfTournament = () => {
    setIndexTypeOfTournament(indexTypeOfTournament - 1);
  };

  const handleNextTypeOfTournament = () => {
    setIndexTypeOfTournament(indexTypeOfTournament + 1);
  };

  const handleClose = () => {
    closeModal();
  };

  const typesOfTournament = [
    {
      name: t(`${translationString}.modal.typesOfTournament.withFriends`),
      key: 'tournamentWithFriends'
    },
    {
      name: t(`${translationString}.modal.typesOfTournament.openTournament`),
      key: 'openTournament'
    }
  ];

  const getTeam = async () => {
    const response = await servicesApis.user.getTeam();
    if (response.data.length === 0) {
      setTypesOfMatch([
        ...typesOfMatch,
        {
          name: t(`${translationString}.modal.typesOfMatch.newTeam`),
          key: 'newTeam'
        }
      ]);
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  const navigateToCreateBattle = () => {
    const typeBattleSelected = typesOfMatch[indexTypeOfMatch].key;

    switch (typeBattleSelected) {
      case 'alone':
        dispatchBattle({
          type: BattleActions.SET_STEP,
          payload: {
            step: 2
          }
        });
        navigate('/matchbet/createBattle?playAlone=true');
        break;
      case 'team':
        dispatchBattle({
          type: BattleActions.SET_STEP,
          payload: {
            step: 1
          }
        });
        navigate('/matchbet/createBattle');
        break;
      case 'newTeam':
        showModal(<NewTeam />, '475px', true, '35px 42px 43px');
        break;
      default:
    }
  };

  const navigateToCreateTournament = () => {
    const typeTournamentSelected = typesOfMatch[indexTypeOfMatch].key;

    switch (typeTournamentSelected) {
      case 'alone':
        dispatchTournament({
          type: TournamentActions.SET_STEP,
          payload: {
            step: 2
          }
        });
        dispatchTournament({
          type: TournamentActions.TYPE_TOURNAMENT,
          payload: {
            typeTournament: typesOfTournament[indexTypeOfTournament].key
          }
        });
        navigate('/matchbet/createTournament?playAlone=true ');
        break;
      case 'team':
        dispatchTournament({
          type: TournamentActions.SET_STEP,
          payload: {
            step: 1
          }
        });
        dispatchTournament({
          type: TournamentActions.TYPE_TOURNAMENT,
          payload: {
            typeTournament: typesOfTournament[indexTypeOfTournament].key
          }
        });
        navigate('/matchbet/createTournament');
        break;
      case 'newTeam':
        showModal(<NewTeam />, '475px', true, '35px 42px 43px');
        break;
      default:
    }
  };

  const handleEvent = () => {
    if (isTournament) {
      return navigateToCreateTournament();
    }
    return navigateToCreateBattle();
  };

  return (
    <S.Container>
      <S.Title>{t(`${translationString}.modal.title`)}</S.Title>
      <S.Img src={images.Trophy} alt={t(`${translationString}.modal.alt`)} />
      <S.Text>{t(`${translationString}.modal.subTitle`)}</S.Text>
      <Selector
        list={typesOfMatch}
        prev={handlePrevTypeOfMatch}
        next={handleNextTypeOfMatch}
        itemSelected={
          typesOfMatch[indexTypeOfMatch] || {
            name: 'NAN',
            entity_id: 'NAN',
            image_url: 'NAN'
          }
        }
      />
      {isTournament && (
        <>
          <S.Margin />
          <Selector
            list={typesOfTournament}
            prev={handlePrevTypeOfTournament}
            next={handleNextTypeOfTournament}
            itemSelected={
              typesOfTournament[indexTypeOfTournament] || {
                name: 'NAN',
                entity_id: 'NAN',
                image_url: 'NAN'
              }
            }
          />
        </>
      )}
      <S.ContainerButtons>
        <GeneralButton
          onClick={() => handleClose()}
          transparent
          variant="primary"
          size="medium"
        >
          {t(`${translationString}.modal.buttonSecondary`)}
        </GeneralButton>

        <GeneralButton
          variant="primary"
          size="medium"
          onClick={() => handleEvent()}
        >
          {t(`${translationString}.modal.buttonPrimary`)}
        </GeneralButton>
      </S.ContainerButtons>
    </S.Container>
  );
};

export default NewEvent;
