import styled from 'styled-components';

export const CloseButtonContainer = styled.button`
  width: 24px;
  height: 24px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
