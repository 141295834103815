import { getImage } from 'assets/img';
import styled, { css } from 'styled-components';
import { Pagination as PaginationComponent } from 'components/elements';

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: grid;
  padding: 24px;
  min-height: 820px;
  grid-template-columns: 1fr 300px;
  grid-template-areas: 'banner list' 'rounds rounds';

  @media (max-width: 1919px) {
    grid-template-columns: 1fr 300px;
  }

  @media (max-width: 1439px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
  }
`;

export const BannerContainer = styled.div<{ img: string }>`
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 860px;
  grid-area: banner;
  border-radius: 20px;
  align-items: center;
  background-size: cover;
  padding: 30px 0 30px 55px;
  background-position: right;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(${(props) => props.img});
`;
export const ConntentDescription = styled.div`
  width: 50%;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 530px;
  flex-direction: column;
  > p:first-child {
    margin-bottom: 15px;
  }
  > p:nth-child(2n) {
    margin: 0 0 10px;
  }
  > p:nth-child(3n) {
    margin: 10px 0 20px;
  }
`;
export const ListContainer = styled.div`
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  grid-area: list;
`;
export const RoundsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-area: rounds;
  align-items: flex-end;
  justify-content: space-between;
  > div:first-child {
    flex: 0.98;
    > h2 {
      height: auto;
      max-width: 300px;
      font-size: ${(props) => props.theme.typography.fontSizeBold - 0.1}rem;
    }
  }

  .MuiTabPanel-root {
    padding: 12px 0;
  }
  div {
    > .MuiBox-root {
      min-height: 500px;
      .MuiBox-root {
        min-height: auto;
      }
    }
  }
`;
export const Card = styled.div`
  width: 100%;
  height: 107px;
  display: flex;
  margin: 10px 0;
  max-width: 486px;
  padding: 30px 16px;
  border-radius: 16px;
  align-items: center;
  justify-content: start;
  background-color: rgba(2, 2, 2, 0.1);
`;
export const ContainerText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.h2<{
  yellow?: boolean;
  small?: boolean;
  registered?: boolean;
}>`
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  line-height: 115%;
  align-items: center;
  font-family: 'Russo One';
  letter-spacing: 0.0015em;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeBold + 0.9}rem;
  ${(props) =>
    props.yellow &&
    css`
      color: ${props.theme.palette.button.default};
    `};
  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.typography.fontSize - 0.3}rem;
    `};

  ${(props) =>
    props.registered &&
    css`
      margin-bottom: 1rem;
    `};
`;
export const Text = styled.p<{ small?: boolean }>`
  line-height: 180%;
  font-family: 'Poppins';
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${(props) =>
    props.small &&
    css`
      line-height: 150%;
      font-size: ${props.theme.typography.fontSize - 0.4}rem;
    `};
`;
export const RulesTermo = styled.a`
  cursor: pointer;
  padding: 0 45px;
  margin-top: 10px;
  line-height: 180%;
  text-decoration: none;
  font-family: 'Poppins';
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
export const Icon = styled.img<{ formated?: boolean }>`
  width: 51px;
  height: 51px;
  margin-right: 8px;
  ${(props) =>
    props.formated &&
    css`
      object-fit: cover;
      border-radius: 9px;
    `};
`;
export const TitleTab = styled(Title)`
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSize + 0.2}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;
export const TextTab = styled(Text)`
  font-family: 'Poppins';
  font-size: ${(props) => props.theme.typography.fontSize + 0.2}rem;
`;
export const ContainerRows = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  > div:nth-child(odd) {
    background: rgba(255, 255, 255, 0.1);
  }
`;
export const Row = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 12px;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const ContainerRow = styled.div<{ small?: boolean; round?: boolean }>`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  ${(props) =>
    props.small &&
    css`
      width: 63px;
      font-size: ${props.theme.typography.fontSize - 0.3}rem;
    `};
  ${(props) =>
    props.round &&
    css`
      margin: 13px 0 30px;
    `};
`;
export const UserInfo = styled.div<{ two?: boolean }>`
  gap: 10px;
  display: flex;
  min-width: 120px;
  align-items: center;
  justify-content: ${(props) => (props.two ? 'flex-start' : 'flex-end')};
`;
export const Date = styled(Title)`
  max-width: 70px;
  font-family: 'Poppins';
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
`;
export const Point = styled(Title)`
  margin: 0 5px;
  font-family: 'Poppins';
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular + 100};
`;
export const Name = styled(Title)<{ start?: boolean }>`
  font-family: 'Poppins';
  align-items: flex-end;
  flex-direction: column;
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular + 100};

  ${(props) =>
    props.start &&
    css`
      align-items: flex-start;
    `};
`;
export const Avatar = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 8px;
`;
export const Status = styled(Text)<{ color: string; small?: boolean }>`
  width: auto;
  min-width: 100px;
  padding: 4px 12px;
  border-radius: 4px;
  text-align: center;
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular + 100};

  background: ${(props) => {
    switch (props.color) {
      case 'Running':
        return '#8fa5fe';
      case 'Completed':
        return '#52A569';
      case 'Pending':
        return '#666666';
      case 'Victory':
        return '#52A569';
      case 'Eliminated':
        return '#A73737';
      default:
        return '#8fa5fe';
    }
  }};

  ${(props) =>
    props.small &&
    css`
      width: 55.77px;
      padding: 4px 0px;
      font-size: ${props.theme.typography.fontSize - 0.6}rem;
    `};
`;
export const TitleRound = styled(Title)`
  font-family: 'Poppins';
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSizeRegular}rem;
`;
export const CurrentRound = styled.div<{ small?: boolean }>`
  width: 301px;
  height: 509px;
  display: flex;
  border-radius: 9px;
  align-items: center;
  padding-bottom: 51px;
  flex-direction: column;
  background-size: contain;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.06);
  background-image: url(${getImage('bannerRound')});

  ${(props) =>
    props.small &&
    css`
      width: 63px;
      font-size: ${props.theme.typography.fontSize - 0.3}rem;
    `};
`;

export const Pagination = styled(PaginationComponent)`
  margin-top: 10px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
