import React from 'react';
import { t } from 'translate/i18n';

import images from 'assets/img';

import { Props } from './@types';

import * as S from './BannerBattleStyled';

const BannerBattle: React.FC<Props> = ({
  current_match,
  showOnMobile = true
}) => {
  const translationString = 'components.tournament.bannerBattle';

  return (
    <S.Container showOnMobile={showOnMobile}>
      <S.Image src={current_match.image_url || images.defaultImage} />
      <S.Board>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.PlayersWrapper>
          <S.Name position="right">{current_match.player1.nickname}</S.Name>
          <S.PhotosWrapper>
            <S.Photo
              src={current_match.player1.image_url || images.defaultImage}
            />
            :
            <S.Photo
              src={current_match.player2.image_url || images.defaultImage}
            />
          </S.PhotosWrapper>
          <S.Name position="left">{current_match.player2.nickname}</S.Name>
        </S.PlayersWrapper>
        <S.Information>{t(`${translationString}.information`)}</S.Information>
        <S.ButtonWrapper>
          <a
            href="https://sales.matchbet.io/regras_gerais/"
            target="_blank"
            rel="noreferrer"
          >
            <S.Button size="large" variant="primary">
              {t(`${translationString}.button`)}
            </S.Button>
          </a>
        </S.ButtonWrapper>
      </S.Board>
    </S.Container>
  );
};

export default BannerBattle;
