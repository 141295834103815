import icons from 'assets/img/icons';
import Card from 'components/modules/Card/Card';
import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';
import { format } from 'date-fns';
import { Transaction, TransactionStatus } from 'models/Transaction';
import React, { ReactElement } from 'react';
import { t } from 'translate/i18n';
import { Props } from './@types';
import * as S from './RecentsTransactionsStyled';

const RecentsTransactions: React.FC<Props> = ({ transactions = [] }) => {
  function getColorStatusIndicator(status: TransactionStatus): string {
    switch (status) {
      case TransactionStatus.PROCESSING:
        return 'yellow';

      case TransactionStatus.CONCLUDED:
        return 'green';

      default:
        return '';
    }
  }

  function renderTransaction(transaction: Transaction): ReactElement {
    return (
      <S.TransactionCard className="list-item">
        <S.TransactionHeader>
          <S.TransactionType>
            {transaction.type.toUpperCase()}
          </S.TransactionType>
          <S.TransactionDetail>
            <S.TransactionDate>{transaction.operation_id}</S.TransactionDate>
            <S.Dot />
            <S.TransactionDate>
              {format(new Date(transaction.date), 'dd-MM HH:mm:ss')}
            </S.TransactionDate>
          </S.TransactionDetail>
        </S.TransactionHeader>
        <S.TransactionFooter>
          <S.StatusContainer>
            <S.StatusIndicator
              className={getColorStatusIndicator(transaction.status)}
            />
            <S.TransactionStatus>{transaction.status}</S.TransactionStatus>
          </S.StatusContainer>
          <S.TransactionPrice>
            <ExtendedNumberBox position="top">
              {transaction.value ? transaction.value : '0.00'}
            </ExtendedNumberBox>
            {transaction.currency.replaceAll('_', ' ')}
          </S.TransactionPrice>
        </S.TransactionFooter>
        <S.ComplementaryInfoContainer>
          <S.TypeTransaction>{transaction.entry_type}</S.TypeTransaction>
          <S.FromToContainer>
            <S.UserInfoContainer>
              <S.FromToText>
                {t('components.recentTransactions.from')}
              </S.FromToText>
              <S.UserInfo>{transaction.user_from}</S.UserInfo>
            </S.UserInfoContainer>
            <S.UserInfoContainer>
              <S.FromToText>
                {t('components.recentTransactions.to')}
              </S.FromToText>
              <S.UserInfo>{transaction.user_to}</S.UserInfo>
            </S.UserInfoContainer>
          </S.FromToContainer>
        </S.ComplementaryInfoContainer>
      </S.TransactionCard>
    );
  }

  function renderEmpty(): ReactElement {
    return (
      <S.EmptyText
        dangerouslySetInnerHTML={{
          __html: t('components.recentTransactions.emptyLabel')
        }}
      />
    );
  }

  return (
    <S.Container>
      <S.ListHeader>
        <S.Title>{t('components.recentTransactions.title')}</S.Title>
        <div style={{ display: 'none' }}>
          <Card width="50px" height="50px" padding="8px" background="#392E4F">
            <S.Icon src={icons.calendar} />
          </Card>
        </div>
      </S.ListHeader>
      <S.List>
        {transactions.length !== 0 && transactions.map(renderTransaction)}
        {transactions.length === 0 && renderEmpty()}
      </S.List>
    </S.Container>
  );
};

export default RecentsTransactions;
