import React from 'react';
import { CustomCircularProgress } from '../CustomCircularProgress';

import { TableProps } from './@types';
import { Row, TableHeader } from './Components';

import * as S from './TableStyled';

export const Table = ({
  listRef,
  headerContent,
  rowContent,
  isLoading
}: TableProps) => (
  <S.ContainerTable>
    <TableHeader headerContent={headerContent} isLoading={isLoading} />
    {isLoading ? (
      <S.Containerloading>
        <CustomCircularProgress />
      </S.Containerloading>
    ) : (
      <Row listRef={listRef} rowContent={rowContent} isLoading={isLoading} />
    )}
  </S.ContainerTable>
);
