import { useContext, useEffect, useState } from 'react';
import { t } from 'translate/i18n';

import { Nft } from 'models/NftStoreList';

import { NftUserItem } from 'pages/NftStore/components/NftUserItem';
import { NftStoreListContext } from 'state/nft/state';
import { UserNftsProps } from './@types';

import * as S from './NftUserItemsStyled';

const NftUserItems: React.FC<UserNftsProps> = ({
  translationString
}: UserNftsProps) => {
  const { state } = useContext(NftStoreListContext);
  const [userNftList, setUserNtfList] = useState<Nft[]>(state.user_nfts);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setUserNtfList(state.user_nfts);
  }, [state.user_nfts]);

  return (
    <S.UserNftsContainer>
      {userNftList && userNftList.length > 0 && (
        <NftUserItem
          data={userNftList}
          title={t(`${translationString}.listUserNFTs.title`)}
          desktopRows={1}
          page={page}
          setPage={setPage}
          buttonLabel={t(`${translationString}.listUserNFTs.item.button.view`)}
        />
      )}
    </S.UserNftsContainer>
  );
};

export default NftUserItems;
