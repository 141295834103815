/* eslint-disable no-console */
import { GeneralButton, Input } from 'components/elements';
import { useModal } from 'components/modules/Modal';
import { useMobile } from 'hooks/useMobile';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { servicesApis } from 'service/service.api';
import { t } from 'translate/i18n';
import { Props } from './@types';
import * as S from './CheckoutInfoModalStyled';
import CheckoutResponseModal from '../CheckoutResponseModal/CheckoutResponseModal';

const CheckoutInfoModal: React.FC<Props> = ({
  translationString,
  item
}: Props) => {
  const [buyerInfo, setBuyerInfo] = useState({ name: '', lastname: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile] = useMobile();
  const { closeModal, closeAndShow } = useModal();
  const navigate = useNavigate();
  const getButtonSize = () => {
    return isMobile ? 'small' : 'large';
  };
  const checkOutStore = async () => {
    setIsLoading(true);
    const payload = {
      first_name: buyerInfo.name,
      last_name: buyerInfo.lastname,
      store_item_id: item.storeItemId,
      item_count: Number(item.itemQuantity)
    };
    await servicesApis.matchBetStore
      .postGateWay(payload)
      .then((response) => {
        closeModal();
        setIsLoading(false);
        window.open(response.payment_link, '_self');
        navigate('/matchbet/store/buyCoins');
        setBuyerInfo({ name: '', lastname: '' });
      })

      .catch(() => {
        closeAndShow(
          <CheckoutResponseModal type="error" />,
          '475px',
          true,
          '35px 42px 43px'
        );
      });
  };

  const isDisabled =
    buyerInfo.name.trim() === '' || buyerInfo.lastname.trim() === '';

  return (
    <S.MainContainer>
      <S.Description>
        {t(`${translationString}.checkoutInfoModal.description`)}
      </S.Description>
      <Input
        type="text"
        placeholder={t(`${translationString}.checkoutInfoModal.name`)}
        onChange={(e) => setBuyerInfo({ ...buyerInfo, name: e.target.value })}
        value={buyerInfo.name}
      />
      <Input
        type="text"
        placeholder={t(`${translationString}.checkoutInfoModal.lastname`)}
        onChange={(e) =>
          setBuyerInfo({ ...buyerInfo, lastname: e.target.value })
        }
        value={buyerInfo.lastname}
      />
      <S.ButtonContainer>
        <GeneralButton
          variant="primary"
          size={getButtonSize()}
          onClick={checkOutStore}
          disabled={isDisabled}
          isLoading={isLoading}
        >
          {t(`${translationString}.checkoutInfoModal.continueButton`)}
        </GeneralButton>
      </S.ButtonContainer>
    </S.MainContainer>
  );
};

export default CheckoutInfoModal;
