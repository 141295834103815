import { GeneralButton } from 'components/elements';
import { useMobile } from 'hooks/useMobile';
import { ChangeEvent, useState } from 'react';
import { useModal } from 'components/modules/Modal';
import { t } from 'translate/i18n';
import { CheckOutInfoModal } from '../CheckoutInfoModal';

import { Props } from './@types';
import * as S from './ItemModalStyled';

const ItemModal: React.FC<Props> = ({
  item,
  formattedPrice,
  price,
  storeItemId
}: Props) => {
  const [quantity, setQuantity] = useState('');
  const [isMobile] = useMobile();
  const translationString = 'components.MatchBetStore';
  const { closeAndShow } = useModal();

  const getButtonSize = () => {
    return isMobile ? 'small' : 'large';
  };

  const updateCart = (
    itemAdded: {
      description: string;
      entity_id: string;
      image_url: string;
      name: string;
    },
    itemQuantity: string
  ) => {
    const itemPayload = { ...itemAdded, itemQuantity, price, storeItemId };
    closeAndShow(
      <CheckOutInfoModal
        translationString={translationString}
        item={itemPayload}
      />,
      '475px',
      true,
      '35px 42px 43px'
    );
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(0, Number(e.target.value));
    setQuantity(String(value));
  };

  return (
    <S.Container>
      <S.ItemImage src={item.image_url} alt="Product Image" />
      <S.InfoContainer>
        <S.Description>{item.description}</S.Description>
        <S.Price>{formattedPrice}</S.Price>
        <S.StockMessage>
          {t(`${translationString}.itemModal.stockInfo`)}
        </S.StockMessage>
        <S.ButtonContainer>
          <S.Input
            onChange={handleQuantityChange}
            type="number"
            min="0"
            value={quantity}
          />
          <GeneralButton
            variant="primary"
            size={getButtonSize()}
            onClick={() => updateCart(item, quantity)}
            disabled={quantity === '' || quantity === '0'}
          >
            {t(`${translationString}.itemModal.cartButton`)}
          </GeneralButton>
        </S.ButtonContainer>
      </S.InfoContainer>
    </S.Container>
  );
};
export default ItemModal;
