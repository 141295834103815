import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SideBarContainer = styled(ContainerGeneric)<{ show?: boolean }>`
  left: 0;
  gap: 144px;
  z-index: 2;
  width: 94px;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  padding: 12px 0 45px 0;
  border-radius: 0 0 29px 0;
  transition: all 0.4s ease-out;
  justify-content: space-between;
  background: rgba(51, 41, 80, 0.3);

  @media (max-height: 930px) {
    padding: 12px 0 12px 0;
    gap: 0;
  }

  ${(props) => media.lessThan('medium')`
    display: none;
    ${
      props.show &&
      css`
        left: 0px;
      `
    }
  `}
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #2b2346;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 37px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.1);
  }
`;
const Icon = styled.img<{ marginTop?: string }>`
  width: 24px;
  height: 24px;
`;

const MatchBetIcon = styled.img<{ marginTop?: string }>``;

const ContainerCards = styled.div`
  gap: 24px;
  display: flex;
  position: relative;
  flex-direction: column;

  @media (max-height: 930px) {
    gap: 15px;
  }

  div {
    position: sticky;
    :hover {
      p {
        transition: opacity 0.4s ease-out;
        display: block;
      }
    }
  }
`;
const ContentArrow = styled.div<{ show?: boolean }>`
  top: 50%;
  width: 35px;
  right: -56px;
  height: 80px;
  padding: 4px;
  color: #fff;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  border-radius: 0px 60px 60px 0px;
  background: rgba(51, 41, 80, 0.3);
  &:active,
  &:focus {
    outline: 0;
    border: none;
  }
  svg {
    transform: rotateY(0deg);
    transition: all 0.4s ease-out;
    font-size: ${(props) => props.theme.typography.fontSizeRegular}rem;
  }
  ${(props) => media.lessThan('medium')`
    ${
      props.show &&
      css`
        svg {
          transform: rotateY(180deg);
          transition: all 0.4s ease-out;
        }
      `
    }
  `}
`;
const FloatingDescription = styled.p`
  top: 10px;
  left: 65px;
  padding: 7px 11px;
  position: absolute;
  width: max-content;
  border-radius: 5px;
  letter-spacing: 1px;
  font-family: 'Poppins';
  background: #77798b;
  transition: opacity 0.8s ease-out;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSize - 0.6}rem;
  z-index: 2;
  display: none;

  ::after {
    width: 0;
    height: 0;
    left: -5px;
    top: 8.5px;
    content: '';
    clear: both;
    position: absolute;
    border-right: 5px solid #77798b;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
`;

const SideBarItem = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 12px;
  background-color: #392e4f;
  transition: all 0.1s ease-out;
  border-radius: 12px;
  cursor: pointer;

  :hover {
    background-color: #7e7ae9;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #7e7ae9;
    `}
`;

export {
  Icon,
  ContentArrow,
  ContainerCards,
  SideBarItem,
  FloatingDescription,
  SideBarContainer,
  MatchBetIcon
};
