export const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3
};

export const TOURNAMENT_SIZE_TYPE = {
  FOUR_TEAMS: 4,
  EIGHT_TEAMS: 8,
  SIXTEEN_TEAMS: 16,
  THIRTY_TWO_TEAMS: 32
};
