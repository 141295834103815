import { CloseButton } from 'components/elements';
import { useContext, useState } from 'react';
import { Actions } from 'state/profileSettings/@types/actions';
import { ProfileSettingsContext } from 'state/profileSettings/state';
import { t } from 'translate/i18n';
import { ActiveComponent, DropdownComponents } from './@types';
import FinancePassword from './components/Menus/FinancePassword';
import PersonalData from './components/Menus/PersonalData';
import PlatformPassword from './components/Menus/PlatformPassword';
import UserData from './components/UserData';
import * as S from './UserProfileStyled';

export const UserProfile = () => {
  const [activeComponent, setActiveComponent] = useState<ActiveComponent>({
    active: false,
    component: DropdownComponents.PERSONAL_DATA
  });

  const { state, dispatch } = useContext(ProfileSettingsContext);

  const onClickToClose = (): void => {
    dispatch({
      type: Actions.CLOSE_MENU
    });
  };

  return (
    <S.UserProfileContainer open={state.menu.open}>
      <S.HeaderProfile>
        <S.HeaderTitle>{t('components.userProfile.title')}</S.HeaderTitle>
        <CloseButton onClick={onClickToClose} />
      </S.HeaderProfile>

      <S.Profile>
        <UserData />

        <PersonalData
          active={
            activeComponent.component === DropdownComponents.PERSONAL_DATA &&
            activeComponent.active
          }
          setActive={setActiveComponent}
        />

        <FinancePassword
          active={
            activeComponent.component === DropdownComponents.FINANCE_PASSWORD &&
            activeComponent.active
          }
          setActive={setActiveComponent}
        />

        <PlatformPassword
          active={
            activeComponent.component ===
              DropdownComponents.PLATFORM_PASSWORD && activeComponent.active
          }
          setActive={setActiveComponent}
        />
      </S.Profile>
    </S.UserProfileContainer>
  );
};
