import { v4 as uuid } from 'uuid';
import EmptyState from '../EmptyState';
import Card from '../Card';
import { CardListProps } from './@types';

const CardList = ({ data, type, updatedData }: CardListProps) => {
  return (
    <>
      {data.length === 0 ? (
        <EmptyState type={type} />
      ) : (
        data.map((item) => (
          <Card
            type={type}
            key={uuid()}
            item={item}
            updatedData={updatedData}
          />
        ))
      )}
    </>
  );
};

export { CardList };
