import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  padding: 0 9px;
`;

export const Step = styled.div<{ active?: boolean }>`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.difusePurple};
  color: ${({ theme }) => theme.palette.text.white};
  transition: all 500ms;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.palette.background.purple};
      position: relative;

      ::after {
        content: '';
        border: 9px solid
          ${({ theme }) => theme.palette.background.difusePurple};
        width: 35px;
        height: 35px;
        background-clip: padding-box;
        position: absolute;
        border-radius: 50%;
      }

      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    `}
`;

export const Dots = styled.p`
  font-size: 24px;
  transform: translateY(-25%);
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

export const Bar = styled.div<{
  active?: boolean;
  left?: boolean;
  right?: boolean;
}>`
  width: 86px;
  height: 3px;
  background: ${({ theme }) => theme.palette.background.difusePurple};
  transition: all 500ms;
  z-index: 1;

  @media (max-width: 768px) {
    width: 73px;
    height: 2.5px;
  }

  @media (max-width: 314px) {
    width: 100%;
  }

  ${({ active, left }) =>
    active &&
    left &&
    css`
      background: rgb(165, 58, 252);
      background: linear-gradient(
        90deg,
        rgba(165, 58, 252, 1) 0%,
        rgba(51, 41, 80, 0.3) 100%
      );
    `}

  ${({ active, right }) =>
    active &&
    right &&
    css`
      background: rgb(51, 41, 80);
      background: linear-gradient(
        90deg,
        rgba(51, 41, 80, 0.3) 0%,
        rgba(165, 58, 252, 1) 100%
      );
    `}
`;
