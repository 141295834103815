import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';

const MessageSuccessContainer = styled.div<{ show?: boolean }>`
  z-index: 3;
  opacity: 0;
  left: -100%;
  width: 100%;
  bottom: 16px;
  height: 56px;
  display: flex;
  position: fixed;
  max-width: 547px;
  padding: 18px 12px;
  border-radius: 12px;
  align-items: center;
  background: #a53afc;
  transition: all 1.5s ease-out;
  justify-content: space-between;
  box-shadow: 0px 12px 24px -6px rgba(96, 96, 96, 0.15),
    0px 0px 1px rgba(96, 96, 96, 0.1);

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      left: 10px;
    `};
`;
const Text = styled.p`
  width: 100%;
  line-height: 20px;
  margin-right: 24px;
  font-family: 'Inter';
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

const Register = styled(ButtonSmall)`
  width: 100%;
  float: right;
  padding: 8px;
  max-width: 86px;
  max-height: 40px;
  line-height: 120%;
  border-radius: 50px;
  font-family: 'Poppins';
  background: transparent;
  border: 1px solid #ffffff;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize + 0.4}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold + 200};
`;

export { MessageSuccessContainer, Text, Register };
