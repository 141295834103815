import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  border-radius: 12px;
  padding: 22px;
  display: flex;
  flex-direction: column;

  ${media.lessThan('medium')`
    padding: 0;
    background-color: transparent;
  `};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TitleFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2<{ small?: boolean; flex?: boolean }>`
  font-weight: 400;
  text-align: start;
  text-align: start;
  line-height: 27px;
  font-style: normal;
  font-family: 'Russo One';
  color: ${({ theme }) => theme.palette.title.default};
  font-size: ${({ theme }) => theme.typography.fontSizeRegular}rem;
`;

export const Text = styled.p<{ small?: boolean; uppercase?: boolean }>`
  text-align: end;
  font-weight: 400;
  text-align: start;
  line-height: 20px;
  font-style: normal;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.title.default};
  font-size: ${({ theme }) => theme.typography.fontSize}rem;
`;

export const ListBattles = styled.ul`
  flex-grow: 1;
  margin-top: 22px;
  max-height: 780px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
