import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import media from 'styled-media-query';

export const UserDataContainer = styled.div`
  gap: 18px;
  display: flex;
  margin-bottom: 30px;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const PhotoContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-height: 135px;
`;

export const PhoneContainer = styled.div`
  display: flex;
`;

export const Avatar = styled.img`
  width: 117px;
  height: 115px;
  border-radius: 20px;
  border: 4.5px solid #fff;
  object-fit: cover;
`;

export const EditIcon = styled.img`
  max-width: 26px;
  bottom: 4.5px;
  right: 4.5px;
  padding: 4px;
  max-width: 50px;
  margin-right: 0;
  position: absolute;
  -webkit-text-security: disc;
  border-radius: 10px 0px 10px 0px;
  background: ${({ theme }) => theme.palette.background.light};
`;

export const Text = styled.p<{ margin?: string }>`
  color: ${({ theme }) => theme.palette.text.white};
  font-family: 'Roboto';
  font-size: 1.6rem;
  ${media.lessThan('small')`
    font-size: 1.4rem;
  `};

  margin: 5px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 40px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Phone = styled(PhoneInput).attrs({
  inputStyle: {
    width: '100%',
    background: 'transparent',
    color: 'white',
    borderWidth: 0,
    paddingRight: 0
  },
  buttonStyle: {
    background: 'transparent',
    borderRight: 0,
    borderWidth: 0
  },
  dropdownStyle: {
    width: '459px',
    background: 'rgba(255, 255, 255, 0.8)',
    height: '140px'
  },
  containerStyle: { borderWidth: 0 }
})`
  .selected-flag {
    padding-left: 0;
    width: 25px;
  }

  .arrow {
    display: none;
  }

  .form-control {
    transform: translateX(-25px);
  }
`;
