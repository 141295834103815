/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';

import { TournamentProviderType } from './@types';
import tournamentReducer from './reducer';

const initialState: any = {
  tournament: {
    game: {
      created_by: null,
      status: '',
      entity_id: '',
      platforms: [],
      name: '',
      image_url: ''
    },
    nickname: '',
    platform: null,
    amount: '',
    createABetModal: {
      open: false
    },
    myTournaments: [],
    myTournamentHistory: [],
    step: 1,
    teamSelected: {
      image_url: '',
      created_by: '',
      entity_i: ''
    },
    loading: true,
    typeTournament: '',
    selectedPlayers: [],
    sizeTournament: ''
  }
};

export const TournamentContext = createContext(initialState);

const TournamentProvider: React.FC<TournamentProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(tournamentReducer, initialState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <TournamentContext.Provider value={provider}>
      {children}
    </TournamentContext.Provider>
  );
};

export default TournamentProvider;
