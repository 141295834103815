import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 27px - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 769px) {
    height: auto;
    margin-top: 10px;
    margin-bottom: 27px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-58px);

  @media (min-width: 769px) {
    transform: unset;
  }
`;

export const Image = styled.img`
  width: 136px;
  height: 136px;
  object-fit: contain;
`;

export const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 18px;
`;

export const Description = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.white};
  margin-top: 12px;
  width: 150px;
`;
