import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 125%;
  text-align: center;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const SubTitle = styled(Title)`
  max-width: 400px;
  text-align: center;
  margin: 24px auto 40px;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.2}rem;
`;

export const TextError = styled.p`
  margin-top: -18px;
  color: yellow;
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

export const ContainerButtons = styled.div`
  max-width: 278px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 50px auto 0;
`;

export const CustomInput = styled.div<{ maxWidth?: string }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  > div {
    margin: 0;
  }

  max-width: ${({ maxWidth }) => maxWidth || '100%'};
`;

export const WrapperInput = styled.div`
  display: flex;
  gap: 20px;
`;
