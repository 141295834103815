import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (min-width: 1170px) {
    grid-area: title;
  }
`;

export const Icon = styled.img`
  width: 34.15px;
  height: 34.15px;
  object-fit: contain;
  cursor: pointer;
`;

export const Text = styled.h1`
  width: 223px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.text.white};
  margin-left: 17.43px;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`;
