import images from 'assets/img';
import { GeneralButton } from 'components/elements/GeneralButton';
import { BetAmount, Card } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { Friend } from 'models/Friends';
import { EntityGame } from 'models/Games';
import { NewTeam } from 'components/modules/Banner/components';
import { Team } from 'pages/CreateBattle/@types';
import React, { useContext } from 'react';
import { Actions } from 'state/battle/@types/actions';
import { BattleContext } from 'state/battle/state';
import { t } from 'translate/i18n';
import { v4 } from 'uuid';
import {
  Type as BetAmountType,
  Values
} from 'components/modules/BetAmount/@types';
import { FormikHelpers } from 'formik';
import { SelectPlatformModal } from '../SelectPlatformModal';
import { Props, Type, DataType } from './@types';
import { Platforms } from './components/Platforms';

import * as S from './ListStyled';
import BetCreated from '../BetCreated';

const List: React.FC<Props> = ({
  type,
  data,
  loading = false,
  playAlone = false,
  updatedData = () => {},
  clearList = () => {}
}) => {
  const { dispatch } = useContext(BattleContext);

  const { showModal, closeModal, closeAndShow } = useModal();

  const translationString = 'components.createBattle';
  const isChallenge = [Type.CHALLENGE_TEAM, Type.CHALLENGE_PLAYER].includes(
    type
  );

  const onClickOnCard = (team: Team): void => {
    showModal(
      <NewTeam team={team} updateState={updatedData} />,
      '475px',
      true,
      '19px'
    );
  };

  const onSelectATeam = (
    event: MouseEvent,
    { entity_id, image_url, members, name }: Team
  ): void => {
    event.stopPropagation();

    dispatch({
      type: Actions.SELECT_TEAM,
      payload: {
        team: {
          name,
          members,
          entity_id,
          image_url
        }
      }
    });

    clearList();

    dispatch({
      type: Actions.SET_STEP,
      payload: {
        step: 2
      }
    });
  };

  const toggleCreateABetModal = (): void => {
    dispatch({
      type: Actions.TOGGLE_CREATE_A_BET_MODAL
    });
  };

  const onSelectPlatform = (): void => {
    clearList();

    dispatch({
      type: Actions.SET_STEP,
      payload: {
        step: 3
      }
    });
  };

  const onClickInAcceptGame = (game: EntityGame): void => {
    showModal(
      <SelectPlatformModal
        platforms={game.platforms}
        onSelectPlatform={onSelectPlatform}
      />,
      '475px',
      true,
      '35px 42px 43px'
    );

    dispatch({
      type: Actions.SELECT_GAME,
      payload: {
        game
      }
    });
  };

  const getOpenBets = (game: EntityGame): number => {
    return playAlone
      ? game?.open_battles_count?.alone_battles
      : game?.open_battles_count?.team_battles;
  };

  const renderCard = (game: EntityGame, index: number): React.ReactElement => {
    return (
      <S.Card id="card" $loading={loading} key={v4()}>
        <Card
          width={window.innerWidth < 486 ? '100%' : '187px'}
          height="min-content"
          padding="16px 12px"
          background="rgba(255, 255, 255, 0.1)"
        >
          <S.Picture src={game.image_url || images.defaultImage} />
          <S.Row>
            <S.PlatformsWrapper>
              <Platforms platforms={game.platforms} />
            </S.PlatformsWrapper>
          </S.Row>
          <S.Row>
            <S.OpenBets>
              {getOpenBets(game)} {t(`${translationString}.card.openBets`)}
            </S.OpenBets>
            <GeneralButton
              variant="primary"
              size="small"
              transparent
              onClick={() => onClickInAcceptGame(game)}
              id={`accept-game-${index}`}
            >
              {t(`${translationString}.card.select`)}
            </GeneralButton>
          </S.Row>
        </Card>
      </S.Card>
    );
  };

  const onSubmitBetAmount = (
    { amount, password }: Values,
    formik: FormikHelpers<Values>
  ) => {
    dispatch({
      type: Actions.SET_BET_AMOUNT,
      payload: {
        amount
      }
    });

    closeAndShow(
      <BetCreated
        currentPassword={password}
        onSubmitBetAmount={onSubmitBetAmount}
        onCloseBetAmount={() => closeModal()}
      />,
      '475px',
      true,
      '35px 44px 25px'
    );

    formik.resetForm();
  };

  const selectAChallenger = (friend: Friend): void => {
    toggleCreateABetModal();

    dispatch({
      type: Actions.SET_FRIEND_CHALLENGER,
      payload: {
        friend
      }
    });

    showModal(
      <BetAmount
        type={BetAmountType.BATTLE}
        onClose={() => closeModal()}
        onSubmit={onSubmitBetAmount}
      />,
      '475px',
      true,
      '20px'
    );
  };

  const renderPlayerCard = (
    friend: Friend,
    index: number
  ): React.ReactElement => {
    const playerId = `player-buttom-${index + 1}`;
    return (
      <S.Card id="card" key={v4()} onClick={() => {}} $loading={loading}>
        <Card
          width={window.innerWidth < 486 ? '100%' : '187px'}
          height="min-content"
          padding="16px 12px"
          background="rgba(255, 255, 255, 0.1)"
        >
          <S.Picture src={friend.image_url || images.defaultImage} />
          <S.Name>{friend.apelido}</S.Name>
          <GeneralButton
            variant="primary"
            size="small"
            transparent
            onClick={() => selectAChallenger(friend)}
            id={playerId}
          >
            {t(`${translationString}.teamCard.challenge`)}
          </GeneralButton>
        </Card>
      </S.Card>
    );
  };

  const onChallengeATeam = (
    { name, members, entity_id, image_url }: Team,
    event: MouseEvent
  ): void => {
    if (type === Type.CHALLENGE_TEAM) {
      toggleCreateABetModal();
      dispatch({
        type: Actions.SET_TEAM_CHALLENGER,
        payload: {
          team: {
            name,
            entity_id,
            members,
            image_url
          }
        }
      });

      showModal(
        <BetAmount
          type={BetAmountType.BATTLE}
          onClose={() => closeModal()}
          onSubmit={onSubmitBetAmount}
        />,
        '475px',
        true,
        '20px'
      );
    } else {
      onSelectATeam(event, {
        entity_id,
        name,
        image_url,
        members
      });
    }
  };

  const renderTeamCard = ({
    name,
    members,
    entity_id,
    image_url,
    user,
    created_by
  }: Team): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return (
      <S.Card key={v4()} id="card" $loading={loading}>
        <Card
          width={window.innerWidth < 486 ? '100%' : '187px'}
          height="min-content"
          padding="16px 12px"
          background="rgba(255, 255, 255, 0.1)"
        >
          <S.Picture src={image_url || images.defaultImage} />
          <S.Name type={type}>{name}</S.Name>
          {type === Type.CHALLENGE_TEAM ? (
            <S.UsernameOfLeaderOfTeam>{user?.name}</S.UsernameOfLeaderOfTeam>
          ) : null}
          <S.WrapperActions>
            {!isChallenge && (
              <S.Button
                variant="primary"
                size="small"
                transparent
                isChallenge={!isChallenge}
                onClick={() =>
                  onClickOnCard({
                    entity_id,
                    name,
                    image_url,
                    members,
                    created_by
                  })
                }
              >
                {t(`${translationString}.teamCard.view`)}
              </S.Button>
            )}
            <S.Button
              variant="primary"
              size="small"
              transparent={isChallenge}
              isChallenge={!isChallenge}
              onClick={(event) =>
                onChallengeATeam(
                  { name, members, entity_id, image_url },
                  event as MouseEvent
                )
              }
            >
              {isChallenge
                ? t(`${translationString}.teamCard.challenge`)
                : t(`${translationString}.teamCard.select`)}
            </S.Button>
          </S.WrapperActions>
        </Card>
      </S.Card>
    );
  };

  const getRenderFunction = (
    item: Team | EntityGame | Friend,
    index: number
  ): React.ReactElement => {
    const dataRender: DataType = {
      [Type.TEAM]: () => renderTeamCard(item as Team),
      [Type.GAME]: () => renderCard(item as EntityGame, index),
      [Type.CHALLENGE_TEAM]: () => renderTeamCard(item as Team),
      [Type.CHALLENGE_PLAYER]: () => renderPlayerCard(item as Friend, index)
    };

    return dataRender[type]();
  };

  const getWarningMessage = (): string => {
    if (type === Type.CHALLENGE_PLAYER) {
      return t(`${translationString}.list.challengePlayerWarning`);
    }

    return '';
  };

  return (
    <S.Container id="list">
      {data.response.map(getRenderFunction)}
      {data.response.length <= 0 && (
        <S.Warning>{getWarningMessage()}</S.Warning>
      )}
    </S.Container>
  );
};

export default List;
