import { getImage } from 'assets/img';
import { ButtonLarge } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const AccountContainer = styled.div`
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 30px 0;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  padding: 5px 27px 20px 27px;
  background-repeat: no-repeat;
  background-image: url(${getImage('background_home')});
`;
const ContentContainer = styled.div`
  width: 100%;
  display: grid;
  position: sticky;
  max-width: 1270px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(min-content, 3);
  grid-template-areas: 'logo logo' 'wallet transactions' 'text transactions';
  gap: 16px;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr, 3);
    grid-template-areas: "logo" "wallet" "text" "transactions";
  `}
`;
const ChartInitialMobile = styled.img`
  width: 100%;
  height: auto;
  min-height: 400px;
  display: none;
  ${media.lessThan('medium')`
    display:block;
  `};
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: border-box;
`;
const ChartInitialDesktop = styled.img`
  width: 100%;
  height: auto;
  min-height: 400px;
  ${media.lessThan('medium')`
    display:none;
  `};
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: border-box;
`;
const LogoContainer = styled.div`
  grid-area: logo;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WalletContainer = styled.div`
  grid-area: wallet;
  width: 100%;
  height: 100%;

  ${media.lessThan('medium')`
  overflow-x: auto;
`}
`;
const TextContainer = styled.div`
  grid-area: text;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const TransactionsContainer = styled.div`
  width: 100%;
  height: 735px;
  grid-area: transactions;

  ${media.lessThan('medium')`
    height: auto;
    overflow-y: auto;
    max-height: 735px;
  `}
`;
const Logo = styled.img`
  margin: 40px auto;
`;
const ContainerCards = styled.div`
  gap: 20px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  justify-content: flex-start;
`;
const Container = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;
const AccountInfo = styled.div`
  gap: 15px;
  width: 100%;
  display: flex;
  max-width: 271px;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  ${media.lessThan('medium')`
    background-color: transparent;
  `}
`;
const ContentTexts = styled.div`
  width: 100%;
  display: flex;
  max-height: 221px;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px 10px 10px;
  ${media.lessThan('medium')`
    width: 95%;
    padding: 21px 0px 24px 10px;
  `}
`;
const FloatingAmountContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  :hover {
    opacity: 1;
  }
`;

const Title = styled.h2<{
  size?: string;
  margin?: string;
  showBalance?: boolean;
}>`
  gap: 5px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  font-family: 'Russo One';
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.size}rem;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${(props) =>
    props.showBalance === false &&
    css`
      -webkit-text-security: disc;
    `};

  button {
    width: 22px;
    height: 22px;
    margin: 0 0 0 37px;
    border-radius: 0px !important;
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.white};
    :hover {
      background-color: transparent;
    }
    svg {
      width: 1.3em;
      height: 1.3em;
    }
  }
`;
const Content = styled.div<{ column?: boolean }>`
  gap: 10px;
  width: 100%;
  height: 48px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 0fr 0.98fr;
  ${(props) =>
    props.column &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    `};
`;
const Select = styled.select<{ account?: boolean }>`
  height: 100%;
  border: none;
  cursor: no-drop;
  padding: 0 1.2rem;
  border-radius: 8px;
  appearance: textfield;
  box-sizing: border-box;
  background: transparent;
  -moz-appearance: textfield;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  color: ${(props) => props.theme.palette.text.light};
  width: ${(props) => (props.account ? '273px' : '326px')};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 1}rem;
  &:focus {
    outline: none;
  }
`;
const Option = styled.option`
  display: block;
  padding: 15px;
  background-color: ${(props) => props.theme.palette.background.light};
`;
const Row = styled.div`
  width: 100%;
  display: grid;
  min-width: 1178px;
  padding: 10px 20px;
  align-items: center;
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
`;
const Card = styled.div`
  width: 100%;
  margin: auto;
  height: 126px;
  display: flex;
  padding: 15px 10px;
  align-items: space-evenly;
  flex-direction: column;
  width: calc(99vw - 100px);
  background: rgba(255, 255, 255, 0.1);

  p:nth-child(2) {
    margin: 18px 0 28px 0;
  }
  ${media.greaterThan('medium')`
    display:none;
  `}
`;
const SubTitle = styled(Title)`
  font-family: 'Poppins';
  text-align: center;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 1}rem;
  margin-bottom: 22px;
`;
const Text = styled.p<{ maxWidth?: string; margin?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  justify-content: space-between;
  margin: ${(props) => props.margin};
  /* max-width: ${(props) => props.maxWidth}px; */
  color: ${(props) => props.theme.palette.text.white};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSize - 0.4}rem;

  p {
    width: auto;
  }
`;
const Icon = styled.img`
  /* cursor: pointer;
  cursor: no-drop; */
`;
const Search = styled(ButtonLarge)`
  height: 100%;
  cursor: no-drop;
  max-width: 126px;
  color: #ffb620;
  font-weight: 300;
  border-radius: 12px;
  font-family: 'Roboto';
  letter-spacing: 1.25px;
  border: 1px solid #ffb620;
  background: rgba(255, 255, 255, 0.1);
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.8}rem;
`;
const Button = styled(ButtonLarge)`
  height: 30px;
  max-width: 110px;
  border-radius: 50px;
  font-family: 'Poppins';
  font-weight: 900;
`;
const Img = styled.img`
  width: 164px;
  height: 164px;
`;
const AccountCardsImage = styled.img`
  width: 46px;
  height: 46px;
  margin-bottom: 12px;
`;
const Calendar = styled.div<{ showCalendar?: boolean }>`
  top: 395px;
  z-index: -1;
  left: 435px;
  width: 625px;
  height: 345px;
  position: absolute;
  transition: opacity 0.2s ease-out;
  opacity: ${(props) => (props.showCalendar ? '1' : '0')};
  left: ${(props) => (props.showCalendar ? '435px' : '-100%')};

  .css-1snvurg-MuiPickerStaticWrapper-root {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    color: ${(props) => props.theme.palette.text.light} !important;
    button,
    span {
      color: ${(props) => props.theme.palette.text.light};
    }
    .css-1rhx85y-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(
      // eslint-disable-next-line prettier/prettier
      .Mui-selected
    ) {
      color: ${(props) => props.theme.palette.text.light};
    }
  }
`;
const Message = styled(Text)`
  gap: 5px;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  line-height: 18px;
  text-align: center;
  font-style: normal;
  margin-bottom: 20px;
  font-family: 'Poppins';
  justify-content: center;
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight} - 100;
`;
const Link = styled.a`
  font-size: 16px;
  font-weight: 300;
  color: #48b9fe;
  max-width: 700px;
  line-height: 18px;
  text-align: center;
  font-style: normal;
  font-family: 'Poppins';
`;
export {
  Row,
  Img,
  Text,
  Icon,
  Logo,
  Link,
  Card,
  Title,
  Select,
  Option,
  Search,
  Button,
  Content,
  Message,
  SubTitle,
  Calendar,
  Container,
  AccountInfo,
  ContentTexts,
  LogoContainer,
  FloatingAmountContainer,
  ChartInitialMobile,
  ChartInitialDesktop,
  TextContainer,
  ContainerCards,
  WalletContainer,
  ContentContainer,
  AccountContainer,
  AccountCardsImage,
  TransactionsContainer
};
