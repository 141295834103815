import icons from 'assets/img/icons';
import { Friend } from 'models/Friends';
import React from 'react';
import { t } from 'translate/i18n';
import { Props } from './@types';

import * as S from './SolicitationStyled';

const Solicitations: React.FC<Props> = ({ data, empty = false }) => {
  const translationString = 'components.friends.solicitations';

  const renderItem = ({ apelido }: Friend): React.ReactElement => {
    return (
      <S.Item>
        <S.Name>{apelido}</S.Name>
        <S.BetWrapper>
          <S.BetCoin src={icons.moedaOuro} />
          <S.BetValue>
            {t(`${translationString}.betOnAddNewFriends`)}
          </S.BetValue>
        </S.BetWrapper>
      </S.Item>
    );
  };

  return <S.List empty={empty}>{!empty && data.map(renderItem)}</S.List>;
};

export default Solicitations;
