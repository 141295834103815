import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Trophy = styled.img`
  width: 183px;
  height: 183px;
  object-fit: contain;
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
  width: 300px;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
  width: 260px;
`;
