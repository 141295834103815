import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  display: flex;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
`;
export const ContainerButtons = styled.div`
  gap: 10px;
  display: flex;
  max-width: 278px;
  align-items: center;
  margin: 25px auto 25px;
  justify-content: space-between;
`;
export const Platforms = styled.div`
  gap: 5px;
  top: -5px;
  width: auto;
  display: flex;
  align-items: center;
  position: relative;
  grid-area: PlatformIcon;
  justify-content: flex-end;
`;
export const Title = styled.h2<{ small?: boolean; large?: boolean }>`
  text-align: start;
  font-family: 'Russo One';
  color: ${({ theme }) => theme.palette.title.default};
  text-align: left;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
`;
export const GameTitle = styled.h2<{ small?: boolean; large?: boolean }>`
  text-align: start;
  font-family: 'Russo One';
  color: ${({ theme }) => theme.palette.title.default};
  text-align: left;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
  font-size: ${({ theme }) => theme.typography.fontSize}rem;
`;
export const Subtitle = styled.h2<{ small?: boolean; large?: boolean }>`
  text-align: start;
  font-family: 'Russo One';
  color: ${({ theme }) => theme.palette.title.default};
  text-align: center;
  line-height: 40px;
  margin-top: 25px;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
`;
export const Platform = styled.img`
  width: 10px;
  height: 10px;
  justify-self: flex-end;
`;
export const Coin = styled.img`
  width: 36px;
  height: 34px;
  grid-area: CoinIcon;
  justify-self: right;
  margin-bottom: -22px;
`;
export const Battle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 11px 12px;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 11.8903px;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
`;
export const ContentDescription = styled.div`
  width: 100%;
  gap: 0px 5px;
  display: grid;
  justify-content: left;
  grid-area: ContentDescription;
  grid-template-rows: 0.2fr 0.2fr;
  grid-template-areas:
    'CoinIcon ValueForBet '
    'CoinIcon ValueBet';
`;
export const Img = styled.img<{
  game?: boolean;
}>`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
  border-radius: 9.9086px;
  ${(props) =>
    props.game &&
    css`
      max-height: 164px;
    `};
`;
export const ContainerDescription = styled.div`
  width: 100%;
  gap: 0px 0px;
  display: grid;
  margin-top: 13px;
  grid-template-columns: 165px 1fr;
  grid-template-rows: 25px 0.2fr 0.2fr;
  grid-template-areas:
    'NameGame PlatformText'
    '.      PlatformIcon'
    'ContentDescription ContentDescription';
  h2:nth-child(1) {
    margin-bottom: 30px;
    grid-area: NameGame;
  }
  p:nth-child(1) {
    grid-area: ValueForBet;
  }
  p:nth-child(2) {
    text-align: end;
    grid-area: PlatformText;
  }
  h2:nth-child(2) {
    grid-area: ValueBet;
  }
`;
export const ContainerSmallParagraph = styled.div`
  display: flex;
  max-width: 150px;
  margin: auto;
  margin-bottom: 5px;
`;
export const Text = styled.p<{
  small?: boolean;
  large?: boolean;
  center?: boolean;
  uppercase?: boolean;
}>`
  max-width: 320px;
  line-height: 20px;
  text-align: start;
  font-style: normal;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.title.default};
  font-size: ${({ theme }) => theme.typography.fontSize}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  ${(props) =>
    props.small &&
    css`
      line-height: 12px;
      font-size: ${({ theme }) => theme.typography.htmlFontSize};
    `};
  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
      text-align: center;
    `};
  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${(props) =>
    props.large &&
    css`
      line-height: 24px;
      letter-spacing: 0.15px;
      font-size: ${({ theme }) => theme.typography.fontSizeRegular - 0.1}rem;
    `};
`;
export const Value = styled(Title)`
  font-size: ${({ theme }) => theme.typography.fontSizeRegular - 0.2}rem;
  text-align: left;
`;
