import styled from 'styled-components';

import { GeneralButton } from 'components/elements';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 294px;
  max-height: 354px;
  border-radius: 10px 10px 0px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BannerImage = styled.div`
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 36%,
    rgba(18, 0, 49, 1) 100%
  );
`;

export const BannerTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
`;

export const BannerContainer = styled.div<{ backgroundUrl: string }>`
  width: 100%;
  height: 100%;
  max-height: 207px;
  position: relative;
  background: ${({ backgroundUrl }) => `url(${backgroundUrl})`};
  background-size: 100%;
`;

export const RowCompetitors = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #ffffff;
`;

export const RowCompetitor = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const RowCompetitorName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-size: 12px;
`;

export const RowCompetitorImg = styled.img`
  width: 34px;
  height: 34px;
`;

export const Button = styled(GeneralButton)`
  margin: 37px 0 40px 0;
`;
