/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIcon } from 'assets/img/icons';
import { GeneralButton } from 'components/elements/GeneralButton';
import IconsContainer from 'components/modules/IconsContainer';
import { useModal } from 'components/modules/Modal';
import { Battle } from 'models/Battle';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { servicesApis } from 'service/service.api';
import { BattleContext } from 'state/battle/state';
import { t } from 'translate/i18n';
import { utils } from 'ethers';
import { getErrorStringName } from 'utils/errorHandling';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { BetAmount } from 'components/modules';
import { Type as BetAmountType } from 'components/modules/BetAmount/@types';
import { Props } from './@types';
import * as S from './BetCreatedStyled';

const BetCreated = ({
  currentPassword,
  onCloseBetAmount,
  onSubmitBetAmount,
  betAmountType = BetAmountType.BATTLE
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { setUserActive } = useContentUserActive();
  const translationString = 'components.battle.betCreated';
  const router = useLocation();
  const queryParams = new URLSearchParams(router.search);
  const playAlone = queryParams.get('playAlone') === 'true';

  const navigate = useNavigate();
  const { state } = useContext(BattleContext);

  const { closeAndShow, closeModal } = useModal();

  const handleClose = (errorMessage?: string) => {
    setLoading(false);
    closeAndShow(
      <BetAmount
        type={betAmountType}
        errorMessage={errorMessage}
        onClose={onCloseBetAmount}
        onSubmit={onSubmitBetAmount}
      />,
      '475px',
      true,
      '35px 42px 43px'
    );
  };

  const handleCreate = async () => {
    setLoading(true);
    const battleType = playAlone ? 'alone' : 'team';
    const oponentChallanged = playAlone
      ? state.battle.challenger.friend?.entity_id
      : state.battle.challenger.team?.entity_id;

    const battle: Battle = {
      selected_game: state.battle.game.entity_id,
      selected_platform: state.battle.platform.entity_id,
      amount: utils.parseEther(`${state.battle.amount}`).toString(),
      financial_password: currentPassword,
      selected_team_id: state.battle.teamSelected?.entity_id || '',
      nickname: state.battle.nickname,
      opponent_id: oponentChallanged || '',
      opponent_user_id: oponentChallanged || '',
      opponent_team_id: state.battle.teamSelected?.entity_id || ''
    };

    try {
      await servicesApis.battle.createBattle(battle, battleType);
      navigate('/matchbet/battle');
      setUserActive({
        registrationSuccessful: true,
        message: t(`${translationString}.messages.successfulRegistration`)
      });
      closeModal();
    } catch (error: any) {
      const errorCode = await getErrorStringName(
        error.response.data.error_code
      );

      handleClose(t(`${translationString}.error.${errorCode.toLowerCase()}`));

      setLoading(false);
    }
  };

  return (
    <S.Container>
      <S.Header>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
      </S.Header>
      <S.Battle>
        <S.Img
          game
          src={state.battle.game.image_url}
          alt={t(`${translationString}.gameImage.alt`)}
        />
        <S.ContainerDescription>
          <S.GameTitle small>{state.battle.game.name}</S.GameTitle>
          <S.Text small>{t(`${translationString}.platforms`)}</S.Text>
          <S.Platforms>
            {state.battle.platform ? (
              <IconsContainer platforms={[state.battle.platform]} />
            ) : null}
          </S.Platforms>
          <S.ContentDescription>
            <S.Text small uppercase>
              {t(`${translationString}.betValue`)}
            </S.Text>
            <S.Value>{state.battle.amount}</S.Value>
            <S.Coin
              src={getIcon('moedaOuro')}
              alt={t(`${translationString}.coinIcon.alt`)}
            />
          </S.ContentDescription>
        </S.ContainerDescription>
      </S.Battle>

      <S.ContainerButtons>
        <GeneralButton
          transparent
          variant="primary"
          size="medium"
          onClick={() => handleClose()}
          id="cancel-bet"
        >
          {t(`${translationString}.buttonCancel`)}
        </GeneralButton>
        <GeneralButton
          onClick={() => handleCreate()}
          variant="primary"
          size="medium"
          isLoading={loading}
          id="create-bet"
        >
          {t(`${translationString}.buttonCreate`)}
        </GeneralButton>
      </S.ContainerButtons>
      <S.ContainerSmallParagraph>
        <S.Text center small>
          {t(`${translationString}.description`)}
        </S.Text>
      </S.ContainerSmallParagraph>
    </S.Container>
  );
};

export default BetCreated;
