import React, { useContext, useEffect } from 'react';

import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { servicesApis } from 'service/service.api';
import { BattleContext } from 'state/battle/state';
import { Actions } from 'state/battle/@types/actions';
import { Type } from 'components/modules/Banner/@types';
import { useMobile } from 'hooks/useMobile';
import { useHugeScreen } from 'hooks/useHugeScreen';
import { Matchbet } from 'pages';
import * as S from './BattleStyled';
import { BattleInProgress } from './components/BattleInProgress';
import { Banner } from '../../components/modules/Banner';
import { Slider } from './components/Slider';
import { OpenGlobalBattles } from './components/OpenGlobalBattles';

const Battle: React.FC = () => {
  const [isMobile] = useMobile();
  const [isHugeScreen] = useHugeScreen();
  const { state, dispatch } = useContext(BattleContext);
  const { userActive, setUserActive } = useContentUserActive();

  const getPlatformsIcons = async () => {
    const response = await servicesApis.battle.getPlatforms();
    setUserActive({
      ...userActive,
      platforms: response
    });
  };

  const resetBattleAloneStateOnCreateBattle = (): void => {
    dispatch({
      type: Actions.RESET_BATTLE_ALONE
    });
  };

  useEffect(() => {
    getPlatformsIcons();
    resetBattleAloneStateOnCreateBattle();
  }, []);

  return (
    <Matchbet>
      <S.Container>
        <S.BannerContainer>
          {!isMobile && state.battle.currentBattle.inProgress && (
            <BattleInProgress />
          )}

          <Banner
            type={Type.BATTLE}
            small={!isHugeScreen && state.battle.currentBattle.inProgress}
          />
        </S.BannerContainer>

        <S.ListContainer>
          <OpenGlobalBattles />
        </S.ListContainer>

        {isMobile && state.battle.currentBattle.inProgress && (
          <BattleInProgress />
        )}

        <S.SliderContainer>
          <Slider type="progress" />
        </S.SliderContainer>
      </S.Container>
    </Matchbet>
  );
};
export default Battle;
