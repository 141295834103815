import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: 1fr min-content;
  grid-template-areas: 'header display' 'allNFTS display' 'userNfts userNfts';
  gap: 26px;
  padding: 40px;

  @media (max-width: 1170px) {
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'display' 'allNFTS' 'userNfts';
  }
`;

export const HeaderContainer = styled.section`
  grid-area: header;
`;

export const DisplayContainer = styled.section`
  grid-area: display;
`;

export const UserNftsContainer = styled.section`
  grid-area: userNfts;
`;
