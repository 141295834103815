import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 611px;
  height: 892px;

  @media (max-width: 650px) {
    width: 100%;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h2`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 31px;
  color: ${({ theme }) => theme.palette.title.default};
`;

export const Subtitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.text.white};
  margin-bottom: 20px;
`;

export const Video = styled.video<{ small?: boolean }>`
  margin: 0 auto;
  align-self: center;
  width: 492px;
  height: 486px;
  object-fit: cover;
  border-radius: 30px;
  ${(props) =>
    props.small &&
    css`
      width: 156px;
      height: 154px;
      cursor: pointer;
    `};

  @media (max-width: 650px) {
    width: 100%;
    height: auto;
  }
`;

export const CanvasContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 556px;
  cursor: move;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 181%;
  text-align: justify;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
  margin: 31px 0;
  overflow-y: scroll;
  height: 110px;
  padding-right: 15px; /* Add padding to create space for the scrollbar */

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const DisplayFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 415px) {
    justify-content: center;
  }
`;

export const BuyInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 415px) {
    flex-direction: column;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValueLabel = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Value = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 31px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Disc = styled.div`
  width: 416px;
  height: 416px;
  border-radius: 50%;
  transform: scaleY(0.17) translateX(-50%);
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(255, 255, 255, 0.1) 45%
  );
  position: absolute;
  bottom: -140px;
  left: 50%;
  z-index: -1;

  @media (max-width: 690px) {
    width: 78%;
  }

  @media (max-width: 560px) {
    transform: scaleY(0.1) translateX(-50%);
    bottom: -155px;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 10%,
      rgba(255, 255, 255, 0.1) 35%
    );
  }

  @media (max-width: 420px) {
    transform: scaleY(0.05) translateX(-50%);
    bottom: -175px;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 10%,
      rgba(255, 255, 255, 0.1) 20%
    );
  }

  @media (max-width: 312px) {
    transform: scaleY(0.025) translateX(-50%);
    bottom: -185px;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 10%,
      rgba(255, 255, 255, 0.1) 15%
    );
  }
`;

export const RotateButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const Arrow = styled.img<{ left?: boolean }>`
  width: 23px;
  height: 17px;
  object-fit: contain;

  ${({ left }) =>
    left &&
    css`
      transform: scale(-1);
    `}
`;
