import styled, { css } from 'styled-components';

type Margin = {
  marginTop?: string;
};

export const Icon = styled.img<Margin>`
  width: 30px;
  height: 30px;
  object-fit: contain;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}
`;
