/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

export const usePagination = ({ ROWS_REQUIRED }: { ROWS_REQUIRED: number }) => {
  const [selectedPage, setPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [dataInitial, setDataInitial] = useState<any[]>([]);

  const clickOnLeftArrow = (): void => {
    setData(
      dataInitial.slice(
        (selectedPage - 2) * ROWS_REQUIRED,
        ROWS_REQUIRED * (selectedPage - 1)
      )
    );
    setPage(selectedPage - 1);
  };

  const clickOnRightArrow = (): void => {
    setData(
      dataInitial.slice(
        ROWS_REQUIRED * selectedPage,
        (selectedPage + 1) * ROWS_REQUIRED
      )
    );
    setPage(selectedPage + 1);
  };

  const clickOnPageIndicator = (requestedPage: number): void => {
    setData(
      dataInitial.slice(
        (requestedPage - 1) * ROWS_REQUIRED,
        ROWS_REQUIRED * requestedPage
      )
    );
    setPage(requestedPage);
  };

  const calculatePaginationVars = (
    dataInit: any[],
    callback = () => {}
  ): void => {
    setDataInitial(dataInit);
    const rowsOnScreen = dataInit.length;
    const numbersOfPages = Math.ceil(rowsOnScreen / ROWS_REQUIRED);

    setData(dataInit.slice(0, ROWS_REQUIRED));
    setNumberOfPages(numbersOfPages);
    setPage(1);
    callback();
  };

  const paginateSearch = (filterFunction: any): void => {
    const dataInitFiltered: any[] = dataInitial?.filter(filterFunction);
    const rowsOnScreen: number = dataInitFiltered?.length;
    const numbersOfPages: number = Math.ceil(rowsOnScreen / ROWS_REQUIRED);

    setData(dataInitFiltered.slice(0, ROWS_REQUIRED));
    setNumberOfPages(numbersOfPages);
    setPage(1);
  };

  const resetPage = (): void => {
    setDataInitial(dataInitial);
    const rowsOnScreen = dataInitial.length;
    const numbersOfPages = Math.ceil(rowsOnScreen / ROWS_REQUIRED);

    setData(dataInitial.slice(0, ROWS_REQUIRED));
    setNumberOfPages(numbersOfPages);
    setPage(1);
  };

  return {
    paginateSearch,
    resetPage,
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data
  };
};
