import icons from 'assets/img/icons';
import React from 'react';
import { Props } from './@types';

import * as S from './ProgressStyled';

const Progress: React.FC<Props> = ({ step }) => {
  const renderFirstStep = (): React.ReactElement => {
    return (
      <S.Container>
        <S.Step active>
          <S.Icon src={icons.edit2} />
        </S.Step>
        <S.Bar active left />
        <S.Step>
          <S.Dots>...</S.Dots>
        </S.Step>
        <S.Bar />
        <S.Step>
          <S.Dots>...</S.Dots>
        </S.Step>
      </S.Container>
    );
  };

  const renderSecondStep = (): React.ReactElement => {
    return (
      <S.Container>
        <S.Step>
          <S.Icon src={icons.checkmark} />
        </S.Step>
        <S.Bar active right />
        <S.Step active>
          <S.Icon src={icons.edit2} />
        </S.Step>
        <S.Bar active left />
        <S.Step>
          <S.Dots>...</S.Dots>
        </S.Step>
      </S.Container>
    );
  };

  const renderThirdStep = (): React.ReactElement => {
    return (
      <S.Container>
        <S.Step>
          <S.Icon src={icons.checkmark} />
        </S.Step>
        <S.Bar />
        <S.Step>
          <S.Icon src={icons.checkmark} />
        </S.Step>
        <S.Bar active right />
        <S.Step active>
          <S.Icon src={icons.edit2} />
        </S.Step>
      </S.Container>
    );
  };

  const renderStep = (): React.ReactElement => {
    switch (step) {
      case 'first':
        return renderFirstStep();

      case 'second':
        return renderSecondStep();

      case 'third':
        return renderThirdStep();

      default:
        return renderFirstStep();
    }
  };

  return renderStep();
};

export default Progress;
