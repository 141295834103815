export enum Currencies {
  NFT_CHAMP_BET = 'NFT_CHAMP_BET',
  NFT_SUPER_BET = 'NFT_SUPER_BET',
  NFT_SUPER_CASH_BET = 'NFT_SUPER_CASH_BET',
  NFT_CASH_BET = 'NFT_CASH_BET',
  NFT_MATCH_BET = 'NFT_MATCH_BET',
  NFT_SALES_CASH = 'NFT_SALES_CASH',
  NFT_MB_PARTNER = 'NFT_MB_PARTNER'
}

export type Nft = {
  valid_until: string;
  image_url: string;
  video_url: string;
  name: string;
  description: string;
  entity_id: Currencies;
};

export type NftStoreItem = {
  nft: Nft;
  title: string;
  subtitle: string;
  price: string;
  validity: number;
  has_nft: boolean;
};

export type NftStoreList = {
  nft_store_items: NftStoreItem[];
  user_nfts: Nft[];
};
