import styled from 'styled-components';

export const ContainerTableHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 13px;
  align-items: start;
  justify-content: start;
  border-bottom: 1px solid #7f8594;
`;
