import { ReactElement, useContext } from 'react';

import { AuthContext } from 'state/auth/state';

import {
  ChangePassword,
  CompleteSignUp,
  ConfirmCode,
  Home,
  SignIn,
  SignUp,
  HomePage
} from 'pages';

import { publicRoute } from './RouteTypes';

const publicRoutes = (): ReactElement[] => {
  const { state } = useContext(AuthContext);
  const signed = state.auth.signed as boolean;

  return [
    publicRoute({
      key: 'home',
      path: '/',
      element: HomePage,
      signed
    }),

    publicRoute({
      key: 'sign-in',
      path: '/sign-in',
      element: SignIn,
      signed
    }),

    publicRoute({
      key: 'sign-up',
      path: '/signUp',
      element: SignUp,
      signed
    }),

    publicRoute({
      key: 'home',
      path: '/matchbet/home',
      element: Home,
      signed
    }),

    publicRoute({
      key: 'complete-sign-up',
      path: '/completeSignUp',
      element: CompleteSignUp,
      signed
    }),

    publicRoute({
      key: 'confirm-code',
      path: '/confirmcode',
      element: ConfirmCode,
      signed
    }),

    publicRoute({
      key: 'change-password',
      path: '/matchbet/changepassword',
      element: ChangePassword,
      signed
    })
  ];
};

export default publicRoutes;
