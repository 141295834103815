import { Friend } from 'models/Friends';
import { EntityGame } from 'models/Games';
import { Team } from 'pages/CreateBattle/@types';

export enum Type {
  TEAM = 'TEAM',
  GAME = 'GAME',
  CHALLENGE_TEAM = 'CHALLENGE_TEAM',
  CHALLENGE_PLAYER = 'CHALLENGE_PLAYER'
}

export type Props = {
  data: {
    type: string;
    response: EntityGame[] | Team[] | Friend[];
  };
  type: Type;
  loading?: boolean;
  playAlone?: boolean;
  updatedData?: () => void;
  clearList?: () => void;
};

export type DataType = {
  [Type.TEAM]: () => React.ReactElement;
  [Type.GAME]: () => React.ReactElement;
  [Type.CHALLENGE_TEAM]: () => React.ReactElement;
  [Type.CHALLENGE_PLAYER]: () => React.ReactElement;
};
