import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.text.white};
`;

export const Field = styled.input`
  ${({ theme }) => css`
    font-size: 1.6rem;
    color: ${theme.palette.text.white};
    background-color: transparent;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.005em;
    width: 100%;
    margin-right: 10px;
    border: 0;
    outline: 0;
    text-overflow: ellipsis;

    &::placeholder {
      color: ${theme.palette.text.white};
    }
  `}
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
`;
