import images, { getImage } from 'assets/img';
import { i18n } from 'translate/i18n';
import { Form } from './Components/Form';
import * as S from './SignInStyled';

export const SignIn = () => {
  return (
    <S.SignInContainer>
      <S.BackgroundImage src={images.backgroundSignin} />
      <S.Logo src={getImage('Logo')} alt="logo Matchbet" />
      <Form />
      <S.Text>
        {i18n.t('messages.dontHaveAnAccount')}
        <S.BlueLink to="/signUp"> {i18n.t('buttons.signUpForFree')}</S.BlueLink>
      </S.Text>
    </S.SignInContainer>
  );
};
