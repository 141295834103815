import React from 'react';
import { t } from 'translate/i18n';

import images from 'assets/img';

import * as C from './EmptyStyled';

const Empty: React.FC = () => {
  const translationString = 'components.header.profileMenu.notification.empty';

  return (
    <C.Container>
      <C.Wrapper>
        <C.Image src={images.Notification} />
        <C.Title>{t(`${translationString}.title`)}</C.Title>
        <C.Description>{t(`${translationString}.description`)}</C.Description>
      </C.Wrapper>
    </C.Container>
  );
};

export default Empty;
