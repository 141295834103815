import styled from 'styled-components';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

const LayoutContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  max-width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-end;
`;

export { LayoutContainer, LayoutContent };
