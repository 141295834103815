export const formSchema = {
  date: {
    required: 'A data é obirgatória!'
  },
  time: {
    required: 'O tempo é obrigatório!'
  },
  startOption: {
    required: 'A opção de início é obrigatória!',
    name: {
      required: 'O nome é obrigatório!'
    }
  }
};
