export enum Actions {
  SET_STEP = '@BATTLE/SET_STEP',
  SELECT_TEAM = '@BATTLE/SELECT_TEAM',
  SELECT_GAME = '@BATTLE/SELECT_GAME',
  CREATE_NEW_TEAM = '@BATTLE/CREATE_NEW_TEAM',
  SET_TEAMS = '@BATTLE/SET_TEAMS',
  UPDATE_TEAM = '@BATTLE/UPDATE_TEAM',
  SAVE_PLATFORM_FOR_SELECTED_GAME = '@BATLE/SAVE_PLATFORM_FOR_SELECTED_GAME',
  TOGGLE_CREATE_A_BET_MODAL = '@BATTLE/TOGGLE_CREATE_A_BET_MODAL',
  SET_BET_AMOUNT = '@BATTLE/SET_BET_AMOUNT',
  SET_FRIEND_CHALLENGER = '@BATTLE/SET_FRIEND_CHALLENGER',
  SET_LOADING = '@BATTLE/SET_LOADING',
  SET_MY_BATTLES = '@BATTLE/SET_MY_BATTLES',
  RESET_BATTLE_ALONE = '@BATTLE/RESET_BATTLE_ALONE',
  SET_CURRENT_BATTLE = '@BATTLE/SET_CURRENT_BATTLE',
  RESET_CURRENT_BATTLE = '@BATTLE/RESET_CURRENT_BATTLE',
  SET_TEAM_CHALLENGER = '@BATTLE/SET_TEAM_CHALLENGER'
}
