import { getImage } from 'assets/img';
import styled from 'styled-components';

const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuMobile = styled(ContainerGeneric)`
  width: 100%;
  position: relative;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const Icon = styled.img<{ width?: number; height?: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const ContainerLinks = styled(ContainerGeneric)<{ active?: boolean }>`
  top: 0px;
  width: 100%;
  z-index: -1;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  padding: 150px 20px 41px;
  transition: left 1s ease-out;
  background-repeat: no-repeat;
  left: ${(props) => (props.active ? '0' : '100%')};
  background-image: url(${getImage('background_home')});
`;

export const Logout = styled.button`
  border: none;
  cursor: pointer;
  font-family: 'Mulish';
  background: transparent;
  color: ${(props) => props.theme.palette.text.white};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.2}rem;
  position: absolute;
  bottom: 10vh;
  width: calc(100% - 40px);
  text-align: center;
`;

export const Link = styled.a`
  font-family: 'Mulish';
  color: ${(props) => props.theme.palette.text.white};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.2}rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
