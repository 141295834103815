import { getImage } from 'assets/img';
import * as S from './VerificationPaymentStatusStyled';

export const VerificationPaymentStatus = () => {
  return (
    <S.VerificationPaymentStatusContainer>
      <S.Content>
        <S.Icon src={getImage('Metamask')} alt="Logo metamask" />
        <S.Title>Successful transaction</S.Title>
        <S.Text margin> Transaction Id:</S.Text>
        <S.Text>
          b123b912e1b2ed10nd01b379123v6fx7g 938hj013h8d7g13d13dfv1b9u3
        </S.Text>
      </S.Content>
    </S.VerificationPaymentStatusContainer>
  );
};
