const LANGUAGE_LOCAL_ID = 'matchbet-language';

export const getUserLanguage = (): string => {
  const language: string | null =
    window.localStorage.getItem(LANGUAGE_LOCAL_ID);
  return language || navigator.language;
};

export const getLanguageFlag = (): string => {
  const language = getUserLanguage();

  switch (language) {
    case 'pt-BR':
      return 'flagPt';

    case 'en-US':
      return 'flagUs';

    default:
      return '';
  }
};

export const saveUserLanguage = (language: string): void => {
  window.localStorage.setItem(LANGUAGE_LOCAL_ID, language);
};
