import images, { getImage } from 'assets/img';
import { i18n } from 'translate/i18n';
import { Form } from './Components/Form';
import * as S from './SignUpStyled';

export const SignUp = () => {
  return (
    <S.SignUpContainer>
      <S.BackgroundImage src={images.backgroundSignin} />
      <S.Logo src={getImage('Logo')} alt="logo Matchbet" />
      <Form />
      <S.Text>
        {i18n.t('messages.alreadyHaveAnAccount')}
        <S.BlueLink to="/"> {i18n.t('buttons.signIn')}</S.BlueLink>
      </S.Text>
    </S.SignUpContainer>
  );
};
