export const account = {
  yourWallet: {
    wallet: {
      alt: 'icone de carteira'
    },
    title: 'Sua carteira',
    eye: {
      ariaLabel: 'mostrar ou esconder valores em match coins'
    },
    virtualBalance: 'Saldo virtual da conta',
    virtualBalancePending: 'Saldo em processamento',
    coin: 'MATCH',
    moreCoins: 'Precisa de mais moedas?',
    buyCoins: 'Comprar moedas'
  },
  transfer: {
    title: 'Transferência',
    description: 'Transferir moeda a outros usuários',
    button: 'Transferir'
  },
  withdraw: {
    title: 'Sacar',
    description: 'Sacar para outra carteira',
    button: 'Sacar'
  },
  wallet: {
    title: 'Carteira',
    description: 'Recarregue sua conta MatchCash',
    button: 'Recarregar'
  },
  lineChart: {
    label: 'Saldo em reais'
  }
};
