import { i18n } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const signUpSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, i18n.t('errors.invalidPassword'))
    .required(i18n.t('errors.mandatoryPassword')),
  email: yup
    .string()
    .email(i18n.t('errors.invalidEmail'))
    .required(i18n.t('errors.mandatoryEmail')),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], i18n.t('errors.passwordsDontMatch'))
    .required(i18n.t('errors.mandatoryConfirmPassword'))
});
export default signUpSchema;
