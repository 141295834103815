export const matchBetStore = {
  title: 'Welcome to MatchCash Shop',
  subTitle:
    'Take advantage of MATCHCOINs reduced values, they change as lots are consumed',
  emptyComponent: {
    title: 'No items in the store',
    description: 'The store items will be displayed here'
  },
  itemModal: {
    stockInfo: 'In Stock',
    cartButton: 'Add to cart'
  },
  checkoutInfoModal: {
    name: 'Name',
    lastname: 'Last Name',
    description: 'Enter your name and last name to proceed ',
    continueButton: 'Continue'
  }
};
