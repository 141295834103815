import { Home } from './components';
import * as S from './HomePageStyled';

export const HomePage = () => {
  return (
    <>
      <S.Container>
        <Home />
      </S.Container>
    </>
  );
};
