import styled, { css } from 'styled-components';

export const OutContainer = styled.div<{ open: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    align-items: flex-end;
  }

  ${({ open }) =>
    !open &&
    css`
      display: none;
    `}
`;

export const FloatContainer = styled.div<{
  width: string;
  height: string;
  padding: string;
}>`
  ${({ width, height, padding }) => css`
    width: ${width};
    height: ${height};
    padding: ${padding};
  `}

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(74, 62, 94, 0.95);
  border-radius: 12px;
`;
