import React, { useContext } from 'react';

import { NftStoreListContext } from 'state/nft/state';

import { NftUserView } from 'pages/NftStore/components/NftUserViewProduct/@types';
import { useModal } from 'components/modules/Modal';
import * as S from './NftUserViewProductStyled';

const NftUserViewProduct = ({ name, description }: NftUserView) => {
  const { state } = useContext(NftStoreListContext);
  const { closeModal } = useModal();

  return (
    <S.NftUserViewProductContainer onClick={() => closeModal()}>
      <S.Container>
        <S.Img id="nft-image" src={state.user_nft_selected.image_url} />
        <S.ContainerDescription>
          <S.Title>{name}</S.Title>
          <S.Text margin="20px 0 0">{description}</S.Text>
        </S.ContainerDescription>
      </S.Container>
    </S.NftUserViewProductContainer>
  );
};

export default NftUserViewProduct;
