export const createTournament = {
  title: 'Criar torneio',
  auxiliarTitle: 'Novo torneio',
  description: {
    matchFormat:
      'Escolha o seu formato de partida, jogue sozinho ou em um time com seus amigos',
    selectAGame:
      'Selecione um jogo para continuar, você pode escolher um jogo da plataforma ou adicionar um novo jogo.',
    challenge: 'Selecione seus amigos que participarão do torneio'
  },
  addButton: 'Adicionar',
  newGlobalBet: 'Novo Torneio Global',
  subtitle: 'Desafie um/os amigo(s)',
  search: {
    placeholder: 'Procurar'
  },
  platformFilter: {
    gamesFor: 'Jogos para',
    allGames: 'Todos os jogos',
    victoryValidation: 'Validador de vitória'
  },
  teamCard: {
    select: 'Selecionar',
    view: 'Visualizar',
    challenge: 'Desafiar'
  },
  gameCard: {
    select: 'Selecionar',
    openBets: 'bets abertos'
  },
  platforms: {
    onThePlatform: 'Na(s) plataforma(s)'
  },
  createABetModal: {
    title: 'Valor da aposta',
    amount: 'Quantidade de Bet',
    balance: 'Balanço:',
    password: 'Senha financeira',
    subTitle: 'Definir o valor da apostar no torneio',
    description:
      'No caso de jogos jogados em equipa, o valor é depositado e retirado apenas do utilizador responsável pela equipa.',
    buttons: {
      cancel: 'Voltar',
      create: 'Criar aposta'
    }
  },
  amountBetFilter: {
    title: 'Quantia em Bet',
    lowestPlaceholder: 'Bet mínimo',
    highPlaceholder: 'Bet máximo'
  },
  list: {
    challengePlayerWarning: 'Você não possui amigos para desafiar'
  },
  tournamentSizeModal: {
    title: 'Tamanho do torneio',
    description: 'Escolha a quantidade de participantes',
    typesOfTournamentSize: {
      fourTeams: 'Torneio de quatro times',
      eightTeams: 'Torneio de oito times',
      sixTeenTeams: 'Torneio de dezesseis times',
      thirtyTwoTeams: 'Torneio de trinta e dois times',
      fourPlayers: 'Quatro jogadores',
      eightPlayers: 'Oito jogadores',
      sixTeenPlayers: 'Dezesseis jogadores',
      thirtyTwoPlayers: 'Trinta e dois jogadores'
    },
    primaryButton: 'Avançar',
    secondaryButton: 'Voltar'
  },
  selectTournamentDateModal: {
    title: 'Data do torneio',
    description: 'Selecione uma data para o seu torneio começar',
    buttons: {
      return: 'Voltar',
      next: 'Próximo'
    },
    tournamentStartOptions: {
      onFuture: 'Iniciar no futuro',
      now: 'Iniciar agora'
    },
    error: {
      tournament_no_nft_matchbet:
        'Você não possui a NFT necessária para criar torneios'
    }
  }
};
