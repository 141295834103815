import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const useHugeScreen = (): [
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const [isHugeScreen, setIsHugeScreen] = useState<boolean>(
    window.innerWidth >= 1920
  );

  useEffect(() => {
    const onResize = (): void => {
      setIsHugeScreen(window.innerWidth >= 1920);
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [window]);

  return [isHugeScreen, setIsHugeScreen];
};
