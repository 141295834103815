import styled, { css } from 'styled-components';

export const DropDown = styled.div<{
  active?: boolean;
  minHeight?: string;
  error?: boolean;
  numberOfErrors?: number;
}>`
  width: 100%;
  height: 48px;
  min-height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 6px;
  padding: 0px 19.5px 20px;
  transition: all 0.3s ease-out;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 12px;

  svg {
    transform: rotateZ(90deg);
    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.palette.background.light};
  }
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      background: transparent;
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }

  ${(props) =>
    props.active &&
    css`
      svg {
        transform: rotateZ(270deg);
      }
    `};

  ${(props) =>
    props.active &&
    css`
      ${(() => {
        switch (props.numberOfErrors) {
          case 0:
            return 'height: 340px;';

          case 1:
            return 'height: 369px;';

          case 2:
            return 'height: 403.91px;';

          case 3:
            return 'height: 438.81px;';

          default:
            return '';
        }
      })()}
    `}
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0 30px 0;
  cursor: pointer;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
`;
