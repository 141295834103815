import icons from 'assets/img/icons';
import { Card } from 'components/modules';
import { AmountSlider } from 'components/modules/AmountSlider';
import React, { useState } from 'react';
import { t } from 'translate/i18n';
import { Props } from './@types';

import * as S from './AmountBetFilterStyled';

const AmountBetFilter: React.FC<Props> = ({
  min,
  max,
  disabled = false,
  onChange = () => {}
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const toggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };

  const translationString = 'components.createBattle.amountBetFilter';

  return (
    <S.Container>
      <S.Button onClick={toggleFilter} disabled={disabled}>
        <Card
          width="46px"
          height="46px"
          background="rgba(74, 62, 94, 0.95)"
          padding="unset"
        >
          <S.Icon src={icons.settings} />
        </Card>
      </S.Button>

      <S.Popup openFilter={openFilter}>
        <S.BallonLeg />
        <Card
          width="min-content"
          height="min-content"
          padding="20px"
          background="rgba(74, 62, 94, 0.95)"
        >
          <S.Content>
            <S.Title>{t(`${translationString}.title`)}</S.Title>
            <AmountSlider
              min={min}
              max={max}
              lowestValuePlaceholder={t(
                `${translationString}.lowestPlaceholder`
              )}
              highValuePlaceholder={t(`${translationString}.highPlaceholder`)}
              onChange={onChange}
            />
          </S.Content>
        </Card>
      </S.Popup>
    </S.Container>
  );
};

export default AmountBetFilter;
