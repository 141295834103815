import styled, { css } from 'styled-components';

export const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;
export const BuyNftContainer = styled(ContainerGeneric)``;
export const Img = styled.img`
  width: 100%;
  max-width: 289px;
  max-height: 388px;
  margin-bottom: 30px;
`;
export const Text = styled.p`
  font-style: normal;
  line-height: 24px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
export const ContainerDescription = styled(ContainerGeneric)`
  width: 100%;
`;
export const Title = styled.h2`
  width: 100%;
  display: flex;
  line-height: 31px;
  font-style: normal;
  align-items: center;
  font-family: 'Russo One';
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.4}rem;
`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  .MuiFormControl-root {
    margin: 0;
    width: 100%;

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        border-radius: 0px;
        color: ${(props) => props.theme.palette.text.light};

        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.text.light};
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${(props) => props.theme.palette.text.light};
        }
      }
    }
  }

  .MuiFormHelperText-root {
    margin-bottom: 10px;
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }

  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.palette.text.light};
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }

  .MuiFormLabel-filled,
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.text.light};
  }

  .MuiOutlinedInput-input {
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
  }
`;
export const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  margin: 28px 0 36px;
  justify-content: flex-start;
  legend {
    font-size: 1.2em;
  }
  input {
    font-family: 'Roboto';
    letter-spacing: 0.15px;
    font-size: ${(props) => props.theme.typography.fontSize}rem;
    ::placeholder {
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
  }
  ${(props) =>
    props.error &&
    css`
      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          .MuiOutlinedInput-notchedOutline {
            border-color: ${props.theme.palette.error.main};
          }
        }
        .MuiInputLabel-formControl {
          color: ${props.theme.palette.error.main};
        }
      }
    `};
`;
