/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { GeneralButton } from 'components/elements/GeneralButton';
import { useContext, useState } from 'react';
import { t } from 'translate/i18n';
import { useModal } from 'components/modules/Modal';
import { servicesApis } from 'service/service.api';
import { BattleContext } from 'state/battle/state';
import { Actions } from 'state/battle/@types/actions';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { ModalProps } from '../../@types';
import Card from '../Card';
import * as S from './ExcludeBattleModalStyled';

const ExcludeModalBattle = ({ battle, updatedData = () => {} }: ModalProps) => {
  const [loading, setLoading] = useState(false);
  const { setUserActive } = useContentUserActive();
  const translationString = 'components.battle.excludeBattles';
  const { closeModal } = useModal();

  const { dispatch } = useContext(BattleContext);

  const handleClose = () => {
    closeModal();
  };

  const handleRemove = async (): Promise<void> => {
    setLoading(true);
    try {
      await servicesApis.battle.deleteABattle(battle.entity_id);
      closeModal();
      const response = await servicesApis.battle.getMyBattles();

      dispatch({
        type: Actions.SET_MY_BATTLES,
        payload: {
          myBattles: response.data
        }
      });
      setUserActive({
        registrationSuccessful: true,
        message: t(`${translationString}.messages.success`)
      });
      updatedData();
    } catch (error: any) {
      if (error.message) {
        switch (error.message) {
          default:
            setUserActive({
              registrationSuccessful: true,
              message: t(`${translationString}.messages.failed`)
            });
        }
      }
    }
    setLoading(false);
  };

  return (
    <>
      <S.CardContainer>
        <Card readOnly item={battle} />
      </S.CardContainer>

      <S.Title>{t(`${translationString}.modal.title`)}</S.Title>

      <S.Text>{t(`${translationString}.modal.description`)}</S.Text>

      <S.ContainerButtons>
        <GeneralButton
          transparent
          variant="primary"
          size="medium"
          onClick={() => handleClose()}
        >
          {t(`${translationString}.modal.buttonReturn`)}
        </GeneralButton>
        <GeneralButton
          onClick={handleRemove}
          variant="primary"
          size="medium"
          isLoading={loading}
        >
          {t(`${translationString}.modal.buttonExclude`)}
        </GeneralButton>
      </S.ContainerButtons>
    </>
  );
};

export default ExcludeModalBattle;
