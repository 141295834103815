export const transfer = {
  title: 'Transferir',
  userWalletTransfer: {
    title: 'Email do destinatário',
    placeholder: 'Email',
    description:
      'As transferências só podem ser feitas entre usuários da plataforma matchCash'
  },
  amount: {
    title: 'A quantidade',
    placeholder: 'Quantidade',
    initials: 'MATCH',
    initials2: 'Matchcoin',
    balance: 'Saldo',
    description: 'E-mail: '
  },
  password: {
    placeholder: 'Senha financeira'
  },
  messages: {
    errorOnTransfer: 'Um erro ocorreu. Tente novamente!',
    transferSuccessfully: 'Transferência concluída com sucesso.',
    insufficientWalletBalance: 'Saldo insuficiente na carteira.',
    user_financial_password_invalid: 'Senha financeira não registada.',
    transaction_same_user: 'Usuário não pode transferir para si mesmo.',
    user_to_transfer_does_not_exist: 'Usuário não existe',
    financial_wallets_must_be_differents:
      'Não é possivel transferir para a mesma carteira',
    user_financial_password_not_found: 'Senha financeira não cadastrada',
    unknow_error: 'Erro desconhecido'
  }
};
