import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

import { Actions } from 'state/nft/@types/actions';
import { NftStoreListContext } from 'state/nft/state';
import { Nft } from 'models/NftStoreList';
import { GeneralButton } from 'components/elements/GeneralButton';
import { Card } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { NftUserViewProduct } from 'pages/NftStore/components/NftUserViewProduct';

import images from 'assets/img';
import icons from 'assets/img/icons';

import { t } from 'translate/i18n';
import { Props } from './@types';

import * as S from './NftUserItemStyled';

const NftUserItem: React.FC<Props> = ({
  data,
  title = 'Title',
  desktopRows = 2,
  page = 1,
  setPage = () => {},
  buttonLabel = 'VIEW'
}) => {
  const listRef = useRef() as MutableRefObject<HTMLUListElement>;
  const cardRef = useRef() as MutableRefObject<HTMLLIElement>;

  const router = useLocation();
  const { dispatch } = useContext(NftStoreListContext);
  const { showModal } = useModal();

  const [dataToShow, setDataToShow] = useState<Nft[]>(data);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const translationString = 'components.nft';

  useEffect(() => {
    const calculatePaginationVars = (): void => {
      if (listRef.current && cardRef.current) {
        const GAP = 24;
        const ROWS = window.innerWidth < 669 ? 6 : desktopRows;
        const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
        const cardsOnScreen =
          Math.floor(
            (listRef.current.clientWidth + COMPENSATION) /
              (cardRef.current.clientWidth + GAP)
          ) * ROWS;
        setDataToShow(data.slice(0, cardsOnScreen));
        setNumberOfPages(Math.ceil(data.length / cardsOnScreen));
        setPage(1);
      }
    };

    calculatePaginationVars();

    window.addEventListener('resize', calculatePaginationVars);

    return () => window.removeEventListener('resize', calculatePaginationVars);
  }, [window, router.pathname, data]);

  const goFowardOnList = (): void => {
    if (listRef.current && cardRef.current && page < numberOfPages) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : desktopRows;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      setDataToShow(
        data.slice(cardsOnScreen * page, (page + 1) * cardsOnScreen)
      );
      setPage(page + 1);
    }
  };

  const goBackwardsOnList = (): void => {
    if (listRef.current && cardRef.current && page > 1) {
      const GAP = 24;
      const ROWS = window.innerWidth < 669 ? 6 : desktopRows;
      const COMPENSATION = window.innerWidth < 299 ? 2 * GAP : GAP;
      const cardsOnScreen =
        Math.floor(
          (listRef.current.clientWidth + COMPENSATION) /
            (cardRef.current.clientWidth + GAP)
        ) * ROWS;

      setDataToShow(
        data.slice((page - 2) * cardsOnScreen, cardsOnScreen * (page - 1))
      );
      setPage(page - 1);
    }
  };
  const onClickOverShowsNftUserDetails = (nft: Nft): void => {
    dispatch({
      type: Actions.SELECT_USER_NFT,
      payload: nft
    });
  };
  const openNftModal = (nft: Nft): void => {
    showModal(
      <NftUserViewProduct name={nft.name} description={nft.description} />,
      '1072px',
      true,
      '36px 34px'
    );
  };

  const renderItems = (nft: Nft, index: number): React.ReactElement => {
    return (
      <S.Item
        ref={index === 0 ? cardRef : undefined}
        onClick={() => onClickOverShowsNftUserDetails(nft)}
        key={index}
      >
        <Card
          background="rgba(255, 255, 255, 0.3)"
          height="min-content"
          width="100%"
          padding="16px 12px"
        >
          <S.Content>
            <S.Badget>{t(`${translationString}.listAllNFTs.yourNft`)}</S.Badget>
            <S.Photo src={nft.image_url || images.defaultImage} />
            <S.Name>{nft.name}</S.Name>
            <S.Validity>
              {t(`${translationString}.listUserNFTs.valid_until`)}:{' '}
              {new Date(nft.valid_until).toLocaleDateString()}
            </S.Validity>
            <S.ItemFooter>
              <S.ButtonContainer>
                <GeneralButton
                  variant="primary"
                  size="small"
                  transparent
                  onClick={() => openNftModal(nft)}
                >
                  {buttonLabel}
                </GeneralButton>
              </S.ButtonContainer>
            </S.ItemFooter>
          </S.Content>
        </Card>
      </S.Item>
    );
  };

  return (
    <>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.ButtonsWrapper hide={numberOfPages <= 1}>
          <S.Button>
            <S.Arrow src={icons.arrowRight} left onClick={goBackwardsOnList} />
          </S.Button>

          <S.Button>
            <S.Arrow src={icons.arrowRight} onClick={goFowardOnList} />
          </S.Button>
        </S.ButtonsWrapper>
      </S.Header>
      <S.ListWrapper ref={listRef}>{dataToShow.map(renderItems)}</S.ListWrapper>
    </>
  );
};

export default NftUserItem;
