/* eslint-disable func-names */
// import { getIsTestnet, injected } from "../../../utils/connectors";
import { useMobile } from 'hooks/useMobile';
import { useState } from 'react';
import { Notifications } from '../Notifications';
import { Menu } from './@types';
import AccountButton from './components/AccountButton';
// import ActionButtons from './components/ActionButtons';
import MenuMobile from './components/MenuMobile';
import Wallet from './components/Wallet';
import * as S from './HeaderStyled';
// import { UserProfile } from "../../../pages/UserProfile";

const Header = () => {
  // const [chainId, setChainId] = useState(false);
  // const [showWallet, setShowWallet] = useState(false);
  // const [wallet, setWallet] = useState("");
  const [menuConfig, setMenuConfig] = useState<Menu>({
    active: false,
    menu: '',
    positionMenu: ''
  });
  const [isMobile] = useMobile();

  // const connectedWallet = useCallback(async () => {
  //   const isTestnet = await getIsTestnet();
  //   await setChainId(isTestnet);
  //   await getAccount();
  // }, [setChainId]);

  // const getAccount = async () => {
  //   injected.activate().then(async (value: any) => {
  //     setShowWallet(true);
  //     setWallet(value.account);
  //   });
  // };

  // const openSettingsProfile = () => {
  //   console.log("chegou ::")
  //   setShowProfileSettings(!showProfileSettings);
  //   setMenuConfig({
  //     menu: "",
  //     active: false,
  //     positionMenu: "",
  //   });
  // };

  // useEffect(() => {
  //   connectedWallet();
  //   console.log(wallet);
  //   console.log(chainId);
  //   console.log(showWallet);
  // }, [chainId, connectedWallet, showWallet, wallet]);

  return (
    <S.HeaderContainer>
      {!isMobile && (
        <div>
          <Wallet />
        </div>
      )}

      <S.ContainerCards>
        {/* <Indication /> */}

        {/* <ActionButtons /> */}

        <Notifications />
        <AccountButton setMenuConfig={setMenuConfig} menuConfig={menuConfig} />
      </S.ContainerCards>

      <MenuMobile />
    </S.HeaderContainer>
  );
};

export default Header;
