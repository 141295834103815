import { useEffect, useRef, useState, useCallback, useContext } from 'react';

import images from 'assets/img/icons';

import { servicesApis } from 'service/service.api';
import { Actions } from 'state/battle/@types/actions';
import { BattleContext } from 'state/battle/state';
import { useTournament } from 'state/tournament/hooks';
import { MyBattle } from 'models/Battle';
import { MyTournament } from 'models/Tournaments';
import { CustomCircularProgress } from 'components/modules';
import CardList from './components/CardList';

import { sliderProps } from './@types';

import * as S from './SliderStyled';

const Slider = ({ type }: sliderProps) => {
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [numberHasIncreased, setNumberHasIncreased] = useState<boolean>(false);
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<MyBattle[] | MyTournament[]>([]);

  const { dispatch } = useContext(BattleContext);
  const { setTournament } = useTournament();

  const container = document.querySelector('#slider') as HTMLElement;
  const cards = document.querySelectorAll(
    '#game-card'
  ) as NodeListOf<HTMLElement>;

  const [sliderWidth, setSliderWidth] = useState(0);

  const CARD_WIDTH = 213 + 13;

  const remainingWidth = container
    ? container.scrollWidth - container.offsetWidth
    : 0;
  const cardsRemaining = remainingWidth / CARD_WIDTH;
  const maxPage = Math.ceil(cardsRemaining);

  const getBattles = (): void => {
    setLoading(true);

    if (type === 'finished') {
      servicesApis.battle.getMyBattlesHistory().then((response) => {
        dispatch({
          type: Actions.SET_MY_BATTLES,
          payload: {
            myBattlesHistory: response.data
          }
        });
        setData(response.data);
      });
      setLoading(false);
    } else {
      servicesApis.battle.getMyBattles().then((response) => {
        dispatch({
          type: Actions.SET_MY_BATTLES,
          payload: {
            myBattles: response.data
          }
        });
        setData(response.data);
      });
      setLoading(false);
    }
  };

  const getTournaments = async (): Promise<void> => {
    setLoading(true);

    const response = await servicesApis.tournament.getMyTournaments();

    setData(response.data);
    setTournament({
      myTournaments: response.data
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!shouldUpdateData) return;

    if (type === 'my_tournaments') {
      getTournaments();
    } else {
      getBattles();
    }

    setShouldUpdateData(false);
  }, [shouldUpdateData]);

  const scrollToRight = (): void => {
    if (page <= maxPage) {
      container.scrollLeft = page * CARD_WIDTH;
    }
  };

  const handleClickRight = (): void => {
    if (page !== maxPage) {
      setPage(page + 1);
      setNumberHasIncreased(true);
    }
  };

  const scrollToLeft = (): void => {
    const currentScroll = CARD_WIDTH * page;

    if (container && currentScroll >= 0) {
      container.scrollLeft = remainingWidth - (maxPage - page) * CARD_WIDTH;
    }
  };

  const containerRef = useRef(null);

  useEffect(() => {
    if (numberHasIncreased) {
      scrollToRight();
    }
    if (!numberHasIncreased) {
      scrollToLeft();
    }
  }, [numberHasIncreased, page]);

  const handleClickLeft = (): void => {
    if (page !== 0) {
      setPage(page - 1);
      setNumberHasIncreased(false);
    }
  };

  const sliderMeasuredRef = useCallback((node) => {
    if (node !== null) {
      setSliderWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const validationToShowButtons = (): void => {
    if (cards) {
      setShowButtons(CARD_WIDTH * cards.length > container?.offsetWidth);
    }
  };

  useEffect(() => {
    validationToShowButtons();
    window.addEventListener('resize', validationToShowButtons);

    return () => window.removeEventListener('resize', validationToShowButtons);
  }, [sliderWidth]);

  const updatedData = () => {
    setShouldUpdateData(true);
  };

  if (isLoading) {
    return (
      <S.SliderContainer isLoading={isLoading}>
        <CustomCircularProgress />
      </S.SliderContainer>
    );
  }

  return (
    <S.SliderContainer ref={containerRef}>
      <S.LeftArrow
        src={images.leftArrow}
        onClick={handleClickLeft}
        $visibility={showButtons}
      />
      <S.Slider id="slider" ref={sliderMeasuredRef}>
        <CardList type={type} data={data} updatedData={updatedData} />
      </S.Slider>
      <S.RightArrow
        onClick={handleClickRight}
        $visibility={showButtons}
        src={images.rightArrow}
      />
    </S.SliderContainer>
  );
};
export default Slider;
