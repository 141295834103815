import icons from 'assets/img/icons';
import { Card } from 'components/modules';
import React, { useContext } from 'react';
import { UserContext } from 'state/user/state';

import * as S from './IndicationBoxStyled';

const IndicationBox: React.FC = () => {
  const [copied, setCopied] = React.useState(false);
  const { state } = useContext(UserContext);

  function copyLink(): void {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);

    const str = `https://matchbet.io/signUp?indication=${state.user.linkRef}`;
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <Card
      background="rgba(255, 255, 255, 0.1)"
      width="100%"
      height="auto"
      padding="11px 12px"
    >
      <S.Container>
        <S.Link id="link">
          https://matchbet.io/signUp?indication={state.user.linkRef}
        </S.Link>
        <S.FloatingMessage $isActive={copied}>
          Copied to clipboard
        </S.FloatingMessage>
        <S.Icon src={icons.copy} onClick={() => copyLink()} />
      </S.Container>
    </Card>
  );
};

export default IndicationBox;
