import { getImage } from 'assets/img';
import Card from 'components/modules/Card/Card';
import { Matchbet } from '..';
import * as S from './HomeStyled';

export const Home = () => {
  return (
    <Matchbet>
      <S.HomeContainer>
        <S.Content>
          <Card
            width="400px"
            height="420px"
            padding="22px 23px"
            background="rgba(255, 255, 255, 0.1);"
          >
            <></>
          </Card>
          <Card
            width="400px"
            height="420px"
            padding="22px 23px"
            background="rgba(255, 255, 255, 0.1);"
          >
            <></>
          </Card>
          <Card
            width="824"
            height="366px"
            padding="22px 23px"
            background="rgba(255, 255, 255, 0.1);"
          >
            <></>
          </Card>
        </S.Content>
        <Card
          width="400px"
          height="817px"
          padding="23px 16px"
          background="rgba(255, 255, 255, 0.1);"
        >
          <S.Title>open battle bets</S.Title>
          <S.Text>1:1 battles</S.Text>
          <S.ContainerCards>
            <Card
              width="366px"
              height="108px"
              padding="11px 13px"
              background="rgba(255, 255, 255, 0.1);"
            >
              <S.Container>
                <S.Img src={getImage('Fortnite')} alt="Imagem da nft" />
                <S.Container>
                  <S.Container>
                    <S.Title>Mortal kombat</S.Title>
                    <S.Text>On the platform</S.Text>
                    <S.Icon src="" alt="" />
                  </S.Container>
                  <S.Container>
                    <S.Text>value for the bet</S.Text>
                    <S.Title>3.122</S.Title>
                    <S.Icon src="" alt="" />
                  </S.Container>
                </S.Container>
              </S.Container>
            </Card>
          </S.ContainerCards>
        </Card>
      </S.HomeContainer>
    </Matchbet>
  );
};
