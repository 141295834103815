import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  gap: 8px;

  & + & {
    margin-top: 10px;
  }
`;

export const PlayerImage = styled.img`
  width: 86px;
  height: 86px;
  object-fit: contain;
  border-radius: 10px;
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`;

export const PlayerNickname = styled.p`
  width: 100%;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CoinValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const CoinIcon = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ValueLabel = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.99086rem;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Value = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.08081rem;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.white};
`;
