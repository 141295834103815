/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { BattleProviderType } from './@types';
import battleReducer from './reducer';

const initialState: any = {
  battle: {
    teamSelected: {
      name: '',
      members: [],
      entity_id: '',
      image_url: ''
    },
    game: {
      created_by: null,
      status: '',
      entity_id: '',
      platforms: [],
      name: '',
      image_url: ''
    },
    nickname: '',
    platform: null,
    challenger: {
      friend: null,
      team: null
    },
    amount: '0',
    createABetModal: {
      open: false
    },
    myBattles: [],
    myBattlesHistory: [],
    step: 1,
    teams: [],
    loading: true,
    currentBattle: {
      entity_id: '',
      inProgress: false,
      selectedGame: {
        image_url: ''
      },
      playerOne: {
        name: '',
        image_url: ''
      },
      playerTwo: {
        name: '',
        image_url: ''
      }
    }
  }
};

export const BattleContext = createContext(initialState);

const BattleProvider: React.FC<BattleProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(battleReducer, initialState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <BattleContext.Provider value={provider}>{children}</BattleContext.Provider>
  );
};

export default BattleProvider;
