import defaultImage from './default-game-image.svg';
import avatar1 from './avatar1.png';
import avatar2 from './avatar2.png';
import avatar3 from './avatar3.png';
import background from './back.png';
import backgroundSignin from './background-signin.png';
import background_home from './background_home.png';
import battle_cards from './battle_cards.png';
import cyberpink from './cyberpink.png';
import EmojiIcons from './emoji-icons.svg';
import fifa22 from './fifa22.png';
import fifa22_BG from './fifa22_BG.png';
import Fortnite from './Fortnite.png';
import GoldCoin from './gold-coin.svg';
import grafico_desktop from './grafico-desktop.png';
import grafico_mobile from './grafico-mobile.png';
import impact from './impact.png';
import Load from './loading.gif';
import Logo from './Logo.png';
import Mask from './Mask.png';
import Metamask from './metamask.png';
import Mov from './mov.mp4';
import nft from './nft.png';
import NFT from './NFT.svg';
import nft_view_product from './nft_view_product.svg';
import nft_view_product_box from './nft_view_product_box.svg';
import Nintendo from './Nintendo.svg';
import Notification from './notification.png';
import playerOne from './player-1.png';
import playerTwo from './player-2.png';
import Playstation from './Playstation.svg';
import QRCode from './qr-code.png';
import Steam from './Steam.svg';
import swordIcon from './swordIcon.png';
import team1 from './team1.png';
import team2 from './team2.png';
import teamPossion from './team_possion.svg';
import teamSamurai from './team_samurai.svg';
import teamSilver from './team_silver.svg';
import teamSunGamer from './team_sun_gamer.svg';
import teamWolves from './team_wolves.svg';
import teamZeus from './team_zeus.svg';
import Transfer from './transfer.svg';
import Trophy from './trophy.svg';
import versus from './versus.svg';
import Wallet from './wallet.svg';
import Withdraw from './withdraw.svg';
import Xbox from './Xbox.svg';
import fortnite2 from './fortnite-2.png';
import bannerGame from './banner_game.svg';
import bannerRound from './bannerRound.png';
import valorant_img from './valorant_img.png';
import valorant_thamb from './valorant_thamb.webp';
import fourTeamTournament from './four_team_tournament.png';
import eightTeamTournament from './eight_team_tournament.png';
import sixteenTeamTournament from './sixteen_team_tournament.png';
import thirtytwoTeamTournament from './thirty_two_team_tournament.png';
import createTournamentBanner from './create-tournament-banner.png';

interface IImage<TValue> {
  [id: string]: TValue;
}

const images: IImage<string> = {
  Mov,
  nft,
  Logo,
  Load,
  Mask,
  team2,
  team1,
  QRCode,
  Trophy,
  impact,
  versus,
  Nintendo,
  NFT,
  Xbox,
  Steam,
  GoldCoin,
  swordIcon,
  Playstation,
  valorant_img,
  valorant_thamb,
  grafico_desktop,
  grafico_mobile,
  background,
  backgroundSignin,
  Fortnite,
  fortnite2,
  cyberpink,
  fifa22,
  EmojiIcons,
  Wallet,
  avatar1,
  avatar2,
  avatar3,
  Withdraw,
  Transfer,
  Metamask,
  teamZeus,
  playerOne,
  playerTwo,
  fifa22_BG,
  teamSilver,
  teamWolves,
  bannerGame,
  bannerRound,
  teamPossion,
  teamSamurai,
  teamSunGamer,
  battle_cards,
  Notification,
  background_home,
  nft_view_product,
  nft_view_product_box,
  defaultImage,
  fourTeamTournament,
  eightTeamTournament,
  sixteenTeamTournament,
  thirtytwoTeamTournament,
  createTournamentBanner
};

export const getImage = (id: string) => images[id];

export default images;
