/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import { Box, IconButton, TextField } from '@mui/material';
import Icons from 'assets/img/icons';
import { API } from 'aws-amplify';
import { GeneralButton } from 'components/elements/GeneralButton';
import { CustomCircularProgress } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import config from 'config/constants';
import { ENDPOINTS } from 'config/endpoints';
import { useFormik } from 'formik';
import { PlatformsType } from 'pages/SignIn/Components/Form/@types';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import { saveImageOnBucket } from 'service/s3.service';
import { servicesApis } from 'service/service.api';
import { t } from 'translate/i18n';
import { NewGameType, Props } from './@types';
import newGameSchema from './NewGameSchema';
import * as S from './NewGameStyled';

const NewGame: React.FC<Props> = ({
  game = undefined,
  updateState = () => {}
}) => {
  const [src, setSrc] = useState('');
  const [platformsSelected, setPlatformsSelected] = useState<string[]>([]);
  const { userActive } = useContentUserActive();
  const translationString = 'components.battle.newGame';
  const [imageGame, setImageGame] = useState<File | undefined>();
  const [load, setLoad] = useState(false);
  const { closeModal } = useModal();
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClose = () => {
    // eslint-disable-next-line no-use-before-define
    formik.resetForm();
    setSrc('');
    setPlatformsSelected([]);
    closeModal();
  };

  const getUploadURL = async () => {
    const response = await servicesApis.user.getUploadURL(
      ENDPOINTS.games.getUploadUrl
    );
    return response;
  };

  const postGame = async (data: NewGameType) => {
    try {
      const userData = {
        name: data.nameGame,
        image_url: '',
        platforms: data.platforms
      };

      if (imageGame) {
        const { fields, url } = await getUploadURL();
        await saveImageOnBucket(url, fields, imageGame);
        userData.image_url = `${url}/${fields?.key}`;
      }

      await API.post(config.APIS.MATCHBET, ENDPOINTS.user.postGame, {
        body: {
          ...userData
        }
      });
      updateState();
    } catch (error) {
      console.error(error);
    }
  };

  const putGame = async () => {};

  const formik = useFormik<NewGameType>({
    initialValues: {
      nameGame: '',
      image_url: src,
      platforms: platformsSelected
    },
    onSubmit: (data) => {
      setLoad(false);
      // eslint-disable-next-line no-unused-expressions
      game ? putGame() : postGame(data);
      handleClose();
    },
    validationSchema: newGameSchema
  });

  useEffect(() => {
    if (game) {
      formik.setFieldValue('nameGame', game.name);
      formik.setFieldValue('image_url', game.image_url);
    }
    // eslint-disable-next-line no-use-before-define
    formik.setFieldValue('platforms', platformsSelected);
  }, [game, platformsSelected]);

  const selectAnAvatar = (): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const eraseInput = (input: HTMLInputElement): void => {
    // eslint-disable-next-line no-param-reassign
    input.value = '';
  };

  const getAvatar = (event: ChangeEvent<HTMLInputElement>): void => {
    const images = event?.target?.files;
    const image = (images || [])[0];
    const url: string = image ? URL.createObjectURL(image) : '';

    setSrc(url);
    setImageGame(image);
    eraseInput(event?.target);
    formik.setFieldValue('image_url', url);
  };

  const onClickOverPlatform = (index: string) => {
    if (platformsSelected.includes(index)) {
      setPlatformsSelected(platformsSelected.filter((item) => item !== index));
    } else {
      setPlatformsSelected([...platformsSelected, index]);
    }
  };

  const renderButton = ({
    entity_id,
    image_url
  }: PlatformsType): React.ReactElement => {
    if (platformsSelected.includes(entity_id)) {
      return (
        <S.Item>
          <S.Button onClick={() => onClickOverPlatform(entity_id)} active>
            <S.Icon src={image_url} active />
          </S.Button>
        </S.Item>
      );
    }
    return (
      <S.Item>
        <S.Button onClick={() => onClickOverPlatform(entity_id)}>
          <S.Icon src={image_url} />
        </S.Button>
      </S.Item>
    );
  };
  return (
    <S.Container>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.ContainerInput
          error={formik.touched.nameGame && Boolean(formik.errors.nameGame)}
        >
          <S.ContainerGameName>
            <TextField
              type="text"
              id="nameGame"
              label={t(`${translationString}.labelTeam`)}
              variant="outlined"
              autoComplete="off"
              sx={{
                '& legend': {
                  fontSize: '1.2rem'
                }
              }}
              value={formik.values.nameGame}
              onChange={formik.handleChange}
              error={formik.touched.nameGame && Boolean(formik.errors.nameGame)}
              helperText={formik.touched.nameGame && formik.errors.nameGame}
            />
            <IconButton
              aria-label="toggle Confirm Password visibility"
              edge="end"
            >
              <S.Img
                src={Icons.game}
                alt={t(`${translationString}.gameIcon.alt`)}
              />
            </IconButton>
          </S.ContainerGameName>
        </S.ContainerInput>
        <S.PhotoContainer onClick={() => selectAnAvatar()}>
          {src !== '' ? (
            <S.Avatar src={src} alt={t(`${translationString}.avatar.alt`)} />
          ) : (
            <S.ContentNoPhone>
              <S.Img
                src={Icons.galleryAdd}
                alt={t(`${translationString}.gameImage.alt`)}
              />
              <S.Text>{t(`${translationString}.text`)}</S.Text>
            </S.ContentNoPhone>
          )}
          <S.HiddenInput
            id="image_url_input"
            type="file"
            ref={inputRef}
            accept="image/*"
            onChange={getAvatar}
          />
        </S.PhotoContainer>
      </S.Form>

      <S.Label>{t(`${translationString}.label`)}</S.Label>
      <S.ButtonsList>{userActive?.platforms?.map(renderButton)}</S.ButtonsList>

      <S.Text>{t(`${translationString}.description`)}</S.Text>

      <S.ContainerButtons>
        <GeneralButton
          onClick={() => handleClose()}
          transparent
          variant="primary"
          size="medium"
          id="button-cancel"
        >
          {t(`${translationString}.buttonCancel`)}
        </GeneralButton>

        <GeneralButton
          type="submit"
          size="medium"
          disabled={!formik.isValid}
          variant="primary"
          onClick={() => formik.handleSubmit()}
          id="button-create"
        >
          {load ? (
            <Box>
              <CustomCircularProgress />
            </Box>
          ) : (
            <Box>
              {game
                ? t(`${translationString}.updateButton`)
                : t(`${translationString}.buttonCreate`)}
            </Box>
          )}
        </GeneralButton>
      </S.ContainerButtons>
    </S.Container>
  );
};

export default NewGame;
