/* eslint-disable @typescript-eslint/no-explicit-any */
import { resetMenu, setMenuSelected } from 'service/sessionStorage';
import { Actions } from './@types/actions';

const battleReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.SET_MENU:
      setMenuSelected(action.payload.selected);
      return {
        ...state,
        menu: {
          ...state.menu,
          selected: action.payload.selected
        }
      };

    case Actions.RESET_MENU:
      resetMenu();
      return {
        ...state,
        menu: {
          ...state.menu,
          selected: 'account'
        }
      };

    default:
      return {};
  }
};

export default battleReducer;
