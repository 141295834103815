export const userProfileSchema = {
  nickname: {
    required: 'Apelido inválido!',
    message: 'O nome deve ter pelo menos 3 letras'
  },
  birthday: {
    required: 'A data de nascimento é obrigatória!',
    max: 'A data de aniversário deve ser anterior a hoje'
  },
  phone: {
    invalid: 'O número é inválido'
  }
};

export const userProfilePhoneNumberSchema = {
  currentNumber: {
    required: 'Telefone é obrigatório!',
    message: 'Número inválido, deveria ter 13 dígitos',
    sameNumber: 'O número atual está incorreto'
  },
  newNumber: {
    required: 'Telefone é obrigatório!',
    message: 'Número inválido, deveria ter 13 dígitos'
  }
};

export const financePasswordSchema = {
  currentPassword: {
    required: 'O campo de senha atual é obrigatória!',
    message: 'A senha atual é inválida, deveria ter 8 dígitos'
  },
  newPassword: {
    required: 'A nova senha é obrigatória!',
    message: 'A nova senha é inválida, deveria ter 8 dígitos'
  },
  confirmPassword: {
    required: 'O campo de confirmação de senha é obrigatória!',
    mustMatch: 'As senhas não coincidem'
  },
  submit: {
    success: 'Senha atualizada com sucesso!',
    wrongPassword: 'Senha está errada',
    callsExceeded:
      'Limite de solicitações foi execido. Aguarde um tempo e tente novamente'
  }
};

export const platformPasswordSchema = {
  currentPassword: {
    required: 'O campo de senha atual é obrigatória!',
    message: 'A senha atual é inválida, deveria ter 8 dígitos'
  },
  newPassword: {
    required: 'A nova senha é obrigatória!',
    message: 'A nova senha é inválida, deveria ter 8 dígitos'
  },
  confirmPassword: {
    required: 'Confirmar senha é obrigatório',
    message: 'A senhas devem ser iguais',
    wrong: 'A senha atual está errada'
  },
  submit: {
    success: 'Senha atualizada com sucesso!',
    wrongPassword: 'Senha está errada',
    callsExceeded:
      'Limite de solicitações foi execido. Aguarde um tempo e tente novamente'
  }
};
