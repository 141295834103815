import { t } from 'translate/i18n';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { betinToMatch, getDateFormatted } from 'utils/formatters';
import images, { getImage } from 'assets/img';
import { GeneralButton } from 'components/elements/GeneralButton';
import { useModal } from 'components/modules/Modal';
import IconsContainer from 'components/modules/IconsContainer';
import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';
import { MyBattle } from 'models/Battle';
import { CountDown, Registered, EventModal } from 'components/modules';
import {
  useTournament,
  ContentDeleteTournament
} from 'components/modules/EventModal/Tournament';

import { UserContext } from 'state/user/state';
import ExcludeBattleModal from '../ExcludeBattleModal';
import { CardProps } from './@types';
import * as S from './CardStyled';

const Card = ({ item, readOnly, type, updatedData }: CardProps) => {
  const { showModal } = useModal();
  const { onSubmitEventModal } = useTournament({ updatedData });
  const {
    state: { user }
  } = useContext(UserContext);
  const navigate = useNavigate();

  const translationString = 'components.battle.yourBattles';
  const amount = `${item.amount || item.entry_fee}`;
  const platformSelected = item.selected_platform || item.platform;
  const gameSelected = item.selected_game || item?.game;
  const registered = item?.registered || item?.tournament_players?.length;
  const startDate = getDateFormatted(item.start_date as string);

  const actionDeleteTournament = () => {
    showModal(
      <EventModal
        data={{
          amount,
          image_url: gameSelected?.image_url,
          title: gameSelected?.name || '',
          translationString: 'components.tournament.deleteTournament',
          platform: platformSelected,
          start_date: startDate,
          max_players: item.max_players,
          registered,
          entity_id: item.entity_id
        }}
        Children={ContentDeleteTournament}
        onSubmit={onSubmitEventModal}
      />,
      'min-content',
      true,
      '42px'
    );
  };

  const actionDeleteBattle = () => {
    showModal(
      <ExcludeBattleModal
        battle={item as MyBattle}
        updatedData={updatedData}
      />,
      '475px',
      true,
      '35px 44px 18px'
    );
  };

  const isNow =
    !!startDate && new Date(startDate).getTime() <= new Date().getTime();

  const isCardOfTournament = type === 'my_tournaments';
  const isYourTournament = item.created_by === user.entityId;
  const isAvailableDeleteTournament = isCardOfTournament && isYourTournament;
  const isAvailableViewTournament = isCardOfTournament && isNow;
  const isAvailableViewTagTournament = isCardOfTournament && isYourTournament;

  const isAvailableDeleteBattle = !isCardOfTournament;

  const redirectToTournament = () => {
    navigate('/matchbet/tournament', {
      state: { entity_id: item.entity_id, isAdmin: isYourTournament }
    });
  };

  const getCountDownDescription = () => {
    const tournamentStatus = item.status;
    if (tournamentStatus === 'FINISHED') {
      return (
        <S.TournamentStatus>
          {t(`${translationString}.card.finishedTournament`)}
        </S.TournamentStatus>
      );
    }
    if (tournamentStatus === 'STARTED') {
      return (
        <S.TournamentStatus>
          {t(`${translationString}.card.onGoingTournament`)}
        </S.TournamentStatus>
      );
    }
    return (
      <CountDown isNow={isAvailableViewTournament} startDate={startDate} />
    );
  };

  return (
    <S.CardContainer
      id="game-card"
      tournament={isCardOfTournament}
      readOnly={readOnly}
    >
      <S.ImageContainer>
        <S.Image
          readOnly={readOnly}
          src={gameSelected?.image_url || images.defaultImage}
        />
      </S.ImageContainer>
      <S.Tag readOnly={readOnly}>
        {isAvailableViewTagTournament
          ? t(`${translationString}.card.tagTournament`)
          : t(`${translationString}.card.tag`)}
      </S.Tag>
      <S.InfoContainer>
        <S.Title tournament={isCardOfTournament}>
          {item?.selected_game?.name}
        </S.Title>
        <S.PlatformInfo tournament={isCardOfTournament}>
          {isCardOfTournament && (
            <S.PlatformInfoTitle>
              {t(`${translationString}.card.platform`)}
            </S.PlatformInfoTitle>
          )}
          <IconsContainer
            readOnly={readOnly}
            platforms={platformSelected?.entity_id ? [platformSelected] : []}
          />
        </S.PlatformInfo>
        <S.BetBox>
          <S.BetValueTitle readOnly={readOnly}>
            {t(`${translationString}.card.betValue`)}
          </S.BetValueTitle>
          <S.BetValue readOnly={readOnly}>
            <ExtendedNumberBox>{betinToMatch(amount)}</ExtendedNumberBox>
          </S.BetValue>
        </S.BetBox>
        <S.GoldCoin readOnly={readOnly} src={getImage('GoldCoin')} />
      </S.InfoContainer>
      {isCardOfTournament && (
        <S.ContainerDescription>
          {getCountDownDescription()}
          <Registered
            text="games"
            max={item?.max_players || 0}
            registered={registered || 0}
          />
        </S.ContainerDescription>
      )}
      <S.ButtonContainer readOnly={readOnly}>
        {isAvailableDeleteTournament && (
          <GeneralButton
            transparent
            variant="primary"
            size="small"
            onClick={actionDeleteTournament}
          >
            {t(`${translationString}.card.exclude`)}
          </GeneralButton>
        )}
        {isAvailableDeleteBattle && (
          <GeneralButton
            transparent
            variant="primary"
            size="small"
            onClick={actionDeleteBattle}
          >
            {t(`${translationString}.card.exclude`)}
          </GeneralButton>
        )}
        {isAvailableViewTournament && (
          <GeneralButton
            variant="primary"
            size="small"
            onClick={redirectToTournament}
          >
            {t(`${translationString}.card.view`)}
          </GeneralButton>
        )}
      </S.ButtonContainer>
    </S.CardContainer>
  );
};

export default Card;
