import styled, { css } from 'styled-components';

import { SelectGamer, SelectPlatform } from './components';
import { StyleStatusProps } from './@types';

export const Container = styled.div`
  gap: 10px;
  z-index: 1;
  width: 100%;
  display: grid;
  padding: 24px;
  align-items: start;
  min-height: 820px;
  grid-template-rows: 185px 550px;
  grid-template-columns: 295px 55px 2fr;
  grid-template-areas: 'container_text select_platform select_gamer' 'container_history container_ranking container_ranking';

  @media (max-width: 768px) {
    grid-template-rows: auto; /* Adjust the rows as needed for responsiveness */
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-areas:
      'container_text'
      'select_platform'
      'select_gamer'
      'container_ranking'
      'container_history';
  }
`;
export const ContainerText = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-area: container_text;
  h2,
  p {
    max-width: 300px;
  }
`;
export const SubContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ContainerRanking = styled.div`
  gap: 10px;
  width: 100%;
  height: 550px;
  display: flex;
  overflow: hidden;
  border-radius: 12px;
  flex-direction: column;
  padding: 24px 23px 12px;
  backdrop-filter: blur(75px);
  grid-area: container_ranking;
  background: rgba(255, 255, 255, 0.1);
`;
export const ContainerHistory = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  margin: 0 auto;
  align-self: end;
  padding: 0 12px;
  position: sticky;
  border-radius: 20px;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(75px);
  grid-area: container_history;
  background: rgba(255, 255, 255, 0.1);

  h2 {
    max-width: 280px;
    font-weight: 400;
    line-height: 100%;
    font-size: 3.8rem;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Russo One';
  }

  button {
    font-size: 18px;
    font-weight: 900;
    margin-top: 40px;
    line-height: 120%;
    border-radius: 50px;
    font-family: 'Poppins';
    border: 2px solid #ffffff;
    background-color: transparent;
    filter: drop-shadow(0px 0px 16.9957px rgba(0, 0, 0, 0.25));
    :hover {
      color: #ffffff;
    }
  }
`;

export const Img = styled.img`
  top: -90px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: relative;
  margin-bottom: -70px;
  border-radius: 74.5px;
  border: 1px solid #ffffff;
`;

export const Title = styled.h2`
  margin-top: 16px;
  font-weight: 400;
  line-height: 100%;
  font-size: 4.8rem;
  font-style: normal;
  letter-spacing: -0.02em;
  font-family: 'Russo One';
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.title.default};

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 43px;
    margin-top: 20px;
    padding-left: 7px;
  }
`;
export const AuxiliarTitle = styled.p`
  top: 9px;
  left: 40px;
  z-index: 0;
  width: 200px;
  font-weight: 400;
  font-style: normal;
  line-height: 225px;
  color: transparent;
  position: absolute;
  font-size: 18.6711rem;
  font-family: 'Russo One';
  text-transform: uppercase;
  -webkit-text-stroke: 1.24474px rgba(255, 255, 255, 0.1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 0;

  @media (max-width: 768px) {
    font-size: 7.99566rem;
    line-height: 96px;
    top: 40px;
    left: 30px;
  }
`;
export const Description = styled.p`
  margin-top: 6px;
  font-weight: 500;
  max-width: 370px;
  line-height: 130%;
  font-size: 1.8rem;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 768px) {
    margin-top: 10px;
    line-height: 20px;
    padding-left: 7px;
  }
`;
export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;

  @media (max-width: 968px) {
    justify-content: center;
  }
`;
export const Text = styled.p`
  font-weight: 500;
  color: #ffffff;
  max-width: 180px;
  font-size: 1.6rem;
  line-height: 115%;
  font-style: normal;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Poppins';
`;
export const Info = styled(Text)`
  width: 100%;
  padding: 10px;
  display: flex;
  max-width: 100%;
  margin-bottom: 0;
  margin-top: 12px;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 130%;
  text-align: start;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const SelectGamers = styled(SelectGamer)`
  grid-area: select_gamer;
  width: 100%;
`;

export const SelectPlatforms = styled(SelectPlatform)`
  grid-area: select_platform;
  width: 100%;
  max-width: 55px;
`;

export const ContainerPosition = styled.div<StyleStatusProps>`
  min-width: 56px;
  width: ${({ size }) => size};
`;

export const Position = styled.h2`
  gap: 13px;
  width: 34px;
  height: 34px;
  font-weight: 400;
  font-size: 1.3rem;
  border-radius: 6px;
  font-family: 'Roboto';
  background: #ffb620;
  padding: 9.15385px 13.0769px;
  color: ${({ theme }) => theme.palette.title.default};
`;

export const ContentInfo = styled.div<StyleStatusProps>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '10px'};
  width: ${({ size }) => size || '40%'};

  div {
    gap: 10px;
    display: flex;
    align-items: center;
  }
`;

export const ImgUser = styled.img`
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 6px;
`;

export const Name = styled.p`
  margin-top: 6px;
  font-weight: 500;
  max-width: 370px;
  line-height: 130%;
  font-size: 1.2rem;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 768px) {
    margin-top: 10px;
    line-height: 20px;
    padding-left: 7px;
  }
`;

export const TextRow = styled(Name)<StyleStatusProps>`
  text-align: start;
  width: ${({ size }) => size || '17%'};
`;

export const TextHeader = styled.p<StyleStatusProps>`
  display: flex;
  min-width: 56px;
  margin-top: -6px;
  min-height: 28px;
  font-weight: 600;
  line-height: 130%;
  font-size: 1.2rem;
  text-align: start;
  align-items: center;
  font-family: 'Poppins';
  letter-spacing: 0.0015em;
  width: ${({ size }) => size};
  color: ${({ theme }) => theme.palette.text.white};

  ${({ click }) =>
    click &&
    css`
      cursor: pointer;
    `};
`;
