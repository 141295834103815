import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Link = styled.p`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16.0292px;
  line-height: 20px;
  text-align: right;
  color: #ffffff;
  padding: 11px 13px 12px 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 446px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

export const Icon = styled.img`
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin-left: 20px;
  cursor: pointer;
`;

export const FloatingMessage = styled.p<{ $isActive: boolean }>`
  bottom: 60px;
  left: 30%;
  padding: 7px 11px;
  position: absolute;
  opacity: ${(props) => (props.$isActive ? 1 : 0)};
  width: max-content;
  border-radius: 5px;
  letter-spacing: 1px;
  font-family: 'Poppins';
  background: #77798b;
  transition: opacity 0.8s ease-out;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSize - 0.6}rem;
  ::after {
    width: 0;
    height: 0;
    left: 10px;
    bottom: -5px;
    content: '';
    clear: both;
    position: absolute;
    border-left: 5px solid #77798b;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
`;
