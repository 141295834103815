import { servicesApis } from 'service/service.api';

type ErrorCode = {
  nome: string;
  valor: number;
};

let errors: ErrorCode[] = [];

export const getAllErrors = async () => {
  const response = await servicesApis.error.getErrors();
  errors = response.data.ErrorCodes;
};

export const getErrorStringName = async (errorCode: number) => {
  await getAllErrors();
  if (errors.length === 0) {
    return 'unknow_error';
  }
  const errorCodeStringName = errors.find(
    (element) => element.valor === errorCode
  );
  if (errorCodeStringName) {
    return errorCodeStringName.nome;
  }
  return 'unknown_error';
};
