import { GeneralButton } from 'components/elements/GeneralButton';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ showOnMobile?: boolean }>`
  display: none;
  justify-content: center;
  padding: 20px;
  padding-top: 0;

  ${({ showOnMobile }) =>
    showOnMobile &&
    css`
      display: flex;
    `}

  @media (min-width: 1170px) {
    display: none;
  }
`;

export const Button = styled(GeneralButton)`
  @media (max-width: 325px) {
    height: 30px;
    padding: 0 21px;
    font-size: 1.2rem;
  }
`;
