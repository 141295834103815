export const matchBetStore = {
  title: 'Bem vindo a loja MatchCash',
  subTitle:
    'Aproveite os valores reduzidos do MATCHCOIN, eles mudam conforme os lotes são consumidos.',
  emptyComponent: {
    title: 'Não há items na loja',
    description: 'Os itens serão disponibilizados aqui'
  },
  itemModal: {
    stockInfo: 'Em estoque',
    cartButton: 'Adicionar ao carrinho'
  },
  checkoutInfoModal: {
    name: 'Nome',
    lastname: 'Sobrenome',
    description: 'Preencha com seu nome e sobrenome para continuar',
    continueButton: 'Continuar'
  }
};
