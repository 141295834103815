import { FormikHelpers } from 'formik';

export enum Type {
  BATTLE = 'BATTLE',
  TOURNAMENT = 'TOURNAMENT'
}

export type Values = {
  amount: string;
  password: string;
};

export type Props = {
  type: Type;
  isError?: string;
  errorMessage?: string;
  onClose?: () => void;
  onSubmit?: (values: Values, formik: FormikHelpers<Values>) => void;
};
