import { ReactElement, useContext } from 'react';

import { AuthContext } from 'state/auth/state';
import { FeatureControlContext } from 'state/featureControl/state';

import {
  Account,
  UserTournament,
  CreateTournament,
  Battle,
  CreateBattle,
  Chat,
  NftStore,
  Friends,
  GlobalRanking,
  Tournament,
  YourHistory,
  TournamentDetails,
  MatchbetStore,
  CheckoutSuccess
} from 'pages';

import { privateRoute } from './RouteTypes';

const privateRoutes = (): (ReactElement | null)[] => {
  const { state: auth } = useContext(AuthContext);
  const signed = auth.auth.signed as boolean;

  const { state: feature } = useContext(FeatureControlContext);
  const account = feature.featureControl.account as boolean;
  const battle = feature.featureControl.battle as boolean;
  const chat = feature.featureControl.chat as boolean;
  const store = feature.featureControl.store as boolean;
  const friends = feature.featureControl.friends as boolean;
  const tournaments = feature.featureControl.tournaments as boolean;
  const globalRanking = feature.featureControl.globalRanking as boolean;
  const matchBetTournament = feature.featureControl
    .matchBetTournament as boolean;
  const yourHistory = feature.featureControl.globalRanking as boolean;
  const matchBetStore = feature.featureControl.buy_coins as boolean;

  return [
    account
      ? privateRoute({
          key: 'account',
          path: '/matchbet/banking',
          element: Account,
          signed
        })
      : null,

    globalRanking
      ? privateRoute({
          key: 'globalRanking',
          path: '/matchbet/globalRanking',
          element: GlobalRanking,
          signed
        })
      : null,
    yourHistory
      ? privateRoute({
          key: 'yourHistory',
          path: '/matchbet/yourhistory',
          element: YourHistory,
          signed
        })
      : null,

    tournaments
      ? privateRoute({
          key: 'tournaments',
          path: '/matchbet/tournaments',
          element: UserTournament,
          signed
        })
      : null,

    tournaments
      ? privateRoute({
          key: 'create-tournament',
          path: '/matchbet/createTournament',
          element: CreateTournament,
          signed
        })
      : null,

    tournaments
      ? privateRoute({
          key: 'create-tournament',
          path: '/matchbet/tournament',
          element: TournamentDetails,
          signed
        })
      : null,

    matchBetTournament
      ? privateRoute({
          key: 'matchBetTournament',
          path: '/matchbet/matchbetTournament',
          element: Tournament,
          signed
        })
      : null,

    battle
      ? privateRoute({
          key: 'battle',
          path: '/matchbet/battle',
          element: Battle,
          signed
        })
      : null,

    battle
      ? privateRoute({
          key: 'create-battle',
          path: '/matchbet/createBattle',
          element: CreateBattle,
          signed
        })
      : null,

    chat
      ? privateRoute({
          key: 'chat',
          path: '/matchbet/chat',
          element: Chat,
          signed
        })
      : null,

    store
      ? privateRoute({
          key: 'store',
          path: '/matchbet/store/nfts',
          element: NftStore,
          signed
        })
      : null,

    friends
      ? privateRoute({
          key: 'friends',
          path: '/matchbet/friends',
          element: Friends,
          signed
        })
      : null,

    matchBetStore
      ? privateRoute({
          key: 'buy_coins',
          path: '/matchbet/store/buyCoins',
          element: MatchbetStore,
          signed
        })
      : null,

    privateRoute({
      key: 'success-checkout',
      path: '/matchbet/store/checkoutSuccess',
      element: CheckoutSuccess,
      signed
    })
  ];
};

export default privateRoutes;
