/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './@types/actions';

const featureControlReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.TOOGLE_FEATURE:
      return {
        ...state,
        featureControl: {
          ...state.featureControl,
          ...action.payload
        }
      };
    default:
      return {};
  }
};

export default featureControlReducer;
