import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const MenuContainer = styled.div<{
  show?: boolean;
  right?: string;
  menu?: string;
}>`
  opacity: 0;
  top: -100vh;
  width: auto;
  height: auto;
  min-width: 238px;
  position: absolute;
  border-radius: 12px;
  background: rgb(53, 40, 76);
  right: ${(props) => props.right};
  box-shadow: 0px 0px 6px -1px #57556e;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      top: 100px;
      transition: opacity 0.5s ease-out;
    `}

  ::before {
    position: absolute;
    width: 0;
    height: 0;
    top: -11px;
    content: '';
    right: 25px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #35284c;
  }

  ${(props) =>
    props.menu === 'notification' &&
    css`
      min-width: 286px;
      min-height: 358px;
    `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
