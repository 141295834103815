import styled, { css } from 'styled-components';

export const Container = styled.div<{ canRenderButtons: boolean }>`
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 153px;

  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  justify-content: flex-start;

  ${({ canRenderButtons }) =>
    canRenderButtons &&
    css`
      justify-content: space-evenly;
    `}
`;

export const ContainerSlider = styled.div`
  width: 85%;
  height: 153px;

  display: flex;
  flex-direction: row;
  align-items: center;

  overflow: hidden;
`;

export const GameImage = styled.img`
  width: 120.32px;
  height: 120.32px;
  border-radius: 11.5376px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 18px;
  cursor: pointer;

  ${({ actived }: { actived?: boolean }) =>
    actived &&
    css`
      border: 2px solid #ffb620;
      filter: drop-shadow(0px 0px 15px #ffb719);
    `}
`;

export const LeftArrow = styled.img<{
  canRenderButtons: boolean;
  isDisabled?: boolean;
}>`
  display: ${({ canRenderButtons }) => (canRenderButtons ? 'flex' : 'none')};
  width: 30px;
  height: 30px;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const RightArrow = styled.img<{
  canRenderButtons: boolean;
  isDisabled?: boolean;
}>`
  display: ${({ canRenderButtons }) => (canRenderButtons ? 'flex' : 'none')};
  width: 30px;
  height: 30px;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;
