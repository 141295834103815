import { t } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const translationString = 'components.battle.schemas.createABetModalSchema';

const modalSchema = yup.object().shape({
  amount: yup
    .number()
    .required(t(`${translationString}.amount.required`))
    .notOneOf([0], t(`${translationString}.amount.min`)),

  password: yup.string().required(t(`${translationString}.password.required`))
});

export { modalSchema };
