import styled, { css } from 'styled-components';
import media from 'styles/breakpoints';

export const Content = styled.div<{ flex?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;

  ${(props) =>
    props.flex &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  svg {
    color: ${(props) => props.theme.palette.title.default};
    font-size: ${(props) => props.theme.typography.fontSizeRegular}rem;
  }
`;

export const MenuContainer = styled.div<{
  show?: boolean;
  right?: string;
  arrow?: string;
  menu?: string;
}>`
  opacity: 0;
  top: -100vh;
  width: auto;
  height: auto;
  min-width: 238px;
  position: absolute;
  border-radius: 12px;
  background: rgb(53, 40, 76);
  right: ${(props) => props.right};
  box-shadow: 0px 0px 6px -1px #57556e;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      top: 100px;
      transition: opacity 0.5s ease-out;
    `}

  ::before {
    width: 0;
    height: 0;
    top: -22px;
    content: '';
    right: -195px;
    position: relative;
    right: ${(props) => props.arrow};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #35284c;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;

export const UserAvatar = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    border-radius: 6px;
    object-fit: cover;
    border: 2px solid ${(props) => props.theme.palette.title.default};
  }
`;

export const Icon = styled.img<{ width?: number; height?: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const ContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccountContainer = styled(ContainerGeneric)<{ show?: boolean }>`
  padding: 14px 14px 14px;
  flex-direction: column;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  div:nth-child(2) {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ffffff33;
  }
`;

export const AccountContent = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
`;

export const TextAccount = styled.p`
  line-height: 140%;
  margin-left: 24px;
  letter-spacing: 0.0025em;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSize - 0.2}rem;
`;
