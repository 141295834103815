import cup from './cup.svg';
import eye from './eye.svg';
import coin from './coin.svg';
import copy from './copy.png';
import edit from './edit.png';
import flag from './flag.png';
import game from './game.svg';
import menu from './menu.png';
import xbox from './xbox.svg';
import steam from './steam.svg';
import clock from './clock.svg';
import union from './Union.svg';
import error from './error.png';
import edit2 from './edit2.svg';
import sorted from './sorted.png';
import logout from './logout.png';
import search from './search.svg';
import amount from './amount.svg';
import goBack from './go-back.svg';
import flagPt from './flag_pt.png';
import flagUs from './flag_us.png';
import successo from './check.png';
import message from './message.png';
import loading from './loading.svg';
import players from './players.svg';
import gameLol from './gameLol.svg';
import Close from './icon-close.png';
import ckeckIn from './calendar.svg';
import tagUser from './tag-user.svg';
import nintendo from './nintendo.svg';
import settings from './settings.svg';
import calendar from './calendar.png';
import group_16 from './Group_16.png';
import group_50 from './Group_50.png';
import group_51 from './Group_51.png';
import group_52 from './Group_52.png';
import group_53 from './Group_53.png';
import group_54 from './Group_54.png';
import group_55 from './Group_55.png';
import category from './category.png';
import setting2 from './setting-2.png';
import setting3 from './setting-3.png';
import group_143 from './Group_143.svg';
import arrowTop from './arrow-top.svg';
import checkmark from './checkmark.svg';
import arrowSwap from './arrow-swap.png';
import leftArrow from './left-arrow.svg';
import moedaOuro from './moeda_ouro.png';
import user_icon from './user-square.svg';
import searchIcon from './search-icon.svg';
import arrowBottom from './arrow-bottom.svg';
import galleryAdd from './gallery_add.svg';
import arrowRight from './arrow-right.svg';
import rightArrow from './right-arrow.svg';
import playstation from './playstation.svg';
import closeYellow from './close_yellow.svg';
import userOctagon from './user-octagon.png';
import closeButton from './close-square.svg';
import emptyWallet from './empty-wallet.png';
import loadingGray from './loading-gray.svg';
import notification from './notification.png';
import shieldSearch from './shield-search.svg';
import closeOutline from './close_outline.png';
import userOctagonSvg from './user-octagon.svg';
import triangleArrow from './triangle-arrow.svg';
import notificationActive from './notification_active.svg';
import walletConnectSuccess from './wallet_connect_success.png';
import arrowSquareLeft from './arrow-square-left.svg';
import send from './send.svg';
import calendarYellow from './calendar-yellow.svg';
import clockIcon from './clock-icon.svg';
import cartIcon from './cart-icon.png';
import errorIconModal from './modal-error-icon.png';
import successIconModal from './modal-success-icon.png';

interface IIcon<TValue> {
  [id: string]: TValue;
}

const icons: IIcon<string> = {
  cup,
  eye,
  coin,
  xbox,
  edit,
  copy,
  game,
  menu,
  send,
  flag,
  clock,
  union,
  error,
  edit2,
  steam,
  Close,
  logout,
  amount,
  goBack,
  flagPt,
  flagUs,
  search,
  sorted,
  tagUser,
  loading,
  message,
  players,
  ckeckIn,
  gameLol,
  successo,
  category,
  settings,
  calendar,
  group_16,
  group_50,
  group_51,
  nintendo,
  group_52,
  group_53,
  group_54,
  group_55,
  setting2,
  setting3,
  arrowTop,
  group_143,
  arrowSwap,
  user_icon,
  leftArrow,
  checkmark,
  moedaOuro,
  arrowRight,
  rightArrow,
  galleryAdd,
  searchIcon,
  arrowBottom,
  playstation,
  closeYellow,
  loadingGray,
  emptyWallet,
  userOctagon,
  closeButton,
  shieldSearch,
  notification,
  closeOutline,
  triangleArrow,
  userOctagonSvg,
  arrowSquareLeft,
  notificationActive,
  walletConnectSuccess,
  calendarYellow,
  clockIcon,
  cartIcon,
  errorIconModal,
  successIconModal
};

export const getIcon = (id: string) => icons[id];

export default icons;
