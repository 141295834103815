export const Checkout = {
  title: 'Cart',
  itemAdded: {
    description: 'has been added to your cart',
    button: 'Continue shopping'
  },
  totalContainer: {
    title: 'Order Total'
  },
  header: {
    product: 'Product',
    price: 'Price',
    quantity: 'Quantity',
    subtotal: 'Subtotal'
  },
  checkoutInfoModal: {
    name: 'Name',
    lastname: 'Last Name',
    description: 'Enter your name and last name to proceed ',
    continueButton: 'Continue'
  },
  checkoutResponseModal: {
    error:
      'There was an error in processing your Matchcoins purchase. Our team is actively addressing the issue, and we kindly request that you try the purchase again later.',
    success: 'Congratulations! Your Matchcoins purchase was a success.'
  }
};
