import { t } from 'translate/i18n';
import * as yup from 'yup';

const translationString = 'components.friends.schemas.inviteAFriendSchema';

const inviteAFriendSchema = yup.object().shape({
  email: yup
    .string()
    .email(t(`${translationString}.email.invalid`))
    .required(t(`${translationString}.email.required`))
});

export { inviteAFriendSchema };
