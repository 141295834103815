export const battle = {
  banner: {
    title: 'Create a battle and challenge your friends.',
    description: 'Play with your friends, create teams and much more.',
    alt: 'battle cards',
    button: {
      small: 'Create battle',
      large: `Let's go !`,
      goToStore: 'Go to Store'
    },
    modal: {
      alt: 'trophy image.',
      title: 'New battle',
      subTitle: 'Who do you want to play this game with?',
      buttonPrimary: 'Next',
      buttonSecondary: 'Cancel',
      typesOfMatch: {
        alone: 'Play alone',
        team: 'Play with team',
        newTeam: 'Create a new team'
      },
      buttonNewTeam: 'New Team',
      buttonPlayAlone: 'Play Alone',
      buttonPlayWithTeam: 'Play with team',
      description: `You don't have any team created yet.`,
      descriptionWithTeam: `who do you want to play this game with?`
    },
    buyCoins: 'Insuficient balance, click here to buy Matchcoins'
  },
  startNewBattle: {
    alt: 'trophy image',
    title: 'Start new battle',
    buttonNewTeam: 'New Team',
    buttonPlayAlone: 'Play Alone',
    buttonPlayWithTeam: 'Play with team',
    description: `You don't have any team created yet.`,
    descriptionWithTeam: `who do you want to play this game with?`
  },
  modalSelectPlatform: {
    buttonCancel: 'Cancel',
    buttonCreate: 'Select',
    placeholder: 'Nick Name',
    title: 'Select a platform',
    subTitle: 'All games will take place on the selected platform',
    description: 'Put the same nickname used on the selected platform.'
  },
  betCreated: {
    gameImage: {
      alt: 'Game image'
    },
    coinIcon: {
      alt: 'Coin image'
    },
    title: 'Create bet',
    buttonCancel: 'Cancel',
    buttonCreate: 'Create',
    platforms: 'On the platform',
    unknownError: 'Unknown error',
    betValue: 'value for the bet',
    subtitle: 'YOUR BET IS READY TO BE CREATED',
    invalidFinancialPassword: 'Invalid financial password',
    description:
      'The opposing team must accept your bet for the battle to begin',
    messages: {
      successfulRegistration: 'Battle successfully created.'
    },
    error: {
      user_financial_password_invalid: 'Invalid financial password',
      user_has_no_nft_to_create_battle:
        'You need to have the NFT to create a battle',
      user_financial_password_not_found:
        'You need to have a financial password to create a battle.'
    }
  },
  battleInProgress: {
    title: 'Current battle in progress',
    buttonChat: 'Go to chat',
    description: {
      small: 'To confirm the score, take a screenshot of the scoreboard.',
      large: 'Put the result in the app'
    },
    playerOneImage: {
      alt: 'Player one'
    },
    playerTwoImage: {
      alt: 'Player two'
    }
  },
  chat: {
    title: {
      battle: 'Battle MatchCash',
      tournament: 'Tournament MatchCash'
    }
  },
  newTeam: {
    Avatar: {
      alt: 'User image'
    },
    team: {
      alt: 'Team image'
    },
    buttonCancel: 'Cancel',
    buttonCreate: 'Create',
    buttonDelete: 'Delete',
    updateButton: 'Update',
    labelTeam: 'Name team',
    title: 'Create your team',
    titleUpdate: 'Your team',
    labelFriend: 'Add new friend',
    subTitle: 'Add your friends to the team'
  },
  newGame: {
    avatar: {
      alt: 'Game image'
    },
    gameIcon: {
      alt: 'Game icone'
    },
    gameImage: {
      alt: 'Icone for add image of game'
    },
    buttonCancel: 'Cancel',
    buttonCreate: 'Create',
    updateButton: 'Update',
    labelTeam: 'Game name',
    title: 'New game',
    label: 'Select the platform:',
    text: 'Add image for game',
    description:
      'Games added by the user do not have MatchCash result validation in disputes, if there is a divergence in the winning result, MatchCash will withhold the rake and return the rest of Betcons to opponents equally.'
  },
  excludeBattles: {
    modal: {
      buttonReturn: 'Return',
      title: 'Exclude Battle',
      buttonExclude: 'Exclude',
      description: 'Your battle is still waiting for an opponent'
    },
    messages: {
      success: 'Battle successfully exclued.',
      failed: 'Battle deleted failed, try again later.'
    }
  },
  yourBattles: {
    emptyState: {
      titleHistory: 'My history',
      title: 'Your list of created battles',
      paragraph: 'When you have created battles, they will appear here.',
      button: `Let's go!`
    },
    coinIcon: {
      alt: 'Coin image'
    },
    card: {
      tag: 'Your Bet',
      tagTournament: 'Your tournament',
      betValue: 'VALUE FOR THE BET',
      platform: 'On the platforms:',
      exclude: 'Exclude',
      view: 'View',
      finishedTournament: 'Finished',
      onGoingTournament: 'Ongoing'
    }
  },
  acceptBattle: {
    title: 'Enter the battle',
    gameImage: {
      alt: 'game image'
    },
    coinIcon: {
      alt: 'coin'
    },
    password: {
      placeholder: 'Financial password'
    },
    eyeIcon: {
      alt: 'see password'
    },
    platforms: 'On the platform',
    buttons: {
      cancel: 'Cancel',
      create: 'Enter'
    },
    notEnoughFunds: 'You do not have enough funds to enter this battle.',
    noPasswordSet: 'Please set a financial password to enter this battle.',
    cannotAcceptBattleWhileInBattle:
      'Cannot accept battle while you have an active battle',
    unknowError: 'It is not possible accept the battle'
  },
  openGlobalBattles: {
    title: 'Open Global Battle',
    description: '1:1 battles',
    gameImage: {
      alt: 'Game image'
    },
    platformLabel: 'On the platform',
    platformIcon: {
      alt: 'Platform icon'
    },
    valueLabel: 'value for the bet',
    coinIcon: {
      alt: 'Coin image'
    },
    modal: {
      buttonCancel: 'Cancel',
      buttonCreate: 'Create',
      title: 'Accept the battle',
      description:
        'The opposing team must accept your bet for the battle to begin'
    },
    emptyList: {
      title: 'Open Global Battles',
      description: 'The open global battle availables will appear here'
    }
  }
};
