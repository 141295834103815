/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  SubTitle,
  Title,
  Tooltip
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import { useEffect, useState } from 'react';
import { t } from 'translate/i18n';

import { servicesApis } from 'service/service.api';
import * as S from './LineChart.Styled';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend
);

export const LineChart = () => {
  const translationString = 'components.account.lineChart';
  const [chartData, setChartData] = useState<any>([]);
  const [dolarQuote, setDolarQuote] = useState<any>(0);
  const [dolarQuoteDate, setDolarQuoteDate] = useState<any>(new Date());

  const getChartValues = () => {
    // get de values from lineCartData
    const values = chartData?.map((item: any) => {
      return item.value.slice(2);
    });
    return values || [];
  };

  const getBeforeBody = (tooltipItem: any) => {
    return chartData[tooltipItem[0].dataIndex].variation;
  };

  const getChartLabels = () => {
    // get de labels from lineCartData
    const labels = chartData?.map((item: any) => {
      return item.label;
    });
    return labels || [];
  };

  const getBalance = async () => {
    const response = await servicesApis.user.getBalance();
    setChartData(response.data.chart_data);
    setDolarQuote(response.data.dolar_ask);
    setDolarQuoteDate(new Date(response.data.dolar_ref_date));
  };
  const data = {
    labels: getChartLabels(),
    datasets: [
      {
        label: t(`${translationString}.label`),
        data: getChartValues(),
        borderColor: '#00EC42',
        backgroundColor: '#00EC42'
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'MATCHCOIN'
      },
      subtitle: {
        display: true,
        text: `USD = R$ ${dolarQuote} em ${dolarQuoteDate.toLocaleString([], {
          dateStyle: 'medium'
        })}`
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          beforeBody: getBeforeBody,
          label: () => {
            return ``;
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          callback: (value: string | number) => {
            return `R$ ${value.toLocaleString()}`;
          }
        }
      }
    },
    elements: {
      point: {
        hitRadius: 20
      }
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <S.ChatContainer>
      <Line options={options} data={data} />
    </S.ChatContainer>
  );
};
