import React, { useContext } from 'react';
import { format } from 'date-fns';
import { t } from 'i18next';

import images from 'assets/img';
import { UserContext } from 'state/user/state';
import { useModal } from 'components/modules/Modal';
import { GeneralButton } from 'components/elements';
import { CustomCircularProgress } from 'components/modules';
import { WinnerModal } from '../WinnerModal';
import { Status, TableHeaderProps, TableProps, TableRowProps } from './@types';

import * as S from './TableStyled';

const translationString = 'components.tournamentDetails';

const TableHeader = ({
  stageSelected,
  setStageSelected,
  stages
}: TableHeaderProps) => {
  return (
    <S.HeaderContainer>
      {stages?.map((stage) => {
        const isPlural = stage.order > 1;
        const isStageSelected = stageSelected.order === stage.order;
        const isAvailableStage = stage.avaliable;
        return (
          <S.HeaderStage
            isDisabled={!isAvailableStage}
            onClick={() => {
              if (isStageSelected || !isAvailableStage) return;

              setStageSelected(stage);
            }}
            key={stage.order}
            stageSelected={isStageSelected}
          >
            <S.HeaderStageTitle>{`${stage.title}`}</S.HeaderStageTitle>
            <S.HeaderStageSubTitle>{`${stage.matches} ${t(
              `${translationString}.match`
            )}${isPlural ? 's' : ''}`}</S.HeaderStageSubTitle>
          </S.HeaderStage>
        );
      })}
    </S.HeaderContainer>
  );
};

const TableRow = ({
  entityId,
  player1,
  player2,
  startDate,
  endDate,
  status,
  validator,
  userId,
  tournamentCreatedById,
  tournamentId,
  battleWinner,
  callback = () => {}
}: TableRowProps) => {
  const { showModal } = useModal();

  const getStatusColor = {
    [Status.CREATED]: '#999999',
    [Status.PENDING]: '#999999',
    [Status.ACCEPTED]: '#52A569',
    [Status.VALIDATED]: '#52A569',
    [Status.WAITING_PAYMENT]: '#52A569',
    [Status.FINISHED]: '#52A569',
    [Status.ERROR]: '#A73737',
    [Status.CANCELED]: '#A73737',
    [Status.SUPPORT]: '#ff5900',
    [Status.WAITING_OPPONENT]: '#999999'
  }[status];

  const getDateInitAndEnd = () => {
    if (!startDate || !endDate)
      return {
        hourInit: '',
        hourEnd: '',
        day: '',
        month: ''
      };

    const formatStartDate = new Date(startDate);
    const formatEndDate = new Date(endDate);

    return {
      hourInit: formatStartDate.getHours(),
      hourEnd: formatEndDate.getHours(),
      day: formatEndDate.getDate(),
      month: format(formatEndDate, 'LLL')
    };
  };

  const getWinnerProps = () => {
    const stateWinner = {
      win: {
        text: t(`${translationString}.playerWin`),
        color: '#52A569'
      },
      less: {
        text: t(`${translationString}.playerLess`),
        color: '#A73737'
      },
      undefined: {
        text: t(`${translationString}.playerWithoutClass`),
        color: '#8FA5FE'
      }
    };

    if (player1.entity_id === battleWinner)
      return {
        player1: stateWinner.win,
        player2: stateWinner.less
      };
    if (player2.entity_id === battleWinner)
      return {
        player1: stateWinner.less,
        player2: stateWinner.win
      };
    return {
      player1: stateWinner.undefined,
      player2: stateWinner.undefined
    };
  };

  const { hourInit, hourEnd, day, month } = getDateInitAndEnd();

  const winnerProps = getWinnerProps();

  const handleWinnerMatch = () => {
    showModal(
      <WinnerModal
        callback={callback}
        battleId={entityId}
        tournamentId={tournamentId}
        competitors={{
          player1,
          player2
        }}
      />,
      'min-content',
      true,
      '42px'
    );
  };

  const isYourTournamnet = tournamentCreatedById === userId;
  const hasWinner = !!validator?.first?.user_id;
  const isBattleOver = new Date(endDate).getTime() < new Date().getTime();
  const isAvailableToSetWinner = isYourTournamnet && !hasWinner && isBattleOver;

  return (
    <S.RowContainer>
      <S.RowDate>
        <p>{`${hourInit}h ás ${hourEnd}h`}</p>
        <p>{`${day} de ${month}`}</p>
      </S.RowDate>

      <S.RowCompetitors>
        <S.RowCompetitor flex="flex-end">
          <S.RowCompetitorContainerName>
            <S.RowCompetitorName>{player1.nickname}</S.RowCompetitorName>
            <S.RowStatus tagColor={winnerProps.player1.color}>
              {winnerProps.player1.text}
            </S.RowStatus>
          </S.RowCompetitorContainerName>
          <S.RowCompetitorImg
            src={player1.image_url || images.defaultImage}
            alt=""
          />
        </S.RowCompetitor>
        :
        <S.RowCompetitor flex="flex-start">
          <S.RowCompetitorImg
            src={player2.image_url || images.defaultImage}
            alt=""
          />
          <S.RowCompetitorContainerName>
            <S.RowCompetitorName>{player2.nickname}</S.RowCompetitorName>
            <S.RowStatus tagColor={winnerProps.player2.color}>
              {winnerProps.player2.text}
            </S.RowStatus>
          </S.RowCompetitorContainerName>
        </S.RowCompetitor>
      </S.RowCompetitors>

      <S.RowStatusContainer>
        <S.RowStatus tagColor={getStatusColor}>
          {t(`${translationString}.status_name.${status.toLowerCase()}`)}
        </S.RowStatus>
        {isAvailableToSetWinner && (
          <GeneralButton
            variant="primary"
            size="small"
            onClick={handleWinnerMatch}
          >
            {t(`${translationString}.rowButton`)}
          </GeneralButton>
        )}
      </S.RowStatusContainer>
    </S.RowContainer>
  );
};

const Table = ({
  data,
  isAdmin,
  tournamentId,
  tournamentCreatedById,
  stageSelected,
  setStageSelected,
  callback = () => {},
  stagesLength = [],
  isLoading,
  stages
}: TableProps) => {
  const { state } = useContext(UserContext);

  const renderElement = (elementRendered: JSX.Element) => {
    if (isLoading) {
      return (
        <S.ContainerLoading>
          <CustomCircularProgress />
        </S.ContainerLoading>
      );
    }

    return elementRendered;
  };

  return (
    <S.TableContainer>
      <TableHeader
        stageSelected={stageSelected}
        setStageSelected={setStageSelected}
        matchCurrent={data.length}
        stagesLength={isLoading ? [] : stagesLength}
        stages={stages}
      />
      <S.TableRowContainer>
        {renderElement(
          <>
            {data?.map((battle) => (
              <TableRow
                key={battle.entity_id}
                entityId={battle.entity_id}
                player1={battle.player1}
                player2={battle.player2}
                startDate={battle.start_datetime}
                endDate={battle.end_datetime}
                status={battle.status as Status}
                validator={battle.validator}
                isAdmin={isAdmin}
                tournamentId={tournamentId}
                tournamentCreatedById={tournamentCreatedById}
                userId={state.user.entityId}
                callback={callback}
                battleWinner={battle.winner_id}
              />
            ))}
          </>
        )}
      </S.TableRowContainer>
    </S.TableContainer>
  );
};

export default Table;
