import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { t } from 'translate/i18n';

import icons from 'assets/img/icons';

import Time from 'react-time-picker';

import { Props } from './@types';
import * as S from './TimePickerStyled';

const TimePicker: React.FC<Props> = ({
  value = '',
  disabled = false,
  onChange = () => {}
}) => {
  const translationString = 'components.timePicker';

  const TimePickerWrapperStyles = createGlobalStyle`
    .time_picker {
      width: 195px;
      height: 65px;
      border-radius: 13px;
      border: 0;
      border: 1px solid #FFB620;
      color: white;
    }

    .react-time-picker__wrapper {
      border: 0 !important;
      padding: 10px;
    }

    .react-time-picker__inputGroup {
      font-size: 1.6rem;
    }

    .react-time-picker__inputGroup > input {
      color: white;
    }

    .react-time-picker--disabled {
      background-color: unset !important;
      opacity: 0.5;
    }
`;

  return (
    <>
      <TimePickerWrapperStyles />
      <Time
        className={['time_picker']}
        onChange={onChange}
        value={value}
        disabled={disabled}
        clockIcon={
          <S.Icon
            src={icons.clockIcon}
            alt={t(`${translationString}.clockIcon.alt`)}
          />
        }
        clearIcon={
          <S.Icon
            src={icons.closeYellow}
            alt={t(`${translationString}.clearIcon.alt`)}
            marginTop="2px"
          />
        }
      />
    </>
  );
};

export default TimePicker;
