import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled from 'styled-components';
import media from 'styled-media-query';

const CookiePolicyContainer = styled.div`
  left: 10px;
  width: 100%;
  bottom: 16px;
  height: 64px;
  display: flex;
  position: fixed;
  max-width: 750px;
  padding: 12px 16px;
  border-radius: 10px;
  align-items: center;
  background: #0d0b22;
  justify-content: space-between;
  box-shadow: 0px 12px 24px -6px rgba(96, 96, 96, 0.15),
    0px 0px 1px rgba(96, 96, 96, 0.1);
  ${media.lessThan('medium')`
    left: 0px;
    bottom: 0px;
    height: auto;
    position: sticky;
    padding: 5px 15px;
    border-radius: 0px;
  `}
`;
const Text = styled.p`
  line-height: 170%;
  margin-right: 10px;
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

const Register = styled(ButtonSmall)`
  width: 100%;
  float: right;
  max-width: 80px;
`;

const Link = styled.a`
  height: auto;
  color: #ffb620;
  text-decoration: none;
  width: auto !important;
  background-color: transparent;
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

export { CookiePolicyContainer, Text, Register, Link };
