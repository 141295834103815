export const Checkout = {
  title: 'Carrinho',
  itemAdded: {
    description: 'foi adicionado ao seu carrinho',
    button: 'Continue comprando'
  },
  totalContainer: {
    title: 'Total da ordem'
  },
  header: {
    product: 'Produto',
    price: 'Preço',
    quantity: 'Quantidade',
    subtotal: 'Subtotal'
  },
  checkoutInfoModal: {
    name: 'Nome',
    lastname: 'Sobrenome',
    description: 'Preencha com seu nome e sobrenome para continuar',
    continueButton: 'Continuar'
  },
  checkoutResponseModal: {
    error:
      'Houve um erro no seu processo de compra de Matchcoins. Nosso time esta ativamente trabalhando para resolver o problema, por favor, tente comprar novamente mais tarde',
    success: 'Parabéns! Sua compra de matchcoins foi um sucesso.'
  }
};
