import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Icon = styled.img`
  width: 21px;
  height: 21px;
  object-fit: contain;
`;

export const Field = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.white};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-left: 5px;
  background-color: transparent;
  border: 0;
  outline: 0;

  ::placeholder {
    color: ${({ theme }) => theme.palette.text.white};
  }
`;
