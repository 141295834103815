import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  margin-left: 2%;
  @media (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

export const Title = styled.h1`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.title.default};
  margin-top: 16px;
  padding-left: 1px;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 43px;
    margin-top: 20px;
    padding-left: 7px;
  }
`;

export const SubTitle = styled.p`
  margin-top: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  text-align: justify;
  letter-spacing: 0.15px;
  max-width: 518.91px;
  color: ${({ theme }) => theme.palette.text.white};

  @media (max-width: 768px) {
    line-height: 23.4px;
    text-align: start;
  }
`;

export const LoadingContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
