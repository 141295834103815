export const acceptBattleSchema = {
  password: {
    required: 'Mandatory password field!',
    min: 'The password must be longer than 6 characters'
  }
};

export const createABetModalSchema = {
  amount: {
    required: 'Mandatory amount field!',
    min: 'A battle should have an amount greater than 0'
  },
  password: {
    required: 'Mandatory current password'
  }
};
