import { i18n } from 'translate/i18n';
import { useModal } from '../Modal';
import * as S from './TermoStyled';

export const TermoModalPt = () => {
  const { closeModal } = useModal();

  const handleClose = () => {
    closeModal();
  };
  return (
    <S.TermoContainer>
      <S.Title>Termos e Condições de Uso</S.Title>
      <S.Container>
        <S.Text>
          Estes Termos e Condições de Uso (&quot;Termos de Uso&quot;), descrevem
          os termos e as condições aplicáveis ao acesso e consulta de saldo de
          {i18n.t('titles.matchcoins')} da Plataforma MatchCash por você,
          Usuário da Plataforma MatchCash. Desse modo, é importante a leitura
          atenta, pois ao se cadastrar você estará aceitando as nossas regras.
        </S.Text>
        <S.Text>
          O “Aceite” dos termos descritos a seguir é indispensável para qualquer
          forma de utilização da Conta do Usuário relacionados nesta Plataforma.
          Se você não concordar com os termos que se seguem, não poderá utilizar
          ou acessar o site a qualquer título. Além disso, o cadastro como
          Usuário não é permitido para pessoa juridicamente incapaz.
        </S.Text>
        <S.SubTitle>1. Definições</S.SubTitle>
        <S.Text>
          1.1. Toda vez que houver menção aos termos MatchCash, “nós” ou
          “nossos” estaremos nos referindo à MatchCash; bem como toda vez que
          houver menção aos termos “você”, “usuário”, “seu”, “sua”, estaremos
          nos referindo a você Usuário, que está consentindo com estes Termos de
          Uso para acessar a Plataforma.
        </S.Text>
        <S.Text>
          1.2. Para efeitos do presente, as seguintes palavras e expressões
          terão os respectivos significados a eles atribuídos abaixo: E-mail: O
          e-mail a ser utilizado pelo Usuário no cadastro deve ser o mesmo que
          foi informado para realizar a aquisição das{' '}
          {i18n.t('titles.matchcoins')} na página de vendas do site da MatchCash
          <S.Link href="https://sales.matchbet.io/" target="_blank">
            (sales.matchbet.io)
          </S.Link>
          .
        </S.Text>
        <S.Topics>
          <S.Topic>
            Conta Virtual: é a conta que o Usuário poderá visualizar e consultar
            o seu saldo de {i18n.t('titles.matchcoins')} adquiridos e
            disponíveis, vinculados ao seu e-mail de compra, dentro da
            plataforma MatchCash.
          </S.Topic>
          <S.Topic>
            Matchcoin: Utility Token utilizado para todas as transações internas
            ao ecossistema da MatchCash.O saldo de Tokens a ser visualizado por
            você foi aquele adquirido na Pré-Venda, exclusivamente, através do
            site
            <S.Link href="https://sales.matchbet.io/" target="_blank">
              (sales.matchbet.io)
            </S.Link>
            .
          </S.Topic>
          <S.Topic>
            Saldo Futuro: Saldo que está em processamento com uma previsão de
            recebimento no futuro.
          </S.Topic>
          <S.Topics>
            <S.Topic>
              E-mail:
              <S.Link href="mailto:admin@matchbet.io">
                {' '}
                admin@matchbet.io
              </S.Link>
            </S.Topic>
            <S.Topic>Whatsapp: +1 (321) 257-3602 </S.Topic>

            <S.Topic>
              Telegram:
              <S.Link href="https://t.me/matchbetprevenda">
                {' '}
                https://t.me/matchbetprevenda
              </S.Link>
            </S.Topic>
          </S.Topics>
          <S.Topic>
            Plataforma MatchCash: plataforma digital própria da MatchCash que
            habilita Usuários a acessarem os serviços de consulta de saldo de
            {i18n.t('titles.matchcoins')} para fins de visualição e conferência
            antes do envio para as suas wallets.
          </S.Topic>
          <S.Topic>
            Wallet: Wallets ou carteiras digitais são responsáveis por armazenar
            as chaves – sequência de números para pagar e receber criptomoedas
            ou tokens.
          </S.Topic>
          <S.Topic>
            Usuário: pessoa física, maior de 18 anos e capaz, que aderiu a estes
            Termos de Uso ao se cadastrar na plataforma MatchCash, inserir os
            dados cadastrais no formulário de adesão à Conta Virtual e aceitar
            os Termos de Uso.
          </S.Topic>
        </S.Topics>
        <S.SubTitle>2. Introdução</S.SubTitle>
        <S.Text>
          2.1. O objeto destes Termos de Uso é a prestação de serviços pela
          MatchCash ao Usuário, de informações acerca de seu saldo de{' '}
          {i18n.t('titles.matchcoins')}. A conferência do saldo pelo Usuário é
          importante para que ele visualize a quantidade de{' '}
          {i18n.t('titles.matchcoins')} que poderão ser enviadas para a sua
          wallet, quando estiverem disponíveis, de acordo com o whitepaper
          disponível no site da MatchCash. Caso o Usuário não concorde com a
          quantidade de {i18n.t('titles.matchcoins')} informados em sua conta
          virtual, deve entrar em contato com os nossos Canais de Atendimento.
        </S.Text>
        <S.Text>
          2.2. O Usuário declara estar ciente de que o saldo virtual está
          disponível apenas para conferência.
        </S.Text>
        <S.Text>
          2.3. A aceitação destes Termos de Uso é indispensável para a
          utilização dos Serviços prestados pela MatchCash. Cabe ao Usuário ler,
          entender e concordar com todas as condições estabelecidas neste
          documento antes de cadastrar-se como Usuário MatchCash.
        </S.Text>
        <S.Text>
          2.4. A MatchCash não se responsabiliza por falhas, erros,
          interrupções, maus funcionamentos, atrasos ou outras imperfeições que
          possam surgir, ainda que ocasionados pela MatchCash ou quaisquer de
          seus parceiros, não garantindo a prestação de serviço de forma
          ininterrupta, sem momentos de indisponibilidade ou lentidão. A
          MatchCash considera que tais situações são previsíveis e possíveis de
          ocorrer em se tratando de serviços de tecnologia.
        </S.Text>
        <S.Text>
          2.5. O Usuário declara estar ciente de que pode haver um atraso de até
          72 horas para que o seu saldo possa ser visualizado após realizada a
          compra de suas {i18n.t('titles.matchcoins')} no site da MatchCash, a
          partir do dia 10 de maio de 2022.
        </S.Text>
        <S.Text>
          2.6. Os sistemas necessários para o funcionamento da Conta do Usuário
          podem eventualmente não estar disponíveis, na medida em que estes
          dependem de serviços prestados por terceiros, como empresas de
          telecomunicações e provedores de acesso à internet, e em casos de
          falhas técnicas ou problemas com a internet, interrupção ou queda do
          sistema ou por qualquer outra circunstância alheia à MatchCash ou a
          seus parceiros comerciais, o que pode inviabilizar a visualização
          momentânea de seu saldo. Além disso, suspenderemos o uso e acesso à
          Plataforma em caso fortuito ou força maior, por exemplo, em caso de
          ataques cibernéticos e outros que possam prejudicar as funcionalidades
          da Plataforma e colocar em risco as informações dos Usuários, ou até
          mesmo em razão de manutenções e melhorias na Plataforma. Os Usuários
          NÃO poderão responsabilizar a MatchCash em virtude de prejuízos
          resultantes das dificuldades mencionadas, assim como por qualquer
          outro tipo de dano oriundo do disposto nesta cláusula.
        </S.Text>
        <S.Text>
          2.7. A MatchCash não se responsabiliza pelo recebimento de e-mails
          falsos e mensagens, enviados por terceiros sem qualquer relação direta
          com a MatchCash e/ou empresas do grupo.
        </S.Text>
        <S.Text>
          2.8. Ao aderir a estes Termos de Uso, o Usuário concorda que, para
          prestar os Serviços, a MatchCash exercerá a condição de mandatário
          (representante), dando cumprimento às solicitações feitas pelo
          Usuário, desde que respeitada a legislação vigente e as regras
          previstas nestes Termos de Uso.
        </S.Text>

        <S.SubTitle>3. Conta MatchCash</S.SubTitle>
        <S.Text>
          3.1. O Usuário só será elegível à manutenção da Conta MatchCash caso
          tenha 18 anos completos ou mais no momento do cadastro, além de
          capacidade legal para contratar, na condição de Pessoa Física. Para
          efetuar o cadastro, o Usuário deve preencher certas informações que
          permitam a sua identificação.
        </S.Text>
        <S.Text>
          3.2. O Usuário, ao se cadastrar na Plataforma, tem automaticamente
          criada uma Conta MatchCash em seu nome, que consiste em informar ao
          Usuário seu saldo de {i18n.t('titles.matchcoins')} para mera
          conferência.
        </S.Text>
        <S.Text>
          3.3. O Usuário receberá um e-mail de validação do seu cadastro no
          endereço informado, bem como um código de confirmação. Em caso de não
          recebimento da referida mensagem eletrônica, o Usuário deverá entrar
          em contato conosco a fim de verificar a efetiva criação de seu
          cadastro.
        </S.Text>
        <S.Text>
          3.4. A Conta MatchCash é única, pessoal e intransferível. Da mesma
          forma, o e-mail informado durante o cadastro somente poderá ser
          associado a um único Usuário. Em nenhuma hipótese, será aceito pela
          MatchCash o cadastro de novas contas de titularidade do mesmo Usuário.
        </S.Text>
        <S.Text>
          3.5. Os dados inseridos durante o cadastro deverão ser completos, com
          o preenchimento de todos os itens obrigatórios com informações
          precisas, idôneas, verdadeiras e exatas, sendo o Usuário o único
          responsável pelas informações fornecidas. Cabe ainda ao Usuário
          atualizá-las sempre que necessário, por meio dos Canais de
          Atendimento, mantendo sua veracidade, sob pena de suspensão dos
          Serviços, mediante bloqueio da Conta MatchCash e da responsabilização
          do Usuário nas esferas cível, administrativa e criminal previstas em
          lei.
        </S.Text>
        <S.Text>
          3.6. A MatchCash se reserva o direito de, a qualquer momento e ao seu
          critério, solicitar informações do Usuário, para verificar a
          veracidade dos dados informados. Entretanto, se as informações não
          forem enviadas, a MatchCash se reserva o direito de cancelar o
          cadastro a qualquer momento e suspender a Conta do Usuário, sem a
          necessidade de aviso prévio.
        </S.Text>
        <S.Text>
          3.7. É de total responsabilidade do Usuário manter em sigilo suas
          senhas, sendo elas pessoais e intransferíveis. A senha é a única forma
          para visualização de seu saldo e ingresso na área financeira. Dessa
          maneira, a MatchCash não será, em nenhum cenário, responsável por
          quaisquer prejuízos causados ao Usuário ou a terceiros pela divulgação
          e utilização indevida e não autorizada das senhas.
        </S.Text>
        <S.Text>
          3.8. O Usuário deverá comunicar imediatamente a MatchCash, pelos
          Canais de Atendimento, se tiver ciência de fraude, perda, ou qualquer
          incidente de segurança que resulte no compartilhamento de suas
          credenciais e senha com ou ainda na hipótese de suspeita ou indícios
          de uso indevido da Conta MatchCash, para que a MatchCash possa efetuar
          o bloqueio e emissão de uma nova senha para o Usuário.
        </S.Text>
        <S.Text>
          3.9. O Usuário somente poderá realizar a consulta de seu saldo de
          {i18n.t('titles.matchcoins')} por meio das ferramentas disponíveis na
          Conta MatchCash.
        </S.Text>
        <S.Text>
          3.10. A MatchCash se reserva o direito de suspender, limitar, bloquear
          ou inativar os Serviços sobre os recursos constantes na Conta
          MatchCash, por tempo indeterminado, não assistindo ao Usuário, por
          essa razão, qualquer tipo de indenização ou ressarcimento em caso de:
          violação aos termos destes Termos de Uso; suspeita de fraude; não
          fornecimento das informações solicitadas; constatação de dados
          incorretos ou inverídicos fornecidos pelo Usuário; condutas praticadas
          com a finalidade de obter saldo de forma ilícita e/ou fraudulenta;
          envolvimento do Usuário em qualquer ato de corrupção, ativa ou
          passiva; envolvimento em ações caracterizadas como suborno, conflito
          de interesse, financiamento ao terrorismo, lavagem de dinheiro;
          qualquer outra participação em crimes descritos em todas as leis das
          jurisdições pertinentes que resultem aplicáveis.
        </S.Text>
        <S.Text>
          3.11. O Usuário acessará as informações da Conta MatchCash pelo sítio
          eletrônico disponibilizado pela MatchCash
          <S.Link href="http://sales.matchbet.io/" target="_blank">
            {' '}
            (sales.matchbet.io)
          </S.Link>
          . A disponibilidade do Saldo na Conta MatchCash caracteriza-se como
          prestação de contas, para todos os fins legais.
        </S.Text>
        <S.Text>
          3.12. Para utilização das ferramentas, o Usuário deverá possuir acesso
          à internet, sendo de total responsabilidade do Usuário a aquisição de
          equipamentos (computador, smartphone, tablet) e programas (navegador)
          necessários à navegação da Plataforma e o provimento adequado de todos
          os recursos da internet (como conexão), sem exceção, bem como manter o
          ambiente do computador, smartphone e/ou tablet seguros, com uso de
          ferramentas disponíveis como antivírus e firewall, entre outras,
          atualizadas, de modo a contribuir na prevenção de riscos eletrônicos.
        </S.Text>

        <S.SubTitle>4. Retirada de valores Conta Digital</S.SubTitle>
        <S.Text>
          4.1. A quantidade de {i18n.t('titles.matchcoins')} disponibilizada na
          Conta MatchCash do Usuário estará disponível para saque entre os dias
          04 e 09 de julho de 2022, conforme descrito no whitepaper no sítio
          eletrônico da MatchCash, através do Botão “SAQUE” que estará
          disponível a partir destas datas. Para isso, o Usuário deverá conectar
          a sua wallet para que os valores disponíveis sejam transferidos a sua
          carteira digital.
        </S.Text>

        <S.SubTitle>5. Obrigações da MatchCash</S.SubTitle>

        <S.Text>
          5.1. A MatchCash se obriga a informar o saldo de{' '}
          {i18n.t('titles.matchcoins')} adquiridas pelo Usuário de maneira
          correta, correspondendo ao número de
          {i18n.t('titles.matchcoins')} que o Usuário comprou através do site da
          MatchCash, com todas as vantagens de compra incluídas, caso tenha
          havido algum link especial de compra utilizado pelo Usuário.
        </S.Text>
        <S.Text>
          5.2. A MatchCash se obriga, no período entre os dias 04 e 09 de julho
          de 2022, a disponibilizar aos Usuários a opção de saque de suas
          {i18n.t('titles.matchcoins')} para suas as carteiras digitais,
          conforme descrito no whitepaper, disponível no site da MatchCash.
        </S.Text>
        <S.Text>
          5.3. A MatchCash se esforçará para assegurar o cumprimento dos prazos
          destes Termos de Uso. Não obstante, o Usuário está ciente que, por
          determinados fatores externos que fujam ao controle da MatchCash,
          poderão ocorrer atrasos, motivo pelo qual o Usuário exime a MatchCash
          de toda e qualquer responsabilidade decorrente de eventuais
          inconvenientes ou prejuízos.
        </S.Text>
        <S.Text>
          5.4. A MatchCash manterá as {i18n.t('titles.matchcoins')} do Usuário
          em sua Conta MatchCash por um período indefinido.
        </S.Text>

        <S.SubTitle>6. Vigência e Encerramento destes Termos de Uso</S.SubTitle>

        <S.Text>
          6.1. Estes Termos de Uso têm prazo indeterminado de validade, com
          vigor a partir do Aceite do Usuário no momento do cadastro.
        </S.Text>
        <S.Text>
          6.2. Estes Termos de Uso serão rescindidos prontamente, mediante aviso
          ao Usuário, nas seguintes situações:
        </S.Text>
        <S.Topics>
          <S.Topic>
            Caso sejam verificadas operações fora do padrão de uso, e o Usuário
            deixe de atender pedido de envio de informações para a comprovação
            da regularidade dessas operações;
          </S.Topic>
          <S.Topic>
            Caso sejam identificadas irregularidades de natureza grave nas
            informações prestadas pelo Usuário.
          </S.Topic>
        </S.Topics>
        <S.Text>
          6.3. Caso a Conta MatchCash do Usuário seja suspensa em razão de
          fraude ou infração a estes Termos de Uso, a quantidade de{' '}
          {i18n.t('titles.matchcoins')} recebida indevidamente pelo Usuário
          poderá ser cancelada. Cabe à MatchCash fazer ou não o desbloqueio da
          Conta MatchCash.
        </S.Text>
        <S.Text>
          6.3. Caso a Conta MatchCash do Usuário seja suspensa em razão de
          fraude ou infração a estes Termos de Uso, a quantidade de{' '}
          {i18n.t('titles.matchcoins')} recebida indevidamente pelo Usuário
          poderá ser cancelada. Cabe à MatchCash fazer ou não o desbloqueio da
          Conta MatchCash.
        </S.Text>

        <S.SubTitle>7. Condições gerais</S.SubTitle>

        <S.Text>
          7.1. As Partes reconhecem que qualquer condição presente nestes Termos
          de Uso não implica na caracterização da MatchCash como uma instituição
          financeira, tampouco seguradora, nos termos da legislação vigente. A
          MatchCash não presta nenhum serviço bancário ou de câmbio ao Usuário,
          e tampouco qualquer outra atividade privativa de Instituições
          Financeiras ou seguradoras reguladas ou fiscalizadas pelo Banco
          Central do Brasil, Comissão de Valores Mobiliários (CVM) ou pela
          Superintendência de Seguros Privados (Susep).
        </S.Text>

        <S.Text>
          7.2. A MatchCash não será responsável e tampouco garantirá o
          cumprimento das obrigações que forem assumidas pelos Usuários perante
          terceiros.
        </S.Text>
        <S.Text>
          7.3. Não existe entre os Usuários e a MatchCash nenhuma relação
          societária ou comercial além da estabelecida por meio dos presentes
          Termos de Uso, e reciprocamente se manterão livres e indenes por
          qualquer reclamação oriunda das obrigações tributárias, trabalhistas
          ou previdenciárias que forem de sua própria responsabilidade. Nem a
          MatchCash nem qualquer Usuário induzirão pessoa alguma em erro sobre
          sua independência, nem obrigarão uns aos outros perante terceiros. O
          descumprimento desta obrigação pelo Usuário autorizará a MatchCash a
          inabilitar a Conta MatchCash do Usuário, sem prejuízo das medidas
          judiciais ou extrajudiciais que julgar cabíveis.
        </S.Text>

        <S.SubTitle>8. Anexos</S.SubTitle>
        <S.Text>
          8.1. É parte integrante e indissociável destes Termos de Uso, e nele
          incorporados por referência, o seguinte documento, onde estão
          detalhadas as políticas e/ou termos e condições de distribuição das
          {i18n.t('titles.matchcoins')}. O respectivo documento pode ser
          consultado por meio do link disponibilizado abaixo:
        </S.Text>
        <S.Topics>
          <S.Topic>
            Whitepaper MatchCash:
            <S.Link href="https://whitepaper.matchbet.io/" target="_blank">
              {' '}
              https://whitepaper.matchbet.io/
            </S.Link>
          </S.Topic>
        </S.Topics>

        <S.SubTitle>9. Disposições Finais</S.SubTitle>
        <S.Text>
          9.1. O Usuário declara-se ciente e concorda que a relação com a
          MatchCash estará sujeita à legislação aplicável à MatchCash,
          independentemente do local de utilização dos serviços.
        </S.Text>
        <S.Text>
          9.2. Serão válidas todas as notificações realizadas aos Usuários pela
          MatchCash no endereço de correio eletrônico principal que conste do
          cadastro dos Usuários.
        </S.Text>
        <S.Text>
          9.3. A nulidade ou invalidade de qualquer dos itens dispostos aqui
          nestes Termos de Uso, não implica na nulidade ou invalidade dos
          demais. As disposições consideradas nulas ou inválidas serão
          reescritas, de modo a refletir a intenção inicial de sua previsão em
          conformidade com a legislação aplicável, e inserida na nova versão
          destes Termos de Uso.
        </S.Text>
        <S.Text>
          9.4. A eventual tolerância por quaisquer das partes quanto a qualquer
          violação dos termos e condições deste documento será considerada mera
          liberalidade e não será interpretada como novação, precedente
          invocável, renúncia a direitos, alteração tácita dos termos
          contratuais, direito adquirido ou alteração contratual.
        </S.Text>
        <S.Text>
          9.5. Fica eleito o foro judicial do domicílio do Usuário como
          competente para dirimir qualquer dúvida decorrente deste instrumento.
        </S.Text>
        <S.Strong>Última atualização: 10 de maio de 2022</S.Strong>
      </S.Container>
      <S.Register onClick={handleClose}>REGISTRO</S.Register>
    </S.TermoContainer>
  );
};
