import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { t } from 'translate/i18n';

import { servicesApis } from 'service/service.api';

import { GeneralBattle } from 'models/InfoCard';

import { useModal } from 'components/modules/Modal';

import {
  AmountBetFilter,
  CustomCircularProgress,
  EventModal,
  OpenEvents
} from 'components/modules';
import { BattleContext } from 'state/battle/state';
import { EmptyList } from 'components/elements';
import eventModalSchema from 'components/modules/EventModal/EventModalSchema';
import {
  FormEnterBattle,
  useBattle
} from 'components/modules/EventModal/Battle';
import { BetCard } from './components/BetCard';

import * as S from './OpenGlobalBetsStyled';

import { Props } from './@types';

const OpenGlobalBets: React.FC<Props> = ({ playAlone, gameId, platformId }) => {
  const [openBets, setOpenBets] = useState<GeneralBattle[]>([]);
  const [filteredBets, setFilteredBets] = useState<GeneralBattle[] | null>(
    null
  );
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const { onSubmitEventModal } = useBattle();
  const { state: battleState } = useContext(BattleContext);
  const selectedTeamId = battleState.battle.teamSelected?.entity_id || '';

  const translationString = 'components.createBattle.openGlobalBets';

  const { showModal } = useModal();

  const acceptBattle = (battle: GeneralBattle): void => {
    showModal(
      <EventModal
        data={{
          amount: battle.amount,
          image_url: battle.selected_game.image_url,
          title: battle.selected_game.name,
          translationString: 'components.battle.acceptBattle',
          platform: battle.selected_platform,
          entity_id: battle.entity_id
        }}
        Children={FormEnterBattle}
        onSubmit={onSubmitEventModal}
        validationSchema={eventModalSchema}
      />,
      'min-content',
      true,
      '42px'
    );
  };

  const loadMore = async (): Promise<void> => {
    try {
      const battlesMethod = playAlone ? 'alone' : 'team';

      const bets = await servicesApis?.battle.getGlobalBattles(
        battlesMethod,
        page,
        gameId,
        platformId,
        selectedTeamId
      );

      setOpenBets([...openBets, ...bets]);

      if (bets.length <= 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadMore();
  }, [page]);

  const onChangeFilter = (values: string[]): void => {
    const [min, max] = values.map((item: string) => +item);

    const items = openBets
      .map((item: GeneralBattle) => {
        if (+item.amount >= min && +item.amount <= max) {
          return item;
        }

        return null;
      })
      .filter((item) => item) as GeneralBattle[];

    setFilteredBets(items);
  };

  const getMinValueOnList = (): number => {
    const betValues: number[] = openBets.map(
      (item: GeneralBattle) => +item.amount
    );
    return Math.min(...betValues);
  };

  const getMaxValueOnList = (): number => {
    const betValues: number[] = openBets.map(
      (item: GeneralBattle) => +item.amount
    );
    return Math.max(...betValues);
  };

  const getData = (): GeneralBattle[] => {
    return filteredBets && filteredBets.length > 0 ? filteredBets : openBets;
  };

  const renderOpenBets = (item: GeneralBattle): JSX.Element => {
    return (
      <BetCard
        playerImage={item.created_by.image_url}
        playerNickname={item.created_by.name}
        amount={item.amount}
        onAccept={() => acceptBattle(item)}
      />
    );
  };

  return (
    <OpenEvents
      title={t(`${translationString}.title`)}
      empty={openBets.length <= 0}
      emptyList={
        <EmptyList
          title={t(`${translationString}.emptyList.title`)}
          description={t(`${translationString}.emptyList.description`)}
        />
      }
      filter={
        <AmountBetFilter
          min={getMinValueOnList()}
          max={getMaxValueOnList()}
          disabled={openBets.length <= 0}
          onChange={onChangeFilter}
        />
      }
    >
      <InfiniteScroll
        dataLength={getData().length}
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={
          <S.LoadingWrapper>
            <CustomCircularProgress />
          </S.LoadingWrapper>
        }
      >
        {getData().map(renderOpenBets)}
      </InfiniteScroll>
    </OpenEvents>
  );
};

export default OpenGlobalBets;
