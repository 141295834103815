/* eslint-disable no-console */
import { useMemo } from 'react';
import icons from 'assets/img/icons';
import { Item, Props } from './@types';
import * as S from './SelectorStyled';

const Selector = ({
  list,
  itemSelected,
  prev = () => {},
  next = () => {},
  margin = '0 20px'
}: Props) => {
  const index = useMemo(
    () =>
      list
        .map((one: Item, idx: number) =>
          one.name === itemSelected.name ? idx : null
        )
        .filter((item: number | null) => Number.isInteger(item))
        .at(0) || 0,
    [itemSelected, list]
  );

  return (
    <S.ContainerSelector margin={margin}>
      <S.Icon
        alt="Icon arrow"
        disabled={index === 0}
        src={icons.arrowSquareLeft}
        onClick={() => (index === 0 ? {} : prev(itemSelected))}
      />
      <S.Item>{itemSelected.name}</S.Item>
      <S.Icon
        right
        alt="Icon arrow"
        src={icons.arrowSquareLeft}
        disabled={index >= list.length - 1}
        onClick={() => (index >= list.length - 1 ? {} : next(itemSelected))}
      />
    </S.ContainerSelector>
  );
};

export default Selector;
