import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const RechargeContainer = styled.div`
  z-index: 2;
  bottom: 0px;
  width: auto;
  height: auto;
  display: flex;
  position: fixed;
  align-items: center;
  justify-self: self-end;
  > div {
    justify-content: flex-start;
  }
  ${media.lessThan('medium')`
    left:0;
    > div {
      width: 100%;
      min-width: 100%;
    }
  `}
`;
const Content = styled.div<{
  header?: boolean;
  column?: boolean;
  padding?: string;
}>`
  width: 100%;
  height: 100%;
  display: grid;
  padding-bottom: 22px;
  grid-template-rows: 0fr 4.5fr 1fr;
  padding: ${(props) => props.padding};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
      justify-content: space-between;
    `};
  ${(props) =>
    props.header &&
    css`
      height: auto;
      display: flex;
      background: #22173a;
      border-radius: 12px 12px 0px 0px;
    `}
`;
const Title = styled.h2`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 22px;
  letter-spacing: 0.0015em;
  font-family: 'Russo One';
  text-transform: uppercase;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
const SubTitle = styled(Title)`
  font-family: 'Roboto';
  text-transform: initial;
`;
const Close = styled.button``;
const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;
  padding: 16px;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #ffffff60;
  justify-content: flex-start;
  .invalid-number-message {
    top: 45px;
    line-height: 24px;
    font-style: normal;
    position: absolute;
    letter-spacing: 0.15px;
    background-color: transparent;
    color: ${(props) => props.theme.palette.error.main};
    font-size: ${(props) => props.theme.typography.htmlFontSize}rem;
    font-weight: ${(props) => props.theme.typography.fontWeightLight};
  }

  input {
    font-family: 'Roboto';
    letter-spacing: 0.15px;
    font-size: ${(props) => props.theme.typography.fontSize}rem;
    ::placeholder {
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
  }
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const Continue = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
const Cancel = styled(ButtonSmall)`
  margin: 0;
  max-width: 108px;
`;
const Text = styled.p`
  gap: 5px;
  height: 100%;
  display: flex;
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const Initials = styled.strong``;
const ImgWallet = styled.img``;
const ContainerButtons = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 0 24px;
  align-items: flex-end;
  justify-content: flex-end;
`;
const Icon = styled.img`
  cursor: pointer;
`;

export {
  Icon,
  Text,
  Title,
  Close,
  Cancel,
  Content,
  SubTitle,
  Initials,
  Continue,
  ImgWallet,
  ContainerInput,
  ContainerButtons,
  RechargeContainer
};
