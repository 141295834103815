import React from 'react';
import type { Props } from './@types';
import * as S from './ProfileMenuStyled';

const ProfileMenu: React.FC<Props> = ({ children, show, position }) => {
  return (
    <S.MenuContainer show={show} right={position}>
      {children}
    </S.MenuContainer>
  );
};

export default ProfileMenu;
