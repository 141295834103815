import { GeneralButton } from 'components/elements/GeneralButton';
import { useModal } from 'components/modules/Modal';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Actions } from 'state/battle/@types/actions';
import { BattleContext } from 'state/battle/state';
import { t } from 'translate/i18n';
import { UserContext } from 'state/user/state';
import icons from 'assets/img/icons';
import { utils } from 'ethers';
import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';
import { Modal } from '../Modal';
import { Props, Values } from './@types';
import { modalSchema } from './CreateABetModalSchema';
import * as S from './CreateABetModalStyled';
import BetCreated from '../BetCreated';

const CreateABetModal: React.FC<Props> = ({
  open,
  isError,
  onClose = () => {}
}) => {
  const { showModal } = useModal();
  const { dispatch } = useContext(BattleContext);
  const { state: userState } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [createABetError, setCreateABetError] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      amount: 0,
      password: ''
    },
    onSubmit: ({ amount, password }: Values, { resetForm }) => {
      dispatch({
        type: Actions.SET_BET_AMOUNT,
        payload: {
          amount
        }
      });

      showModal(
        <BetCreated
          createABetError={setCreateABetError}
          currentPassword={password}
        />,
        '475px',
        true,
        '35px 44px 25px'
      );
      onClose();
      setCreateABetError('');
      resetForm();
    },
    validationSchema: modalSchema
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const stringValue = e.target.value.replace(/[^0-9.]/g, '');

    if (!e.target.value || !stringValue) {
      formik.setFieldValue('amount', '');
      return;
    }
    const balance = utils.parseEther(userState.user.balance);
    const value = utils.parseEther(stringValue);
    const isBalanceEnough = balance.gte(value);

    if (!isBalanceEnough) {
      formik.setFieldValue('amount', userState.user.balance);
    } else {
      formik.setFieldValue('amount', stringValue);
    }
  };
  const onCloseModal = (): void => {
    setCreateABetError('');
    onClose();
    formik.resetForm();
  };

  const translationString = 'components.createBattle.createABetModal';

  return (
    <Modal
      width="468px"
      height="auto"
      open={open}
      onClickOutContainer={onCloseModal}
    >
      <S.Container>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.SubTitle>{t(`${translationString}.subTitle`)}</S.SubTitle>
        <S.Form onSubmit={() => formik.handleSubmit}>
          <S.InputWrapper
            error={!!formik.errors.amount && formik.touched.amount}
          >
            <S.Field
              id="amount"
              type="text"
              placeholder={t(`${translationString}.amount`)}
              onChange={onChange}
              value={formik.values.amount || ''}
            />

            <div>
              <S.Text>
                <S.Initials>{t(`${translationString}.balance`)}</S.Initials>
                <S.Initials yellow>
                  <ExtendedNumberBox>
                    {userState.user.balance}
                  </ExtendedNumberBox>
                </S.Initials>
              </S.Text>
              <S.SeePassIcon
                src={icons.amount}
                alt={t(`${translationString}.eyeIcon.alt`)}
                error={!!formik.errors.amount && formik.touched.amount}
              />
            </div>
          </S.InputWrapper>
          <S.InputWrapper
            error={
              (!!formik.errors.password && formik.touched.password) ||
              !!createABetError
            }
          >
            <S.Field
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t(`${translationString}.password`)}
              onChange={formik.handleChange}
            />
            <S.SeePassIcon
              src={icons.eye}
              alt={t(`${translationString}.eyeIcon.alt`)}
              show={showPassword}
              error={!!formik.errors.password && formik.touched.password}
              onClick={() => setShowPassword(!showPassword)}
            />
          </S.InputWrapper>

          {isError ? (
            <S.TextError>{isError}</S.TextError>
          ) : (
            <S.TextError> {createABetError}</S.TextError>
          )}
        </S.Form>
        <S.Description>{t(`${translationString}.description`)}</S.Description>

        <S.ButtonsWrapper>
          <GeneralButton
            transparent
            type="button"
            size="medium"
            variant="primary"
            onClick={() => onCloseModal()}
            id="cancel"
          >
            {t(`${translationString}.buttons.cancel`)}
          </GeneralButton>
          <GeneralButton
            type="submit"
            size="large"
            variant="primary"
            onClick={() => formik.handleSubmit()}
            id="create"
          >
            {t(`${translationString}.buttons.create`)}
          </GeneralButton>
        </S.ButtonsWrapper>
      </S.Container>
    </Modal>
  );
};

export default CreateABetModal;
