/* eslint-disable @typescript-eslint/no-explicit-any */
import { Actions } from './@types/actions';

const ProfileNotificationsReducer = (state: any, action: any) => {
  switch (action.type) {
    case Actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications
      };

    case Actions.SET_LOADING:
      return {
        ...state,
        status: {
          ...state.status,
          loading: action.payload.loading
        }
      };

    default:
      return state;
  }
};

export default ProfileNotificationsReducer;
