import { i18n } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('errors.invalidEmail'))
    .required(i18n.t('errors.mandatoryEmail'))
});
export { formSchema };
