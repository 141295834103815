import React, { useContext } from 'react';
import { t } from 'translate/i18n';
import { Matchbet } from 'pages/Matchbet';
import icons from 'assets/img/icons';
import { useNavigate } from 'react-router-dom';
import { GeneralButton } from 'components/elements';
import { useMobile } from 'hooks/useMobile';
import { MenuContext } from 'state/menu/state';
import { Actions } from 'state/menu/@types/actions';

import * as S from './CheckoutSuccessStyled';

const CheckoutSuccess: React.FC = () => {
  const translationString = 'components.CheckoutSuccess';
  const [isMobile] = useMobile();
  const navigate = useNavigate();
  const { dispatch } = useContext(MenuContext);

  const getButtonSize = () => {
    return isMobile ? 'small' : 'large';
  };

  const sideBarControl = (key: string) => {
    dispatch({
      type: Actions.SET_MENU,
      payload: {
        selected: key
      }
    });
  };

  const onClickCreateBattle = () => {
    sideBarControl('battle');
    navigate('/matchbet/battle');
  };

  const onClickCreateTournament = () => {
    sideBarControl('tournaments');
    navigate('/matchbet/tournaments');
  };

  return (
    <Matchbet>
      <S.MainContainer>
        <S.Icon src={icons.successIconModal} />
        <S.Description>{t(`${translationString}.description`)}</S.Description>
        <S.ButtonContainer>
          <GeneralButton
            variant="primary"
            size={getButtonSize()}
            onClick={onClickCreateBattle}
          >
            {t(`${translationString}.createBattleButton`)}
          </GeneralButton>
          <GeneralButton
            variant="primary"
            size={getButtonSize()}
            onClick={onClickCreateTournament}
          >
            {t(`${translationString}.createTournamentButton`)}
          </GeneralButton>
        </S.ButtonContainer>
      </S.MainContainer>
    </Matchbet>
  );
};
export default CheckoutSuccess;
