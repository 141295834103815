import React, { useState } from 'react';
import { t } from 'i18next';

import images from 'assets/img';
import { tournamentService } from 'service/tournament';
import { GeneralButton } from 'components/elements';
import { useModal } from 'components/modules/Modal';

import { Props, Player } from './@types';

import * as S from './WinnerModalStyled';

const WinnerModal: React.FC<Props> = ({
  callback = () => {},
  battleId,
  tournamentId,
  competitors
}) => {
  const { closeModal } = useModal();
  const { setWinnerTournament } = tournamentService();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [playerSelected, setPlayerSelected] = useState<Player>({
    nickname: '',
    image_url: '',
    entity_id: ''
  });

  const translationString = 'components.tournamentDetails.winnerModal';

  const action = async () => {
    setLoading(true);

    await setWinnerTournament({
      battleId,
      winnerId: playerSelected.entity_id,
      tournamentId
    })
      .then(() => {
        callback();
        closeModal();
        setPlayerSelected({
          nickname: '',
          image_url: '',
          entity_id: ''
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const isAvailableToSetResult = !!playerSelected?.entity_id;
  const isWinner = (entity_id: string): boolean =>
    entity_id === playerSelected.entity_id;

  return (
    <S.WinnerModalContainer>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.SubTitle>{t(`${translationString}.subTitle`)}</S.SubTitle>

      <S.Compentitors>
        <S.Competitor onClick={() => setPlayerSelected(competitors.player1)}>
          <S.CompetitorImage
            src={competitors.player1.image_url || images.defaultImage}
            alt=""
          />
          <S.CompetitorNickname>
            {competitors.player1.nickname}
          </S.CompetitorNickname>
          <S.WinnerTag isWinner={isWinner(competitors.player1.entity_id)}>
            {t(`${translationString}.competitorSelected`)}
          </S.WinnerTag>
        </S.Competitor>
        <S.Separator>:</S.Separator>
        <S.Competitor onClick={() => setPlayerSelected(competitors.player2)}>
          <S.CompetitorImage
            src={competitors.player2.image_url || images.defaultImage}
            alt=""
          />
          <S.CompetitorNickname>
            {competitors.player2.nickname}
          </S.CompetitorNickname>
          <S.WinnerTag isWinner={isWinner(competitors.player2.entity_id)}>
            {t(`${translationString}.competitorSelected`)}
          </S.WinnerTag>
        </S.Competitor>
      </S.Compentitors>

      <S.WrapperButtons>
        <GeneralButton
          transparent
          onClick={() => closeModal()}
          variant="primary"
          size="medium"
        >
          {t(`${translationString}.button.return`)}
        </GeneralButton>
        <GeneralButton
          onClick={() => action()}
          variant="primary"
          size="medium"
          disabled={!isAvailableToSetResult}
          isLoading={isLoading}
        >
          {t(`${translationString}.button.set_result`)}
        </GeneralButton>
      </S.WrapperButtons>
    </S.WinnerModalContainer>
  );
};

export default WinnerModal;
