import styled, { css } from 'styled-components';

export const Container = styled.div<{
  showOnMobile?: boolean;
}>`
  width: 100%;
  height: calc(100vh - 98px - 20px - 68px - 30px - 22px);
  border-radius: 12px;
  padding: 22px;
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
  display: none;
  flex-direction: column;
  gap: 22px;
  position: relative;

  ${({ showOnMobile }) =>
    showOnMobile &&
    css`
      display: flex;
    `}

  @media (min-width: 768px) {
    height: calc(100vh - 80px - 50px - 96px - 30px - 50px);
  }

  @media (min-width: 1170px) {
    display: flex;
    grid-area: chat;
    height: 100%;
    max-height: 734.22px;
  }
`;

export const Title = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 130%;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const MessagesList = styled.div`
  display: flex;
  height: 100%;
  gap: 22px;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const Message = styled.div<{ mine?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;

  ${({ mine }) =>
    mine &&
    css`
      flex-direction: row-reverse;
    `};
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Name = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #979797;
  margin-bottom: 5px;
`;

export const Text = styled.p<{ mine?: boolean }>`
  padding: 9px 12px 9px 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.text.white};
  background: #8fa5fe;
  max-width: 250px;

  ${({ mine }) =>
    mine &&
    css`
      background: #8c0ff5;
      margin-left: auto;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 0px;
    `};
`;

export const NewMessages = styled.div`
  padding: 5px;
  color: ${({ theme }) => theme.palette.text.white};
  font-size: 1.6rem;
  font-family: 'Poppins';
  background-color: ${({ theme }) => theme.palette.background.purple};
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  text-align: center;
  cursor: pointer;
`;
