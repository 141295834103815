/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  saveAuthetification,
  removeAuthetification
} from 'service/sessionStorage';
import { Actions } from './@types/actions';

const dispatchEvent = (event: string) => {
  window.dispatchEvent(new CustomEvent(event));
};

const authReducer = (state: any, action: any) => {
  const signed = {
    auth: {
      signed: true
    }
  };

  const unsigned = {
    auth: {
      signed: false
    }
  };

  switch (action.type) {
    case Actions.USER_IS_SIGNED:
      saveAuthetification(signed);
      dispatchEvent('login');
      return signed;

    case Actions.USER_IS_UNSIGNED:
      removeAuthetification();
      dispatchEvent('logout');
      return unsigned;

    default:
      return {};
  }
};

export default authReducer;
