export const SideBar = {
  MatchBetTournament: 'MatchCash Tournament',
  Tournaments: 'Tournaments',
  GlobalRanking: 'Global Ranking',
  MatchBetBanking: 'MatchCash Banking',
  Battle: 'Battle',
  Friends: 'Friends',
  NftStore: 'NFT Store',
  Store: 'Buy Matchcoins'
};
