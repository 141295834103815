import icons from 'assets/img/icons';
import { GeneralButton } from 'components/elements/GeneralButton';
import { useModal } from 'components/modules/Modal';
import { Selector } from 'components/elements';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { EntityPlatform } from 'models/Games';
import { formSchema } from 'pages/Tournament/components/SetNickname/SetNicknameSchema';
import React, { useContext, useEffect, useState } from 'react';
import { Actions } from 'state/battle/@types/actions';
import { TournamentContext } from 'state/tournament/state';
import { v1 as uuidv1 } from 'uuid';

import { Props } from './@types';

import * as S from './SelectPlatformModalStyled';
import { TournamentSizeModal } from '../TournamentSizeModal';

const SelectPlatformModal: React.FC<Props> = ({ platforms }) => {
  const { closeModal, closeAndShow } = useModal();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(TournamentContext);
  const translationString = 'components.battle.modalSelectPlatform';
  const [activeButton, setActiveButton] = useState<number>(-1);
  const [indexPlatform, setIndexPlatform] = useState<number>(0);

  const onClickOverPlatform = (index: number): void => {
    setActiveButton(index);
    setIndexPlatform(index);
  };

  const renderButton = (
    platform: EntityPlatform,
    index: number
  ): React.ReactElement => {
    const buttom_id = `platform_button_${index + 1}`;
    if (index === activeButton) {
      return (
        <S.Item key={uuidv1()}>
          <S.Button active id={buttom_id}>
            <S.Icon src={platform.image_url} active />
          </S.Button>
        </S.Item>
      );
    }

    return (
      <S.Item key={uuidv1()} onClick={() => onClickOverPlatform(index)}>
        <S.Button id={buttom_id}>
          <S.Icon src={platform.image_url} />
        </S.Button>
      </S.Item>
    );
  };

  const formik = useFormik({
    initialValues: {
      nickname: ''
    },
    onSubmit: async ({ nickname }) => {
      setLoading(true);
      dispatch({
        type: Actions.SAVE_PLATFORM_FOR_SELECTED_GAME,
        payload: {
          nickname,
          platform: platforms[indexPlatform]
        }
      });
      setLoading(false);

      closeAndShow(
        <TournamentSizeModal platforms={platforms} />,
        '475px',
        true,
        '35px 42px 43px'
      );
    },
    validationSchema: formSchema
  });

  const onCloseModal = (): void => {
    setIndexPlatform(0);
    formik.resetForm();
    closeModal();
  };

  const handlePrev = () => {
    setIndexPlatform(indexPlatform - 1);
  };
  const handleNext = () => {
    setIndexPlatform(indexPlatform + 1);
  };

  useEffect(() => {
    onClickOverPlatform(indexPlatform);
  }, [indexPlatform]);

  return (
    <S.Content>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.SubTitle>{t(`${translationString}.subTitle`)}</S.SubTitle>
      <S.ButtonsList>{platforms?.map(renderButton)}</S.ButtonsList>
      <Selector
        list={platforms}
        prev={handlePrev}
        next={handleNext}
        itemSelected={
          platforms[indexPlatform] || {
            name: 'NAN',
            entity_id: 'NAN',
            image_url: 'NAN'
          }
        }
      />
      <S.Form onSubmit={formik.handleSubmit}>
        <S.InputWrapper
          error={!!formik.errors.nickname && formik.touched.nickname}
        >
          <S.Field
            type="text"
            id="nickname"
            onChange={formik.handleChange}
            placeholder={t(`${translationString}.placeholder`)}
          />

          <S.SeePassIcon
            src={icons.userOctagonSvg}
            alt={t(`${translationString}.eyeIcon.alt`)}
            error={!!formik.errors.nickname && formik.touched.nickname}
          />
        </S.InputWrapper>
        {formik.errors.nickname && (
          <S.TextError>{formik.errors.nickname}</S.TextError>
        )}
      </S.Form>
      <S.Text>{t(`${translationString}.description`)}</S.Text>
      <S.ContainerButtons>
        <GeneralButton
          transparent
          size="medium"
          variant="primary"
          onClick={() => onCloseModal()}
        >
          {t(`${translationString}.buttonCancel`)}
        </GeneralButton>
        <GeneralButton
          size="medium"
          type="submit"
          variant="primary"
          isLoading={loading}
          onClick={() => formik.handleSubmit()}
        >
          {t(`${translationString}.buttonCreate`)}
        </GeneralButton>
      </S.ContainerButtons>
    </S.Content>
  );
};

export default SelectPlatformModal;
