import { GeneralButton } from 'components/elements';
import { Card } from 'components/modules/Card';
import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuContext } from 'state/menu/state';
import { Actions as MenuActions } from 'state/menu/@types/actions';
import { UserContext } from 'state/user/state';
import { t } from 'translate/i18n';

import * as S from './WalletStyled';

const Wallet: React.FC = () => {
  const { state } = useContext(UserContext);
  const { dispatch: MenuDispatch } = useContext(MenuContext);
  const translationString = 'components.account.yourWallet';

  const navigate = useNavigate();

  const onClickToBuyCoins = (): void => {
    MenuDispatch({
      type: MenuActions.SET_MENU,
      payload: {
        selected: 'buy_coins'
      }
    });
    navigate('/matchbet/store/buyCoins');
  };

  return (
    <Card
      width="auto"
      height="auto"
      padding="5px 10px"
      background="rgba(255, 255, 255, 0.1)"
    >
      <S.Content>
        <S.SubContainer>
          <S.Text>{t(`${translationString}.virtualBalance`)}</S.Text>
          <S.Title size="2.6">
            <ExtendedNumberBox>{`${state.user.balance} MTC`}</ExtendedNumberBox>
          </S.Title>
        </S.SubContainer>
        <GeneralButton
          size="small"
          variant="primary"
          transparent
          onClick={onClickToBuyCoins}
        >
          {t(`${translationString}.buyCoins`)}
        </GeneralButton>
      </S.Content>
    </Card>
  );
};

export default Wallet;
