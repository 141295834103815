import styled, { css } from 'styled-components';

export const Container = styled.ul`
  gap: 24px;
  width: 100%;
  display: flex;
  height: 564px;
  flex-wrap: wrap;
  margin-top: 25px;
  position: relative;

  @media (max-width: 485px) {
    justify-content: space-between;
  }

  @media (max-width: 299px) {
    gap: 20px;
  }
`;

export const Card = styled.li`
  cursor: pointer;
  list-style: none;

  @media (max-width: 485px) {
    width: 45%;
  }
`;

export const ContentCard = styled.div<{ hide: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const CardImage = styled.img`
  width: 164px;
  height: 164px;
  object-fit: contain;

  @media (max-width: 485px) {
    width: 100%;
    height: min-content;
  }
`;

export const CardDescription = styled.p`
  margin: 13px 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.text.white};
  overflow: hidden;
  max-width: 153px;
  margin-top: 13px;
`;

export const CardPrice = styled.p`
  margin: 13px 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.button.default};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 153px;
  margin-top: 13px;
`;
