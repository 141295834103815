import styled from 'styled-components';

export const Container = styled.li`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.palette.background.lightBlur};
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

export const GameImage = styled.img`
  width: 118px;
  height: 118px;
  object-fit: cover;
  border-radius: 10px;
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

type Alignment = {
  alignment?: 'flex-start' | 'baseline';
};

export const TitleValueWrapper = styled.div<Alignment>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.h4`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.58538rem;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.text.white};
  word-wrap: break-word;
  max-width: 110px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;

export const Coin = styled.img`
  width: 36px;
  height: 34px;
  object-fit: contain;
`;

export const Value = styled.div`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 2.08081rem;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Platforms = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 3px;
`;

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.99086rem;
  line-height: 17px;
  letter-spacing: 0.106698px;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Icon = styled.img`
  width: 11px;
  height: 11px;
  object-fit: contain;
`;

export const CountDownWrapper = styled.div`
  width: 100%;
  margin-top: 11px;
`;
