import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled from 'styled-components';
import media from 'styled-media-query';

const Container = styled.div`
  float: right;
  display: flex;
  height: 542px;
  padding: 42px;
  margin-right: 12%;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  padding: 42px;

  @media (max-width: 1440px) {
    margin-right: 5%;
  }

  ${media.lessThan('medium')`
    width: 100%;
    height: 100vh;
    margin-right: 0;
    padding: 35px 30px;
    background: rgba(11, 11, 34, 0.9);
  `}
`;
const ContainerConfirmCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.span`
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.palette.text.dark};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  color: rgba(255, 255, 255, 0.6);
`;

const Register = styled(ButtonSmall)`
  font-weight: 900;
  font-family: Poppins;
  font-size: 2rem;
  width: 206px;
  height: 52px;
  margin-top: auto;
  ${media.lessThan('medium')`
    width: 100%;
    max-width: 212px;
  `}
`;
const ContainerForm = styled.div`
  gap: 5px;
  display: flex;
  margin-top: 42px;
  margin-bottom: 22px;
  align-items: center;
  justify-content: center;

  input {
    width: 67px;
    height: 103px;
    margin-right: 4px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ffffff61;
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.light};
    font-size: ${(props) => props.theme.typography.fontSizeBold + 1.5}rem;
    box-sizing: border-box;

    :focus-visible {
      border: none;
      outline: 2px solid #1c75d2;
    }

    @media (max-width: 520px) {
      width: 48px;
      height: 72px;
      font-size: ${(props) => props.theme.typography.fontSizeBold - 0.5}rem;
    }
    ${media.lessThan('small')`
      width: 40px;
      height: 62px;
    `}
  }

  input:last-child {
    margin-right: 0px;
  }
`;
const Title = styled.h2`
  text-align: left;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  margin-bottom: 2px;
  font-family: 'Russo One';
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSizeBold}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const ResendText = styled(Text)<{ active?: boolean }>`
  color: ${(props) => props.active && props.theme.palette.button.default};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    text-decoration: ${(props) => props.active && 'underline'};
  }
`;
const Logo = styled.img`
  margin-bottom: 42px;
`;

export {
  Text,
  Register,
  Container,
  ContainerConfirmCode,
  ContainerForm,
  Title,
  Logo,
  ResendText
};
