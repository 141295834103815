import { i18n } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, i18n.t('errors.invalidPassword'))
    .required(i18n.t('errors.mandatoryPassword')),
  accessToken: yup
    .string()
    .min(6, 'Invalid access token, must be 6 digits')
    .required('Mandatory access token field!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], i18n.t('errors.passwordsDontMatch'))
    .required(i18n.t('errors.mandatoryConfirmPassword'))
});
const sendCodeSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('errors.invalidEmail'))
    .required(i18n.t('errors.mandatoryEmail'))
});
export { changePasswordSchema, sendCodeSchema };
