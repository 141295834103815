import { CustomCircularProgress } from 'components/modules';
import { Props } from './@types';
import * as S from './GeneralButtonStyled';

const GeneralButton = ({
  type,
  onClick,
  children,
  variant,
  size,
  isLoading = false,
  disabled = false,
  id,
  ...rest
}: Props) => {
  return (
    <S.GeneralButtonContainer
      type={type}
      variant={variant}
      size={size}
      isLoading={isLoading}
      disabled={disabled}
      onClick={onClick}
      id={id}
      {...rest}
    >
      <>
        <S.LoadingContainer isLoading={isLoading}>
          <CustomCircularProgress isGray={variant === 'secondary'} />
        </S.LoadingContainer>

        <S.ChildrenContainer isLoading={isLoading}>
          {children}
        </S.ChildrenContainer>
      </>
    </S.GeneralButtonContainer>
  );
};
export default GeneralButton;
