export const userProfileSchema = {
  nickname: {
    required: 'Invalid nickname!',
    message: 'The name must be longer than 3 letters'
  },
  birthday: {
    required: 'Mandatory birth Date field!',
    max: 'The birthday must be earlier than today'
  },
  phone: {
    invalid: 'The phone number is invalid'
  }
};

export const userProfilePhoneNumberSchema = {
  currentNumber: {
    required: 'Mandatory phone field!',
    message: 'Invalid phone, must be 13 digits',
    sameNumber: 'The actual number is incorrect'
  },
  newNumber: {
    required: 'Mandatory phone field!',
    message: 'Invalid phone, must be 13 digits'
  }
};

export const financePasswordSchema = {
  currentPassword: {
    required: 'Mandatory current password field!',
    message: 'Invalid current password, must be 8 digits'
  },
  newPassword: {
    required: 'Mandatory new password field!',
    message: 'Invalid new password, must be 8 digits'
  },
  confirmPassword: {
    required: 'Mandatory confirm password field!',
    mustMatch: 'Passwords do not match'
  },
  submit: {
    success: 'Password has been updated!',
    wrongPassword: 'Wrong password',
    callsExceeded: 'Calls limit has been exceeded. Wait a minute and try again'
  }
};

export const platformPasswordSchema = {
  currentPassword: {
    required: 'Mandatory current password field!',
    message: 'Invalid current password, must be 8 digits'
  },
  newPassword: {
    required: 'Mandatory new password field!',
    message: 'Invalid new password, must be 8 digits'
  },
  confirmPassword: {
    required: 'Mandatory confirm password field!',
    message: 'Password must match',
    wrong: 'Wrong password'
  },
  submit: {
    success: 'Password has been updated!',
    wrongPassword: 'Wrong password',
    callsExceeded: 'Calls limit has been exceeded. Wait a minute and try again'
  }
};
