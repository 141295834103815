import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getUserLanguage } from 'service/localStorage';
import { messages } from './languages';

i18n.use(LanguageDetector).init({
  debug: false,
  fallbackLng: 'en-US',
  defaultNS: 'translations',
  ns: ['translations'],
  resources: messages,
  lng: getUserLanguage()
});

const { t } = i18n;

export { i18n, t };
