export const tournamentDetails = {
  playerWin: 'Vitória',
  playerLess: 'Eliminado',
  playerWithoutClass: 'Sem definição',
  rowButton: 'Definir',
  title: 'Torneio',
  altIcon: 'Ícone de demonstração',
  entranceFee: 'Premiação',
  gameTitle: 'Jogo',
  platform: 'Plataforma',
  startDate: 'Data inicio',
  registeredPlayers: 'Jogadores',
  status_name: {
    created: 'Criado',
    error: 'Erro',
    pending: 'Pendente',
    accepted: 'Aceitado',
    validated: 'Validado',
    waiting_payment: 'Esperando pagamento',
    finished: 'Finalizado',
    canceled: 'Cancelado',
    support: 'Suporte',
    waiting_opponent: 'Esperando oponente'
  },
  stage: {
    one: 'Fase 1',
    two: 'Fase 2',
    three: 'Quartas de Final',
    four: 'Semifinais',
    five: 'Final'
  },
  match: 'partida',
  currentMatch: {
    title: 'Partida atual',
    button: 'Entrar na batalha',
    buttonChat: 'Entrar no chat'
  },
  winnerModal: {
    title: 'Definir Resultado',
    subTitle: 'Selecione a equipe vencedora para prosseguir.',
    button: {
      return: 'Voltar',
      set_result: 'Definir Resultado'
    },
    competitorSelected: 'Selecionado'
  }
};
