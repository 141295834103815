/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { ProfileSettingsProviderType } from './@types';
import profileSettingReducer from './reducer';

const initialState: any = {
  menu: {
    open: false
  }
};

export const ProfileSettingsContext = createContext(initialState);

const ProfileSettingsProvider: React.FC<ProfileSettingsProviderType> = ({
  children
}) => {
  const [state, dispatch] = useReducer(profileSettingReducer, initialState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ProfileSettingsContext.Provider value={provider}>
      {children}
    </ProfileSettingsContext.Provider>
  );
};

export default ProfileSettingsProvider;
