/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import { API } from 'aws-amplify';
import config from 'config/constants';
import { ENDPOINTS } from 'config/endpoints';
import { Battle, BattleRequested, ResponseMyBattle } from 'models/Battle';
import { Message, Messages } from 'models/Chat';
import { GeneralBattle } from 'models/InfoCard';
import { NftStoreList } from 'models/NftStoreList';
import { nftStoreList as nftStoreListMock } from 'service/mocks/nftStoreList';
import { OpenTournament, ResponseMyTournament } from 'models/Tournaments';
import { UserPayload } from 'models/User';
import { UploadURL } from 'components/modules/NewTeam/@types';
import { PlatformsType } from 'pages/SignIn/Components/Form/@types';
import { t } from 'translate/i18n';
import { yourHistoryInfoMock } from './mocks/yourHistoryInfo';
import { globalRankingMock } from './mocks/globalRanking';
import {
  MyListTournaments,
  OpenTournaments,
  Tournaments
} from './mocks/Tournament';

const getUploadURL = async (router: string): Promise<UploadURL> => {
  try {
    const response = await API.get(config.APIS.MATCHBET, router, {});
    return response.data;
  } catch (error) {
    throw new Error("Can't get the upload URL.");
  }
};

const getPlatforms = async (): Promise<PlatformsType[]> => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.user.getPlatforms,
      {}
    );
    return response.data;
  } catch (error) {
    throw new Error("Can't get the upload URL.");
  }
};

const getGlobalBattles = async (
  type: string,
  currentPage: number,
  gameId: string,
  platformId: string,
  selectedTeamId: string,
  options: { signal: AbortSignal } | Record<string, never> = {}
): Promise<GeneralBattle[]> => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.globalBattles(
        type,
        currentPage,
        gameId,
        platformId,
        selectedTeamId
      ),
      options
    );
    return response.data;
  } catch (error) {
    throw new Error("Can't get the global battles alone");
  }
};

const getAGlobalBattleAlone = async (
  battleId: string
): Promise<GeneralBattle> => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.aGlobalBattleAlone(battleId),
      {}
    );
    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw new Error("Can't get the battle");
  }
};

const createBattle = async (body: Battle, battleType: string) => {
  const isGlobal = body.opponent_id === '';
  if (isGlobal) {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.createGlobalBattle(battleType),
      {
        body: {
          ...body
        }
      }
    );
    return { response, error: '' };
  }
  const response = await API.post(
    config.APIS.MATCHBET,
    ENDPOINTS.battle.createFriendBattle(battleType),
    {
      body: {
        ...body
      }
    }
  );
  return { response, error: '' };
};

const getMyBattles = async (): Promise<ResponseMyBattle> => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.myBets,
      {}
    );
    return response;
  } catch (error) {
    throw new Error("Can't get your battles");
  }
};

const getMyBattlesHistory = async (): Promise<ResponseMyBattle> => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.battlesHistory,
      {}
    );
    return response;
  } catch (error) {
    throw new Error("Can't get your battles");
  }
};

const deleteABattle = async (battleId: string): Promise<void> => {
  try {
    const response = await API.del(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.removeBattle(battleId),
      {}
    );
    return response;
  } catch (error: any) {
    if (error.response) {
      switch (error.response.data.message) {
        default:
          throw new Error('UnknownError');
      }
    }
  }
};

const getTransactions = async () => {
  try {
    const responseTransactions = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.user.getTransactions,
      {}
    );

    return {
      success: true,
      data: responseTransactions.data
    };
  } catch (error: unknown) {
    console.log('error');

    if (error instanceof Error) {
      if (error.message === 'Request failed with status code 404') {
        return {
          data: [],
          success: false
        };
      }
    }
  }
};

const getUser = async (push?: boolean) => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.user.getUser,
      {}
    );
    if (push) {
      return {
        success: true,
        router: '/matchbet/tournaments'
      };
    }
    return response;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === 'Request failed with status code 404') {
        return {
          success: false,
          router: '/completesignup'
        };
      }
    }
  }
};

const getNotifications = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.user.getNotifications,
      {}
    );
    return response.data;
  } catch (error: unknown) {
    // ...
  }
};

const getBalance = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.user.getBalance,
      {}
    );
    return response;
  } catch (error: unknown) {
    // ...
  }
};

const getUserBalance = async () => {
  const response = await API.get(
    config.APIS.MATCHBET,
    ENDPOINTS.user.getUserBalance,
    {}
  );
  return response;
};

const getTeam = async () => {
  const response = await API.get(
    config.APIS.MATCHBET,
    ENDPOINTS.user.getTeam,
    {}
  );
  return response;
};

const getGames = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.games.getGames,
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const postFriendsRequest = async (email: string) => {
  const response = await API.post(
    config.APIS.MATCHBET,
    ENDPOINTS.friends.postFriendsRequest,
    {
      body: {
        to_email: email
      }
    }
  );

  return response;
};

const deleteFriend = async (requestId: string) => {
  try {
    const response = await API.del(
      config.APIS.MATCHBET,
      ENDPOINTS.friends.deleteFriend(requestId),
      {}
    );
    return response;
  } catch (error) {
    throw new Error('UnknownError');
  }
};

const getFriendRequests = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.friends.getFriendsRequest,
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const putAcceptBattle = async (
  battleId: string,
  password: string,
  nickname: string,
  selectedTeamId: string
) => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.battle.acceptBattleAlone(battleId),
      {
        body: {
          nickname,
          financial_password: password,
          selected_team_id: selectedTeamId,
          currency: 'MATCH_PRESALE'
        }
      }
    );

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const translationString = 'components.battle.acceptBattle';
    const message = error.response.data.messageç;

    const MESSAGES = {
      activeBattle: 'Cannot accept battle while you have an active battle',
      withoutFinancialPassword: 'não possui senha financeira cadastrada'
    };

    if (message === MESSAGES.activeBattle) {
      throw new Error(
        t(`${translationString}.cannotAcceptBattleWhileInBattle`)
      );
    }

    if (message.includes(MESSAGES.withoutFinancialPassword)) {
      throw new Error(t(`${translationString}.noPasswordSet`));
    }

    throw new Error(t(`${translationString}.unknowError`));
  }
};

const putRejectBattle = async (battleId: string) => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      `${ENDPOINTS.battle.rejectBattleAlone(battleId)}`,
      { body: {} }
    );
    return response;
  } catch (error) {
    throw new Error("Can't reject the battle alone");
  }
};

const putAcceptFriendRequest = async (requestId: string) => {
  try {
    const response = await API.put(
      config.APIS.MATCHBET,
      ENDPOINTS.friends.putFriend(requestId),
      { body: { status: 'accepted' } }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const putRejectFriendRequest = async (requestId: string) => {
  try {
    const response = await API.put(
      config.APIS.MATCHBET,
      ENDPOINTS.friends.putFriend(requestId),
      { body: { status: 'rejected' } }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const putNotificationsRead = async () => {
  try {
    const response = await API.put(
      config.APIS.MATCHBET,
      ENDPOINTS.user.putNotificationsRead,
      { body: { read: true } }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getFriends = async (type: string, teamId = '') => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.friends.getFriends(type, teamId),
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const bindsWallet = async () => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.blockchain.postbindsWallet,
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const withdraw = async () => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.blockchain.postWithdraw,
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const recharge = async () => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.blockchain.postRecharge,
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getWithdraw = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.blockchain.getWithdraw(
        '0x8433C835dC2E8F4f2DA86D4E97F1342B00b8D57d'
      ),
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getInfo = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.blockchain.getInfo(
        '0x8433C835dC2E8F4f2DA86D4E97F1342B00b8D57d'
      ),
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const postFinancePassword = async (
  currentPassword: string | undefined,
  newPassword: string
) => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.user.postFinancePassword,
      { body: { current_password: currentPassword, new_password: newPassword } }
    );
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      switch (error.response.data.message) {
        case 'Erro atualizando password: UserFinancialPasswordException(Senha financeira inválida)':
          throw new Error('invalidPassword');
        default:
          throw new Error('UnknownError');
      }
    }
  }
};
const getCurrentBattle = async (): Promise<BattleRequested> => {
  const response = await API.get(
    config.APIS.MATCHBET,
    ENDPOINTS.battle.currentBattle,
    {}
  );

  return response.data;
};

const getTournament = async () => {
  const useMock = false;
  if (useMock) {
    return Promise.resolve(Tournaments);
  }
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.tournament.getTournament('matchbet'),
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getMyTournaments = async (): Promise<ResponseMyTournament> => {
  const useMock = false;
  if (useMock) {
    return Promise.resolve(MyListTournaments);
  }
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.tournament.getMyListTournaments,
      {}
    );
    return response;
  } catch (error) {
    throw new Error("Can't get your tournaments");
  }
};

const postTournament = async (name: string, password: string, id: string) => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.tournament.postTournamentId(id),
      {
        body: {
          nick_name: name,
          financial_password: password
        }
      }
    );
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      switch (error.response.data.message) {
        case 'Senha financeira inválida':
          throw new Error('invalidPassword');
        case 'Erro desconhecido: TournamentPlayerAlreadyRegisteredException(Player already registered)':
          throw new Error('playerAlreadyRegistered');
        case 'Usuário não possui senha financeira cadastrada':
          throw new Error('financialPasswordNotRegistered');
        case 'Insufficient wallet balance':
          throw new Error('InsufficientWalletBalance');
        default:
          throw new Error('UnknownError');
      }
    }
  }
};

const getTournaments = async (type: string): Promise<OpenTournament[]> => {
  const useMock = false;

  if (useMock) {
    return Promise.resolve(OpenTournaments);
  }

  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.tournament.getTournaments(type),
      {}
    );
    return response.data;
  } catch (error) {
    throw new Error('UnknownError');
  }
};

const putAcceptTournamentBattle = async (
  tournamentId: string,
  battleId: string
) => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.tournament.acceptTournamentBattle(tournamentId),
      {
        body: {
          battle_id: battleId
        }
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

const sendTransaction = async ({
  payload,
  callBackError
}: {
  payload: object;
  callBackError: (error: Error) => void;
}) => {
  try {
    await API.post(config.APIS.MATCHBET, ENDPOINTS.blockchain.sendTransaction, {
      body: {
        ...payload
      }
    });
  } catch (error: any) {
    callBackError(error);
    throw error;
  }
};

const getMessages = async (battleId: string): Promise<Messages> => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.chat.getMessages(battleId),
      {}
    );
    return response?.data;
  } catch (error) {
    throw new Error("Can't get messages of chat");
  }
};

const sendMessage = async (
  battleId: string,
  message: string
): Promise<Message[]> => {
  try {
    const response = await API.put(
      config.APIS.MATCHBET,
      ENDPOINTS.chat.sendMessage(battleId),
      {
        body: {
          message
        }
      }
    );
    return response?.data;
  } catch (error) {
    throw new Error("Can't send message on chat");
  }
};

const putUserData = async (
  userId: string,
  user: UserPayload
): Promise<void> => {
  try {
    await API.put(config.APIS.MATCHBET, ENDPOINTS.user.putUserData(userId), {
      body: user
    });
  } catch (error) {
    throw new Error("Can't put user");
  }
};

const getGlobalRanking = async (gameId: string) => {
  const useMock = false;
  if (useMock) {
    return Promise.resolve(globalRankingMock);
  }
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.ranking.getGlobalRanking(gameId),
      {}
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
const getYourHistory = async () => {
  const useMock = false;

  if (useMock) {
    return Promise.resolve(yourHistoryInfoMock);
  }

  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.ranking.getYourHistory,
      {}
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getNftStoreList = async (): Promise<NftStoreList> => {
  const useMock = false;

  if (useMock) {
    return Promise.resolve(nftStoreListMock);
  }

  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.nft_store.getNftStoreList(),
      {}
    );
    console.log('response api', response);
    return response.data;
  } catch (error) {
    throw new Error('UnknownError');
  }
};

const getUserNFts = async (NFT_ID: string) => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.user.userNftOwnership(NFT_ID),
      {}
    );
    return response.data;
  } catch (error) {
    throw new Error('UnknownError');
  }
};

const postNftStorePurchase = async (
  nft_id: string,
  currency: string,
  password: string
) => {
  try {
    const response = await API.post(
      config.APIS.MATCHBET,
      ENDPOINTS.nft_store.postNftStorePurchase(),
      {
        body: {
          nft_id,
          currency,
          financial_password: password
        }
      }
    );

    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const getErrors = async () => {
  try {
    const response = await API.get(
      config.APIS.MATCHBET,
      ENDPOINTS.error.getErrors,
      {}
    );
    return response;
  } catch (error) {
    throw new Error('Cant get errors ');
  }
};

const getStoreItems = async () => {
  const response = await API.get(
    config.APIS.MATCHBET,
    ENDPOINTS.matchbet_store.getStoreItems,
    {}
  );
  return response;
};

const postGateWay = async (payload: object) => {
  const response = await API.post(
    config.APIS.MATCHBET,
    ENDPOINTS.matchbet_store.postGateWay,
    {
      body: {
        ...payload
      }
    }
  );
  return response.data;
};

export const servicesApis = {
  battle: {
    getGames,
    getPlatforms,
    getMyBattlesHistory,
    getGlobalBattles,
    createBattle,
    putAcceptBattle,
    getMyBattles,
    deleteABattle,
    putRejectBattle,
    getAGlobalBattleAlone,
    getCurrentBattle
  },
  user: {
    getUser,
    getUserBalance,
    getTeam,
    getBalance,
    getUploadURL,
    getTransactions,
    getNotifications,
    putNotificationsRead,
    postFinancePassword,
    putUserData,
    getUserNFts
  },
  friends: {
    postFriendsRequest,
    getFriendRequests,
    putAcceptFriendRequest,
    putRejectFriendRequest,
    getFriends,
    deleteFriend
  },
  tournament: {
    getTournament,
    postTournament,
    getMyTournaments,
    getTournaments,
    putAcceptTournamentBattle
  },
  chat: {
    getMessages,
    sendMessage
  },
  bank: {
    getInfo,
    withdraw,
    recharge,
    bindsWallet,
    getWithdraw,
    sendTransaction
  },
  globalRanking: {
    getGlobalRanking
  },
  yourHistory: {
    getYourHistory
  },
  nftStore: {
    getNftStoreList,
    postNftStorePurchase
  },
  error: {
    getErrors
  },
  matchBetStore: {
    getStoreItems,
    postGateWay
  }
};
