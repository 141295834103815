/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { getAuthetification } from 'service/sessionStorage';
import { AuthProviderType } from './@types';
import authReducer from './reducer';

const initialState: any = {
  auth: {
    signed: false
  }
};

const initialData = getAuthetification() || initialState;

export const AuthContext = createContext(initialState);

const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialData);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
