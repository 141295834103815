import { IndicationBox } from 'components/elements/IndicationBox';
import React from 'react';
import { t } from 'translate/i18n';

import * as S from './IndicationLinkStyled';

const IndicationLink: React.FC = () => {
  const translationString = 'components.indicationLink';

  return (
    <S.Container>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.Description>{t(`${translationString}.description`)}</S.Description>
      <S.IndicationLinkWrapper>
        <IndicationBox />
      </S.IndicationLinkWrapper>
    </S.Container>
  );
};

export default IndicationLink;
