import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const Icon = styled.img``;
export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 125%;
  text-align: center;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
`;
export const Description = styled.p`
  margin-top: 2px;
  line-height: 119%;
  font-size: 1.4rem;
  text-align: center;
  font-family: 'Poppins';
  color: ${({ theme }) => theme.palette.text.white};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: row;
`;
