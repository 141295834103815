import React from 'react';
import { Props } from './@types';
import * as S from './ExtendedNumberBoxStyled';

const ExtendedNumberBox: React.FC<Props> = ({
  children,
  isActive = true,
  position = 'top'
}) => {
  const formatExtendedNumbers = (
    value: string
  ): { value: string; isExtended: boolean } => {
    const numberLength = value.length;
    if (numberLength > 8) {
      return { value: `${value.slice(0, 8)}...`, isExtended: true };
    }
    return { value, isExtended: false };
  };
  const { value, isExtended } = formatExtendedNumbers(`${children}`);
  return (
    <S.NumberWrapper $isActive={isExtended && isActive}>
      <S.FloatingAmount position={position}>{children}</S.FloatingAmount>
      <span>{value}</span>
    </S.NumberWrapper>
  );
};

export { ExtendedNumberBox };
