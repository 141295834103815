import React from 'react';
import { useNavigate } from 'react-router-dom';

import icons from 'assets/img/icons';
import { Props } from './@types';
import * as S from './GoBackStyled';

const GoBack: React.FC<Props> = ({ onClick = null, ...restProps }) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    } else {
      navigate(-1);
    }
  };

  return (
    <S.GoBackContainer onClick={(event) => handleClick(event)} {...restProps}>
      <S.CloseIcon src={icons.goBack} />
    </S.GoBackContainer>
  );
};

export default GoBack;
