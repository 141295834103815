import { getImage } from 'assets/img';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

const StyledModalBody = styled.div`
  padding-top: 10px;
`;
const StyledModal = styled.div<{
  maxWidth?: string;
  padding?: string;
  small?: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 5px #ffffff14;
  background-image: url(${getImage('background_home')});
  margin: 42px;
  max-width: ${(props) => props.maxWidth};

  ${(props) => media.greaterThan('medium')`
    width: 100%;
    height: initial;
    padding: ${props.padding ? props.padding : '44px 50px'};
    border-radius: 10px;
    /* max-width: ${props.maxWidth}; */
  `}

  padding: 17px;
  overflow: auto;
  border-radius: 0;

  ${(props) =>
    props.small &&
    css`
      height: auto;
      display: flex;
      max-height: 98vh;
      border-radius: 12px;
      background-image: none;
      flex-direction: column;
      background-color: ${({ theme }) => theme.palette.background.lightBlur};
      background-color: rgba(74, 62, 94, 0.95);
    `};
`;

const StyledModalOverlay = styled.div<{ isOpen?: boolean }>`
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: all 1s cubic-bezier(0.7, 0, 0.27, 1);
  top: 100%;
  ${(props) =>
    props.isOpen &&
    css`
      top: 0%;
    `}
`;
const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 13px;
`;

const Shadow = styled.div<{ isOpen?: boolean }>`
  top: 100%;
  opacity: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
  display: flex;
  position: fixed;
  transition: top 0s linear 0.5s, opacity 0.5s linear;
  ${(props) =>
    props.isOpen &&
    css`
      top: 0%;
      opacity: 1;
      transition: top 0s linear, opacity 0.5s linear;
    `};
`;

export { StyledModalBody, StyledModal, StyledModalOverlay, Close, Shadow };
