import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const Title = styled.h2`
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 125%;
  text-align: center;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.white};
`;
export const ButtonsList = styled.ul`
  gap: 17px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  justify-content: center;
`;
export const Item = styled.li`
  list-style: none;
`;
export const Button = styled.button<{ active?: boolean }>`
  width: 67px;
  height: 67px;
  border: 0;
  border: 3px solid ${({ theme }) => theme.palette.button.disable};
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme, active }) =>
    active &&
    css`
      border-color: ${theme.palette.button.default};
    `};
`;
export const Icon = styled.img<{ active?: boolean }>`
  width: 30px;
  height: 30px;
  object-fit: contain;

  ${({ active }) =>
    active &&
    css`
      filter: invert(9.5%) sepia(17%) saturate(7418%) hue-rotate(339deg)
        brightness(101%) contrast(101%);
    `};
`;
export const Form = styled.form`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 24px auto 12px;
  align-items: flex-start;
  justify-content: flex-start;

  .MuiFormControl-root {
    margin: 0;
    width: 60%;

    .MuiOutlinedInput-root {
      display: flex;
      justify-content: space-between;

      .MuiOutlinedInput-input {
        border-radius: 0px;
        padding-right: 20px;
        color: ${(props) => props.theme.palette.text.light};
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.text.light};
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${(props) => props.theme.palette.text.light};
        }
      }
    }

    p.Mui-error {
      font-size: 1rem;
      margin-top: 5px;
    }
  }

  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.palette.text.light};
    font-size: ${(props) => props.theme.typography.fontSize}rem;
  }

  .MuiFormLabel-filled,
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.text.light};
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  @media (max-width: 768px) {
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
      display: -webkit-box;
    }

    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }

    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 16.5px 0px;
    }

    > div:first-child {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #ffffff99 !important;
  }
`;
export const SubTitle = styled(Title)`
  max-width: 400px;
  text-align: center;
  margin: 24px auto 40px;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.2}rem;
`;
export const Text = styled.p`
  text-align: center;
  font-family: 'Poppins';
  color: ${(props) => props.theme.palette.text.white};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSize - 0.2}rem;
`;
export const TextError = styled.p`
  margin-top: -18px;
  color: ${(props) => props.theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
export const InputWrapper = styled.div<{ error?: boolean }>`
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12.83px;
  padding: 16.5px 12.83px;
  border: 1px solid #ffb620;
  justify-content: space-between;

  > div {
    display: flex;
    max-width: auto;
    align-items: center;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.palette.error.main};
    `}

  @media (max-width: 768px) {
    width: 304px;
  }

  @media (max-width: 422px) {
    width: 250px;
  }

  @media (max-width: 368px) {
    width: 200px;
  }
`;
export const Field = styled.input`
  border: 0;
  outline: 0;
  flex: 0.94;
  width: 100%;
  font-weight: 500;
  line-height: 130%;
  font-style: normal;
  font-size: 1.92438rem;
  letter-spacing: 0.01em;
  font-family: 'Poppins';
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.white};

  ::placeholder {
    font-size: 1.55rem;
    color: ${({ theme }) => theme.palette.text.white};
  }
`;
export const SeePassIcon = styled.img<{ show?: boolean; error?: boolean }>`
  width: 38.49px;
  height: 38.79px;
  cursor: pointer;
  margin-left: 4px;
  object-fit: contain;

  ${({ show }) =>
    show &&
    css`
      filter: grayscale(1);
    `}

  ${({ error }) =>
    error &&
    css`
      filter: invert(45%) sepia(42%) saturate(2297%) hue-rotate(328deg)
        brightness(76%) contrast(123%);
    `}
`;
export const ContainerButtons = styled.div`
  gap: 10px;
  display: flex;
  max-width: 278px;
  align-items: center;
  margin: 25px auto 25px;
  justify-content: space-between;
`;
