import React from 'react';
import images from 'assets/img';

import * as S from './EmptyListStyled';

import { Props } from './@types';

const EmptyList: React.FC<Props> = ({ title, description }) => {
  return (
    <S.Container>
      <S.Trophy src={images.Trophy} />
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.Container>
  );
};

export default EmptyList;
