import React from 'react';

import { RowProps } from '../../@types';

import * as S from './RowStyled';

export const Row = ({ listRef, rowContent }: RowProps) => {
  return (
    <S.ContainerRow ref={listRef}>
      {rowContent?.map((row) => (
        <S.Row>{row.columns.map((column) => column.content)}</S.Row>
      ))}
    </S.ContainerRow>
  );
};
