export enum Actions {
  SET_TOURNAMENT = '@TOURNAMENT/SET_TOURNAMENT',
  SET_STEP = '@TOURNAMENT/SET_STEP',
  SELECT_TEAM = '@TOURNAMENT/SELECT_TEAM',
  SELECT_GAME = '@TOURNAMENT/SELECT_GAME',
  CREATE_NEW_TEAM = '@TOURNAMENT/CREATE_NEW_TEAM',
  SET_TEAMS = '@TOURNAMENT/SET_TEAMS',
  UPDATE_TEAM = '@TOURNAMENT/UPDATE_TEAM',
  SAVE_PLATFORM_FOR_SELECTED_GAME = '@BATLE/SAVE_PLATFORM_FOR_SELECTED_GAME',
  TOGGLE_CREATE_A_BET_MODAL = '@TOURNAMENT/TOGGLE_CREATE_A_BET_MODAL',
  SET_BET_AMOUNT = '@TOURNAMENT/SET_BET_AMOUNT',
  SET_FRIEND_CHALLENGER = '@TOURNAMENT/SET_FRIEND_CHALLENGER',
  SET_LOADING = '@TOURNAMENT/SET_LOADING',
  SET_MY_TOURNAMENTS = '@TOURNAMENT/SET_MY_TOURNAMENTS',
  RESET_TOURNAMENT_ALONE = '@TOURNAMENT/RESET_TOURNAMENT_ALONE',
  SET_CURRENT_TOURNAMENT = '@TOURNAMENT/SET_CURRENT_TOURNAMENT',
  RESET_CURRENT_TOURNAMENT = '@TOURNAMENT/RESET_CURRENT_TOURNAMENT',
  TYPE_TOURNAMENT = '@TOURNAMENT/TYPE_TOURNAMENT',
  UPDATE_PLAYERS_SELECTED = '@TOURNAMENT/UPDATE_PLAYERS_SELECTED',
  SET_SIZE_TOURNAMENT = '@TOURNAMENT/SET_SIZE_TOURNAMENT',
  SET_AMOUNT = '@TOURNAMENT/SET_AMOUNT'
}
