import React, { useEffect, useState } from 'react';
import { t } from 'translate/i18n';

import { CustomCircularProgress, NewTeam, NewGame } from 'components/modules';
import { platformsMock } from 'service/mocks/platforms';
import { Pagination, GeneralButton } from 'components/elements';
import { STEPS } from './constants';

import { Matchbet } from '../Matchbet';
import {
  SelectedCompetitors,
  List,
  CreateABetModal,
  PlatformFilter
} from './components';
import { useCreateTournament } from './hooks/useCreateTournament';

import * as S from './CreateTournamentStyled';

const CreateTournament: React.FC = () => {
  const {
    tournament,
    showModal,
    getList,
    translationString,
    getPageDescription,
    getTypeByStep,
    getAddButtonLabel,
    savePlatform,
    searchTeamOrGame,
    dataToShow,
    playAlone,
    numberOfPages,
    selectedPage,
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    toogleNewBetModal,
    clearList
  } = useCreateTournament();
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);

  const updatedData = () => {
    setShouldUpdateData(true);
  };

  useEffect(() => {
    if (!shouldUpdateData) return;
    getList();
    setShouldUpdateData(false);
  }, [shouldUpdateData]);

  const onClickToAdd = (): void => {
    switch (tournament.step) {
      case STEPS.FIRST:
        showModal(
          <NewTeam updateState={getList} />,
          '475px',
          true,
          '35px 42px 43px'
        );
        break;

      case STEPS.SECOND:
        showModal(
          <NewGame updateState={getList} platforms={platformsMock} />,
          '468px',
          true,
          '19px'
        );
        break;

      default:
        break;
    }
  };

  return (
    <Matchbet>
      <S.Container step={tournament.step}>
        <S.ListWrapper>
          <S.WrapperGoBack>
            <S.ButtonGoBack />
            <S.WrapperTexts>
              <S.Title>{t(`${translationString}.title`)}</S.Title>
              <S.AuxiliarTitle>
                {t(`${translationString}.auxiliarTitle`)}
              </S.AuxiliarTitle>
              {tournament.step === 3 && (
                <S.Description step={tournament.step}>
                  {getPageDescription()}
                </S.Description>
              )}
              <S.Wrapper step={tournament.step}>
                {tournament.step <= 2 && (
                  <S.Description step={tournament.step}>
                    {getPageDescription()}
                  </S.Description>
                )}
                {tournament.step === 3 && window.innerWidth > 1096 && (
                  <S.Subtitle>{t(`${translationString}.subtitle`)} </S.Subtitle>
                )}
                <S.SearchContainer>
                  <PlatformFilter
                    data={dataToShow?.response}
                    search={dataToShow?.response}
                    type={getTypeByStep()}
                    onChange={savePlatform}
                  />
                  <S.Search
                    placeholder={t(`${translationString}.search.placeholder`)}
                    onChange={searchTeamOrGame}
                  />
                  {getAddButtonLabel() && (
                    <GeneralButton
                      variant="primary"
                      size="medium"
                      onClick={() => onClickToAdd()}
                    >
                      {getAddButtonLabel()}
                    </GeneralButton>
                  )}
                </S.SearchContainer>
              </S.Wrapper>
            </S.WrapperTexts>
          </S.WrapperGoBack>

          {tournament.loading && (
            <S.LoadingWrapper>
              <CustomCircularProgress />
            </S.LoadingWrapper>
          )}

          <List
            type={getTypeByStep()}
            loading={tournament.loading}
            playAlone={playAlone}
            data={dataToShow}
            updatedData={updatedData}
            clearList={clearList}
          />

          {!tournament.loading && numberOfPages > 1 && (
            <S.PaginationWrapper>
              <Pagination
                numberOfPages={numberOfPages}
                selectedPage={selectedPage}
                clickOnRightArrow={clickOnRightArrow}
                clickOnLeftArrow={clickOnLeftArrow}
                clickOnPageIndicator={clickOnPageIndicator}
              />
            </S.PaginationWrapper>
          )}
        </S.ListWrapper>

        <S.OpenBetsWrapper step={tournament.step}>
          <SelectedCompetitors />
        </S.OpenBetsWrapper>

        <CreateABetModal
          open={tournament.createABetModal.open}
          onClose={toogleNewBetModal}
        />
      </S.Container>
    </Matchbet>
  );
};

export default CreateTournament;
