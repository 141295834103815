/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { API } from 'aws-amplify';
import { t } from 'translate/i18n';

import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField
} from '@mui/material';

import imagesImport from 'assets/img';
import Icons from 'assets/img/icons';

import { GeneralButton } from 'components/elements/GeneralButton';
import { Input } from 'components/elements';
import { CustomCircularProgress } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { Friend } from 'models/Friends';
import { ENDPOINTS } from 'config/endpoints';
import config from 'config/constants';
import { saveImageOnBucket } from 'service/s3.service';
import { servicesApis } from 'service/service.api';

import { NewTeamType, Props } from './@types';

import newTeamSchema from './NewTeamSchema';

import * as S from './NewTeamStyled';

const NewTeam: React.FC<Props> = ({
  team = undefined,
  updateState = () => {}
}) => {
  const translationString = 'components.battle.newTeam';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listFriends, setListFriends] = useState<Friend[]>([]);
  const [load, setLoad] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchFriend, setSearchFriend] = useState('');
  const [src, setSrc] = useState('');
  const [loading, setLoading] = useState(true);
  const [imageTeam, setImageTeam] = useState<File | undefined>();
  const [myfriends, setMyfriends] = useState<Friend[]>([]);
  const { closeModal } = useModal();
  const navigation = useNavigate();

  const handleClose = () => {
    // eslint-disable-next-line no-use-before-define
    formik.resetForm();
    setSrc('');
    closeModal();
  };
  const getUploadURL = async () => {
    const response = await servicesApis.user.getUploadURL(
      ENDPOINTS.teams.getUploadUrl
    );
    return response;
  };

  const postTeam = async (data: NewTeamType) => {
    const friends = listFriends
      .filter((friend: Friend) => friend.checked)
      .map((friend: Friend) => ({
        apelido: friend.apelido,
        teams: friend.entity_id,
        image_url: friend.image_url,
        entity_id: friend.entity_id
      }));

    try {
      const userData = {
        name: data.nameTeam,
        image_url: '',
        members: friends?.map(({ entity_id }) => entity_id)
      };

      if (imageTeam) {
        const { fields, url } = await getUploadURL();
        await saveImageOnBucket(url, fields, imageTeam);
        userData.image_url = `${url}/${fields?.key}`;
      }

      await API.post(config.APIS.MATCHBET, ENDPOINTS.user.postTeam, {
        body: {
          ...userData
        }
      });
      updateState();
      navigation('/matchbet/createBattle', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const putTeam = async (data: NewTeamType) => {
    const friends = listFriends
      .filter((friend: Friend) => friend.checked)
      .map((friend: Friend) => ({
        apelido: friend.apelido,
        teams: friend.entity_id,
        image_url: friend.image_url,
        entity_id: friend.entity_id
      }));
    try {
      const userData = {
        name: data.nameTeam,
        image_url: data.avatar,
        members: friends?.map(({ entity_id }) => entity_id),
        entity_id: team?.entity_id,
        created_by: team?.created_by
      };

      if (imageTeam) {
        const { fields, url } = await getUploadURL();
        await saveImageOnBucket(url, fields, imageTeam);
        userData.image_url = `${url}/${fields?.key}`;
      }

      await API.put(
        config.APIS.MATCHBET,
        ENDPOINTS.user.putTeam(team?.entity_id),
        {
          body: {
            ...userData
          }
        }
      );
      updateState();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteTeam = async () => {
    try {
      await API.del(
        config.APIS.MATCHBET,
        ENDPOINTS.user.deleteTeam(team?.entity_id),
        {}
      );
    } catch (error) {
      console.error(error);
    }
    handleClose();
    updateState();
  };
  const getFriends = async () => {
    try {
      const list = await API.get(
        config.APIS.MATCHBET,
        ENDPOINTS.friends.getFriends('alone'),
        {}
      );
      setMyfriends(list.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik<NewTeamType>({
    initialValues: {
      nameTeam: '',
      avatar: '',
      friends: [
        {
          image_url: '',
          apelido: ''
        }
      ]
    },
    onSubmit: (data) => {
      setLoad(false);
      team ? putTeam(data) : postTeam(data);
      handleClose();
    },
    validationSchema: newTeamSchema
  });

  const handleToggle = (id: string) => {
    const clonedData = listFriends?.length ? [...listFriends] : [...myfriends];

    setListFriends(
      clonedData.map((item) =>
        item.entity_id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  useEffect(() => {
    getFriends();
  }, []);

  useEffect(() => {
    if (team) {
      // setListFriends(team.members);
      setSrc(team.image_url);
      formik.setFieldValue('nameTeam', team.name);
      formik.setFieldValue('avatar', team.image_url);
      formik.setFieldValue('friends', team.members);
    }
  }, [team]);

  const listFiltered = myfriends.filter((friend: Friend) =>
    friend.apelido.toUpperCase().includes(searchFriend.toUpperCase())
  );

  const selectAnAvatar = (): void => {
    const input: HTMLInputElement | null =
      document.querySelector('#avatar-input');

    if (input) {
      input.click();
    }
  };

  const eraseInput = (input: HTMLInputElement): void => {
    // eslint-disable-next-line no-param-reassign
    input.value = '';
  };

  const getAvatar = (event: ChangeEvent<HTMLInputElement>): void => {
    const images = event?.target?.files;
    const image = (images || [])[0];
    const url: string = image ? URL.createObjectURL(image) : '';

    setSrc(url);
    setImageTeam(image);
    eraseInput(event?.target);
    formik.setFieldValue('avatar', url);
  };
  return (
    <S.Container>
      <S.Title>
        {team
          ? t(`${translationString}.titleUpdate`)
          : t(`${translationString}.title`)}
      </S.Title>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.ContainerInput
          nameTeam
          error={formik.touched.nameTeam && Boolean(formik.errors.nameTeam)}
        >
          <TextField
            id="nameTeam"
            type="nameTeam"
            label={t(`${translationString}.labelTeam`)}
            variant="outlined"
            autoComplete="off"
            sx={{
              '& legend': {
                fontSize: '1.2rem'
              }
            }}
            value={formik.values.nameTeam}
            onChange={formik.handleChange}
            error={formik.touched.nameTeam && Boolean(formik.errors.nameTeam)}
            helperText={formik.touched.nameTeam && formik.errors.nameTeam}
          />
        </S.ContainerInput>
        <S.Title small>{t(`${translationString}.subTitle`)}</S.Title>
        <S.PhotoContainer onClick={() => selectAnAvatar()}>
          {src !== '' ? (
            <S.Avatar src={src} alt={t(`${translationString}.team.alt`)} />
          ) : (
            <S.ContentNoPhone>
              <S.Img src={Icons.galleryAdd} alt="Add image for team" />
              <S.Text> Add image for team</S.Text>
            </S.ContentNoPhone>
          )}
          <S.HiddenInput
            id="avatar-input"
            type="file"
            accept="image/*"
            onChange={getAvatar}
          />
        </S.PhotoContainer>
      </S.Form>
      <Input
        search
        type="text"
        iconUrl={Icons.searchIcon}
        placeholder={t(`${translationString}.labelFriend`)}
        onChange={(event) => setSearchFriend(event.target.value)}
      />
      <List
        dense
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      >
        {loading ? (
          <Box>
            <CustomCircularProgress />
          </Box>
        ) : (
          <>
            {listFiltered.length > 0 &&
              listFiltered.map((friend) => {
                const labelId = `checkbox-list-secondary-label-${friend.checked}`;

                return (
                  <ListItem
                    role={undefined}
                    dense
                    key={friend.entity_id}
                    onClick={() => handleToggle(friend.entity_id)}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        checked={friend.checked}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <S.Img
                          src={friend.image_url || imagesImport.defaultImage}
                          alt={t(`${translationString}.Avatar.alt`)}
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={friend.apelido} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </>
        )}
      </List>

      <S.ContainerButtons team={!!team}>
        {team && (
          <GeneralButton
            onClick={() => handleDeleteTeam()}
            transparent
            variant="primary"
            size="medium"
          >
            {t(`${translationString}.buttonDelete`)}
          </GeneralButton>
        )}
        <GeneralButton
          onClick={() => handleClose()}
          transparent
          variant="primary"
          size="medium"
        >
          {t(`${translationString}.buttonCancel`)}
        </GeneralButton>
        <GeneralButton
          type="submit"
          variant="primary"
          size="medium"
          onClick={() => formik.handleSubmit()}
        >
          {load ? (
            <Box>
              <CustomCircularProgress />
            </Box>
          ) : (
            <Box>
              {team
                ? t(`${translationString}.updateButton`)
                : t(`${translationString}.buttonCreate`)}
            </Box>
          )}
        </GeneralButton>
      </S.ContainerButtons>
    </S.Container>
  );
};

export default NewTeam;
