import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const Description = styled.p`
  font-family: 'Russo One';
  font-weight: 400;
  line-weight: 38px;
  font-size: 24px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Icon = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
  margin-bottom: 2%;
`;
