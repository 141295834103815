import { Props } from './@types';
import * as S from './InputStyled';

const Input = ({
  disabled = false,
  search = false,
  iconUrl = '',
  alt = '',
  reference = undefined,
  value = '',
  ...rest
}: Props) => {
  return (
    <S.ContainerSearch search={search}>
      <S.Input disabled={disabled} ref={reference} value={value} {...rest} />
      <S.Icon src={iconUrl} alt={alt} />
    </S.ContainerSearch>
  );
};

export default Input;
