import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled from 'styled-components';
import media from 'styled-media-query';

const TermoContainer = styled.div`
  height: auto;
`;
const Container = styled.div`
  overflow: hidden;
  max-height: 480px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #dedede;
  }
  &::-webkit-scrollbar-thumb {
    background: #ffb620;
  }
`;

const Title = styled.h2`
  text-align: left;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  margin-bottom: 12px;
  font-family: 'Russo One';
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSizeBold}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const Text = styled.p`
  line-height: 170%;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${media.lessThan('medium')`
    margin-bottom: 50px;
  `}
`;
const SubTitle = styled(Title)`
  line-height: 170%;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};

  ${media.lessThan('medium')`
    margin-bottom: 50px;
  `}
`;
const Register = styled(ButtonSmall)`
  width: 100%;
  float: right;
  max-width: 170px;
  margin-top: 42px;
`;
const Topics = styled.ul`
  gap: 10px;
  display: flex;
  padding: 10px 60px;
  flex-direction: column;
`;
const Topic = styled.li`
  line-height: 170%;
  color: ${(props) => props.theme.palette.text.white};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;
const Strong = styled.strong`
  line-height: 50px;
  color: ${(props) => props.theme.palette.text.regular};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;
const Link = styled.a`
  line-height: 170%;
  color: ${(props) => props.theme.palette.text.lightBlue};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

export {
  Text,
  Link,
  Title,
  Topic,
  Topics,
  Strong,
  Register,
  SubTitle,
  Container,
  TermoContainer
};
