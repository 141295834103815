import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  div {
    gap: 3px;
    div {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
  > div:last-child {
    gap: 0;
    h2 {
      line-height: 13px;
      font-size: 1.5rem;
    }
    p {
      line-height: 17px;
      font-size: 0.9rem;
    }
  }
`;
