import { getImage } from 'assets/img';
import styled, { css } from 'styled-components';

const HomeContainer = styled.div`
  gap: 24px;
  width: 100%;
  height: auto;
  float: right;
  display: flex;
  min-height: 100%;
  background-size: cover;
  padding: 28px 0 0 144px;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-image: url(${getImage('background_home')});
`;
const Container = styled.div<{ column?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};
`;

const Content = styled.div`
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 824px;
`;

const Title = styled.h2`
  letter-spacing: 0;
  margin-bottom: 12px;
  font-family: 'Russo One';
  color: ${(props) => props.theme.palette.title.default};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.1}rem;
`;
const Text = styled.p`
  color: ${(props) => props.theme.palette.text.light};
  font-size: ${(props) => props.theme.typography.fontSize}rem;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

const ContainerCards = styled.div``;

const Img = styled.img``;
const Icon = styled.img``;

export {
  Img,
  Text,
  Icon,
  Title,
  Content,
  Container,
  HomeContainer,
  ContainerCards
};
