/* eslint-disable no-console */
import images from 'assets/img';
import icons from 'assets/img/icons';
import { GeneralButton } from 'components/elements/GeneralButton';
import React, { useEffect, useState } from 'react';
import { t } from 'translate/i18n';
import { useFormik } from 'formik';
import { useModal } from 'components/modules/Modal';
import * as S from 'components/modules/EventModal/EventModalStyled';
import acceptBattleSchema from 'components/modules/EventModal/EventModalSchema';
import { AcceptBattleSchema, Props } from './@types';
import { SetNickname } from '../SetNickname';

const AcceptTournament: React.FC<Props> = ({ data, isError, callback }) => {
  const { open, showModal, closeModal } = useModal();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const translationString = 'components.tournament.acceptTournament';

  const formik = useFormik<AcceptBattleSchema>({
    initialValues: {
      password: ''
    },
    onSubmit: ({ password }) => {
      showModal(
        <SetNickname
          name={data?.name}
          password={password}
          amount={data?.amount}
          type="acceptTournament"
          entity_id={data?.entity_id}
          image_url={data?.image_url}
          platforms={{
            name: data?.platforms.name,
            entity_id: data?.platforms.entity_id,
            image_url: data?.platforms.image_url
          }}
          callback={callback}
        />,
        '465px',
        true,
        '42px'
      );
    },
    validationSchema: acceptBattleSchema
  });

  const error = !!formik.errors.password && formik.touched.password;

  useEffect(() => {
    if (!open) {
      const SECOND = 1000;

      const timeout = setTimeout(() => {
        formik.resetForm();
        clearTimeout(timeout);
      }, SECOND);
    }
  }, [open]);

  return (
    <S.Container>
      <S.Title>{t(`${translationString}.title`)}</S.Title>

      <S.Content>
        <S.GameImage
          tournament
          src={data?.image_url || images.defaultImage}
          alt={t(`${translationString}.gameImage.alt`)}
        />
        <S.BattleInfo>
          <S.MainInfo>
            <S.Name>{data?.name}</S.Name>
            <S.CoinWrapper>
              <S.CoinIcon
                src={icons.moedaOuro}
                alt={t(`${translationString}.coinIcon.alt`)}
              />
              <S.Value>{data?.amount.toLocaleString()}</S.Value>
            </S.CoinWrapper>
          </S.MainInfo>
          <S.PlatformWrapper>
            <S.PlatformLabel>
              {t(`${translationString}.platforms`)}
            </S.PlatformLabel>
            <S.PlatformIcon
              src={data?.platforms?.image_url}
              alt={data?.platforms.name}
            />
          </S.PlatformWrapper>
          <S.Form onSubmit={formik.handleSubmit} tournament>
            <S.PasswordWrapper error={error}>
              <S.Field
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder={t(`${translationString}.password.placeholder`)}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <S.SeePassIcon
                src={icons.eye}
                alt={t(`${translationString}.eyeIcon.alt`)}
                show={showPassword}
                error={error}
                onClick={() => setShowPassword(!showPassword)}
              />
            </S.PasswordWrapper>
            {error ? <S.Error>{formik.errors.password}</S.Error> : null}
            {isError && <S.Error>{isError}</S.Error>}
            <S.ButtonsWrapper>
              <GeneralButton
                type="button"
                size="medium"
                variant="primary"
                transparent
                onClick={closeModal}
              >
                {t(`${translationString}.buttons.cancel`)}
              </GeneralButton>
              <GeneralButton type="submit" size="medium" variant="primary">
                {t(`${translationString}.buttons.create`)}
              </GeneralButton>
            </S.ButtonsWrapper>
            <S.Description>
              {t(`${translationString}.description`)}
            </S.Description>
          </S.Form>
        </S.BattleInfo>
      </S.Content>
    </S.Container>
  );
};

export default AcceptTournament;
