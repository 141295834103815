/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { t } from 'translate/i18n';
import { useLocation } from 'react-router-dom';
import intersaction from 'lodash.intersection';

import { Friend } from 'models/Friends';
import { EntityGame, EntityPlatform } from 'models/Games';
import { servicesApis } from 'service/service.api';
import { usePagination } from 'utils/Pagination';
import { useTournament } from 'state/tournament/hooks';
import { useModal } from 'components/modules/Modal';

import { ContentData, Game, Team } from '../@types';
import { Type } from '../components/List/@types';
import { STEPS } from '../constants';

export const useCreateTournament = () => {
  const [platforms, setPlatforms] = useState<string[]>([]);

  const { tournament, updateStep, toggleBetModal, setLoading } =
    useTournament();

  const { showModal, closeModal } = useModal();
  const {
    paginateSearch,
    resetPage,
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data
  } = usePagination({ ROWS_REQUIRED: 6 });

  const router = useLocation();

  const queryParams = new URLSearchParams(router.search);
  const playAlone = queryParams.get('playAlone') === 'true';

  const translationString = 'components.createTournament';

  const [dataToShow, setDataToShow] = useState<ContentData>({
    type: 'mock',
    response: []
  });

  const getTeams = async () => {
    setLoading(true);

    const response = await servicesApis.user.getTeam();

    if (response?.data.length) {
      calculatePaginationVars(response?.data);
      setLoading(false);
    }

    setLoading(false);
  };

  const getGames = async () => {
    setLoading(true);

    const response = await servicesApis.battle.getGames(); // change

    if (response?.data.length) {
      calculatePaginationVars(response?.data);
    }

    setLoading(false);
  };

  const getChallengers = async () => {
    setLoading(true);
    const type = playAlone ? 'alone' : 'team';
    const teamId = tournament.teamSelected.entity_id || '';
    const response = await servicesApis.friends.getFriends(type, teamId);

    if (response?.data.length) {
      calculatePaginationVars(response?.data);
    }
    setLoading(false);
  };

  const getList = () => {
    const list: any = {
      [STEPS.FIRST]: () => (!playAlone ? getTeams() : null),
      [STEPS.SECOND]: () => getGames(),
      [STEPS.THIRD]: () => getChallengers()
    };

    const currentStep = tournament.step || STEPS.FIRST;

    list[currentStep]();
  };

  useEffect(() => {
    getList();
  }, [tournament.step, router.pathname]);

  useEffect(() => {
    setDataToShow({
      type: 'dataApi',
      response: data
    });
  }, [JSON.stringify(data)]);

  const clearList = () => {
    setDataToShow({
      type: 'mock',
      response: []
    });
  };

  const searching = (valueSearched: string): void => {
    const functionFilter = (item: Team | Game | Friend) =>
      ((item as Team).name || (item as Game).name || (item as Friend).apelido)
        .toLocaleLowerCase()
        ?.includes(valueSearched.toLocaleLowerCase());

    paginateSearch(functionFilter);
  };

  const paginateGames = (games: string[], valueSearched: string): void => {
    const filterByPlatform = (game: EntityGame) =>
      intersaction(
        games,
        (game.platforms as EntityPlatform[]).map(
          (platform: EntityPlatform) => platform.name
        )
      ).length > 0;

    const filterByNameAndPlatforms = (game: EntityGame) =>
      game.name
        .toLocaleLowerCase()
        ?.includes(valueSearched.toLocaleLowerCase()) &&
      intersaction(
        games,
        (game.platforms as EntityPlatform[]).map(
          (platform: EntityPlatform) => platform.name
        )
      ).length > 0;

    const functionFilter =
      valueSearched.length > 0 ? filterByNameAndPlatforms : filterByPlatform;

    paginateSearch(functionFilter);
  };

  const filterGames = (games: string[]) => {
    const search: HTMLInputElement | null =
      document.querySelector('#search-input');

    if (search) {
      if (games.length > 0) {
        paginateGames(games, search.value);
      } else if (search.value.length > 0) {
        searching(search.value);
      } else {
        resetPage();
      }
    } else {
      resetPage();
    }
  };

  const searchTeamOrGame = (valueSearched: string): void => {
    if (valueSearched?.length > 0) {
      filterGames(platforms);
    } else {
      resetPage();
    }
  };

  const shouldRedirectToStepToOnReloadPage = () => {
    if (playAlone) {
      updateStep(2);
    }
  };

  useEffect(() => {
    closeModal();
    resetPage();
    shouldRedirectToStepToOnReloadPage();
  }, [router.pathname]);

  const getTypeByStep = (): Type => {
    const typesByStep: any = {
      [STEPS.FIRST]: Type.TEAM,
      [STEPS.SECOND]: Type.GAME,
      [STEPS.THIRD]: playAlone ? Type.CHALLENGE_PLAYER : Type.CHALLENGE_TEAM
    };

    const currentStep = tournament.step || STEPS.FIRST;

    return typesByStep[currentStep];
  };

  const getPageDescription = (): string => {
    const descriptionByStep: any = {
      [STEPS.FIRST]: t(`${translationString}.description.matchFormat`),
      [STEPS.SECOND]: t(`${translationString}.description.selectAGame`),
      [STEPS.THIRD]: t(`${translationString}.description.challenge`)
    };

    const currentStep = tournament.step || STEPS.FIRST;

    return descriptionByStep[currentStep];
  };

  const getAddButtonLabel = (): string => {
    const labelByStep: any = {
      [STEPS.FIRST]: t(`${translationString}.addButton`),
      [STEPS.SECOND]: t(`${translationString}.addButton`),
      [STEPS.THIRD]: undefined
    };

    const currentStep = tournament.step || STEPS.FIRST;

    return labelByStep[currentStep];
  };

  const toogleNewBetModal = (): void => {
    toggleBetModal();
  };

  const savePlatform = (games: string[]) => {
    setPlatforms(games);
    filterGames(games);
  };

  return {
    tournament,
    showModal,
    getList,
    translationString,
    getPageDescription,
    getTypeByStep,
    getAddButtonLabel,
    savePlatform,
    searchTeamOrGame,
    dataToShow,
    playAlone,
    numberOfPages,
    selectedPage,
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    toogleNewBetModal,
    clearList
  };
};
