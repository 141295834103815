import { getIcon } from 'assets/img/icons';
import Card from 'components/modules/Card/Card';
import { useState } from 'react';
import { Props } from '../@types';
import { Amount } from './components';
import { ConnectWallet } from './components/ConnectWallet';
import { VerificationPayment } from './components/VerificationPayment';
import { VerificationPaymentStatus } from './components/VerificationPaymentStatus';
import * as S from './RechargeWalletStyled';

export const Recharge = ({ close }: Props) => {
  const [step, setStep] = useState(1);

  return (
    <S.RechargeContainer>
      <Card width="479px" height="441px" padding="0" background="#ffffff19">
        <S.Content column>
          <S.Content header padding="16px 22px 16px 22px">
            <S.Title>Recharge </S.Title>
            <S.Icon
              onClick={() => close()}
              src={getIcon('Close')}
              alt="Icone de fechar"
            />
          </S.Content>
          {(() => {
            switch (step) {
              case 1:
                return <Amount />;
              case 2:
                return <ConnectWallet />;
              case 3:
                return <VerificationPayment />;
              case 4:
                return <VerificationPaymentStatus />;
              default:
                return <Amount />;
            }
          })()}

          <S.ContainerButtons>
            <S.Cancel
              secondary
              onClick={() => (step > 1 ? setStep(step - 1) : close())}
            >
              {step > 1 ? 'Voltar' : 'Cancel'}{' '}
            </S.Cancel>
            <S.Continue
              noActive={step === 4}
              onClick={() => (step < 4 ? setStep(step + 1) : null)}
            >
              Continue
            </S.Continue>
          </S.ContainerButtons>
        </S.Content>
      </Card>
    </S.RechargeContainer>
  );
};
