import styled, { css } from 'styled-components';

export const Language = styled.div`
  position: relative;
`;

export const Content = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Icon = styled.img<{ cursor?: string }>`
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `}
`;

export const Text = styled.h2`
  line-height: 15px;
  font-family: 'Mulish';
  color: ${(props) => props.theme.palette.title.default};
  font-size: ${(props) => props.theme.typography.fontSizeBold - 2.3}rem;
`;

export const FloatingDescription = styled.p`
  top: 10px;
  left: 65px;
  opacity: 0;
  padding: 7px 11px;
  position: absolute;
  width: max-content;
  border-radius: 5px;
  letter-spacing: 1px;
  font-family: 'Poppins';
  background: #77798b;
  transition: opacity 0.8s ease-out;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSize - 0.6}rem;
  ::after {
    width: 0;
    height: 0;
    left: -5px;
    top: 8.5px;
    content: '';
    clear: both;
    position: absolute;
    border-right: 5px solid #77798b;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
`;
