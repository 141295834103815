export const ENDPOINTS = {
  user: {
    postTeam: 'teams',
    getTeam: 'teams',
    postGame: 'games',
    getUser: 'usuarios',
    putUserData: (userId) => `usuarios/${userId}/personal_data`,
    postUser: 'usuarios/cadastro',
    getPlatforms: 'games/platforms',
    getTransactions: 'financial/entries',
    putTeam: (teamId) => `teams/${teamId}`,
    deleteTeam: (teamId) => `teams/${teamId}`,
    validateImg: 'image-validator/validate',
    getBalance: 'usuarios/balance-valuation',
    getUserBalance: 'usuarios/balance',
    getUploadUrl: 'image-validator/get-upload-url',
    getUserUploadUrl: 'usuarios/get-upload-url',
    getNotifications: 'notifications',
    putNotificationsRead: `notifications`,
    postFinancePassword: 'usuarios/financial-password',
    userNftOwnership: (nftId) => `usuarios/nft_ownership?nft_id=${nftId}`
  },
  battle: {
    globalBattles: (type, page, gameId, platformId, selectedTeamId) =>
      `battles?type=${type}&pagination_page=${page}&pagination_perPage=10&game_id=${gameId}&platform_id=${platformId}&selected_team_id=${selectedTeamId}`,
    createGlobalBattle: (type) => `battles/global/${type}`,
    createFriendBattle: (type) => `battles/friend/${type}`,
    acceptBattleAlone: (battle_id) => `battles/${battle_id}/invite/accept/`,
    rejectBattleAlone: (battle_id) => `battles/${battle_id}/invite/reject/`,
    myBets: 'battles/me',
    battlesHistory: 'usuarios/battles/history',
    removeBattle: (battleId) => `battles/${battleId}`,
    aGlobalBattleAlone: (battleId) => `battles/${battleId}`,
    currentBattle: 'usuarios/battle'
  },
  teams: {
    getUploadUrl: 'teams/get-upload-url'
  },
  games: {
    getGames: 'games?pagination_perPage=50',
    getUploadUrl: 'games/get-upload-url'
  },
  friends: {
    getFriends: (type, teamId) =>
      `friends?type=${type}${teamId ? `&selected_team_id=${teamId}` : ''}`,
    getFriendsRequest: 'friends/request',
    postFriendsRequest: 'friends/request',
    putFriend: (friendId) => `friends/request/${friendId}`,
    deleteFriend: (friendId) => `friends/${friendId}`
  },
  tournament: {
    getTournament: (type) => `tournaments?type=${type}`,
    createTournament: 'tournaments',
    getMyListTournaments: 'usuarios/tournaments',
    getTournamentId: (tournamentId) => `tournaments/${tournamentId}`,
    postTournamentId: (tournamentId) => `tournaments/${tournamentId}/register`,
    getTournaments: (type) => `tournaments?type=${type}`,
    setWinner: (battleId) => `battles/${battleId}/set-winner`,
    acceptTournamentBattle: (tournamentId) =>
      `tournaments/${tournamentId}/ready_player`
  },
  chat: {
    getMessages: (battleId) => `battles/${battleId}/chat`,
    sendMessage: (battleId) => `battles/${battleId}/chat`
  },
  blockchain: {
    postRecharge: 'blockchain/recharge',
    postWithdraw: 'blockchain/withdraw',
    sendTransaction: 'financial/transfer',
    postbindsWallet: 'blockchain/binds-wallet',
    getInfo: (wallet) => `blockchain/matchbet-info?user_wallet=${wallet}`,
    getWithdraw: (wallet) => `blockchain/withdraw-info?user_wallet=${wallet}`
  },
  ranking: {
    getGlobalRanking: (gameId) => `usuarios/ranking?game_id=${gameId}`,
    getYourHistory: 'usuarios/battle-historic'
  },
  nft_store: {
    getNftStoreList: () => 'nft-store',
    postNftStorePurchase: () => 'nft-store/purchase'
  },
  matchbet_store: {
    getStoreItems: '/matchbet-store',
    postGateWay: '/matchbet-store/purchase'
  },
  error: {
    getErrors: 'enums'
  }
};
