export const acceptBattleSchema = {
  password: {
    required: 'A senha é obrigatória!',
    min: 'A senha deve ter pelo menos 6 caracteres'
  }
};

export const createABetModalSchema = {
  amount: {
    required: 'O campo de quantidade é obrigatorio!',
    min: 'A batalha deve ter um valor maior que 0'
  },
  password: {
    required: 'A senha é um campo obrigatório'
  }
};
