/* eslint-disable @typescript-eslint/no-explicit-any */
const AUTH_SESSION_ID = 'matchbet-auth';

export const saveAuthetification = (state: any): void => {
  window.sessionStorage.setItem(AUTH_SESSION_ID, JSON.stringify(state));
};

export const getAuthetification = (): any | null => {
  const data: string | null = window.sessionStorage.getItem(AUTH_SESSION_ID);
  return data ? JSON.parse(data) : null;
};

export const removeAuthetification = (): void => {
  window.sessionStorage.removeItem(AUTH_SESSION_ID);
};

const MENU_SESSION_ID = 'matchbet-menu';

export const getMenuSelected = (): string => {
  const menu: string | null = window.sessionStorage.getItem(MENU_SESSION_ID);
  return menu || 'account';
};

export const setMenuSelected = (menu: string): void => {
  window.sessionStorage.setItem(MENU_SESSION_ID, menu);
};

export const resetMenu = (): void => {
  window.sessionStorage.removeItem(MENU_SESSION_ID);
};
