/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIcon } from 'assets/img/icons';
import { GeneralButton } from 'components/elements/GeneralButton';
import IconsContainer from 'components/modules/IconsContainer';
import { useModal } from 'components/modules/Modal';
import { Battle } from 'models/Battle';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { servicesApis } from 'service/service.api';
import { Actions } from 'state/battle/@types/actions';
import { BattleContext } from 'state/battle/state';
import { t } from 'translate/i18n';
import { utils } from 'ethers';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import { Props } from './@types';
import * as S from './BetCreatedStyled';

const BetCreated = ({ currentPassword, createABetError }: Props) => {
  const [loading, setLoading] = useState(false);
  const { setUserActive } = useContentUserActive();
  const translationString = 'components.battle.betCreated';

  const navigate = useNavigate();
  const { state, dispatch } = useContext(BattleContext);

  const { closeModal } = useModal();

  const handleClose = () => {
    setLoading(false);
    closeModal();
  };

  const handleCreate = async () => {
    setLoading(true);

    const battle: Battle = {
      selected_game: state.battle.game.entity_id,
      selected_platform: state.battle.platform.entity_id,
      amount: utils.parseEther(`${state.battle.amount}`).toString(),
      financial_password: currentPassword,
      selected_team_id: state.battle.teamSelected?.entity_id || '',
      nickname: state.battle.nickname,
      opponent_id: '',
      opponent_user_id: state.battle.challenger.friend?.entity_id || '',
      opponent_team_id: ''
    };

    const toogleNewBetModal = (): void => {
      dispatch({
        type: Actions.TOGGLE_CREATE_A_BET_MODAL
      });
    };
    try {
      await servicesApis.battle.createBattle(battle, 'tournament');
      handleClose();
      navigate('/matchbet/battle');
      setUserActive({
        registrationSuccessful: true,
        message: t(`${translationString}.messages.successfulRegistration`)
      });
    } catch (error: any) {
      if (error.message) {
        switch (error.message) {
          case 'invalid_financial_password':
            handleClose();
            toogleNewBetModal();
            createABetError(t(`${translationString}.invalidFinancialPassword`));
            break;
          default:
            handleClose();
            toogleNewBetModal();
            createABetError(t(`${translationString}.unknownError`));
        }
      }
      setLoading(false);
    }
  };

  return (
    <S.Container>
      <S.Header>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
      </S.Header>
      <S.Battle>
        <S.Img
          game
          src={state.battle.game.image_url}
          alt={t(`${translationString}.gameImage.alt`)}
        />
        <S.ContainerDescription>
          <S.GameTitle small>{state.battle.game.name}</S.GameTitle>
          <S.Text small>{t(`${translationString}.platforms`)}</S.Text>
          <S.Platforms>
            {state.battle.platform ? (
              <IconsContainer platforms={[state.battle.platform]} />
            ) : null}
          </S.Platforms>
          <S.ContentDescription>
            <S.Text small uppercase>
              {t(`${translationString}.betValue`)}
            </S.Text>
            <S.Value>{state.battle.amount}</S.Value>
            <S.Coin
              src={getIcon('moedaOuro')}
              alt={t(`${translationString}.coinIcon.alt`)}
            />
          </S.ContentDescription>
        </S.ContainerDescription>
      </S.Battle>

      <S.ContainerButtons>
        <GeneralButton
          transparent
          variant="primary"
          size="medium"
          onClick={() => handleClose()}
          id="cancel-bet"
        >
          {t(`${translationString}.buttonCancel`)}
        </GeneralButton>
        <GeneralButton
          onClick={() => handleCreate()}
          variant="primary"
          size="medium"
          isLoading={loading}
          id="create-bet"
        >
          {t(`${translationString}.buttonCreate`)}
        </GeneralButton>
      </S.ContainerButtons>
      <S.ContainerSmallParagraph>
        <S.Text center small>
          {t(`${translationString}.description`)}
        </S.Text>
      </S.ContainerSmallParagraph>
    </S.Container>
  );
};

export default BetCreated;
