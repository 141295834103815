import { useState } from 'react';
import { t } from 'translate/i18n';

import images from 'assets/img';
import { useTournament } from 'state/tournament/hooks';
import { useCreateTournament } from 'pages/CreateTournament/hooks/useCreateTournament';
import { Selector, GeneralButton } from 'components/elements';
import { useModal } from 'components/modules/Modal';

import { TOURNAMENT_SIZE_TYPE } from '../../constants';
import { SelectPlatformModal } from '../SelectPlatformModal';

import { TournamentSizeModalProps } from './@types';

import * as S from './TournamentSizeModalStyled';
import { SelectTournamentDateModal } from '../SelectTournamentDateModal';

const TournamentSizeModal: React.FC<TournamentSizeModalProps> = ({
  platforms,
  initialTournamentSize = 0
}: TournamentSizeModalProps) => {
  const translationString = 'components.createTournament';
  const { playAlone } = useCreateTournament();

  const translationControl = playAlone ? 'Players' : 'Teams';

  const typesOfTournamentSize = [
    {
      name: t(
        `${translationString}.tournamentSizeModal.typesOfTournamentSize.four${translationControl}`
      ),
      key: TOURNAMENT_SIZE_TYPE.FOUR_TEAMS,
      image: images.fourTeamTournament
    },
    {
      name: t(
        `${translationString}.tournamentSizeModal.typesOfTournamentSize.eight${translationControl}`
      ),
      key: TOURNAMENT_SIZE_TYPE.EIGHT_TEAMS,
      image: images.eightTeamTournament
    },
    {
      name: t(
        `${translationString}.tournamentSizeModal.typesOfTournamentSize.sixTeen${translationControl}`
      ),
      key: TOURNAMENT_SIZE_TYPE.SIXTEEN_TEAMS,
      image: images.sixteenTeamTournament
    },
    {
      name: t(
        `${translationString}.tournamentSizeModal.typesOfTournamentSize.thirtyTwo${translationControl}`
      ),
      key: TOURNAMENT_SIZE_TYPE.THIRTY_TWO_TEAMS,
      image: images.thirtytwoTeamTournament
    }
  ];

  const getInitialIndex = (): number => {
    const hasInitialValue = initialTournamentSize !== 0;
    if (hasInitialValue) {
      const indexInitial = typesOfTournamentSize?.findIndex(
        (item) => item.key === initialTournamentSize
      );
      return indexInitial;
    }
    return 0;
  };

  const [indexTournament, setIndexTournament] = useState<number>(
    getInitialIndex()
  );
  const { closeAndShow } = useModal();
  const { setSizeTournament } = useTournament();

  const handlePrev = () => {
    setIndexTournament(indexTournament - 1);
  };

  const handleNext = () => {
    setIndexTournament(indexTournament + 1);
  };

  const returnToPlataformSelector = () => {
    closeAndShow(
      <SelectPlatformModal platforms={platforms} />,
      '475px',
      true,
      '35px 42px 43px'
    );
  };

  const handleSizeTournament = () => {
    setSizeTournament(typesOfTournamentSize[indexTournament].key);
    closeAndShow(<SelectTournamentDateModal />, '454px', true, '20px');
  };

  return (
    <S.Container>
      <S.Title>{t(`${translationString}.tournamentSizeModal.title`)}</S.Title>
      <S.Text>
        {t(`${translationString}.tournamentSizeModal.description`)}
      </S.Text>
      <S.Img src={typesOfTournamentSize[indexTournament].image} alt="teste" />
      <Selector
        list={typesOfTournamentSize}
        prev={handlePrev}
        next={handleNext}
        itemSelected={
          typesOfTournamentSize[indexTournament] || {
            name: 'NAN',
            entity_id: 'NAN',
            image_url: 'NAN'
          }
        }
      />
      <S.ContainerButtons>
        <GeneralButton
          onClick={() => returnToPlataformSelector()}
          transparent
          variant="primary"
          size="medium"
        >
          {t(`${translationString}.tournamentSizeModal.secondaryButton`)}
        </GeneralButton>

        <GeneralButton
          variant="primary"
          size="medium"
          onClick={() => handleSizeTournament()}
        >
          {t(`${translationString}.tournamentSizeModal.primaryButton`)}
        </GeneralButton>
      </S.ContainerButtons>
    </S.Container>
  );
};

export default TournamentSizeModal;
