/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header } from 'components/modules/Header';
import { i18n } from 'translate/i18n';
import { SideBar } from 'components/modules/SideBar';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { servicesApis } from 'service/service.api';
import { Actions } from 'state/user/@types/actions';
import { getErrorStringName } from 'utils/errorHandling';
import { Actions as BattleActions } from 'state/battle/@types/actions';
import { UserContext } from 'state/user/state';
import { Actions as ActionsNotifications } from 'state/profileNotifications/@types/actions';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { NotificationContext } from 'state/notification/state';
import { useErrorCodes } from 'state/errorCodes/hooks';
import { ProfileNotificationsContext } from 'state/profileNotifications/state';
import { BattleRequested } from 'models/Battle';
import { BattleContext } from 'state/battle/state';
import { UserProfile } from './components/UserProfile';
import * as S from './MatchbetStyled';

export const Matchbet = ({ children }: { children: any }) => {
  const { state, dispatch } = useContext(UserContext);
  const { dispatch: dispatchNotifications } = useContext(
    ProfileNotificationsContext
  );
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const { dispatch: battleDispatch } = useContext(BattleContext);
  const { setErrorCode } = useErrorCodes();

  const navigate = useNavigate();

  const getUser = async () => {
    const response = await servicesApis.user.getUser();
    if (response?.status === 'success' && state?.user?.updateState) {
      dispatch({
        type: Actions.SET_USER_DATA,
        payload: {
          ...state.user,
          updateState: false,
          email: response.data.email,
          nickname: response.data.apelido,
          phone: response.data.telefone,
          entityId: response.data.entity_id,
          birthday: response.data.nascimento,
          image_url: response.data.image_url,
          hasFinancialPassword: response.data.has_financial_password
        }
      });

      dispatch({
        type: Actions.SET_USER_LINKREF,
        payload: {
          linkRef: response.data.invitation_code
        }
      });
    } else if (response.router) {
      navigate(response.router, { replace: true });
    }
  };

  useEffect(() => {
    getUser();
  }, [state?.user?.updateState]);

  const getUserBalance = async () => {
    const response = await servicesApis.user.getUserBalance();

    dispatch({
      type: Actions.SET_USER_BALANCE,
      payload: {
        balance: response.data.available_balance,
        pending_balance: response.data.pending_balance
      }
    });
  };

  const getErrors = async () => {
    const response = await servicesApis.error.getErrors();
    setErrorCode(response.data.ErrorCodes);
  };

  let pendingNotificationsReq = false;

  const getNotifications = async () => {
    if (pendingNotificationsReq) return;

    pendingNotificationsReq = true;

    dispatchNotifications({
      type: ActionsNotifications.SET_LOADING,
      payload: { loading: true }
    });

    const response = await servicesApis.user.getNotifications();

    pendingNotificationsReq = false;

    dispatchNotifications({
      type: ActionsNotifications.SET_LOADING,
      payload: { loading: false }
    });

    if (response) {
      dispatchNotifications({
        type: ActionsNotifications.SET_NOTIFICATIONS,
        payload: { notifications: response }
      });
    }
  };

  const poolingNotifications = (): NodeJS.Timer => {
    const SECOND = 1000;

    const interval = setInterval(() => {
      getNotifications();
    }, 10 * SECOND);

    return interval;
  };
  let pendingBattleReq = false;
  const getCurrentBattle = async (): Promise<void> => {
    if (pendingBattleReq) return;
    pendingBattleReq = true;
    try {
      const battle: BattleRequested =
        await servicesApis.battle.getCurrentBattle();

      if (!battle.entity_id) {
        battleDispatch({
          type: BattleActions.RESET_CURRENT_BATTLE
        });
        return;
      }

      const payload = {
        currentBattle: {
          entity_id: battle.entity_id,
          inProgress: true,
          selectedGame: {
            image_url: battle.selected_game.image_url
          },
          playerOne: {
            name: battle.player1.name,
            image_url: battle.player1.image_url
          },
          playerTwo: {
            name: battle.player2.name,
            image_url: battle.player2.image_url
          }
        }
      };
      battleDispatch({
        type: BattleActions.SET_CURRENT_BATTLE,
        payload
      });
    } catch (error: any) {
      if (!error.response) {
        return;
      }

      const errorCode = await getErrorStringName(
        error.response.data.error_code
      );

      const message = i18n.t(`messages.${errorCode}`);
      notificationDispatch({
        type: NotificationActions.OPEN,
        payload: {
          message
        }
      });
    } finally {
      pendingBattleReq = false;
    }
  };

  const poolingCurrentBattle = (): NodeJS.Timer => {
    const SECOND = 1000;

    const interval = setInterval(async () => {
      await getCurrentBattle();
    }, 5 * SECOND);

    return interval;
  };

  const onDismountComponent = (intervals: NodeJS.Timer[]): void => {
    intervals.forEach((interval: NodeJS.Timer) => clearInterval(interval));
  };

  useEffect(() => {
    getUserBalance();
    getCurrentBattle();
    getErrors();
    const interval1 = poolingNotifications();
    const interval2 = poolingCurrentBattle();

    return () => onDismountComponent([interval1, interval2]);
  }, []);

  return (
    <S.MatchbetContainer>
      <Header />
      <S.PageActive show>{children}</S.PageActive>
      <SideBar />
      <UserProfile />
    </S.MatchbetContainer>
  );
};
