export const formatMoney = (amount: string) => {
  const hasDot = amount.includes('.');

  if (!hasDot) return amount;

  const [integer, decimal] = amount.split('.');
  const integerFormatted = (+integer).toLocaleString('pt-BR');
  const firstTwoDecimals = decimal.slice(0, 2);
  const sumOfDecimalsAfterTwoFirst = decimal
    .slice(3)
    .split('')
    .map((num) => +num)
    .reduce((sum, num) => sum + num, 0);
  const hasMoreDecimals = sumOfDecimalsAfterTwoFirst > 0;
  return `${integerFormatted},${firstTwoDecimals}${
    hasMoreDecimals ? '...' : ''
  }`;
};

export const formattedPrice = (price: number) =>
  (price / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
