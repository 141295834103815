import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { Actions } from 'state/user/@types/actions';
import { UserContext } from 'state/user/state';
import { t } from 'translate/i18n';
import { Props } from '../../@types';
import { formSchema } from './UserWalletTransferSchema';
import * as S from './UserWalletTransferStyled';

export const UserWalletTransfer = ({ actionCloseModal = () => {} }: Props) => {
  const { dispatch } = useContext(UserContext);
  const translationString = 'components.transfer';
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: ({ email }) => {
      dispatch({
        type: Actions.SET_WALLET_DATA,
        payload: {
          Step: 2,
          emailTransfer: email
        }
      });
    },
    validationSchema: formSchema
  });

  return (
    <S.UserWalletTransferContainer>
      <S.Content column padding="24px">
        <S.SubTitle>
          {t(`${translationString}.userWalletTransfer.title`)}
        </S.SubTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput
            error={formik.touched.email && Boolean(formik.errors.email)}
          >
            <TextField
              id="email"
              type="email"
              label={t(`${translationString}.userWalletTransfer.placeholder`)}
              variant="outlined"
              inputProps={{
                form: {
                  autocomplete: 'off'
                }
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </S.ContainerInput>
          <S.Text>
            {t(`${translationString}.userWalletTransfer.description`)}
          </S.Text>
        </S.Form>
      </S.Content>
      <S.ContainerButtons>
        <S.Cancel secondary onClick={() => actionCloseModal()}>
          {t('buttons.back')}
        </S.Cancel>
        <S.Continue onClick={() => formik.handleSubmit()}>
          {t('buttons.continue')}
        </S.Continue>
      </S.ContainerButtons>
    </S.UserWalletTransferContainer>
  );
};
