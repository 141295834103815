import { DefaultTheme } from 'styled-components';

const Theme: DefaultTheme = {
  palette: {
    text: {
      grey: '#898989',
      dark: '#000000',
      white: '#ffffff',
      gray: '#d9d9d9',
      light: '#ffffff99',
      regular: '#91DEFB',
      disable: '#BEC5CF',
      lightBlue: '#48B9FE'
    },
    title: {
      default: '#FFFFFF'
    },

    button: {
      default: '#FFB620',
      disable: '#EAEBEC'
    },
    background: {
      light: '#ffffff',
      orange: '#D9B36A',
      regular: '#DCE4E4',
      default: '#F5F4F6',
      green: '#D9B36A',
      lightBlur: '#FFFFFF1A',
      purple: '#A53AFC',
      difusePurple: '#3329504D',
      lightPurple: '#9f71fc',
      primary: '#FFB620'
    },

    transaction: {
      white: '#fff',
      green: '#00EC42',
      yellow: '#FFB620',
      red: '#9C0000'
    },

    error: {
      main: '#d32f2f'
    },
    success: {
      main: '#6EBA19'
    },
    warning: {
      main: '#121A3D'
    }
  },
  typography: {
    fontSize: 1.6,
    fontSizeBold: 3.5,
    fontWeightBold: 700,
    fontWeightBolder: 900,
    htmlFontSize: '10px',
    fontWeightLight: 400,
    fontSizeRegular: 2.2,
    fontWeightRegular: 500
  }
};

export default Theme;
