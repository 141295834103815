import React from 'react';
import { t } from 'translate/i18n';
import { v1 as uuidv1 } from 'uuid';

import { GeneralButton } from 'components/elements/GeneralButton';
import { Card } from 'components/modules';
import { Friend } from 'models/Friends';

import images from 'assets/img';

import { Props } from './@types';
import { Empty } from './components/Empty';

import * as S from './ListStyled';

const List: React.FC<Props> = ({
  data,
  listRef = undefined,
  cardRef = undefined,
  empty = false,
  removeFriend = () => {}
}) => {
  const translationString = 'components.friends.friendCard';

  const renderFriendCard = (
    { apelido, image_url, entity_id }: Friend,
    index: number
  ): React.ReactElement => {
    return (
      <S.Card
        id="card"
        ref={index === 0 ? cardRef : undefined}
        onClick={() => {}}
        key={uuidv1()}
      >
        <Card
          width={window.innerWidth < 486 ? 'auto' : '187px'}
          height="min-content"
          padding="16px 12px"
          background={
            empty ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.1)'
          }
        >
          <S.ContentCard hide={empty}>
            <S.Picture src={image_url || images.defaultImage} />
            <S.Name>{apelido}</S.Name>
            <GeneralButton
              variant="primary"
              size="small"
              transparent
              onClick={() => {
                removeFriend(entity_id);
              }}
            >
              {t(`${translationString}.remove`)}
            </GeneralButton>
          </S.ContentCard>
        </Card>
      </S.Card>
    );
  };

  return (
    <S.Container id="list" ref={listRef}>
      {empty ? <Empty /> : null}
      {data.map(renderFriendCard)}
    </S.Container>
  );
};

export default List;
