import { EntityPlatform } from 'models/Games';
import { ReactElement } from 'react';
import { v1 as uuidv1 } from 'uuid';
import * as S from './IconsContainerStyled';

const IconsContainer = ({
  platforms,
  readOnly
}: {
  platforms: EntityPlatform[];
  readOnly?: boolean;
}) => {
  const renderIcons = (platform: EntityPlatform): ReactElement => {
    return (
      <S.PlatformIcon
        key={uuidv1()}
        readOnly={readOnly}
        src={platform.image_url}
      />
    );
  };

  return <S.IconsContainer>{platforms.map(renderIcons)}</S.IconsContainer>;
};

export default IconsContainer;
