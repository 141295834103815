import styled from 'styled-components';

export const Button = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #392e4f;
  border: 0;
  position: relative;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 26px;
  height: 26px;
  object-fit: contain;
`;

export const Badge = styled.div`
  padding: 2px;
  background-color: ${({ theme }) => theme.palette.background.primary};
  position: absolute;
  top: 10px;
  left: 23px;
  border-radius: 2px;
  color: ${({ theme }) => theme.palette.text.white};
  font-family: 'Roboto';
  font-size: 1.2rem;
  font-weight: 800;
`;
