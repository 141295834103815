import { useFormik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';

import { useModal } from 'components/modules/Modal';

import { PropsUseEventModal } from './@types';

export const useEventModal = ({
  onSubmit,
  data,
  initialValues,
  validationSchema
}: PropsUseEventModal) => {
  const [loading] = useState(false);
  const { open, closeModal } = useModal();

  const formik = useFormik<FormikValues>({
    initialValues,
    onSubmit: (values, formikHelpers) =>
      onSubmit({ ...values, ...data }, formikHelpers),
    validationSchema
  });

  useEffect(() => {
    if (!open) {
      const SECOND = 1000;

      const timeout = setTimeout(() => {
        formik.resetForm();
        clearTimeout(timeout);
      }, SECOND);
    }
  }, [open]);

  return {
    formik,
    loading,
    closeModal
  };
};
