import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  margin-bottom: 12px;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.error &&
    css`
      .Mui-error {
        color: #d32f2f;
      }
      .Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: #d32f2f !important;
      }

      .MuiFormHelperText-root {
        margin-bottom: 10px;
      }

      .Mui-focused {
        color: #d32f2f !important;
      }

      fieldset > legend span {
        color: #d32f2f !important;
      }
    `};

  .MuiFormHelperText-root {
    font-size: 1.2rem;
    margin-top: 5px;
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
