import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p<{ margin?: string }>`
  width: auto;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  justify-content: space-between;

  ${({ margin, theme }) => css`
    margin: ${margin};
    color: ${theme.palette.text.white};
    font-weight: ${theme.typography.fontWeightLight};
    font-size: ${theme.typography.fontSize - 0.4}rem;
  `}
`;

export const Title = styled.h2<{
  size?: string;
  margin?: string;
}>`
  font-family: 'Russo One';
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.size}rem;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;
