import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProfileNotificationsContext } from 'state/profileNotifications/state';

import { NotificationButton } from './components/NotificationButton';
import { NotificationList } from './components/NotificationList';
import * as C from './NotificationsStyles';

const Notifications: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const { state } = useContext(ProfileNotificationsContext);

  const html: HTMLHtmlElement | null = useMemo(
    () => document.querySelector('html'),
    []
  );

  const toggleSmooth = (): void => {
    const isSmooth = html?.style.scrollBehavior === 'smooth';

    if (isSmooth) {
      html.style.scrollBehavior = 'auto';
    } else if (html) {
      html.style.scrollBehavior = 'smooth';
    }
  };

  const disableScroll = (): void => {
    toggleSmooth();
    window.onscroll = () => window.scrollTo(0, 0);
  };

  const enableScroll = (): void => {
    toggleSmooth();
    window.onscroll = () => {};
  };

  const toggleScroll = (enable: boolean): void => {
    if (enable) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  const toggleList = (): void => {
    setShow(!show);
    toggleScroll(show);
  };

  useEffect(() => {
    const addSmoothToHTMLForToggleSmothLogic = (): void => {
      if (html) html.style.scrollBehavior = 'smooth';
    };

    const removeSmoothFromInlineHTMLStyles = (): void => {
      if (html) html.style.scrollBehavior = '';
    };

    const onComponentDidMount = (): void => {
      addSmoothToHTMLForToggleSmothLogic();
    };

    const onComponentWillUmount = (): void => {
      removeSmoothFromInlineHTMLStyles();
    };

    onComponentDidMount();

    return () => onComponentWillUmount();
  }, []);

  return (
    <C.Container>
      <NotificationButton onClick={toggleList} />
      <NotificationList show={show} data={state.notifications || []} />
    </C.Container>
  );
};

export default Notifications;
