import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFormik } from 'formik';
import React, { useState, useContext } from 'react';
import { t } from 'translate/i18n';
import { UserContext } from 'state/user/state';
import { servicesApis } from 'service/service.api';
import { NotificationContext } from 'state/notification/state';
import { Actions } from 'state/notification/@types/actions';
import { Actions as UserActions } from 'state/user/@types/actions';
import { DropdownComponents, FinancePasswordType } from '../../../@types';
import { financePasswordSchema } from '../../../UserProfileSchema';
import { Props } from '../../@types';
import Form from '../../Form';
import SaveButton from '../../SaveButton';
import TextField from '../../TextField';
import * as S from './FinancePasswordStyled';

const FinancePassword: React.FC<Props> = ({ active, setActive }) => {
  const [load, setLoad] = useState(false);
  const { dispatch: dispatchNotification } = useContext(NotificationContext);
  const { state, dispatch } = useContext(UserContext);
  const formik = useFormik<FinancePasswordType>({
    initialValues: {
      showCurrentPassword: false,
      newPassword: '',
      currentPassword: '',
      confirmPassword: ''
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit,

    validationSchema: financePasswordSchema
  });

  const translationSchema =
    'components.userProfile.schemas.financePasswordSchema';

  async function onSubmit(data: FinancePasswordType): Promise<void> {
    setLoad(true);

    try {
      await servicesApis.user.postFinancePassword(
        data.currentPassword,
        data.newPassword
      );
      formik.resetForm();
      formik.setFieldError(
        'currentPassword',
        t(`${translationSchema}.submit.success`)
      );
      dispatchNotification({
        type: Actions.OPEN,
        payload: {
          message: t(`${translationSchema}.submit.success`)
        }
      });
      dispatch({
        type: UserActions.SET_USER_DATA,
        payload: {
          ...state.user,
          hasFinancialPassword: true
        }
      });
      setLoad(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoad(false);

      if (error.message === 'invalidPassword') {
        formik.setFieldError(
          'currentPassword',
          t(`${translationSchema}.submit.wrongPassword`)
        );
      }
    }
  }

  function onClickOverMenu(): void {
    setActive({
      active: !active,
      component: DropdownComponents.FINANCE_PASSWORD
    });
  }

  const currentPasswordError: boolean =
    !!formik.touched.currentPassword && Boolean(formik.errors.currentPassword);

  const newPasswordError: boolean =
    !!formik.touched.newPassword && Boolean(formik.errors.newPassword);

  const numberOfErrors: number = Object.values(formik.errors).length || 0;

  const translationComponent = 'components.userProfile.menus.financePassword';

  return (
    <S.DropDown active={active} numberOfErrors={numberOfErrors}>
      <S.Menu onClick={() => onClickOverMenu()}>
        <S.Title>{t(`${translationComponent}.title`)}</S.Title>
        <ArrowForwardIosIcon />
      </S.Menu>

      <Form onSubmit={formik.handleSubmit}>
        <S.Subtitle>{t(`${translationComponent}.description`)}</S.Subtitle>
        {state.user.hasFinancialPassword ? (
          <TextField
            id="currentPassword"
            type="password"
            label={t(`${translationComponent}.currentPasswordPlaceholder`)}
            variant="outlined"
            autoComplete="off"
            sx={{
              '& legend': {
                fontSize: '1.2rem'
              }
            }}
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={currentPasswordError}
            helperText={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
          />
        ) : (
          <></>
        )}
        <TextField
          id="newPassword"
          type="password"
          label={t(`${translationComponent}.newPasswordPlaceholder`)}
          variant="outlined"
          autoComplete="off"
          sx={{
            '& legend': {
              fontSize: '1.2rem'
            }
          }}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={newPasswordError}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />

        <TextField
          id="confirmPassword"
          type="password"
          label={t(`${translationComponent}.confirmPasswordPlaceholder`)}
          variant="outlined"
          autoComplete="off"
          sx={{
            '& legend': {
              fontSize: '1.2rem'
            }
          }}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={newPasswordError}
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />

        <SaveButton loading={load} onClick={formik.handleSubmit} />
      </Form>
    </S.DropDown>
  );
};

export default FinancePassword;
