import { TextField } from '@mui/material';
import styled from 'styled-components';

export const Field = styled(TextField)`
  margin: 0;
  width: 100%;

  .MuiInputLabel-root {
    color: #ffffff61;
    font-size: 1.6rem;
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.6);
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff61;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff61;
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }
      color: rgba(255, 255, 255, 0.6);
      font-size: 1.6rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff61;
    }

    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff61;
      }
    }

    .css-1in441m {
      font-size: 1.2rem;
    }
  }
`;

export const Container = styled.div`
  position: relative;
`;
