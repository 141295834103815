export const userProfile = {
  title: 'Profile',
  menus: {
    personalData: {
      title: 'Personal data',
      nameInputPlaceholder: 'Nickname',
      birthdayInputPlaceholder: 'Birthday',
      messages: {
        requestFailed: 'Data update failed, try again later.',
        requestSuccessfully: 'Data update completed successfully.'
      }
    },
    phoneNumber: {
      title: 'Phone number',
      currentNumberPlaceholder: 'Current number',
      newNumberPlaceholder: 'New number'
    },
    financePassword: {
      title: 'Financial password',
      description:
        'This password will be used to access financial functions of the platform, such as transfers and deposits.',
      currentPasswordPlaceholder: 'Current password',
      newPasswordPlaceholder: 'New password',
      confirmPasswordPlaceholder: 'Confirm password',
      verificationCodePlaceholder: 'Verification code',
      phoneVerificationCodePlaceholder: 'Phone verification code',
      getCodeLabel: 'Get code',
      helperGetCode: 'Enter the verification code sent to your e-mail.'
    },
    platformPassword: {
      title: 'Platform password',
      description: 'This password will be used to access the platform.',
      currentPasswordPlaceholder: 'Current password',
      newPasswordPlaceholder: 'New password',
      getCodeLabel: 'Get code',
      confirmPasswordPlaceholder: 'Confirm password'
    }
  }
};
