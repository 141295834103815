export const createBattle = {
  title: 'create a battle',
  auxiliarTitle: 'New Battle',
  description: {
    matchFormat:
      'Choose your match format, play alone or team up with your friends.',
    selectAGame:
      'Select a game to continue, you can choose a game from the platform or add a new one.',
    challenge: 'Challenge your friends or accept an open bet'
  },
  addButton: 'Add',
  newGlobalBet: 'New Global Battle',
  subtitle: 'Challenge a friend',
  search: {
    placeholder: 'Search'
  },
  platformFilter: {
    gamesFor: 'Games for',
    allGames: 'All Games',
    victoryValidation: 'Victory validation'
  },
  teamCard: {
    select: 'Select',
    challenge: 'Challenge',
    view: 'View'
  },
  card: {
    select: 'Select',
    openBets: 'open bets'
  },
  platforms: {
    onThePlatform: 'On the platform'
  },
  betAmount: {
    title: 'Bet amount',
    subTitle: 'Define the amount to be bet on the battle',
    amount: 'Bet amount',
    balance: 'Balance:',
    eyeIcon: {
      balance: {
        alt: 'See balance'
      },
      password: {
        alt: 'See password'
      }
    },
    password: 'Financial password',
    description:
      ' In case of games played in a team, the amount is deposited and withdrawn only from the user responsible for the team.',
    buttons: {
      cancel: 'Return',
      create: 'Create bet',
      next: 'Next'
    }
  },
  amountBetFilter: {
    title: 'Bet amount',
    lowestPlaceholder: 'Minimum bet',
    highPlaceholder: 'Maximum bet'
  },
  list: {
    challengePlayerWarning: "You don't have friends to challenge"
  },
  openGlobalBets: {
    title: 'Choose an Global Battle',
    card: {
      image: {
        alt: 'player avatar'
      },
      valueLabel: 'value for the bet',
      button: 'Accept',
      coin: {
        alt: 'coin'
      }
    },
    emptyList: {
      title: 'Open Global Battle',
      description: 'The Global Battle availables will appear here'
    }
  }
};
