import icons, { getIcon } from 'assets/img/icons';
import { LanguageSwitch } from 'components/elements/LanguageSwitch';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BattleContext } from 'state/battle/state';
import { FeatureControlContext } from 'state/featureControl/state';
import { Actions } from 'state/menu/@types/actions';
import { MenuContext } from 'state/menu/state';
import { i18n } from 'translate/i18n';
import { v4 as uuid } from 'uuid';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import type { Card } from './@types';
import * as S from './SideBarStyled';

const SideBar = () => {
  const [show] = useState(false);

  const navigate = useNavigate();
  const { state, dispatch } = useContext(MenuContext);
  const featureContext = useContext(FeatureControlContext);
  const { state: battle } = useContext(BattleContext);

  const isMenuAllowed = (item: Card): boolean => {
    const allowed: string[] = Object.keys(
      featureContext?.state.featureControl
    ).filter((key: string) => featureContext?.state.featureControl[key]);

    return allowed.includes(item.key);
  };

  const isMenuForOnlyInBattle = (item: Card): boolean => {
    const menuOnlyInBattle = ['chat'];

    if (battle.battle.currentBattle.inProgress) {
      return true;
    }

    return !menuOnlyInBattle.includes(item.key);
  };

  const cards: Card[] = [
    {
      key: 'tournaments',
      name: `${i18n.t('components.SideBar.Tournaments')}`,
      url: 'group_51',
      id: 'tournaments',
      navigate: () => navigate('/matchbet/tournaments')
    },
    {
      key: 'battle',
      name: `${i18n.t('components.SideBar.Battle')}`,
      url: 'group_55',
      id: 'battle',
      navigate: () => navigate('/matchbet/battle')
    },
    {
      key: 'globalRanking',
      name: `${i18n.t('components.SideBar.GlobalRanking')}`,
      url: 'group_54',
      id: 'globalRanking',
      navigate: () => navigate('/matchbet/globalRanking')
    },
    {
      key: 'friends',
      name: `${i18n.t('components.SideBar.Friends')}`,
      url: 'group_50',
      id: 'friends',
      navigate: () => navigate('/matchbet/friends')
    },
    {
      key: 'account',
      name: `${i18n.t('components.SideBar.MatchBetBanking')}`,
      url: 'group_16',
      id: 'account',
      navigate: () => navigate('/matchbet/banking')
    },
    {
      key: 'store',
      name: `${i18n.t('components.SideBar.NftStore')}`,
      url: 'group_52',
      id: 'store',
      navigate: () => navigate('/matchbet/store/nfts')
    },
    {
      key: 'buy_coins',
      url: 'group_53',
      id: 'buy_coins',
      name: `${i18n.t('components.SideBar.Store')}`,
      navigate: () => navigate('/matchbet/store/buyCoins')
    }
  ]
    .filter(isMenuAllowed)
    .filter(isMenuForOnlyInBattle);

  const selectMenu = (key: string): void => {
    dispatch({
      type: Actions.SET_MENU,
      payload: {
        selected: key
      }
    });
  };

  const onClickSideBarItem = (item: Card): void => {
    item.navigate();
    selectMenu(item.key);
  };

  return (
    <S.SideBarContainer show={show}>
      <S.MatchBetIcon
        marginTop="calc(-7vh - 100px)"
        src={getIcon('group_143')}
        alt="Icone do matchcash"
      />
      <S.ContainerCards>
        {cards.map((item: Card) => {
          return (
            <S.SideBarItem
              key={uuid()}
              onClick={() => onClickSideBarItem(item)}
              active={state.menu.selected === item.key}
              id={item.id}
            >
              <S.Icon src={icons[item.url]} alt="Icone do matchbet" />
              <S.FloatingDescription>{item.name}</S.FloatingDescription>
            </S.SideBarItem>
          );
        })}
        {/* <S.ContentArrow onClick={() => setShow(!show)} show={show}>
          <ArrowForwardIosIcon />
        </S.ContentArrow> */}
        <LanguageSwitch />
      </S.ContainerCards>
    </S.SideBarContainer>
  );
};

export default SideBar;
