import { getImage } from 'assets/img';
import { useCallback, useEffect, useState } from 'react';

import * as S from './ConnectWalletStyled';

/* Mocking injected e getIsTestnet */
const injected = {
  activate: () => Promise.reject(Error('Not implemented yet'))
};
const getIsTestnet = () => Promise.reject(Error('Not implemented yet'));
/* Mocking end */

export const ConnectWallet = () => {
  const [chainId, setChainId] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [wallet, setWallet] = useState('');

  const getAccount = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    injected.activate().then(async (value: any) => {
      setShowWallet(true);
      setWallet(value.account);
    });
  };
  const connectedWallet = useCallback(async () => {
    const isTestnet = await getIsTestnet();
    await setChainId(isTestnet);
    await getAccount();
  }, [setChainId]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(wallet);
    // eslint-disable-next-line no-console
    console.log(chainId);
    // eslint-disable-next-line no-console
    console.log(showWallet);
  }, [chainId, connectedWallet, showWallet, wallet]);
  return (
    <S.ConnectWalletContainer>
      <S.Content>
        <S.Icon src={getImage('Metamask')} alt="icone de demostrativo" />
        <S.Title>Connect your wallet</S.Title>
        <S.Text>
          {' '}
          To top up on MatchCash use your MetaMask digital wallet
        </S.Text>
        <S.Connect onClick={() => connectedWallet()}>Connect</S.Connect>
      </S.Content>
    </S.ConnectWalletContainer>
  );
};
