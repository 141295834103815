/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { ErrorProviderType } from './@types';
import errorReducer from './reducer';

const errorState: any = {
  errorCodes: []
};

export const ErrorContext = createContext(errorState);

const ErrorProvider: React.FC<ErrorProviderType> = ({ children }) => {
  const [state, dispatch] = useReducer(errorReducer, errorState);
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ErrorContext.Provider value={provider}>{children}</ErrorContext.Provider>
  );
};

export default ErrorProvider;
