import * as yup from 'yup';
import 'yup-phone';

const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required('Mandatory Current password field!'),
  emailVerificationCode: yup
    .string()
    .required('Mandatory E-mail verification code field!')
});
export { passwordSchema };
