import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { getImage } from 'assets/img';
import { CustomCircularProgress } from 'components/modules';
import { useFormik } from 'formik';
import { useContentUserActive } from 'pages/SignIn/Components/Form/useContent';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changePasswordForgotten, forgotPassword } from 'service/auth.service';
import { i18n } from 'translate/i18n';
import { ChangePasswordType, PasswordType, SendCodeType } from './@types';
import { changePasswordSchema, sendCodeSchema } from './ChangePasswordSchema';
import * as S from './ChangePasswordStyled';

const ChangePassword = () => {
  const [passwordShow, setPasswordShow] = useState<PasswordType>({
    showPassword: false,
    showConfirmPassword: false
  });
  const navigation = useNavigate();
  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState('');
  const [sendCode, setSendCode] = useState(true);
  const { setUserActive } = useContentUserActive();

  const handleClickShowPassword = (
    password: boolean,
    confirmPassword: boolean
  ) => {
    setPasswordShow({
      showPassword: password,
      showConfirmPassword: confirmPassword
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  async function sendchangePassword(values: ChangePasswordType) {
    setLoad(true);
    await changePasswordForgotten(email, values.accessToken, values.password)
      .then(() => {
        setUserActive({
          registrationSuccessful: true,
          message:
            'Your password has been successfully changed, now just sign in and enjoy the platform.'
        });
        navigation('/sign-up', {
          replace: true
        });
        setLoad(false);
      })
      .catch();
  }

  const ForgotPassword = useCallback(async (userEmail) => {
    setLoad(true);
    await forgotPassword(userEmail)
      .then(() => {
        setLoad(false);
        setEmail(userEmail);
        setSendCode(false);
      })
      .catch(() => {
        setLoad(false);
      });
  }, []);

  const formik = useFormik<ChangePasswordType>({
    initialValues: {
      email: '',
      password: '',
      accessToken: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      sendchangePassword(values);
    },
    validationSchema: changePasswordSchema
  });

  const formikSendCode = useFormik<SendCodeType>({
    initialValues: {
      email: ''
    },
    onSubmit: (value: SendCodeType) => {
      ForgotPassword(value.email);
    },
    validationSchema: sendCodeSchema
  });

  return (
    <S.ChangePasswordContainer height={sendCode ? '444px' : '551px'}>
      <S.Logo src={getImage('Logo')} alt="logo MatchCash" />
      <S.Title>{i18n.t('buttons.changePassword')}</S.Title>
      {sendCode ? (
        <S.ContainerSendCode>
          <S.Text>{i18n.t('messages.weWillSendYouACode')}</S.Text>
          <S.Form onSubmit={formikSendCode.handleSubmit}>
            <S.ContainerInput
              error={
                formikSendCode.touched.email &&
                Boolean(formikSendCode.errors.email)
              }
            >
              <TextField
                id="email"
                type="text"
                label="E-mail"
                variant="outlined"
                sx={{
                  '& legend': {
                    fontSize: '1.2rem'
                  }
                }}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off'
                  }
                }}
                value={formikSendCode.values.email}
                onChange={formikSendCode.handleChange}
                error={
                  formikSendCode.touched.email &&
                  Boolean(formikSendCode.errors.email)
                }
                helperText={
                  formikSendCode.touched.email && formikSendCode.errors.email
                }
              />
            </S.ContainerInput>
            <S.Register submit noActive={false}>
              {load ? (
                <Box>
                  <CustomCircularProgress />
                </Box>
              ) : (
                i18n.t('buttons.sendCode')
              )}
            </S.Register>
            <S.Cancel
              onClick={() =>
                navigation('/', {
                  replace: true
                })
              }
            >
              {i18n.t('buttons.cancel')}
            </S.Cancel>
          </S.Form>
        </S.ContainerSendCode>
      ) : (
        <S.ContainerChangePassword>
          <S.Form onSubmit={formik.handleSubmit}>
            <S.ContainerInput
              error={
                formik.touched.accessToken && Boolean(formik.errors.accessToken)
              }
            >
              <TextField
                id="accessToken"
                type="text"
                label="Code"
                variant="outlined"
                sx={{
                  '& legend': {
                    fontSize: '1.2rem'
                  }
                }}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off'
                  }
                }}
                value={formik.values.accessToken}
                onChange={formik.handleChange}
                error={
                  formik.touched.accessToken &&
                  Boolean(formik.errors.accessToken)
                }
                helperText={
                  formik.touched.accessToken && formik.errors.accessToken
                }
              />
            </S.ContainerInput>
            <S.ContainerInput
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              <S.ContainerPassword>
                <TextField
                  label="Password"
                  variant="outlined"
                  autoComplete="off"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  sx={{
                    '& legend': {
                      fontSize: '1.2rem'
                    }
                  }}
                  type={`${passwordShow.showPassword ? 'text' : 'password'}`}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    handleClickShowPassword(
                      !passwordShow.showPassword,
                      !!passwordShow.showConfirmPassword
                    )
                  }
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passwordShow.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </S.ContainerPassword>
            </S.ContainerInput>
            <S.ContainerInput
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              <S.ContainerPassword>
                <TextField
                  label="Confirm password"
                  variant="outlined"
                  autoComplete="off"
                  id="confirmPassword"
                  sx={{
                    '& legend': {
                      fontSize: '1.2rem'
                    }
                  }}
                  type={`${
                    passwordShow.showConfirmPassword ? 'text' : 'password'
                  }`}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
                <IconButton
                  aria-label="toggle Confirm Password visibility"
                  onClick={() =>
                    handleClickShowPassword(
                      !!passwordShow.showPassword,
                      !passwordShow.showConfirmPassword
                    )
                  }
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passwordShow.showConfirmPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </S.ContainerPassword>
            </S.ContainerInput>
            <S.ChangePassword submit noActive={false}>
              {load ? (
                <Box>
                  <CustomCircularProgress />
                </Box>
              ) : (
                'Change Password'
              )}
            </S.ChangePassword>
            <S.Cancel
              onClick={() =>
                navigation('/sign-up', {
                  replace: true
                })
              }
            >
              {i18n.t('buttons.cancel')}
            </S.Cancel>
          </S.Form>
        </S.ContainerChangePassword>
      )}
    </S.ChangePasswordContainer>
  );
};

export default ChangePassword;
