export const friends = {
  title: 'seus amigos',
  auxiliarTitle: 'seus amigos',
  description:
    'Jogue com os seus amigos, convide eles para a plataforma e ganhe matchcoins como recompensa',
  addButton: 'Convidar',
  searchPlaceholder: 'Pesquisar',
  friendCard: {
    remove: 'Remover'
  },
  inviteAFriend: {
    title: 'Convide um amigo',
    description: 'Adicione amigos, jogue e se divirta <br /> com eles',
    inputPlaceholder: 'e-mail',
    submitButton: 'enviar convite',
    error: {
      unknown_error: 'Erro desconhecido',
      friends_request_to_user_requester: `Não é possivel enviar um convite para si mesmo`,
      friends_already: 'Vocês já são amigos',
      friends_request_already_sent: 'Convite de amigo já enviado',
      friends_request_not_found: 'Convite de amigo não encontrado'
    }
  },
  solicitations: {
    betOnAddNewFriends: '+22 BET'
  },
  emptyList: {
    title: 'Convidar amigos',
    description: 'Jogue com os seus amigos, isso faz tudo ser mais divertido',
    button: 'Convidar'
  },
  popup: {
    friendDelete: 'Amigo deletado',
    friendDeleteError: 'Erro ao deletar amigo'
  }
};
