import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Icons from 'assets/img/icons';
import { Card } from 'components/modules';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { t, i18n } from 'translate/i18n';
import { Auth } from 'aws-amplify';
import { UserContext } from 'state/user/state';
import { ProfileSettingsContext } from 'state/profileSettings/state';
import { Actions } from 'state/profileSettings/@types/actions';
import { Actions as AuthActions } from 'state/auth/@types/actions';
import { Actions as BattleActions } from 'state/battle/@types/actions';
import { BattleContext } from 'state/battle/state';
import { AuthContext } from 'state/auth/state';
import { Actions as ActionsUser } from 'state/user/@types/actions';
import { Actions as ActionsMenu } from 'state/menu/@types/actions';

import { MenuContext } from 'state/menu/state';
import { Props } from './@types';

import * as S from './AccountButtonStyled';
import ProfileMenu from '../ProfileMenu';

const AccountButton: React.FC<Props> = ({ menuConfig, setMenuConfig }) => {
  const [showProfileSettings, setShowProfileSettings] =
    useState<boolean>(false);
  const { dispatch: settingsDispatch } = useContext(ProfileSettingsContext);
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { state: userState, dispatch: dispatchUser } = useContext(UserContext);
  const { dispatch: menuDispatch } = useContext(MenuContext);
  const { dispatch: battleDispatch } = useContext(BattleContext);

  const navigation = useNavigate();

  const logout = () => {
    Auth.signOut()
      .then(() => {
        authDispatch({
          type: AuthActions.USER_IS_UNSIGNED
        });
        menuDispatch({
          type: ActionsMenu.RESET_MENU
        });
        navigation('/sign-in', { replace: true });
        dispatchUser({
          type: ActionsUser.SET_USER_DATA,
          payload: {
            Step: 1,
            email: '',
            phone: '',
            Amount: '',
            Wallet: '',
            linkRef: '',
            nickname: '',
            entityId: '',
            birthday: '',
            image_url: '',
            updateState: true,
            hasFinancialPassword: false
          }
        });
        dispatchUser({
          type: ActionsUser.SET_USER_BALANCE,
          payload: {
            balance: 0
          }
        });
        dispatchUser({
          type: ActionsUser.SET_NFT_OWNERSHIP,
          payload: {
            ...userState.user,
            champBetOnwership: {
              lastCheckedTime: new Date('01/01/2000'),
              ownership: false
            }
          }
        });
        battleDispatch({
          type: BattleActions.RESET_CURRENT_BATTLE
        });
      })
      .catch();
  };

  const openSettingsProfile = () => {
    setShowProfileSettings(!showProfileSettings);
    setMenuConfig({
      menu: '',
      active: !menuConfig.active,
      positionMenu: ''
    });
    settingsDispatch({
      type: Actions.OPEN_MENU
    });
  };
  const onClickAccountButton = (): void => {
    setMenuConfig({
      menu: 'account',
      positionMenu: '45px',
      active: !menuConfig.active
    });
  };

  return (
    <>
      <Card
        width="72px"
        height="50px"
        background="#392E4F"
        padding="8px 3px 8px 8px"
      >
        <S.Content flex onClick={onClickAccountButton}>
          <S.UserAvatar>
            <S.Icon
              width={34}
              height={34}
              src={userState.user.image_url || Icons.user_icon}
              alt={t('components.header.accountButton.userIcon.alt')}
            />
          </S.UserAvatar>
          <KeyboardArrowDownRoundedIcon />
        </S.Content>
      </Card>

      <ProfileMenu
        position={menuConfig.positionMenu}
        show={menuConfig.menu === 'account' && menuConfig.active}
      >
        <S.AccountContainer show={menuConfig.menu === 'account'}>
          <div style={{ display: 'none' }}>
            <S.AccountContent>
              <S.Icon
                src={Icons.userOctagon}
                alt={t('components.header.profileMenu.userButton.alt')}
              />
              <S.TextAccount>
                {t('components.header.profileMenu.userButton.label')}
              </S.TextAccount>
            </S.AccountContent>
          </div>

          <S.AccountContent onClick={openSettingsProfile}>
            <S.Icon
              src={Icons.setting2}
              alt={t('components.header.profileMenu.profileSettingsButton.alt')}
            />
            <S.TextAccount>{i18n.t('account.settings')}</S.TextAccount>
          </S.AccountContent>

          <div style={{ display: 'none' }}>
            <S.AccountContent>
              <S.Icon
                src={Icons.setting3}
                alt={t('components.header.profileMenu.settingsButton.alt')}
              />
              <S.TextAccount>
                {t('components.header.profileMenu.settingsButton.label')}
              </S.TextAccount>
            </S.AccountContent>
          </div>

          <S.AccountContent>
            <S.Icon
              src={Icons.logout}
              alt={t('components.header.profileMenu.logoutButton.alt')}
            />
            <S.TextAccount onClick={() => logout()}>
              {t('components.header.profileMenu.logoutButton.label')}
            </S.TextAccount>
          </S.AccountContent>
        </S.AccountContainer>
      </ProfileMenu>
    </>
  );
};

export default AccountButton;
