export const userProfile = {
  title: 'Perfil',
  menus: {
    personalData: {
      title: 'Dados pessoais',
      nameInputPlaceholder: 'Apelido',
      birthdayInputPlaceholder: 'Aniversário',
      messages: {
        requestFailed:
          'Atualização de dados falhou, tente novamente mais tarde.',
        requestSuccessfully: 'Atualização de dados completada com sucesso.'
      }
    },
    phoneNumber: {
      title: 'Telefone',
      currentNumberPlaceholder: 'Número atual',
      newNumberPlaceholder: 'Número novo'
    },
    financePassword: {
      title: 'Senha financeira',
      description:
        'Essa senha irá ser usada para acessar funções financeiras da plataforma, como tranferências e depósitos.',
      currentPasswordPlaceholder: 'Senha atual',
      newPasswordPlaceholder: 'Nova senha',
      confirmPasswordPlaceholder: 'Confirmar senha',
      verificationCodePlaceholder: 'Código de verificação',
      phoneVerificationCodePlaceholder: 'Código de verificação por telefone',
      getCodeLabel: 'Pegar código',
      helperGetCode:
        'Entre com o código de verificação enviado para o seu e-mail'
    },
    platformPassword: {
      title: 'Senha da plataforma',
      description: 'Essa senha é usada para acessar a plataforma.',
      currentPasswordPlaceholder: 'Senha atual',
      newPasswordPlaceholder: 'Nova senha',
      getCodeLabel: 'Pegar código',
      confirmPasswordPlaceholder: 'Confirmar senha'
    }
  }
};
