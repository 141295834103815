import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Props, Type } from './@types';

type Small = {
  small?: boolean;
};

export const Content = styled.div<Small>`
  display: flex;
  align-items: center;
  flex-direction: row;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  ${({ small }) =>
    small &&
    css`
      flex-direction: column;
    `}
`;

export const TextWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 56px;
  width: ${({ type }) => (type === Type.BATTLE ? '313px' : '350px')};

  ${media.lessThan('medium')`
    margin-top: 22px;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    width: auto;
`}

  ${({ small }) =>
    small &&
    css`
      margin-top: 22px;
      flex-direction: row;
      align-items: center;
      margin-left: 0;
      width: auto;
    `}

  @media (max-width: 375px) {
    flex-direction: column;
  }
`;

export const Image = styled.img<Small>`
  width: 356px;
  height: 334px;
  object-fit: contain;

  ${media.lessThan('large')`
    width: 100%;
  `}

  ${({ small }) =>
    small &&
    css`
      width: 100%;
    `}

  ${media.lessThan('small')`
    height: 100%;
  `}
`;

export const Title = styled.h2<Small>`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;

  ${media.lessThan('large')`
    font-size: 38px;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}

  ${({ small }) =>
    small &&
    css`
      display: none;
    `}
`;

export const Description = styled.p<Small>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.005em;
  color: #ffffff;
  margin-top: 8px;
  width: 250px;

  ${media.lessThan('medium')`
    font-size: 12.81px;
    font-family: 'Roboto';
    line-height: 16px;
    padding: 10px 0 0 0;
    width: 70%;
  `}

  ${({ small }) =>
    small &&
    css`
      font-size: 12.81px;
      font-family: 'Roboto';
      line-height: 16px;
      padding: 10px 0 0 0;
      width: 70%;
    `}

  @media (max-width: 375px) {
    width: 100%;
    padding: 10px 0 0 0;
    margin-right: 0;
    text-align: center;
  }
`;

export const ButtonWrapper = styled.div<Small>`
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lessThan('medium')`
    margin-top: 0px;
  `}

  ${({ small }) =>
    small &&
    css`
      margin-top: 0px;
    `}

  @media (max-width: 375px) {
    margin-top: 20px;
  }
`;

export const BuyMatchCoinText = styled.p<Small>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: 250px;
  cursor: pointer;
  margin: 10px 0 0 10px;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 12.81px;
    font-family: 'Roboto';
    line-height: 16px;
    width: 50%;
  `}

  ${({ small }) =>
    small &&
    css`
      font-size: 12.81px;
      font-family: 'Roboto';
      line-height: 16px;

      width: 50%;
    `}

  @media (max-width: 375px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
`;
