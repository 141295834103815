// import { t } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

// const translationString = 'components.battle.schemas.createABetModalSchema';

const formSchema = yup.object().shape({
  nickname: yup
    .string()
    .required('Mandatory name field!')
    .min(3, 'The name must be longer than 3 letters')
});

export { formSchema };
