/* eslint-disable no-console */
import { t } from 'translate/i18n';
import { FormikHelpers, useFormik } from 'formik';
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TimePickerValue } from 'react-time-picker';

import { useTournament } from 'state/tournament/hooks';
import { tournamentService } from 'service/tournament';
import { PayloadCreateTournament } from 'service/@types';
import { dateFormatted } from 'utils/formatters';
import { utils } from 'ethers';
import { useModal } from 'components/modules/Modal';
import { Item } from 'components/elements/Selector/@types';
import {
  DatePicker,
  GeneralButton,
  Selector,
  TimePicker
} from 'components/elements';
import { getErrorStringName } from 'utils/errorHandling';
import { NotificationContext } from 'state/notification/state';
import { Actions as NotificationActions } from 'state/notification/@types/actions';
import { BetAmount } from 'components/modules';
import {
  Type as BetAmountType,
  Values
} from 'components/modules/BetAmount/@types';

import { TournamentSizeModal } from '../TournamentSizeModal';
import { formSchema } from './SelectTournamentDateSchema';
import { Init, StartOption, TournamentStartOptions } from './@types';

import * as S from './SelectTournamentDateModalStyled';

const SelectTournamentDateModal: React.FC = () => {
  const { tournament, setTournament, isTournamentOpen } = useTournament();
  const { createTournament } = tournamentService();
  const { closeAndShow, closeModal } = useModal();
  const navigate = useNavigate();

  const { dispatch: notificationDispatch } = useContext(NotificationContext);

  const currentDate = new Date();
  const translationString =
    'components.createTournament.selectTournamentDateModal';

  const tournamentStartOptions: TournamentStartOptions = useMemo(
    () => [
      {
        name: t(`${translationString}.tournamentStartOptions.onFuture`),
        init: Init.ONFUTURE
      },
      {
        name: t(`${translationString}.tournamentStartOptions.now`),
        init: Init.NOW
      }
    ],
    []
  );

  const formik = useFormik({
    initialValues: {
      date: null,
      time: '',
      startOption: tournamentStartOptions[0]
    },
    onSubmit: () => {},
    validationSchema: formSchema
  });

  const onChangeDate = (date: unknown): void => {
    const selectedDate = (date as Date) || null;
    formik.setFieldValue('date', selectedDate);
  };

  const onChangeTime = (time: TimePickerValue): void => {
    const selectedTime = (time as string) || null;
    formik.setFieldValue('time', selectedTime);
  };

  const nextOptionSelector = (): void => {
    const actualSelectedOptionIndex =
      tournamentStartOptions
        .map((one: StartOption, index: number) =>
          one.name === (formik.values.startOption as StartOption).name
            ? index
            : null
        )
        .filter((item: number | null) => Number.isInteger(item))
        .at(0) || 0;

    const selectedOption: StartOption | undefined = tournamentStartOptions.find(
      (one: StartOption, index: number) =>
        index === actualSelectedOptionIndex + 1 &&
        index < tournamentStartOptions.length
    );

    if (selectedOption) {
      formik.setFieldValue('startOption', selectedOption);
    } else {
      formik.setFieldValue(
        'startOption',
        tournamentStartOptions.at(actualSelectedOptionIndex)
      );
    }
  };

  const prevOptionSelector = (): void => {
    const actualSelectedOptionIndex =
      tournamentStartOptions
        .map((one: StartOption, index: number) =>
          one.name === (formik.values.startOption as StartOption).name
            ? index
            : null
        )
        .filter((item: number | null) => Number.isInteger(item))
        .at(0) || 0;

    const selectedOption: StartOption | undefined = tournamentStartOptions.find(
      (one: StartOption, index: number) =>
        index === actualSelectedOptionIndex - 1 && index >= 0
    );

    if (selectedOption) {
      formik.setFieldValue('startOption', selectedOption);
    } else {
      formik.setFieldValue(
        'startOption',
        tournamentStartOptions.at(actualSelectedOptionIndex)
      );
    }
  };

  const returnToTournamentSize = (e: unknown): void => {
    (e as MouseEvent).preventDefault();

    closeAndShow(
      <TournamentSizeModal platforms={tournament.game.platforms} />,
      '475px',
      true,
      '35px 42px 43px'
    );
  };

  const returnToTournamentDate = () => {
    closeAndShow(
      <SelectTournamentDateModal />,
      '475px',
      true,
      '35px 42px 43px'
    );
  };

  const getDateSelected = (): Date => {
    const timeHour = formik.values.time?.slice(0, 2);
    const timeMinute = formik.values.time?.slice(3, 5);

    if (!formik.values.date || !formik.values.time) {
      const dateNow = new Date();
      return dateNow;
    }

    const dateWithHours = new Date(formik.values.date).setHours(
      +timeHour,
      +timeMinute
    );

    const date = new Date(dateWithHours);
    return date;
  };

  const isStartNow =
    formik.values.startOption.name === tournamentStartOptions[1].name;

  const isSelectedDate = formik.values.date && formik.values.time;

  const isAvailableSubmitStartDateTournament =
    (isSelectedDate && currentDate.getTime() < getDateSelected().getTime()) ||
    isStartNow;

  const onSubmitBetAmount = async (
    formikValues: Values,
    { setSubmitting }: FormikHelpers<Values>,
    startDate: string
  ) => {
    if (isTournamentOpen) {
      const payloadCreateTournament = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values: any
      ): PayloadCreateTournament => {
        const entryFeeString = formikValues.amount;
        const entryFeeBigNumber = utils.parseEther(entryFeeString);
        const entryFeeConverted = entryFeeBigNumber.toString();

        const payload = {
          name: values.name,
          description: values.description,
          rules: values.rules,
          platform_id: values.platform.entity_id,
          entry_fee: entryFeeConverted,
          start_date: startDate,
          tournament_type: 'OPEN',
          image_url:
            'https://s3.amazonaws.com/dev.matchbet/tournaments/decc/decc3250-a1a2-4be9-b63b-684d511c3c38',
          max_players: values.sizeTournament,
          game_id: values.game.entity_id,
          stage_infos: {
            time_between_stages: values.timeBetweenStages,
            stage_time: values.stageTime,
            ready_timeout: values.readyTimeout,
            stages_per_day: values.stagesPerDay
          }
        };
        return payload;
      };

      const payload = payloadCreateTournament(tournament);

      setSubmitting(true);

      await createTournament(payload)
        .then(() => {
          navigate('/matchbet/tournaments');
        })
        .catch(async (error) => {
          const errorCode = await getErrorStringName(
            error.response.data.error_code
          );
          const message = t(
            `${translationString}.error.${errorCode.toLowerCase()}`
          );
          notificationDispatch({
            type: NotificationActions.OPEN,
            payload: {
              message
            }
          });
          navigate('/matchbet/tournaments');
        });

      closeModal();
      setSubmitting(false);
      return;
    }

    setTournament({
      isStartNow,
      startDate,
      amount: Number(formikValues.amount),
      step: 3
    });
    console.log('SelectTournamentDateModal - onSubmitBetAmount', tournament);

    closeModal();
  };

  const goToBetAmount = (e: unknown): void => {
    (e as MouseEvent).preventDefault();

    const dateNow = new Date();
    const dateScheduling = getDateSelected();
    const startDate = dateFormatted(isStartNow ? dateNow : dateScheduling);

    closeAndShow(
      <BetAmount
        type={BetAmountType.TOURNAMENT}
        onClose={returnToTournamentDate}
        onSubmit={(values, formikValues) =>
          onSubmitBetAmount(values, formikValues, startDate)
        }
      />,
      '475px',
      true,
      '35px 42px 43px'
    );
  };

  const willInitNow: boolean = useMemo(
    () => formik.values.startOption.init === Init.NOW,
    [formik.values.startOption]
  );

  useEffect(() => {
    if (willInitNow) {
      formik.setFieldValue('date', null);
      formik.setFieldValue('time', '');
    }
  }, [willInitNow]);

  return (
    <>
      <S.Container>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.Description>{t(`${translationString}.description`)}</S.Description>
        <S.Form>
          <Selector
            list={tournamentStartOptions}
            itemSelected={formik.values.startOption as Item}
            next={nextOptionSelector}
            prev={prevOptionSelector}
            margin="0"
          />
          <S.ScheduleWrapper>
            <DatePicker
              minDate={currentDate}
              value={formik.values.date}
              disabled={willInitNow}
              onChange={onChangeDate}
            />
            <TimePicker
              value={formik.values.time}
              disabled={willInitNow}
              onChange={onChangeTime}
            />
          </S.ScheduleWrapper>
          <S.ButtonsWrapper>
            <GeneralButton
              variant="primary"
              transparent
              size="medium"
              onClick={returnToTournamentSize}
            >
              {t(`${translationString}.buttons.return`)}
            </GeneralButton>
            <GeneralButton
              variant="primary"
              size="medium"
              onClick={goToBetAmount}
              disabled={!isAvailableSubmitStartDateTournament}
            >
              {t(`${translationString}.buttons.next`)}
            </GeneralButton>
          </S.ButtonsWrapper>
        </S.Form>
      </S.Container>
    </>
  );
};

export default SelectTournamentDateModal;
