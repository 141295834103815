import { getImage } from 'assets/img';
import * as S from './VerificationPaymentStyled';

export const VerificationPayment = () => {
  return (
    <S.VerificationPaymentContainer>
      <S.Content>
        <S.Icon src={getImage('Metamask')} alt="Logo metamask" />
        <S.Title>Awaiting verification of payment via blocochain.</S.Title>
        <S.Text margin> Transaction Id:</S.Text>
        <S.Text>
          b123b912e1b2ed10nd01b379123v6fx7g 938hj013h8d7g13d13dfv1b9u3
        </S.Text>
      </S.Content>
    </S.VerificationPaymentContainer>
  );
};
