export const formSchema = {
  date: {
    required: 'Mandatory date field!'
  },
  time: {
    required: 'Mandatory time field!'
  },
  startOption: {
    required: 'Mandatory startOption field!',
    name: {
      required: 'Mandatory name field!'
    }
  }
};
