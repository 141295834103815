/* eslint-disable no-console */
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { Actions } from 'state/user/@types/actions';
import { UserContext } from 'state/user/state';
import { i18n, t } from 'translate/i18n';
import { utils } from 'ethers';
import ExtendedNumberBox from 'components/modules/ExtendedNumberBox.tsx';
import { amountSchema } from './AmountSchema';
import * as S from './AmountStyled';

export const Amount = () => {
  const { state, dispatch } = useContext(UserContext);
  const translationString = 'components.transfer.amount';

  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    onSubmit: ({ amount }) => {
      const stringAmount = `${amount}`;

      const stringEther = stringAmount.replace(',', '.');
      const wei = utils.parseEther(stringEther);
      const stringWei = wei.toString();
      const decimalBalance = state.user.balance.replace(',', '.');
      const balance = utils.parseEther(decimalBalance);

      if (!balance.gte(wei)) {
        formik.setFieldError('amount', i18n.t('errors.insufficientBalance'));
      } else {
        dispatch({
          type: Actions.SET_WALLET_DATA,
          payload: {
            ...state.user,
            Step: 3,
            Amount: stringWei
          }
        });
      }
    },
    validationSchema: amountSchema
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const stringAmount = `${value}`;

    if (!e.target.value || e.target.value === '') {
      formik.setFieldValue('amount', '');
      return;
    }
    const decimalBalance = state.user.balance;
    const balance = utils.parseEther(decimalBalance);
    const amount = utils.parseEther(stringAmount);
    const isBalanceEnough = balance.gte(amount);
    if (!isBalanceEnough) {
      formik.setFieldValue('amount', utils.formatEther(balance));
    } else {
      formik.setFieldValue('amount', value);
    }
  };
  const backStep = () => {
    dispatch({
      type: Actions.SET_WALLET_DATA,
      payload: {
        ...state.user,
        Step: state.user.Step - 1
      }
    });
  };
  return (
    <S.AmountContainer>
      <S.Content column padding="24px">
        <S.SubTitle>
          {t(`${translationString}.title`)}
          <S.Text>
            <S.Initials>{t(`${translationString}.initials`)}</S.Initials>{' '}
            {t(`${translationString}.initials2`)}
          </S.Text>
        </S.SubTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput>
            <S.ContainerPassword>
              <TextField
                type="number"
                variant="outlined"
                id="amount"
                label={t(`${translationString}.placeholder`)}
                sx={{
                  '& legend': {
                    fontSize: '1.2em'
                  }
                }}
                value={formik.values.amount || ''}
                onChange={onChange}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                InputProps={{
                  endAdornment: (
                    <S.Text>
                      <S.Initials>
                        {t(`${translationString}.balance`)}
                      </S.Initials>
                      <S.Initials yellow>
                        <ExtendedNumberBox>
                          {state.user.balance}
                        </ExtendedNumberBox>{' '}
                        {t(`${translationString}.initials`)}
                      </S.Initials>
                    </S.Text>
                  )
                }}
              />
            </S.ContainerPassword>
          </S.ContainerInput>
          <S.Content column>
            <S.Text margin="5px 0 10px">
              {t(`${translationString}.description`)} {state.user.email}
              {/* <S.Text yellow>Edit account </S.Text> */}
            </S.Text>
          </S.Content>
        </S.Form>
      </S.Content>
      <S.ContainerButtons>
        <S.Cancel secondary onClick={() => backStep()}>
          {i18n.t('buttons.back')}
        </S.Cancel>
        <S.Continue onClick={() => formik.handleSubmit()}>
          {i18n.t('buttons.continue')}
        </S.Continue>
      </S.ContainerButtons>
    </S.AmountContainer>
  );
};
