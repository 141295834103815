import icons from 'assets/img/icons';
import { ToggleButton } from 'components/elements/ToggleButton';
import { Card } from 'components/modules';
import { EntityGame, EntityPlatform } from 'models/Games';
import React, { useEffect, useState } from 'react';
import { t } from 'translate/i18n';
import { v4 } from 'uuid';
import { Type } from '../List/@types';
import { Props } from './@types';

import * as S from './PlatformFilterStyled';

const PlatformFilter: React.FC<Props> = ({
  data,
  search = [],
  type,
  onChange = () => {}
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [seeAllGames, setSeeAllGames] = useState<boolean>(true);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);

  const translationString = 'components.createBattle.platformFilter';

  useEffect(() => {
    const getPlatforms = (): void => {
      if (type === Type.GAME) {
        const platformOfAllGames = (data as EntityGame[])
          .map((game: EntityGame) => game?.platforms)
          .flat()
          .map((platform: EntityPlatform) => platform?.name);
        const uniquesPlatforms = Array.from(new Set(platformOfAllGames.flat()));
        setPlatforms(uniquesPlatforms);
      }
    };

    getPlatforms();
  }, [data]);

  const openSettings = (): void => {
    setOpenFilter(!openFilter);
  };

  const toggleSeeAllGames = (): void => {
    setSeeAllGames(selectedPlatforms.length <= 0);
  };

  const toggleFilter = (platform: string): void => {
    if (!selectedPlatforms?.includes(platform)) {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    } else {
      setSelectedPlatforms(
        selectedPlatforms.filter((all: string) => all !== platform)
      );
    }
    toggleSeeAllGames();
  };

  const renderFilter = (platform: string): React.ReactElement => {
    const hasSearch = search.length > 0;
    const hasPlatformOnSearch = !!(search as EntityGame[]).find(
      (one: EntityGame) =>
        one.platforms
          ?.map((item: EntityPlatform) => item.name)
          ?.includes(platform)
    );

    return (
      <S.Filter key={v4()}>
        {t(`${translationString}.gamesFor`)} {platform?.toUpperCase()}
        <ToggleButton
          active={selectedPlatforms?.includes(platform)}
          bindState
          disabled={hasSearch && !hasPlatformOnSearch}
          onChange={() => toggleFilter(platform)}
        />
      </S.Filter>
    );
  };

  useEffect(() => {
    onChange(selectedPlatforms);
    toggleSeeAllGames();
  }, [selectedPlatforms]);

  return (
    <S.Container type={type}>
      <S.Button onClick={openSettings}>
        <Card
          width="46px"
          height="46px"
          background="rgba(255, 255, 255, 0.1)"
          padding="unset"
        >
          <S.Icon src={icons.settings} />
        </Card>
      </S.Button>

      <S.Popup openFilter={openFilter}>
        <S.BallonLeg />
        <Card
          width="265px"
          height="min-content"
          padding="20px"
          background="#36284E"
        >
          {platforms.map(renderFilter)}
          <S.Filter>
            {t(`${translationString}.allGames`)}{' '}
            <ToggleButton
              active={seeAllGames}
              bindState
              disabled={selectedPlatforms.length > 0}
            />
          </S.Filter>
          <S.Line />
          <S.Filter>
            {t(`${translationString}.victoryValidation`)}{' '}
            <ToggleButton active />
          </S.Filter>
        </Card>
      </S.Popup>
    </S.Container>
  );
};

export default PlatformFilter;
