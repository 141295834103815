export const GlobalRanking = {
  title: 'Global Ranking',
  placeholder_search_user: 'search',
  auxiliarTitle: 'RANKING MatchCash',
  description: 'This is the global ranking, all players appear here.',

  yourHistory: {
    button: 'View more',
    title: 'your history',
    battles_text: 'Battles',
    tournaments_text: 'Tournaments',
    battles_won_text: 'Victorys Batlles',
    elo_points_text: 'ELO Points',
    text: 'This is a summary of your history',
    tournaments_won_text: 'Victorys Tournaments',
    place_in_the_ranking_text: 'Place in the ranking'
  },

  table: {
    user_text: 'User',
    battles_won_text: 'battles won',
    tournaments_won_text: 'tournaments won',
    elo_points_text: 'ELO Points',
    imgs_alt: {
      icon: 'icon',
      user: 'Image user',
      game: 'Image game'
    },
    row: {
      text: 'value bet'
    }
  }
};
