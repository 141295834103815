import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Matchbet } from 'pages/Matchbet';
import { BattleContext } from 'state/battle/state';
import { BannerBattle } from './components/BannerBattle';
import { ChatButton } from './components/ChatButton';
import { Messenger } from './components/Messenger';
import { Title } from './components/Title';

import * as S from './ChatStyled';

const Chat: React.FC = () => {
  const [showMessenger, setShowMessenger] = useState(false);
  const {
    state: {
      battle: {
        currentBattle: { selectedGame, playerOne, playerTwo, entity_id }
      }
    }
  } = useContext(BattleContext);

  const navigate = useNavigate();
  const location = useLocation();

  const hasCurrentMatch = !!location?.state?.current_match;
  const isTournament = location?.state?.isTournament;

  const showBanner = (): void => {
    setShowMessenger(false);
  };

  const showTheMessenger = (): void => {
    setShowMessenger(true);
  };

  const backToBattle = (): void => {
    if (hasCurrentMatch) {
      navigate(-1);
      return;
    }
    navigate('/matchbet/battle');
  };

  const getBackClickFunction = () => {
    return showMessenger ? showBanner() : backToBattle();
  };

  const current_match = hasCurrentMatch
    ? location.state.current_match
    : {
        entity_id,
        image_url: selectedGame.image_url,
        player1: {
          nickname: playerOne.name,
          image_url: playerOne.image_url
        },
        player2: {
          nickname: playerTwo.name,
          image_url: playerTwo.image_url
        }
      };

  return (
    <Matchbet>
      <S.Container>
        <Title isTournament={isTournament} onBackClick={getBackClickFunction} />
        <BannerBattle
          current_match={current_match}
          showOnMobile={!showMessenger}
        />
        <ChatButton showOnMobile={!showMessenger} onClick={showTheMessenger} />
        <Messenger
          battleId={current_match.entity_id}
          showOnMobile={showMessenger}
        />
      </S.Container>
    </Matchbet>
  );
};

export default Chat;
