import { isDate, parse } from 'date-fns';
import { t } from 'translate/i18n';
import * as yup from 'yup';
import 'yup-phone';

const today = new Date();

function parseDateString(value: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd/MM/yyyy', new Date());

  return parsedDate;
}

const translationString = 'components.userProfile.schemas';

const userProfileSchema = yup.object().shape({
  nickname: yup
    .string()
    .required(t(`${translationString}.userProfileSchema.nickname.required`))
    .min(3, t(`${translationString}.userProfileSchema.nickname.message`)),
  birthday: yup
    .date()
    .transform(parseDateString)
    .max(today, t(`${translationString}.userProfileSchema.birthday.max`))
    .required(t(`${translationString}.userProfileSchema.birthday.required`)),
  phone: yup
    .string()
    .required(t(`${translationString}.userProfileSchema.phone.required`))
});

const userProfilePhoneNumberSchema = yup.object().shape({
  currentNumber: yup
    .string()
    .min(
      13,
      t(
        `${translationString}.userProfilePhoneNumberSchema.currentNumber.message`
      )
    )
    .required(
      t(
        `${translationString}.userProfilePhoneNumberSchema.currentNumber.required`
      )
    ),
  newNumber: yup
    .string()
    .min(
      13,
      t(`${translationString}.userProfilePhoneNumberSchema.newNumber.message`)
    )
    .required(
      t(`${translationString}.userProfilePhoneNumberSchema.newNumber.required`)
    )
});

const financePasswordSchema = yup.object().shape({
  showCurrentPassword: yup.boolean(),

  currentPassword: yup.string().when('showCurrentPassword', {
    is: true,
    then: yup
      .string()
      .min(
        8,
        t(`${translationString}.financePasswordSchema.currentPassword.message`)
      )
      .required(
        t(`${translationString}.financePasswordSchema.currentPassword.required`)
      )
  }),
  newPassword: yup
    .string()
    .min(8, t(`${translationString}.financePasswordSchema.newPassword.message`))
    .required(
      t(`${translationString}.financePasswordSchema.newPassword.required`)
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      t(`${translationString}.financePasswordSchema.confirmPassword.mustMatch`)
    )
    .required()
});

const platformPasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(
      8,
      t(`${translationString}.platformPasswordSchema.currentPassword.message`)
    )
    .required(
      t(`${translationString}.platformPasswordSchema.currentPassword.required`)
    ),
  newPassword: yup
    .string()
    .min(
      8,
      t(`${translationString}.platformPasswordSchema.newPassword.message`)
    )
    .required(
      t(`${translationString}.platformPasswordSchema.newPassword.required`)
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      t(`${translationString}.platformPasswordSchema.confirmPassword.message`)
    )
    .required(
      t(`${translationString}.platformPasswordSchema.confirmPassword.required`)
    )
});

export {
  userProfileSchema,
  userProfilePhoneNumberSchema,
  financePasswordSchema,
  platformPasswordSchema
};
