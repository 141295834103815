import React, { MutableRefObject, ReactElement, useRef } from 'react';

import { Notification as NotifcationType } from 'models/Notification';
import { Props } from './@types';

import { Empty } from '../Empty';
import { Notification } from '../Notification';
import { UpdateListButton } from '../UpdateListButton';

import * as C from './NotificationListStyled';

const NotificationList: React.FC<Props> = ({ show, data }) => {
  const listRef = useRef() as MutableRefObject<HTMLUListElement>;

  const renderNotification = (notification: NotifcationType): ReactElement => {
    return (
      <Notification notification={notification} key={notification.entity_id} />
    );
  };

  const getHeightOfContainerOnOpenNotifications = (): number | null => {
    if (listRef.current) {
      const listHeight = listRef.current.clientHeight;
      const BUTTON_HEIGHT = 67.59;
      const PADDING_TOP = 27;

      return listHeight + BUTTON_HEIGHT + PADDING_TOP;
    }

    return null;
  };

  return (
    <>
      <C.ArrowUp show={show} />
      <C.Container
        show={show}
        heightDesktop={getHeightOfContainerOnOpenNotifications()}
      >
        <UpdateListButton />
        {data.length <= 0 ? (
          <Empty />
        ) : (
          <C.List ref={listRef}>{data.map(renderNotification)}</C.List>
        )}
      </C.Container>
    </>
  );
};

export default NotificationList;
