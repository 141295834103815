import styled from 'styled-components';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 475px;
  height: auto;
  display: flex;
  position: fixed;
  border-radius: 12px;
  flex-direction: column;
  padding: 35px 44px 18px;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.background.lightBlur};
`;
export const CardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
`;
export const ContainerButtons = styled.div`
  gap: 10px;
  display: flex;
  max-width: 278px;
  align-items: center;
  margin: 34px auto 25px;
  justify-content: space-between;
`;
export const Title = styled.h2`
  text-align: center;
  line-height: 27px;
  margin-bottom: 12px;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  color: ${({ theme }) => theme.palette.title.default};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
`;

export const Text = styled.p`
  max-width: 320px;
  margin: auto;
  text-align: center;
  line-height: 20px;
  font-style: normal;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.title.default};
  font-size: ${({ theme }) => theme.typography.fontSizeRegular - 0.2}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
export const Value = styled(Title)`
  font-size: ${({ theme }) => theme.typography.fontSizeRegular - 0.2}rem;
`;
