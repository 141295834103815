import styled from 'styled-components';

export const ContainerTable = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  @media (max-width: 768px) {
    overflow: scroll;
  }
`;

export const Containerloading = styled.div`
  width: 100%;
  align-items: center;
  margin-top: 100px;
`;
