import * as Battle from './components/Battle';
import * as CreateBattle from './components/CreateBattle';
import * as CreateTournament from './components/CreateTournament';
import * as Tournament from './components/Tournament';
import * as Friends from './components/Friends';
import * as Header from './components/Header';
import * as GlobalRanking from './components/GlobalRanking';
import * as IndicationLink from './components/IndicationLink';
import * as NFTs from './components/NFT';
import * as RecentTransactions from './components/RecentTransactions';
import * as UserProfile from './components/UserProfile';
import * as LanguageSwitch from './components/LanguageSwitch';
import * as Account from './components/Account';
import * as Transfer from './components/Transfer';
import * as CompleteSignup from './components/CompleteSignUp';
import * as UserTournament from './components/UserTournament';
import * as CountDown from './components/CountDown';
import * as AmountAndPlatformFilter from './components/AmountAndPlatformFilter';
import * as DatePicker from './components/DatePicker';
import * as TimePicker from './components/TimePicker';
import * as YourHistory from './components/YourHistory';
import * as TournamentDetails from './components/TournamentDetails';
import * as MatchBetStore from './components/MatchbetStore';
import * as Checkout from './components/Checkout';
import * as CheckoutSuccess from './components/CheckoutSuccess';
import * as Sidebar from './components/SideBar';

const messages = {
  'pt-BR': {
    translations: {
      account: {
        user: 'Usuário',
        logout: 'Sair',
        settings: 'Configurações do perfil',
        configurations: 'Configurações'
      },
      inputs: {
        email: 'E-mail',
        password: 'Senha',
        confirmPassword: 'Confirmar senha'
      },
      titles: {
        matchcoins: 'Matchcoins',
        account_user: 'Conta de',
        app: 'Saldo da conta virtual',
        confirmEmail: 'Confirmar e-mail',
        transfer: 'Transferência',
        withdraw: 'Sacar',
        wallet: 'Carteira'
      },
      messages: {
        itWorks: 'Está funcionando com vários idiomas',
        smallText: 'Um pequeno texto',
        dontHaveAnAccount: 'Ainda não tem uma conta?',
        alreadyHaveAnAccount: 'Já tem uma conta MatchCash?',
        iAgreeToThe: 'Eu concordo com os',
        termsOfUse: 'termos de uso',
        weVeSentYouACode: `Enviamos um e-mail de confirmação, insira o código abaixo`,
        counter: 'reenviar em {{counter}} segundos',
        weWillSendYouACode:
          'Enviamos um código de confirmação por e-mail para alterar sua senha. Insira o código abaixo:',
        sucessRegister:
          'Seu registro foi feito com sucesso, logue e aproveite a plataforma.',
        errorGettingBattle:
          'Algo deu errado durante o fluxo de pegar informacões da batalha, por favor, atualize a pagina e tente novamente ',
        unknow_error: 'Erro desconhecido'
      },
      links: {
        signIn: 'Entrar',
        signUp: 'Cadastrar-se',
        resendCode: 'reenviar código'
      },
      buttons: {
        save: 'Salvar',
        back: 'voltar',
        signIn: 'Entrar',
        withdraw: 'Sacar',
        cancel: 'Cancelar',
        recharge: 'Recaregar',
        continue: 'Continuar',
        transfer: 'Transferir',
        signUp: 'Cadastrar-se',
        sendCode: 'Enviar código',
        changePassword: 'Alterar senha',
        signUpForFree: 'Faça seu cadastro',
        forgotMyPassword: 'Esqueci minha senha'
      },
      components: {
        userProfile: {
          ...UserProfile.labels.PT_BR.userProfile,
          schemas: {
            ...UserProfile.schemas.PT_BR
          }
        },
        yourHistory: {
          ...YourHistory.labels.PT_BR.yourHistory
        },
        globalRanking: {
          ...GlobalRanking.labels.PT_BR.globalRanking
        },
        header: {
          ...Header.labels.PT_BR.header
        },
        recentTransactions: {
          ...RecentTransactions.labels.PT_BR.recentTransactions
        },
        battle: {
          ...Battle.labels.PT_BR.battle,
          schemas: {
            ...Battle.schemas.PT_BR
          }
        },
        transfer: {
          ...Transfer.labels.PT_BR.transfer
        },
        tournament: {
          ...Tournament.labels.PT_BR.tournament
        },
        countDown: {
          ...CountDown.labels.PT_BR.countDown
        },
        createBattle: {
          ...CreateBattle.labels.PT_BR.createBattle
        },
        createTournament: {
          ...CreateTournament.labels.PT_BR.createTournament,
          schemas: {
            ...CreateTournament.schemas.PT_BR
          }
        },
        completeSignup: {
          ...CompleteSignup.labels.PT_BR.CompleteSignUp
        },
        friends: {
          ...Friends.labels.PT_BR.friends,
          schemas: {
            ...Friends.schemas.PT_BR
          }
        },
        indicationLink: {
          ...IndicationLink.labels.PT_BR.indicationLink
        },
        nft: {
          ...NFTs.labels.PT_BR.nft
        },
        languageSwitch: {
          ...LanguageSwitch.labels.PT_BR.languageSwitch
        },
        account: {
          ...Account.labels.PT_BR.account
        },
        userTournament: {
          ...UserTournament.labels.PT_BR.userTournament
        },
        tournamentDetails: {
          ...TournamentDetails.labels.PT_BR.tournamentDetails
        },
        amountAndPlatformFilter: {
          ...AmountAndPlatformFilter.labels.PT_BR.amountAndPlatformFilter
        },
        datePicker: {
          ...DatePicker.labels.PT_BR.datePicker
        },
        timePicker: {
          ...TimePicker.labels.PT_BR.timePicker
        },
        MatchBetStore: {
          ...MatchBetStore.labels.PT_BR.matchBetStore
        },
        Checkout: {
          ...Checkout.labels.PT_BR.Checkout
        },
        CheckoutSuccess: {
          ...CheckoutSuccess.labels.PT_BR.CheckoutSuccess
        },
        SideBar: {
          ...Sidebar.labels.PT_BR.SideBar
        }
      },
      errors: {
        insufficientBalance: 'Saldo insuficientes',
        incorrectUserNameOrPassword: 'Usuário ou senha incorretos.',
        invalidEmail: 'E-mail inválido.',
        invalidPassword:
          'Senha inválida, a senha deve ter no mínimo 8 dígitos.',
        invalidPlatforms: 'Plataforma inválida, selecione uma plataforma',
        invalidCodeAccount: 'Número da conta inválido',
        mandatoryPassword: 'O campo de senha é obrigatório.',
        mandatoryCodeAccount: 'O campo do número da conta obrigatório.',
        mandatoryEmail: 'O campo de e-mail obrigatório',
        mandatoryFile: 'Imagem obrigatória!',
        mandatoryPlatforms: 'Plataforma obrigatória!',
        mandatoryConfirmPassword:
          'O campo de confirmação de senha é obrigatório.',
        passwordsDontMatch: `As senhas devem ser iguais.`,
        passwordAttemptsExceeded: 'A quantidade de tentativas foi excedida.'
      }
    }
  }
};

export { messages };
