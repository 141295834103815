import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .MuiList-root {
    width: 100%;
    max-width: 100%;
    margin-top: 12px;
    max-height: 205px;
    overflow-y: hidden;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.palette.text.gray};

    .MuiListItemAvatar-root {
      min-width: 0;
      margin-right: 12px;
    }
    .MuiListItemText-root {
      .MuiTypography-root {
        line-height: 130%;
        font-family: 'Poppins';
        letter-spacing: 0.01em;
        color: ${({ theme }) => theme.palette.text.gray};
        font-size: ${({ theme }) => theme.typography.fontSize - 0.4}rem;
        font-weight: ${({ theme }) => theme.typography.fontWeightLight};
      }
    }
    .MuiListItemSecondaryAction-root {
      .MuiCheckbox-root {
        .MuiSvgIcon-root {
          width: 24px;
          height: 24px;
          color: ${({ theme }) => theme.palette.button.default};
        }
        .Mui-checked {
          color: ${({ theme }) => theme.palette.button.default};
        }
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background: #dedede;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #c4c4c4;
    }
    &:hover {
      overflow-y: scroll;
    }
  }
`;
export const Form = styled.form`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiInputLabel-root {
      color: ${(props) => props.theme.palette.text.light};
      font-size: ${(props) => props.theme.typography.fontSize}rem;
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color:color: ${(props) =>
            props.theme.palette.text.white} !important;
        }
        width: 85%;
        border-radius: 0px;
        transition: background-color 5000s;
        color: ${(props) => props.theme.palette.text.light};
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.background.primary};
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${(props) => props.theme.palette.background.primary};
        }
      }
    }
  }
  .MuiInputAdornment-root {
    margin: 0;
    padding: 0;
    width: auto;
    height: 100%;
    .MuiButtonBase-root {
      width: 100%;
      height: 100%;
      padding: 11px 20px;
      margin-right: -32px;
      background-color: #2f2744;
      border-radius: 0 !important;
      button:hover {
      }
      svg {
        width: 22px;
        height: 22px;
        color: ${(props) => props.theme.palette.text.light};
      }
    }
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 0px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    color: ${(props) => props.theme.palette.text.light};
  }
`;
export const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  display: flex;
  position: sticky;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;
  ${(props) =>
    props.error &&
    css`
      .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
        color: ${props.theme.palette.error.main};
        font-size: ${props.theme.typography.fontSize}rem;
      }

      .MuiOutlinedInput-notchedOutline {
        font-size: ${props.theme.typography.fontSize}rem;
        border-color: ${props.theme.palette.error.main} !important;
      }
      .MuiFormHelperText-root {
        margin-bottom: 10px;
        font-size: ${props.theme.typography.fontSize}rem;
      }
    `};
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
export const ContainerButtons = styled.div<{ noActive?: boolean }>`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button:last-child {
    ${(props) =>
      props.noActive &&
      css`
        cursor: no-drop;
        box-shadow: none;
        color: ${props.theme.palette.text.disable};
        background-color: ${props.theme.palette.button.disable};
      `};
  }
`;
export const PhotoContainer = styled.div`
  width: 100%;
  height: 173.66px;
  border-radius: 6px;
  border: 0.998408px dashed ${(props) => props.theme.palette.background.primary};
`;
export const HiddenInput = styled.input`
  display: none;
`;
export const Title = styled.h2<{ small?: boolean }>`
  line-height: 125%;
  font-style: normal;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.title.default};
  font-weight: ${({ theme }) => theme.typography.fontWeightBolder};
  font-size: ${({ theme }) => theme.typography.fontSizeBold - 0.1}rem;
  ${(props) =>
    props.small &&
    css`
      margin-bottom: 0;
      max-width: 175px;
      line-height: 130%;
      font-weight: ${({ theme }) => theme.typography.fontWeightBolder - 200};
      font-size: ${({ theme }) => theme.typography.fontSize + 0.2}rem;
    `};
`;
export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
`;
export const Img = styled.img`
  width: 34px;
  height: 34px;
  object-fit: cover;
`;
export const Text = styled.p`
  margin-top: 7px;
  line-height: 140%;
  text-align: justify;
  font-family: 'Poppins';
  letter-spacing: 0.0025em;
  color: ${({ theme }) => theme.palette.text.gray};
  font-size: ${({ theme }) => theme.typography.fontSize - 0.2}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;
export const ContentNoPhone = styled.div`
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.141542px;
  color: ${({ theme }) => theme.palette.text.white};
`;
export const ButtonsList = styled.ul`
  gap: 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const Item = styled.li`
  list-style: none;
`;
export const Button = styled.button<{ active?: boolean }>`
  width: 67px;
  height: 67px;
  border: 0;
  border: 3px solid ${({ theme }) => theme.palette.button.disable};
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.palette.button.default};
    img {
      filter: invert(9.5%) sepia(17%) saturate(7418%) hue-rotate(339deg)
        brightness(101%) contrast(101%);
    }
  }

  ${({ theme, active }) =>
    active &&
    css`
      border-color: ${theme.palette.button.default};
    `};
`;
export const Icon = styled.img<{ active?: boolean }>`
  width: 30px;
  height: 30px;
  object-fit: contain;

  ${({ active }) =>
    active &&
    css`
      filter: invert(9.5%) sepia(17%) saturate(7418%) hue-rotate(339deg)
        brightness(101%) contrast(101%);
    `};
`;
export const ContainerGameName = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  div:first-child {
    margin-right: 0px;
    width: 100% !important;
  }
  button {
    width: 45px;
    right: 13px;
    height: 55px;
    position: absolute;
    border-radius: 0px !important;
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.white};

    :hover {
      background-color: transparent;
    }
    .MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.fontSizeRegular + 0.3}rem;
    }
  }
`;
