import styled from 'styled-components';

export const ContainerRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  > div:nth-child(odd) {
    background: rgba(255, 255, 255, 0.1);
  }
`;
export const Row = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  justify-content: start;
`;
