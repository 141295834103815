import { ButtonSmall } from 'components/elements/Button/ButtonStyled';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const UserProfileContainer = styled.div<{ open?: boolean }>`
  gap: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 582px;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  background: rgba(74, 62, 94, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 24px 39px rgba(0, 0, 0, 0.14);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  transition: all 0.5s ease-in-out;
  transform: translateX(100%);

  &::-webkit-scrollbar {
    width: 0px;
  }

  ${media.lessThan('medium')`
    position: fixed;
  `}

  ${({ open }) =>
    open &&
    css`
      transform: translateX(0);
    `}
`;

export const HeaderProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  height: 81px;
  min-height: 81px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const HeaderTitle = styled.h2`
  font-size: 2.1rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const Profile = styled.div`
  padding: 30px 42px;
  ${media.lessThan('small')`
    padding: 30px 30px;
  `}
  width: 100%;
`;

export const Container = styled.div<{ column?: boolean; width?: string }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => props.width};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `};
`;

export const Avatar = styled.img`
  width: 117px;
  height: 115px;
  margin-right: 20px;
  border-radius: 20.3506px;
  border: 4.58346px solid #ffffff;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
`;

export const Text = styled.p<{ margin?: string }>`
  width: 100%;
  display: flex;
  margin: 5px 0;
  color: #6c6f6f;
  align-items: center;
  font-family: 'Roboto';
  justify-content: space-between;
  margin: ${(props) => props.margin};
  color: ${(props) => props.theme.palette.text.regular};
  font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
`;

export const Icon = styled.img<{ edit?: boolean }>`
  max-width: 26px;
  margin-right: 10px;
  ${(props) =>
    props.edit &&
    css`
      bottom: 0;
      right: 20px;
      padding: 4px;
      max-width: 50px;
      cursor: pointer;
      cursor: no-drop;
      margin-right: 0;
      position: absolute;
      -webkit-text-security: disc;
      border-radius: 10px 0px 10px 0px;
      background: ${props.theme.palette.background.light};
    `};
`;

export const DropDown = styled.div<{ active?: boolean; minHeight?: string }>`
  width: 100%;
  height: 48px;
  display: flex;
  cursor: pointer;
  min-height: 48px;
  overflow: hidden;
  max-width: 315px;
  border-radius: 6px;
  padding: 16px 20px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.5s ease-out;
  background: rgba(255, 255, 255, 0.1);
  svg {
    transform: rotateZ(90deg);
    transition: transform 0.5s ease-out;
    color: ${(props) => props.theme.palette.background.light};
  }
  ${(props) =>
    props.active &&
    css`
      height: auto;
      min-height: ${props.minHeight};
      svg {
        transform: rotateZ(270deg);
      }
    `};
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  max-width: 431px;
  align-items: flex-start;
  justify-content: flex-end;

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.6);
    }
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: #fff !important;
        }
        color: rgba(255, 255, 255, 0.6);
        font-size: ${(props) => props.theme.typography.fontSize}rem;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff61;
      }
      :hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #ffffff61;
        }
      }
    }
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 47px;
      color: #ffffff61;
      background: transparent;
      border: 1px solid #ffffff61;
    }
    .selected-flag:hover,
    .flag-dropdown.open .selected-flag,
    .react-tel-input .selected-flag:focus {
      background-color: transparent;
    }
    .flag-dropdown {
      border-radius: 5px 0 0 5px;
      background-color: transparent;
      .country-list {
        width: 430px;
        color: #ffffff61;
        border-radius: 4px;
        filter: invert(0.02);
        background-color: #0d0b22;

        .country {
          :hover {
            background-color: #27244dad;
            background-color: #2d2d4a78;
          }
        }
        .country.highlight {
          background-color: #2d2d4a78;
        }
      }
    }
  }

  .react-tel-input .form-control.invalid-number {
    :focus {
      background-color: transparent;
    }
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-size: ${(props) => props.theme.typography.fontSizeRegular - 0.6}rem;
  }

  ${media.lessThan('medium')`
    .MuiFormControl-root:nth-child(n) {
      width: 100%;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
      display: -webkit-box;

    }
    .MuiInputAdornment-root {
      margin: 0;
      padding: 0;
      width: auto;
      left: -50px;
      height: 100%;
      position: relative;
    }
    .css-1lwbda4-MuiStack-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      padding: 16.5px 0px;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  `}

  input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff99;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    box-shadow: 0 0 0 1000px transparent inset;
  }
`;

export const Save = styled(ButtonSmall)`
  margin: 0;
  border-radius: 12px;
  ${media.lessThan('medium')`
    width: 100%;
    max-width: 212px;
  `}
`;

export const ContainerInput = styled.div<{ error?: boolean }>`
  width: 100%;
  display: flex;
  position: sticky;
  min-height: 72px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: flex-start;
  .invalid-number-message {
    top: 45px;
    line-height: 24px;
    font-style: normal;
    position: absolute;
    letter-spacing: 0.15px;
    background-color: transparent;
    color: ${(props) => props.theme.palette.error.main};
    font-size: ${(props) => props.theme.typography.htmlFontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightLight};
  }

  ${(props) =>
    props.error &&
    css`
      margin-bottom: 0px;
      .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
        color: #d32f2f;
      }
      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
        .MuiOutlinedInput-notchedOutline {
        border-color: #d32f2f !important;
      }

      .MuiFormHelperText-root {
        margin-bottom: 10px;
        font-size: ${props.theme.typography.fontSize}rem;
      }
    `};
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
