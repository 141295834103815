import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { amountSchema } from './AmountSchema';
import * as S from './AmountStyled';

export const Amount = () => {
  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: () => {},
    validationSchema: amountSchema
  });

  return (
    <S.AmountContainer>
      <S.Content column padding="24px">
        <S.SubTitle>The amount</S.SubTitle>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.ContainerInput>
            <TextField
              type="text"
              variant="outlined"
              id="amount"
              label="Recharge value"
              sx={{
                '& legend': {
                  fontSize: '1.2em'
                }
              }}
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              InputProps={{
                endAdornment: (
                  <S.Text>
                    <S.Initials>MATCH</S.Initials> Matchcoin
                  </S.Text>
                )
              }}
            />
          </S.ContainerInput>
        </S.Form>
      </S.Content>
    </S.AmountContainer>
  );
};
