import axios from 'axios';
import { Fields } from 'models/S3';

export const saveImageOnBucket = async (
  url: string,
  extraFields: Fields,
  file: File
) => {
  const formData = new FormData();
  const keys: string[] = Object.keys(extraFields);
  const values: string[] = Object.values(extraFields);

  keys.forEach((key: string, index: number) =>
    formData.append(key, values[index])
  );
  formData.append('file', file);

  await axios.post(url, formData);
};
