export const userTournament = {
  openGlobalTournament: {
    title: 'Open global tournamnet bets',
    card: {
      gameImage: {
        alt: 'Game image'
      },
      coin: {
        alt: 'Coin'
      },
      platformLabel: 'On the platform',
      platformIcon: {
        alt: 'Platform icon'
      },
      registeredLabel: 'Gamers'
    },
    emptyList: {
      title: 'Open global tournaments',
      description: 'The open global tournaments availables will appear here'
    }
  },
  banner: {
    title: 'Create tournaments and challenge your friends.',
    description: 'Play with your friends, create teams and much more.',
    alt: 'Create tournament banner',
    button: {
      small: 'Let`s go!',
      large: 'Let`s go!',
      goToStore: 'Go to Store'
    },
    buyNft:
      'You need the NFT ChampBet to create tournaments, click in the button to go to the store'
  },
  yourTournaments: {
    emptyState: {
      titleHistory: 'My tournament history',
      title: 'Your list of created tournaments',
      paragraph: 'When you have created tournaments, they will appear here.',
      button: `Let's go!`
    }
  },
  betAmount: {
    title: 'Bet amount',
    subTitle: 'Define the amount to be bet on the tournament',
    amount: 'Bet amount',
    balance: 'Balance:',
    eyeIcon: {
      balance: {
        alt: 'See balance'
      },
      password: {
        alt: 'See password'
      }
    },
    password: 'Financial password',
    description:
      ' In case of games played in a team, the amount is deposited and withdrawn only from the user responsible for the team.',
    buttons: {
      cancel: 'Return',
      next: 'Next',
      createTournament: 'Create'
    }
  }
};
