import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { t } from 'translate/i18n';

import icons from 'assets/img/icons';

import { ToggleButton } from 'components/elements';
import { AmountSlider } from 'components/modules';

import { Platform } from 'models/InfoCard';
import { Props } from './@types';

import * as S from './AmountAndPlatformFilterStyled';

const AmountAndPlatformFilter: React.FC<Props> = ({
  min,
  max,
  platforms,
  disabled = false,
  onChange = () => {}
}) => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState<Platform[]>([]);
  const [seeAllGames, setSeeAllGames] = useState<boolean>(true);
  const [victoryValidation, setVictoryValidation] = useState<boolean>(true);

  const translationString = 'components.amountAndPlatformFilter';

  const toggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };

  const toggleSeeAllGames = (): void => {
    setSeeAllGames(selectedPlatforms.length <= 0);
  };

  const toogleVictoryValidation = (): void => {
    setVictoryValidation(!victoryValidation);
  };

  const addPlatform = (platform: Platform): void => {
    setSelectedPlatforms([...selectedPlatforms, platform]);
  };

  const removePlatform = (platform: Platform): void => {
    setSelectedPlatforms(
      selectedPlatforms.filter((all: Platform) => all.name !== platform.name)
    );
  };

  const togglePlatform = (platform: Platform): void => {
    const platformNames = selectedPlatforms.map((item: Platform) => item.name);
    const isPlatformSelected = !platformNames?.includes(platform.name);

    if (isPlatformSelected) addPlatform(platform);
    else removePlatform(platform);
  };

  useEffect(() => {
    toggleSeeAllGames();
  }, [selectedPlatforms]);

  const renderFilter = (platform: Platform): React.ReactElement => {
    const platformNames = selectedPlatforms.map((item: Platform) => item.name);
    const isActive = platformNames?.includes(platform.name);

    return (
      <S.Filter key={v4()}>
        {t(`${translationString}.gamesFor`)} {platform?.name.toUpperCase()}
        <ToggleButton
          active={isActive}
          bindState
          onChange={() => togglePlatform(platform)}
        />
      </S.Filter>
    );
  };

  return (
    <S.Container>
      <S.Button onClick={toggleFilter} disabled={disabled}>
        <S.Icon
          src={icons.settings}
          alt={t(`${translationString}.filterIcon.alt`)}
        />
      </S.Button>

      <S.Popup open={openFilter}>
        <S.BallonLeg />
        <S.Ballon>
          <S.Title>{t(`${translationString}.amount`)}</S.Title>
          <AmountSlider
            min={min}
            max={max}
            lowestValuePlaceholder={t(
              `${translationString}.slider.lowestPlaceholder`
            )}
            highValuePlaceholder={t(
              `${translationString}.slider.highPlaceholder`
            )}
            onChange={onChange}
          />

          <S.Line />

          <S.Title>{t(`${translationString}.platforms`)}</S.Title>

          {platforms.map(renderFilter)}

          <S.Filter>
            {t(`${translationString}.seeAllGames`)}{' '}
            <ToggleButton active={seeAllGames} bindState />
          </S.Filter>

          <S.Line />

          <S.Filter>
            {t(`${translationString}.victoryValidation`)}{' '}
            <ToggleButton
              active={victoryValidation}
              bindState
              onChange={toogleVictoryValidation}
            />
          </S.Filter>
        </S.Ballon>
      </S.Popup>
    </S.Container>
  );
};

export default AmountAndPlatformFilter;
