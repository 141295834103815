import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 46px 50px;
  position: relative;
  justify-content: flex-end;
  flex-direction: column-reverse;

  @media (min-width: 1268px) {
    gap: 22px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 400px;
    grid-template-areas: 'friendsList solicitations';
  }
`;

export const FriendsList = styled.div`
  grid-area: friendsList;
`;

export const Solicitations = styled.div`
  height: auto;
  grid-area: solicitations;
`;

export const Title = styled.h1`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.title.default};
  margin-top: 16px;
  padding-left: 1px;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 43px;
    margin-top: 20px;
    padding-left: 7px;
  }
`;

export const AuxiliarTitle = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 186.711px;
  line-height: 225px;
  text-transform: uppercase;
  -webkit-text-stroke: 1.24474px rgba(255, 255, 255, 0.1);
  color: transparent;
  position: absolute;
  top: 9px;
  left: 40px;
  width: 200px;

  @media (max-width: 768px) {
    font-size: 79.9566px;
    line-height: 96px;
    top: 40px;
    left: 30px;
  }
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
  max-width: 434px;
  padding-left: 1px;

  @media (max-width: 768px) {
    margin-top: 10px;
    line-height: 20px;
    padding-left: 7px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 6px;
  position: relative;
  justify-content: space-between;

  @media (max-width: 1096px) {
    justify-content: unset;
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  height: 50px;
  display: flex;
  gap: 17px;

  @media (max-width: 1096px) {
    margin-top: 53px;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  @media (max-width: 968px) {
    justify-content: center;
  }
`;
