/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import images from 'assets/img';
import icons from 'assets/img/icons';
import { GeneralButton } from 'components/elements/GeneralButton';
import { CustomCircularProgress } from 'components/modules';
import { useModal } from 'components/modules/Modal';
import { t } from 'i18next';
import { CurrentMatch, Table } from 'pages/TournamentDetails/components';
import { useContext, useEffect, useState } from 'react';
import { getUserLanguage } from 'service/localStorage';
import { servicesApis } from 'service/service.api';
import { UserContext } from 'state/user/state';
import { usePagination } from 'utils/Pagination';
import { Matchbet } from '..';
import { AcceptTournamentType, TournamentPlayers } from './@types';
import { AcceptTournament } from './components/AcceptTournaments';
import * as S from './TournamentStyled';

const Tournament = () => {
  const { showModal } = useModal();
  const [data, setData] = useState<any>();
  const { state: userState } = useContext(UserContext);
  const [topLoading, setTopLoading] = useState(true);
  const [language] = useState(getUserLanguage());
  const [bottomLoading, setBottomLoading] = useState<boolean>(false);
  const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);
  const stagesLength = data?.stages?.map(
    (stage: { tournament_battles: { length: number }[] }) =>
      stage.tournament_battles.length
  );
  const updateData = () => {
    setShouldUpdateData(true);
  };
  const initialOrder = Number(localStorage.getItem('initialOrder')) || 1;
  const [stageSelected, setStageSelected] = useState<{
    order: number;
  }>({
    order: initialOrder
  });

  const translationString = 'components.tournament';

  const getStageTitle = (order: number, stageQtd: number) => {
    const specialNames: { [key: number]: string } = {
      0: 'Final',
      1: t(`${translationString}.stages.semiFinal`),
      2: t(`${translationString}.stages.quarterFinal`)
    };
    const stageNum = stageQtd - order;
    return Object.keys(specialNames)
      .map((_, i: number) => {
        return 1 * i;
      })
      .includes(order)
      ? specialNames[order]
      : `Fase ${stageNum}`;
  };

  const [stages, setStages] = useState([
    { title: '', order: 1, avaliable: true, matches: 2 }
  ]);

  const getStages = (
    firstStage: { order: number },
    stagesAvaliable: number[]
  ) => {
    const stagesQtd = firstStage.order + 1;
    const emptyStage = new Array(stagesQtd).fill({
      title: '',
      order: 0,
      avaliable: false,
      matches: 0
    });
    const newStages = emptyStage.map((_, index) => {
      const currentOrder = stagesQtd - index - 1;
      const isStageAvaliable = stagesAvaliable.includes(currentOrder);
      const matchCount = 2 ** currentOrder + 0 ** currentOrder;

      return {
        title: getStageTitle(currentOrder, stagesQtd),
        order: currentOrder,
        avaliable: isStageAvaliable,
        matches: matchCount
      };
    });
    setStages(newStages);
    if (stagesAvaliable.length <= 1) {
      localStorage.setItem('initialOrder', firstStage.order.toString());
    }
  };

  const {
    clickOnLeftArrow,
    clickOnRightArrow,
    clickOnPageIndicator,
    calculatePaginationVars,
    numberOfPages,
    selectedPage,
    data: dataToTable
  } = usePagination({ ROWS_REQUIRED: 9 });

  const [detailsTournament, setDetailsTournament] =
    useState<AcceptTournamentType>({
      name: '',
      amount: '',
      image_url: '',
      entity_id: '',
      platforms: { entity_id: '', name: '', image_url: '' }
    });

  const tournamentPlayers = data?.tournament_players;

  const verifyIfPlayerIsRegistered = !!tournamentPlayers?.find(
    (player: TournamentPlayers) =>
      player.user.entity_id === userState.user.entityId
  );

  const checkAvailability = () => {
    const checkDateTime = new Date(data?.start_date) < new Date();
    const verifyAmountPlayers =
      data?.tournament_players.length >= data?.max_players;

    return checkDateTime || verifyAmountPlayers;
  };

  const onClickAccept = async () => {
    setTopLoading(true);
    showModal(
      <AcceptTournament data={detailsTournament} callback={updateData} />,
      'min-content',
      true,
      '42px'
    );
    setTopLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setBottomLoading(true);
      try {
        const response = await servicesApis.tournament.getTournament();
        if (response.data[0].stages.length > 0) {
          const currentState =
            response.data[0].stages.find(
              (stage: { order: number }) => stage.order === stageSelected.order
            ) || [];
          const avaliableStages = response.data[0].stages.map(
            (stage: { order: number }) => {
              return stage.order;
            }
          );

          getStages(response.data[0].stages[0], avaliableStages);
          calculatePaginationVars(currentState?.tournament_battles || []);
        }
        const responseFormat = {
          ...(response.data[0] || {}),
          entry_fee: response.data[0].entry_fee || '',
          start_date: response.data[0].start_date
            ? new Date(response.data[0].start_date).toLocaleDateString('pt-BR')
            : ''
        };

        const body = {
          name: responseFormat.name || '',
          amount: responseFormat.entry_fee || '',
          image_url: images.valorant_thamb,
          entity_id: responseFormat.entity_id || '',
          platforms: responseFormat.platform || '',
          tournament_players: responseFormat.tournament_players || ''
        };

        setData(responseFormat);
        setDetailsTournament(body);
      } catch (error) {
        console.log('Error fetching tournament:', error);
      } finally {
        setTopLoading(false);
        setShouldUpdateData(false);
        setBottomLoading(false);
      }
    };

    if (shouldUpdateData) {
      fetchData();
    }
  }, [shouldUpdateData]);

  useEffect(() => {
    setShouldUpdateData(true);
  }, [stageSelected.order]);

  const tournamentStatus = data?.status;

  const hasTournament = data?.entity_id !== undefined;

  const getBanner = () => {
    return (
      <S.BannerContainer img={data?.image_url}>
        {hasTournament ? (
          <S.ConntentDescription>
            {tournamentStatus === 'FINISHED' && (
              <S.Title yellow>
                {' '}
                {t(`${translationString}.finishedMessage`)}
              </S.Title>
            )}

            <S.Text>
              {t(`${translationString}.subTitle`, {
                name: detailsTournament.name
              })}
            </S.Text>
            <S.Title>
              {t(`${translationString}.title01`)}
              <S.Title yellow>
                {data?.first_prize} {t(`${translationString}.title02`)}
              </S.Title>
            </S.Title>
            <S.Text>{t(`${translationString}.description`)}</S.Text>
            <S.Text>
              {t(`${translationString}.text`)} {data?.start_date}
            </S.Text>
            {verifyIfPlayerIsRegistered && (
              <S.Title yellow small registered>
                {t(`${translationString}.playerAlreadyRegistered`)}
              </S.Title>
            )}
            <GeneralButton
              size="large"
              variant="primary"
              isLoading={topLoading}
              disabled={checkAvailability() || verifyIfPlayerIsRegistered}
              onClick={() => onClickAccept()}
            >
              {t(`${translationString}.button`)}
            </GeneralButton>
            <S.RulesTermo
              href={
                language === 'pt-BR'
                  ? 'https://sales.matchbet.io/regras-de-jogo-valorant/'
                  : 'https://sales.matchbet.io/regras-de-jogo-valorant/'
              }
              target="_blank"
            >
              {t(`${translationString}.rules`)}
            </S.RulesTermo>
          </S.ConntentDescription>
        ) : (
          <S.EmptyContainer>
            <S.Title yellow>
              {t(`${translationString}.emptyTournament.title`)}
            </S.Title>
            <S.Text>
              {t(`${translationString}.emptyTournament.paragraph`)}
            </S.Text>
          </S.EmptyContainer>
        )}
      </S.BannerContainer>
    );
  };

  return (
    <Matchbet>
      <S.Container>
        {topLoading ? (
          <CustomCircularProgress />
        ) : (
          <>
            {getBanner()}
            {hasTournament && (
              <S.ListContainer>
                <S.Card>
                  <S.Icon
                    src={icons.cup}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.awardTitle`)}
                    </S.Title>
                    <S.Text small>{data?.first_prize}</S.Text>
                  </S.ContainerText>
                </S.Card>
                <S.Card>
                  <S.Icon
                    src={icons.moedaOuro}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.entranceFee`)}
                    </S.Title>
                    <S.Text small>{data?.entry_fee} Matchoins</S.Text>
                  </S.ContainerText>
                </S.Card>
                <S.Card>
                  <S.Icon
                    formated
                    src={data?.game.image_url}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.gameTitle`)}
                    </S.Title>
                    <S.Text small>{data?.game.name}</S.Text>
                  </S.ContainerText>
                </S.Card>
                <S.Card>
                  <S.Icon
                    src={data?.platform.image_url}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.platform`)}
                    </S.Title>
                    <S.Text small>{data?.platform.name}</S.Text>
                  </S.ContainerText>
                </S.Card>
                <S.Card>
                  <S.Icon
                    src={icons.players}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.maxPlayersTitle`)}
                    </S.Title>
                    <S.Text small>{data?.max_players}</S.Text>
                  </S.ContainerText>
                </S.Card>
                <S.Card>
                  <S.Icon
                    src={icons.players}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.minPlayersTitle`)}
                    </S.Title>
                    <S.Text small>{data?.min_players}</S.Text>
                  </S.ContainerText>
                </S.Card>
                <S.Card>
                  <S.Icon
                    src={icons.players}
                    alt={t(`${translationString}.altIcon`)}
                  />
                  <S.ContainerText>
                    <S.Title small>
                      {t(`${translationString}.registeredPlayers`)}
                    </S.Title>
                    <S.Text small>{data?.tournament_players.length}</S.Text>
                  </S.ContainerText>
                </S.Card>
                {data?.current_match?.entity_id && (
                  <CurrentMatch
                    entity_id={data.current_match.entity_id}
                    image_url={data.current_match.image_url}
                    player1={data.current_match.player1}
                    player2={data.current_match.player2}
                    tournamentId={data.entity_id}
                    userId={userState.user.entityId}
                    battleStatus={data.current_match.status}
                    callBack={updateData}
                    isLoading={bottomLoading}
                  />
                )}
              </S.ListContainer>
            )}
          </>
        )}
        {data?.stages.length > 0 && (
          <>
            <Table
              isLoading={bottomLoading}
              stagesLength={stagesLength}
              data={dataToTable}
              isAdmin={false}
              tournamentId={data?.entity_id}
              tournamentCreatedById={data?.created_by}
              callback={updateData}
              stageSelected={stageSelected}
              setStageSelected={setStageSelected}
              stages={stages}
            />
            <S.Pagination
              selectedPage={selectedPage}
              numberOfPages={numberOfPages}
              clickOnLeftArrow={clickOnLeftArrow}
              clickOnRightArrow={clickOnRightArrow}
              clickOnPageIndicator={clickOnPageIndicator}
            />
          </>
        )}
      </S.Container>
    </Matchbet>
  );
};

export default Tournament;
