/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Props } from './@types';
import * as S from './CountDownStyled';

const CountDown = ({ startDate, isNow = false }: Props) => {
  const [daysState, setDays] = useState('00');
  const translationString = 'components.countDown';
  const [hoursState, setHoursState] = useState('00');
  const [minutesState, setMinutesState] = useState('00');
  const interval: React.MutableRefObject<any> = useRef(null);

  const changeDecimal = (value: string) =>
    value?.length <= 1 ? `0${value}` : value;

  const startTimer = () => {
    const date = new Date(startDate);
    const countDownDate = new Date(date).getTime();
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    interval.current = setInterval(() => {
      const now = Date.now();
      const distance = countDownDate - now;

      const days = String(Math.floor(distance / DAY));
      const hours = String(Math.floor((distance % DAY) / HOUR));
      const minutes = String(Math.floor((distance % HOUR) / MINUTE));
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setDays(changeDecimal(days));
        setHoursState(changeDecimal(hours));
        setMinutesState(changeDecimal(minutes));
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <S.ContentDescription>
      <S.CounterTitle>{t(`${translationString}.title`)}</S.CounterTitle>
      {isNow ? (
        <S.CountDownContainer>
          <S.CounterNow>{t(`${translationString}.now`)}</S.CounterNow>
        </S.CountDownContainer>
      ) : (
        <S.CountDownContainer>
          <S.Counter>
            {daysState}
            <S.CounterDescription>
              {t(`${translationString}.dayText`)}
            </S.CounterDescription>
          </S.Counter>
          <S.Pointer>:</S.Pointer>
          <S.Counter>
            {hoursState}
            <S.CounterDescription>
              {t(`${translationString}.hoursText`)}
            </S.CounterDescription>
          </S.Counter>
          <S.Pointer>:</S.Pointer>
          <S.Counter>
            {minutesState}
            <S.CounterDescription>
              {t(`${translationString}.minutesText`)}
            </S.CounterDescription>
          </S.Counter>
        </S.CountDownContainer>
      )}
    </S.ContentDescription>
  );
};

export default CountDown;
