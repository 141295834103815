import styled from 'styled-components';

export const WinnerModalContainer = styled.div`
  background: rgba(74, 62, 94, 0.95);
  border-radius: 12px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-family: 'Poppins';
`;

export const Title = styled.p`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.02em;
  width: 100%;
  text-align: left;
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 119%;
  width: 100%;
  text-align: left;
`;

export const Compentitors = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin: 40px 0;

  font-style: normal;
  font-weight: 600;
  font-size: 29.2941px;
  line-height: 130%;
  letter-spacing: 0.01em;
`;

export const Competitor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: relative;
`;

export const Separator = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WinnerTag = styled.div<{
  isWinner: boolean;
}>`
  display: ${({ isWinner }) => (isWinner ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 146px;
  height: 38px;

  top: calc(160px - 38px - 8px);
  left: calc((160px - 146px) / 2);

  background: linear-gradient(
    271.95deg,
    #ffb622 -2.55%,
    #de8971 39.08%,
    #a73df8 110.96%
  );
  border-radius: 6.77622px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.004em;
`;

export const CompetitorImage = styled.img`
  width: 160px;
  height: 160px;
  background-size: 160px;
  border-radius: 14.6471px;
`;

export const CompetitorNickname = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.01em;
  text-align: center;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
`;
