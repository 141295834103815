/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useReducer } from 'react';
import { ProfileNotificationsProviderType } from './@types';
import profileNotificationsReducer from './reducer';

const initialState: any = {
  notifications: [],
  status: {
    loading: false
  }
};

export const ProfileNotificationsContext = createContext(initialState);

const ProfileNotificationsProvider: React.FC<
  ProfileNotificationsProviderType
> = ({ children }) => {
  const [state, dispatch] = useReducer(
    profileNotificationsReducer,
    initialState
  );
  const provider = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ProfileNotificationsContext.Provider value={provider}>
      {children}
    </ProfileNotificationsContext.Provider>
  );
};

export default ProfileNotificationsProvider;
