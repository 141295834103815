import styled, { css } from 'styled-components';

export const List = styled.ul<{ empty: boolean }>`
  height: 585px;
  margin-top: 25px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${({ empty }) =>
    empty &&
    css`
      background-color: ${({ theme }) => theme.palette.background.lightBlur};
      border-radius: 12px;
    `}
`;

export const Item = styled.li`
  list-style: none;
  width: 100%;
  padding: 4px 12.5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 10px;
  }
`;

export const Name = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 115%;
  letter-spacing: 0.0015em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const BetWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BetCoin = styled.img`
  width: 36px;
  height: 34px;
  object-fit: contain;
`;

export const BetValue = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.palette.text.white};
`;
