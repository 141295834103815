import React from 'react';
import { t } from 'translate/i18n';

import * as S from './EmptyStyled';

const Empty: React.FC = () => {
  const translationString = 'components.MatchBetStore.emptyComponent';

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>{t(`${translationString}.title`)}</S.Title>
        <S.Description>{t(`${translationString}.description`)}</S.Description>
      </S.Container>
    </S.Wrapper>
  );
};

export default Empty;
