import React from 'react';
import { Skeleton } from '@mui/material';

import { Platform, SelectPlatformProps } from './@types';

import * as S from './SelectPlatformStyled';

const SelectPlatform = ({
  platforms,
  availablePlatforms,
  isLoading
}: SelectPlatformProps) => (
  <S.Container>
    {(isLoading ? new Array(4).fill(1) : platforms)?.map(
      (platform: Platform) => {
        if (isLoading) {
          return (
            <Skeleton
              variant="circular"
              style={{
                minWidth: 24,
                width: 24,
                height: 24
              }}
            />
          );
        }

        const isPlatformActived = !!availablePlatforms?.find(
          (availablePlatform) =>
            availablePlatform.entity_id === platform.entity_id
        );

        return (
          <S.Platform actived={isPlatformActived}>
            <S.PlatformIcon src={platform.image_url} alt="platform image" />
          </S.Platform>
        );
      }
    )}
  </S.Container>
);

export default SelectPlatform;
