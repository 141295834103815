import { t } from 'i18next';
import { CountDown, Registered } from 'components/modules';
import icons from 'assets/img/icons';
import { useState } from 'react';
import {
  PropsContentEnterTournament,
  PropsFormDeleteTournament
} from './@types';

import * as S from '../EventModalStyled';

export const ContentDeleteTournament = ({
  start_date = '',
  max_players = 0,
  registered = 0
}: PropsFormDeleteTournament) => {
  return (
    <S.DeleteTournament>
      <CountDown startDate={start_date} />
      <Registered max={max_players} text="Gamers" registered={registered} />
    </S.DeleteTournament>
  );
};

export const ContentEnterTournament = ({
  error = false,
  translationString = '',
  formik = {}
}: PropsContentEnterTournament) => {
  const [seePass, setSeePass] = useState(false);
  return (
    <>
      <S.PasswordWrapper error={error}>
        <S.Field
          id="password"
          type={seePass ? 'text' : 'password'}
          placeholder={t(`${translationString}.password.placeholder`)}
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <S.SeePassIcon
          src={icons.eye}
          alt={t(`${translationString}.eyeIcon.alt`)}
          show={seePass}
          error={error}
          onClick={() => setSeePass(!seePass)}
        />
      </S.PasswordWrapper>
      {error ? <S.Error>{formik.errors.password}</S.Error> : null}
    </>
  );
};
