import styled, { css } from 'styled-components';

export const NumberWrapper = styled.div<{ $isActive: boolean }>`
  position: relative;
  :hover > p {
    opacity: ${(props) => (props.$isActive ? 1 : 0)};
  }
`;
export const FloatingAmount = styled.p<{ position: 'top' | 'left' }>`
  padding: 7px 11px;
  position: absolute;
  opacity: 0;
  width: max-content;
  border-radius: 5px;
  letter-spacing: 1px;
  font-family: 'Poppins';
  background: #77798b;
  transition: opacity 0.8s ease-out;
  color: ${(props) => props.theme.palette.background.light};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: ${(props) => props.theme.typography.fontSize - 0.6}rem;

  ::after {
    width: 0;
    height: 0;
    content: '';
    clear: both;
    position: absolute;
    border-left: 5px solid #77798b;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  ${({ position }) =>
    position === 'top' &&
    css`
      bottom: 40px;
      left: 30%;

      ::after {
        left: 10px;
        bottom: -5px;
      }
    `};

  ${({ position }) =>
    position === 'left' &&
    css`
      bottom: -5px;
      left: -220%;

      ::after {
        right: -5px;
        bottom: 10px;
      }
    `};
`;
