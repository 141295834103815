import { useContext } from 'react';
import { Actions } from 'state/notification/@types/actions';
import { NotificationContext } from 'state/notification/state';
import * as S from './MessageSuccessStyled';

const MessageSuccess = () => {
  const { state, dispatch } = useContext(NotificationContext);
  const { open, message } = state.notification;

  const onClickButton = (): void => {
    dispatch({
      type: Actions.CLOSE
    });
  };

  return (
    <S.MessageSuccessContainer show={open}>
      <S.Text>{message}</S.Text>
      <S.Register onClick={onClickButton}>
        {message?.includes('password') ? 'X' : 'X'}
      </S.Register>
    </S.MessageSuccessContainer>
  );
};

export default MessageSuccess;
