import { format, formatISO, parseISO } from 'date-fns';

export const dateAndTimeFormatter = (
  date: string,
  hasWordSeparator = false,
  pattern = 'dd/MM/yyyy'
) => {
  if (!date) {
    return '';
  }

  if (hasWordSeparator) {
    return format(parseISO(date), `${pattern}, 'às' HH:mm`);
  }

  return format(parseISO(date), `${pattern} - HH:mm`);
};

export const dateFormatted = (date: Date): string => {
  if (!date) return '';

  const dateISO = date.toISOString();
  return dateISO;
};

export const getDateFormatted = (date: string): string => {
  if (!date) return '';

  const dateLocal = new Date(date);
  const dateString = formatISO(dateLocal);
  return dateString;
};

export const betinToMatch = (betins: string, decimal_separator = '.') => {
  const prep = betins.padStart(19, '0');
  const prep2 =
    prep.slice(0, prep.length - 18) +
    decimal_separator +
    prep.slice(prep.length - 18, prep.length);
  return prep2.replace(/[!0]+$/g, '');
};
