export const friends = {
  title: 'your friends',
  auxiliarTitle: 'your friends',
  description:
    'Play with your friends, invite them to the platform and earn matchcoins with referrals',
  addButton: 'Invite',
  searchPlaceholder: 'Search',
  friendCard: {
    remove: 'Remove'
  },
  inviteAFriend: {
    title: 'Invite friend',
    description: 'Add friends, play and have fun <br /> with them',
    inputPlaceholder: 'e-mail',
    submitButton: 'send invitation',
    error: {
      unknown_error: 'Unknown Error',
      friends_request_to_user_requester: `You can't send a friend request to yourself`,
      friends_already: 'Friends already',
      friends_request_already_sent: 'Friend request already sent',
      friends_request_not_found: 'Friend request not found'
    }
  },
  solicitations: {
    betOnAddNewFriends: '+22 BET'
  },
  emptyList: {
    title: 'Invite friends',
    description: 'Play with your friends, it makes everything more fun',
    button: 'Invite'
  },
  popup: {
    friendDelete: 'Friend deleted',
    friendDeleteError: 'Error deleting friend'
  }
};
