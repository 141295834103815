import { getIcon } from 'assets/img/icons';
import * as S from './MessageStyled';

export const Message = () => {
  return (
    <S.MessageContainer>
      <S.Icon src={getIcon('successo')} alt="Icone de demostração" />
      <S.Title>Withdrawal order sent</S.Title>
      <S.Text>
        Withdrawal request sent. Visit the history to check the status of your
        order.
      </S.Text>
      <S.Title small>withdrawal amount</S.Title>
      <S.Title large>MATCH 500.00 </S.Title>
    </S.MessageContainer>
  );
};
