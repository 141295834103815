/* eslint-disable no-irregular-whitespace */
import './Home.css';
import ValorantImg from '../img/valorant.png';
import CSGOImg from '../img/csgo.png';
import FortniteImg from '../img/fortnite.png';
import LolImg from '../img/lol.png';
import StreetFighterImg from '../img/streetfighter.png';
import InstagramImg from '../img/instagram.png';
import FacebookImg from '../img/facebook.png';
import TwitterImg from '../img/twitter.png';
import TelegramImg from '../img/telegram.png';
import YoutubeImg from '../img/youtube.png';
import WhatsAppImg from '../img/whatsapp.png';

const Home: React.FC = () => {
  return (
    <>
      <header id="Header" className="fix">
        <div className="container">
          <h1 className="logo rpl">
            <a href="/" title="MatchCash">
              MatchCash
            </a>
          </h1>
          <h2 className="fnt-russo">
            Você joga games online? E joga bem? É bom de verdade? 
          </h2>
          <h3 className="fnt-russo">Então, você está no lugar certo.</h3>
          <p>
            Seja bem-vindo à <strong>MatchCash</strong>, a plataforma de
            disputas entre usuários ou entre equipes.
          </p>
          <p>
            <strong>MatchCash</strong> é pura adrenalina: aqui você aposta suas{' '}
            <strong>MatchCoins</strong>, o “dinheiro” da <br />
            MatchCash, nos seus próprios conhecimentos e habilidades, jogando os
            games em que <br /> você é craque. 
          </p>
          <p>
            Mostre do que você é capaz, acumule MatchCoins e sinta a emoção de
            ser reconhecido <br /> como a fera dos games online.
          </p>
          <p>
            A <strong>MatchCash</strong> é a plataforma para os gamers que
            buscam uma dose extra de adrenalina.
          </p>

          <a href="/sign-in" className="btn">
            fazer cadastro / entrar
          </a>
        </div>
      </header>
      <section id="ComoFunciona">
        <div className="container fix">
          <header>
            <h2 className="title-n1 fnt-russo">Como Funciona</h2>
            <p>
              A <strong>MatchCash</strong> possui uma moeda própria, a{' '}
              <strong>MatchCoin</strong>. E para vivenciar toda essa <br />
              experiência, primeiro você deve adquiri-las!
            </p>
          </header>

          <div className="columns">
            <article className="box">
              <h3 className="fnt-russo">Compre suas moedas</h3>
              <p>
                As <strong>MatchCoins</strong> estão disponíveis para venda e
                são muito simples de comprar. Aqui, os gamers não precisam
                formar times ou ter patrocinadores: é pagar barato, entrar,
                apostar e jogar!
              </p>
            </article>
            <article className="box">
              <h3 className="fnt-russo">
                Desafie seus amigos e aposte suas MatchCoins contra as deles!
              </h3>

              <p>
                Aceite ou proponha desafios apostando as{' '}
                <strong>MatchCoins</strong> que você quiser botar em jogo, jogue
                contra seus amigos ou entre em disputas abertas. Você pode
                escolher qualquer jogo e plataforma para disputar com outros
                gamers. Jogue naquele em que você é especialista.
              </p>
            </article>
            <article className="box">
              <h3 className="fnt-russo">
                Ganhe ou perca MatchCoins de acordo com sua habilidade
              </h3>
              <p>
                Se você ganhar, você acumula parte das{' '}
                <strong>MatchCoins</strong> do seu oponente - seu saldo vai
                aparecer na sua conta, e você vai poder jogar mais e mais. Você
                ganha MatchCoins se for melhor, perde se for pior. Simples
                assim.
              </p>
            </article>
          </div>
          <h4 className="fnt-russo">E aí, vai encarar?</h4>
          <a href="/sign-in" className="btn" title="Quero Jogar">
            quero jogar
          </a>
        </div>
      </section>

      <section id="ContainerGames">
        <header>
          <h3 className="title-n1 fnt-russo">
            Escolha seu game favorito e divirta-se!
          </h3>
        </header>
        <div className="container fix">
          <article className="box">
            <img src={ValorantImg} alt="Valorant Logo" />
            <div className="info">
              <h3 className="fnt-russo">Valorant</h3>
              <p>
                Um FPS tático 5x5 com personagens marcantes, mecânica de tiro
                precisa e habilidades únicas.
              </p>
            </div>
          </article>

          <article className="box">
            <img src={CSGOImg} alt="CS Go Logo" />
            <div className="info">
              <h3 className="fnt-russo">CS:go</h3>
              <p>
                Um dos jogos de tiro em primeira pessoa mais populares e jogados
                da atualidade.
              </p>
            </div>
          </article>

          <article className="box">
            <img src={FortniteImg} alt="Fortnite Logo" />
            <div className="info">
              <h3 className="fnt-russo">Fortnite</h3>
              <p>
                Um mundo de muitas experiências. Entre na Ilha e na competição
                para ser o último jogador ou equipe sobrevivente!
              </p>
            </div>
          </article>

          <article className="box">
            <img src={LolImg} alt="League Of Legends Logo" />
            <div className="info">
              <h3 className="fnt-russo">League of legends</h3>
              <p>
                Um jogo em equipes com mais de 140 campeões com os quais você
                pode fazer jogadas épicas, assegurar abates e destruir torres
                conforme você luta até a vitória.
              </p>
            </div>
          </article>

          <article className="box">
            <img src={StreetFighterImg} alt="Street Fighter Logo" />
            <div className="info">
              <h3 className="fnt-russo">street fighter v</h3>
              <p>
                Escolha entre 40 lutadores diversos, 34 fases dinâmicas e mais
                de 200 trajes estilosos, lutando em uma variedade de modos solo
                e multijogador empolgantes.
              </p>
            </div>
          </article>

          <article className="box">
            <div className="info last">
              <h4 className="fnt-russo title-n1">E muito mais!</h4>
              <a href="/sign-in" className="btn" title="Quero Jogar">
                quero jogar
              </a>
            </div>
          </article>
        </div>
      </section>

      <section id="JogarComAmigos">
        <div className="container fix">
          <h3 className="title-n1 fnt-russo">
            Jogue com seus amigos,
            <br /> crie times e muito mais!
          </h3>
          <p>
            Aqui na <strong>MatchCash</strong>, ao criar uma batalha, além da{' '}
            <br />
            opção de jogar sozinho, você também tem a <br />
            possibilidade de jogar em equipe. Para isso, basta <br />
            criar um time ou escolher um já existente!
          </p>

          <a href="/sign-in" className="btn" title="Quero Jogar">
            quero jogar
          </a>
        </div>
      </section>

      <section id="CriarTorneio">
        <div className="container fix">
          <article className="info">
            <h3 className="title-n1 fnt-russo">
              Crie um torneio ou escolha um <br /> para entrar!
            </h3>
            <p>
              Com a <strong>MatchCash</strong>, você pode criar torneios entre
              seus amigos ou entrar na disputa em busca do primeiro lugar e sair
              com o prêmio. Primeiro, escolha entre criar um time ou participar
              de um. Depois, só selecionar se irá jogar com seus amigos ou em um
              torneio aberto. Que vença o melhor!
            </p>
            <a href="/sign-in" className="btn" title="Quero Jogar">
              quero jogar
            </a>
          </article>
        </div>
      </section>

      <section id="SectionMatchcoins">
        <div className="container fix">
          <h3 className="title-n1 fnt-russo">
            E se suas MatchCoins <br /> virassem dinheiro?{' '}
          </h3>
          <p>
            Suas <strong>MatchCoins</strong>, no futuro, podem virar
            criptomoedas
            <br />, permitindo que os campeões troquem suas conquistas por
            <br />
            dinheiro, se quiserem. Com isso, a MatchCash poderá representar{' '}
            <br />
            uma oportunidade única de monetizar seu talento e paixão pelos
            <br />
            jogos. Fique de olho!
          </p>

          <a href="/sign-in" className="btn" title="Quero Jogar">
            quero jogar
          </a>
        </div>
      </section>

      <section id="BoxDescubra">
        <h3 className="title-n1 fnt-russo">
          Descubra uma nova forma de viver a sua paixão por games <br /> e seja
          recompensado por isso com a MatchCash!
        </h3>
      </section>

      <footer id="Footer">
        <h1 className="logo rpl">
          <a href="/" title="MatchCash">
            MatchCash
          </a>
        </h1>

        <section className="multi-contact fix">
          <a
            className="social"
            href="https://www.instagram.com/matchbet.br/"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={InstagramImg} alt="Instagram Logo" />
            </figure>
            <p className="fnt-russo">Instagram</p>
          </a>
          <a
            className="social"
            href="https://www.facebook.com/matchbetbr"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={FacebookImg} alt="Facebook Logo" />
            </figure>
            <p className="fnt-russo">Facebook</p>
          </a>
          <a
            className="social"
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FMatchBet4"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={TwitterImg} alt="Twitter Logo" />
            </figure>
            <p className="fnt-russo">Twitter</p>
          </a>
          <a
            className="social"
            href="https://t.me/matchbetusa "
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={TelegramImg} alt="Telegram Logo" />
            </figure>
            <p className="fnt-russo">
              Pre-Venda <br /> Telegram
            </p>
          </a>
          <a
            className="social"
            href="https://t.me/matchbetusa"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={TelegramImg} alt="Telegram Logo" />
            </figure>
            <p className="fnt-russo">Canal Telegram</p>
          </a>
          <a
            className="social"
            href="https://www.youtube.com/channel/UC5yOZ44_VRh5ivKqvF5HDqw"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={YoutubeImg} alt="Youtube Logo" />
            </figure>
            <p className="fnt-russo">YouTube</p>
          </a>
          <a
            className="social"
            href="https://api.whatsapp.com/send/?phone=5521995704731&text&type=phone_number&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img src={WhatsAppImg} alt="Whatsapp Logo" />
            </figure>
            <p className="fnt-russo">WhatsApp</p>
          </a>
        </section>

        <section className="copyright fix">
          © 2023 MatchCash. Todos direitos reservados.
        </section>
      </footer>
    </>
  );
};

export default Home;
