import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.h2`
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 20.6557px;
  line-height: 25px;
  text-transform: uppercase;
  color: #ffffff;
`;

export const Description = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15.7377px;
  line-height: 20px;
  letter-spacing: 0.147541px;
  color: #ffffff;
  width: 252px;
  margin-top: 6px;

  @media (max-width: 290px) {
    width: 100%;
  }
`;

export const IndicationLinkWrapper = styled.div`
  margin-top: 20px;
`;
