import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Icon = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
`;

export const Popup = styled.div<{ openFilter?: boolean }>`
  position: absolute;
  top: 68px;
  right: -20px;
  z-index: 1;

  @media (max-width: 1096px) {
    left: -20px;
  }

  ${({ openFilter }) =>
    !openFilter &&
    css`
      display: none;
    `}
`;

export const BallonLeg = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14.5px 11px 14.5px;
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
  position: absolute;
  top: -11px;
  right: 27px;
`;

export const Content = styled.div`
  width: calc(365px - 40px);
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 384px) {
    width: 250px;
  }

  @media (max-width: 309px) {
    width: 200px;
  }
`;

export const Title = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.005em;
  color: ${({ theme }) => theme.palette.text.white};
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const InputWrapper = styled.div`
  width: 151px;
`;
