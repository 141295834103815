import { getIcon } from 'assets/img/icons';
import Card from 'components/modules/Card/Card';
import { Message } from 'pages/Account/components/Wallet/Transfer/components/Password/components';
import { useContext } from 'react';
import { Actions } from 'state/user/@types/actions';
import { UserContext } from 'state/user/state';
import { t } from 'translate/i18n';
import { Props } from '../@types';
import { Amount, Password, UserWalletTransfer } from './components';
import * as S from './TransferStyled';

export const Transfer = ({ callback, close }: Props) => {
  const { state, dispatch } = useContext(UserContext);
  const translationString = 'components.transfer';
  const CloseModal = () => {
    close();

    dispatch({
      type: Actions.SET_WALLET_DATA,
      payload: {
        Step: 1,
        emailTransfer: '',
        Amount: '',
        Wallet: ''
      }
    });
  };
  return (
    <S.TransferContainer>
      <Card width="479px" height="441px" padding="0" background="#ffffff19">
        <S.Content column>
          <S.Content header padding="16px 0px 16px 22px">
            <S.Title>
              {t(`${translationString}.title`)}
              <S.Icon
                onClick={() => close()}
                src={getIcon('Close')}
                alt="Icone de fechar"
              />
            </S.Title>
          </S.Content>
          {(() => {
            switch (state.user.Step) {
              case 1:
                return <UserWalletTransfer actionCloseModal={CloseModal} />;
              case 2:
                return <Amount />;
              case 3:
                return (
                  <Password actionCloseModal={CloseModal} callback={callback} />
                );
              case 4:
                return <Message />;
              default:
                return <UserWalletTransfer actionCloseModal={CloseModal} />;
            }
          })()}
        </S.Content>
      </Card>
    </S.TransferContainer>
  );
};
