import { t } from 'i18next';
import React, { useContext } from 'react';

import { useModal } from 'components/modules/Modal';
import { NftStoreListContext } from 'state/nft/state';
import { NftUserItemView } from './@types';

import * as S from './NftStoreItemViewProductStyled';

const NftStoreItemViewProduct = ({
  name,
  text,
  value,
  description
}: NftUserItemView) => {
  const translationString = 'components.nft.viewProduct';
  const { state } = useContext(NftStoreListContext);
  const { closeModal } = useModal();

  return (
    <S.NftViewProductContainer onClick={() => closeModal()}>
      <S.Container>
        <S.Img
          id="nft-image"
          src={state.nft_store_item_selected.nft.image_url}
        />
        <S.ContainerDescription>
          <S.Title>{name}</S.Title>
          <S.SubTitle roboto>{text}</S.SubTitle>
          <S.Text margin="20px 0 0">{description}</S.Text>
        </S.ContainerDescription>
        <S.ContainerBuy>
          <S.Text>{t(`${translationString}.text`)}</S.Text>
          <S.Title>{value} BET</S.Title>
        </S.ContainerBuy>
      </S.Container>
    </S.NftViewProductContainer>
  );
};

export default NftStoreItemViewProduct;
