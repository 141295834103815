import React from 'react';

import images from 'assets/img';
import icons from 'assets/img/icons';

import { GeneralButton } from 'components/elements';

import { t } from 'translate/i18n';
import { formatMoney } from 'utils/formatMoney';
import { useEventModal } from './useEventModal';

import { PropsEventModal } from './@types';
import { CustomCircularProgress } from '../CustomCircularProgress';

import * as S from './EventModalStyled';

const EventModal: React.FC<PropsEventModal> = ({
  data,
  Children,
  onSubmit,
  initialValues = {},
  validationSchema = null
}) => {
  const { formik, loading, closeModal } = useEventModal({
    onSubmit,
    data,
    initialValues,
    validationSchema
  });
  const {
    platform,
    amount,
    image_url,
    title,
    translationString,
    start_date,
    max_players,
    registered
  } = data;

  return (
    <S.Container>
      <S.Title>{t(`${translationString}.title`)}</S.Title>
      <S.Content>
        <S.GameImage
          src={image_url || images.defaultImage}
          alt={t(`${translationString}.gameImage.alt`)}
        />
        <S.BattleInfo>
          <S.MainInfo>
            <S.Name>{title}</S.Name>
            <S.CoinWrapper>
              <S.CoinIcon
                src={icons.moedaOuro}
                alt={t(`${translationString}.coinIcon.alt`)}
              />
              <S.Value>{formatMoney(amount)}</S.Value>
            </S.CoinWrapper>
          </S.MainInfo>
          <S.PlatformWrapper>
            <S.PlatformLabel>
              {t(`${translationString}.platforms`)}
            </S.PlatformLabel>
            <S.PlatformIcon src={platform?.image_url} alt={platform?.name} />
          </S.PlatformWrapper>
          <S.Form onSubmit={formik.handleSubmit} deleteTournament>
            <Children
              error={!!formik.errors.password}
              formik={formik}
              translationString={translationString}
              start_date={start_date}
              max_players={max_players}
              registered={registered}
            />
            <S.ButtonsWrapper>
              {loading ? (
                <CustomCircularProgress />
              ) : (
                <>
                  <GeneralButton
                    type="button"
                    size="medium"
                    variant="primary"
                    transparent
                    onClick={closeModal}
                  >
                    {t(`${translationString}.buttons.cancel`)}
                  </GeneralButton>
                  <GeneralButton
                    type="submit"
                    size="medium"
                    variant="primary"
                    isLoading={loading}
                  >
                    {t(`${translationString}.buttons.create`)}
                  </GeneralButton>
                </>
              )}
            </S.ButtonsWrapper>
          </S.Form>
        </S.BattleInfo>
      </S.Content>
    </S.Container>
  );
};

export default EventModal;
