import { t } from 'i18next';
import { useState } from 'react';

import { ShowModalFunction } from 'components/modules/Modal/@types';
import { SetNickname } from 'pages/Tournament/components';

import icons from 'assets/img/icons';

import { PropsFormEnterBattle } from './@types';

import * as S from '../EventModalStyled';

export const FormEnterBattle = ({
  error = false,
  translationString = '',
  formik = {}
}: PropsFormEnterBattle) => {
  const [seePass, setSeePass] = useState(false);

  return (
    <>
      <S.PasswordWrapper error={error}>
        <S.Field
          id="password"
          type={seePass ? 'text' : 'password'}
          placeholder={t(`${translationString}.password.placeholder`)}
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <S.SeePassIcon
          src={icons.eye}
          alt={t(`${translationString}.eyeIcon.alt`)}
          show={seePass}
          error={error}
          onClick={() => setSeePass(!seePass)}
        />
      </S.PasswordWrapper>
      {error ? <S.Error>{formik.errors.password}</S.Error> : null}
    </>
  );
};

export const renderNicknameModal = (
  showModal: ShowModalFunction,
  password: string,
  entity_id: string
) => {
  showModal(
    <SetNickname
      name=""
      amount=""
      image_url=""
      type="acceptBattle"
      password={password}
      entity_id={entity_id}
      platforms={{
        name: '',
        entity_id: '',
        image_url: ''
      }}
    />,
    '465px',
    true,
    '42px'
  );
};
