import { registeredType } from './@types';
import * as S from './RegisteredStyle';

const Registered = ({ registered, max, text }: registeredType) => {
  return (
    <S.RegisteredContainer>
      <S.Title>
        {registered}/{max}
      </S.Title>
      <S.Text>{text}</S.Text>
    </S.RegisteredContainer>
  );
};

export default Registered;
